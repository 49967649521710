import { TAG_VARIANT } from "../theme";

export const PAGE_HEADER_CARD_EXPENSE = {
  desc: "Track expenses, submit receipts, and action any expenses requiring your approval. Expense approval actions are configured in Settings > Smart approvals.",
  header: "Card expenses",
};

export const CARDS_EXPENSES_DEFAULT_ROW_HEIGHT = 48;
export const CARDS_EXPENSES_DEFAULT_PAGE_SIZE = 10;

export const CARDS_EXPENSES_TABLE_COLUMNS_STATUS_KEY = "activity_status";
export const CARDS_EXPENSES_TABLE_COLUMNS_RECEIPT_KEY = "receipt_attachment";
export const CARDS_EXPENSES_TABLE_COLUMNS_SUBSCRIPTION_KEY =
  "subscription_source";
export const CARDS_EXPENSES_TABLE_COLUMNS_XERO_STATUS_KEY = "tXeroStatus";

export const CARDS_EXPENSES_STATUS = {
  DECLINED: "declined",
  SETTLED: "settled",
  REVERSED: "reversed",
  REVERSAL: "reversal",
  REFUND: "refund",
};

export const CARDS_STATUS_CLASSES = {
  approved: TAG_VARIANT.ACTIVE,
  declined: TAG_VARIANT.REJECTED,
  settled: TAG_VARIANT.ACTIVE,
  reversed: TAG_VARIANT.CREATED,
};

export const CARDS_EXPENSES_TABLE_COLUMNS = {
  status: {
    label: "Transaction status",
    key: CARDS_EXPENSES_TABLE_COLUMNS_STATUS_KEY,
    ellipsis: false,
  },
  receipt: {
    label: "Receipt",
    key: CARDS_EXPENSES_TABLE_COLUMNS_RECEIPT_KEY,
    ellipsis: false,
  },
  date: { label: "Transaction date", key: "date" },
  cardHolder: { label: "Cardholder", key: "CardHolder" },
  cardType: { label: "Card type", key: "card_type" },
  cardNickname: { label: "Card nickname", key: "nickname" },
  merchant: { label: "Merchant", key: "merchant_name" },
  billingAmount: { label: "Billing amount", key: "amount" },
  subscription: {
    label: "Subscription",
    key: CARDS_EXPENSES_TABLE_COLUMNS_SUBSCRIPTION_KEY,
  },
  nextApprover: { label: "Next approver", key: "step_name" },
  xeroStatus: {
    label: "Xero status",
    key: CARDS_EXPENSES_TABLE_COLUMNS_XERO_STATUS_KEY,
    ellipsis: false,
  },
};

export const CARDS_EXPENSES_TABLE_COLUMNS_OUTSTANDING = [
  { ...CARDS_EXPENSES_TABLE_COLUMNS.status, width: 10, align: "center" },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.receipt, width: 5, align: "center" },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.date, width: 10, align: "center" },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.cardHolder, width: 12 },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.cardType, width: 10 },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.cardNickname, width: 13 },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.merchant, width: 12 },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.billingAmount, width: 13, align: "right" },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.subscription, width: 10, align: "center" },
];

export const CARDS_EXPENSES_TABLE_COLUMNS_REVIEW = [
  { ...CARDS_EXPENSES_TABLE_COLUMNS.status, width: 10, align: "center" },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.receipt, width: 5, align: "center" },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.date, width: 10, align: "center" },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.cardHolder, width: 10 },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.nextApprover, width: 10 },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.cardType, width: 7 },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.cardNickname, width: 10 },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.merchant, width: 10 },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.billingAmount, width: 13, align: "right" },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.subscription, width: 10, align: "center" },
];

export const CARDS_EXPENSES_TABLE_COLUMNS_APPROVED = ({ isXero }) => [
  { ...CARDS_EXPENSES_TABLE_COLUMNS.receipt, width: 5, align: "center" },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.date, width: 10, align: "center" },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.cardHolder, width: 15 },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.cardType, width: 10 },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.cardNickname, width: 15 },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.merchant, width: isXero ? 10 : 20 },
  {
    ...CARDS_EXPENSES_TABLE_COLUMNS.billingAmount,
    width: isXero ? 10 : 15,
    align: "right",
  },
  { ...CARDS_EXPENSES_TABLE_COLUMNS.subscription, width: 10, align: "center" },
];

export const CARDS_EXPENSES_KEYS = {
  OUTSTANDING: "outstanding",
  REVIEW: "to_be_reviewed",
  DRAWER_REVIEW: "toBeReviewed",
  APPROVED: "approved",
};

export const selectAllCheckboxItemsKey = {
  DISPLAYED_ITEMS: "displayedItems",
  AVAILABLE_ITEMS: "availableItems",
};

export const CARDS_EXPENSES_TABS = [
  {
    key: CARDS_EXPENSES_KEYS.OUTSTANDING,
    label: "Outstanding",
    transactionStatusApplicableForArchiving: [
      CARDS_EXPENSES_STATUS.DECLINED,
      CARDS_EXPENSES_STATUS.REVERSAL,
      CARDS_EXPENSES_STATUS.REVERSED,
    ],
    transactionStatusApplicableForShowingOnlyDetails: [
      CARDS_EXPENSES_STATUS.DECLINED,
      CARDS_EXPENSES_STATUS.REVERSAL,
      CARDS_EXPENSES_STATUS.REVERSED,
      CARDS_EXPENSES_STATUS.REFUND,
    ],
    selectedAllCheckboxItems: [
      {
        label: (noOfRecords) =>
          `Select ${noOfRecords} expense${noOfRecords > 1 ? "s" : ""}`,
        key: selectAllCheckboxItemsKey.DISPLAYED_ITEMS,
      },
      {
        label: (noOfRecords) =>
          `Select all ${noOfRecords} expense${noOfRecords > 1 ? "s" : ""}`,
        key: selectAllCheckboxItemsKey.AVAILABLE_ITEMS,
      },
    ],
    columns: CARDS_EXPENSES_TABLE_COLUMNS_OUTSTANDING,
  },
  {
    key: CARDS_EXPENSES_KEYS.REVIEW,
    label: "To be reviewed",
    selectedAllCheckboxItems: [
      {
        label: (noOfRecords) =>
          `Select ${noOfRecords} expense${noOfRecords > 1 ? "s" : ""}`,
        key: selectAllCheckboxItemsKey.DISPLAYED_ITEMS,
      },
      {
        label: (noOfRecords) =>
          `Select all ${noOfRecords} expense${noOfRecords > 1 ? "s" : ""}`,
        key: selectAllCheckboxItemsKey.AVAILABLE_ITEMS,
      },
    ],
    columns: CARDS_EXPENSES_TABLE_COLUMNS_REVIEW,
  },
  {
    key: CARDS_EXPENSES_KEYS.APPROVED,
    label: "Approved",
    columns: CARDS_EXPENSES_TABLE_COLUMNS_APPROVED,
  },
];

export const VISIBILITY_TYPE = {
  ALL: "all",
  MY: "my",
};

export const BE_FE_FORM_KEY_MAPPING = {
  receipt: "Card receipts",
  vat: "VAT",
};

export const BE_KEY_MAPPING_FOR_ROW = {
  [BE_FE_FORM_KEY_MAPPING.receipt]: "receipt_attachment_url",
  [BE_FE_FORM_KEY_MAPPING.vat]: "vat_amount",
};

export const BUTTON_TOOLTIP = "Please upload a copy of your receipt to proceed";
