import { Form } from "antd";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NAVIGATION_URL } from "../../../constants";
import { SCHEDULE_PAYMENT_MODAL } from "../../../constants/PaymentRuns";
import { getSupplierDetails } from "../../../helper";
import { PR_SCHEDULE_PAYMENT_MODAL } from "../../../utils";
import {
  ActionLink,
  Column,
  CommonInput,
  CommonSelect,
  Row,
  Space,
} from "../../common";
import "../payment-run.scss";

function InvoicesList({
  form,
  index,
  invoice,
  suppliers,
  prDetails,
  paymentRunFromSupplier,
  handleSupplierSelected,
  schedule = false,
  download = false,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedSupplier =
    Form.useWatch("supplier" + index, form) || invoice?.supplier?.id;
  const { currentSupplierAllAccountDetails } = getSupplierDetails({
    allSupplierDetails: suppliers?.result,
    selectedSupplier: selectedSupplier,
  });
  const supplierAccountOptions = currentSupplierAllAccountDetails.map(
    ({ id, account_name }) => ({
      value: id,
      label: account_name,
    })
  );
  useEffect(() => {
    const timer = setTimeout(() => {
      if (paymentRunFromSupplier) {
        navigate(location.pathname, { replace: true });
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const goToSupplierManagement = (supplierId) => {
    const supplier = suppliers?.result?.find(({ id }) => id === supplierId);
    navigate(NAVIGATION_URL.SUPPLIER_MANAGEMENT, {
      state: {
        supplier: {
          supplier,
          supplierName: supplier?.name,
        },
        prDetails: {
          prDetails,
          selectedInvoiceFromPR: invoice?.invoice_number,
          schedule,
          download,
        },
      },
    });
  };

  function supplierInitialValue() {
    if (invoice?.supplier?.id) {
      return invoice?.supplier?.id;
    } else if (
      paymentRunFromSupplier &&
      paymentRunFromSupplier?.prDetails?.selectedInvoiceFromPR ===
        invoice?.invoice_number
    ) {
      return paymentRunFromSupplier?.selectedSupplier?.id;
    } else {
      return null;
    }
  }

  function supplierAccountInitialValue() {
    if (
      paymentRunFromSupplier &&
      paymentRunFromSupplier?.prDetails?.selectedInvoiceFromPR ===
        invoice?.invoice_number
    ) {
      return paymentRunFromSupplier?.selectedSupplier?.supplier_accounts?.[0]
        ?.id;
    } else if (invoice?.supplier?.id) {
      return supplierAccountOptions?.find(
        ({ value }) => value === invoice?.supplier_account_details_id
      )?.value;
    } else {
      return null;
    }
  }

  return (
    <Row span={24} className="w-100 invoices-list m-b-20px">
      <Column span={8}>
        <Form.Item
          name={"invoiceNumber" + index}
          initialValue={invoice?.invoice_number}
        >
          <CommonInput className="m-t-15px" disabled={true} />
        </Form.Item>
      </Column>
      <Column span={8}>
        <Form.Item
          name={"supplier" + index}
          initialValue={supplierInitialValue()}
          rules={PR_SCHEDULE_PAYMENT_MODAL.SUPPLIER}
        >
          <CommonSelect
            disabled={invoice?.supplier?.id}
            className="m-t-15px"
            options={suppliers?.result?.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            onChange={(value) => handleSupplierSelected(index, value)}
            placeholder={SCHEDULE_PAYMENT_MODAL.SUPPLIER_PLACEHOLDER}
          />
        </Form.Item>
      </Column>
      <Column span={8}>
        <Form.Item
          name={"supplierAccount" + index}
          initialValue={supplierAccountInitialValue()}
          rules={PR_SCHEDULE_PAYMENT_MODAL.SUPPLIER_ACCOUNT}
        >
          <CommonSelect
            className="m-t-15px"
            options={supplierAccountOptions}
            placeholder={SCHEDULE_PAYMENT_MODAL.SUPPLIER_ACCOUNT_PLACEHOLDER}
            dropdownRender={
              !selectedSupplier || supplierAccountOptions?.length !== 0
                ? null
                : () => (
                    <>
                      <Space className="p-l-8px p-r-8px p-b-4px">
                        <ActionLink
                          onClick={() =>
                            goToSupplierManagement(
                              invoice?.supplier?.id || selectedSupplier
                            )
                          }
                          text={SCHEDULE_PAYMENT_MODAL.ADD_SUPPLIER_AC}
                        />
                      </Space>
                    </>
                  )
            }
          />
        </Form.Item>
      </Column>
    </Row>
  );
}

export default InvoicesList;
