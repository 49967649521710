import { useCallback, useEffect, useState } from "react";
import { getCurrencies } from "../redux/action/auth";

export default function useCurrencies() {
  const [currencies, setCurrencies] = useState([]);
  const [rawCurrencies, setRawCurrencies] = useState([]);

  const getCurrencyNameFromId = useCallback(
    (id) => {
      const currencyDetails = currencies?.find((el) => el?.value === id);
      return currencyDetails ? currencyDetails?.label : id;
    },
    [currencies]
  );

  useEffect(() => {
    getCurrencies().then((res) => {
      let data = res.result.map((list) => ({
        label: ` ${list.symbol}-${list.currency} - ${list.currency_name}`,
        value: list.id,
      }));
      setCurrencies(data || []);
      setRawCurrencies(res);
    });
  }, []);

  return { currencies, rawCurrencies, getCurrencyNameFromId };
}
