import { ACCENTS, BUTTON_VARIANT } from "../../constants";
import { TRACKING_CATEGORIES } from "../../redux/service/types";
import { LINE_ITEMS_FORM_RULES, capitalizeFirst } from "../../utils";

export const LINE_ITEMS = {
  LINE_ITEMS: "line items",
  SUMMARY:
    "The sum of your invoice line items total the original invoice net amount of ",
  SUMMARY_CHANGED:
    "Since changes have been made to the invoice line items, the net amount of the invoice at the header level will be changed from ",
  TO: " to ",
};

export const LINE_ITEMS_CONTAINER_CLASS =
  "line-items-section h-100 w-100 position-absolute t-0px l-0px w-100 bg-white";

export const LINE_ITEM_HEADER = {
  BACK_BTN: {
    accent: ACCENTS.BLACK,
    variant: BUTTON_VARIANT.BUTTON_ONLY_ICON,
    className: "p-0 justify-content-start ",
    ICON: {
      iconName: "arrow_back",
      showIconOutline: true,
    },
  },
  TITLE: {
    variant: "title",
    fontSize: "24",
    responsiveFontSize: false,
  },
};

export const LINE_ITEM_FOOTER = {
  CANCEL: {
    text: "Cancel",
    className: "w-50",
    accent: ACCENTS.SECONDARY,
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
  },
  SAVE: {
    text: "Save",
    className: "w-45",
  },
};

export const RIGHT_SECTION = {
  ICON: {
    CLOSED: {
      className: "arrow-icon",
      iconName: "keyboard_arrow_left",
    },
    OPEN: {
      className: "arrow-icon",
      iconName: "keyboard_arrow_right",
    },
  },
  LABEL: {
    className: "invoice-icon-title",
    variant: "body",
    text: "Invoice PDF",
  },
};

export const LINE_ITEM_TABLE_COLUMNS_KEY = {
  IS_AI_GENERATED: "is_ai_generated",
  DESCRIPTION: "description",
  QUANTITY: "quantity",
  UNIT_COST: "unit_cost",
  NET_AMOUNT: "net_amount",
  TOTAL_AMOUNT: "total_amount",
  EDIT: "edit",
  ACCOUNT: "chartOfAccounts",
  TAX_RATE: "taxRate",
  TRX_AMOUNT: "trx_amount",
  ITEM_LIST: "erp_item_id",
  TRACKING_CATEGORIES: "erp_tracking_categories",
};

export const LINE_ITEM_TABLE_COLUMNS = {
  generated_by_AI: {
    label: "",
    key: LINE_ITEM_TABLE_COLUMNS_KEY.IS_AI_GENERATED,
  },
  description: {
    label: "Line item description",
    key: LINE_ITEM_TABLE_COLUMNS_KEY.DESCRIPTION,
    placeholder: "Line item description",
  },
  quantity: {
    label: "Quantity",
    key: LINE_ITEM_TABLE_COLUMNS_KEY.QUANTITY,
    placeholder: "Quantity",
  },
  unit_cost: {
    label: "Unit cost",
    key: LINE_ITEM_TABLE_COLUMNS_KEY.UNIT_COST,
    placeholder: "Unit cost",
  },
  net_amount: {
    label: "Net amount",
    key: LINE_ITEM_TABLE_COLUMNS_KEY.NET_AMOUNT,
    placeholder: "Net amount",
  },
  total_amount: {
    label: "Total amount",
    key: LINE_ITEM_TABLE_COLUMNS_KEY.TOTAL_AMOUNT,
  },
  account: {
    label: "Account",
    key: LINE_ITEM_TABLE_COLUMNS_KEY.ACCOUNT,
    placeholder: "Account",
  },
  tax_rate: {
    label: "Tax rate",
    key: LINE_ITEM_TABLE_COLUMNS_KEY.TAX_RATE,
    placeholder: "Tax rate",
  },
  trx_amount: {
    label: "TRX amount",
    key: LINE_ITEM_TABLE_COLUMNS_KEY.TRX_AMOUNT,
    placeholder: "TRX amount",
  },
  item_list: {
    label: "Item",
    key: LINE_ITEM_TABLE_COLUMNS_KEY.ITEM_LIST,
    placeholder: "Item",
  },
  tracking_categories: {
    label: "Tracking categories",
    key: LINE_ITEM_TABLE_COLUMNS_KEY.TRACKING_CATEGORIES,
    placeholder: "Tracking categories",
  },
  edit: { label: "Actions", key: LINE_ITEM_TABLE_COLUMNS_KEY.EDIT },
};

export const NON_ERP_COLUMNS = [
  { ...LINE_ITEM_TABLE_COLUMNS.generated_by_AI, width: 8 },
  { ...LINE_ITEM_TABLE_COLUMNS.description, width: 20 },
  { ...LINE_ITEM_TABLE_COLUMNS.quantity, width: 15 },
  { ...LINE_ITEM_TABLE_COLUMNS.unit_cost, width: 15 },
  {
    ...LINE_ITEM_TABLE_COLUMNS.net_amount,
    width: 15,
    customClass: "line-items-net-amount",
  },
  // { ...LINE_ITEM_TABLE_COLUMNS.edit, width: 20, align: "center" },
];

export const ERP_COLUMNS = [
  { ...LINE_ITEM_TABLE_COLUMNS.generated_by_AI, widthInPX: 50 },
  { ...LINE_ITEM_TABLE_COLUMNS.description, widthInPX: 160 },
  { ...LINE_ITEM_TABLE_COLUMNS.quantity, widthInPX: 100 },
  { ...LINE_ITEM_TABLE_COLUMNS.unit_cost, widthInPX: 100 },
  {
    ...LINE_ITEM_TABLE_COLUMNS.net_amount,
    widthInPX: 100,
    customClass: "line-items-net-amount",
  },
  // { ...LINE_ITEM_TABLE_COLUMNS.edit, widthInPX: 100, align: "center" },
];

export const ACTION_COLUMN = {
  ...LINE_ITEM_TABLE_COLUMNS.edit,
  align: "center",
};

export const TOOLTIP_TEXT = {
  MANDATORY_FIELD: (integrationName) =>
    `Mandatory ${capitalizeFirst(integrationName)} field`,
  NON_MANDATORY_FIELD: (integrationName) =>
    `${capitalizeFirst(integrationName)} field`,
};

export const LINE_ITEMS_FORM = {
  DESCRIPTION: {
    placeholder: LINE_ITEM_TABLE_COLUMNS.description.placeholder,
    rules: LINE_ITEMS_FORM_RULES.DESCRIPTION,
  },
  UNIT_COST: {
    placeholder: LINE_ITEM_TABLE_COLUMNS.unit_cost.placeholder,
    rules: LINE_ITEMS_FORM_RULES.UNIT_COST,
    type: "number",
    controls: false,
    min: 0,
    precision: 2,
    className: "line-item-unit-cost",
  },
  QUANTITY: {
    placeholder: LINE_ITEM_TABLE_COLUMNS.quantity.placeholder,
    rules: LINE_ITEMS_FORM_RULES.QUANTITY,
    type: "number",
    controls: false,
    min: 0,
    precision: 4,
    className: "line-item-qty",
  },
  TAX_RATE: {
    placeholder: LINE_ITEM_TABLE_COLUMNS.tax_rate.placeholder,
    // rules: LINE_ITEMS_FORM_RULES.TAX_RATE,
  },
  TRX_AMOUNT: {
    placeholder: LINE_ITEM_TABLE_COLUMNS.trx_amount.placeholder,
    type: "number",
    controls: false,
    min: 0,
    precision: 2,
    className: "line-item-unit-cost",
  },
  ITEM_LIST: {
    placeholder: LINE_ITEM_TABLE_COLUMNS.item_list.placeholder,
    // rules: LINE_ITEMS_FORM_RULES.ITEM_LIST,
  },
  ACCOUNT: {
    placeholder: LINE_ITEM_TABLE_COLUMNS.account.placeholder,
    // rules: LINE_ITEMS_FORM_RULES.CHART_OF_ACCOUNTS,
  },
  TRACKING_CATEGORIES: {
    // rules: LINE_ITEMS_FORM_RULES.TRACKING_CATEGORIES,
  },
};

export const LINE_ITEMS_TOGGLE_TYPE = {
  EDIT: "edit",
  SAVE: "save",
};

export const ITEM_TABLE_LOADER_TEXT =
  "Line items are being generated by Kloo AI";
