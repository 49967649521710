import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import { LOCAL_STORAGE_KEY, getItem } from "../utils";

export function poRegistration(data) {
  return apiClient({
    method: "POST",
    url: PATH.user.poRegistration(),
    data,
  });
}

export async function getOrganizationDetail({ orgId }) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.auth.orgData({ orgId }),
  });
  return tempResponse?.data?.result;
}

export async function updateOrganizationDetail(data) {
  const orgId = getItem(LOCAL_STORAGE_KEY.ORD_ID);
  const tempResponse = await apiClient({
    method: "PUT",
    url: PATH.auth.updateOrgData({ orgId }),
    data,
  });
  return tempResponse?.data?.result;
}
