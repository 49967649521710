import { isDevelopmentEnvironment } from "../../utils";
import { TAG_VARIANT } from "../theme";

export const CARD_REQUESTS_TABS_KEYS = {
  MY_APPROVALS: "my-approvals",
  ALL_APPROVALS: "all-approvals",
};

export const CARD_REQUESTS_TABS = [
  {
    key: CARD_REQUESTS_TABS_KEYS.MY_APPROVALS,
    label: "My approvals",
  },
  {
    key: CARD_REQUESTS_TABS_KEYS.ALL_APPROVALS,
    label: "All approvals",
  },
];

export const CARD_REQUESTS_TABS_PU = [
  {
    key: CARD_REQUESTS_TABS_KEYS.MY_APPROVALS,
    label: "My approvals",
  },
];

export const CARD_REQUESTS_TABLE_COLUMNS_STATUS_KEY = "tStatus";

export const CARD_REQUESTS_TABLE_COLUMNS = {
  status: {
    title: "Status",
    key: CARD_REQUESTS_TABLE_COLUMNS_STATUS_KEY,
    ellipsis: false,
  },
  requestor: { title: "Requestor", key: "tRequestorName" },
  cardNickname: { title: "Card nickname", key: "tNickName" },
  requestType: {
    title: "Request type",
    key: "tRequestType",
    visible: isDevelopmentEnvironment(),
  },
  type: { title: "Type", key: "tType" },
  amount: { title: "Amount", key: "tAmount" },
  createdAt: { title: "Created date", key: "tCreatedAt" },
  needsApprover: { title: "Needs approver", key: "tNeedsApprover" },
};

export const CARD_REQUESTS_TABLE_MY_APPROVAL_COLUMNS = [
  { ...CARD_REQUESTS_TABLE_COLUMNS.status, align: "center", width: 14 },
  { ...CARD_REQUESTS_TABLE_COLUMNS.requestor, width: 20 },
  { ...CARD_REQUESTS_TABLE_COLUMNS.cardNickname, width: 20 },
  { ...CARD_REQUESTS_TABLE_COLUMNS.requestType, width: 10 },
  { ...CARD_REQUESTS_TABLE_COLUMNS.type, width: 10 },
  { ...CARD_REQUESTS_TABLE_COLUMNS.amount, align: "right", width: 16 },
  { ...CARD_REQUESTS_TABLE_COLUMNS.createdAt, width: 10 },
];

export const CARD_REQUESTS_TABLE_ALL_APPROVAL_COLUMNS = [
  { ...CARD_REQUESTS_TABLE_COLUMNS.status, align: "center", width: 14 },
  { ...CARD_REQUESTS_TABLE_COLUMNS.needsApprover, width: 10 },
  { ...CARD_REQUESTS_TABLE_COLUMNS.requestor, width: 15 },
  { ...CARD_REQUESTS_TABLE_COLUMNS.cardNickname, width: 15 },
  {
    ...CARD_REQUESTS_TABLE_COLUMNS.requestType,
    visible: isDevelopmentEnvironment(),
    width: 10,
  },
  { ...CARD_REQUESTS_TABLE_COLUMNS.type, width: 10 },
  { ...CARD_REQUESTS_TABLE_COLUMNS.amount, align: "right", width: 16 },
  { ...CARD_REQUESTS_TABLE_COLUMNS.createdAt, width: 10 },
];

export const CARD_REQUEST_PAGE_CONFIG = {
  HEADER_CONFIG: {
    desc: "All outstanding card requests requiring approval are shown on this page, with Organisation Admins able to overwrite the configured approval chain as required.",
    header: "Card requests",
    buttonLabel: "",
  },
  ROW_HEIGHT: 50,
  DEFAULT_PAGE_SIZE: 10,
  CREATED_AT_DATE_FORMAT: "DD-MM-YYYY",
  STATUS: [
    {
      key: "Request Cancelled",
      variant: TAG_VARIANT.REJECTED,
      title: "Request cancelled",
    },
    {
      key: "Your request",
      variant: TAG_VARIANT.CREATED,
      title: "Your request",
    },
    {
      key: "Awaiting approval",
      variant: TAG_VARIANT.AWAITING_APPROVAL,
      title: "Awaiting approval",
    },
  ],
  CARD_TYPE: [
    {
      key: "virtual-multi",
      title: "Virtual-multi",
    },
    {
      key: "virtual-single",
      title: "Virtual-single",
    },
    {
      key: "physical",
      title: "Physical",
    },
  ],
  NEEDS_APPROVER_CONFIG: [
    {
      key: "Teammanager",
      title: "Team manager",
    },
  ],
};
