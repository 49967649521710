import { WORKFLOW_FORM } from "../../constants";
import { OPERATORS } from "../constant";

export function manipulateDataForWorkFlowForm(data) {
  return [data].map((val) => ({
    workflow_name: val.workflow_name,
    steps: val.workflow_children.map((data) => {
      let otherFields = {};
      data.workflowsteps.forEach((d, index) => {
        otherFields = {
          ...otherFields,
          ...{
            [`approver${index + 1}`]:
              d.approver_type === "user"
                ? d.approver_type_id
                : `${d.approver_type_id}_${d.approver_type}`,
            [`limit${index + 1}`]: d.workflow_step_threshold.threshold_limit,
            [`custom_field_value_id${index + 1}`]: d?.custom_field_value_id,
          },
        };
      });
      const newData = {
        ...otherFields,
        totalSteps: data.step,
        stepType: data.workflow_category.id,
        custom_field_id: data?.workflowsteps?.[0]?.custom_field_id,
        stepTypeName: data.workflow_category.workflow_type,
      };
      const keys = Object.keys(newData);
      const stepId = keys.map((item) => newData[item]).join("-");
      return {
        stepId,
        stepIndex: data.order - 1,
        data: newData,
      };
    }),
  }))[0];
}

const getApproversPayload = (data) => {
  const isConditionalThresholdStepTypeSelected =
    convertLowerCaseWithoutSpace(data?.stepTypeName || "") ===
    convertLowerCaseWithoutSpace(WORKFLOW_FORM.CONDITIONAL_THRESHOLD_STEP_TYPE);

  const approvers = [];
  for (let index = 1; index <= data.totalSteps; index++) {
    const approver = data[`approver${index}`].split("_");
    approvers.push({
      id: approver[0],
      amount: data[`limit${index}`] || 0,
      order: index,
      type: approver[1] || "user",
      operator:
        index !== 1 || isConditionalThresholdStepTypeSelected
          ? OPERATORS.GREATER_THAN
          : OPERATORS.LESS_THAN_EQUAL_TO,
      custom_field_value_id: data[`custom_field_value_id${index}`],
    });
  }
  return approvers;
};

export const getPayloadForWorkflow = (data) => {
  return data.map((row) => ({
    category_id: row.data.stepType,
    custom_field_id: row?.data?.custom_field_id,
    approvers: getApproversPayload(row.data),
  }));
};

export const convertLowerCaseWithoutSpace = (str) => {
  return str.toLowerCase().replaceAll(" ", "");
};
