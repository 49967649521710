import { Collapse as ACollapse } from "antd";
import { memo } from "react";
import { constructClassName } from "../../../utils/functions";

function Collapse({ className, ...rest }) {
  return (
    <ACollapse
      className={constructClassName(["kloo-collapse", className])}
      {...rest}
    />
  );
}

export default memo(Collapse);
