import { Form } from "antd";
import { Button, CustomButton, Modal, TextField, Typography } from "..";
import { verifyCardOTP } from "../../../api/OTPAPI";
import {
  LOCAL_STORAGE_KEY,
  OTP_MODAL_CONSTANT,
  OTP_REQUEST_TYPE,
} from "../../../utils/constant";
import { getItem } from "../../../utils/localStorage";
import { OTP_MODAL_RULES } from "../../../utils/validator";
import "./otp-input.scss";
import { ACCENTS, BUTTON_VARIANT } from "../../../constants";

export default function OTPModal({ open, onConfirm, mobile, onCancel }) {
  const [form] = Form.useForm();

  const handleOtpRequest = (values) => {
    const payload = {
      otp: values.otp,
      otp_send_type: OTP_REQUEST_TYPE.CARD_MONTHLY_LIMIT,
    };
    verifyCardOTP(getItem(LOCAL_STORAGE_KEY.USER_ID), payload)
      .then(() => {
        onConfirm();
      })
      .catch(() => {
        form.setFields([
          {
            name: "otp",
            value: values.otp,
            errors: ["Please enter a valid verification code"],
          },
        ]);
      });
  };

  return (
    <Modal width={500} isOpen={open} showHeader={false}>
      <Typography
        variant={"title"}
        text={OTP_MODAL_CONSTANT.title}
        fontSize={"24"}
        responsiveFontSize={false}
        className={"verification_title"}
      />
      <div className="otp-container">
        <Typography
          className="body-text m-b-20px"
          variant="body"
          text={OTP_MODAL_CONSTANT.body(mobile)}
        />
        <Form
          form={form}
          initialValues={{
            otp: "",
          }}
          onFinish={handleOtpRequest}
          className={"width-full"}
        >
          <Form.Item name="otp" rules={OTP_MODAL_RULES}>
            <TextField
              placeholder={OTP_MODAL_CONSTANT.placeholder}
              size={"large"}
              maxLength={6}
              autoComplete="off"
              allowClear={false}
            />
          </Form.Item>

          <div className="otp-btn-container m-t-25px">
            <CustomButton
              text={OTP_MODAL_CONSTANT.confirmButtonLabel}
              accent={ACCENTS.PRIMARY}
              variant={BUTTON_VARIANT.BUTTON_FILLED}
              htmlType="submit"
              className="m-r-20px"
              customSize={"large"}
            />
            <CustomButton
              text={OTP_MODAL_CONSTANT.cancelButtonLabel}
              accent={ACCENTS.SECONDARY}
              variant={BUTTON_VARIANT.BUTTON_HOLLOW}
              onClick={onCancel}
              customSize={"large"}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
}
