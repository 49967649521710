import { MENU_LIST } from "../service/types";

const initialState = {
  menuList: {},
  userType: "",
};
export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case MENU_LIST:
      return {
        ...state,
        menuList: action.payload?.scopes,
        userType: action.payload?.role,
      };
    default:
      return state;
  }
}
