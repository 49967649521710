import { isPDF } from "../../../utils";
import { Box, Image, PDFViewer, When } from "../../common";

function DrawerRightSection({ selectedModuleData }) {
  const isPdf = isPDF(selectedModuleData?.attachment);

  return (
    <Box className="w-100 h-100">
      <When condition={isPdf}>
        <PDFViewer url={selectedModuleData?.attachment} />
      </When>
      <When condition={!isPdf}>
        <Image preview={false} src={selectedModuleData?.attachment} />
      </When>
    </Box>
  );
}

export default DrawerRightSection;
