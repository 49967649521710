import { REGISTRATION } from "../constants";
import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import { NOTIFICATION_TYPE, showNotification } from "../utils";
const { errorMessage } = REGISTRATION;

export async function sanctionCheckUser({ data = {}, id, method = "GET" }) {
  const tempResponse = await apiClient({
    method,
    url: PATH.auth.sanctionCheck(id),
    data: data,
  });
  return tempResponse?.data?.result;
}

export async function selfRegistration({ data, id }) {
  const tempResponse = await apiClient({
    method: "PUT",
    url: PATH.auth.selfRegistration(id),
    data,
  });
  return tempResponse;
}

export async function OtherOrgTypeRegistration({ data }) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.user.poRegistration(),
    data,
  });
  return tempResponse?.data?.result;
}

export async function analyticsTypeRegistration({ data }) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.auth.analyticsRegistor,
    data: data,
  });
  return tempResponse?.data?.result;
}

export async function analyticsUserDetails({ userId }) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.auth.RegistrationLink(userId),
  });
  return tempResponse?.data?.result;
}
export const updateAnalyticsUser = ({ data, userId }) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.auth.updateUserAnalytics(userId),
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        showNotification(
          NOTIFICATION_TYPE.error,
          errorMessage?.title,
          errorMessage?.subTitle
        );
      });
  });
};

export const createAnalyticsUser = ({ data }) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.UserAnalytics(),
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        showNotification(
          NOTIFICATION_TYPE.error,
          errorMessage?.title,
          errorMessage?.subTitle
        );
      });
  });
};

export async function verifyEmail({ email, contact, country_code }) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.auth.verifyEmailRegistration(email, contact, country_code),
  });
  return tempResponse?.data;
}

export async function verifyOtp({ data }) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.auth.verifyOTPViaMobile,
    data,
  });
  return tempResponse;
}
