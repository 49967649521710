import React, { memo } from "react";
import PropTypes from "prop-types";
import { Steps as ASteps } from "antd";
import { constructClassName } from "../../../utils";

function Steps({ steps, currentStep, className, ...rest }) {
  const { Step } = ASteps;
  return (
    <ASteps
      currentStep={currentStep}
      className={constructClassName(["kloo-steps", className])}
      {...rest}
    >
      {steps &&
        steps?.map(({ key, title }) => {
          return <Step key={key} title={title} />;
        })}
    </ASteps>
  );
}

Steps.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  className: PropTypes.string,
};

Steps.defaultProps = {
  steps: [],
  currentStep: 0,
};

export default memo(Steps);
