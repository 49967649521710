import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { lastLoginUpdate, refreshToken } from "../../api";
import {
  NAVIGATION_URL,
  REDIRECTION_CONST,
  TAG_VARIANT,
} from "../../constants";
import { refreshTokenPayload, removeKey } from "../../helper/Auth";
import { loadingStart, loadingStop } from "../../redux/action/loader";
import { LOCAL_STORAGE_KEY, getItem, setItem } from "../../utils";
import { CustomTag, Typography } from "../common";
import CommonAvatar from "../common/Avatar";
import "./SwitchProfile.scss";
import { isInactiveOTPVerifyRequired } from "../../hooks/useAuth";

const SwitchProfile = ({
  setLoginStepper,
  setprofileData,
  setRemainingTime,
  loggedIn,
  orgData = [],
  setShowSwitchProfile,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get(REDIRECTION_CONST.redirectTo);
  const isLoginViaSSO = getItem(LOCAL_STORAGE_KEY.SSO);

  function switchProfileHandler(orgObj) {
    dispatch(loadingStart());
    const payload = refreshTokenPayload({
      SSO: isLoginViaSSO,
      org_id: orgObj?.org_id,
    });
    refreshToken(payload).then(async (res) => {
      loggedIn && setShowSwitchProfile(false);
      const userOrgId = res?.user_org_id;
      if (!isInactiveOTPVerifyRequired()) {
        setItem(LOCAL_STORAGE_KEY.VERIFY_OTP, true);
      }
      lastLoginUpdate(userOrgId);
      if (redirectTo) {
        navigate(removeKey(window.location.search));
      } else {
        navigate(NAVIGATION_URL.DASHBOARD);
      }
      loggedIn && window.location.reload();
      dispatch(loadingStop());
    });
  }

  useEffect(() => {
    setRemainingTime && setRemainingTime(true);
    let timer =
      !loggedIn &&
      setInterval(() => {
        setLoginStepper("login");
        localStorage.clear();
        setRemainingTime && setRemainingTime(false);
      }, 1000 * 299);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const current_org_id = localStorage.getItem("org_id");
  const active_item = orgData?.find((item) => item?.org_id === current_org_id);
  const other__item =
    orgData?.filter((item) => item?.org_id !== current_org_id) || [];
  return (
    <>
      {/* <div className="login-form-wrapper"> */}
      <div className="switch-profile-wrap">
        <Typography
          variant={"title"}
          text={"Select your business profile"}
          fontSize={"24"}
          responsiveFontSize={false}
          className="m-b-20px"
        />
        <ul className="switch-profile-list">
          {orgData &&
            [active_item, ...other__item]?.map((item) => {
              const active_id = loggedIn && current_org_id === item?.org_id;
              return (
                <li key={item?.org_id}>
                  <div
                    className={`profile-avatar-wrap  ${
                      active_id ? "profile-avatar-wrap-active" : ""
                    }`}
                    onClick={() => !active_id && switchProfileHandler(item)}
                  >
                    <div className="profile-avatar">
                      <CommonAvatar
                        backgroundColor={item?.bgcolor}
                        title={item?.org_name}
                        gap={5}
                        size={{
                          xs: 60,
                          sm: 60,
                          md: 60,
                          lg: 60,
                          xl: 60,
                          xxl: 60,
                        }}
                      ></CommonAvatar>
                      {active_id && (
                        <CustomTag
                          text={"Active"}
                          variant={TAG_VARIANT.ACTIVE}
                          className={"profile-avatar-active-status"}
                        />
                      )}
                    </div>
                    <p className="profile-name">{item?.org_name}</p>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      {/* </div> */}
    </>
  );
};

export default SwitchProfile;
