import React, { useState } from "react";
import { Form, Col, Row } from "antd";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  When,
  Typography,
  CustomButton,
  CustomField,
  Icon,
  Tooltip,
  KlooAIButton,
  KlooAILoader,
} from "..";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import CommonInput from "../Input";
import CommonInputNumber from "../Input/InputNumber";
import {
  PO_ITEM_FORM_RULE,
  roundOff,
  keyDifference,
  moneyFormatter,
  showNotification,
  NOTIFICATION_TYPE,
} from "../../../utils";
import {
  ACCENTS,
  BUTTON_VARIANT,
  DELETE_BUTTON_KEY,
  INPUT_TYPES,
  OCR_LINE_ITEMS,
  PURCHASE_ORDER_CREATE_FROM,
} from "../../../constants";
import "./item-list.scss";
import { getOCRLineItemDetails } from "../../../api";

function ItemList({
  totalAmount,
  onAdd,
  data,
  currencyValue,
  customFields = [],
  setFieldOptions,
  isEditView,
  itemSwitch,
  isExpandable = true,
  disabled = false,
  listLabel,
  listInfo,
  showExpandIcon,
  allowEdit,
  costFieldRequired = true,
  quantityFieldRequired = true,
  viewLineItems,
  setViewLineItems,
  suffixIcon = false,
  suffixIconClassName,
  suffixIconTooltip,
  removeLineItems,
  deleteLineItem,
  inputChangedFromAiValue,
  itemTableContainerClass,
  tableClassName,
  showForm = true,
  showHeader = true,
  columns,
  version,
  taxCodeFieldEnabled,
  forInvoice = false,
  automateLineItems,
  selectedData,
  isKlooAIBtnDisabled = false,
  klooAITooltipText,
}) {
  const [form] = Form.useForm();
  const [loadingLineItems, setLoadingLineItems] = useState(false);
  const [isFormActive, setIsFormActive] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const initialState = {
    name: "",
    cost: null,
    quantity: null,
  };

  const currencySymbol = currencyValue?.[0]?.symbol || currencyValue;

  const onSave = (e, selectedIndex = null, expandRow) => {
    form.validateFields().then((values) => {
      const dirtyFields = Object.keys(values).filter((field) =>
        form.isFieldTouched(field)
      );
      if (!dirtyFields.length) {
        handleClear(expandRow);
        return;
      }
      const customKeys = keyDifference({
        comparingObj: forInvoice ? {} : initialState,
        obj: dirtyFields,
      });
      const newData = getUpdatedListItem(customKeys, selectedIndex, values);

      if (selectedIndex === null) {
        onAdd([...data, newData]);
        toggleForm();
      } else {
        if (data[selectedIndex]?.id) {
          newData.id = data[selectedIndex]?.id;
          newData.deleted_line_items_custom_fields = data[
            selectedIndex
          ]?.innerCustomFields
            ?.filter((field) => !field.value)
            .map((field) => field.id);
        }
        data[selectedIndex] = newData;
        onAdd(data);
      }
      if (inputChangedFromAiValue) {
        inputChangedFromAiValue("line_items");
      }
      form.resetFields();
      setEditItem(false);
      if (expandRow) {
        handleClear(expandRow);
      }
    });
  };

  const getUpdatedListItem = (customKeys, selectedIndex, values) => {
    const amount = values.quantity * values.cost;
    let innerCustomFields = [];
    if (customFields?.length) {
      customKeys.forEach((keyName) => {
        if (data?.[selectedIndex]) {
          innerCustomFields =
            data[selectedIndex]?.innerCustomFields?.filter(
              (field) => field.custom_field_id !== keyName
            ) || [];
        }
        if (
          !values[keyName] &&
          !data?.[selectedIndex]?.storedCustomKeys?.includes(keyName)
        ) {
          return;
        }

        const customFieldById = customFields.find(
          (field) => field.name === keyName
        );
        let itemCustomField = {
          custom_field_id: customFieldById.custom_field_id,
          value: values[keyName] || null,
          id: customFieldById.id,
          user_input: customFieldById.user_input,
          label: customFieldById.label,
        };
        if (
          customFieldById.inputType === INPUT_TYPES.SELECT &&
          values[keyName]
        ) {
          const valueAsText = customFieldById.options.find(
            (option) => option.value === values[keyName]
          ).label;
          itemCustomField.valueAsText = valueAsText;
        }
        innerCustomFields.push(itemCustomField);
        if (data?.[selectedIndex]) {
          data[selectedIndex].innerCustomFields = innerCustomFields;
        }
      });
    }

    let updatedItem = {
      amount: roundOff(amount),
      name: values.name,
      cost: values.cost,
      quantity: values.quantity,
    };

    if (customFields?.length) {
      updatedItem.innerCustomFields = innerCustomFields;
      updatedItem.storedCustomKeys =
        data?.[selectedIndex]?.storedCustomKeys || [];
    }
    return updatedItem;
  };

  const toggleForm = () => {
    setIsFormActive(!isFormActive);
  };

  const renderFormattedAmount = (amount) => {
    return amount || amount === 0
      ? moneyFormatter(amount, null, currencyValue?.[0]?.currency)
      : "";
  };

  const renderBody = ({
    label,
    key,
    record,
    index,
    defaultRender,
    expandRow,
    isRowExpanded,
  }) => {
    return ["cost", "amount"].includes(key)
      ? renderFormattedAmount(record[key])
      : key === DELETE_BUTTON_KEY
      ? renderDelete({
          label,
          key,
          record,
          index,
          defaultRender,
          isRowExpanded,
          expandRow,
        })
      : defaultRender({ label, key, record, index, defaultRender });
  };

  const onEditClick = (record, isRowExpanded, expandRow) => {
    setEditItem(true);
    form.resetFields();
    if (!isRowExpanded) {
      expandRow();
    }
    const { innerCustomFields, ...rest } = record;
    const formValues = { ...rest };
    if (innerCustomFields?.length) {
      record.innerCustomFields.forEach((field) => {
        formValues[field.custom_field_id] = field.value;
      });
    }
    form.setFieldsValue(formValues);
  };

  const renderDelete = ({ record, isRowExpanded, expandRow }) => {
    return (
      <Box wrap="nowrap" columnGap={20}>
        {allowEdit && (
          <CustomButton
            variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
            accent={ACCENTS.SECONDARY}
            icon={<Icon iconName={"edit"} id="edit-icon-font" />}
            onClick={() => {
              onEditClick(record, isRowExpanded, expandRow);
            }}
            disabled={disabled}
            className="edit-icon"
          />
        )}
        <CustomButton
          accent={ACCENTS.DESTRUCTIVE}
          variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
          icon={<CloseOutlined />}
          onClick={() => removeItem(record)}
          disabled={disabled}
          className="edit-icon"
        />
      </Box>
    );
  };

  const removeItem = (record) => {
    if (record?.id && deleteLineItem) {
      deleteLineItem(record.id);
    }
    onAdd(data.filter((item) => item.name !== record.name));
    if (inputChangedFromAiValue) {
      inputChangedFromAiValue("line_items");
    }
  };

  const handleClear = (expandRow) => {
    form.resetFields();
    if (editItem) {
      expandRow && expandRow();
      setEditItem(false);
      return;
    }
    toggleForm();
  };

  const onAddMoreClick = () => {
    toggleForm();
    form.resetFields();
    setEditItem(false);
  };

  const renderExpandedDiv = ({ selectedRow, expandRow }) => {
    const selectedItem = data[selectedRow];
    return (
      <Row key={selectedRow} style={{ width: "100%" }}>
        <When condition={editItem}>
          {renderItemForm(selectedRow, expandRow)}
        </When>
        <When condition={!editItem && selectedItem?.innerCustomFields}>
          {Array.isArray(selectedItem?.innerCustomFields) &&
            selectedItem?.innerCustomFields.map(
              (item, innerIndex) =>
                item?.value && (
                  <Col span={6} key={innerIndex} className="mt-3">
                    <Typography
                      variant="secondary"
                      text={item.label}
                      fontStyle="semibold"
                      fontSize={14}
                    />
                    <Typography
                      variant="secondary"
                      text={item?.valueAsText || item.value}
                      fontSize={14}
                      className="mt-2 text-break"
                    />
                  </Col>
                )
            )}
        </When>
      </Row>
    );
  };

  const handleViewLineItems = () => {
    setViewLineItems(!viewLineItems);
  };

  const generateOCRLineItems = async () => {
    removeLineItems();
    setLoadingLineItems(true);
    if (selectedData?.id) {
      try {
        const res = await getOCRLineItemDetails(selectedData.id);
        automateLineItems(res.line_items);
        showNotification(NOTIFICATION_TYPE.success, OCR_LINE_ITEMS.SUCCES_MSG);
      } catch (error) {
        showNotification(NOTIFICATION_TYPE.error, OCR_LINE_ITEMS.ERROR_MSG);
      } finally {
        setLoadingLineItems(false);
      }
    } else {
      automateLineItems();
      showNotification(NOTIFICATION_TYPE.success, OCR_LINE_ITEMS.SUCCES_MSG);
      setLoadingLineItems(false);
    }
  };

  const toggleEditView = () => {
    setEditItem(false);
  };

  const tableProps = {
    tableClassName: "m-b-0px",
    className: tableClassName,
    columns:
      columns || PURCHASE_ORDER_CREATE_FROM.ITEM_TABLE_COLUMN(forInvoice),
    renderBody,
    data,
    showExpandIcon: !forInvoice,
    onExpand: toggleEditView,
  };

  if (isExpandable) {
    tableProps.renderExpandedDiv = renderExpandedDiv;
  }

  const quantityRule = quantityFieldRequired ? PO_ITEM_FORM_RULE.QUANTITY : [];
  const costRule = costFieldRequired ? PO_ITEM_FORM_RULE.UNIT_COST : [];

  const renderItemForm = (selectedIndex, expandRow) => (
    <>
      <Form
        form={form}
        initialValues={initialState}
        className="item-table-form"
        layout="vertical"
        autoComplete="off"
      >
        <Box justifyContent="spaceBetween" wrap="noWrap">
          <Row className="w-100" gutter={16} align="top">
            <Col className="gutter-row" span={12}>
              <CommonInput
                formItemClassName="m-b-16px"
                label={PURCHASE_ORDER_CREATE_FROM.ITEM_FORM_LABELS.name}
                placeholder={PURCHASE_ORDER_CREATE_FROM.ITEM_FORM_LABELS.name}
                name="name"
                maxLength={355}
                rules={[...PO_ITEM_FORM_RULE.ITEM_NAME]}
              />
            </Col>
            <Col className="gutter-row" span={12}>
              <CommonInputNumber
                label={PURCHASE_ORDER_CREATE_FROM.ITEM_FORM_LABELS.quantity}
                name="quantity"
                type="number"
                placeholder={
                  PURCHASE_ORDER_CREATE_FROM.ITEM_FORM_LABELS.quantity
                }
                rules={quantityRule}
                controls={false}
                min={0}
                precision={2}
              />
            </Col>

            <Col className="gutter-row po-table-listing" span={12}>
              <CommonInputNumber
                label={PURCHASE_ORDER_CREATE_FROM.ITEM_FORM_LABELS.cost}
                placeholder={PURCHASE_ORDER_CREATE_FROM.ITEM_FORM_LABELS.cost}
                name="cost"
                rules={costRule}
                className={"item-list-Po-input"}
                prefix={currencySymbol || ""}
                controls={false}
                min={0}
                precision={2}
              />
            </Col>
            <When condition={customFields?.length}>
              <CustomField
                fields={customFields}
                setFields={setFieldOptions}
                version={version}
                columnSpan={12}
              />
            </When>
          </Row>
        </Box>
        <Box
          justifyContent="flexEnd"
          wrap="nowrap"
          columnGap={16}
          className="w-100"
        >
          <CustomButton
            text={PURCHASE_ORDER_CREATE_FROM.ITEM_FORM_LABELS.saveButton}
            accent={ACCENTS.PRIMARY}
            variant={BUTTON_VARIANT.BUTTON_FILLED}
            onClick={(e) => onSave(e, selectedIndex, expandRow)}
            className="w-20"
          />
          <CustomButton
            text={PURCHASE_ORDER_CREATE_FROM.ITEM_FORM_LABELS.cancelButton}
            accent={ACCENTS.SECONDARY}
            variant={BUTTON_VARIANT.BUTTON_HOLLOW}
            onClick={() => handleClear(expandRow)}
            type="text"
            className="w-20"
          />
        </Box>
      </Form>
    </>
  );

  const handleClose = () => {
    removeLineItems();
    handleViewLineItems();
  };

  const showKlooAIButton = forInvoice === true;

  return (
    <>
      <When condition={showHeader}>
        <Box
          justifyContent="spaceBetween"
          wrap="noWrap"
          className="item-list-cnt px-2 py-2 m-t-16px"
        >
          <div>
            <Box justifyContent="flexStart">
              <Typography
                variant="secondary"
                text={listLabel}
                fontStyle="semibold"
                fontSize={16}
              />
              <When condition={suffixIcon}>
                <Tooltip title={suffixIconTooltip} placement="right">
                  <Icon
                    showIconOutline={true}
                    iconName={suffixIcon}
                    className={suffixIconClassName}
                  />
                </Tooltip>
              </When>
            </Box>
            <When condition={!showKlooAIButton}>
              <Typography variant="body" text={listInfo} fontSize={12} />
            </When>
          </div>
          <Box>
            <CustomButton
              icon={
                <Icon
                  iconName={viewLineItems ? "expand_less" : "expand_more"}
                />
              }
              accent={ACCENTS.SECONDARY}
              variant={BUTTON_VARIANT.BUTTON_TEXT}
              onClick={handleViewLineItems}
              disabled={disabled}
            />
            <When condition={showKlooAIButton}>
              <KlooAIButton
                onClick={generateOCRLineItems}
                btnWithToolTip={true}
                toolTipTitle={OCR_LINE_ITEMS.toolTipTitle(
                  !data?.length ? OCR_LINE_ITEMS.GENERATE : klooAITooltipText
                )}
                disabled={isKlooAIBtnDisabled}
              />
            </When>
            <CustomButton
              icon={<Icon iconName={"close"} />}
              accent={ACCENTS.DESTRUCTIVE}
              variant={BUTTON_VARIANT.BUTTON_TEXT}
              onClick={handleClose}
              disabled={!data?.length}
            />
          </Box>
        </Box>
      </When>
      <When condition={loadingLineItems}>
        <Box className="item-table-card" direction="column" rowGap={4}>
          <KlooAILoader text={OCR_LINE_ITEMS.loaderText} />
        </Box>
      </When>
      <When condition={viewLineItems || itemSwitch}>
        <Box
          className={`item-table-card mb-4 ${itemTableContainerClass}`}
          justifyContent="flexStart"
        >
          <When condition={data?.length}>
            <div className={"item-table pb-3 w-100"}>
              <Table {...tableProps} />
            </div>
          </When>

          <Box
            justifyContent="spaceBetween"
            className="m-h-24px m-v-16px w-100"
          >
            <When condition={isFormActive}>{renderItemForm()}</When>
            <Box
              justifyContent="spaceBetween"
              wrap="nowrap"
              columnGap={16}
              className="w-100"
            >
              <When condition={!isFormActive}>
                <CustomButton
                  text={
                    PURCHASE_ORDER_CREATE_FROM.ITEM_FORM_LABELS.addMoreButton
                  }
                  accent={ACCENTS.SECONDARY}
                  variant={BUTTON_VARIANT.BUTTON_TEXT}
                  icon={<PlusOutlined />}
                  onClick={onAddMoreClick}
                  type="text"
                  disabled={disabled}
                />
              </When>
              <When condition={!!totalAmount && !isFormActive}>
                <Box wrap="nowrap" className="total-amount-width">
                  <Typography
                    variant="body"
                    text={
                      taxCodeFieldEnabled
                        ? PURCHASE_ORDER_CREATE_FROM.ITEM_FORM_LABELS.netAmount
                        : PURCHASE_ORDER_CREATE_FROM.ITEM_FORM_LABELS
                            .totalAmount
                    }
                    className="text-nowrap"
                    fontStyle="semibold"
                  />
                  <Typography
                    variant="body"
                    text={renderFormattedAmount(totalAmount)}
                    ellipsis={{
                      tooltip: renderFormattedAmount(totalAmount),
                    }}
                    className="text-nowrap"
                    fontStyle="semibold"
                  />
                </Box>
              </When>
            </Box>
          </Box>
        </Box>
      </When>
    </>
  );
}

ItemList.propTypes = {
  totalAmount: PropTypes.number,
  onAdd: PropTypes.func,
  data: PropTypes.array,
  showExpandIcon: PropTypes.bool,
};

export default ItemList;
