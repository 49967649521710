import { Form } from "antd";
import Moment from "moment";
import { memo, useState } from "react";
import { updateDOB } from "../../../api";
import { ReactComponent as SuccessCheckMark } from "../../../assets/images/successCheckMark.svg";
import { profileConstants } from "../../../constants/Profile";
import { constructClassName, subtractYear } from "../../../utils";
import { DOB_MODAL_CONSTANT } from "../../../utils/constant";
import {
  NOTIFICATION_TYPE,
  showNotification,
} from "../../../utils/notifications";
import Box from "../Box";
import CustomButton from "../CustomButton";
import CommonDatePicker from "../CommonDatePicker";
import Icon from "../Icon";
import Modal from "../Modal";
import Typography from "../Typography";
import When from "../When";
import "./DOBModal.scss";
import { ACCENTS, BUTTON_VARIANT } from "../../../constants";

function DOBModal({ open, onCancel, title, subTitle, onConfirm }) {
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  function handleConfirmClicked(values) {
    setConfirmClicked(true);
    const date = values.birth_date
      ? Moment(values.birth_date).format("YYYY-MM-DD")
      : undefined;

    updateDOB({ birth_date: date })
      .then(() => {
        setShowSuccessMessage(true);
        setConfirmClicked(false);
      })
      .catch((error) => {
        onCancel();
        showNotification(
          NOTIFICATION_TYPE.error,
          "",
          error?.response?.data?.message
        );
      })
      .finally();
  }
  const { dob } = profileConstants;

  function disableDateHandler(current) {
    if (current > subtractYear({ noOfYear: 16 })) {
      return true;
    } else if (current < subtractYear({ noOfYear: 125 })) {
      return true;
    }
    return false;
  }

  return (
    <Modal
      title={showSuccessMessage ? "" : title}
      isOpen={open}
      showHeader={true}
      getContainer={false}
      showDivider={false}
      hide={showSuccessMessage ? onConfirm : onCancel}
      className={"dob-modal"}
    >
      <When condition={!showSuccessMessage}>
        <Box rowGap={16}>
          <Typography variant="body" text={subTitle} />
          <Form
            name="update_profile"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={handleConfirmClicked}
            autoComplete="off"
            scrollToFirstError
            className={constructClassName(["DOB-modal-form", "w-100"])}
          >
            <Box justifyContent={"flexStart"}>
              <CommonDatePicker
                name={dob.name}
                label={dob.label}
                format={dob.format}
                disabledDate={disableDateHandler}
                suffixIcon={<Icon iconName={dob.suffixIcon} />}
                placeholder={dob.format}
                rules={dob.rules}
                className={"w-60"}
              />
            </Box>
            <Box>
              <CustomButton
                className="m-r-20px"
                accent={ACCENTS.PRIMARY}
                variant={BUTTON_VARIANT.BUTTON_FILLED}
                size="large"
                htmlType="submit"
                loading={confirmClicked}
                text={DOB_MODAL_CONSTANT.confirmButtonLabel}
                customSize={"large"}
              />
              <CustomButton
                accent={ACCENTS.SECONDARY}
                variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                size="large"
                text={DOB_MODAL_CONSTANT.cancelButtonLabel}
                onClick={onCancel}
                customSize={"large"}
              />
            </Box>
          </Form>
        </Box>
      </When>
      <When condition={showSuccessMessage}>
        <Box direction="column" rowGap={24}>
          <SuccessCheckMark />
          <Typography
            variant="body"
            text={DOB_MODAL_CONSTANT?.successMessage}
          />
          <CustomButton
            text={"Close"}
            onClick={onConfirm}
            accent={ACCENTS.SECONDARY}
            variant={BUTTON_VARIANT.BUTTON_HOLLOW}
            customSize={"large"}
          />
        </Box>
      </When>
    </Modal>
  );
}

export default memo(DOBModal);
