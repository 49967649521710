const DATA_SEPARATOR = "-,-";
export const IM_CUSTOM_FIELDS = {
  CHANNEL_NAME: "customFieldsIM",
  REFETCH_CUSTOM_FIELDS_MESSAGE: "triggerCustomFieldsRefetch",
  DATA_SEPARATOR,
  MESSAGES: {
    ADD: "ADD",
    UPDATE: "UPDATE",
    DELETED: "DELETED",
    EDIT: "EDIT",
    FETCH_VALUES: "FETCH_VALUES",
    FETCH_FIELDS: "FETCH_FIELDS",
  },
};

export const PO_GRNI_MATCH_DATA = {
  CHANNEL_NAME: "PO_GRNI_MATCH_DATA",
};
