import { useState } from "react";
import {
  constructClassName,
  getMaxRule,
  scriptTagRegex,
} from "../../../../utils";
import { CommonInput, Empty, Icon, Label } from "../../../common";
import { INVOICE_POPULATED_USING_AI } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { TreeSelect, Form } from "antd";
import CreatableSelect from "react-select/creatable";
import {
  createManageCustomField,
  getCustomFieldValuesList,
} from "../../../../redux/action/setting";

export default function CustomFieldInvoice(props) {
  const {
    customField,
    onHandleTrigger,
    inputChangedFromAiValue,
    dataPopulatedByAI,
    editMode,
    isDisabledOnEdit,
    componentDisabled,
    treeData,
    createSelector,
    setCreateSelector,
    form,
    creatableError,
    setcreatableError,
    newCreatedField,
    mappingDynamic,
    dynamicOptions,
    setDynamicOptions,
  } = props;

  const [size] = useState("large");
  const [dynamicAdd, setDynamicAdd] = useState(null);
  const dispatch = useDispatch();
  const toggle_status = useSelector(
    (state) => state && state.categorisationReducer
  );

  const isCustomExpensesToggleEnabled = toggle_status?.customExpenses
    ? isDisabledOnEdit
    : true;

  const getPredefineList = useSelector(
    (state) =>
      state &&
      state?.CustomCategories &&
      state?.CustomCategories?.getPredefineList
  );

  const enterValueValidation = () => ({
    validator(_, value) {
      if (!value) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
  });

  const onRemoveCreateSelect = (key) => {
    setCreateSelector({ ...createSelector, [key]: "" });
    form.setFieldsValue({ [key]: "" });
    form.validateFields([key]);
  };

  const onInputChange = (newValue) => {
    let newValueWithoutTags = newValue.replace(scriptTagRegex, "");
    setDynamicAdd(newValueWithoutTags);
    return newValueWithoutTags;
  };

  let error = creatableError;
  const validationErrorMsg = (key) => ({
    validator(_, value) {
      if (!value) {
        error = { ...error, [key]: true };
        setcreatableError(error);
        return Promise.reject();
      } else {
        error = { ...error, [key]: false };
        setcreatableError(error);
        return Promise.resolve();
      }
    },
  });

  const onAddDynamic = (record) => {
    const id = record?.id;
    const type = record?.attribute_type;
    let createUrl = `?parent_id=${null}&type=${type}`;
    const values = {
      external_id: "",
      createValueNames: [{ name: dynamicAdd }],
    };
    dispatch(createManageCustomField(id, createUrl, values))
      .then((res) => {
        const newField = res.data.result.find((k) => k.label === dynamicAdd);
        if (record?.field_name == mappingDynamic?.label) {
          newCreatedField.push(...newCreatedField, newField.value);
        }
        onHandleTrigger(record?.field_name, id, type, newField?.value);
        dispatch(
          getCustomFieldValuesList(
            record?.id,
            record?.attribute_type,
            record?.field_name,
            getPredefineList
          )
        )
          .then((res) => {
            if (record?.attribute_type === "dynamic") {
              let convert = JSON.stringify(res?.data?.result)
                ?.replace(/id/g, "value")
                .replace(/name/g, "label");
              let replace = JSON.parse(convert);
              let dynamicOption = {
                ...dynamicOptions,
                [record?.field_name]: replace,
              };
              setDynamicOptions(dynamicOption);
            }
          })
          .catch((err) => {
            let dynamicOption = {
              ...dynamicOptions,
              [record?.field_name]: {},
            };
            setDynamicOptions(dynamicOption);
          });
        setCreateSelector({
          ...createSelector,
          [record?.field_name]: res?.data?.result,
        });
        setcreatableError({ ...creatableError, [record?.field_name]: false });
        form.setFieldsValue({
          [record?.field_name]: {
            label: dynamicAdd,
            value: res?.data?.result?.find?.(({ name }) => name == dynamicAdd)
              ?.value,
          },
        });
        setDynamicAdd(null);
      })
      .catch((err) => {
        setDynamicAdd(null);
      });
  };

  return (
    <>
      {customField.map((field, i) =>
        field?.attribute_type === "textbox" ? (
          <>
            <CommonInput
              key={i}
              name={field?.field_name}
              rules={[
                {
                  required: field?.required === 1 ? true : false,
                  message: `Please enter ${field?.field_name}`,
                },
                {
                  pattern: new RegExp(/^(\s+\S+\s*)*(?!\s).*$/),
                  message: "Please enter a valid text",
                },
                field?.required === 1 && enterValueValidation,
                getMaxRule(255),
              ]}
              autoComplete="off"
              placeholder={field?.field_name}
              size={size}
              onChange={() => {
                onHandleTrigger(field?.field_name);
                inputChangedFromAiValue(
                  "custom_expense_fields",
                  field?.field_name
                );
              }}
              className={`${
                field?.required === 1 ? "free-text-required" : "free-text"
              }`}
              suffixIcon={
                dataPopulatedByAI?.custom_expense_fields?.[field?.field_name]
                  ? "smart_toy"
                  : ""
              }
              suffixIconClassName={"brand-color"}
              suffixIconTooltip={INVOICE_POPULATED_USING_AI}
              disabled={isCustomExpensesToggleEnabled}
              label={field?.field_name}
            />
          </>
        ) : field?.attribute_type === "predefined" ? (
          <>
            <div key={i} className="tree-select-Directory">
              <Form.Item
                label={
                  <Label
                    text={field?.field_name}
                    optional={field?.required === 0}
                    suffixIcon={
                      dataPopulatedByAI?.custom_expense_fields?.[
                        field?.field_name
                      ]
                        ? "smart_toy"
                        : ""
                    }
                    suffixIconClassName={"brand-color"}
                    suffixIconTooltip={INVOICE_POPULATED_USING_AI}
                  />
                }
                className={
                  editMode === "edit" && componentDisabled
                    ? "grayValue disabledError"
                    : ""
                }
                name={field?.field_name}
                rules={[
                  {
                    required: field?.required === 1 ? true : false,
                    message: `Please select ${field?.field_name}`,
                  },
                ]}
              >
                <TreeSelect
                  className={`common-select optional dynamicOption ${
                    field?.required === 1 ? "requiredBorder" : ""
                  } ${
                    editMode === "edit" && componentDisabled
                      ? "treeDisabledError"
                      : ""
                  }`}
                  suffixIcon={<Icon iconName={"arrow_drop_down"} />}
                  getPopupContainer={(trigger) => {
                    return trigger;
                  }}
                  disabled={isDisabledOnEdit}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  onSelect={() => onHandleTrigger(field?.field_name, field?.id)}
                  onChange={() =>
                    inputChangedFromAiValue(
                      "custom_expense_fields",
                      field?.field_name
                    )
                  }
                  placeholder={field?.field_name}
                  allowClear
                  treeDefaultExpandAll={false}
                  treeData={treeData[field?.field_name]}
                  showSearch={true}
                  filterTreeNode={(input, treeNode) =>
                    treeNode.label
                      .toLowerCase()
                      .includes(input.toLowerCase()) ||
                    treeNode.value.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </div>
          </>
        ) : field?.attribute_type === "dynamic" ? (
          <>
            <div
              className={`CreatableSelect-main ${
                editMode === "edit" && componentDisabled
                  ? ""
                  : createSelector && createSelector[field?.field_name]
                  ? "arrow-hovering"
                  : ""
              }`}
            >
              {editMode === "edit" && componentDisabled ? (
                <div className={`CreatableSelect-arrow`}>
                  {" "}
                  <span className="disabled-cancel-icon"></span>
                </div>
              ) : (
                <div className={`CreatableSelect-arrow`}>
                  {createSelector && createSelector[field?.field_name] ? (
                    <span
                      onClick={() => onRemoveCreateSelect(field?.field_name)}
                      className="cancel-icon"
                    ></span>
                  ) : (
                    <span
                      className={`${
                        field?.required === 1 ? "IconsRequired" : "down-arrow"
                      }`}
                    ></span>
                  )}
                </div>
              )}
              <Form.Item
                className={constructClassName([
                  "optional dynamicOption",
                  field?.required === 1 ? "requiredBorder" : "",
                  field?.required === 1 &&
                  Object.keys(creatableError).length &&
                  creatableError[field?.field_name]
                    ? "creatSelectError"
                    : "",
                  editMode === "edit" && componentDisabled
                    ? "disabledError"
                    : "",
                ])}
                name={field?.field_name}
                label={
                  <Label
                    text={field?.field_name}
                    optional={field?.required === 0}
                    suffixIcon={
                      dataPopulatedByAI?.custom_expense_fields?.[
                        field?.field_name
                      ]
                        ? "smart_toy"
                        : ""
                    }
                    suffixIconClassName={"brand-color"}
                    suffixIconTooltip={INVOICE_POPULATED_USING_AI}
                  />
                }
                rules={[
                  {
                    required: field?.required === 1 ? true : false,
                    message: `Please select ${field?.field_name}`,
                  },
                  field?.required === 1
                    ? validationErrorMsg(field?.field_name)
                    : null,
                ]}
              >
                {
                  <CreatableSelect
                    id="dynamic-selector"
                    classNamePrefix={"kloo-dynamic-select"}
                    className={
                      Object.keys(creatableError).length &&
                      creatableError[field?.field_name]
                        ? "creatSelectError"
                        : ""
                    }
                    noOptionsMessage={() => (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        className={"m-0px"}
                      />
                    )}
                    placeholder={field?.field_name}
                    onChange={(newValue) => {
                      onHandleTrigger(
                        field?.field_name,
                        field?.id,
                        field?.attribute_type
                      );
                      setCreateSelector({
                        ...createSelector,
                        [field?.field_name]: newValue,
                      });
                    }}
                    isDisabled={isDisabledOnEdit}
                    onInputChange={(newValue) => {
                      onInputChange(newValue);

                      inputChangedFromAiValue(
                        "custom_expense_fields",
                        field?.field_name
                      );
                    }}
                    onCreateOption={() => {
                      onAddDynamic(field);
                      onHandleTrigger(field?.field_name);
                    }}
                    options={dynamicOptions[field?.field_name]}
                  />
                }
              </Form.Item>
            </div>
          </>
        ) : null
      )}
    </>
  );
}
