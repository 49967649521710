import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import { SET_NOTIFICATION, SET_NOTIFICATIONS } from "../service/types";
import { loadingStart } from "./loader";

export const notificationsData = (data) => ({
  type: SET_NOTIFICATION,
  payload: data,
});
export const notificationsList = (data) => ({
  type: SET_NOTIFICATIONS,
  payload: data,
});
export const getBannerNotifications = () => {
  return new Promise((resolve) => {
    apiClient({
      method: "GET",
      url: PATH.notification.getNotifications,
    }).then((response) => {
      resolve(response.data);
    });
  });
};
