import { memo } from "react";
import {
  REDIRECT_YAPILY_WINDOW_NAME,
  YapilyIntegrationConfig,
} from "../../../../constants/Integration/OpenBanking";
import { openYapilyDesktopLogin } from "../../../../helper";
import {
  Box,
  Button,
  ComponentLoader,
  CustomButton,
  Typography,
  When,
} from "../../../common";
import { ACCENTS, BUTTON_VARIANT } from "../../../../constants";

const { consentJourneyStepsConfig } = YapilyIntegrationConfig;

function YapilyConsentConfirmation({ stepData, loaderText }) {
  const { stepKey: AUTHENTICATION } = consentJourneyStepsConfig[1];
  const { buttonText } = consentJourneyStepsConfig[2];

  function handleRelaunchLogin() {
    return openYapilyDesktopLogin(
      stepData[AUTHENTICATION].authorisationUrl,
      REDIRECT_YAPILY_WINDOW_NAME
    );
  }

  return (
    <Box
      direction={"column"}
      justifyContent={"flexEnd"}
      wrap={"noWrap"}
      className="yapily-relaunch-login"
    >
      <ComponentLoader />
      <When condition={loaderText && loaderText !== ""}>
        <Typography variant={"body"} text={loaderText} />
      </When>
      <CustomButton
        text={buttonText}
        accent={ACCENTS.PRIMARY}
        variant={BUTTON_VARIANT.BUTTON_FILLED}
        onClick={handleRelaunchLogin}
      />
    </Box>
  );
}

export default memo(YapilyConsentConfirmation);
