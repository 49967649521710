import { CURRENCY_LIST } from "../service/types";

const initialState = {
  currencyList: {},
};
export default function currency(state = initialState, action) {
  switch (action.type) {
    case CURRENCY_LIST:
      return { ...state, currencyList: action.payload };
    default:
      return state;
  }
}
