import moment from "moment";
import {
  getItem,
  isDevelopmentEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
  LOCAL_STORAGE_KEY,
} from "../../utils";
import { featureFlags } from "../../constants";
import {
  ERP_CONNECTION_COMPANY_NAME_CONFIG,
  ERP_INTEGRATIONS_PAGE_CONFIG,
  ITG_ORG_ID,
} from "../../constants/Integration/OpenBanking";
import XeroLogo from "../../assets/images/logo-xero.png";
import SageIntacctLogo from "../../assets/images/SageIntacctLogoWithBorder.png";

export function openYapilyDesktopLogin(url, name = "_blank") {
  window.open(url, name);
}

export function getDateIntoYapilyConsentFormat(date) {
  return moment(date).format("Do MMM YYYY");
}

export function connectedErpLogo(connectedErpPlatform) {
  switch (connectedErpPlatform) {
    case ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.xero.key:
    case ERP_CONNECTION_COMPANY_NAME_CONFIG.Xero.keyToCheckLogo:
      return XeroLogo;
    case ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.sageIntacct
      .keyToCheckLogo:
    case ERP_CONNECTION_COMPANY_NAME_CONFIG["Sage Intacct"].keyToCheckLogo:
      return SageIntacctLogo;
    default:
      return null;
  }
}

export function isSageBusinessCloudEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.settings.integrations.sageBusinessCloud.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.settings.integrations.sageBusinessCloud.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.settings.integrations.sageBusinessCloud.enableForProd)
  );
}

export function isDisconnectModalEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.settings.integrations.disconnectModal.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.settings.integrations.disconnectModal.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.settings.integrations.disconnectModal.enableForProd)
  );
}

export function isWorkflowAmountHidden() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.settings.integrations.isWorkflowAmountHidden_11768
        .enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.settings.integrations.isWorkflowAmountHidden_11768
        .enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.settings.integrations.isWorkflowAmountHidden_11768
        .enableForProd)
  );
}

export function isITGOrg() {
  return getItem(LOCAL_STORAGE_KEY.ORD_ID) === ITG_ORG_ID;
}
