import { lazy } from "react";
import { NAVIGATION_URL, PERMISSIONS } from "../constants";

const Login = lazy(() => import("../pages/Login"));
const Registration = lazy(() =>
  import("../components/auth/Registration/index")
);
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Funds = lazy(() => import("../components/Funds/index"));
const Cards = lazy(() => import("../components/Cards/index"));
const NotFound = lazy(() =>
  import("../components/common/DefaultPage/NotFound")
);
const Trial1 = lazy(() => import("../components/auth/Trial1"));
const AboutUs = lazy(() => import("../components/About/AboutUs"));
const ContactUs = lazy(() => import("../components/ContactUs/ContactUs"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const ChangePassword = lazy(() =>
  import("../components/auth/ChangePassword/index")
);
const ManageUser = lazy(() => import("../components/ManageUser/index"));
const Teams = lazy(() => import("../components/Teams/index"));
const Transaction = lazy(() => import("../components/Transaction/index"));
const CardRequests = lazy(() => import("../pages/CardRequests"));
const Address = lazy(() => import("../components/Address/address"));
const Profile = lazy(() => import("../components/Profile/index"));
const Xero = lazy(() => import("../components/Xero-Account"));
const YapilyConsentJourney = lazy(() =>
  import(
    "../components/OpenBanking/YapilyAccount/ConsentJourney/YapilyConsentJourney"
  )
);
const YapilyLandingPage = lazy(() =>
  import("../components/OpenBanking/YapilyAccount/YapilyLandingPage")
);
const InvoiceManagement = lazy(() => import("../pages/InvoiceManagement"));
const SplitInvoice = lazy(() => import("../../src/pages/InvoiceSplit"));
const SingleMatchingInvoice = lazy(() =>
  import("../../src/pages/InvoiceSingleMatching")
);

const CardExpenses = lazy(() => import("../pages/CardExpenses"));

const Settings = lazy(() => import("../pages/Settings/SmartApprovals"));
const SupplierManagement = lazy(() => import("../pages/SupplierManagement"));
const Categorisation = lazy(() => import("../components/Categorisation"));
const PurchaseOrder = lazy(() => import("../pages/PurchaseOrder"));
const CreditNotes = lazy(() => import("../pages/CreditNotes"));
const LinkInvoiceToCreditNotes = lazy(() =>
  import("../pages/CreditNotesInvoiceAllocation")
);
const InvoiceMatching = lazy(() => import("../pages/InvoiceMatching"));
const Acknowledgement = lazy(() => import("../pages/Acknowledgement"));
const PaymentRuns = lazy(() => import("../pages/PaymentRuns"));
const Configurations = lazy(() => import("../pages/Settings/Configurations"));
const CustomConditionalExpense = lazy(() =>
  import("../pages/CustomConditionalExpense")
);

const ERPContactCreation = lazy(() =>
  import("../pages/Settings/ERPContactCreation")
);

export const routes = [
  {
    path: "/",
    element: Login,
    isPrivate: false,
    exact: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.LOGIN,
    element: Login,
    isPrivate: false,
    exact: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.ANALYTICS_REGISTRATION,
    element: Registration,
    isPrivate: false,
    exact: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.REGISTRATION,
    element: Registration,
    isPrivate: false,
    exact: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.ACKNOWLEDGEMENT,
    element: Acknowledgement,
    isPrivate: false,
    exact: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.DASHBOARD,
    element: Dashboard,
    isPrivate: true,
    exact: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.FUNDS,
    element: Funds,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.FUNDS.key,
  },
  {
    path: NAVIGATION_URL.CARDS,
    element: Cards,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.CARDS.key,
  },
  {
    path: NAVIGATION_URL.SET_PASSWORD,
    element: Trial1,
    isPrivate: true,
    exact: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.ABOUT_US,
    element: AboutUs,
    isPrivate: false,
    exact: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.CONTACT,
    element: ContactUs,
    isPrivate: false,
    exact: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.FORGOT_PASSWORD,
    element: ForgotPassword,
    isPrivate: false,
    exact: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.CHANGE_PASSWORD,
    element: ChangePassword,
    isPrivate: true,
    exact: true,
    isLayout: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.USERS,
    element: ManageUser,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.USERS.key,
  },
  {
    path: NAVIGATION_URL.TEAMS,
    element: Teams,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.TEAMS.key,
  },
  {
    path: NAVIGATION_URL.TRANSACTION,
    element: Transaction,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.TRANSACTIONS.key,
  },
  {
    path: NAVIGATION_URL.APPROVALS,
    element: CardRequests,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.CARD_REQUEST.key,
  },
  {
    path: NAVIGATION_URL.ADDRESS,
    element: Address,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.SETTING.key,
    viewSubLabel: PERMISSIONS.SETTING.ADDRESS.read,
  },
  {
    path: NAVIGATION_URL.PROFILE,
    element: Profile,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.PROFILE.key,
  },
  {
    path: NAVIGATION_URL.SPLIT_INVOICE,
    element: SplitInvoice,
    isPrivate: true,
    exact: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.SINGLE_MATCHING_INVOICE,
    element: SingleMatchingInvoice,
    isPrivate: true,
    exact: true,
    viewLabel: true,
  },
  {
    path: NAVIGATION_URL.INVOICE_MANAGEMENT,
    element: InvoiceManagement,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.INVOICE_MANAGEMENT.key,
  },
  {
    path: NAVIGATION_URL.PAYMENT_RUNS,
    element: PaymentRuns,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.PAYMENT_RUNS.key,
  },
  {
    path: NAVIGATION_URL.SMART_APPROVALS("*"),
    element: Settings,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.SETTING.key,
    viewSubLabel: PERMISSIONS.SETTING.SMART_APPROVAL.read,
  },
  {
    path: NAVIGATION_URL.SUPPLIER_MANAGEMENT,
    element: SupplierManagement,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.SUPPLIER_MANAGEMENT.key,
    viewSubLabel: PERMISSIONS.SUPPLIER_MANAGEMENT.read,
  },
  {
    path: NAVIGATION_URL.CATEGORISATION,
    element: Categorisation,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.SETTING.key,
    viewSubLabel: PERMISSIONS.SETTING.CATEGORISATION.read,
  },
  {
    path: NAVIGATION_URL.MANAGE_CCE_CREATE,
    element: CustomConditionalExpense,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.SETTING.key,
    viewSubLabel: PERMISSIONS.SETTING.CATEGORISATION.CUSTOM_EXPENSE_FIELD.read,
  },
  {
    path: NAVIGATION_URL.MANAGE_CCE_EDIT,
    element: CustomConditionalExpense,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.SETTING.key,
    viewSubLabel: PERMISSIONS.SETTING.CATEGORISATION.CUSTOM_EXPENSE_FIELD.read,
  },
  {
    path: NAVIGATION_URL.CONFIGURATIONS,
    element: Configurations,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.SETTING.key,
    viewSubLabel: PERMISSIONS.SETTING.CONFIGURATION.read,
  },
  {
    path: NAVIGATION_URL.CARD_EXPENSES,
    element: CardExpenses,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.CARD_EXPENSES.key,
  },
  {
    path: NAVIGATION_URL.INTEGRATIONS,
    element: Xero,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.SETTING.key,
    viewSubLabel: PERMISSIONS.SETTING.INTEGRATION.read,
  },
  {
    path: NAVIGATION_URL.YAPILY_CONSENT_JOURNEY,
    element: YapilyConsentJourney,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.SETTING.key,
    viewSubLabel: PERMISSIONS.SETTING.INTEGRATION.read,
  },
  {
    path: NAVIGATION_URL.YAPILY_LANDING_PAGE,
    element: YapilyLandingPage,
    isPrivate: false,
    exact: true,
    viewLabel: PERMISSIONS.SETTING.key,
    viewSubLabel: PERMISSIONS.SETTING.INTEGRATION.read,
  },
  {
    path: NAVIGATION_URL.PO_MANAGEMENT,
    element: PurchaseOrder,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.PO.key,
  },
  {
    path: NAVIGATION_URL.CREDIT_NOTES,
    element: CreditNotes,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.CREDIT_NOTES.key,
  },
  {
    path: NAVIGATION_URL.CREDIT_NOTES_LINK_INVOICE,
    element: LinkInvoiceToCreditNotes,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.CREDIT_NOTES.key,
  },
  {
    path: NAVIGATION_URL.INVOICE_MATCHING,
    element: InvoiceMatching,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.INVOICE_MATCHING.key,
  },
  {
    path: NAVIGATION_URL.ERP_CONTACT_CREATION,
    element: ERPContactCreation,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSIONS.SETTING.key,
    viewSubLabel: PERMISSIONS.SETTING.INTEGRATION.read,
  },
  {
    path: "*",
    element: NotFound,
    isPrivate: false,
    exact: false,
    viewLabel: true,
  },
];
