import { Drawer } from "../../common";

function SkeletonDrawer(props) {
  const { drawerTitle, onClose, drawerWidth, drawerContent, bodyStyle } = props;
  return (
    <Drawer
      open={true}
      title={drawerTitle}
      width={drawerWidth}
      onClose={onClose}
      bodyStyle={bodyStyle}
    >
      {drawerContent}
    </Drawer>
  );
}

export default SkeletonDrawer;
