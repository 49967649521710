import React, { useEffect, useState } from "react";
import CommonToolTip from "../Tooltip";
import { Icon } from "..";

export default function CopyToClipboard({ text }) {
  const [copied, setCopied] = useState(false);
  let timeoutId;

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
    });
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <CommonToolTip
      title={copied ? "Copied!" : "Copy"}
      getPopupContainer={(trigger) => {
        return trigger.parentNode;
      }}
    >
      {copied ? (
        <Icon className={"cursor-pointer"} iconName={"done"} />
      ) : (
        <Icon
          className={"cursor-pointer"}
          iconName={"content_copy"}
          onClick={copyToClipboard}
        />
      )}
    </CommonToolTip>
  );
}
