import {
  CUSTOM_GET_EXPENSES,
  DELETE_CUSTOM_FIELD_OPTION,
  Edit_CUSTOM_FIELD_NAME,
  GET_ACCOUNT_CUSTOM,
  GET_PREDEFINE_LIST,
  UPDATE_CUSTOM_FIELD,
} from "../service/types";

const initialState = {
  customGetExpenses: [],
  customExpenseField: [],
  getAccountCustom: [],
  getPredefineList: [],
};
export default function CustomCategories(state = initialState, action) {
  switch (action.type) {
    case CUSTOM_GET_EXPENSES:
      return { ...state, customGetExpenses: action.payload };
    case GET_PREDEFINE_LIST:
      return { ...state, getPredefineList: action.payload };
    case GET_ACCOUNT_CUSTOM:
      return { ...state, getAccountCustom: action.payload };
    case UPDATE_CUSTOM_FIELD: {
      const { type, name, data } = action.payload;
      const updatedPreDefinedList = {
        ...state.getPredefineList,
        [type]: {
          ...state.getPredefineList[type],
          [name]: data,
        },
      };
      return { ...state, getPredefineList: updatedPreDefinedList };
    }
    case DELETE_CUSTOM_FIELD_OPTION: {
      const { type, name } = action.payload;
      if (!state.getPredefineList[type]) {
        return state;
      }
      const { [name]: deletedKey, ...updatedList } =
        state.getPredefineList[type];
      const updatedPreDefinedList = {
        ...state.getPredefineList,
        [type]: updatedList,
      };
      return { ...state, getPredefineList: updatedPreDefinedList };
    }
    case Edit_CUSTOM_FIELD_NAME: {
      const { type } = action.payload;
      const listToUpdate = { ...state.getPredefineList[type] };
      const customFields = state.customGetExpenses;
      let keyToDelete = "";
      for (const key in listToUpdate) {
        const ifFieldExist = customFields.find(
          (item) => item.field_name === key
        );
        if (!ifFieldExist) {
          keyToDelete = key;
          break;
        }
      }
      const { [keyToDelete]: deletedKey, ...updatedList } = listToUpdate;
      const updatedPreDefinedList = {
        ...state.getPredefineList,
        [type]: updatedList,
      };
      return { ...state, getPredefineList: updatedPreDefinedList };
    }
    default:
      return state;
  }
}
