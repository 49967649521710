import moment from "moment";

export const DEFAULT_FORMAT = "DD/MM/YYYY";
export const PAYLOAD_DEFAULT_FORMAT = "YYYY-MM-DD";
export const SCHEDULED_DATE_FORMAT_FOR_YAPILY = "YYYY-MM-DD HH:mm:ss";
export const DEFAULT_FORMAT_WITH_TIME = "DD/MM/YYYY HH:mm";
export const DEFAULT_FORMAT_WITH_TIME_AM_AND_PM = "DD/MM/YYYY HH:mm a";

export function getMoment(date) {
  return moment(date);
}

export function getFormattedDate(date, format, fallback = "--/--") {
  return date ? moment(date).format(format) : fallback;
}

export function getFormattedDateInUTC(date, format, fallback = "--/--") {
  return date ? moment(date).utc().format(format) : fallback;
}

export function getFormattedMoment(
  date,
  format = "DD/MM/YYYY",
  fallback = "--/--"
) {
  return date ? moment(date, format) : fallback;
}

export const addDays = ({ date, noOfDays }) => {
  const dateInstance = date ? moment(date) : moment();
  return dateInstance.add(noOfDays, "days");
};

export const subtractDays = ({ date, noOfDays }) => {
  const dateInstance = date ? moment(date) : moment();
  return dateInstance.subtract(noOfDays, "days");
};
export const subtractYear = ({ date, noOfYear }) => {
  const dateInstance = date ? moment(date) : moment();
  return dateInstance.subtract(noOfYear, "year");
};
// get day, month, year any date
export const getDateChunks = (date, format = "") => {
  const dateInstance = date ? moment(date, format) : moment();
  return {
    day: dateInstance.date(),
    month: dateInstance.month(),
    year: dateInstance.year(),
  };
};

// total days in month
export const getDays = (year, month) => new Date(year, month, 0).getDate();

export const checkIfDateIsAWeekend = (date) => {
  const currentDateId = moment(date).weekday();
  return currentDateId === 0 || currentDateId === 6;
};

export const getTodaysDate = (format) => {
  return getFormattedDate(moment().utc().toDate(), format);
};

export const getDateAndTimeInTimeForOpenBankingYapilyPayment = (date) => {
  return date
    ? moment(date)
        .set({
          hour: 8,
          minute: 0,
          second: 0,
        })
        .format(SCHEDULED_DATE_FORMAT_FOR_YAPILY)
    : false;
};

export const dateBefore = (differentDate, constDate) => {
  return !moment(differentDate).isBefore(moment(constDate, "DD/MM/YYYY")); // createdAt on 27/06/2023 or before
};

export function disablePreviousDates(current) {
  return current && current < moment().subtract(1, "day");
}

export function disableUpcomingDates(current, date) {
  return !!date && current && current.isAfter(date, "day");
}

export function disableOlderDates(current, date) {
  return !!date && current && current.isBefore(date, "day");
}

export function disableDateOfBirthInput(current) {
  if (current > subtractYear({ noOfYear: 16 })) {
    return true;
  } else if (current < subtractYear({ noOfYear: 125 })) {
    return true;
  }
}

export function getDateAndTimeForApproval(dateString) {
  const dateNTime = moment(dateString, SCHEDULED_DATE_FORMAT_FOR_YAPILY).format(
    DEFAULT_FORMAT_WITH_TIME
  );
  return dateNTime.replace(" ", " at ");
}

export function getDateAndTimeForLastSync(dateString) {
  const dateNTime = moment(dateString, SCHEDULED_DATE_FORMAT_FOR_YAPILY).format(
    DEFAULT_FORMAT_WITH_TIME_AM_AND_PM
  );
  return dateNTime.replace(" ", " at ");
}
