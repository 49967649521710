import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import { getItem, LOCAL_STORAGE_KEY } from "../utils";

export async function getCustomFieldVisibility(pageName) {
  const response = await apiClient({
    url: PATH.categorisation.getCategorisationToggleData(
      pageName,
      getItem(LOCAL_STORAGE_KEY.ORD_ID)
    ),
    method: "GET",
  });
  return response?.data?.result;
}

export async function getVatFieldDetails(pageName) {
  const tempResponse = await apiClient({
    url: PATH.categorisation.getVatFieldDetails(pageName),
    method: "GET",
  });
  return tempResponse?.data?.result;
}

export async function updateVATField(data) {
  const tempResponse = await apiClient({
    url: PATH.categorisation.updateVatFieldDetails,
    method: "PUT",
    data: data,
  });
  return tempResponse?.data?.result;
}

export async function customFieldOptionsList(activeKey) {
  const tempResponse = await apiClient({
    url: PATH.categorisation.customFieldsOptions(activeKey),
    method: "GET",
  });
  return tempResponse?.data?.result;
}

export async function getFormFieldsForCategorisation(pageName) {
  const tempResponse = await apiClient({
    url: PATH.categorisation.getFieldDetails(pageName),
    method: "GET",
  });
  return tempResponse?.data?.result;
}

export async function updateFormField(data) {
  const tempResponse = await apiClient({
    url: PATH.categorisation.updateFieldDetails,
    method: "PUT",
    data,
  });
  return tempResponse?.data?.result;
}

export async function getERPCategorisation() {
  const tempResponse = await apiClient({
    url: PATH.categorisation.getERPCategorisation,
    method: "GET",
  });
  return tempResponse?.data?.result;
}
