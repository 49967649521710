import React from "react";
import { Navigate } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import "../assets/scss/Layout.scss";
import { isKlooValidUser, isRedirectAfterLoginEabled } from "../helper";
import { NAVIGATION_URL, REDIRECTION_CONST } from "../constants";

const PrivateRoute = ({ isLayout = true, children, route }) => {
  const validUser = isKlooValidUser(route?.path);

  const isRedirectionRequired =
    isRedirectAfterLoginEabled() &&
    window.location.pathname !== NAVIGATION_URL.LOGIN;
  let state;

  return validUser ? (
    isLayout ? (
        <ErrorBoundary>{children}</ErrorBoundary>
    ) : (
      children
    )
  ) : (
    <Navigate
      to={`/login${
        isRedirectionRequired
          ? `?${REDIRECTION_CONST.redirectTo}=${window.location.pathname}${window.location.search}`
          : ""
      }`}
      state={state}
    />
  );
};

export default PrivateRoute;
