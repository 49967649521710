import { Image as AImage } from "antd";
import { memo } from "react";
import { constructClassName } from "../../../utils/functions";

function Image({
  className,
  rootClassName,
  preview = false,
  width,
  height,
  src,
  ...rest
}) {
  return (
    <AImage
      rootClassName={constructClassName(["kloo-root-image", rootClassName])}
      className={constructClassName(["kloo-image", className])}
      preview={preview}
      width={width}
      height={height}
      src={src}
      {...rest}
    />
  );
}

export default memo(Image);
