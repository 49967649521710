const PAGE_HEADER_INFO_CARDS_COMMONS = {
  desc: "The cards page allows users to issue, request, and manage the cards currently issued across an organisation. Organisation Admins and Finance Approvers can see all cards, whilst Platform Users see only the cards where they are a cardholder.",
  header: "Card management",
  buttonIcon: "add_card",
};

export const PAGE_HEADER_INFO_CARDS = {
  OA: {
    ...PAGE_HEADER_INFO_CARDS_COMMONS,
    buttonLabel: "Issue new card",
    buttonLabelMobile: "Issue new card",
  },
  PU: {
    ...PAGE_HEADER_INFO_CARDS_COMMONS,
    buttonLabel: "New card request",
    buttonLabelMobile: "New card request",
  },
};

export const CARDS_TABLE_COLUMNS_STATUS_KEY = "status";

export const CARDS_DEFAULT_ROW_HEIGHT = 48;
export const CARDS_DEFAULT_PAGE_SIZE = 10;

export const CARDS_TABLE_COLUMNS = {
  status: {
    label: "Status",
    key: CARDS_TABLE_COLUMNS_STATUS_KEY,
    ellipsis: false,
  },
  type: { label: "Type", key: "card_type" },
  cardholder: { label: "Cardholder", key: "cardholder" },
  cardNickname: { label: "Card nickname	", key: "nickname" },
  monthlySpend: { label: "Monthly spend	", key: "spent" },
  monthlyBalance: { label: "Monthly balance", key: "balance" },
};

export const CARDS_TABLE_COLUMNS_ALL_TABS = [
  { ...CARDS_TABLE_COLUMNS.status, width: 10, align: "center" },
  { ...CARDS_TABLE_COLUMNS.type, width: 15 },
  { ...CARDS_TABLE_COLUMNS.cardholder, width: 20 },
  { ...CARDS_TABLE_COLUMNS.cardNickname, width: 25 },
  { ...CARDS_TABLE_COLUMNS.monthlySpend, width: 15, align: "right" },
  { ...CARDS_TABLE_COLUMNS.monthlyBalance, width: 15, align: "right" },
];

export const CARDS_TABS_KEYS = {
  ALL_CARDS: "all_cards",
  MY_CARDS: "my_cards",
};

export const CARDS_TABS = {
  ALL_CARDS: {
    key: CARDS_TABS_KEYS.ALL_CARDS,
    path: "/cards/all_cards",
    label: "All cards",
  },
  MY_CARDS: {
    key: CARDS_TABS_KEYS.MY_CARDS,
    path: "/cards/my_cards",
    label: "My cards",
  },
};
