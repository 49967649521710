import { MATCH_TYPE, MATCH_TYPE_LABEL } from "../../../constants";
import { Box, Icon, Tooltip, When } from "../../common";

function InvoiceMatchType({ matchType }) {
  return (
    <Box justifyContent="center">
      <When condition={matchType === MATCH_TYPE.CONFIRMED}>
        <Tooltip title={MATCH_TYPE_LABEL.CONFIRMED}>
          <Icon iconName={"check_circle"} className={"green-color"} />
        </Tooltip>
      </When>
      <When condition={matchType === MATCH_TYPE.SUGGESTED}>
        <Tooltip title={MATCH_TYPE_LABEL.SUGGESTED}>
          <Icon
            showIconOutline={true}
            iconName={"smart_toy"}
            className={"primary-color"}
          />
        </Tooltip>
      </When>
      <When condition={matchType === MATCH_TYPE.PARTIAL}>
        <Tooltip title={MATCH_TYPE_LABEL.PARTIAL}>
          <Icon
            showIconOutline={true}
            iconName={"contrast"}
            className={"primary-color"}
          />
        </Tooltip>
      </When>
      <When condition={matchType === MATCH_TYPE.NOT_A_MATCH}>
        <Tooltip title={MATCH_TYPE_LABEL.NOT_A_MATCH}>
          <Icon iconName={"cancel"} className={"red-color"} />
        </Tooltip>
      </When>
    </Box>
  );
}

export default InvoiceMatchType;
