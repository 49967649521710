import React, { memo } from "react";
import PropTypes from "prop-types";
import { Modal as AModal } from "antd";
import "./modal.scss";
import When from "../When";
import Typography from "../Typography";

function Modal({
  isOpen,
  width,
  hide,
  title,
  showHeader = true,
  subtitle,
  destroyOnClose = true,
  children,
  showDivider = true,
  showCloseIcon = true,
  className,
  wrapClassName,
  getContainer,
  maskClosable = false,
  footer = null,
  zIndex,
  closable,
}) {
  function Header() {
    return (
      <div className="modal-header">
        <div className="modal-title-close-button">
          {title && (
            <Typography
              variant={"title"}
              className="w-100"
              fontSize={24}
              responsiveFontSize={false}
              text={title}
            />
          )}

          <When condition={showCloseIcon && !closable}>
            <span onClick={hide} className="material-icons">
              close
            </span>
          </When>
        </div>
        {subtitle && <p>{subtitle}</p>}
        <When condition={showDivider}>
          <hr />
        </When>
      </div>
    );
  }

  const handleClose = (e) => {
    if (e.keyCode === 27) {
      return;
    }
    hide && hide();
  };

  return (
    <AModal
      centered
      open={isOpen}
      onCancel={handleClose}
      closable={closable}
      width={width}
      footer={footer}
      destroyOnClose={destroyOnClose}
      className={className}
      wrapClassName={wrapClassName}
      getContainer={getContainer}
      maskClosable={maskClosable}
      zIndex={zIndex}
    >
      {showHeader && <Header />}
      {children}
    </AModal>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  width: PropTypes.number,
  hide: PropTypes.func,
  title: PropTypes.string,
  showHeader: PropTypes.bool,
  subtitle: PropTypes.string,
  destroyOnClose: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  closable: PropTypes.bool,
};

Modal.defaultProps = {
  showHeader: true,
  destroyOnClose: true,
  closable: false,
  // width: 1000,
};

export default memo(Modal);
