function AuthenticateIcon() {
  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M2225 5114 c-135 -13 -297 -41 -410 -69 -847 -213 -1527 -893 -1740
-1740 -55 -219 -69 -342 -69 -595 0 -253 14 -376 69 -595 170 -676 642 -1256
1270 -1565 175 -86 296 -130 472 -175 226 -56 338 -69 593 -69 249 1 417 19
451 50 26 23 25 78 -2 105 -24 24 -13 24 -259 -1 -291 -30 -658 18 -955 124
-307 111 -643 328 -862 559 -456 480 -682 1125 -623 1772 54 583 345 1143 790
1518 352 296 803 485 1255 527 932 85 1824 -419 2235 -1264 106 -217 166 -410
206 -661 22 -136 25 -485 6 -620 -12 -84 -60 -316 -67 -323 -2 -2 -45 17 -97
42 -294 143 -628 152 -929 27 -112 -46 -221 -118 -343 -226 -158 -138 -305
-204 -457 -205 -90 0 -132 -75 -74 -130 23 -21 30 -23 107 -17 46 3 107 12
136 20 28 8 52 13 52 12 0 -1 -13 -33 -28 -71 l-29 -69 -79 -13 c-119 -19
-368 -10 -464 18 -139 40 -203 81 -330 211 -64 65 -128 123 -144 129 -30 11
-41 9 -166 -35 -49 -17 -107 -28 -165 -32 -75 -5 -93 -10 -112 -29 -30 -30
-29 -69 2 -99 20 -21 34 -25 84 -25 75 0 190 22 259 49 29 12 54 21 56 21 1 0
25 -28 52 -62 105 -131 266 -232 449 -279 81 -22 112 -24 300 -24 l210 0 1
-175 c0 -155 4 -186 26 -271 60 -222 142 -364 302 -525 161 -160 303 -242 525
-302 85 -22 115 -25 266 -25 151 0 181 3 266 25 125 34 226 77 330 140 114 70
287 243 357 357 206 337 227 737 56 1090 l-45 93 80 83 c90 92 99 118 56 160
-53 54 -34 71 -630 -525 l-550 -550 -235 235 c-204 203 -240 235 -266 235 -41
0 -74 -36 -74 -80 0 -31 23 -57 268 -302 247 -247 270 -268 302 -268 33 0 65
30 486 451 l450 450 26 -53 c126 -258 128 -572 4 -834 -54 -114 -90 -167 -174
-255 -89 -95 -169 -153 -287 -210 -147 -71 -248 -93 -420 -93 -169 0 -271 22
-411 88 -114 54 -167 90 -255 174 -95 89 -153 169 -210 287 -71 147 -93 248
-93 420 0 172 22 273 93 420 57 118 115 198 210 287 88 84 141 120 255 174
312 147 674 118 967 -80 96 -64 126 -47 169 99 30 102 59 238 82 380 20 126
16 504 -6 650 -84 544 -329 1018 -725 1397 -362 347 -817 570 -1311 643 -109
16 -445 28 -535 19z"
          />
          <path
            d="M2243 4355 c-381 -69 -678 -348 -818 -768 -74 -222 -95 -402 -95
-804 l0 -284 25 -24 c30 -31 69 -32 99 -2 l24 24 5 354 c6 374 11 424 62 616
53 193 138 352 260 481 118 125 239 200 392 244 111 32 314 32 426 0 100 -29
121 -28 146 4 41 53 23 94 -54 123 -127 48 -325 63 -472 36z"
          />
          <path
            d="M2906 4168 c-24 -22 -30 -36 -27 -68 0 -9 49 -65 109 -126 182 -187
271 -373 329 -694 25 -139 25 -460 -1 -595 -32 -169 -90 -319 -165 -429 -51
-74 -155 -164 -228 -198 -57 -27 -83 -33 -158 -36 -74 -3 -100 0 -148 18 -143
54 -237 183 -266 368 -7 49 -1 67 15 41 3 -6 30 -22 58 -35 62 -29 168 -32
239 -8 140 48 230 213 246 454 10 159 1 272 -23 299 -28 30 -79 28 -105 -4
-18 -23 -20 -41 -20 -183 0 -304 -64 -432 -215 -432 -57 0 -61 2 -102 44 -87
91 -122 297 -93 552 11 105 38 205 56 211 15 5 48 -87 62 -174 6 -39 11 -140
11 -222 0 -138 2 -153 20 -171 30 -30 83 -27 109 6 19 24 21 40 21 183 -1 363
-94 565 -246 536 -47 -8 -107 -64 -132 -123 -50 -115 -57 -190 -57 -597 0
-436 5 -472 80 -624 95 -189 260 -291 470 -291 353 0 635 312 720 795 23 129
30 348 16 495 -39 402 -165 704 -390 927 -106 106 -140 121 -185 81z"
          />
          <path
            d="M2232 4060 c-144 -38 -294 -139 -387 -261 -61 -81 -127 -219 -160
-333 -21 -75 -23 -92 -12 -114 15 -33 57 -49 94 -36 24 8 33 24 68 129 47 139
81 204 141 276 119 139 264 209 434 209 170 0 315 -70 434 -210 123 -144 192
-375 203 -681 9 -243 -21 -412 -99 -562 -65 -124 -165 -197 -270 -197 -68 0
-92 -91 -35 -134 25 -19 32 -19 97 -8 214 37 360 214 433 526 29 125 31 479 3
631 -64 351 -212 586 -447 710 -142 74 -339 96 -497 55z"
          />
          <path
            d="M2342 3789 c-168 -28 -306 -167 -375 -379 -48 -144 -58 -240 -63
-595 -6 -362 -13 -426 -64 -533 -16 -35 -53 -88 -82 -118 -83 -88 -164 -128
-292 -144 -80 -10 -109 -32 -109 -81 0 -55 33 -72 128 -66 156 9 324 101 419
228 38 51 47 58 53 42 92 -251 210 -390 415 -488 40 -19 86 -35 103 -35 58 0
95 69 62 114 -7 10 -42 30 -78 45 -242 102 -372 318 -400 664 -15 183 -6 648
15 772 42 247 140 393 287 424 135 29 273 -68 335 -236 24 -66 50 -93 90 -93
34 0 74 37 74 69 0 33 -53 150 -99 217 -100 147 -258 220 -419 193z"
          />
          <path
            d="M1649 3161 l-24 -19 -6 -329 c-5 -307 -7 -332 -27 -384 -29 -74 -99
-139 -149 -139 -92 0 -144 -73 -92 -129 19 -21 29 -23 88 -19 147 8 257 108
309 281 12 41 16 124 19 384 5 325 4 332 -16 352 -26 26 -71 27 -102 2z"
          />
          <path
            d="M1615 1495 c-40 -39 -33 -69 36 -140 170 -177 418 -286 689 -302 88
-5 97 -4 118 16 29 28 30 83 2 109 -16 15 -42 20 -113 25 -168 11 -346 71
-473 158 -23 16 -74 58 -113 94 -79 73 -107 80 -146 40z"
          />
        </g>
      </svg>
    </>
  );
}
export default AuthenticateIcon;
