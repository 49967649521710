import { MODULAR_ACCOUNT_DETAILS, ORGANISATION_AMOUNT } from "../service/types";

const initialValue = {
  accountDetail: {},
  organisationAmount: "",
};
export const modular = (state = initialValue, action) => {
  switch (action.type) {
    case MODULAR_ACCOUNT_DETAILS:
      return { ...state, accountDetail: action.payload };
    case ORGANISATION_AMOUNT:
      return { ...state, organisationAmount: action.payload };
    default:
      return state;
  }
};
