import { DEFAULT_FORMAT, PROFILE_FORM } from "../../utils";

export const profileConstants = {
  title: "Profile",
  firstName: {
    label: "First name",
  },
  lastName: {
    label: "Last name",
  },
  email: {
    label: "Email address",
  },
  mobileNumber: {
    label: "Mobile number",
  },
  dob: {
    name: "birth_date",
    label: "Date of birth",
    format: DEFAULT_FORMAT,
    suffixIcon: "calendar_month",
    rules: PROFILE_FORM.DOB,
    placeholder: "Select date of birth",
  },
  friend: {
    label: "What is the name of your favorite childhood friend?",
  },
  submitButtonLabel: "Update profile",
  preferredCountries: ["us", "gb"],
  updateSuccessMessage: "Your profile has been updated successfully",
  updateFailedMessage: "Profile updation failed",
  otpSendType: "profile-update",
  validNumberMessage: "Please enter a valid 10 digits mobile number",
};
