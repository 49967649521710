export function appendKeyForFilters(filters) {
  filters.forEach((filter) => {
    if (filter?.queryType === "between" && filter?.type !== "date") {
      filter.type = "range";
    }
    const newFilter = [];
    filter.children.forEach((child) => {
      if (child?.label && child?.value) {
        newFilter.push({
          ...child,
          label: `${child.label}`,
          key: `${filter.key}-${child.value}`,
        });
      }
    });
    filter.children = newFilter.sort((a, b) => a.label.localeCompare(b.label));
  });
  return filters;
}

// Helper function to process range filters
const processRangeFilter = (key, value) => {
  const minMaxCheckRegex = /(min|max)/;

  // Check if value contains 'min' or 'max', otherwise return 'equal'
  if (!minMaxCheckRegex.test(value)) {
    return `equal:${key}~${value}`;
  }
  const values = value.split(",").map((v) => v.replace(/(min_|max_)/, ""));
  const minValue = values[0] === "null" ? "" : values[0];
  const maxValue = values[1] === "null" ? "" : values[1];

  // Determine the appropriate condition based on min and max values
  if (minValue && maxValue) {
    return `between:${key}~${minValue}|${maxValue}`;
  }
  if (minValue) {
    return `gt:${key}~${minValue}`;
  }
  if (maxValue) {
    return `lte:${key}~${maxValue}`;
  }

  return ""; // Return an empty string if neither minValue nor maxValue is present
};

export const generateKeyValueFromFilter = (filter, filterItems) => {
  const reqObj = {};

  // Helper function to extract key and value from filter string
  const extractKeyValue = (item) => {
    const index = item.indexOf("-");
    return [item.slice(0, index), item.slice(index + 1)];
  };

  // Helper function to update reqObj with key-value pairs
  const updateReqObj = (key, value, type) => {
    if (reqObj[key]) {
      reqObj[key] += `|${value}`;
    } else if (type === "date") {
      reqObj[key] = value.replace(",", "|");
    } else if (type === "range") {
      reqObj[key] = processRangeFilter(key, value);
    } else {
      reqObj[key] = value;
    }
  };

  // Main loop to iterate over filter array
  filter.forEach((item) => {
    const [key, value] = extractKeyValue(item);
    const selectedFilterData =
      filterItems?.find(({ key: k }) => k === key) || {};
    updateReqObj(key, value, selectedFilterData.type);
  });

  return reqObj;
};

export const generateEncodedURI = (appliedFilters, filterItems) => {
  const filters = generateKeyValueFromFilter(appliedFilters, filterItems);
  if (!Object.keys(filters).length || filterItems?.length === 0) {
    return "";
  }
  let url = "&filter=";
  for (const [key, value] of Object.entries(filters)) {
    const parent = filterItems.find((item) => item.key === key);
    url = `${url}${
      parent?.type === "range"
        ? `${value}^`
        : `${parent?.queryType}:${key}~${
            parent?.type === "date" ? value : encodeURIComponent(value)
          }^`
    }`;
  }
  return url.slice(0, -1);
};

export const generateEncodedSearchURI = (search) => {
  return !search?.length ? "" : "&search=" + encodeURIComponent(search);
};

export const generateEncodedURIString = (param, value, encode = true) => {
  return !value.length
    ? ""
    : "&" + param + "=" + (encode ? encodeURIComponent(value) : value);
};
