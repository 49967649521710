import React, { useEffect, useState } from "react";
import "./preview-workflow.scss";
import { Typography, Box, When } from "..";
import {
  AND,
  ASSIGN_WORKFLOW,
  OVER,
  UPTO,
  constructClassName,
  convertLowerCaseWithoutSpace,
  convertToGBP,
  getCurrenciesFromLS,
  getUserName,
  moneyFormatter,
} from "../../../utils";
import { WORKFLOW_FORM } from "../../../constants";
import { getCustomFieldsAndValues } from "../../../api";
import { isWorkflowAmountHidden } from "../../../helper";

function PreviewWorkflow({
  workflowData,
  className,
  showName = true,
  amount,
  currency,
  showNumberOfStep = false,
  activeTab,
  selectedData,
  heightPadding,
  applyHeightPadding = true,
}) {
  const currencies = getCurrenciesFromLS();

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const doesCustomFieldExist = workflowData?.workflow_children?.some((step) =>
      step?.workflowsteps?.some((innerStep) => !!innerStep?.custom_field_id)
    );

    if (doesCustomFieldExist) {
      getCustomFieldsAndValues({ integrationType: activeTab }).then((res) => {
        let tempArr = [];

        Object.keys(res).forEach(
          (item) => (tempArr = [...tempArr, ...res[item]])
        );
        setCustomFields(tempArr);
      });
    }
  }, []);
  function highlightApproverName(workflowstep) {
    const convertedGBPAmount = convertToGBP(amount, currencies, currency);
    if (
      convertedGBPAmount <=
        workflowstep?.workflow_step_threshold?.threshold_limit_max &&
      convertedGBPAmount >
        workflowstep?.workflow_step_threshold?.threshold_minlimit
    ) {
      return true;
    }
    return false;
  }

  const highlightApproverNameConditionalThreshold = (workflowstep) => {
    const selectedCustomField = selectedData?.custom_fields?.find(
      (i) => i.custom_field_id === workflowstep?.custom_field_id
    );
    const convertedGBPAmount = convertToGBP(amount, currencies, currency);
    if (
      convertedGBPAmount <=
        workflowstep?.workflow_step_threshold?.threshold_limit_max &&
      convertedGBPAmount >
        workflowstep?.workflow_step_threshold?.threshold_minlimit &&
      !!selectedCustomField &&
      selectedCustomField?.value_id === workflowstep?.custom_field_value_id
    ) {
      return true;
    }
    return false;
  };
  return (
    <div
      className={constructClassName([
        className,
        "preview-workflow-section p-16px m-b-15px w-100",
      ])}
      style={{
        maxHeight: applyHeightPadding
          ? `calc(100% - ${heightPadding ? heightPadding : 200}px)`
          : "100%",
      }}
    >
      <When condition={showName}>
        <Box
          justifyContent={"spaceBetween"}
          wrap={"nowrap"}
          alignItem={"flexStart"}
        >
          <Typography
            className="m-b-17px"
            fontSize={"16"}
            variant="body"
            text={workflowData?.workflow_name}
            fontStyle={"semibold"}
          />
          <Typography
            className="m-b-17px ms-2"
            fontSize={14}
            textColorType="tertiary"
            variant="body"
            text={ASSIGN_WORKFLOW.TOTAL_WORKFLOW_STEPS(
              workflowData?.workflow_children?.length
            )}
          />
        </Box>
      </When>
      <When condition={showNumberOfStep}>
        <Box wrap={"nowrap"}>
          <Typography
            className="m-b-5px"
            fontSize={"16"}
            variant="body"
            fontStyle={"semibold"}
            text={ASSIGN_WORKFLOW.TOTAL_WORKFLOW_STEPS(
              workflowData?.workflow_children?.length
            )}
          />
        </Box>
      </When>
      <Box direction="column" alignContent="flexStart" alignItem="flexStart">
        {workflowData?.workflow_children?.map((workflow, dataIndex) => {
          const isConditionalThresholdStepType =
            convertLowerCaseWithoutSpace(
              workflow?.workflow_category?.workflow_type
            ) ===
            convertLowerCaseWithoutSpace(
              WORKFLOW_FORM.CONDITIONAL_THRESHOLD_STEP_TYPE
            );

          const isRoleOrUserStepType =
            convertLowerCaseWithoutSpace(
              workflow?.workflow_category?.workflow_type
            ) ===
            convertLowerCaseWithoutSpace(WORKFLOW_FORM.ROLE_USER_STEP_TYPE);

          return (
            <>
              <When condition={!workflow?.isOldStep}>
                <Typography
                  variant="secondary"
                  fontStyle="semibold"
                  text={ASSIGN_WORKFLOW.WORKFLOW_STEP(dataIndex + 1)}
                />
              </When>
              <Box
                alignContent="flexStart"
                alignItem="flexStart"
                justifyContent="flexStart"
                className="p-b-25px"
              >
                {workflow?.workflowsteps?.map((workflowstep, index) => {
                  const isLast = index === workflow?.workflowsteps?.length - 1;
                  const field = customFields.find(
                    (i) => i.id === workflowstep?.custom_field_id
                  );

                  const customFieldLabel = field?.label;
                  const customFieldValueLabel = field?.values.find(
                    (i) => i.value === workflowstep?.custom_field_value_id
                  )?.label;
                  return (
                    <>
                      <When
                        condition={
                          isWorkflowAmountHidden()
                            ? !isConditionalThresholdStepType
                            : true
                        }
                      >
                        <Box wrap="nowrap">
                          <div className="approver">
                            <Typography
                              variant="body"
                              fontSize={12}
                              fontStyle="semibold"
                              textColorType={
                                highlightApproverName(workflowstep)
                                  ? "secondary"
                                  : "primary"
                              }
                              text={
                                ["role", "attribute"].includes(
                                  workflowstep?.approver_type
                                )
                                  ? workflowstep?.approver?.name
                                  : workflowstep?.approver_type === "user" &&
                                    workflowstep?.approver?.status === "active"
                                  ? getUserName(workflowstep?.approver?.user)
                                  : WORKFLOW_FORM.PLACEHOLDERS.APPROVER_EDIT
                              }
                            />
                            <When condition={!isRoleOrUserStepType}>
                              <Box justifyContent="flexStart" rowGap="4">
                                <Typography
                                  variant="body"
                                  fontSize={12}
                                  textColorType="tertiary"
                                  text={isLast ? OVER : UPTO}
                                />
                                <Typography
                                  variant="body"
                                  fontSize={12}
                                  textColorType="tertiary"
                                  ellipsis={{
                                    tooltip: moneyFormatter(
                                      workflowstep?.workflow_step_threshold
                                        ?.threshold_limit,
                                      0
                                    ),
                                  }}
                                  text={moneyFormatter(
                                    workflowstep?.workflow_step_threshold
                                      ?.threshold_limit,
                                    0
                                  )}
                                />
                              </Box>
                            </When>
                          </div>
                          <When condition={!isLast}>
                            <Box className="approver-divider mx-3">
                              {ASSIGN_WORKFLOW.WORKFLOW_ARROW}
                            </Box>
                          </When>
                        </Box>
                      </When>
                      <When condition={isConditionalThresholdStepType}>
                        <div className="workflow-step-data">
                          <Typography
                            variant="body"
                            fontSize={12}
                            fontStyle="semibold"
                            text={ASSIGN_WORKFLOW.AUTO_APPROVE}
                            textColorType={
                              !highlightApproverNameConditionalThreshold(
                                workflowstep
                              )
                                ? "secondary"
                                : "primary"
                            }
                          />

                          <Box justifyContent="flexStart">
                            <Typography
                              variant="body"
                              fontSize={12}
                              textColorType="tertiary"
                              ellipsis={{
                                tooltip: moneyFormatter(
                                  workflowstep?.workflow_step_threshold
                                    ?.threshold_limit,
                                  0
                                ),
                              }}
                              text={UPTO}
                            />
                            <Typography
                              variant="body"
                              fontSize={12}
                              textColorType="tertiary"
                              ellipsis={{
                                tooltip: moneyFormatter(
                                  workflowstep?.workflow_step_threshold
                                    ?.threshold_limit,
                                  0
                                ),
                              }}
                              text={moneyFormatter(
                                workflowstep?.workflow_step_threshold
                                  ?.threshold_limit,
                                0
                              )}
                            />
                          </Box>
                        </div>
                        <Box className="approver-divider mx-3">
                          {ASSIGN_WORKFLOW.WORKFLOW_ARROW}
                        </Box>
                        <div className="workflow-step-data">
                          <Typography
                            variant="body"
                            fontSize={12}
                            fontStyle="semibold"
                            text={
                              ["role", "attribute"].includes(
                                workflowstep?.approver_type
                              )
                                ? workflowstep?.approver?.name
                                : workflowstep?.approver_type === "user" &&
                                  workflowstep?.approver?.status === "active"
                                ? getUserName(workflowstep?.approver?.user)
                                : WORKFLOW_FORM.PLACEHOLDERS.APPROVER_EDIT
                            }
                            textColorType={
                              highlightApproverNameConditionalThreshold(
                                workflowstep
                              )
                                ? "secondary"
                                : "primary"
                            }
                          />
                          <Box justifyContent="flexStart">
                            <Typography
                              variant="body"
                              fontSize={12}
                              textColorType="tertiary"
                              text={isLast ? OVER : UPTO}
                            />
                            <Typography
                              variant="body"
                              fontSize={12}
                              textColorType="tertiary"
                              ellipsis={{
                                tooltip: moneyFormatter(
                                  workflowstep?.workflow_step_threshold
                                    ?.threshold_limit,
                                  0
                                ),
                              }}
                              text={moneyFormatter(
                                workflowstep?.workflow_step_threshold
                                  ?.threshold_limit,
                                0
                              )}
                            />
                          </Box>
                          <When condition={!!customFields.length && !!field}>
                            <Box justifyContent="flexStart">
                              <Typography
                                variant="body"
                                fontSize={12}
                                textColorType="tertiary"
                                ellipsis={{
                                  tooltip: moneyFormatter(
                                    workflowstep?.workflow_step_threshold
                                      ?.threshold_limit,
                                    0
                                  ),
                                }}
                                text={AND}
                              />
                              <Typography
                                variant="body"
                                fontSize={12}
                                textColorType="tertiary"
                                ellipsis={{
                                  tooltip: moneyFormatter(
                                    workflowstep?.workflow_step_threshold
                                      ?.threshold_limit,
                                    0
                                  ),
                                }}
                                text={customFieldLabel + " ="}
                              />
                              <Typography
                                variant="body"
                                fontSize={12}
                                textColorType="tertiary"
                                ellipsis={{
                                  tooltip: moneyFormatter(
                                    workflowstep?.workflow_step_threshold
                                      ?.threshold_limit,
                                    0
                                  ),
                                }}
                                text={customFieldValueLabel}
                              />
                            </Box>
                          </When>
                        </div>
                      </When>
                    </>
                  );
                })}
              </Box>
            </>
          );
        })}
      </Box>
    </div>
  );
}

export default PreviewWorkflow;
