import React, { memo, useState } from "react";
import { Upload as AUpload } from "antd";
import { Box, Icon, Typography, When } from "..";
import "./style.scss";
import { fileNameErrorMessage, validateFileName } from "../../../constants";

function Upload({
  accept,
  maxCount,
  multiple = true,
  className,
  showUploadList = true,
  maxSize,
  maxSizeErrorMessage,
  action,
  uploadAreaText,
  itemRender,
  uploadedFiles,
  customRequest,
  disabled = false,
  fileList,
  onChange,
  ...rest
}) {
  const [showError, setShowError] = useState("");

  function beforeUpload(file) {
    if (!maxSize) {
      return;
    }
    const isLessThanMaxSize = file.size / 1024 / 1024 < maxSize;
    const isFileNameValid = validateFileName(file.name);

    const isValidFile = isLessThanMaxSize && isFileNameValid;

    if (!isFileNameValid) {
      setShowError(fileNameErrorMessage);
    } else if (!isLessThanMaxSize) {
      setShowError(maxSizeErrorMessage);
    } else {
      setShowError("");
    }

    return isValidFile;
  }

  return (
    <div className="kloo-upload-container w-100 h-100">
      <AUpload
        accept={accept}
        maxCount={maxCount}
        multiple={multiple}
        showUploadList={showUploadList}
        className={className}
        beforeUpload={beforeUpload}
        action={action}
        itemRender={itemRender}
        customRequest={customRequest}
        disabled={disabled}
        fileList={fileList}
        onChange={onChange}
        {...rest}
      >
        <When
          condition={uploadedFiles ? uploadedFiles?.length < maxCount : true}
        >
          <Box direction="column" className="w-100 p-v-35px">
            <Icon iconName={"cloud_upload"} showIconOutline={true} />
            <Typography
              text={
                uploadAreaText ||
                "Drag & drop PDF, JPG or PNG files or click to upload"
              }
              variant={disabled ? "tertiary" : "body"}
              fontStyle="semibold"
            />
            <When condition={showError}>
              <Typography
                text={showError}
                variant="body"
                className={"text-align-center text-destructive"}
              />
            </When>
          </Box>
        </When>
      </AUpload>
    </div>
  );
}

export default memo(Upload);
