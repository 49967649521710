import { FUNDS_ACCOUNT, UPDATE_THRESHOLD_AMOUNT } from "../service/types";

const initialValue = {
  fundsaccountDetail: {},
  updateThresholdAmtDetails: {},
};
export const funds = (state = initialValue, action) => {
  switch (action.type) {
    case FUNDS_ACCOUNT:
      return { ...state, fundsaccountDetail: action.payload };
    case UPDATE_THRESHOLD_AMOUNT:
      return { ...state, updateThresholdAmtDetails: action.payload };
    default:
      return state;
  }
};
