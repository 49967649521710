import React, { memo } from "react";
import { Switch as ASwitch, Form } from "antd";
import PropTypes from "prop-types";
import { constructClassName } from "../../../utils/functions";
import When from "../When";
import "./Switch.scss";

function Switch({ isFormItem = true, className, ...rest }) {
  return (
    <>
      <When condition={isFormItem}>
        <Form.Item name={rest.name}>
          <ASwitch
            className={constructClassName(["kloo-switch", className])}
            {...rest}
          />
        </Form.Item>
      </When>
      <When condition={!isFormItem}>
        <ASwitch
          className={constructClassName(["kloo-switch", className])}
          {...rest}
        />
      </When>
    </>
  );
}

Switch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default memo(Switch);
