function AutomaticSubscriptionIcon () {
    return (
        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.1326 11.3004C16.1326 12.8825 15.5819 14.3233 14.6579 15.4628L15.9832 16.8C17.2339 15.3215 17.9992 13.4004 17.9992 11.3004C17.9992 8.72018 16.8512 6.413 15.0499 4.85919L13.7246 6.19641C15.1899 7.40179 16.1326 9.24753 16.1326 11.3004ZM13.3326 3.76682L9.59922 0V2.82511C4.96055 2.82511 1.19922 6.62018 1.19922 11.3004C1.19922 13.4004 1.96455 15.3215 3.21522 16.8L4.54055 15.4628C3.61655 14.3233 3.06589 12.8825 3.06589 11.3004C3.06589 7.66547 5.99655 4.70852 9.59922 4.70852V7.53363L13.3326 3.76682Z" fill="#1127FF" />
            <path d="M15.7755 14.7657C15.1881 13.4413 14.1747 12.3426 12.8906 11.6381C11.6066 10.9337 10.1227 10.6624 8.66661 10.8658C7.2105 11.0692 5.86249 11.7361 4.82927 12.7642C3.79606 13.7924 3.13466 15.1251 2.94648 16.5579C2.03096 16.7732 1.22784 17.3114 0.68963 18.0701C0.151422 18.8287 -0.084388 19.7552 0.0269821 20.6734C0.138352 21.5916 0.589146 22.4377 1.29375 23.051C1.99836 23.6642 2.90772 24.0019 3.84912 24H14.4119C15.5636 23.9969 16.6758 23.5872 17.5456 22.8457C18.4154 22.1043 18.985 21.0804 19.1503 19.9608C19.3156 18.8413 19.0657 17.7006 18.4462 16.747C17.8267 15.7933 16.8788 15.0901 15.7755 14.7657ZM14.4119 22.1135H3.84912C3.33977 22.1135 2.85128 21.9148 2.49112 21.561C2.13095 21.2072 1.92861 20.7274 1.92861 20.227C1.92861 19.7267 2.13095 19.2469 2.49112 18.8931C2.85128 18.5393 3.33977 18.3406 3.84912 18.3406C4.1038 18.3406 4.34804 18.2412 4.52813 18.0643C4.70821 17.8874 4.80938 17.6475 4.80938 17.3974C4.81183 16.2818 5.2168 15.2032 5.95234 14.3531C6.68788 13.5031 7.70637 12.9368 8.82685 12.7546C9.94733 12.5725 11.0973 12.7865 12.0723 13.3584C13.0474 13.9304 13.7845 14.8234 14.1527 15.8787C14.209 16.0391 14.3083 16.1815 14.4401 16.2911C14.5719 16.4006 14.7314 16.4732 14.9017 16.5013C15.6055 16.6289 16.2358 17.0092 16.6698 17.5683C17.1038 18.1274 17.3103 18.8252 17.2492 19.5257C17.1881 20.2261 16.8636 20.8792 16.3392 21.3576C15.8147 21.836 15.1276 22.1055 14.4119 22.1135Z" fill="#1127FF" />
        </svg>
    );
};
export default AutomaticSubscriptionIcon ;




