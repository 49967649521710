import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";

export function linkToken(data) {
  return apiClient({
    method: "POST",
    url: PATH.integrations.linkToken,
    data,
  });
}

export function sendlinkToken(linkToken, data) {
  return apiClient({
    method: "POST",
    url: PATH.integrations.sendlinkToken(linkToken),
    data,
  });
}

export function getConnectionStatus() {
  return apiClient({
    method: "GET",
    url: PATH.integrations.getConnectionStatus,
  });
}

export function getSyncedData(data) {
  return apiClient({
    method: "POST",
    url: PATH.integrations.getSyncedData,
    data,
  });
}

export async function getEntityList({ key }) {
  const params = key ? `?config_key_name=${key}` : "";
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.configurations.entity.list(params),
  });
  return tempResponse?.data?.result;
}

export async function getEntityMappingList(linkTokenId) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.integrations.getEntityMappingList(linkTokenId),
  });
  return tempResponse?.data?.result;
}

export async function getEntityMappingListByOrgId(orgId) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.integrations.getEntityMappingListByOrgId(orgId),
  });
  return tempResponse?.data?.result;
}

export async function getErpCompanies(linkTokenId) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.integrations.getErpCompanies(linkTokenId),
  });
  return tempResponse?.data?.result;
}

export async function saveEntityCompanyMapping(data) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.integrations.saveEntityCompanyMapping,
    data,
  });
  return tempResponse?.data?.result;
}

export async function updateEntityCompanyMapping(
  id,
  linkTokenId,
  entityId,
  erpCompanyId,
  status
) {
  const tempResponse = await apiClient({
    method: "PUT",
    url: PATH.integrations.updateEntityCompanyMapping(
      id,
      linkTokenId,
      entityId,
      erpCompanyId,
      status
    ),
  });
  return tempResponse?.data?.result;
}

export async function getErpConfigMapping(linkTokenId) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.integrations.getErpConfigMapping(linkTokenId),
  });
  return tempResponse?.data?.result;
}

export async function updateErpConfigMapping(id, status, linkTokenId) {
  const tempResponse = await apiClient({
    method: "PATCH",
    url: PATH.integrations.updateErpConfigMapping(id, status, linkTokenId),
  });
  return tempResponse?.data?.result;
}

export async function getMasterDataList(linkTokenId) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.integrations.getMasterDataList(linkTokenId),
  });
  return tempResponse?.data?.result;
}

export async function disconnectErp(linkTokenId) {
  return apiClient({
    method: "DELETE",
    url: PATH.integrations.disconnectErp(linkTokenId),
  });
}

export async function deleteUser(data) {
  return apiClient({
    method: "POST",
    url: PATH.integrations.deleteUser,
    data,
  });
}

export async function workflowAutomationUnmappingForEntity(data) {
  return apiClient({
    method: "PUT",
    url: PATH.integrations.workflowAutomationUnmappingForEntity,
    data,
  });
}

export async function getERPCompanies() {
  const tempResponse = await apiClient({
    method: "GET",
    url: `${PATH.integrations.getERPCompanies}?integration_type=xero`,
  });
  return tempResponse?.data?.result;
}

export async function createERPContact(data) {
  return apiClient({
    method: "POST",
    url: PATH.integrations.createERPContact,
    data,
  });
}
