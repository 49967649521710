import {
  CONFIRM_MATCHING_CONFIRMATION_MODAL,
  INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY,
  MATCH_TYPE,
} from "../../constants";
import {
  amountFormatterWithoutCurrency,
  getLeastThresholdValue,
  getOnlyAmount,
  moneyFormatter,
} from "../../utils";

export const calculateRemainingAmountAfterOffset = ({
  initialFlow = false,
  offsetValue,
  poDetail,
  invoiceDetail,
  threshold,
}) => {
  const { FORM_FIELDS } = CONFIRM_MATCHING_CONFIRMATION_MODAL;
  let poRemainingAmount = getOnlyAmount(poDetail?.remaining_amount);
  let poNetAmount = getOnlyAmount(poDetail?.net_amount);
  const matchedInvoice = poNetAmount - poRemainingAmount;
  const totalGRNIAmount = getOnlyAmount(poDetail?.total_grni_amount);
  const invoiceAmount = invoiceDetail?.rawAmount;
  if (
    poRemainingAmount < invoiceAmount ||
    totalGRNIAmount > poNetAmount ||
    poRemainingAmount - totalGRNIAmount < 0
  ) {
    poNetAmount += getLeastThresholdValue({
      fixedAmount: threshold.fixed_value,
      threshold: threshold.threshold_value,
      poNetAmount,
    });
  }
  const unallocatedAmount = poNetAmount - matchedInvoice - totalGRNIAmount;
  let minimumOffsetValue = 0;
  if (initialFlow) {
    if (invoiceAmount <= unallocatedAmount) {
      minimumOffsetValue = 0;
    } else if (invoiceAmount <= unallocatedAmount + totalGRNIAmount) {
      minimumOffsetValue = invoiceAmount - unallocatedAmount;
    }
  } else {
    minimumOffsetValue = Number(offsetValue);
  }
  const poAmountRemainingAfterOffset = unallocatedAmount + minimumOffsetValue;
  const poAmountRemainingAfterMatch =
    invoiceAmount > poAmountRemainingAfterOffset
      ? 0
      : poAmountRemainingAfterOffset - invoiceAmount;

  return {
    [FORM_FIELDS[0].field.name]: minimumOffsetValue.toFixed(2),
    [FORM_FIELDS[1].field.name]: amountFormatterWithoutCurrency(
      poAmountRemainingAfterOffset.toFixed(2)
    ),
    [FORM_FIELDS[2].field.name]: amountFormatterWithoutCurrency(
      poAmountRemainingAfterMatch.toFixed(2)
    ),
    minimumOffsetValue: minimumOffsetValue.toFixed(2),
    maximumOffsetValue: totalGRNIAmount,
  };
};

export const isInvoiceMatchedViaSplitting = ({ invoiceData }) => {
  return (
    invoiceData?.[INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY] ===
      MATCH_TYPE.PARTIAL ||
    (invoiceData?.[INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY] ===
      MATCH_TYPE.CONFIRMED &&
      invoiceData?.poDetail?.length > 1)
  );
};

export const fetchSplittedInvoiceAmountFromPo = ({ data, poNumberUrl }) => {
  if (poNumberUrl === "") return {};
  const poNumberToBeFiltered = poNumberUrl.split("&poNumber=")[1];
  const poWithInvoiceFound = data?.poDetail?.find(
    ({ order_number }) => order_number === poNumberToBeFiltered
  );
  if (!poWithInvoiceFound) return {};
  let amountToBeDisplayed = "";

  let rawAmountToBeUsed = poWithInvoiceFound?.total_matched_amount;

  if (!isInvoiceMatchedViaSplitting({ invoiceData: data })) {
    amountToBeDisplayed = `${moneyFormatter(
      data?.net_amount,
      2,
      data?.currency
    )}${
      poWithInvoiceFound.currency !== data?.currency
        ? ` (${rawAmountToBeUsed})`
        : ""
    }`;
  } else {
    amountToBeDisplayed = rawAmountToBeUsed;
  }

  return {
    matchedAmount: amountToBeDisplayed,
    rawMatchedAmount: getOnlyAmount(rawAmountToBeUsed),
  };
};
