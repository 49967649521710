import { useEffect, useState } from "react";
import { teamManagerAndApList } from "../redux/action/invoice";
import { GetUserById } from "../redux/action/auth";
import { getAllRoles } from "../api";
import { ASSIGN_WORKFLOW, PROMISE } from "../utils";
import { useDispatch } from "react-redux";

const useRolesAttributesUsers = ({ refreshData }) => {
  const [users, setUsers] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();

  const filterRole = (res) => {
    return (
      Array.isArray(res) &&
      res?.filter((el) => el?.name !== ASSIGN_WORKFLOW.PLATFORM_USER)
    );
  };

  const mapUserDetails = (res) => {
    return res?.result?.user_organization?.reduce((acc, organization) => {
      if (
        organization?.users?.first_name?.length ||
        organization?.users?.last_name?.length
      ) {
        acc.push({
          value: organization.id,
          label: organization?.users
            ? `${organization?.users?.first_name} ${organization?.users?.last_name}`
            : "N/A",
          type: "user",
        });
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    if (refreshData) {
      Promise.allSettled([
        dispatch(GetUserById()),
        teamManagerAndApList(),
        getAllRoles(),
      ]).then(([userList, teamManagerList, rolesList]) => {
        if (userList.status === PROMISE.STATUS.FULFILLED) {
          setUsers(mapUserDetails(userList.value.result));
        }
        if (teamManagerList.status === PROMISE.STATUS.FULFILLED) {
          setAttributes(teamManagerList.value);
        }
        if (rolesList.status === PROMISE.STATUS.FULFILLED) {
          setRoles(filterRole(rolesList.value.data.result));
        }
      });
    }
  }, [refreshData, dispatch]);

  return {
    users,
    attributes,
    roles,
  };
};

export default useRolesAttributesUsers;
