export const CATEGORISATION_CONSTANTS = {
  TITLE: "Categorisation",
  SUB_TITLE:
    "Configure all your card, invoice and purchase order spend lines from one single view.",
  TAB: ["Card expenses", "Invoice management", "Purchase requests"],
  CATEGORY: {
    TITLE: "Categories",
    SUB_TITLE: {
      CARD_EXPENSE:
        "Determine optional categories for Kloo users to associate with card spend lines.",
      INVOICE:
        "Determine optional categories for Kloo users to associate with invoice spend lines.",
    },
    VIEW_MORE_BUTTON: "View more",
    VIEW_LESS_BUTTON: "View less",
  },
  XERO_TRACKING_CATEGORIES: {
    TITLE: "Xero tracking categories",
    SUB_TITLE:
      "View and determine required Xero tracking categories for Kloo users.",
  },
  EXPENSE_FIELDS: {
    TITLE: "Custom expense fields",
    TITLE_2: "Custom fields",
    SUB_TITLE:
      "Create and manage expense fields, for reporting on Kloo transactions.",
    SUB_TITLE_2:
      "Create and manage purchase requests data fields, and configure conditions for visibility.",
    ORG_MESSAGE:
      "We are setting categories for your organisation. This may take a while. Please reload in sometime.",
    BTN_LABEL: "Add custom field",
    BTN_LABEL_2: "Add custom field",
    CONDITIONAL_FIELD_TOOLTIP:
      "This is a conditional custom field which will only be displayed to users if triggered by a value within a default custom field.",
  },
  CARD_EXPENSE: "cardexpenses",
  ACCOUNT_PAYABLE: "accountpayable",
  PURCHASE_ORDER: "purchase_order",

  VIEW_BTN_LABEL: "Add category",
  VAT_FIELD: {
    TITLE: "VAT field",
    SUB_TITLE:
      "Determine the VAT field as either mandatory or optional, depending on your organisations specific requirements.",
    MANDATORY: { label: "Mandatory", value: 1 },
    OPTIONAL: { label: "Optional", value: 0 },
  },
};
export const CategoryColumn = [
  {
    Header: "drag",
    accessor: "drag",
  },
  {
    Header: "Tag",
    accessor: "name",
  },
  {
    Header: "Mandatory",
    accessor: "kloo_category_detail.acc_integr_dataset_accounts_id",
  },
  {
    Header: "tax",
    accessor: "kloo_category_detail.acc_integr_dataset_tax_rates_id",
  },
  {
    Header: "Edit",
    accessor: "edit",
  },
];

export const XERO_STATUS = {
  LINKED: "Linked",
};

export const OPTIONS = {
  OPTION_LABEL: "Option",
  OPTION_SUBTITLE:
    "Select where this custom expense field should appear whilst purchase orders are being created.",
  OPTION_ERR_MSG: "Please select option",
};

export const PAGE_TYPE_MAP = {
  cardexpenses: "CE",
  accountpayable: "IM",
  purchase_order: "PO",
};

export const CUSTOM_EXPENSE_DELETE_MSG =
  "Custom expense field deleted successfully";

export const VERSION_CUSTOM_FIELDS = "2";

export const CUSTOM_FIELD_LIST_TITLE = {
  CONDITIONAL: "Conditional custom fields",
  DEFAULT: "Default custom fields",
};

export const ENTITY_DB_KEY_CONFIGURATION = {
  ACCOUNTING: "ACCOUNTS",
  TAX_RATE: "TAX RATE",
  TRACKING_CATEGORY: "TRACKING CATEGORIES",
  ITEMS: "ITEMS",
};

export const LINE_ITEM_CATEGORISATION_CONFIG = {
  HEADER_SECTION: {
    key: "lineItemCategorisation",
    header: {
      title: "Line item categorisation",
      desc: "Categorise your invoice line items with the ERP fields and values coming directly from your accounting software.",
    },
    showAddButton: false,
    showToggle: false,
    switchValue: true,
  },
  ENTITY_STATIC_CONFIGURATION: [
    {
      dbMapping: ENTITY_DB_KEY_CONFIGURATION.ACCOUNTING,
      label: "Account",
    },
    {
      dbMapping: ENTITY_DB_KEY_CONFIGURATION.TAX_RATE,
      label: "Tax rate",
    },
    {
      dbMapping: ENTITY_DB_KEY_CONFIGURATION.TRACKING_CATEGORY,
      label: "(Tracking category)",
    },
    {
      dbMapping: ENTITY_DB_KEY_CONFIGURATION.ITEMS,
      label: "Items",
    },
  ],
  ENTITY_HEADER: {
    variant: "title",
    text: "Entities",
    fontSize: "20",
    responsiveFontSize: false,
  },
  ENTITY_NAME: {
    variant: "secondary",
    fontStyle: "semibold",
  },
  CONNECTION_TEXT: (erpAccountType) =>
    `ERP fields for line items enabled by ${erpAccountType}`,
  NO_ERP_CONNECTED_ENTITY_DATA: {
    variant: "body",
    text: "An ERP connection must be made with your entities before these values can be made visible",
  },
  ENTITY_SECTION_CONFIG: {
    VIEW_DRAWER_CONFIG: {
      HEADER: {
        variant: "body",
        text: "Values from your accounting software. Changes to these values must be made from your accounting software.",
      },
      TAX_COLUMN_CONFIG: [
        { key: "value", label: "Tax rate" },
        { key: "label", label: "Tax name" },
      ],
    },
  },
  ERP_FIELDS_CARD: {
    HEADER: {
      variant: "title",
      responsiveFontSize: false,
      fontSize: 18,
    },
    VIEW_BUTTON: {
      text: "View",
      customSize: "large",
      className: "view-btn",
    },
  },
};
