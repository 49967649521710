import React from "react";
import { Tooltip, CustomButton } from "../../../common";
import { ACCENTS, BUTTON_VARIANT } from "../../../../constants";
import { ReactComponent as AutoAwesomeIcon } from "../../../../assets/images/auto-awesome-icon.svg";

const KlooAIButton = ({
  onClick,
  toolTipTitle,
  btnText = "Kloo AI",
  btnWithToolTip = false,
  toolTipPlacement = "top",
  icon = <AutoAwesomeIcon />,
  ...rest
}) => {
  const btn = (
    <CustomButton
      icon={icon}
      text={btnText}
      variant={BUTTON_VARIANT.BUTTON_AI}
      accent={ACCENTS.SECONDARY}
      onClick={onClick}
      {...rest}
    />
  );

  if (btnWithToolTip) {
    return (
      <Tooltip placement={toolTipPlacement} title={toolTipTitle}>
        {btn}
      </Tooltip>
    );
  } else {
    return btn;
  }
};

export default KlooAIButton;
