import { useCallback, useEffect, useRef, useState } from "react";
import {
  getCategorisationToggleData,
  getCustomFields,
  getCustomFieldsV2,
  getCustomFieldVisibility,
} from "../api";
import {
  CATEGORY_FIELD_TYPE_BE_KEY,
  FIELD_TYPE_TO_TOGGLE_VALUE,
  PAGE_TYPE_TO_TOGGLE_VALUE,
  VISIBILITY_CONFIG,
} from "../constants";
import { addRequiredKeysForCustomFields } from "../utils";

function useCustomField({
  categoryType,
  customFields,
  pageName,
  forFrom,
  type,
  integrationType,
}) {
  const [fields, setFields] = useState([]);
  const [fieldsV2, setFieldsV2] = useState(null);
  const initialFieldsV2 = useRef(null);

  const setFieldOptions = (fieldId, newOption) => {
    setFields([
      ...fields.map((item) =>
        item.name === fieldId
          ? {
              ...item,
              options: newOption,
            }
          : item
      ),
    ]);
  };

  const setFieldOptionsV2 = (fieldId, newOption) => {
    setFieldsV2((prevFields) => {
      const newFields = { ...prevFields };
      for (const [key, value] of Object.entries(newFields)) {
        const fieldIndex = value.findIndex((element) => element.id === fieldId);
        if (fieldIndex !== -1) {
          newFields[key][fieldIndex].values.push(newOption[0]);
        }
      }
      return newFields;
    });
  };

  const checkOnlyConditionalExist = ({ parentSelectedData, itemDeleted }) => {
    let data = parentSelectedData?.options.filter((optionData) => {
      return optionData?.conditional_fields.filter((conditionalItem) => {
        return conditionalItem.id === itemDeleted.id;
      }).length;
    });
    return data;
  };

  const arrangingOptionOrderConditional = ({
    itemDeleted,
    filtererdArray,
    parentValueIndex,
    findOptionSelected,
    newParentIndexValue,
    index,
  }) => {
    const findExistingElementIndex = filtererdArray.findIndex((elementItem) => {
      return elementItem.id === itemDeleted.id;
    });
    let actualLength =
      parentValueIndex + findOptionSelected[0].conditional_fields.length;

    if (
      findExistingElementIndex >= 0 &&
      parentValueIndex < findExistingElementIndex &&
      actualLength > findExistingElementIndex
    ) {
      newParentIndexValue += 1;
    } else {
      filtererdArray.splice(parentValueIndex + index + 1, 0, {
        ...itemDeleted,
      });
    }
  };

  const toggleConditionalFields = (
    parentField,
    selectedField,
    customExpWithConditionalFields,
    oldValue = null
  ) => {
    const newArray = customExpWithConditionalFields
      ? customExpWithConditionalFields
      : [...fieldsV2.default];

    const data = selectedField?.conditional_fields?.length
      ? addRequiredKeysForCustomFields(selectedField.conditional_fields)
      : parentField?.remaining?.length && selectedField
      ? addRequiredKeysForCustomFields(parentField.remaining)
      : [];

    /**
     * Logic to remove conditional fields:
     * If conditional field has been triggerd by multiple default fields then
     * Remove conditional fields only if all triggering parents are removed
     */

    const checkDeletedSetOption = newArray.filter((item) => {
      return (
        (item.triggeredBy?.has(parentField.id) && !selectedField) ||
        (oldValue && item.triggeredBy?.has(oldValue?.id))
      );
    });

    const filtererdArray = newArray.filter((item) => {
      if (
        item.triggeredBy?.has(parentField.id) &&
        item.triggeredBy?.size === 1
      ) {
        return false;
      } else {
        item.triggeredBy?.delete(parentField.id);
        return true;
      }
    });

    /**
     * @name newFieldsIndex: Determines at which position conditional field will render
     */
    const newFieldsIndex = filtererdArray.findIndex(
      (field) => field.id === parentField.id
    );
    /**
     * Iterate over new custom fields to be added to list and
     * if it already exists in displayed list then update triggering parent list
     * if it does not exists in displayed list then add triggering parent list and
     * append it to the displayed list
     */
    data?.forEach((item) => {
      const index = filtererdArray.findIndex(
        (existingItem) => existingItem.id === item.id
      );
      if (index === -1) {
        filtererdArray.splice(newFieldsIndex + 1, 0, {
          ...item,
          /**
           * triggeredBy: Used to keep track of parent from which parent conditional field was triggered
           * One conditional field can be triggered by multiple default fields
           */
          triggeredBy: new Set([parentField.id]),
        });
      } else {
        filtererdArray[index].triggeredBy.add(parentField.id);
      }
    });

    if (
      checkDeletedSetOption.length &&
      parentField?.field_visibility === VISIBILITY_CONFIG?.default
    ) {
      checkDeletedSetOption.forEach((itemDeleted, index) => {
        if (
          itemDeleted?.triggeredBy?.size &&
          !itemDeleted.triggeredBy?.has(parentField.id)
        ) {
          //Currently Using For One Item, In Future it can use more than one
          [...[Array.from(itemDeleted.triggeredBy)[0]]].forEach(
            (itemParent) => {
              // Start: To find the conditional expense the location from array and try to delete from the location
              const findConditonalExpensePosition = filtererdArray.findIndex(
                (conditionalItem) => {
                  return conditionalItem.id === itemDeleted.id;
                }
              );

              if (findConditonalExpensePosition >= 0) {
                filtererdArray.splice(findConditonalExpensePosition, 1);
              }
              //End : Conditional Item is deleted from filteredArray variable

              //Start : Checking Once deleted child conditional and is there any other parent exist so that deleted child conditional will attach to existing parent conditional
              const parentValueIndex = filtererdArray.findIndex(
                (field) => field.id === itemParent
              );

              const parentSelectedData = filtererdArray[parentValueIndex];
              // Getting list of all the option conditional having to that parent condition
              let findOptionSelected = checkOnlyConditionalExist({
                parentSelectedData,
                itemDeleted,
              });

              if (findOptionSelected?.length) {
                let newParentIndexValue = 0;
                // Arranging the order of all the option and pushing to its under the parent
                arrangingOptionOrderConditional({
                  itemDeleted,
                  filtererdArray,
                  parentValueIndex,
                  findOptionSelected,
                  newParentIndexValue,
                  index,
                });
              } else if (parentValueIndex >= 0) {
                filtererdArray.splice(parentValueIndex + 1, 0, {
                  ...itemDeleted,
                });
              }
            }
          );
        }
      });
    }

    setFieldsV2((prevState) => {
      return {
        ...prevState,
        default: filtererdArray,
      };
    });
    return filtererdArray;
  };

  const getToggleObject = useCallback(
    (toggleDataV2, field_type) => {
      const currentPageType = toggleDataV2?.find(
        (page) => page.type === PAGE_TYPE_TO_TOGGLE_VALUE[type]
      );
      const currentFieldType = currentPageType?.fields.find(
        (currentFieldType) => currentFieldType.type === field_type
      );
      return currentFieldType;
    },
    [type]
  );

  const getToggleValue = useCallback(
    (toggleDataV2, field_type) => {
      const currentFieldType = getToggleObject(toggleDataV2, field_type);
      return currentFieldType?.toggle_value || false;
    },
    [getToggleObject]
  );

  const resetFieldsV2 = () => {
    setFieldsV2(initialFieldsV2.current);
  };

  useEffect(() => {
    async function fetchRecords() {
      const res = await getCategorisationToggleData();
      const visibility = getToggleValue(
        res,
        FIELD_TYPE_TO_TOGGLE_VALUE.customExpenses
      );
      if (!visibility) {
        return;
      }
      const res2 = await getCustomFieldsV2({ integrationType });
      setFieldsV2(res2);
      initialFieldsV2.current = res2;
    }
    fetchRecords();
  }, []);

  return {
    fields,
    setFieldOptions,
    fieldsV2,
    setFieldOptionsV2,
    toggleConditionalFields,
    resetFieldsV2,
    initialFieldsV2,
  };
}

export default useCustomField;
