import {
  GLOBAL_NOT_APPLICABLE_SYMBOL,
  SMART_APPROVAL_SUPPLIER,
  isDevelopmentEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
} from "../../utils";
import { CONFIGURATIONS_KEYS, featureFlags } from "../../constants";

export function isSupplierTabSelected(tab) {
  return SMART_APPROVAL_SUPPLIER.key === tab;
}

export function generateUserList(res) {
  let userList = [];
  if (res?.data?.result?.user_organization?.length) {
    res?.data?.result?.user_organization?.forEach((organization) => {
      if (
        organization?.users?.first_name?.length ||
        organization?.users?.last_name?.length
      ) {
        userList.push({
          value: organization.id,
          label: organization?.users
            ? `${organization?.users?.first_name} ${organization?.users?.last_name}`
            : GLOBAL_NOT_APPLICABLE_SYMBOL,
          type: "user",
        });
      }
    });
  }
  return userList;
}

export function generateCardSmartApprovalSettingData(res) {
  const dataAllList = res.result.filter(
    ({ main_type }) => main_type === "Cards"
  );
  return dataAllList;
}

export function getPurchaseRequestCreatorStatus({ configData }) {
  const matchSegregationPermissionStatus =
    configData?.[CONFIGURATIONS_KEYS?.isSegregationPOEnabled] || 1;

  return matchSegregationPermissionStatus?.is_key_enabled === 1;
}

export function isDefaultCurrencyFeatureEnabled() {
  return (
    (isDevelopmentEnvironment() && featureFlags.feat11547.enableForDev) ||
    (isStageEnvironment() && featureFlags.feat11547.enableForStage) ||
    (isPlatformEnvironment() && featureFlags.feat11547.enableForProd)
  );
}

export function isFeat11894Enabled() {
  return (
    (isDevelopmentEnvironment() && featureFlags.feat11894.enableForDev) ||
    (isStageEnvironment() && featureFlags.feat11894.enableForStage) ||
    (isPlatformEnvironment() && featureFlags.feat11894.enableForProd)
  );
}
