import React, { memo } from "react";
import { Alert as AAlert } from "antd";
import PropTypes from "prop-types";
import { constructClassName } from "../../../utils";
import "./custom-alert.scss";

function CustomAlert({ className, type, ...rest }) {
  return (
    <AAlert
      className={constructClassName(["kloo-alert", className])}
      type={type}
      {...rest}
    />
  );
}

CustomAlert.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

export default memo(CustomAlert);
