import {
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_PROFILE,
  USER_ADDRESS,
} from "../service/types";

const initialState = {
  access_token: localStorage.getItem("access_token") || null,
  refresh_token: localStorage.getItem("refresh_token") || null,
  token_type: localStorage.getItem("token_type") || null,
  id: localStorage.getItem("id") || null,
  first_name: localStorage.getItem("first_name") || null,
  middle_name: localStorage.getItem("middle_name") || null,
  last_name: localStorage.getItem("last_name") || null,
  email: localStorage.getItem("email") || null,
  birth_date: localStorage.getItem("birth_date") || null,
  user_created_by: localStorage.getItem("user_created_by") || null,
  login_attempts: localStorage.getItem("login_attempts") || null,
  status: localStorage.getItem("status") || null,
  expires_in: localStorage.getItem("expires_in") || null,
  address: "",
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return action.payload;
    case LOGOUT_USER:
      localStorage.clear();
      sessionStorage.clear();
      return action.payload;
    case UPDATE_PROFILE:
      delete action?.payload?.job_title;
      delete action?.payload?.password;
      delete action?.payload?.user_questionnaire;
      action &&
        action?.payload &&
        Object.keys(action?.payload).map((key) => {
          localStorage.setItem(key, action?.payload[key]);
        });
      return action.payload;
    case USER_ADDRESS:
      return { ...state, address: action.payload };
    default:
      return state;
  }
}
