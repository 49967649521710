export const BUTTON_VARIANT = {
  BUTTON_FILLED_WHITE_TEXT: "kloo-button filled-white-text",
  BUTTON_FILLED: "kloo-button filled",
  BUTTON_HOLLOW: "kloo-button hollow",
  BUTTON_TEXT: "kloo-button text",
  BUTTON_ONLY_ICON: "kloo-button only-icon",
  BUTTON_AI: "kloo-button ai-btn",
  LINK: "kloo-link",
};

export const SIZE = {
  LARGE: "large",
  SMALL: "small",
};

export const ACCENTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  SUCCESS: "success",
  DESTRUCTIVE: "destructive",
  BLACK: "black",
  WHITE: "white",
  LINK: "link",
};

export const TAG_VARIANT = {
  ACTIVE: "kloo-tag active",
  USED: "kloo-tag used",
  REJECTED: "kloo-tag rejected",
  AWAITING_APPROVAL: "kloo-tag awaiting-approval",
  INACTIVE: "kloo-tag inactive",
  CREATED: "kloo-tag created",
  REFUNDED: "kloo-tag refunded",
  ON_HOLD: "kloo-tag on-hold",
};
