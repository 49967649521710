import React from "react";
import { Tooltip } from "antd";
import { TOOLTIP_BG_COLOR } from "../../../constants";

const CommonToolTip = (props) => {
  const {
    placement,
    trigger,
    title,
    children,
    overlayClassName,
    color,
    ...rest
  } = props;
  return (
    <Tooltip
      overlayClassName={overlayClassName}
      placement={placement}
      trigger={trigger}
      title={title}
      color={color || TOOLTIP_BG_COLOR}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
export default CommonToolTip;
