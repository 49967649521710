import { Checkbox as ACheckbox } from "antd";
import React, { memo } from "react";
import { constructClassName } from "../../../utils/functions";
import "./Checkbox.scss";

function Checkbox({ className, ...rest }) {
  return (
    <ACheckbox
      className={constructClassName(["kloo-checkbox", className])}
      {...rest}
    />
  );
}

export default memo(Checkbox);
