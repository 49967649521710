import {
  isDevelopmentEnvironment,
  isEngageEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
} from "../../utils";

class ViewSDKClient {
  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;
  }

  ready() {
    return this.readyPromise;
  }

  previewFile(divId, fileURL, fileName, viewerConfig) {
    const devId = "2858f6bd306b42608709a0414d7b8599";
    const stageId = "f56562c850144fcbad3a3bd94dc9cfa3";
    const prodId = "8ba26f0305c543d38d09176e4447d2e5";
    const demoId = "148389b8c44841198f5e2662851b34a0";
    const localId = "8c0cd670273d451cbc9b351b11d22318";
    const clientId = isDevelopmentEnvironment()
      ? devId
      : isStageEnvironment()
      ? stageId
      : isEngageEnvironment()
      ? demoId
      : prodId;

    const config = {
      clientId: window.location.host.includes("localhost") ? localId : clientId,
    };

    if (divId) {
      config.divId = divId;
    }

    this.adobeDCView = new window.AdobeDC.View(config);

    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: {
          location: {
            url: fileURL,
          },
        },
        metaData: {
          fileName,
        },
      },
      viewerConfig
    );

    return previewFilePromise;
  }
}

export default ViewSDKClient;
