import React, {
  memo,
  useState,
  useCallback,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import { Empty, ComponentLoader, When } from "..";
import {
  constructClassName,
  getOnlyAmount,
  isDeepEqual,
} from "../../../utils/functions";
import Table, { Column } from "./Table";
import ATable from "./ATable";
import { useDebounceState } from "../../../hooks";

const InfiniteScrollTable = forwardRef(
  (
    {
      onNext,
      columns,
      LoaderComponent,
      className,
      variables: vars,
      renderHeaders,
      renderBody,
      enableSerialNumber,
      enableSelection,
      enableSingleSelection,
      onSelectionChange,
      onRowClick,
      enableRowHighlight,
      selectedItems,
      newTableVersionSupport,
      onSelectAll,
      containerStyle,
      headerStyle,
      onDataLoad,
      height: tableHeight,
      showScrollBar,
      ...rest
    },
    ref
  ) => {
    const initialData = newTableVersionSupport ? null : [];
    const [data, setData] = useState(initialData);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const variablesRef = useRef(vars);
    const [height] = useState(window.innerHeight);
    const [, setVariables, debounceVariables] = useDebounceState(vars);

    useImperativeHandle(ref, () => ({
      data,
      setData,
      handleOnNext,
    }));

    const fetchData = useCallback(
      async (pg) => {
        if (!onNext) return;
        const newData = await onNext({
          page: pg,
          debounceVariables,
          height,
        });
        const { list, pageSize } = newData;
        setData((data) => (pg === 1 ? [...list] : [...(data || []), ...list]));

        setLoading(false);
        if (list.length < pageSize) {
          setHasMore(false);
          return;
        } else {
          setHasMore(true);
        }
      },
      [page, debounceVariables, height]
    );

    useEffect(() => {
      if (page !== 1) {
        fetchData(page);
      }
    }, [page]);

    if (
      debounceVariables &&
      !isDeepEqual(variablesRef.current, debounceVariables)
    ) {
      variablesRef.current = debounceVariables;
    }

    useEffect(() => {
      setVariables(vars);
    }, [vars]);

    useEffect(() => {
      setData(initialData);
      setLoading(true);
      setHasMore(false);
      if (page !== 1) {
        setPage(1);
      }
      fetchData(1);
    }, [variablesRef.current]);

    useEffect(() => {
      onDataLoad({ data });
    }, [data]);

    const tableProps = {
      columns: columns,
      renderBody: renderBody,
      renderHeaders: renderHeaders,
      enableSerialNumber: enableSerialNumber,
      enableSelection: enableSelection,
      enableSingleSelection: enableSingleSelection,
      onSelectionChange: onSelectionChange,
      onRowClick: onRowClick,
      onSelectAll: onSelectAll,
      isInfiniteScroll: true,
      enableRowHighlight,
      selectedItems,
      newTableVersionSupport,
      ...rest,
    };

    const handleOnNext = () => {
      setPage(page + 1);
    };

    if (newTableVersionSupport) {
      return (
        <div id="scrollableDiv" className={"common_table"}>
          {!!data && data.length ? (
            <InfiniteScroll
              dataLength={data.length}
              next={handleOnNext}
              hasMore={hasMore}
              loader={LoaderComponent}
              scrollableTarget="scrollableDiv"
            >
              <ATable {...tableProps} data={data} />
            </InfiniteScroll>
          ) : (
            <ATable {...tableProps} data={data} />
          )}
        </div>
      );
    }

    return (
      <>
        <div className="kloo-layout-table-header">
          <div
            className={constructClassName(["common_table_header", className])}
            style={headerStyle}
          >
            <Table showBody={false} {...tableProps} data={data} />
          </div>
        </div>
        <div className="kloo-layout-table">
          <When condition={!tableProps.isInfiniteScroll}>
            <Table
              {...tableProps}
              showScrollBar={showScrollBar}
              tableHeight={tableHeight}
              showHeader={false}
            />
            <When condition={tableProps.enableFooter}>
              <Table
                {...tableProps}
                showFooter={true}
                showHeader={false}
                showBody={false}
              />
            </When>
          </When>
          <When condition={tableProps.isInfiniteScroll}>
            <div
              id="scrollableDiv"
              className={constructClassName([
                "common_table h-100",
                className,
                !showScrollBar && "hide-scroll-bar",
              ])}
              style={containerStyle}
            >
              {(!!data && data.length) ||
              ((!data || !data.length) && hasMore) ? (
                <InfiniteScroll
                  dataLength={data.length}
                  next={handleOnNext}
                  hasMore={hasMore}
                  loader={LoaderComponent}
                  height={!!tableHeight ? tableHeight : undefined}
                  scrollableTarget="scrollableDiv"
                  className={!showScrollBar && "hide-scroll-bar"}
                >
                  <Table {...tableProps} data={data} showHeader={false} />
                </InfiniteScroll>
              ) : (
                <>
                  <Table {...tableProps} showHeader={false} />
                  <When condition={loading}>{LoaderComponent}</When>
                  <When condition={!loading}>
                    <Empty
                      height={
                        !!tableHeight
                          ? getOnlyAmount(`${tableHeight}`) - 40
                          : undefined
                      }
                      // 40 is to offset margin
                    />
                  </When>
                </>
              )}{" "}
            </div>
          </When>
        </div>
      </>
    );
  }
);

InfiniteScrollTable.propTypes = {
  onNext: PropTypes.func,
  height: PropTypes.number,
  columns: PropTypes.array,
  LoaderComponent: PropTypes.element,
  renderHeaders: PropTypes.func,
  renderBody: PropTypes.func,
  enableSerialNumber: PropTypes.bool,
  enableSelection: PropTypes.bool,
  enableSingleSelection: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  onRowClick: PropTypes.func,
  onSelectAll: PropTypes.func,
  enableRowHighlight: PropTypes.bool,
  selectedItems: PropTypes.array,
  newTableVersionSupport: PropTypes.bool,
  onDataLoad: PropTypes.bool,
  showScrollBar: PropTypes.bool,
};

InfiniteScrollTable.defaultProps = {
  // LoaderComponent: <h4 style={{textAlign: 'center'}}>Loading...</h4>,
  LoaderComponent: <ComponentLoader />,
  pageSize: 20,
  enableSerialNumber: false,
  enableSelection: false,
  enableSingleSelection: false,
  enableRowHighlight: false,
  selectedItems: [],
  newTableVersionSupport: false,
  onSelectAll: () => {},
  containerStyle: {},
  onDataLoad: () => {},
  showScrollBar: false,
};

export default memo(InfiniteScrollTable);
