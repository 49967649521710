import React, { useState } from "react";
import { Form, Modal } from "antd";
import {
  Box,
  Button,
  Checkbox,
  CommonSelect,
  CustomButton,
  Typography,
  When,
} from "..";
import {
  AUTOMATION_WORKFLOW,
  DISABLE_DROPDOWN_AUTOMATION,
  INVOICE_PREVENT_TYPE,
} from "../../../utils";
import { ACCENTS, BUTTON_VARIANT } from "../../../constants";

const DisableAutomationModal = ({
  open,
  onConfirm,
  keyDelete,
  handleDelete,
  setDeleteMAppingKey,
  toggle,
  type,
  showCheckbox = true,
  setIsPreventCheckBoxModal,
  checkBoxPrevent,
  mappingData,
}) => {
  const [form] = Form.useForm();

  const isConfirmChecked = Form.useWatch("confirmCheck", form);
  const disableAutomation = Form.useWatch("disableAutomation", form);

  const handleConfirmClicked = () => {
    if (keyDelete === AUTOMATION_WORKFLOW.DELETE_MAPPING) {
      return handleDelete(form, disableAutomation);
    } else {
      return onConfirm(false, disableAutomation, form);
    }
  };
  const onCancel = () => {
    form.resetFields();
    setDeleteMAppingKey("");
    toggle();
    if (setIsPreventCheckBoxModal) {
      setIsPreventCheckBoxModal(false);
    }
  };

  const checkPreventType = () => {
    return [
      INVOICE_PREVENT_TYPE.enablePrevent,
      INVOICE_PREVENT_TYPE.disablePrevent,
    ].includes(type);
  };

  const handleDisableCondition = (keyDelete) => {
    if (
      keyDelete === AUTOMATION_WORKFLOW.DELETE_MAPPING ||
      checkPreventType()
    ) {
      return disableAutomation == null;
    } else {
      return disableAutomation == null || !isConfirmChecked;
    }
  };
  const handleTitleCondition = (keyDelete) => {
    if (checkPreventType()) {
      return AUTOMATION_WORKFLOW[type].title;
    } else if (keyDelete === AUTOMATION_WORKFLOW.DELETE_MAPPING) {
      return AUTOMATION_WORKFLOW.deleteTitle;
    } else {
      return AUTOMATION_WORKFLOW.title;
    }
  };
  const handleSubTitleCondition = (keyDelete) => {
    if (keyDelete === AUTOMATION_WORKFLOW.DELETE_MAPPING) {
      return AUTOMATION_WORKFLOW[type].deleteSubtitle;
    } else {
      return AUTOMATION_WORKFLOW[type].header;
    }
  };
  return (
    <Modal
      width={630}
      open={open}
      showHeader={false}
      footer={null}
      onCancel={onCancel}
      getContainer={false}
      centered
    >
      <Typography
        variant="title"
        className="m-b-10px"
        fontSize={24}
        responsiveFontSize={false}
        text={handleTitleCondition(keyDelete)}
      />
      <div>
        <Form
          form={form}
          initialValues={{
            disableAutomation: null,
          }}
        >
          <div className="custom-select-mapping">
            <Typography
              className="m-b-10px"
              variant="body"
              fontSize={14}
              text={handleSubTitleCondition(keyDelete)}
            />

            <CommonSelect
              newSelect={true}
              name="disableAutomation"
              placeholder={AUTOMATION_WORKFLOW.selectTitle}
              options={DISABLE_DROPDOWN_AUTOMATION[type]({
                checkBoxPrevent,
                mappingData,
              })}
            />
          </div>
          <When
            condition={
              keyDelete !== AUTOMATION_WORKFLOW.DELETE_MAPPING && showCheckbox
            }
          >
            <Form.Item name="confirmCheck" valuePropName="checked">
              <Checkbox>{AUTOMATION_WORKFLOW.checkTitle}</Checkbox>
            </Form.Item>
          </When>
          <Box
            justifyContent="center"
            className="workflow-container-btn m-t-17px"
          >
            <CustomButton
              text={AUTOMATION_WORKFLOW.btnConfirm}
              accent={ACCENTS.PRIMARY}
              variant={BUTTON_VARIANT.BUTTON_FILLED}
              disabled={handleDisableCondition(keyDelete)}
              showLoader={true}
              loaderText={"Confirming..."}
              onClick={handleConfirmClicked}
              className="m-r-20px"
              customSize={"large"}
            />
            <CustomButton
              text={AUTOMATION_WORKFLOW.btCancel}
              accent={ACCENTS.SECONDARY}
              variant={BUTTON_VARIANT.BUTTON_HOLLOW}
              onClick={onCancel}
              customSize={"large"}
            />
          </Box>
        </Form>
      </div>
    </Modal>
  );
};

export default DisableAutomationModal;
