import { Form } from "antd";
import React, { useEffect, useState } from "react";
import {
  Box,
  CommonInput,
  CommonSelect,
  CustomButton,
  Divider,
  Drawer,
  When,
} from "../../../common";
import AddressContainer from "../AddressContainer";
import SuppliersAccountForm from "../SuppliersAccountForm";
import {
  addSupplierAccount,
  createSupplier,
  deleteSupplier,
  updateSupplier,
} from "../../../../api";
import {
  getArrayLength,
  handlePhoneInputChange,
  NOTIFICATION_TYPE,
  showNotification,
} from "../../../../utils";
import {
  ACCENTS,
  ADD_NEW_SUPPLIER_FORM,
  BUTTON_VARIANT,
  SUPPLIER_ACCOUNT_SETS,
} from "../../../../constants";
import "./CreateSupplier.scss";

const CreateSupplier = ({
  open,
  onClose,
  reloadTable,
  selectedSupplier,
  countries: countriesList,
  currencies,
}) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  let defaultFormValue = {
    remember: true,
  };

  const {
    supplierName,
    priority,
    email,
    contactNumber,
    SUPPLIER_ADDRESS,
    TITLE,
    saveButton,
    saveAndAddOtherButton,
    updateButton,
    cancelButton,
    successMessage,
    updateMessage,
  } = ADD_NEW_SUPPLIER_FORM;

  const {
    supplierAddressToggle,
    addressLine1,
    addressLine2,
    city,
    country,
    postcode,
  } = SUPPLIER_ADDRESS;

  const isEditSupplier = selectedSupplier?.id;

  function setFormFieldsHandler(data) {
    return {
      name: data?.name ?? "",
      mobile: data?.supplier_contact_no,
      email: data?.email ?? "",
      priority: data?.priority,

      addressline1: data?.supplier_address?.addressline1 ?? "",
      addressline2: data?.supplier_address?.addressline2 ?? "",
      city: data?.supplier_address?.city ?? "",
      postcode: data?.supplier_address?.postcode ?? "",
      country: data?.supplier_address?.country,

      account_details: data?.supplier_accounts,
    };
  }

  useEffect(() => {
    if (selectedSupplier) {
      form.setFieldsValue({
        ...setFormFieldsHandler(selectedSupplier),
      });
    }
  }, []);

  function addAccountHandler({ requestPayload, data }) {
    if (getArrayLength(data?.account_details) > 0) {
      return addSupplierAccount(requestPayload).catch((err) => {
        deleteSupplier(requestPayload?.supplier_id);
        throw err;
      });
    } else {
      return true;
    }
  }

  function addressPayload(data) {
    return {
      addressline1: data?.addressline1 ?? "",
      addressline2: data?.addressline2 ?? "",
      city: data?.city ?? "",
      postcode: data?.postcode ?? "",
      country: data?.country ?? "",
    };
  }

  function supplierPayload(data) {
    return {
      name: data?.name ?? "",
      mobile: data?.mobile ?? "",
      email: data?.email ?? "",
      priority: data?.priority ?? "",
      supplier_address: addressPayload(data),
    };
  }

  async function addSupplier({ addAnotherSupplier = false }) {
    try {
      setIsSubmitting(true);
      const data = await form.validateFields();
      const requestPayload = supplierPayload(data);
      await createSupplier(requestPayload)
        .then((supplierResponse) => {
          const requestPayload = {
            supplier_id: supplierResponse?.id,
            account_details: data?.account_details?.map((item) => {
              return {
                account_type: SUPPLIER_ACCOUNT_SETS.UKAccount,
                account_name: item?.account_name ?? "",
                account_alias: item?.account_alias ?? "",
                acc_number: item?.acc_number ?? "",
                sort_code: item?.sort_code ?? "",
                currency: item?.currency ?? "",
                bank_address: item?.addressline1
                  ? {
                      bank_name: item?.bank_name,
                      ...addressPayload(item),
                    }
                  : [],
              };
            }),
          };
          return addAccountHandler({ requestPayload, data });
        })
        .then(() => {
          reloadTable();
          showNotification(NOTIFICATION_TYPE.success, successMessage);
          if (!addAnotherSupplier) {
            onClose();
          }
          form.resetFields();
        });
    } catch (err) {
      if (err?.response?.data?.message) {
        showNotification(NOTIFICATION_TYPE.error, err?.response?.data?.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  async function editSupplier() {
    try {
      const data = await form.validateFields();
      const requestPayload = {
        id: selectedSupplier?.id,
        ...supplierPayload(data),
      };
      await updateSupplier(requestPayload).then((res) => {
        reloadTable();
        showNotification(NOTIFICATION_TYPE.success, updateMessage);
        onClose();
        form.resetFields();
      });
    } catch (err) {
      if (err?.response?.data?.message) {
        showNotification(NOTIFICATION_TYPE.error, err?.response?.data?.message);
      }
    }
  }

  return (
    <Drawer
      className="supplier-management-drawer"
      open={open}
      onClose={onClose}
      title={isEditSupplier ? TITLE.edit : TITLE.add}
    >
      <Box className="new-supplier-form-container">
        <Form
          form={form}
          name="create_supplier"
          initialValues={defaultFormValue}
          layout="vertical"
          autoComplete="off"
          className="w-100"
          scrollToFirstError
        >
          <CommonInput type="text" {...supplierName} />

          <CommonInput type="email" {...email} />

          <CommonInput
            {...contactNumber}
            onInput={handlePhoneInputChange}
            maxLength={20}
          />

          <CommonSelect {...priority} />

          <AddressContainer
            addressToggle={supplierAddressToggle}
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            city={city}
            country={{ ...country, options: countriesList }}
            postcode={postcode}
            defaultToggleValue={
              selectedSupplier?.supplier_address?.addressline1
            }
          />

          <Divider className={"m-t-20px m-b-12px"} />

          <When condition={!isEditSupplier}>
            <SuppliersAccountForm
              countriesList={countriesList}
              currencies={currencies}
              isSubmitting={isSubmitting}
            />
          </When>

          <When condition={!isEditSupplier}>
            <Box direction={"row"} wrap="nowrap">
              <CustomButton
                text={saveButton.label}
                accent={ACCENTS.PRIMARY}
                variant={BUTTON_VARIANT.BUTTON_FILLED}
                onClick={() => addSupplier({ addAnotherSupplier: false })}
                htmlType="submit"
                className={"w-50"}
                showLoader={true}
                customSize={"large"}
              />
              <CustomButton
                text={saveAndAddOtherButton.label}
                accent={ACCENTS.SECONDARY}
                variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                onClick={() => addSupplier({ addAnotherSupplier: true })}
                htmlType="submit"
                className={"w-50"}
                showLoader={true}
                customSize={"large"}
              />
            </Box>
          </When>
          <When condition={isEditSupplier}>
            <Box direction={"row"} wrap="nowrap">
              <CustomButton
                text={updateButton.label}
                accent={ACCENTS.PRIMARY}
                variant={BUTTON_VARIANT.BUTTON_FILLED}
                onClick={editSupplier}
                className={"w-50"}
                showLoader={true}
                customSize={"large"}
              />
              <CustomButton
                text={cancelButton.label}
                accent={ACCENTS.SECONDARY}
                variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                onClick={onClose}
                className={"w-50"}
                customSize={"large"}
              />
            </Box>
          </When>
        </Form>
      </Box>
    </Drawer>
  );
};

export default CreateSupplier;
