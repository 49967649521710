import React from "react";
import { Row, Column, Typography, CommonSelect, When, Icon, Box } from "..";
import Erase from "../../../assets/images/Erase.svg";
import { REMAINING_OPTION } from "../../../constants";
import "./WorkflowAutomation.scss";

function MappingRow({
  item,
  label,
  selectedOptions,
  onCheck,
  className,
  workflows,
  showCheckBox,
  apiMAppingData,
  handleOnChange,
  savedSelectedOption,
  remainingDelete,
  isDisableReassignmentForERPFields,
  ...rest
}) {
  const onlySelectedRemaining = (savedSelectedOption) => {
    const addRemaining = savedSelectedOption.some(
      (field) => field == REMAINING_OPTION.value
    );
    if (
      addRemaining
        ? savedSelectedOption?.length !== 1
        : savedSelectedOption?.length !== 0
    ) {
      return true;
    }
    return false;
  };
  return (
    <Row className={className}>
      <Column span={8}>
        <Typography
          variant="body"
          fontStyle={item?.value === "remaining_values" ? "semibold" : ""}
          text={label || item?.label}
          {...rest}
        />
      </Column>
      <Column span={8}>
        <CommonSelect
          name={item?.value}
          placeholder="Select workflow"
          options={workflows}
          className={!showCheckBox ? "custom-text-optional-select" : ""}
          disabled={
            !showCheckBox || isDisableReassignmentForERPFields({ item })
          }
          onChange={handleOnChange}
          wrapperClass={"m-b-0px"}
          wrapperClassFormItem={"form-item-margin"}
        />
      </Column>
      <When condition={!showCheckBox}>
        <Column span={8}>
          <Box
            justifyContent={"flexEnd"}
            className={"clear-selection-remaining"}
          >
            {item?.value !== REMAINING_OPTION.value ? (
              <Icon
                iconName={"delete_outline"}
                onClick={() => onCheck(item?.value)}
              />
            ) : item?.value == REMAINING_OPTION.value &&
              savedSelectedOption[0] === REMAINING_OPTION.value &&
              !onlySelectedRemaining(savedSelectedOption) &&
              remainingDelete["wId"] ? (
              <Icon
                iconName={"delete_outline"}
                onClick={() => onCheck(item?.value)}
              />
            ) : item?.value == REMAINING_OPTION.value &&
              remainingDelete["fId"] &&
              remainingDelete["wId"] ? (
              <Box onClick={() => onCheck(item?.value)}>
                <img src={Erase} />
                <Typography
                  variant="body"
                  fontStyle={"semibold"}
                  text={"Clear selection"}
                  className="clear-mapping"
                  {...rest}
                />
              </Box>
            ) : remainingDelete["fId"] &&
              !onlySelectedRemaining(savedSelectedOption) ? (
              <Icon
                iconName={"delete_outline"}
                onClick={() => onCheck(item?.value)}
              />
            ) : null}
          </Box>
        </Column>
      </When>
    </Row>
  );
}

export default MappingRow;
