import { Tag, Typography } from "antd";
import "./chips.scss";
import Icon from "../Icon/index";
import { TOOLTIP_BG_COLOR } from "../../../constants";
import When from "../When";
import { moneyFormatter } from "../../../utils";

const { Text } = Typography;

const getSelectedLabels = (items, selectedKeys, removeKey) => {
  const temp = [];
  // debugger;
  selectedKeys.forEach((element) => {
    const parent = element.split("-")[0];
    const parentItem = items.find((item) => item.key === parent);
    const selectedParentItem = temp.find((item) => item.key === parent);
    if (
      !selectedParentItem ||
      (selectedParentItem &&
        ["date", "range"].includes(selectedParentItem?.type))
    ) {
      const eleMatched = parentItem?.children.find(
        (item) => item?.key === element
      );
      if (!eleMatched && !["date", "range"].includes(parentItem?.type)) {
        removeKey([element]);
        return;
      }
      temp.push({
        key: parent,
        label: parentItem?.label,
        enableChipReset: parentItem?.enableChipReset,
        kids: [element],
        type: parentItem?.type,
        kidsLabel: ["date", "range"].includes(parentItem?.type)
          ? [element.replace(`${parentItem?.key}-`, "").replace(",", " to ")]
          : [
              eleMatched && eleMatched.label_description
                ? eleMatched.label_description
                : eleMatched.label || "",
            ],
      });
    } else {
      const selectedChild = parentItem?.children.find(
        (item) => item.key === element
      );
      if (!selectedChild && !["date", "range"].includes(parentItem?.type)) {
        removeKey([element]);
        return;
      }
      selectedParentItem.kidsLabel = [
        ...selectedParentItem.kidsLabel,
        selectedChild && selectedChild.label_description
          ? selectedChild.label_description
          : selectedChild.label || "",
      ];
      selectedParentItem.kids = [...selectedParentItem.kids, element];
    }
  });
  return temp;
};

function Chips({ selectedKeys, items, removeKey, removeOnclick }) {
  const selectedLabels = getSelectedLabels(items, selectedKeys, removeKey);
  const rangeText = (label) => {
    const values = label.join("").split(" ");
    const minMaxCheckRegex = /(min|max)/;
    if (!minMaxCheckRegex.test(label.join(""))) {
      return `${values}%`;
    }
    const minValue =
      values[0].replace("min_", "") === "null"
        ? ""
        : values[0].replace("min_", "");
    const maxValue =
      values[2].replace("max_", "") === "null"
        ? ""
        : values[2].replace("max_", "");
    return minValue && maxValue
      ? moneyFormatter(minValue, 2) + " to " + moneyFormatter(maxValue, 2)
      : minValue || maxValue;
  };

  return (
    <>
      {selectedLabels.map((item) => {
        const { enableChipReset = true } = item;

        return (
          <Tag className="kloo-chip" key={item.kidsLabel}>
            {
              <Typography.Text
                ellipsis={{
                  tooltip: {
                    title: item.label + " is " + item.kidsLabel.join(", "),
                    color: TOOLTIP_BG_COLOR,
                  },
                }}
                className="chip"
              >
                {`${item.label} is `}
                <strong>{`${
                  item?.type === "range"
                    ? rangeText(item.kidsLabel)
                    : item.kidsLabel.join(", ")
                }`}</strong>
              </Typography.Text>
            }
            <When condition={enableChipReset}>
              <Icon
                iconName={"cancel"}
                onClick={() =>
                  removeOnclick
                    ? removeOnclick(item.kids)
                    : removeKey(item.kids)
                }
                className="close-icon"
              />
            </When>
          </Tag>
        );
      })}
    </>
  );
}

export default Chips;
