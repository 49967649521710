import { CARDS_EXPENSES_KEYS } from "../CardExpenses";
import { CARD_REQUESTS_TABS_KEYS } from "../CardRequests";
import { CARDS_TABS_KEYS } from "../Cards";
import { INVOICE_MANAGEMENT_TAB_KEYS } from "../InvoiceManagement";
import { NAVIGATION_URL } from "../navigationUrl";
import {
  NEXT_APPROVER,
  PURCHASE_ORDER_TABS_KEYS,
  PURCHASE_REQUEST_PAGE_UPDATE,
} from "../purchaseOrder";

export const dashboardConstants = {
  title: "Home",
  headingDashboard:
    "The home page shows a summary of your Kloo activity; including spend visualisations, subscriptions, and imported data from your accounting software (if connected).",
};

const selectionHeading =
  "You can find an overview of all your Kloo activity including any action items that need to be taken. Simply select any dashboard from the drop-down field, click into any section you would like to view, and be taken immediately to the relevant pages and tabs.";

export const poDashboardConstants = {
  title: "Home",
  selectionHeading,
  subTitle:
    "Here is a summary of all your PO activity as well as everything that requires your attention. You can click into each section and be directed to the relevant tab so you can quickly action anything that is required.",
  requiringApproval: {
    key: "POs requiring your approval",
    label: "POs requiring your approval",
    desc: "These are all the raised POs which require your direct approval",
    type: "assignment_turned_in",
    iconName: "assignment_turned_in",
    route: PURCHASE_ORDER_TABS_KEYS.IN_REVIEW,
    filter: PURCHASE_ORDER_TABS_KEYS.IN_REVIEW,
    navigate: NAVIGATION_URL.PO_MANAGEMENT,
  },
  requiringAssignment: {
    key: "POs requiring assignment",
    label: "POs requiring assignment",
    desc: "These are all the raised POs that are yet to be assigned to a workflow or be approved/rejected",
    type: "assignment_ind",
    iconName: "assignment_ind",
    route: PURCHASE_ORDER_TABS_KEYS.CREATED,
    navigate: NAVIGATION_URL.PO_MANAGEMENT,
  },
  approvalFlow: {
    key: "POs in an approval flow",
    label: "POs in an approval flow",
    desc: "These are all the raised POs that are in an approval flow and awaiting to be approved by those configured in your smart approvals flow",
    type: "pending_actions",
    iconName: "pending_actions",
    route: PURCHASE_ORDER_TABS_KEYS.IN_REVIEW,
    navigate: NAVIGATION_URL.PO_MANAGEMENT,
  },
  total: {
    key: "Total POs",
    label: "Total POs",
    desc: "This is the total number of POs raised by your organisation",
    type: "assignment_returned",
    iconName: "assignment_returned",
    route: PURCHASE_ORDER_TABS_KEYS.COMPLETED,
    filter: PURCHASE_ORDER_TABS_KEYS.COMPLETED,
    navigate: NAVIGATION_URL.PO_MANAGEMENT,
  },
  raisedOrOwn: {
    key: "Your POs",
    label: "Your POs",
    desc: "These are all the approved POs that you have raised or where you're the owner",
    type: "assignment_ind",
    iconName: "assignment_ind",
    route: PURCHASE_ORDER_TABS_KEYS.COMPLETED,
    filter: PURCHASE_ORDER_TABS_KEYS.COMPLETED,
    navigate: NAVIGATION_URL.PO_MANAGEMENT,
  },
};
export const IMDashboardConstants = {
  title: "Home",
  selectionHeading,
  subTitle:
    "Here is a summary of all your invoice activity as well as everything that requires your attention. You can click into each section and be directed to the relevant tab so you can quickly action anything that is required.",
  heading: "Invoice summary",
  totalInvoiceLabel: "Total invoices:",
  overdueLabel: "Overdue:",
  IMrequiringApproval: {
    key: "invoices_requiring_approval",
    label: "Invoices requiring your approval",
    desc: "These are all the submitted invoices which require your direct approval",
    type: "assignment_turned_in",
    iconName: "assignment_turned_in",
    route: INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW,
    filter: INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW,
    navigate: NAVIGATION_URL.INVOICE_MANAGEMENT,
  },
  IMrequiringAssignment: {
    key: "invoices_requiring_assignment",
    label: "Invoices requiring assignment",
    desc: "These are all the submitted invoices that are yet to be assigned to a workflow or be approved/rejected",
    type: "assignment_ind",
    iconName: "assignment_ind",
    route: INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED,
    filter: INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED,
    navigate: NAVIGATION_URL.INVOICE_MANAGEMENT,
  },
  IMapprovalFlow: {
    key: "invoices_approval_flow",
    label: "Invoices in an approval flow",
    desc: "These are all the submitted invoices that are awaiting approval by those configured in your smart approval flow",
    type: "pending_actions",
    iconName: "pending_actions",
    route: INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW,
    navigate: NAVIGATION_URL.INVOICE_MANAGEMENT,
  },
  IMawaitingPayment: {
    key: "invoices_awaiting_payment",
    label: "Invoices awaiting payment",
    desc: "These are all the approved invoices that are yet to be paid",
    type: "assignment_returned",
    iconName: "assignment_returned",
    route: INVOICE_MANAGEMENT_TAB_KEYS.TO_PAY,
    navigate: NAVIGATION_URL.INVOICE_MANAGEMENT,
  },
  IMyourInvoices: {
    key: "your_invoices",
    label: "Your invoices",
    desc: "These are all your approved invoices which have been paid",
    type: "assignment_ind",
    iconName: "assignment_ind",
    route: INVOICE_MANAGEMENT_TAB_KEYS.PAID,
    navigate: NAVIGATION_URL.INVOICE_MANAGEMENT,
  },
};

export const DASHBOARD_TYPE = {
  PO: "PO",
  IM: "IM",
  Modulr: "Modulr",
  CardsAndCardExpenses: "CardsAndCardExpenses",
};

export const DASHBOARD_SELECT_LABEL = {
  [DASHBOARD_TYPE.PO]: PURCHASE_REQUEST_PAGE_UPDATE.LABEL,
  [DASHBOARD_TYPE.IM]: "Invoice management",
  [DASHBOARD_TYPE.CardsAndCardExpenses]: "Cards and card expenses",
};

export const DASHBOARD_OPTIONS = [
  {
    value: DASHBOARD_TYPE.PO,
    label: DASHBOARD_SELECT_LABEL.PO,
  },
  {
    value: DASHBOARD_TYPE.IM,
    label: DASHBOARD_SELECT_LABEL.IM,
  },
  // {
  //   value: DASHBOARD_TYPE.CardsAndCardExpenses,
  //   label: DASHBOARD_SELECT_LABEL.CardsAndCardExpenses,
  // },
];

export const DASHBOARD_CONSTANTS = {
  title: "Home",
  subTitle:
    "You can find an overview of all your Kloo activity including any action items that need to be taken. Simply select any dashboard from the drop-down field, click into any section you would like to view, and be taken immediately to the relevant pages and tabs.",
  [DASHBOARD_TYPE.PO]: {
    sectionTitle:
      "Here is a summary of all your PO activity as well as everything that requires your attention. You can click into each section and be directed to the relevant tab so you can quickly action anything that is required.",
    requiringApproval: {
      key: "POs requiring your approval",
      label: "Purchase requests requiring your approval",
      desc: "These are all the raised POs which require your direct approval",
      type: "assignment_turned_in",
      iconName: "assignment_turned_in",
      route: PURCHASE_ORDER_TABS_KEYS.IN_REVIEW,
      filter: PURCHASE_ORDER_TABS_KEYS.IN_REVIEW,
      navigate: NAVIGATION_URL.PO_MANAGEMENT,
    },
    requiringAssignment: {
      key: "POs requiring assignment",
      label: "Purchase requests requiring assignment",
      desc: "These are all the raised POs that are yet to be assigned to a workflow or be approved/rejected",
      type: "assignment_ind",
      iconName: "assignment_ind",
      route: PURCHASE_ORDER_TABS_KEYS.CREATED,
      navigate: NAVIGATION_URL.PO_MANAGEMENT,
    },
    approvalFlow: {
      key: "POs in an approval flow",
      label: "Purchase requests in an approval flow",
      desc: "These are all the raised POs that are in an approval flow and awaiting to be approved by those configured in your smart approvals flow",
      type: "pending_actions",
      iconName: "pending_actions",
      route: PURCHASE_ORDER_TABS_KEYS.IN_REVIEW,
      navigate: NAVIGATION_URL.PO_MANAGEMENT,
    },
    total: {
      key: "Total POs",
      label: "Completed POs",
      desc: "This is the total number of POs raised by your organisation",
      type: "assignment_returned",
      iconName: "assignment_returned",
      route: PURCHASE_ORDER_TABS_KEYS.COMPLETED,
      filter: PURCHASE_ORDER_TABS_KEYS.COMPLETED,
      navigate: NAVIGATION_URL.PO_MANAGEMENT,
    },
    raisedOrOwn: {
      key: "Your POs",
      label: "Your POs",
      desc: "These are all the approved POs that you have raised or where you're the owner",
      type: "assignment_ind",
      iconName: "assignment_ind",
      route: PURCHASE_ORDER_TABS_KEYS.COMPLETED,
      filter: PURCHASE_ORDER_TABS_KEYS.COMPLETED,
      navigate: NAVIGATION_URL.PO_MANAGEMENT,
    },
  },
  [DASHBOARD_TYPE.IM]: {
    sectionTitle:
      "Here is a summary of all your invoice activity as well as everything that requires your attention. You can click into each section and be directed to the relevant tab so you can quickly action anything that is required.",
    heading: "Invoice summary",
    totalInvoiceLabel: "Total invoices:",
    overdueLabel: "Overdue:",
    IMrequiringApproval: {
      key: "invoices_requiring_approval",
      label: "Invoices requiring your approval",
      desc: "These are all the submitted invoices which require your direct approval",
      type: "assignment_turned_in",
      iconName: "assignment_turned_in",
      route: INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW,
      filter: { nextApprover: NEXT_APPROVER.selfOrRole },
      navigate: NAVIGATION_URL.INVOICE_MANAGEMENT,
    },
    IMrequiringAssignment: {
      key: "invoices_requiring_assignment",
      label: "Invoices requiring assignment",
      desc: "These are all the submitted invoices that are yet to be assigned to a workflow or be approved/rejected",
      type: "assignment_ind",
      iconName: "assignment_ind",
      route: INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED,
      navigate: NAVIGATION_URL.INVOICE_MANAGEMENT,
    },
    IMapprovalFlow: {
      key: "invoices_approval_flow",
      label: "Invoices in an approval flow",
      desc: "These are all the submitted invoices that are awaiting approval by those configured in your smart approval flow",
      type: "pending_actions",
      iconName: "pending_actions",
      route: INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW,
      navigate: NAVIGATION_URL.INVOICE_MANAGEMENT,
    },
    IMawaitingPayment: {
      key: "invoices_awaiting_payment",
      label: "Invoices awaiting payment",
      desc: "These are all the approved invoices that are yet to be paid",
      type: "assignment_returned",
      iconName: "assignment_returned",
      route: INVOICE_MANAGEMENT_TAB_KEYS.TO_PAY,
      navigate: NAVIGATION_URL.INVOICE_MANAGEMENT,
    },
    IMyourInvoices: {
      key: "your_invoices",
      label: "Your invoices",
      desc: "These are all your approved invoices which have been paid",
      type: "assignment_ind",
      iconName: "assignment_ind",
      route: INVOICE_MANAGEMENT_TAB_KEYS.PAID,
      navigate: NAVIGATION_URL.INVOICE_MANAGEMENT,
    },
  },
  [DASHBOARD_TYPE.CardsAndCardExpenses]: {
    sectionTitle:
      "Here is a summary of all your card and card expense activity as well everything that requires you attention. You can click into each section and be directed to the relevant page and tab so you can quickly action anything that is required.",
    heading: "Invoice summary",
    MyCards: {
      key: "my_cards",
      label: "My cards",
      desc: "These are all your created, active and frozen Kloo cards",
      type: "assignment_turned_in",
      iconName: "wallet",
      route: CARDS_TABS_KEYS.MY_CARDS,
      filter: "&filter=whereIn:request_status~8%7C15%7C14",
      navigate: NAVIGATION_URL.CARDS,
    },
    AllCardRequests: {
      key: "all_card_requests",
      label: "All card requests",
      desc: "These are all your organisation's virtual and physical card requests",
      type: "pending_actions",
      iconName: "task",
      route: CARD_REQUESTS_TABS_KEYS.ALL_APPROVALS,
      filter: { statusNotIn: "10" },
      navigate: NAVIGATION_URL.APPROVALS,
    },
    MyCardRequests: {
      key: "my_card_requests",
      label: "My card requests",
      desc: "These are all your virtual and physical card requests",
      type: "assignment_ind",
      iconName: "task",
      route: CARD_REQUESTS_TABS_KEYS.MY_APPROVALS,
      filter: { statusNotIn: "10", mycardreq: "true" },
      navigate: NAVIGATION_URL.APPROVALS,
    },
    CardRequestsRequiringYourDirectApproval: {
      key: "card_requests_requiring_your_direct_approval",
      label: "Card requests requiring your direct approval",
      desc: "These are all your organisation's card requests requiring your direct approval",
      type: "pending_actions",
      iconName: "task",
      route: CARD_REQUESTS_TABS_KEYS.MY_APPROVALS,
      filter: {
        statusNotIn: "10",
        yourRequest: "&mycardreq=false&othercardreq=true",
      },
      navigate: NAVIGATION_URL.APPROVALS,
    },

    MyExpense: {
      key: "my_expenses",
      label: "My expenses",
      desc: "These are all your settled expenses that have been approved by those configured in your smart approval flow",
      type: "assignment_ind",
      iconName: "payments",
      route: CARDS_EXPENSES_KEYS.APPROVED,
      filter: null,
      navigate: NAVIGATION_URL.CARD_EXPENSES,
    },
    MyExpensesRequiringAction: {
      key: "my_expenses_requiring_action",
      label: "My expenses requiring action",
      desc: "These are your expenses that are missing a receipt or mandatory information such as a description",
      type: "assignment_returned",
      iconName: "payments",
      route: CARDS_EXPENSES_KEYS.OUTSTANDING,
      filter: {
        key: "&filter=whereIn:activity_status~Approved%7CSettled",
        viewCompany: false,
      },
      navigate: NAVIGATION_URL.CARD_EXPENSES,
    },
    AllExpensesRequiringAction: {
      key: "all_expenses_requiring_action",
      label: "All expenses requiring action",
      desc: "These are your organisation's expenses that are missing a receipt or mandatory information such as a description",
      type: "pending_actions",
      iconName: "payments",
      route: CARDS_EXPENSES_KEYS.OUTSTANDING,
      filter: {
        key: "&filter=whereIn:activity_status~Approved%7CSettled",
        viewCompany: true,
      },
      navigate: NAVIGATION_URL.CARD_EXPENSES,
    },
    ExpensesRequiringYourDirectApproval: {
      key: "expenses_requiring_your_direct_approval",
      label: "Expenses requiring your direct approval",
      desc: "These are all your organisation's card expenses requiring your direct approval",
      type: "assignment_turned_in",
      iconName: "payments",
      route: CARDS_EXPENSES_KEYS.REVIEW,
      filter: {
        key: "&filter=whereIn:activity_status~Settled",
        viewCompany: true,
        nextApprover: NEXT_APPROVER.selfOrRole,
      },
      navigate: NAVIGATION_URL.CARD_EXPENSES,
    },
    AllExpenses: {
      key: "all_expenses",
      label: "All expenses",
      desc: "These are all your organisation's settled expenses that have been approved by those configured in your smart approval flow",
      type: "assignment_returned",
      iconName: "payments",
      route: CARDS_EXPENSES_KEYS.APPROVED,
      filter: {
        key: null,
        viewCompany: true,
      },
      navigate: NAVIGATION_URL.CARD_EXPENSES,
    },
  },
};

const POKeys = DASHBOARD_CONSTANTS[DASHBOARD_TYPE.PO];
const IMKeys = DASHBOARD_CONSTANTS[DASHBOARD_TYPE.IM];
const CardsKeys = DASHBOARD_CONSTANTS[DASHBOARD_TYPE.CardsAndCardExpenses];

export const count_keys_old = [
  POKeys.approvalFlow,
  POKeys.requiringApproval,
  POKeys.requiringAssignment,
  POKeys.total,
  POKeys.raisedOrOwn,

  IMDashboardConstants.IMrequiringApproval,
  IMDashboardConstants.IMapprovalFlow,
  IMDashboardConstants.IMawaitingPayment,
  IMDashboardConstants.IMyourInvoices,
  IMDashboardConstants.IMrequiringAssignment,

  CardsKeys.MyCards,
  CardsKeys.CardRequestsRequiringYourDirectApproval,
  CardsKeys.AllCardRequests,
  CardsKeys.MyCardRequests,

  CardsKeys.MyExpense,
  CardsKeys.MyExpensesRequiringAction,
  CardsKeys.AllExpensesRequiringAction,
  CardsKeys.ExpensesRequiringYourDirectApproval,
  CardsKeys.AllExpenses,
];
export const count_keys = [
  POKeys.approvalFlow,
  POKeys.requiringApproval,
  POKeys.requiringAssignment,
  POKeys.total,
  POKeys.raisedOrOwn,

  IMKeys.IMrequiringApproval,
  IMKeys.IMapprovalFlow,
  IMKeys.IMawaitingPayment,
  IMKeys.IMyourInvoices,
  IMKeys.IMrequiringAssignment,

  CardsKeys.MyCards,
  CardsKeys.CardRequestsRequiringYourDirectApproval,
  CardsKeys.AllCardRequests,
  CardsKeys.MyCardRequests,

  CardsKeys.MyExpense,
  CardsKeys.MyExpensesRequiringAction,
  CardsKeys.AllExpensesRequiringAction,
  CardsKeys.ExpensesRequiringYourDirectApproval,
  CardsKeys.AllExpenses,
];
