import React, { useState } from "react";
import {
  Box,
  CommonInput,
  CommonSelect,
  Divider,
  Switch,
  Typography,
  When,
} from "../../../common";
import "./AddressContainer.scss";

function AddressContainer({
  addressToggle,
  addressLine1,
  addressLine2,
  city,
  country,
  postcode,
  defaultToggleValue = false,
  children,
}) {
  const [isAddressRequired, setIsAddressRequired] =
    useState(defaultToggleValue);
  function onSupplierAddressToggle(value) {
    setIsAddressRequired(value);
  }

  return (
    <div className={"supplier-address-container"}>
      <Box justifyContent="spaceBetween">
        <Typography
          variant="secondary"
          fontStyle={"semibold"}
          text={addressToggle.label}
        />
        <Switch
          onChange={onSupplierAddressToggle}
          checked={isAddressRequired}
          isFormItem={false}
        />
      </Box>
      <When condition={isAddressRequired}>
        <Divider className={"my-3"} />
        {children}
        <CommonInput type="text" {...addressLine1} />
        <CommonInput type="text" {...addressLine2} />
        <CommonInput type="text" {...city} />
        <CommonInput type="text" {...postcode} />
        <CommonSelect {...country} />
      </When>
    </div>
  );
}

export default AddressContainer;
