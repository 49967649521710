import React from "react";
import {
  When,
  Box,
  Image,
  PDFViewer,
  Icon,
  Typography,
  Column,
} from "../../../common";
import "./RightSection.scss";
import { RIGHT_SECTION } from "../../../../constants/LineItems";

const RightSection = ({ isDrawerVisible, handleDrawer, previewImage }) => {
  const isPdf = !previewImage?.isImg;
  return (
    <>
      <When condition={isDrawerVisible}>
        <Column span={8} className="detail-drawer h-100 p-0px ">
          <When condition={previewImage}>
            <Box className="w-100 h-100">
              <When condition={isPdf}>
                <PDFViewer url={previewImage?.url} />
              </When>
              <When condition={!isPdf}>
                <Image preview={false} src={previewImage?.url} />
              </When>
            </Box>
          </When>
          <div onClick={handleDrawer} className="border-icon">
            <Icon {...RIGHT_SECTION.ICON.OPEN} />
          </div>
        </Column>
      </When>
      <When condition={!isDrawerVisible}>
        <Box className="border-icon-right">
          <div onClick={handleDrawer} className="circle">
            <Icon {...RIGHT_SECTION.ICON.CLOSED} />
          </div>
          <Typography {...RIGHT_SECTION.LABEL} />
        </Box>
      </When>
    </>
  );
};

export default RightSection;
