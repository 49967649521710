import {
  EXPENSES_LIST,
  EXPENSES_FILTER_DATA,
  EXPENSES_FILTER_ITEM,
} from "../service/types";

const initialState = {
  expensesList: {},
  expensesFilter: {},
  expensesFilterItem: {},
};
export default function cardExpenses(state = initialState, action) {
  switch (action.type) {
    case EXPENSES_LIST:
      return {
        ...state,
        expensesList: {
          ...state.expensesList,
          [action.status]: action.payload,
        },
      };
    case EXPENSES_FILTER_DATA:
      return { ...state, expensesFilter: action.payload };
    case EXPENSES_FILTER_ITEM:
      return { ...state, expensesFilterItem: action.payload };
    default:
      return state;
  }
}
