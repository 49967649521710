import { LAMBDA_API_ENDPOINTS } from "../constants";
import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import {
  isDevelopmentEnvironment,
  isEngageEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
} from "../utils";

export async function getWorkFlows(type) {
  const res = await apiClient({
    url: PATH.smart_approval.getAllWorkFlows(type),
    method: "GET",
  });
  return res?.data?.result?.map((workflow) => ({
    label: workflow?.workflow_name,
    value: workflow?.id,
    stepCount: workflow?.workflow_children?.length,
    workflow,
  }));
}

export async function assignWorkFlow(data) {
  return apiClient({
    url: PATH.smart_approval.assignWorkFlowToInvoicePo,
    method: "POST",
    data,
  });
}

export function getLambdaURl() {
  let urlParams = "";
  if (isDevelopmentEnvironment()) {
    urlParams = LAMBDA_API_ENDPOINTS.dev;
  }
  if (isStageEnvironment()) {
    urlParams = LAMBDA_API_ENDPOINTS.stage;
  }
  if (isPlatformEnvironment() && !isEngageEnvironment()) {
    urlParams = LAMBDA_API_ENDPOINTS.platform;
  }
  if (isEngageEnvironment()) {
    urlParams = LAMBDA_API_ENDPOINTS.engage;
  }
  return urlParams;
}

export async function assignWorkFlowWithLambda(data) {
  const urlParams = getLambdaURl();
  return apiClient({
    url: PATH.smart_approval.assignWorkFlowLambdaApi(urlParams),
    method: "POST",
    data,
  });
}

export async function reassignWorkFlowWithLambda(data) {
  const urlParams = getLambdaURl();
  return apiClient({
    url: PATH.smart_approval.reassignWorkFlowLambdaApi(urlParams),
    method: "PUT",
    data,
  });
}

export async function unassignWorkFlowWithLambda(data) {
  const urlParams = getLambdaURl();
  return apiClient({
    url: PATH.smart_approval.unassignWorkFlowLambdaApi(urlParams),
    method: "PUT",
    data,
  });
}

export async function getWorkflowCategory(activeKey) {
  const res = await apiClient({
    url: PATH.workflow.getWorkflowCategory,
    method: "GET",
  });

  return res?.data?.result?.map((workflow) => ({
    label: workflow?.workflow_type,
    value: workflow?.id,
  }));
}

export function createWorkFlow(data) {
  return apiClient({
    url: PATH.workflow.createNewWorkflow,
    method: "POST",
    data,
  });
}

export function updateWorkFlow(data) {
  return apiClient({
    url: PATH.workflow.updateWorkflow,
    method: "POST",
    data,
  });
}

export function updateWorkFlowLambdaApi(data) {
  const urlParams = getLambdaURl();
  return apiClient({
    url: PATH.workflow.updateWorkflowLambdaApi(urlParams),
    method: "POST",
    data,
  });
}

export function getWorkflowSteps({ type, data }) {
  return apiClient({
    url: PATH.getWorkflowSteps({ type }),
    method: "POST",
    data,
  });
}

export function resendApprovalEmail({ data }) {
  return apiClient({
    url: PATH.emailAction.resendApprovalMail,
    method: "POST",
    data,
  });
}
