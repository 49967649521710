export const ACKNOWLEDGEMENT_CONSTANT = {
  APPROVED: {
    title: "Approval made",
    icon: "check_circle_outline",
    variant: "success",
    bodyText: (type) =>
      `This workflow step has been approved. Once the approval workflow is completed, the approved ${type} can be viewed in the Kloo platform.`,
  },
  ALREADY_APPROVED: {
    title: "Approval made",
    icon: "check_circle_outline",
    variant: "success",
    bodyText: (type) =>
      `This workflow step has already been approved. Once the approval workflow is completed, the approved ${type} can be viewed in the Kloo platform.`,
  },
  REJECTED: {
    title: "Request rejected",
    icon: "cancel",
    variant: "fail",
    bodyText: (type) =>
      `Thank you! This rejection reason will be shared with the raiser of the ${type}.`,
    buttonText: "Rejected",
    buttonDisabled: true,
  },
  REJECTED_PO_WITH_REASON: {
    title: "Request rejected",
    icon: "cancel",
    variant: "fail",
    bodyText: (type) =>
      `Thank you! This rejection reason has been shared with the raiser of the ${type}.`,
    buttonText: "Rejected",
    buttonDisabled: true,
  },
  REJECTED_PO: {
    title: "Request rejected",
    icon: "cancel",
    variant: "fail",
    bodyText: (type) =>
      "This request has been rejected, and can be viewed within the Kloo platform.",
  },
  ALREADY_REJECTED: {
    title: "Request rejected",
    icon: "cancel",
    variant: "fail",
    bodyText: (type) =>
      "This request has already been rejected, and can be viewed within the Kloo platform.",
  },
  REJECTION_REASON: {
    title: "Reject request",
    icon: "cancel",
    variant: "fail",
    bodyText: (type) => "Are you sure that you want to reject this request?",
  },
  INVALID: {
    title: "Request link invalid",
    icon: "error_outline",
    variant: "invalid",
    bodyText: (type) =>
      "This request link is no longer valid; due to either expiration or cancellation. Please log into Kloo to view all outstanding requests.",
  },
  EXPIRED: {
    title: "Request link expired",
    icon: "hourglass_bottom",
    variant: "expired",
    bodyText: (type) =>
      "This request link is expired. Please log in to platform to action this request.",
  },
  PAGE_CLOSE_TEXT: "You can close this page now.",
  FOOTER_TEXT: "Want to log in to the platform?",
  FOOTER_LINK: "Click here!",
  KEY_MAP: {
    expired: "EXPIRED",
    approved: "APPROVED",
    rejected: "REJECTED",
    already_approved: "ALREADY_APPROVED",
    already_rejected: "ALREADY_REJECTED",
    invalid: "INVALID",
    rejection_reason: "REJECTION_REASON",
    rejected_po: "REJECTED_PO",
    rejected_po_with_reason: "REJECTED_PO_WITH_REASON",
    "in-progress": "IN_PROGRESS",
    submitted: "SUBMITTED",
  },
  REJECTION_REASON_INPUT: {
    inputName: "rejectionReason",
    placeholder: "Rejection reason",
    buttonText: "Reject",
    status: "rejected",
  },
  DUPLICATE_REQUEST: {
    title: "Duplicate request",
    icon: "access_time",
    variant: "invalid",
    bodyText: (type) => "Your request has already been processed.",
  },
  IN_PROGRESS: {
    title: "Request in progress",
    icon: "access_time",
    variant: "invalid",
    bodyText: (type) => "Your request is in progress.",
  },
  SUBMITTED: {
    title: "Request submitted",
    variant: "success",
    icon: "send",
    bodyText: (type) =>
      "This request has been submitted and is now in pending approval.",
  },
};
