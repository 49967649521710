import { memo } from "react";
import { Row as ARow } from "antd";

function Row({ children, gutter = [8, 8], ...rest }) {
  return (
    <ARow gutter={gutter} {...rest}>
      {children}
    </ARow>
  );
}

export default memo(Row);
