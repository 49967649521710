import { useSelector } from "react-redux";

function usePermission(
  parentKey = "",
  permissionKey = "",
  isReturnHookCallback = false
) {
  const permissionsList = useSelector(
    (state) => state && state?.menuReducer && state?.menuReducer?.menuList
  );

  function checkPermission({ parentKey, permissionKey }) {
    return permissionsList[parentKey]?.includes(permissionKey || "");
  }

  if (isReturnHookCallback) {
    return checkPermission;
  }

  if (!Array?.isArray(permissionsList[parentKey])) {
    return false;
  }

  return checkPermission({ parentKey, permissionKey });
}

export default usePermission;
