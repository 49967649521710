import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import { MODULAR_ACCOUNT_DETAILS } from "../service/types";
import { loadingStart, loadingStop } from "./loader";

export const modulaAccountDetails = (orgId) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.modular.accountDetails(orgId),
    })
      .then((response) => {
        resolve(response?.data?.result);
        dispatch({
          type: MODULAR_ACCOUNT_DETAILS,
          payload: response?.data?.result,
        });
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
