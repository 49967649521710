import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Row,
  Column,
  Typography,
  Box,
  Filter,
  Chips,
  TextField,
  Icon,
  When,
  CustomButton,
  ActionLink,
  CommonInputNumber,
  InfiniteScrollTable,
} from "../../../common";

import {
  ACCENTS,
  BUTTON_VARIANT,
  CURRENCIES,
  INVOICE_MANAGEMENT_MATCHING_TEXTS,
  INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS_PO_NUMBER_KEY,
  INVOICE_MATCHING_DEFAULT_PAGE_SIZE,
  INVOICE_MATCHING_DEFAULT_ROW_HEIGHT,
  INVOICE_MATCHING_MODAL_SELECTED_TABLE_LISTING_COLUMNS,
  INVOICE_MATCHING_MODAL_TABLE_LISTING_COLUMNS,
  INVOICE_MATCHING_TABLE_COLUMNS_ALLOCATED_AMOUNT_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_GRNI_OFFSET_LABEL_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_SELECTION_REMOVE_KEY,
  INVOICE_SPLITTING_CONSTANT,
  INVOICE_SPLITTING_GRNI_OFFSET_CONFIG,
  NAVIGATION_URL,
  NOTIFICATION_TYPE,
} from "../../../../constants";
import {
  allowOnlyNumberOnInputChangeUsingValue,
  calculatePercentageAmount,
  convertToGBP,
  formatCurrency,
  generateEncodedSearchURI,
  generateEncodedURI,
  getCurrenciesFromLS,
  getLeastThresholdValue,
  getOnlyAmount,
  moneyFormatter,
  roundOff,
  showNotification,
} from "../../../../utils";
import {
  getInvoiceMatchingSplitList,
  getThreshold,
  matchPOToInvoice,
} from "../../../../api";
import InvoiceMatchingTable from "../../../InvoiceMatching/components/InvoiceMatchingTable";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { PoundOutlined } from "@ant-design/icons";
import CommonToolTip from "../../../common/Tooltip";
import { useToggle } from "../../../../hooks";
import GRNIOffsetTabularView from "./GRNIOffsetTabularView";
import { getPONumberFilterURL } from "../../../../helper";

function InvoiceSplitListing({ data, handleClose, reloadTable, windowHeight }) {
  const allocatedAmountRef = useRef({});
  const { isOpen: enableGRNIOffsetView, toggle: toggleGRNIOffsetView } =
    useToggle();
  const {
    TITLE,
    SUBTITLE,
    GRNI_OFFSET_NOTIFICATION_1,
    GRNI_OFFSET_NOTIFICATION_2,
  } = INVOICE_SPLITTING_GRNI_OFFSET_CONFIG;
  const [search, setSearch] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [threshold, setThreshold] = useState(0);
  const [fixedAmount, setFixedAmount] = useState(0);
  const [
    totalAllocatedAmountWithThreshold,
    setTotalAllocatedAmountWithThreshold,
  ] = useState(0);
  const [form] = Form.useForm();
  const ref = useRef();
  const currencies = getCurrenciesFromLS();

  const tableHeight = (windowHeight - 510) / 2;

  const updateThePODetails = async () => {
    const { filtersUrl, searchUrl } = getApiUrls({ onlyPOFilter: true });

    const fromValues = {};
    const response = await getInvoiceMatchingSplitList({
      invoiceNumber: data.id,
      page: 1,
      filtersUrl:
        filtersUrl + "&includeZeroRemainingPOs=true&includeClosedPOs=true",
      searchUrl,
      pageSize: 50,
    });
    const selectedPOs = response?.list?.map((item, idx) => {
      const newItem = { ...item };
      const matchedPO = data?.poDetail?.find((i) => i.id === item.id);
      newItem.remaining_amount =
        getOnlyAmount(`${newItem.remaining_amount}`) +
        getOnlyAmount(`${matchedPO.restored_amount}`);

      if (!!matchedPO) {
        fromValues[`allocatedAmount${newItem.id}`] = getOnlyAmount(
          `${matchedPO.restored_amount}`
        );
      }
      const remainingAmount = newItem.remaining_amount;
      const netAmount = getOnlyAmount(`${newItem.net_amount}`);
      const currency = newItem.currency?.currency;
      const formattedRemainingAmount = moneyFormatter(
        remainingAmount,
        null,
        currency
      );
      const isNonGBP = currency !== CURRENCIES.GBP;
      newItem.formattedGrossAmount = moneyFormatter(netAmount, null, currency);
      newItem.formattedRemainingAmount = isNonGBP
        ? `${formattedRemainingAmount} (${moneyFormatter(
            convertToGBP(remainingAmount, currencies, currency)
          )})`
        : formattedRemainingAmount;
      newItem.remainingAmountInGBP = isNonGBP
        ? convertToGBP(remainingAmount, currencies, currency)
        : remainingAmount;
      return newItem;
    });
    form.setFieldsValue(fromValues);
    setSelectedInvoices(selectedPOs);
  };

  useEffect(() => {
    getThreshold().then((res) => {
      setThreshold(res.threshold_value || 0);
      setFixedAmount(res.fixed_value || 0);
    });
    if (data?.poDetail) {
      updateThePODetails();
    }
  }, [data]);

  const getApiUrls = ({ onlyPOFilter = false }) => {
    const filtersUrl = onlyPOFilter
      ? getPONumberFilterURL({
          poNumberArray: data?.poDetail?.map(
            ({ order_number }) => order_number
          ),
        })
      : generateEncodedURI(selectedFilters, filters);
    const searchUrl = generateEncodedSearchURI(search);
    return {
      searchUrl,
      filtersUrl,
    };
  };

  const onNext = async ({ page, height }) => {
    const { filtersUrl, searchUrl } = getApiUrls({ onlyPOFilter: false });

    let pageSize = (height - 300) / INVOICE_MATCHING_DEFAULT_ROW_HEIGHT;
    pageSize =
      pageSize > INVOICE_MATCHING_DEFAULT_PAGE_SIZE
        ? pageSize
        : INVOICE_MATCHING_DEFAULT_PAGE_SIZE;

    const response = await getInvoiceMatchingSplitList({
      invoiceNumber: data.id,
      page,
      filtersUrl,
      searchUrl,
      pageSize,
    });

    if (page === 1) {
      setFilters(response.filters);
    }

    return response;
  };

  const handleBlur = () => {
    const total = calculateTotalAllocatedGBPAmount();

    const consideredAmount = selectedInvoices.reduce(
      (accumulator, currentValue) => {
        const thresholdAmount = calculatePercentageAmount(
          threshold,
          currentValue.remainingAmountInGBP
        );
        let thresholdToConsider =
          threshold && fixedAmount
            ? Math.min(thresholdAmount, fixedAmount)
            : threshold
            ? thresholdAmount
            : fixedAmount;
        return accumulator + thresholdToConsider;
      },
      0
    );
    setTotalAllocatedAmountWithThreshold(total + consideredAmount);
  };

  const navigate = useNavigate();

  const goToPOManagementFromManual = (invoice) => {
    navigate(NAVIGATION_URL.PO_MANAGEMENT, {
      state: { purchaseOrder: { ...invoice }, persistFilters: true },
    });
  };

  const renderBody = ({ label, key, record, index, defaultRender }) => {
    const allocatedAmount =
      form.getFieldValue(`allocatedAmount${record.id}`) || 0;
    const currency = record?.currency?.currency || record?.currency;
    const currencySymbol = record?.currency?.symbol || record?.symbol;
    return key === INVOICE_MATCHING_TABLE_COLUMNS_SELECTION_REMOVE_KEY ? (
      <CustomButton
        icon={<Icon iconName={"close"} />}
        className={"destructive"}
        variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
        accent={ACCENTS.DESTRUCTIVE}
        onClick={() => handleSelection({ record })}
      />
    ) : key === INVOICE_MATCHING_TABLE_COLUMNS_ALLOCATED_AMOUNT_KEY ? (
      <Form form={form}>
        <Box justifyContent="flexStart">
          <CommonInputNumber
            name={`allocatedAmount${record.id}`}
            rules={[validateAllocatedAmount(record)]}
            // onBlur={handleBlur}
            wrapperClass={"allocated-amount-text-field w-80"}
            step={0.01}
            controls={false}
            prefix={currencySymbol}
            placeholder={"Enter amount"}
            formatter={(n) => formatCurrency(n)}
            // parser={(n) => parseCurrency(n)}
            onInput={allowOnlyNumberOnInputChangeUsingValue}
            precision={2}
            min={0}
            onKeyUp={handleBlur}
          />

          <When condition={currency !== CURRENCIES.GBP}>
            <CommonToolTip
              title={
                allocatedAmount
                  ? moneyFormatter(
                      convertToGBP(allocatedAmount, currencies, currency)
                    )
                  : ""
              }
            >
              <PoundOutlined style={{ fontSize: 18 }} />
            </CommonToolTip>
          </When>
        </Box>
      </Form>
    ) : key === INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS_PO_NUMBER_KEY ? (
      <ActionLink
        onClick={() => goToPOManagementFromManual(record)}
        text={
          <Typography
            text={record[key]}
            variant={"body"}
            className="text-link"
            ellipsis={{
              tooltip: record[key],
            }}
          />
        }
      />
    ) : (
      defaultRender({ label, key, record, index, defaultRender })
    );
  };

  const handleSubmit = async () => {
    try {
      const reqBody = {
        invoice_id: data.id,
        match_by: "manual",
        request_type: "split",
        purchase_order_ids: selectedInvoices.map((invoice, idx) => {
          let extraData = {};
          const grniOffsetForPO = form.getFieldValue(
            `${INVOICE_MATCHING_TABLE_COLUMNS_GRNI_OFFSET_LABEL_KEY}${idx}`
          );
          if (enableGRNIOffsetView && grniOffsetForPO !== undefined) {
            const offsetInPayload = Number(grniOffsetForPO);
            extraData.grni_amount =
              offsetInPayload === 0 ? offsetInPayload : -offsetInPayload;
          }
          return {
            id: invoice.id,
            allocated_amount: form.getFieldValue(
              `allocatedAmount${invoice.id}`
            ),
            ...extraData,
          };
        }),
      };
      await matchPOToInvoice(reqBody);
      reloadTable();
      handleClose();
      showNotification(
        NOTIFICATION_TYPE.success,
        INVOICE_SPLITTING_CONSTANT.SPLIT_SUCCESS_MSG
      );
    } catch (error) {}
  };

  const handleContinue = (allocatedAmountValue) => {
    allocatedAmountRef.current = allocatedAmountValue;
    toggleGRNIOffsetView();
  };

  const getMaxAllocationValue = (record) =>
    record.remainingAmountInGBP +
    getLeastThresholdValue({
      fixedAmount,
      threshold,
      poNetAmount: record.remainingAmountInGBP,
    });

  const getInvoiceRemainingAmount = (record) =>
    convertedInvoiceGBPAmount -
    calculateTotalAllocatedGBPAmount(
      selectedInvoices.filter((item) => item.id !== record.id)
    );

  const validateAllocatedAmount = (record) => {
    const remainingAmount = getOnlyAmount(`${record.remaining_amount}`);
    const formattedInvoiceNetAmount = moneyFormatter(
      data.net_amount,
      2,
      data.currency.currency
    );
    return {
      validator(_, value) {
        const maxAllocationAmount = getMaxAllocationValue(record);
        const invoiceRemainingAmount = getInvoiceRemainingAmount(record);
        const maxValue =
          maxAllocationAmount > invoiceRemainingAmount
            ? invoiceRemainingAmount
            : maxAllocationAmount;
        if (!value) {
          return Promise.reject("Please enter allocated amount");
        }
        let convertedAllocatedGBPAmount = Number(value);
        if (remainingAmount !== record.remainingAmountInGBP) {
          convertedAllocatedGBPAmount = convertToGBP(
            convertedAllocatedGBPAmount,
            getCurrenciesFromLS(),
            record?.currency?.currency || record?.currency
          );
        }
        if (convertedAllocatedGBPAmount <= 0) {
          return Promise.reject(
            new Error("Allocated amount must be greater than zero")
          );
        }
        if (
          selectedInvoices.length === 1 &&
          convertedAllocatedGBPAmount > convertedInvoiceGBPAmount
        ) {
          return Promise.reject(
            new Error(
              `Allocated amount should be less than ${formattedInvoiceNetAmount}`
            )
          );
        }
        if (
          selectedInvoices.length > 1 &&
          convertedAllocatedGBPAmount >= convertedInvoiceGBPAmount
        ) {
          return Promise.reject(
            new Error(
              `Allocated amount should be less than ${formattedInvoiceNetAmount}`
            )
          );
        }
        if (convertedAllocatedGBPAmount > maxValue) {
          return Promise.reject(
            new Error(
              `Allocated amount should be less than ${moneyFormatter(maxValue)}`
            )
          );
        }
        return Promise.resolve();
      },
    };
  };

  const calculateTotalAllocatedGBPAmount = (poList) => {
    let total = 0;
    const selectedPOs = poList || selectedInvoices;
    selectedPOs.forEach((record, idx) => {
      let fieldValue = form.getFieldValue(`allocatedAmount${record.id}`);
      if (fieldValue) {
        if (record.remaining_amount !== record.remainingAmountInGBP) {
          fieldValue = convertToGBP(
            Number(fieldValue),
            getCurrenciesFromLS(),
            record?.currency?.currency || record?.currency
          );
        }
        total = total + fieldValue;
      }
    });
    return roundOff(total);
  };

  const doesArrayIncludes = (data, record) => {
    return data.findIndex((item) => item.id === record.id) > -1;
  };

  const handleSelection = ({ record }) => {
    if (doesArrayIncludes(selectedInvoices, record)) {
      const filteredData = selectedInvoices.filter(
        (item) => item.id !== record.id
      );
      setSelectedInvoices(filteredData);

      //adding the unselected item back to original data of API call
      const data = ref?.current?.data;
      ref?.current?.setData([record, ...data]);

      form.setFieldValue(`allocatedAmount${record.id}`, "");
      handleBlur();
    } else {
      setSelectedInvoices([...selectedInvoices, record]);
    }
  };

  const triggerNextPage = () => {
    const { handleOnNext } = ref?.current;
    handleOnNext();
  };

  const filterSelectedData = ({ data }) => {
    //removing the selected item from original data of API call without refreshing the table

    const tempData = data?.filter(
      (item) => !doesArrayIncludes(selectedInvoices, item)
    );
    if (data?.length !== tempData?.length) {
      ref?.current?.setData([...tempData]);
      if (tempData?.length === 0) {
        triggerNextPage();
      }
    }
  };

  const onDataLoad = ({ data }) => {
    filterSelectedData({ data });
  };

  useEffect(() => {
    filterSelectedData({ data: ref?.current?.data });
  }, [selectedInvoices, search, selectedFilters]);

  const convertedInvoiceGBPAmount = Number(
    currencies?.length && data?.currency?.currency === CURRENCIES.GBP
      ? data["net_amount"]
      : convertToGBP(data["net_amount"], currencies, data?.currency?.currency)
  );

  const selectedInvoiceRemainingAmount =
    convertedInvoiceGBPAmount - calculateTotalAllocatedGBPAmount();

  const selectedPOsHasGRNI = useMemo(() => {
    return selectedInvoices.some(
      ({ total_grni_amount }) => getOnlyAmount(total_grni_amount) !== 0
    );
  }, [selectedInvoices]);

  const showGRNIExistsToLoggedInUser =
    !enableGRNIOffsetView && selectedPOsHasGRNI;

  async function handleSubmitClick() {
    const allocatedAmountValue = await form.validateFields();
    if (calculateTotalAllocatedGBPAmount() > convertedInvoiceGBPAmount) {
      let errorFields = [];
      selectedInvoices.forEach((_, idx) => {
        errorFields.push({
          name: `allocatedAmount${_.id}`,
          errors: [""],
        });
      });
      form.setFields(errorFields);
      showNotification(
        NOTIFICATION_TYPE.error,
        INVOICE_SPLITTING_CONSTANT.GREATER_ALLOCATED_AMOUNT_ERR
      );
      return;
    }
    if (showGRNIExistsToLoggedInUser && !enableGRNIOffsetView) {
      return handleContinue(allocatedAmountValue);
    } else {
      return handleSubmit();
    }
  }

  function handleCancelClick() {
    if (enableGRNIOffsetView) {
      toggleGRNIOffsetView();
      form.resetFields();
    } else {
      handleClose();
    }
  }

  return (
    <Row className={"h-100"}>
      <Column span={24}>
        <Box direction={"column"} className={"h-100 w-100 p-v-8px"}>
          <Row className={"w-100 p-h-16px"}>
            <Column span={24}>
              <Row>
                <Typography
                  variant="title"
                  fontSize={24}
                  responsiveFontSize={false}
                  fontStyle={"semibold"}
                  text={
                    enableGRNIOffsetView
                      ? TITLE
                      : INVOICE_MANAGEMENT_MATCHING_TEXTS.title
                  }
                />
              </Row>
              <Row className={"m-t-16px"}>
                <Typography
                  fontSize={16}
                  fontStyle={"semibold"}
                  variant="secondary"
                  text={
                    enableGRNIOffsetView
                      ? SUBTITLE
                      : INVOICE_MANAGEMENT_MATCHING_TEXTS.subTitle
                  }
                />
              </Row>
            </Column>
          </Row>
          <When condition={enableGRNIOffsetView}>
            <GRNIOffsetTabularView
              data={data}
              poDetails={selectedInvoices}
              allocatedAmount={allocatedAmountRef?.current}
              onCancel={toggleGRNIOffsetView}
              form={form}
              tableHeight={tableHeight}
              threshold={{
                threshold_value: threshold,
                fixed_value: fixedAmount,
              }}
            />
          </When>
          <When condition={!enableGRNIOffsetView}>
            <Row className={"flex-1 m-h-0px"}>
              <Column span={24}>
                <Row className={"m-h-16px"}>
                  <Column span={24}>
                    <Row>
                      <Column span={18}>
                        <Box justifyContent="flexStart" rowGap={0}>
                          <Filter
                            onSelectedChange={({ keys }) => {
                              setSelectedFilters(keys);
                            }}
                            items={filters}
                            value={selectedFilters}
                          />
                          <Chips
                            items={filters}
                            removeKey={(keys) => {
                              setSelectedFilters(
                                selectedFilters.filter(
                                  (item) => !keys.includes(item)
                                )
                              );
                            }}
                            selectedKeys={selectedFilters}
                          />
                        </Box>
                      </Column>
                      <Column span={6}>
                        <TextField
                          placeholder="Search"
                          prefix={<Icon iconName={"search"} />}
                          className="historySearch"
                          value={search}
                          onChange={setSearch}
                          customSize={"small"}
                        />
                      </Column>
                    </Row>
                  </Column>
                  <Column span={24}>
                    <Row>
                      <InvoiceMatchingTable
                        columns={INVOICE_MATCHING_MODAL_TABLE_LISTING_COLUMNS}
                        onNext={onNext}
                        search={search}
                        selectedFilters={selectedFilters}
                        height={tableHeight}
                        handleSelection={handleSelection}
                        className={"p-h-0px"}
                        selectedInvoices={selectedInvoices}
                        tableRef={ref}
                        onDataLoad={onDataLoad}
                        showScrollBar={true}
                      />
                    </Row>
                  </Column>
                </Row>
              </Column>
              <div className={"divider"} />

              <When condition={!!selectedInvoices.length}>
                <Row className={" m-h-16px p-h-0px h-100"}>
                  <Column span={24}>
                    <Row className={"m-t-16px p-b-16px"}>
                      <Typography
                        fontSize={16}
                        fontStyle={"semibold"}
                        variant="secondary"
                        text={INVOICE_MANAGEMENT_MATCHING_TEXTS.selectionText}
                      />
                    </Row>
                    <Row>
                      <div style={{ maxHeight: tableHeight }}>
                        <InfiniteScrollTable
                          columns={
                            INVOICE_MATCHING_MODAL_SELECTED_TABLE_LISTING_COLUMNS
                          }
                          className={"p-h-0px"}
                          tableClassName={"m-v-0px"}
                          renderBody={renderBody}
                          data={selectedInvoices}
                          showScrollBar={true}
                          isInfiniteScroll={false}
                          height={`${tableHeight - 50}px`}
                        />
                      </div>
                    </Row>
                  </Column>
                </Row>
              </When>
              <When condition={!selectedInvoices.length}>
                <Row
                  className={" p-h-16px w-100"}
                  style={{ height: tableHeight }}
                >
                  <Column span={24}>
                    <Box
                      justifyContent="center"
                      alignContent="center"
                      className={"h-100 w-100"}
                    >
                      <Typography
                        variant="body"
                        text={INVOICE_SPLITTING_CONSTANT.INFO_MSG}
                      />
                    </Box>
                  </Column>
                </Row>
              </When>
            </Row>
            <div className={"divider"} />
          </When>
          <Row className="w-100 action-btn-parent">
            <Box className={"w-100 p-h-16px"} justifyContent="spaceBetween">
              <Box
                direction="column"
                alignItem="flexStart"
                rowGap={showGRNIExistsToLoggedInUser ? 0 : 8}
              >
                <When condition={!!selectedInvoices?.length}>
                  <When condition={showGRNIExistsToLoggedInUser}>
                    <Typography
                      variant="body"
                      text={GRNI_OFFSET_NOTIFICATION_1}
                    />
                    <Typography
                      variant="body"
                      text={GRNI_OFFSET_NOTIFICATION_2}
                    />
                  </When>
                  <When
                    condition={
                      !showGRNIExistsToLoggedInUser && !enableGRNIOffsetView
                    }
                  >
                    <Typography
                      variant="secondary"
                      text={INVOICE_SPLITTING_CONSTANT.REMAINING_INVOICE_AMOUNT(
                        moneyFormatter(selectedInvoiceRemainingAmount)
                      )}
                      fontSize="16"
                      fontStyle="semibold"
                    />
                  </When>
                  <When condition={!showGRNIExistsToLoggedInUser}>
                    <Typography
                      variant="body"
                      text={INVOICE_SPLITTING_CONSTANT.CONFIRMATION_TEXT(
                        totalAllocatedAmountWithThreshold >=
                          convertedInvoiceGBPAmount
                          ? INVOICE_SPLITTING_CONSTANT.SPLIT_TYPE.CONFIRMED
                          : INVOICE_SPLITTING_CONSTANT.SPLIT_TYPE.PARTIAL
                      )}
                    />
                  </When>
                </When>
              </Box>
              <Box>
                <CustomButton
                  text={
                    showGRNIExistsToLoggedInUser
                      ? INVOICE_SPLITTING_CONSTANT.BUTTONS_LABEL.CONTINUE
                      : INVOICE_SPLITTING_CONSTANT.BUTTONS_LABEL.SPLIT
                  }
                  onClick={handleSubmitClick}
                  showLoader={!showGRNIExistsToLoggedInUser}
                  disabled={!selectedInvoices.length}
                />
                <CustomButton
                  variant={BUTTON_VARIANT.BUTTON_TEXT}
                  accent={ACCENTS.BLACK}
                  text={INVOICE_SPLITTING_CONSTANT.BUTTONS_LABEL.CANCEL}
                  onClick={handleCancelClick}
                />
              </Box>
            </Box>
          </Row>
        </Box>
      </Column>
    </Row>
  );
}

export default InvoiceSplitListing;
