import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import {
  approvePurchaseOrder as closePurchaseOrder,
  downloadPDF,
} from "../../../api";
import {
  ACCENTS,
  BUTTON_VARIANT,
  ERROR_MSG,
  PO_ACTIONS,
  PO_APPROVE_REJECT_DRAWER,
  PURCHASE_ORDER_CLOSE_DRAWER,
  PURCHASE_ORDER_TOAST_MSGS,
} from "../../../constants";
import {
  NOTIFICATION_TYPE,
  downloadFileByJS,
  showNotification,
} from "../../../utils";
import {
  Box,
  Column,
  CustomButton,
  Divider,
  Drawer,
  Icon,
  PDFViewer,
  Row,
  Table,
  Typography,
  When,
} from "../../common";
import "./close-purchase-order.scss";

function ClosePurchaseOrderDrawer({
  open,
  purchaseOrderList,
  reloadTable,
  onClose,
  setSelectedItems,
}) {
  const [detail, setDetail] = useState(purchaseOrderList?.[0] || {});
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [orderList, setOrderList] = useState(purchaseOrderList);
  const [pdfLink, setPDFLink] = useState(null);

  const handleClosePo = async () => {
    try {
      let payload = {
        ids: orderList.map((item) => item.id),
        is_workflow_assigned: true,
      };
      await closePurchaseOrder(PO_ACTIONS.CLOSE, payload);
      showNotification(
        NOTIFICATION_TYPE.success,
        PURCHASE_ORDER_TOAST_MSGS.closed(orderList.length > 1)
      );
      reloadTable();
      onClose();
    } catch (e) {
      showNotification(
        NOTIFICATION_TYPE.error,
        e?.response?.data?.message || ERROR_MSG.common
      );
    }
  };

  const handlePDFView = useCallback((PoId) => {
    downloadPDF(PoId)
      .then((res) => {
        setPDFLink(res?.data?.result?.download_link);
      })
      .catch((e) =>
        showNotification(
          NOTIFICATION_TYPE.error,
          e?.response?.data?.message || ERROR_MSG.common
        )
      );
  }, []);

  const onDownloadClick = () => {
    downloadFileByJS(pdfLink, detail?.order_number || "");
  };

  useEffect(() => {
    if (purchaseOrderList?.[0]?.id) {
      handlePDFView(purchaseOrderList?.[0]?.id || "");
    }
  }, []);

  const handleRemove = () => {
    const filterRecords = orderList.filter((item) => item.id !== detail.id);
    setOrderList(filterRecords);
    setSelectedItems(filterRecords);
    const ifSelectedInvoiceExist = filterRecords.findIndex(
      (item) => item.id === detail.id
    );
    if (ifSelectedInvoiceExist === -1) {
      setDetail(filterRecords[0]);
      setSelectedRowIndex(0);
    } else {
      setDetail(filterRecords[ifSelectedInvoiceExist]);
      setSelectedRowIndex(ifSelectedInvoiceExist);
    }
  };

  const handleRowSelection = ({ record }) => {
    setDetail(record);
    handlePDFView(record.id);
  };

  return (
    <Drawer
      className="close-drawer"
      open={open}
      onClose={onClose}
      title={PURCHASE_ORDER_CLOSE_DRAWER.TITLE}
      width={1200}
    >
      <Row gutter={10} className={"close-wrapper h-100"}>
        <Column {...{ xs: 24, sm: 24, md: 24, lg: 11 }}>
          <Typography
            className="m-b-26px"
            variant="secondary"
            text={PURCHASE_ORDER_CLOSE_DRAWER.INFO(orderList?.length)}
          />
          <Table
            key={Object.values({ orderList, selectedRowIndex }).join("-")}
            tableClassName="m-b-0px close-table"
            columns={PURCHASE_ORDER_CLOSE_DRAWER.CLOSE_TABLE_COLUMN}
            data={orderList}
            enableRowHighlight
            onRowClick={handleRowSelection}
            enableSerialNumber
            defaultSelectedRow={selectedRowIndex}
            showScrollBar={true}
          />
          <When condition={orderList?.length > 1}>
            <CustomButton
              text={PURCHASE_ORDER_CLOSE_DRAWER.REMOVE_BUTTON_LABEL}
              icon={<Icon iconName={"delete_outline"} />}
              accent={ACCENTS.DESTRUCTIVE}
              variant={BUTTON_VARIANT.BUTTON_TEXT}
              onClick={handleRemove}
              disabled={orderList.length === 1}
              className="m-t-10px float-end"
              customSize={"large"}
            />
          </When>
        </Column>
        <Column>
          <Divider type="vertical" className={"h-100"} />
        </Column>
        <Column flex="auto">
          <Box direction="column" className={"pdf-wrapper"} wrap="nowrap">
            <Box className={"h-100 w-100"}>
              <When condition={!!pdfLink}>
                <PDFViewer url={pdfLink} />
              </When>
            </Box>
            <Box direction="column" rowGap={5} className={"p-15px"}>
              <Box>
                <Typography
                  variant="secondary"
                  text={PURCHASE_ORDER_CLOSE_DRAWER.INFO_LABEL}
                />
                <Typography
                  variant="secondary"
                  fontStyle="semibold"
                  text={detail.order_number}
                />
              </Box>
              <Box>
                <Typography
                  variant="secondary"
                  text={PURCHASE_ORDER_CLOSE_DRAWER.APPROVED_BY}
                />
                <Typography
                  variant="secondary"
                  fontStyle="semibold"
                  text={detail.users_approved_rejected_by}
                />
              </Box>
            </Box>
          </Box>
        </Column>
      </Row>
      <Row className="purchase-drawer-footer  m-0" gutter={10}>
        <Column span={11}>
          <Row gutter={36} className={"m-l-0"}>
            <Column span={12}>
              <CustomButton
                text={PO_APPROVE_REJECT_DRAWER.CONFIRM_BUTTON_LABEL}
                accent={ACCENTS.PRIMARY}
                variant={BUTTON_VARIANT.BUTTON_FILLED}
                onClick={() => handleClosePo()}
                className={"w-100"}
                customSize={"large"}
              />
            </Column>
            <Column span={12}>
              <CustomButton
                text="Cancel"
                accent={ACCENTS.SECONDARY}
                variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                customSize={"large"}
                className={"w-100"}
                onClick={onClose}
              />
            </Column>
          </Row>
        </Column>
        <Column>
          <Divider type="vertical" className={"visibility-invisible"} />
        </Column>
        <Column flex="auto">
          <CustomButton
            text={PURCHASE_ORDER_CLOSE_DRAWER.DOWNLOAD_BUTTON_LABEL}
            icon={<Icon iconName={"file_download"} />}
            accent={ACCENTS.PRIMARY}
            variant={BUTTON_VARIANT.BUTTON_FILLED}
            onClick={onDownloadClick}
            disabled={!pdfLink}
            className="w-100"
            customSize={"large"}
          />
        </Column>
      </Row>
    </Drawer>
  );
}

ClosePurchaseOrderDrawer.propTypes = {
  open: PropTypes.bool,
  purchaseOrderList: PropTypes.array,
  reloadTable: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default ClosePurchaseOrderDrawer;
