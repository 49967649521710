import { useSelector } from "react-redux";
import { isFaUser, isOrgAdmin, isPlatformUser } from "../utils";

function useUserType() {
  const userType = useSelector(
    (state) => state && state?.menuReducer && state?.menuReducer?.userType
  );
  return {
    isOrgAdmin: isOrgAdmin(userType),
    isPlatformUser: isPlatformUser(userType),
    isFaUser: isFaUser(userType),
  };
}
export default useUserType;
