import React, { useRef, useState } from "react";
import {
  Box,
  CustomButton,
  Divider,
  Icon,
  useGlobalDrawerContext,
  When,
} from "../../../common";
import DeleteConfirmationModal from "../../../common/GlobalModal/DeleteConfirmationModal";
import TextDisplay from "../TextDisplay";
import {
  deleteSupplierAccount,
  isSupplierAccAssociatedToInvoice,
} from "../../../../api";
import { checkIfCurrentUserCanPerformActions } from "../../../../helper";
import {
  DRAWER_TYPES,
  scrollToTop,
  showNotification,
  getArrayLength,
} from "../../../../utils";
import {
  ACCENTS,
  ACCOUNT_DELETE_MODAL,
  ADD_NEW_SUPPLIER_FORM,
  BUTTON_VARIANT,
  DELETION_FAIL_MSG,
  ERROR_MSG,
  NOTIFICATION_TYPE,
} from "../../../../constants";
import "./ViewAccounts.scss";

function ViewAccounts({
  selectedSupplier,
  countries,
  reloadTable,
  currencies,
  getCurrencyNameFromId,
  currentUserPermissionConfig,
  onCancel,
}) {
  const { showDrawer } = useGlobalDrawerContext();
  const [deleteModal, setDeleteModal] = useState();
  const [accountsDetail, setAccountsDetails] = useState(
    selectedSupplier?.supplier_accounts
  );
  const ref = useRef(null);
  const isSupplierFromErp = selectedSupplier?.erp_is_supplier;

  const {
    SUPPLIERS_ACCOUNT_TYPES,
    accountAlias,
    accountName,
    accountNumber,
    sortCode,
    currency,
    addMoreAccountButton,
  } = ADD_NEW_SUPPLIER_FORM.ACCOUNT_FORM;

  const listTitle = [
    accountAlias,
    accountName,
    accountNumber,
    sortCode,
    currency,
  ];

  const hasAccessToPerformOperations = checkIfCurrentUserCanPerformActions({
    permissionConfig: currentUserPermissionConfig,
  });

  function accountListHandler(data) {
    if (getArrayLength(data) === 0) {
      onCancel();
      return;
    }
    setAccountsDetails(data);
  }

  function onEditClick(record) {
    showDrawer(DRAWER_TYPES.SM_ADD_ACCOUNTS_DRAWER, {
      countries,
      currencies,
      selectedSupplier,
      selectedAccount: record,
      setAccountsDetails,
      reloadTable,
      updateAccounts: true,
    });
  }

  async function onDeleteClick(id) {
    try {
      const haveAssociation = await isSupplierAccAssociatedToInvoice({
        accountId: id,
      });
      if (!haveAssociation) {
        setDeleteModal(id);
      } else {
        showNotification(
          NOTIFICATION_TYPE.error,
          DELETION_FAIL_MSG.SUPPLIER_ACC_DELETION
        );
      }
    } catch (error) {
      showNotification(
        NOTIFICATION_TYPE.error,
        error?.response?.data?.message || ERROR_MSG.common
      );
    }
  }

  function addMoreAccounts() {
    showDrawer(DRAWER_TYPES.SM_ADD_ACCOUNTS_DRAWER, {
      countries,
      currencies,
      selectedSupplier,
      setAccountsDetails,
      reloadTable,
      addMoreAccount: true,
      scrollToTop: () => scrollToTop(ref),
    });
  }

  function onCloseDeleteModal() {
    setDeleteModal(null);
  }

  function deleteAccountHandler(id) {
    deleteSupplierAccount(id)
      .then((res) => {
        reloadTable();
        showNotification(
          NOTIFICATION_TYPE.success,
          ACCOUNT_DELETE_MODAL.onDeleteMessage
        );
        accountListHandler(res?.supplier_accounts);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          showNotification(
            NOTIFICATION_TYPE.error,
            err?.response?.data?.message
          );
        }
      })
      .finally(() => onCloseDeleteModal());
  }

  return (
    <>
      <When condition={deleteModal}>
        <DeleteConfirmationModal
          open={deleteModal}
          title={ACCOUNT_DELETE_MODAL.title}
          subTitle={ACCOUNT_DELETE_MODAL.subBody}
          mandatoryCheckboxConfirmation={true}
          onCancel={onCloseDeleteModal}
          onConfirm={() => deleteAccountHandler(deleteModal)}
          deleteOnConfirm={async () => {}}
        />
      </When>

      <div
        className={"supplier-account-view-container mt-3 px-3 pt-3 m-auto w-60"}
        ref={ref}
      >
        {Array.isArray(accountsDetail) &&
          accountsDetail?.map((accountData) => {
            return (
              <div
                className={"supplier-account-view-wrapper w-100 "}
                direction={"column"}
              >
                <Box className={"account-actions"}>
                  <CustomButton
                    icon={<Icon iconName={"edit"} />}
                    accent={ACCENTS.BLACK}
                    variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
                    onClick={() => onEditClick(accountData)}
                    disabled={
                      !hasAccessToPerformOperations || isSupplierFromErp
                    }
                  />
                  <CustomButton
                    icon={
                      <Icon
                        iconName={"delete"}
                        className={"material-icons-outlined"}
                      />
                    }
                    accent={ACCENTS.DESTRUCTIVE}
                    variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
                    onClick={() => onDeleteClick(accountData?.id)}
                    disabled={
                      !hasAccessToPerformOperations || isSupplierFromErp
                    }
                  />
                </Box>
                <Box
                  direction="column"
                  justifyContent="flexStart"
                  alignContent="flexStart"
                  alignItem="flexStart"
                  className={"w-100"}
                >
                  {listTitle?.map((item) => {
                    let text = accountData[item.key];
                    if (item?.label === currency?.label) {
                      text = getCurrencyNameFromId(accountData[item.key]);
                    }
                    return <TextDisplay title={item?.label} text={text} />;
                  })}
                </Box>
                <Divider />
              </div>
            );
          })}
      </div>
      <Box className={"mt-2"}>
        <CustomButton
          icon={<Icon iconName={addMoreAccountButton.iconName} />}
          text={addMoreAccountButton.label}
          accent={ACCENTS.PRIMARY}
          variant={BUTTON_VARIANT.BUTTON_FILLED}
          onClick={addMoreAccounts}
          disabled={!hasAccessToPerformOperations || isSupplierFromErp}
          customSize={"large"}
        />
      </Box>
    </>
  );
}

export default ViewAccounts;
