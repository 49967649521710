import React, { useEffect, useState } from "react";
import { Column, Modal, Row } from "../../../common";
import InvoiceInfoJsx from "../../../InvoiceMatching/components/InvoiceInfo";
import "./InvoiceMatchingModal.scss";
import { useNavigate } from "react-router-dom";
import { NAVIGATION_URL } from "../../../../constants";
import InvoiceSplitListing from "./InvoiceSplitListing";

export default function InvoiceMatchingModal({
  open,
  title,
  data,
  reloadTable,
  handleClose,
}) {
  const navigate = useNavigate();

  const goToPOManagement = (invoice) => {
    navigate(NAVIGATION_URL.PO_MANAGEMENT, {
      state: {
        purchaseOrder: { ...invoice?.poDetail?.[0] },
        persistFilters: true,
      },
    });
  };

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Update the window height when the window is resized
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Add an event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Modal
      width={"95%"}
      isOpen={open}
      title={title}
      showHeader={true}
      hide={handleClose}
      showDivider={false}
      wrapClassName={"invoice-matching-modal"}
    >
      <Row
        gutter={32}
        className={"modal-parent"}
        style={{ height: windowHeight - 200 }}
      >
        <Column span={6} className={"border gutter-row h-100"}>
          <InvoiceInfoJsx
            resetSelectedInvoice={handleClose}
            selectedInvoice={data}
            goToPOManagement={goToPOManagement}
            showLineItems={true}
          />
        </Column>
        <Column span={18} className={"border gutter-row h-100 p-h-0px"}>
          <InvoiceSplitListing
            data={data}
            reloadTable={reloadTable}
            handleClose={handleClose}
            windowHeight={windowHeight}
          />
        </Column>
      </Row>
    </Modal>
  );
}
