import React from "react";
import { Modal } from "..";
import Table from "../Table/Table";
import "./item-list-table.scss";

function ItemListModal({
  title,
  subTitle,
  showDivider = false,
  open,
  onCancel,
  transformedData,
  billOfMaterials,
  renderBody,
  finalColumns,
}) {
  return (
    <Modal
      isOpen={open}
      width={1200}
      title={title}
      subtitle={subTitle}
      hide={onCancel}
      showDivider={showDivider}
    >
      <div className="kloo-layout-container item-list-container">
        <Table
          tableClassName="m-b-0px"
          columns={finalColumns}
          data={transformedData ?? billOfMaterials}
          showExpandIcon={false}
          renderBody={renderBody}
        />
      </div>
    </Modal>
  );
}

export default ItemListModal;
