import { TRACKING_CATEGORIES, TRACKING_CATEGORIES_MAP } from "../service/types";

const initialState = {
  trackingCategories: [],
  trackingCategoriesMap: [],
  customExpenseField: [],
};
export default function trackingCategories(state = initialState, action) {
  switch (action.type) {
    case TRACKING_CATEGORIES:
      return { ...state, trakingCategories: action.payload };
    case TRACKING_CATEGORIES_MAP:
      return { ...state, trackingCategoriesMap: action.payload };
    default:
      return state;
  }
}
