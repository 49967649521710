import React from "react";
import { DatePicker, Form } from "antd";
import Label from "../Label";
import When from "../When";
import "./CommonDatePicker.scss";
import { constructClassName } from "../../../utils";

const CommonDatePicker = (props) => {
  const {
    name,
    value,
    label,
    onChange,
    placeholder,
    picker,
    rules,
    validateTrigger,
    className,
    disabledDate,
    format = "DD/MM/YYYY",
    size,
    readOnly,
    suffixIcon,
    clearIcon,
    showToday = true,
    getPopupContainer,
    datePickerRef,
    type,
    optionalField = false,
    disabled = false,
    tooltip = false,
    labelSuffixIcon = false,
    suffixIconClassName,
    suffixIconTooltip,
    suffixTooltipList,
    allowClear,
  } = props;

  const isMandatoryField =
    Array.isArray(rules) && rules?.some((el) => el?.required === true);

  const optionalFieldClassName = isMandatoryField ? "" : "optional-field";

  return (
    <Form.Item
      name={name}
      label={
        label ? (
          <Label
            text={label}
            optional={!isMandatoryField}
            suffixIcon={labelSuffixIcon}
            suffixIconClassName={suffixIconClassName}
            suffixIconTooltip={suffixIconTooltip}
            suffixTooltipList={suffixTooltipList}
          />
        ) : null
      }
      rules={rules}
      validateTrigger={validateTrigger}
      colon={false}
      tooltip={tooltip}
    >
      <DatePicker
        popupClassName="kloo-date-picker"
        clearIcon={clearIcon}
        suffixIcon={suffixIcon}
        picker={picker}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabledDate={disabledDate}
        disabled={disabled}
        className={constructClassName([
          className || "",
          "common-date-picker",
          optionalFieldClassName,
        ])}
        format={format}
        size={size}
        readOnly={readOnly}
        showToday={showToday}
        getPopupContainer={getPopupContainer}
        allowClear={allowClear}
      />
    </Form.Item>
  );
};

export default CommonDatePicker;
