import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CloseOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import {
  Button,
  Drawer,
  Column,
  Row,
  Table,
  Typography,
  When,
  CustomButton,
  PreviewApprovalPath,
  EnvFeatureFlag,
  PreviewWorkflow,
  Box,
} from "../../common";
import CommonSelect from "../../common/Selector";
import { loadingStart, loadingStop } from "../../../redux/action/loader";
import {
  assignWorkFlow,
  assignWorkFlowWithLambda,
  getWorkFlows,
  reassignWorkFlowWithLambda,
  unassignWorkFlowWithLambda,
} from "../../../api";
import { PO_ASSIGN_FORM_RULE } from "../../../utils/validator";
import {
  MODAL_TYPES,
  NOTIFICATION_TYPE,
  showNotification,
} from "../../../utils";
import {
  ACCENTS,
  BUTTON_VARIANT,
  DELETE_BUTTON_KEY,
  ERROR_MSG,
  PO_ACTIONS,
  PURCHASE_ORDER_APPROVER_DRAWER,
  PURCHASE_ORDER_TOAST_MSGS,
  WORK_FLOW_TYPES,
  featureFlags,
} from "../../../constants";
import "./assign-purchase-order.scss";
import {
  isConditionalThresholdEnabledForPO,
  isWorkflowApiChangeToLambda,
} from "../../../helper";
import { PurchaseOrderPDFView } from "../PurchaseOrderPDFView";

function AssignPurchaseOrderDrawer({
  open,
  reloadTable,
  purchaseOrderList,
  onClose,
  showModal,
  hideModal,
  taxCodeEnabled,
  entityEnabled,
  version,
  defaultEntityList,
  actionType,
}) {
  const [form] = Form.useForm();
  const [approverList, setApproverList] = useState([]);
  const [orderList, setOrderList] = useState(purchaseOrderList);
  const dispatch = useDispatch();
  const [detail, setDetail] = useState(purchaseOrderList?.[0] || {});

  useEffect(() => {
    getWorkFlows(WORK_FLOW_TYPES.PO).then((res) => setApproverList(res));
  }, []);

  const approverValue = Form.useWatch("firstApprover", form);
  const selectedApprover = approverList?.find(
    (workFlow) => workFlow.value === approverValue
  );

  const handleAssignWorkflow = async () => {
    dispatch(loadingStart());
    try {
      let reqBody = {
        type: WORK_FLOW_TYPES.PO,
        workflow_id: actionType === PO_ACTIONS.UNASSIGN ? "" : approverValue,
      };
      reqBody.event_data = orderList.map((item) => {
        return {
          event_ref_id: item.id,
          custom_fields: item.custom_fields.map((field) => {
            return {
              custom_field_id: field.custom_field_id,
              value_id: field.value_id,
            };
          }),
        };
      });

      switch (actionType) {
        case PO_ACTIONS.ASSIGN:
          await assignWorkFlowWithLambda(reqBody);
          break;
        case PO_ACTIONS.REASSIGN:
          await reassignWorkFlowWithLambda(reqBody);
          break;
        case PO_ACTIONS.UNASSIGN:
          await unassignWorkFlowWithLambda(reqBody);
          break;

        default:
          break;
      }

      hideModal();
      reloadTable();
      onClose();
      showNotification(
        NOTIFICATION_TYPE.success,
        PURCHASE_ORDER_TOAST_MSGS.assigned(orderList?.length > 1)
      );
    } catch (e) {
      showNotification(
        NOTIFICATION_TYPE.error,
        e?.response?.data?.message || ERROR_MSG.common
      );
    } finally {
      dispatch(loadingStop());
    }
  };

  const renderBody = ({ label, key, record, index, defaultRender }) => {
    return key === DELETE_BUTTON_KEY
      ? renderDelete({ label, key, record, index, defaultRender })
      : defaultRender({ label, key, record, index, defaultRender });
  };

  const handleRemove = (record) => {
    setOrderList(orderList.filter((item) => item.id !== record.id));
  };

  const renderDelete = ({ label, key, record, index }) => {
    return (
      <CustomButton
        icon={<CloseOutlined />}
        accent={ACCENTS.DESTRUCTIVE}
        variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
        disabled={orderList?.length === 1}
        onClick={() => handleRemove(record)}
      />
    );
  };

  const onViewClick = () => {
    showModal(MODAL_TYPES.ASSIGN_WORKFLOW_MODAL, {
      workflowName: selectedApprover?.workflow?.workflow_name,
      workflowSteps: selectedApprover?.workflow,
      confirm: handleAssignWorkflow,
      recordsCount: orderList.length,
      module: "purchase request",
    });
  };

  const handleRowSelection = ({ record }) => {
    setDetail(record);
  };

  return (
    <Drawer
      className="assign-approval-drawer h-100"
      open={open}
      width={1080}
      onClose={onClose}
      title={
        <Row className={"h-100"}>
          <Column {...{ xs: 24, sm: 24, md: 24, lg: 10 }}>
            <Typography
              variant="title"
              fontSize={"24"}
              responsiveFontSize={false}
              text={PURCHASE_ORDER_APPROVER_DRAWER[actionType].TITLE}
            />
          </Column>
          <Column
            {...{
              xs: 24,
              sm: { ...{ span: 24 } },
              md: { ...{ span: 24 } },
              lg: { ...{ span: 13, offset: 1 } },
            }}
          >
            <Typography
              variant="title"
              fontSize={"24"}
              responsiveFontSize={false}
              text={
                PURCHASE_ORDER_APPROVER_DRAWER.PURCHASE_ORDER_REQUEST_DETAILS
              }
            />
          </Column>
        </Row>
      }
      // title={PURCHASE_ORDER_APPROVER_DRAWER.TITLE}
    >
      <Row className={"h-100"}>
        <Column
          {...{ xs: 24, sm: 24, md: 24, lg: 10 }}
          className={"h-100 overflow-y-scroll kloo-scrollbar p-r-8px"}
        >
          <Typography
            className="m-b-26px"
            variant="secondary"
            text={PURCHASE_ORDER_APPROVER_DRAWER[actionType].SUBTITLE(
              orderList.length
            )}
          />
          <Form
            form={form}
            onFinish={onViewClick}
            className="w-100 h-100"
            layout="vertical"
            initialValues={{
              firstApprover: null,
            }}
          >
            <When condition={actionType !== PO_ACTIONS.UNASSIGN}>
              <CommonSelect
                name="firstApprover"
                placeholder={
                  PURCHASE_ORDER_APPROVER_DRAWER.WORKFLOW_PLACEHOLDER
                }
                label={PURCHASE_ORDER_APPROVER_DRAWER.WORKFLOW_LABEL}
                rules={PO_ASSIGN_FORM_RULE.APPROVER}
                options={approverList}
              />
            </When>
            <When condition={selectedApprover?.workflow}>
              <PreviewWorkflow
                workflowData={selectedApprover?.workflow}
                amount={detail?.gross_amount}
                currency={detail?.currencyData?.currency}
                showName={false}
                showNumberOfStep={true}
                selectedData={detail}
                heightPadding={200}
              />
            </When>

            <Table
              tableClassName="m-b-0px"
              columns={PURCHASE_ORDER_APPROVER_DRAWER.APPROVAL_TABLE_COLUMN}
              data={orderList}
              enableRowHighlight={true}
              onRowClick={handleRowSelection}
              renderBody={renderBody}
              tableHeight={"400px"}
              enableSerialNumber
              showScrollBar
            />

            <Box className="p-b-16px m-t-16px" justifyContent="spaceBetween">
              <CustomButton
                text={"Confirm"}
                loaderText="Confirming..."
                showLoader
                disabled={
                  actionType !== PO_ACTIONS.UNASSIGN &&
                  !selectedApprover?.workflow
                }
                accent={ACCENTS.PRIMARY}
                variant={BUTTON_VARIANT.BUTTON_FILLED}
                onClick={handleAssignWorkflow}
                className="confirm-button w-45"
                customSize={"large"}
              />
              <CustomButton
                text={"Cancel"}
                accent={ACCENTS.SECONDARY}
                variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                onClick={onClose}
                className="cancel-button w-45"
                customSize={"large"}
              />
            </Box>
          </Form>
        </Column>
        <Column
          {...{
            xs: 24,
            sm: { ...{ span: 24 } },
            md: { ...{ span: 24 } },
            lg: { ...{ span: 13, offset: 1 } },
          }}
        >
          <PurchaseOrderPDFView
            {...detail}
            taxCodeEnabled={taxCodeEnabled}
            entityEnabled={entityEnabled}
            defaultEntityList={defaultEntityList}
            showModal={showModal}
            hideModal={hideModal}
            version={version}
          />
        </Column>
      </Row>
    </Drawer>
  );
}

AssignPurchaseOrderDrawer.propTypes = {
  open: PropTypes.bool,
  purchaseOrderList: PropTypes.array,
  reloadTable: PropTypes.func,
  onClose: PropTypes.func,
};

export default AssignPurchaseOrderDrawer;
