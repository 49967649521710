import { memo } from "react";
import useMobile from "../../../hooks/useMobile";
import { constructClassName } from "../../../utils/functions";
import "./Typography.scss";

function SecondaryText({
  text,
  fontStyle = "regular",
  fontSize = "14",
  mobileFontSize = "20",
  responsiveFontSize = false,
  className,
  textColorType = "primary",
  id,
}) {
  const { isMobile } = useMobile();

  const renderedFontSize =
    responsiveFontSize && isMobile ? mobileFontSize : fontSize;

  const clubbedName = constructClassName([
    "kloo-secondary-text",
    `kloo-font-weight-${fontStyle}`,
    `kloo-font-${renderedFontSize}`,
    `kloo-text-color-type-${textColorType}`,
    className,
  ]);

  return (
    <p className={clubbedName} id={id}>
      {text}
    </p>
  );
}

export default memo(SecondaryText);
