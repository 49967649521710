import { memo } from "react";
import { constructClassName } from "../../../utils/functions";

function Icon({ iconName, className, showIconOutline = false, ...rest }) {
  return (
    <i
      className={constructClassName([
        "kloo-material-icons",
        `material-icons${showIconOutline ? "-outlined" : ""}`,
        className,
      ])}
      {...rest}
    >
      {iconName}
    </i>
  );
}

export default memo(Icon);
