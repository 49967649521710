import { YapilyIntegrationConfig } from "./Integration/OpenBanking";

export const NAVIGATION_URL = {
  INTEGRATIONS: "/settings",
  YAPILY_CONSENT_JOURNEY: "/integration/yapily",
  YAPILY_LANDING_PAGE: "/integration/yapily/landing-page",
  PIS_YAPILY_LANDING_PAGE: ({ paymentSource }) =>
    `/integration/yapily/landing-page/?${YapilyIntegrationConfig.landingPageConfig.landingPagePISConfig.flag}=${paymentSource}`,
  PO_MANAGEMENT: "/purchase-requests",
  INVOICE_MANAGEMENT: "/invoice-management",
  SPLIT_INVOICE: "/invoice-management/split",
  SINGLE_MATCHING_INVOICE: "/invoice-management/match",
  PAYMENT_RUNS: "/payment-runs",
  DASHBOARD: "/dashboard",
  FUNDS: "/funds",
  CARDS: "/cards",
  CARD_EXPENSES: "/card-expenses",
  CREDIT_NOTES: "/credit-notes",
  CREDIT_NOTES_LINK_INVOICE: "/credit-notes/link-invoice",
  APPROVALS: "/approvals",
  TRANSACTION: "/transactions",
  USERS: "/user-management",
  TEAMS: "/teams",
  INVOICE_MATCHING: "/invoice-matching",
  CATEGORISATION: "/categorisation",
  SUPPLIER_MANAGEMENT: "/supplier-management",
  ADDRESS: "/address",
  SMART_APPROVALS: (path = "po") => `/smart-approvals/${path}`,
  MANAGE_CCE_CREATE: `/categorisation/manage/`,
  MANAGE_CCE_EDIT: `/categorisation/manage/:id`,
  ACKNOWLEDGEMENT: "/acknowledgement",
  THRESHOLD: "/threshold-matching",
  CONFIGURATIONS: "/configurations",
  CCE_ROUTE: "/categorisation/manage",
  LOGIN: "/login",
  REGISTRATION: "/registerUser/:user",
  ANALYTICS_REGISTRATION: "/registerUser/analytics/:userId",
  SET_PASSWORD: "/set-password",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_PASSWORD: "/change-password",
  ABOUT_US: "/about",
  CONTACT: "/contact",
  PROFILE: "/profile",
  ERP_CONTACT_CREATION: "/erp-contact",
};

export const CARDS_MODULE_NAVIGATION_URL = [
  NAVIGATION_URL.CARDS,
  NAVIGATION_URL.CARD_EXPENSES,
  NAVIGATION_URL.APPROVALS,
];
