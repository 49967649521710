export const CHANGE_TEAM_MODAL = {
  TITLE: "Change team",
  SUB_TITLE:
    "Are you sure you want to change the team of this user? If so, please select from one of the options below",
  CONFIRM_BTN: "Confirm",
  CANCEL_BTN: "Cancel",
  SELECT_TEAM_LABEL: "Select a team",
  REASSIGN_TEAM_SUCCESS_MSG: "Team updated successfully",
};

export const ADD_MEMBER_MODAL = {
  TITLE: "Add member to team",
  PENDING_REQUESTS_WARNING_MODAL: (
    totalPendingRequest,
    teamManager,
    userName,
    teamName
  ) =>
    `${userName} has ${
      totalPendingRequest === 1 ? "a" : totalPendingRequest
    } pending ${
      totalPendingRequest === 1 ? "request" : "requests"
    } and adding them to ${teamName} will reassign ${
      totalPendingRequest === 1 ? "this" : "these"
    } ${
      totalPendingRequest === 1 ? "request" : "requests"
    } to ${teamManager} for approval.`,

  TEAM_WITHOUT_TEAM_MANAGER_MODAL: (totalPendingRequest, teamName, userName) =>
    `${userName} has ${
      totalPendingRequest === 1 ? "a" : totalPendingRequest
    } pending ${
      totalPendingRequest === 1 ? "request" : "requests"
    } and is being added to a team with no manager. ${
      totalPendingRequest === 1 ? "This" : "These"
    } ${
      totalPendingRequest === 1 ? "request" : "requests"
    } will default to requiring Organisation Admin approval, unless a team manager is added.`,
};

export const ADD_MANAGER = {
  LABEL: "Add manager",
  PLACEHOLDER: "Manager name",
  ERROR_MSG: "Please select a manager",
  CURRENT_MANAGERS: "Current manager",
  NO_MANAGERS: "No manager found",
};

export const ADD_MEMBER = {
  LABEL: "Add member",
  PLACEHOLDER: "Member name",
  ERROR_MSG: "Please select a member",
  CURRENT_MEMBERS: "Current members",
  NO_MEMBERS: "No members found",
};

export const SUBMIT_BTN = "Submit";
