import React, { useEffect, useImperativeHandle, useState } from "react";
import Box from "../Box";
import AddIcon from "../../../assets/images/red_add_icon.svg";
import Typography from "../Typography";
import { CustomButton, Icon, When } from "..";
import { WORKFLOW_CONSTANT } from "../../../constants/invoices";
import PropTypes from "prop-types";
import Button from "../Button";
import EditIcon from "../../../assets/images/edit.svg";
import RedCloseIcon from "../../../assets/images/close.svg";
import "./carouselStep.scss";
import { constructClassName } from "../../../utils";
import CommonToolTip from "../Tooltip";
import { ACCENTS, BUTTON_VARIANT } from "../../../constants";

const defaultRenderStep = ({ step, index }) => {
  return (
    <Box>
      <Typography variant={"body"} text={`Step ${index + 1}`} />
    </Box>
  );
};

const DEFAULT_STEP_VALUE = [{ stepId: "", stepIndex: 0, data: {} }];

const addStepIndexToArray = (arr) => {
  return arr.map((item, index) => {
    return {
      ...item,
      stepIndex: index,
    };
  });
};

const CarouselStep = React.forwardRef((props, ref) => {
  const {
    renderStep,
    updateSteps,
    addStep,
    deleteStep,
    editStep,
    steps: propSteps,
  } = props;
  const [steps, setSteps] = useState(
    propSteps.length ? propSteps : DEFAULT_STEP_VALUE
  );
  const [activeStep, setActiveStep] = useState(null);
  const [nextIndex, setNextIndex] = useState(null);

  useEffect(() => setActiveStep(steps[0]), []);

  useEffect(() => {
    setSteps(propSteps.length ? propSteps : DEFAULT_STEP_VALUE);
    if (nextIndex !== null && propSteps.length) {
      let tempSteps = [...propSteps];
      tempSteps.splice(nextIndex, 0, {
        stepId: "",
        stepIndex: nextIndex,
        data: {},
      });
      tempSteps = addStepIndexToArray(tempSteps);
      setSteps(tempSteps);
      editStep && editStep({ step: tempSteps[nextIndex], nextIndex });
      setActiveStep(tempSteps[nextIndex]);
    }
  }, [propSteps]);

  const handleStepAdd = async (index, shouldAdd = true) => {
    let updatedSteps = [...steps];
    const stepToAdd = addStep && (await addStep({ index, shouldAdd }));
    if (stepToAdd) {
      updatedSteps.splice(activeStep?.stepIndex, 1, stepToAdd);
      updatedSteps = addStepIndexToArray(updatedSteps);
      updateSteps && updateSteps(updatedSteps);
      setSteps(updatedSteps);
      setNextIndex(shouldAdd ? (index === null ? steps.length : index) : null);
      return updatedSteps;
    }
  };

  const handleDelete = async ({ step, index }) => {
    let updatedSteps = [...steps];
    if (nextIndex !== null && nextIndex !== index) {
      updatedSteps = await handleStepAdd(nextIndex, false);
    }
    const stepToDelete = deleteStep && deleteStep({ step, index });
    if (stepToDelete) {
      updatedSteps.splice(index, 1);
      updatedSteps = addStepIndexToArray(updatedSteps);
      updateSteps && updateSteps(updatedSteps);
      setSteps(updatedSteps);
      const newIndex = index > 0 ? index - 1 : 0;
      editStep &&
        editStep({
          step: updatedSteps[newIndex],
          index: newIndex,
        });
      setActiveStep(updatedSteps[newIndex]);
      setNextIndex(null);
    }
  };

  const handleEdit = async ({ step, index }) => {
    await handleStepAdd(nextIndex, false);
    editStep && editStep({ step, index });
    setActiveStep(step);
  };

  const goToPrevious = () => {
    const step = steps[activeStep.stepIndex ? activeStep.stepIndex - 1 : 0];
    handleEdit({ step, index: step.stepIndex });
  };

  useImperativeHandle(ref, () => ({
    handleStepAdd,
    goToPrevious,
  }));

  const renderStepWithButtons = ({ step, index }) => {
    return (
      <Box
        className={constructClassName([
          steps.length !== 1 || step.stepIndex !== activeStep?.stepIndex
            ? "step-box-carousel"
            : "step-box-carousel-first",
          step.stepIndex === activeStep?.stepIndex ? "active" : undefined,
        ])}
        columnGap={0}
        rowGap={0}
      >
        <Box className={"step-content-carousel-hover"} columnGap={0}>
          <When condition={step.stepIndex !== activeStep?.stepIndex}>
            <CustomButton
              icon={<Icon iconName={"edit"} />}
              accent={ACCENTS.SECONDARY}
              variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
              onClick={() => handleEdit({ step, index })}
            />
          </When>
          <When condition={steps.length !== 1}>
            <CustomButton
              icon={<Icon iconName={"close"} />}
              accent={ACCENTS.DESTRUCTIVE}
              variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
              onClick={() => handleDelete({ step, index })}
            />
          </When>
        </Box>
        <Box className={"step-content-carousel"}>
          {renderStep({ step, index })}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      wrap={"nowrap"}
      justifyContent={"flexStart"}
      className={"carousel-step-carousel"}
    >
      <Box direction="column" className="left-add-section">
        <CommonToolTip
          overlayClassName="carousel-step-CommonToolTip"
          title={WORKFLOW_CONSTANT.ADD_STEP}
        >
          <CustomButton
            icon={<Icon iconName={"add"} />}
            accent={ACCENTS.SECONDARY}
            variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
            onClick={() => handleStepAdd(0)}
          />
        </CommonToolTip>
      </Box>
      {steps.map((step, index) => (
        <Box wrap={"nowrap"}>
          {renderStepWithButtons({ step, index })}
          <Box direction="column" className="left-add-section">
            <CommonToolTip
              overlayClassName="carousel-step-tooltip"
              title={WORKFLOW_CONSTANT.ADD_STEP}
            >
              <CustomButton
                icon={<Icon iconName={"add"} />}
                accent={ACCENTS.SECONDARY}
                variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
                onClick={() => handleStepAdd(index + 1)}
              />
            </CommonToolTip>
          </Box>
        </Box>
      ))}
    </Box>
  );
});

CarouselStep.propTypes = {
  renderStep: PropTypes.func,
  steps: PropTypes.array, // [{ stepId: String (unique ID), data: { Step Data } }]
  deleteStep: PropTypes.func,
};

CarouselStep.defaultProps = {
  steps: [],
  renderStep: defaultRenderStep,
  deleteStep: () => {
    return true;
  },
};

export default CarouselStep;
