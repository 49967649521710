import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Chips,
  Filter,
  Icon,
  InfiniteScrollTable,
  Modal,
  TextField,
  When,
  PostComment,
} from "../../common";
import "./audit-modal.scss";

function AuditModal({
  title,
  subTitle,
  fetchAuditHistory,
  showFilters = true,
  showDivider = true,
  showSearch = false,
  open,
  onCancel,
  getColumns,
  addComment,
  showCommentSection,
  eventType,
  eventId,
}) {
  const [appliedFilter, setAppliedFilter] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [clickOnPost, setClickOnPost] = useState(false);

  return (
    <Modal
      isOpen={open}
      width={1200}
      title={title}
      subtitle={subTitle}
      hide={onCancel}
      showDivider={showDivider}
    >
      <div className="kloo-layout-container audit-modal-height">
        <Box justifyContent="spaceBetween" wrap="noWrap">
          <When condition={showFilters}>
            <Box justifyContent={"flexStart"} className="no-background">
              <Filter
                onSelectedChange={({ keys }) => {
                  setAppliedFilter && setAppliedFilter(keys);
                }}
                items={filterItems}
                value={appliedFilter}
              />
              <Chips
                items={filterItems}
                removeKey={(keys) => {
                  setAppliedFilter(
                    appliedFilter.filter((item) => !keys.includes(item))
                  );
                }}
                selectedKeys={appliedFilter}
              />
            </Box>
          </When>
          <When condition={showSearch}>
            <TextField
              placeholder="Search"
              prefix={<Icon iconName={"search"} />}
              className="historySearch"
              value={searchValue}
              onChange={setSearchValue}
            />
          </When>
        </Box>
        <InfiniteScrollTable
          className={"audit-table p-0px h-auto"}
          columns={getColumns()}
          onNext={(rest) => {
            return fetchAuditHistory({
              ...rest,
              appliedFilter,
              filterItems,
              setFilterItems,
              searchValue,
            });
          }}
          variables={{
            filters: appliedFilter,
            searchValue: searchValue,
            clickOnPost,
          }}
          height={400}
        />
      </div>
      <When condition={showCommentSection}>
        <PostComment
          addComment={addComment}
          eventType={eventType}
          eventId={eventId}
          setClickOnPost={setClickOnPost}
          clickOnPost={clickOnPost}
        />
      </When>
    </Modal>
  );
}

AuditModal.propTypes = {
  toggle: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default memo(AuditModal);
