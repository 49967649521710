import React, { useEffect } from "react";
import ViewSDKClient from "../../../library/adobe/ViewSDKClient";
import { constructClassName } from "../../../utils";

const PDFViewer = ({ url, className }) => {
  useEffect(() => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        !!url ? url : "pdf-div",
        url,
        url.split("/").pop(),
        {
          showAnnotationTools: false,
          enableFormFilling: false,
        }
      );
    });
  }, [url]);

  return (
    <div
      className={constructClassName(["w-100 h-100", className])}
      id={!!url ? url : "pdf-div"}
    />
  );
};

export default PDFViewer;
