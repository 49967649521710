import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Row,
  Column,
  When,
  Image,
  Box,
  Empty,
  ComponentLoader,
  CustomButton,
  EnvFeatureFlag,
  PDFViewer,
} from "../../common";
import { DOWNLOAD_INVOICE_DRAWER } from "../../../constants/PaymentRuns";
import EmbeddedIframe from "../../common/EmbeddedIframe/EmbeddedIframe";
import {
  downloadFileByJS,
  isPDF,
  NOTIFICATION_TYPE,
  showNotification,
} from "../../../utils";
import {
  ACCENTS,
  BUTTON_VARIANT,
  ERROR_MSG,
  featureFlags,
} from "../../../constants";
import { downloadPDF } from "../../../api/PaymentRunsAPI";
import "./download-invoice.scss";
import {
  isNewInvoiceDrawerEnabled,
  isScheduledOrPaidTab,
} from "../../../helper/PaymentRuns";

function DownloadInvoiceDrawer({ open, onClose, selectedInvoice, activeKey }) {
  const [invoiceLink, setInvoiceLink] = useState(null);
  const [pdfLink, setPDFLink] = useState(null);
  const [componentLoading, setComponentLoading] = useState(false);

  useEffect(() => {
    if (isNewInvoiceDrawerEnabled()) {
      if (selectedInvoice?.invoice_attachment_url) {
        setInvoiceLink({
          isImg: !isPDF(selectedInvoice?.invoice_attachment_url),
          url: selectedInvoice?.invoice_attachment_url || "",
        });
      }
    } else {
      if (
        !isScheduledOrPaidTab(activeKey) &&
        selectedInvoice?.invoice_attachment_url
      ) {
        setInvoiceLink({
          isImg: !isPDF(selectedInvoice?.invoice_attachment_url),
          url: selectedInvoice?.invoice_attachment_url || "",
        });
      }
      if (isScheduledOrPaidTab(activeKey)) {
        setComponentLoading(true);
        downloadPDF(selectedInvoice?.id)
          .then((res) => {
            setPDFLink(res?.data?.result?.download_link);
          })
          .catch((e) => {
            showNotification(
              NOTIFICATION_TYPE.error,
              e?.response?.data?.message || ERROR_MSG.common
            );
            setComponentLoading(false);
          })
          .finally(() => setComponentLoading(false));
      }
    }
  }, []);

  const onDownloadClick = () => {
    downloadFileByJS(
      isNewInvoiceDrawerEnabled()
        ? selectedInvoice?.invoice_attachment_url
        : isScheduledOrPaidTab(activeKey)
        ? pdfLink
        : selectedInvoice?.invoice_attachment_url,
      selectedInvoice?.invoice_number || "Rejected"
    );
  };
  return (
    <Drawer
      className="download-invoice-drawer"
      open={open}
      onClose={onClose}
      title={DOWNLOAD_INVOICE_DRAWER.TITLE}
    >
      <Row className="document-viewer-height">
        <Box className="invoice-cnt w-100">
          <EnvFeatureFlag
            {...featureFlags.paymentRuns.invoiceDrawer.newVersion}
          >
            <When
              condition={
                selectedInvoice?.invoice_attachment_url && !!invoiceLink?.isImg
              }
            >
              <Image className="w-100" src={invoiceLink?.url} />
            </When>
            <When
              condition={
                selectedInvoice?.invoice_attachment_url && !invoiceLink?.isImg
              }
            >
              <PDFViewer url={invoiceLink?.url} />
            </When>
            <When condition={!selectedInvoice?.invoice_attachment_url}>
              <Empty />
            </When>
          </EnvFeatureFlag>
          <EnvFeatureFlag
            {...featureFlags.paymentRuns.invoiceDrawer.oldVersion}
          >
            <When
              condition={
                !isScheduledOrPaidTab(activeKey) &&
                !selectedInvoice?.invoice_attachment_url
              }
            >
              <Empty />
            </When>
            <When
              condition={
                !isScheduledOrPaidTab(activeKey) &&
                selectedInvoice?.invoice_attachment_url &&
                !!invoiceLink?.isImg
              }
            >
              <Image className="w-100" src={invoiceLink?.url} />
            </When>
            <When condition={componentLoading}>
              <ComponentLoader />
            </When>
            <When
              condition={
                !isScheduledOrPaidTab(activeKey) &&
                selectedInvoice?.invoice_attachment_url &&
                !invoiceLink?.isImg
              }
            >
              <PDFViewer url={invoiceLink?.url} />
            </When>
            <When
              condition={
                isScheduledOrPaidTab(activeKey) &&
                !!pdfLink &&
                !componentLoading
              }
            >
              <PDFViewer url={pdfLink} />
            </When>
          </EnvFeatureFlag>
        </Box>
      </Row>
      <Row>
        <Column
          span={24}
          className="download-invoice-drawer-footer p-v-15px p-h-30px w-100"
        >
          <Row gutter={24}>
            <Column className="gutter-row" span={12}>
              <CustomButton
                text={DOWNLOAD_INVOICE_DRAWER.DOWNLOAD_BTN_LABEL}
                accent={ACCENTS.PRIMARY}
                variant={BUTTON_VARIANT.BUTTON_FILLED}
                onClick={onDownloadClick}
                disabled={
                  isNewInvoiceDrawerEnabled()
                    ? !selectedInvoice?.invoice_attachment_url
                    : isScheduledOrPaidTab(activeKey)
                    ? !pdfLink
                    : !selectedInvoice?.invoice_attachment_url
                }
                className="w-100"
              />
            </Column>
            <Column className="gutter-row" span={12}>
              <CustomButton
                text={DOWNLOAD_INVOICE_DRAWER.CANCEL_BTN_LABEL}
                accent={ACCENTS.SECONDARY}
                variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                onClick={onClose}
                className="w-100"
              />
            </Column>
          </Row>
        </Column>
      </Row>
    </Drawer>
  );
}

DownloadInvoiceDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DownloadInvoiceDrawer;
