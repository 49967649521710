import { useState, useImperativeHandle } from "react";
import { Typography } from "antd";
import { Checkbox, Radio, When, Icon } from "..";
import {
  SERIAL_NUMBER_COLUMN_KEY,
  SELECTION_COLUMN_KEY,
  EXPANDABLE_COLUMN_KEY,
  TOOLTIP_BG_COLOR,
} from "../../../constants";
import "../../../assets/scss/Table.scss";
import { useToggle } from "../../../hooks";
import { constructClassName, isEven } from "../../../utils";
import CommonToolTip from "../Tooltip";

function defaultTableBodyRender({ ellipsis, key, record, index }) {
  return record[key];
}

function defaultSelectionBodyRender({
  label,
  key,
  record,
  handleSelection,
  isSelected,
  enableSingleSelection,
}) {
  return (
    <>
      <When condition={enableSingleSelection}>
        <Radio
          checked={isSelected}
          disabled={!!record.isSelectionDisabled}
          onChange={() => handleSelection({ record })}
        />
      </When>
      <When condition={!enableSingleSelection}>
        <Checkbox
          checked={isSelected}
          disabled={!!record.isSelectionDisabled}
          onChange={() => handleSelection({ record })}
        />
      </When>
    </>
  );
}

function defaultTableBodyRenderSrNo({ ellipsis, key, record, index }) {
  return index + 1;
}

function defaultExpandableBodyRender({
  index,
  selectedRow,
  isOpen,
  toggle,
  expandRow,
  onExpand,
}) {
  return (
    <div>
      <Icon
        iconName={"keyboard_arrow_down"}
        className={
          selectedRow === index && isOpen
            ? "table-component-arrow"
            : "table-component-arrow transform-arrow"
        }
        key={index}
        onClick={() => {
          expandRow({ index, selectedRow, onExpand: true });
          onExpand && onExpand();
        }}
      />
    </div>
  );
}

function TableBody({
  columns,
  data,
  renderBody,
  handleSelection,
  selectedData,
  onRowClick,
  enableRowHighlight,
  enableSingleSelection,
  renderExpandedDiv,
  doesArrayIncludes,
  expandRow,
  isOpen,
  toggle,
  defaultSelectedRow = 0,
  disableBackgroundColor,
  customBackgroundClass,
  onExpand,
}) {
  const [selectedRow, setSelectedRow] = useState(defaultSelectedRow);

  return (
    <tbody>
      {!!data?.length &&
        data.map((record, index) => (
          <>
            <CommonToolTip {...record?.tooltipObjAtRowLevel}>
              <tr
                className={constructClassName([
                  enableRowHighlight && selectedRow === index
                    ? "selected-row"
                    : null,
                  record.isDisabled ? "disabled-row" : "",
                  record.customRowClass,
                ])}
                key={`table_body_${index}`}
              >
                {columns
                  .filter(({ visible = true }) => visible)
                  .map(
                    (
                      {
                        ellipsis,
                        key,
                        width,
                        align,
                        clickable = true,
                        customClass,
                        ...rest
                      },
                      colIndex
                    ) => (
                      <td
                        onClick={
                          clickable
                            ? () => {
                                const scrollableDiv =
                                  document.getElementById("scrollableDiv");
                                const tooltips =
                                  scrollableDiv?.getElementsByClassName(
                                    "ant-tooltip-open"
                                  );
                                if (tooltips?.length) {
                                  return;
                                }
                                setSelectedRow(index);
                                onRowClick && onRowClick({ record });
                              }
                            : undefined
                        }
                        style={{
                          width: width ? width + "%" : "",
                          textAlign: align ? align : "",
                        }}
                        className={constructClassName([
                          customBackgroundClass ? customBackgroundClass : "",
                          disableBackgroundColor ? "white-background" : "",
                        ])}
                      >
                        <Typography.Text
                          ellipsis={
                            ellipsis === false || key === SELECTION_COLUMN_KEY
                              ? false
                              : {
                                  tooltip: {
                                    title: record[key],
                                    color: TOOLTIP_BG_COLOR,
                                  },
                                }
                          }
                          onClick={
                            clickable
                              ? () => onRowClick && onRowClick({ record })
                              : null
                          }
                          className={constructClassName([
                            customClass ? customClass : "",
                            "tooltip-container",
                          ])}
                        >
                          {key === SERIAL_NUMBER_COLUMN_KEY
                            ? defaultTableBodyRenderSrNo({
                                ellipsis,
                                key,
                                record,
                                index,
                                defaultRender: defaultTableBodyRender,
                                ...rest,
                              })
                            : key === SELECTION_COLUMN_KEY
                            ? defaultSelectionBodyRender({
                                ellipsis,
                                key,
                                record,
                                index,
                                handleSelection,
                                isSelected: doesArrayIncludes(
                                  selectedData,
                                  record
                                ),
                                defaultRender: defaultTableBodyRender,
                                enableSingleSelection,
                                ...rest,
                              })
                            : key === EXPANDABLE_COLUMN_KEY
                            ? defaultExpandableBodyRender({
                                index,
                                selectedRow,
                                isOpen,
                                toggle,
                                defaultRender: defaultTableBodyRender,
                                expandRow,
                                onExpand,
                                ...rest,
                              })
                            : renderBody({
                                ellipsis,
                                key,
                                record,
                                index,
                                defaultRender: defaultTableBodyRender,
                                isRowExpanded: isOpen,
                                expandRow: () =>
                                  expandRow({ index, selectedRow }),
                                setSelectedRow,
                                ...rest,
                              })}
                        </Typography.Text>
                      </td>
                    )
                  )}
              </tr>
            </CommonToolTip>
            <When
              condition={!!renderExpandedDiv && selectedRow === index && isOpen}
            >
              <tr className="expanded-div-tr mt-20">
                <td
                  colSpan={columns?.length}
                  className={
                    !isEven(index)
                      ? "white-background expanded-div-td"
                      : "grey-background expanded-div-td"
                  }
                >
                  <div className="expanded-div">
                    {renderExpandedDiv &&
                      renderExpandedDiv({
                        selectedRow,
                        expandRow: () => expandRow({ index, selectedRow }),
                      })}
                  </div>
                </td>
              </tr>
            </When>
          </>
        ))}
    </tbody>
  );
}

TableBody.defaultProps = {
  renderBody: defaultTableBodyRender,
  expandRow: () => {},
};
export default TableBody;
