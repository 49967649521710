import { memo } from "react";
import { featureFlags, PO_AUDIT_LOG_CONFIG } from "../../../constants";
import {
  generateEncodedURI,
  getUserOrgId,
  isPOAuditLogVisible,
  MODAL_TYPES,
} from "../../../utils";
import { CustomButton, EnvFeatureFlag, When } from "../../common";
import { getAuditLogByPOId, postPoComment } from "../../../api";
import { useUserType } from "../../../hooks";

function AuditLogModal({ showModal, poDetails }) {
  const { AUDIT_MODAL_TITLE, AUDIT_LOG_BUTTON, AUDIT_LOG_COLUMN } =
    PO_AUDIT_LOG_CONFIG;

  const userTypeDetails = useUserType();

  const fetchAuditHistory = async ({
    page,
    appliedFilter,
    filterItems,
    setFilterItems,
  }) => {
    const filtersUrl = generateEncodedURI(appliedFilter, filterItems);
    const data = await getAuditLogByPOId({
      id: poDetails?.id,
      page,
      filtersUrl,
    });
    if (page === 1) {
      setFilterItems(data.filters);
    }
    return data;
  };

  const handleAuditModalClick = () => {
    showModal(MODAL_TYPES.AUDIT_MODAL, {
      title: AUDIT_MODAL_TITLE,
      showDivider: false,
      fetchAuditHistory,
      getColumns: () => AUDIT_LOG_COLUMN,
      addComment: postPoComment,
      showCommentSection: true,
      eventType: "PO",
      eventId: poDetails?.id,
    });
  };

  const enableAuditLog = isPOAuditLogVisible({
    userTypeDetails,
    poDetails,
    userOrgId: getUserOrgId(),
  });

  return (
    <When condition={enableAuditLog}>
      <CustomButton {...AUDIT_LOG_BUTTON} onClick={handleAuditModalClick} />
    </When>
  );
}

export default memo(AuditLogModal);
