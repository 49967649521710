import { memo, useEffect, useState } from "react";
import { Form } from "antd";
import {
  Box,
  Column,
  CommonSelect,
  ComponentLoader,
  CustomButton,
  Icon,
  Image,
  Label,
  Modal,
  Row,
  Switch,
  Typography,
  When,
} from "../../common";
import KlooLogo from "../../../assets/images/bg-logo.png";
import {
  CONFIGURE_INTEGRATION_MODAL,
  SINGLE_ENTITY_ERP_KEYS,
} from "../../../constants/Integration/OpenBanking";
import {
  getEntityMappingList,
  getErpCompanies,
  saveEntityCompanyMapping,
  updateEntityCompanyMapping,
  updateErpConfigMapping,
  getErpConfigMapping,
  getEntityMappingListByOrgId,
} from "../../../api/ERPIntegrationsAPI";
import { ERROR_MSG } from "../../../constants";
import {
  LOCAL_STORAGE_KEY,
  NOTIFICATION_TYPE,
  getItem,
  showNotification,
} from "../../../utils";
import { useToggle } from "../../../hooks";
import "../style.scss";

function ConfigureIntegrationModal({
  open,
  onCancel,
  logo,
  isSingleEntityErp,
  linkTokenId,
  title,
  entityList,
  entityCompanyList,
  setEntityCompanyMappingList,
}) {
  const [form] = Form.useForm();
  const [componentLoading, setComponentLoading] = useState(true);
  const [entityMappingList, setEntityMappingList] = useState(entityCompanyList);
  const [erpCompanyList, setErpCompanyList] = useState([]);
  const [entityListLoading, setEntityListLoading] = useState(false);
  const [dataSyncedToErp, setDataSyncedToErp] = useState([]);
  const orgId = getItem(LOCAL_STORAGE_KEY.ORD_ID);
  const { isOpen, toggle } = useToggle();

  useEffect(() => {
    Promise.allSettled([
      getErpCompanies(linkTokenId),
      getErpConfigMapping(linkTokenId),
    ])
      .then(([erpCompanyList, dataSyncedList]) => {
        if (erpCompanyList?.value) {
          if (erpCompanyList.value?.length) {
            const tempArray = erpCompanyList.value
              ?.filter((item) => item?.name)
              ?.map(({ id, name }) => ({
                value: id,
                label: name,
              }));
            setErpCompanyList(tempArray);
          }
        }
        if (dataSyncedList?.value) {
          setDataSyncedToErp(dataSyncedList?.value);
        }
        setComponentLoading(false);
      })
      .catch((error) => {})
      .finally(() => setComponentLoading(false));
  }, []);

  async function saveEntityAndCompanyMapping({ i, value, isDelete, e }) {
    const entityFieldId = `entity${i}`;
    const erpCompanyId = `erpCompany${i}`;
    const isEdit = value?.id;
    const status = isDelete
      ? CONFIGURE_INTEGRATION_MODAL.ENTITY_MAPPING_STATUSES.unmapped
      : CONFIGURE_INTEGRATION_MODAL.ENTITY_MAPPING_STATUSES.mapped;
    const values = await form.validateFields();
    if (values[entityFieldId] && values[erpCompanyId] && e) {
      setEntityListLoading(true);
      if (isEdit) {
        try {
          await updateEntityCompanyMapping(
            value?.id,
            linkTokenId,
            values[entityFieldId],
            values[erpCompanyId],
            status
          );
          fetchEntityMappingList();
          fetchErpConfigMappingList();
        } catch (e) {
          onError(e);
        }
      } else {
        try {
          const payload = {
            erp_link_token_id: linkTokenId,
            entity_id: values[entityFieldId],
            erp_company_id: values[erpCompanyId],
            status: status,
          };
          await saveEntityCompanyMapping(payload);
          fetchEntityMappingList();
          fetchErpConfigMappingList();
        } catch (e) {
          onError(e);
        }
      }
    } else if (
      isSingleEntityErp
        ? !values[entityFieldId] && value
        : !values[entityFieldId] && !values[erpCompanyId] && value
    ) {
      setEntityListLoading(true);
      try {
        await updateEntityCompanyMapping(
          value?.id,
          linkTokenId,
          value?.entity_id,
          value?.erp_company_id,
          CONFIGURE_INTEGRATION_MODAL.ENTITY_MAPPING_STATUSES.unmapped
        );
        fetchEntityMappingList();
        fetchErpConfigMappingList();
      } catch (e) {
        onError(e);
      }
    }
  }

  function onError(e) {
    form.resetFields();
    fetchEntityMappingList();
    showNotification(
      NOTIFICATION_TYPE.error,
      e?.response?.data?.message || ERROR_MSG.common
    );
  }

  async function fetchEntityMappingList() {
    const response = await getEntityMappingList(linkTokenId);
    if (response) {
      let mappedList = response?.filter(
        ({ status }) =>
          status === CONFIGURE_INTEGRATION_MODAL.ENTITY_MAPPING_STATUSES.mapped
      );
      setEntityMappingList(mappedList);
      setEntityCompanyMappingList(mappedList);
    }
    setEntityListLoading(false);
  }

  async function fetchErpConfigMappingList() {
    const response = await getErpConfigMapping(linkTokenId);
    if (response) setDataSyncedToErp(response);
  }

  function filteredEntityMappingList() {
    if (
      entityMappingList?.filter(
        ({ erp_link_token_id }) => erp_link_token_id === linkTokenId
      )?.length > 0
    ) {
      return true;
    }
    return false;
  }

  async function toggleErpMappingConfig({ id, status }) {
    try {
      await updateErpConfigMapping(id, status, linkTokenId);
      fetchErpConfigMappingList();
    } catch (e) {
      toggle();
      showNotification(
        NOTIFICATION_TYPE.error,
        e?.response?.data?.message || ERROR_MSG.common
      );
    }
  }

  function Heading({ text, tooltip }) {
    return (
      <Box className="w-100 p-b-8px" justifyContent="flexStart">
        <Label
          text={text}
          suffixIcon="info"
          suffixIconTooltip={tooltip}
          optional={true}
        />
      </Box>
    );
  }

  function EntityMappingList({ i, value, entityMappingList }) {
    let defaultFormValue = {};
    useEffect(() => {
      defaultFormValue[`entity${i}`] = value?.entity_id;
      defaultFormValue[`erpCompany${i}`] =
        value?.erp_company_id ||
        (isSingleEntityErp ? erpCompanyList?.[0]?.value : null);
      form.setFieldsValue({
        ...defaultFormValue,
      });
    }, []);

    Form.useWatch("entity" + i, form);
    Form.useWatch("erpCompany" + i, form);
    const deleteEntityMappingDisabled = !(
      form.getFieldValue("entity" + i) && form.getFieldValue("erpCompany" + i)
    );

    const selectedEntityIds = entityMappingList
      ?.map((item) => item?.entity_id)
      ?.filter((i) => i !== value?.entity_id);

    return (
      <Form
        form={form}
        initialValues={defaultFormValue}
        layout="vertical"
        className="w-100"
      >
        <Row className="w-80 mt-1" key={i}>
          <Column
            span={1}
            className="d-flex h-40px justify-content-center align-items-center prefix-icon"
          >
            <Image src={KlooLogo} width={30} />
          </Column>
          <Column span={9} className="ps-0">
            <Form.Item name={"entity" + i}>
              <CommonSelect
                newSelect
                options={[
                  ...entityList?.filter(
                    (item) => !selectedEntityIds?.includes(item?.value)
                  ),
                ]}
                onChange={(e) => {
                  saveEntityAndCompanyMapping({ i, value, isDelete: false, e });
                }}
                placeholder={CONFIGURE_INTEGRATION_MODAL.ENTITY.placeholder}
              />
            </Form.Item>
          </Column>
          <Column span={2} className="d-flex justify-content-center">
            <Icon iconName="trending_flat" className="fs-45px" />
          </Column>
          <Column
            span={1}
            className="d-flex h-40px justify-content-center align-items-center prefix-icon"
          >
            <Image src={logo} width={30} />
          </Column>
          <Column span={9} className="ps-0">
            <Form.Item name={"erpCompany" + i}>
              <CommonSelect
                newSelect
                options={erpCompanyList}
                onChange={(e) => {
                  saveEntityAndCompanyMapping({ i, value, isDelete: false, e });
                }}
                disabled={isSingleEntityErp}
                placeholder={
                  CONFIGURE_INTEGRATION_MODAL.ERP_COMPANY.placeholder
                }
              />
            </Form.Item>
          </Column>
          <Column span={2} className="d-flex justify-content-center">
            <When condition={value}>
              <CustomButton
                {...CONFIGURE_INTEGRATION_MODAL.BUTTONS_CONFIG.deleteButton}
                onClick={(e) => {
                  saveEntityAndCompanyMapping({ i, value, isDelete: true, e });
                }}
                disabled={deleteEntityMappingDisabled}
                icon={
                  <Icon
                    iconName={
                      CONFIGURE_INTEGRATION_MODAL.BUTTONS_CONFIG.deleteButton
                        .iconName
                    }
                  />
                }
              />
            </When>
          </Column>
        </Row>
      </Form>
    );
  }

  function EntriesCreatedList({ key, value }) {
    return (
      <Box className="w-100 mt-1" justifyContent="flexStart" key={key}>
        <Box>
          <Image src={KlooLogo} width={30} />
          <Typography
            variant="secondary"
            text={`${value?.kloo_module} (${value?.kloo_module_action})`}
          />
        </Box>
        <Icon iconName={"trending_flat"} className="fs-45px" />
        <Box>
          <Image src={logo} width={30} />
          <Typography
            variant="secondary"
            text={`${value?.erp_module} (${value?.erp_module_action})`}
          />
        </Box>
        <Switch
          defaultChecked={
            value?.status ===
            CONFIGURE_INTEGRATION_MODAL.ENTITY_MAPPING_STATUSES.mapped
          }
          disabled={
            !entityMappingList?.filter(
              ({ erp_link_token_id }) => erp_link_token_id === linkTokenId
            )?.length
          }
          isFormItem={false}
          onChange={() =>
            toggleErpMappingConfig({
              id: value?.id,
              status:
                value?.status ===
                CONFIGURE_INTEGRATION_MODAL.ENTITY_MAPPING_STATUSES.mapped
                  ? CONFIGURE_INTEGRATION_MODAL.ENTITY_MAPPING_STATUSES.unmapped
                  : CONFIGURE_INTEGRATION_MODAL.ENTITY_MAPPING_STATUSES.mapped,
            })
          }
        />
      </Box>
    );
  }

  return (
    <>
      <Modal
        width={1042}
        isOpen={open}
        hide={onCancel}
        title={title}
        showDivider={false}
        className="configure-integration-modal"
      >
        <When condition={componentLoading}>
          <ComponentLoader />
        </When>
        <When condition={!componentLoading}>
          <Box
            direction="column"
            className="mt-4 mb-2"
            justifyContent="flexStart"
          >
            <Heading {...CONFIGURE_INTEGRATION_MODAL.HEADINGS.ENTITY_MAPPING} />
            <When condition={entityListLoading}>
              <ComponentLoader wrapperClassName="h-auto" />
            </When>
            <When condition={!entityListLoading}>
              <When condition={filteredEntityMappingList()}>
                {entityMappingList
                  ?.filter(
                    ({ erp_link_token_id }) => erp_link_token_id === linkTokenId
                  )
                  ?.map((value, i) => (
                    <EntityMappingList
                      i={i}
                      value={value}
                      entityMappingList={entityMappingList}
                    />
                  ))}
              </When>
              <When
                condition={
                  (!filteredEntityMappingList() && isSingleEntityErp) ||
                  !isSingleEntityErp
                }
              >
                <EntityMappingList
                  i={entityMappingList?.length + 1}
                  value={null}
                  entityMappingList={entityMappingList}
                />
              </When>
            </When>
          </Box>
          <When condition={isSingleEntityErp}>
            <Box
              direction="column"
              className="mt-4 mb-2"
              justifyContent="flexStart"
            >
              <Heading
                {...CONFIGURE_INTEGRATION_MODAL.HEADINGS.ENTRIES_CREATED}
              />
              {dataSyncedToErp?.map((value, i) => (
                <EntriesCreatedList key={i} value={value} />
              ))}
            </Box>
          </When>
        </When>
      </Modal>
    </>
  );
}

export default memo(ConfigureIntegrationModal);
