import React from "react";
import { Image, PDFViewer, Typography, When } from "../../../common";
import { capitalizeFirst, isPDF } from "../../../../utils";

const AssignWorkFlowModalRightSection = ({
  imageIndex,
  selectedImage,
  selectedData,
  onImageClick,
  type = "Invoice",
}) => {
  const isPdf = isPDF(selectedImage?.attachment_url);
  return (
    <div className="assign-workflow-right-section">
      <When condition={isPdf}>
        <div className="pdf-viewer">
          <PDFViewer url={selectedImage?.attachment_url} />
        </div>
      </When>
      <When condition={!isPdf}>
        <Image
          width="336px"
          height="472px"
          preview={false}
          src={selectedImage?.attachment_url}
        />
      </When>
      <div className="invoice-name-section">
        <p className="invoice-label">{capitalizeFirst(type)} number: </p>
        <Typography
          variant="body"
          fontStyle={"semibold"}
          text={selectedImage?.displayNumber}
          style={{ maxWidth: 205 }}
          ellipsis={{ tooltip: selectedImage?.displayNumber }}
          fontSize={16}
          responsiveFontSize={false}
        />
      </div>
      <div className="invoices-carousel">
        {selectedData
          ?.filter(({ attachment_url }) => attachment_url)
          ?.map(({ attachment_url, displayNumber }, index) => {
            const isPDFFile = isPDF(attachment_url);
            return (
              <>
                <When condition={isPDFFile}>
                  <div
                    className="carousel-pdf-viewer"
                    onClick={() =>
                      onImageClick(
                        {
                          attachment_url,
                          displayNumber,
                        },
                        index
                      )
                    }
                  >
                    <PDFViewer url={attachment_url.url} />
                  </div>
                </When>
                <When condition={!isPDFFile}>
                  <Image
                    width="107px"
                    height="150px"
                    preview={false}
                    src={attachment_url}
                    onClick={() =>
                      onImageClick(
                        {
                          attachment_url,
                          displayNumber,
                        },
                        index
                      )
                    }
                  />
                </When>
              </>
            );
          })}
      </div>
      <When condition={selectedData?.length > 1}>
        <div className="invoice-images-total-count">
          <div className="total-count">
            {imageIndex + 1} / {selectedData?.length}
          </div>
        </div>
      </When>
    </div>
  );
};

export default AssignWorkFlowModalRightSection;
