import React, { useState } from "react";
import { Form } from "antd";
import ReactPhoneInput from "react-phone-input-2";
import "./PhoneInput.scss";
import { constructClassName } from "../../../../utils";
import Label from "../../Label";
import { useCountries } from "../../../../hooks";

function PhoneInputElement(props) {
  const {
    name,
    value,
    label,
    errors,
    preferredCountries,
    onChange,
    placeholder,
    rules,
    validateTrigger,
    className,
    paddingLeft = "40px",
    validateStatus,
    autoComplete,
    autoFocus = false,
    enableSearch = false,
    validPhoneLength = true,
  } = props;

  const [phoneLength, setPhoneLength] = useState("");
  const { rawCountry } = useCountries();
  const DEFAULT_PHONE_LENGTH = 10;

  const isMandatoryField =
    Array.isArray(rules) && rules?.some((el) => el?.required === true);

  const optionalFieldClassName = isMandatoryField ? "" : "optional-field";

  function phoneInputHandler(value, data, event, formattedValue) {
    const dialCode = data.dialCode;
    const mobileNumber = value?.slice(dialCode.length);
    const mobileNumberLengthWithoutDialCode =
      data?.format?.slice(dialCode.length + 1).split(".")?.length - 1;

    const mobileNumberLength = data?.format?.length;
    onChange({
      mobileNumber,
      dialCode,
      value,
    });
    const phoneLengtht = value.replace(data.dialCode, "").length;
    const codeData =
      rawCountry &&
      rawCountry?.length &&
      rawCountry?.filter(
        (countryCode) => countryCode?.iso?.toLowerCase() === data?.countryCode
      )[0];

    if (
      !(Number(codeData?.phone_lenght || DEFAULT_PHONE_LENGTH) === phoneLengtht)
    ) {
      setPhoneLength(codeData?.phone_lenght || DEFAULT_PHONE_LENGTH);
    } else {
      setPhoneLength("");
    }
  }

  function phoneLengthValidation(rule, value) {
    if (!value) {
      return Promise.resolve();
    }
    if (phoneLength && validPhoneLength) {
      return Promise.reject(
        `Please enter a valid ${phoneLength} digits mobile number`
      );
    } else {
      return Promise.resolve();
    }
  }

  function checkValidPhoneNumber({
    mobileNumber,
    formattedValue,
    dialCode,
    mobileNumberLength,
  }) {
    return !(
      formattedValue?.length === mobileNumberLength &&
      (mobileNumber.indexOf(dialCode) === 0 ||
        dialCode.indexOf(mobileNumber) === 0)
    );
  }

  const error = errors?.getFieldError(name)?.length;

  return (
    <>
      <Form.Item
        name={name}
        label={
          label ? <Label text={label} optional={!isMandatoryField} /> : null
        }
        validateStatus={validateStatus}
        rules={[...rules, { validator: phoneLengthValidation }]}
        validateTrigger={validateTrigger}
      >
        <ReactPhoneInput
          {...props}
          name={name}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          placeholder={placeholder}
          preferredCountries={preferredCountries}
          onChange={phoneInputHandler}
          enableSearch={enableSearch}
          searchStyle={{ margin: "0", width: "97%", height: "30px" }}
          value={value}
          inputStyle={{
            borderColor: error && "red",
            paddingLeft: paddingLeft,
          }}
          className={constructClassName([
            className || "",
            "common-phone-input",
            optionalFieldClassName,
            "react-phone-input",
            error ? "error" : "no-error",
          ])}
          searchPlaceholder={"Search"}
        />
      </Form.Item>
    </>
  );
}

export default PhoneInputElement;
