import React from "react";
import { Box } from "../../../common";
import TextDisplay from "../TextDisplay";
import { ADD_NEW_SUPPLIER_FORM } from "../../../../constants";
import { getFullAddress } from "../../../../utils";
import { getFullContact } from "../../../../helper";

function ViewSupplierDetails({ supplierData }) {
  const { SUPPLIER_ADDRESS, supplierName, email, contactNumber, priority } =
    ADD_NEW_SUPPLIER_FORM;

  return (
    <>
      <Box
        direction="column"
        alignContent="flexStart"
        alignItem="flexStart"
        justifyContent="flexStart"
        rowGap={15}
      >
        <TextDisplay title={supplierName?.label} text={supplierData?.name} />
        <TextDisplay title={email?.label} text={supplierData?.email} />
        <TextDisplay
          title={contactNumber?.label}
          text={getFullContact(
            supplierData?.supplier_country_code,
            supplierData?.supplier_contact_no
          )}
        />
        <TextDisplay title={priority?.label} text={supplierData?.priority} />
        <TextDisplay
          title={SUPPLIER_ADDRESS?.label}
          text={getFullAddress(supplierData?.supplier_address, "")}
        />
      </Box>
    </>
  );
}

export default ViewSupplierDetails;
