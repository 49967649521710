import { memo } from "react";
import { constructClassName } from "../../../utils/functions";
import "./Box.scss";

const boxConfig = {
  direction: {
    column: "direction-column",
    row: "direction-row",
    rowReverse: "direction-row-reverse",
    columnReverse: "direction-column-reverse",
  },
  alignContent: {
    stretch: "align-content-stretch",
    center: "align-content-center",
    spaceAround: "align-content-space-around",
    spaceEvenly: "align-content-space-evenly",
    spaceBetween: "align-content-space-between",
    flexStart: "align-content-flex-start",
    flexEnd: "align-content-flex-end",
  },
  justifyContent: {
    center: "justify-content-center",
    spaceAround: "justify-content-space-around",
    spaceEvenly: "justify-content-space-evenly",
    spaceBetween: "justify-content-space-between",
    flexStart: "justify-content-flex-start",
    flexEnd: "justify-content-flex-end",
  },
  alignItem: {
    stretch: "align-item-stretch",
    baseline: "align-item-baseline",
    center: "align-item-center",
    spaceBetween: "align-item-space-between",
    flexStart: "align-item-flex-start",
    flexEnd: "align-item-flex-end",
  },
  wrap: {
    wrap: "flex-wrap",
    nowrap: "flex-nowrap",
    wrapReverse: "flex-wrap-reverse",
  },
};

function Box({
  className,
  children,
  direction = "row",
  justifyContent = "center",
  alignContent = "center",
  alignItem = "center",
  wrap = "wrap",
  rowGap = 8,
  columnGap = 8,
  onClick,
  ...rest
}) {
  const clubbedClassName = constructClassName([
    "kloo-box",
    boxConfig.direction[direction],
    boxConfig.alignContent[alignContent],
    boxConfig.justifyContent[justifyContent],
    boxConfig.alignItem[alignItem],
    boxConfig.wrap[wrap],
    className,
  ]);

  return (
    <div
      className={clubbedClassName}
      style={{ rowGap: rowGap, columnGap: columnGap }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  );
}

export default memo(Box);
