import {
  updateConfig,
  updateOrganizationDetail,
  updateToggleDataOfEntityListWithERPDetails,
} from "../../../api";
import { featureFlags } from "../../../constants";
import { loadingStart, loadingStop } from "../../../redux/action/loader";
import {
  isDevelopmentEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
} from "../../../utils";

export async function handleSwitchChange({
  key,
  e,
  dispatch,
  configData,
  setConfigData,
}) {
  try {
    dispatch(loadingStart());
    var form_data = new FormData();
    form_data.append("config_key_name", key);
    form_data.append("is_key_enabled", e ? 1 : 0);
    const response = await updateConfig({ data: form_data });
    configData[key] = {
      config_key_name: key,
      is_key_enabled: Number(response?.is_key_enabled),
    };
    setConfigData({ ...configData });
  } catch (error) {
  } finally {
    dispatch(loadingStop());
  }
}

export async function handleOrganisationValueChange({
  key,
  eventKey,
  eventValue,
  dispatch,
  organisationData,
  setOrganisationData,
}) {
  try {
    dispatch(loadingStart());
    await updateOrganizationDetail({
      event_name: eventKey,
      event_ref_id: eventValue,
    });
    organisationData[key] = eventValue;
    setOrganisationData({ ...organisationData });
  } catch (error) {
  } finally {
    dispatch(loadingStop());
  }
}

export async function handleSwitchChangeForERPLineItems({
  payload,
  dispatch,
  refreshData,
}) {
  try {
    dispatch(loadingStart());
    await updateToggleDataOfEntityListWithERPDetails(payload);
    await refreshData();
  } catch (error) {
  } finally {
    dispatch(loadingStop());
  }
}

export function isLineItemToggleSectionEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.settings.configurations.showLineItemSectionInInvoice_11017
        .enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.settings.configurations.showLineItemSectionInInvoice_11017
        .enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.settings.configurations.showLineItemSectionInInvoice_11017
        .enableForProd)
  );
}

export function isDefaultOwnerSelection() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.settings.configurations.showDefaultOwnerSelection_11292
        .enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.settings.configurations.showDefaultOwnerSelection_11292
        .enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.settings.configurations.showDefaultOwnerSelection_11292
        .enableForProd)
  );
}

export function isAutoInvoiceConfirmed() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.settings.configurations.isAutoInvoiceConfirmed_11343
        .enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.settings.configurations.isAutoInvoiceConfirmed_11343
        .enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.settings.configurations.isAutoInvoiceConfirmed_11343
        .enableForProd)
  );
}
