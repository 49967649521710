import React, { useState } from "react";
import PropTypes from "prop-types";
import { Column } from "..";
import { INPUT_TYPES } from "../../../constants";
import "./custom-field.scss";
import { versionForNewCustomField } from "../../../helper";
import RenderCustomField from "./RenderCustomField";

function CustomField({
  fields,
  setFields,
  onChange,
  version,
  toggleConditionalFields,
  dataPopulatedByAI,
  setDataPopulatedByAI,
  columnSpan = 24,
}) {
  const [lastSelectedValue, setSelectedValue] = useState(null);

  const handleChange = (field, value) => {
    if (onChange) {
      onChange(field, value);
    }
    if (
      versionForNewCustomField(version) &&
      field.inputType === INPUT_TYPES.SELECT
    ) {
      const selectedField = field.options.find((item) => value === item.value);
      if (toggleConditionalFields) {
        toggleConditionalFields(
          field,
          selectedField,
          fields,
          lastSelectedValue
        );
      }
    }
    if (dataPopulatedByAI?.hasOwnProperty(field.label)) {
      delete dataPopulatedByAI[field.label];
      setDataPopulatedByAI({ ...dataPopulatedByAI });
    }
    setSelectedValue(field);
  };

  if (!fields || fields.length === 0) {
    return null;
  }

  return (
    <>
      {fields.map((field) => (
        <Column key={field.inputLabel} span={columnSpan}>
          <RenderCustomField
            field={field}
            version={version}
            setFields={setFields}
            handleChange={handleChange}
            dataPopulatedByAI={dataPopulatedByAI}
          />
        </Column>
      ))}
    </>
  );
}

CustomField.propTypes = {
  fields: PropTypes.array,
};

export default CustomField;
