import React from "react";
import When from "../When";
import CustomSelect from "../CustomSelect";
import {
  INPUT_TYPES,
  PURCHASE_ORDER_POPULATED_USING_AI,
} from "../../../constants";
import { createOption, createOptionV2 } from "../../../api";
import { versionForNewCustomField } from "../../../helper";
import CommonInput from "../Input";

export default function RenderCustomField({
  field,
  version,
  setFields,
  handleChange,
  dataPopulatedByAI,
  showLabel = true,
  wrapperClass,
  name,
  getPopupContainer,
}) {
  const handleAddValue = async (newValue, field) => {
    let latestOptions;
    if (versionForNewCustomField(version)) {
      latestOptions = await createOptionV2("po", field.id, {
        values: [
          {
            value: newValue,
          },
        ],
      });
    } else {
      latestOptions = await createOption(field.inputId, "dynamic", {
        external_id: "",
        createValueNames: [
          {
            name: newValue,
          },
        ],
      });
    }

    setFields(field.inputId, latestOptions.data.result, newValue);
  };

  return (
    <>
      <When condition={field.inputType === INPUT_TYPES.SELECT}>
        <CustomSelect
          label={showLabel ? field.inputLabel : ""}
          name={name || field.name}
          options={field.options}
          placeholder={field.placeHolder}
          rules={field.rules}
          initialValue={null}
          className={`dynamic-list ${
            !field.required ? "custom-text-optional-select" : ""
          }`}
          isCreatable={field.type === "dynamic"}
          onAddOption={(v) => handleAddValue(v, field)}
          optionalField={!field.required}
          onChange={(value) => handleChange(field, value)}
          suffixIcon={dataPopulatedByAI?.[field?.label] ? "smart_toy" : ""}
          suffixIconClassName={"brand-color"}
          suffixIconTooltip={PURCHASE_ORDER_POPULATED_USING_AI}
          wrapperClass={wrapperClass}
          getPopupContainer={getPopupContainer}
        />
      </When>
      <When condition={field.inputType !== INPUT_TYPES.SELECT}>
        <CommonInput
          label={showLabel ? field.inputLabel : ""}
          name={name || field.name}
          placeholder={field.placeHolder}
          rules={field.rules}
          className={`${!field.required ? "custom-text-optional" : ""}`}
          optionalField={!field.required}
          onChange={(e) => handleChange(field, e.target.value)}
          suffixIcon={dataPopulatedByAI?.[field?.label] ? "smart_toy" : ""}
          suffixIconClassName={"brand-color"}
          suffixIconTooltip={PURCHASE_ORDER_POPULATED_USING_AI}
          wrapperClass={wrapperClass}
        />
      </When>
    </>
  );
}
