import { fetchSplittedInvoiceAmountFromPo } from "../helper";
import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import {
  appendKeyForFilters,
  convertToGBP,
  generateEncodedURIString,
  getCurrenciesFromLS,
  getOnlyAmount,
  getUserName,
  moneyFormatter,
} from "../utils";

export async function getInvoiceForMatching({
  page,
  filtersUrl,
  searchUrl,
  poNumberUrl = "",
  pageSize: pageSize,
}) {
  const pageSizeURL = generateEncodedURIString(
    "perPageCount",
    pageSize.toFixed()
  );

  const tempResponse = await apiClient({
    url: `${PATH.invoicePOMatching.invoiceForMatching}?page=${page}${filtersUrl}${searchUrl}${pageSizeURL}${poNumberUrl}`,
    method: "GET",
  });

  const response = {
    list: tempResponse?.data?.result?.data?.map((data) => ({
      ...data,
      owner: getUserName(data?.owner_organization_details?.user, ""),
      ...fetchSplittedInvoiceAmountFromPo({ data, poNumberUrl }),
    })),
    page: tempResponse?.data?.result?.meta?.current_page,
    pageSize: tempResponse?.data?.result?.meta?.per_page,
    filters: [],
  };

  if (page === 1) {
    response.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }

  return response;
}

export async function getInvoiceMatchingManualList({
  invoiceNumber,
  page,
  filtersUrl,
  searchUrl,
  pageSize,
  entityId,
}) {
  const pageSizeURL = generateEncodedURIString(
    "perPageCount",
    pageSize.toFixed()
  );
  const tempResponse = await apiClient({
    url: `${PATH.invoiceMatching.manualListing}/${invoiceNumber}?${
      entityId ? `entity_id=${entityId}&&` : ""
    }page=${page}${filtersUrl}${searchUrl}${pageSizeURL}`,
    method: "GET",
  });
  const response = {
    data: {
      list: tempResponse?.data?.result?.data,
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
      filters: [],
    },
  };
  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}

export async function getInvoiceMatchingSplitList({
  invoiceNumber,
  page,
  filtersUrl,
  searchUrl,
  pageSize,
  entityId,
}) {
  const pageSizeURL = generateEncodedURIString(
    "perPageCount",
    pageSize.toFixed()
  );
  const tempResponse = await apiClient({
    url: `${PATH.invoiceMatching.splitListing}/?${
      entityId ? `entity_id=${entityId}&&` : ""
    }page=${page}${filtersUrl}${searchUrl}${pageSizeURL}`,
    method: "GET",
  });
  const response = {
    data: {
      list: tempResponse?.data?.result?.data.map((item) => {
        const currencies = getCurrenciesFromLS();
        const currencyType = item.currency.currency;
        item.formattedRemainingAmount = moneyFormatter(
          item.remaining_amount,
          null,
          currencyType
        );
        item.formattedGrossAmount = moneyFormatter(
          item.net_amount,
          null,
          currencyType
        );
        item.remainingAmountInGBP = item.remaining_amount;
        item.netAmountInGBP = item.net_amount;
        if (item.currency.currency !== "GBP") {
          item.formattedRemainingAmount = `${
            item.formattedRemainingAmount
          } (${moneyFormatter(
            convertToGBP(item.remaining_amount, currencies, currencyType)
          )})`;
          item.formattedGrossAmount = `${
            item.formattedGrossAmount
          } (${moneyFormatter(
            convertToGBP(item.net_amount, currencies, currencyType)
          )})`;
          item.remainingAmountInGBP = convertToGBP(
            item.remaining_amount,
            currencies,
            currencyType
          );
          item.netAmountInGBP = convertToGBP(
            item.net_amount,
            currencies,
            currencyType
          );
        }
        return item;
      }),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
      filters: [],
    },
  };
  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}

export function matchPOToInvoice(data) {
  return apiClient({
    url: PATH.invoicePOMatching.matchPOToInvoice,
    method: "POST",
    data,
  });
}

export function unMatchPOToInvoice(data) {
  return apiClient({
    url: PATH.invoicePOMatching.unMatchPOToInvoice,
    method: "POST",
    data,
  });
}

export async function getMatchingAudit({ invoiceId }) {
  const res = await apiClient({
    url: PATH.invoicePOMatching.audit(invoiceId),
    method: "GET",
  });
  return res?.data?.result?.data;
}
