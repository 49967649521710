export const ERP_CONTACT_CREATION_CONSTANT = {
  title: "ERP contact creation",
  subTitle: "Instantly create ERP contacts to be used as suppliers by kloo.",
  createForm: {
    title: "Create new ERP Contact",
    nameField: {
      label: "Contact name",
      placeholder: "Enter contact name",
    },
    isSupplier: {
      label: "Is supplier?",
    },
  },
  successMsg: "Contact created successfully.",
};
