import "../../../assets/scss/Table.scss";
import { constructClassName } from "../../../utils";
import PropTypes from "prop-types";
import When from "../When";
import "../../../assets/scss/Table.scss";

function defaultTableFooterRender({ key, record }) {
  return record[key];
}

function TableFooter({ columns, data, renderFooter }) {
  return (
    <tfoot>
      {!!data?.length &&
        data.map((record, index) => (
          <>
            <tr key={`table_footer_${index}`}>
              {columns
                .filter(({ visible = true }) => visible)
                .map(({ key, width, align, colSpan, customClass, ...rest }) => {
                  return (
                    <td
                      colspan={colSpan || undefined}
                      style={{
                        width: width ? width + "%" : "",
                        textAlign: align ? align : "",
                      }}
                      className={constructClassName([
                        customClass ? customClass : "",
                      ])}
                    >
                      {renderFooter({
                        key,
                        record,
                        index,
                        defaultRender: defaultTableFooterRender,
                        ...rest,
                      })}
                    </td>
                  );
                })}
            </tr>
          </>
        ))}
    </tfoot>
  );
}

TableFooter.propTypes = {
  renderFooter: PropTypes.func,
  columns: PropTypes.array,
  data: PropTypes.array,
};

TableFooter.defaultProps = {
  renderFooter: defaultTableFooterRender,
  columns: [],
  data: [],
};
export default TableFooter;
