import { CONDITIONAL_FIELDS_FORM, capitalizeFirst } from "../../utils";

export const VISIBILITY_CONFIG = {
  default: "default",
  conditional: "conditional",
};
export const CUSTOM_CONDITIONAL_EXPENSE_CONSTANTS = {
  CREATE_TITLE: "Create custom field",
  EDIT_TITLE: ({ fieldVisibility }) => {
    return `Manage ${
      fieldVisibility == VISIBILITY_CONFIG.conditional
        ? VISIBILITY_CONFIG.conditional
        : ""
    } custom field`;
  },
  VALUES_SECTION: {
    VALUES: "Values",
    ADD_VALUES: "Add values",
    ENTER_VALUES: "Enter value",
    ALREADY_EXIST_ERROR: (value) => {
      return `This value already exists`;
    },
    ERROR_INPUT_EMPTY: "Please fill the value",
    OPEN_INPUT_ERROR: "Please save your changes to continue",
  },
  CONFIGRATION_SECTION: {
    CONFIGRATION: {
      variant: "title",
      text: "Configuration",
      fontSize: "20",
      fontStyle: "semibold",
      responsiveFontSize: false,
    },
    NAME_INPUT: {
      name: "name",
      label: "Name",
      placeholder: "Enter name",
      rules: CONDITIONAL_FIELDS_FORM.NAME,
    },
    FIELD_VISIBILITY_SELECT: {
      name: "fieldVisibility",
      label: "Field visibility",
      placeholder: "Select field visibility",
      rules: CONDITIONAL_FIELDS_FORM.VISIBILITY,
      suffixIcon: "info",
      suffixIconTooltip:
        "Default custom fields will be visible on purchase requests as soon as they are created, unlike conditional fields, which are hidden unless triggered by a value within a default field. Note, conditional fields can only be created at the purchase order level.",
    },
    FIELD_TYPE_SELECT: {
      name: "fieldType",
      label: "Field type",
      placeholder: "Select field type",
      rules: CONDITIONAL_FIELDS_FORM.FIELD_TYPE,
      suffixIcon: "info",
      tooltipText: [
        "Predefined custom fields - users can select only from predefined values.",
        "Dynamic custom fields  - users can select from predefined or create new values.",
        "Free text custom fields - users can enter any text as description.",
      ],
    },
    OPTIONS_SELECT: {
      name: "option",
      label: "Visibility",
      placeholder: "Select visibility",
      rules: CONDITIONAL_FIELDS_FORM.OPTION,
      suffixIcon: "info",
      suffixIconTooltip: [
        `Select where this custom field should appear whilst purchase requests are being created.`,
      ],
    },
    REQUIRED_FIELD_OPTION: {
      REQUIRED_FIELD: "Required field",
      REQUIRED_FIELD_TOOLTIP:
        "Determine if users must fill in this custom field when creating or editing a purchase request.",
      ICON: "info",
      NAME: "requiredField",
    },
  },
  CONDITIONAL_FIELD: {
    CONDITIONAL_FIELD_TITLE: {
      variant: "title",
      text: "Conditional field",
      fontSize: "20",
      fontStyle: "semibold",
      responsiveFontSize: false,
    },
    IF_TITLE: {
      variant: "secondary",
      text: "IF",
      fontSize: "20",
      fontStyle: "semibold",
      responsiveFontSize: "false",
    },
    THEN_DISPLAY_TITLE: {
      variant: "secondary",
      text: "THEN DISPLAY",
      fontSize: "20",
      fontStyle: "semibold",
      responsiveFontSize: false,
    },
    FOR_ALL_VALUES_TEXT: {
      variant: "secondary",
      text: "FOR ALL OTHER VALUES",
      fontSize: "20",
      fontStyle: "semibold",
      responsiveFontSize: false,
    },
    SELECT_CONDITION_REMAINING: {
      mode: "multiple",
      newSelect: true,
      name: "remaining",
      placeholder: "No conditional field",
      maxTagCount: "responsive",
    },
    SELECT_MAPPING_CONDITION: {
      mode: "multiple",
      newSelect: true,
      name: "mapping",
      placeholder: "Conditional field",
      maxTagCount: "responsive",
    },
    SELECT_VALUES_OPTION: {
      newSelect: true,
      name: "option",
      placeholder: "Value",
    },
    suffixIcon: "info",
    suffixIconTooltip:
      "Configure conditions against values within custom fields so that conditional fields are displayed when all conditions are met. You can add as many conditions as required and a value can have more than one condition.",
    createButtonToolTip: "You must add values to create a custom field.",
    editButtonToolTip: "You must add values to save a custom field.",
  },
  ACTION_TYPE: {
    CREATED: "created",
    UPDATED: "updated",
    DELETED: "deleted",
  },
  BUTTON: {
    CREATE: `Create custom field`,
    UPDATE: `Save changes`,
    DELETE: `Delete custom field`,
  },
  OPTION_MODAL: {
    title: "Field visibility change",
    subTitle:
      "By changing the field visibility of this custom field, any existing conditions associated to this field will be deleted and removed from all purchase requests.",
  },
  VALUES_DELETE_MODAL: {
    title: (deletedValues = []) => {
      return `Delete value${deletedValues.length > 1 ? "s" : ""}`;
    },
    subTitle: (deletedValues = []) => {
      return `You are about to permanently delete ${
        deletedValues.length > 1 ? "these" : "this"
      } value${deletedValues.length > 1 ? "s" : ""} and ${
        deletedValues.length > 1 ? "they" : "it"
      } will be removed from all purchase requests. In addition, if automated assignment of workflows is enabled based on ${
        deletedValues.length > 1 ? "these" : "this"
      } value${
        deletedValues.length > 1 ? "s" : ""
      }, automated assignment will be disabled and reassignment will be required.`;
    },
  },
  DELETE_CUSTOM_FIELD_MODAL: {
    title: (val) => {
      return `Delete ${val}`;
    },
    subTitle: `You are about to permanently delete this custom field and it will be removed from all purchase requests. If automated assignment of workflows is enabled based on this field, automated assignment will be disabled and reassignment will be required. In addition, all conditions associated to this custom field will be deleted.`,
  },
};

export const FIELD_TYPE_CONFIG = {
  predefined: "predefined",
  dynamic: "dynamic",
  freetype: "textbox",
};

export const OPTION_CONFIG = {
  PO: "default",
  LINE_ITEM: "line_items",
};

export const MAPPINGLIST = [
  {
    id: "",
    mapping_id: "",
    mapping: [],
  },
];

export const PAGE_TYPE_TO_TOGGLE_VALUE = {
  accountpayable: "invoice",
  purchase_order: "po",
  cardexpenses: "ce",
};

export const FIELD_TYPE_TO_TOGGLE_VALUE = {
  customExpenses: "custom_fields",
  category: "categories",
  xero: "xero",
};

export const REMAINING_MAPPING_LIST = [
  {
    id: "remaining_values",
    val: "remaining_values",
    mapping_id: "",
    mapping: [],
  },
];

export const MAPPING_ID = "mapping_id";

export const MODAL_TYPE = {
  DELETE: "delete",
  VALUE_DELETE: "value_delete",
  OPTION_CHANGE: "option_change",
};

export const SUCCESS_TOAST_MSG = {
  CREATE_CUSTOM_EXPENSE: (val) => {
    return `${
      val == VISIBILITY_CONFIG.default ? "C" : "Conditional c"
    }ustom field created`;
  },
  UPDATE_CUSTOM_EXPENSE: `Custom field updated`,
  DELETE_CUSTOM_EXPENSE: `Custom field deleted`,
};

export const OPTION_DESCRIPTION_V2 = (dataField, optionArray) => {
  let optionLabel = "";
  [...dataField.options].reverse().forEach((item, index, array) => {
    const option = optionArray.find((option) => option.id === item) || {};
    const currLabel = option?.label
      ?.replace("Only", "")
      ?.replace("Line items", "purchase order line items")
      ?.toLowerCase();
    if (index < array.length - 2) {
      optionLabel += currLabel + ", ";
    } else if (index === array.length - 2) {
      optionLabel += currLabel + " and ";
    } else {
      optionLabel += currLabel;
    }
  });
  return `${
    dataField?.attribute_type === "textbox"
      ? "Free text field"
      : `${capitalizeFirst(dataField?.attribute_type)} field`
  } for ${optionLabel}`;
};

export const SELECT_TYPE_ATTRIBUTES = ["dynamic", "predefined"];
