import { memo } from "react";
import { AUDIT_LOG_CONFIG, WORK_FLOW_TYPES_TO_TEXT } from "../../../constants";
import { generateEncodedURI, MODAL_TYPES } from "../../../utils";
import { CustomButton, When } from "../../common";
import { getAuditLogById } from "../../../api";

function AuditLogModal({
  showModal,
  details,
  type,
  addComment,
  isAuditLogEnabled = true,
}) {
  const { AUDIT_MODAL_TITLE, AUDIT_LOG_BUTTON, AUDIT_LOG_COLUMN } =
    AUDIT_LOG_CONFIG;

  const fetchAuditHistory = async ({
    page,
    appliedFilter,
    filterItems,
    setFilterItems,
  }) => {
    const filtersUrl = generateEncodedURI(appliedFilter, filterItems);
    const data = await getAuditLogById({
      id: details?.id,
      page,
      filtersUrl,
      type,
    });
    if (page === 1) {
      setFilterItems(data.filters);
    }
    return data;
  };

  const handleAuditModalClick = () => {
    showModal(MODAL_TYPES.AUDIT_MODAL, {
      title: AUDIT_MODAL_TITLE(WORK_FLOW_TYPES_TO_TEXT[type]),
      showDivider: false,
      fetchAuditHistory,
      getColumns: () => AUDIT_LOG_COLUMN,
      addComment,
      showCommentSection: true,
      eventType: type,
      eventId: details?.id,
    });
  };

  return (
    <When condition={isAuditLogEnabled}>
      <CustomButton {...AUDIT_LOG_BUTTON} onClick={handleAuditModalClick} />
    </When>
  );
}

export default memo(AuditLogModal);
