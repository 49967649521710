import {
  ACCOUNT_PAYABLE_FILTER,
  BENEFICIRIES_LIST,
  GEN_AI_INVOICE_EMAIL_ID,
  PAYABLE_LIST,
  PAYABLE_LIST_PAID,
  PAYABLE_LIST_REVIEW,
  PAYABLE_LIST_TO_PAY,
  UPDATE_APURL,
} from "../service/types";

const initialState = {
  payableList: {},
  payableReviewList: {},
  payableToPayList: {},
  payablePaidList: {},
  beneficiarieslist: {},
  accountPayableFilter: {},
  url: "",
  genAIInvoiceEmailId: {},
};
export default function accountPayable(state = initialState, action) {
  switch (action.type) {
    case UPDATE_APURL:
      return { ...state, url: action.payload };
    case PAYABLE_LIST:
      return { ...state, payableList: action.payload };
    case PAYABLE_LIST_REVIEW:
      return { ...state, payableReviewList: action.payload };
    case PAYABLE_LIST_TO_PAY:
      return { ...state, payableToPayList: action.payload };
    case PAYABLE_LIST_PAID:
      return { ...state, payablePaidList: action.payload };
    case BENEFICIRIES_LIST:
      return { ...state, beneficiarieslist: action.payload };
    case ACCOUNT_PAYABLE_FILTER:
      return { ...state, accountPayableFilter: action.payload };
    case GEN_AI_INVOICE_EMAIL_ID:
      return { ...state, genAIInvoiceEmailId: action.payload };
    default:
      return state;
  }
}
