import { memo } from "react";
import Drawer from ".";
import useToggle from "../../../hooks/useToggle";
import Box from "../Box";
import Button from "../Button";
import ActionLink from "../Typography/ActionLink";
import CustomButton from "../CustomButton";
import { ACCENTS, BUTTON_VARIANT } from "../../../constants";

function TextTriggeredDrawer({
  triggerText,
  children,
  drawerTitle,
  showCloseButtonInDrawerBody = true,
}) {
  const { isOpen, toggle } = useToggle();

  return (
    <>
      <ActionLink text={triggerText} onClick={toggle} />
      <Drawer
        className={"text-trigerred-drawer"}
        title={drawerTitle}
        open={isOpen}
        onClose={toggle}
      >
        {children}
        <br />
        <br />
        {showCloseButtonInDrawerBody ? (
          <Box>
            <CustomButton
              text={"Close"}
              accent={ACCENTS.PRIMARY}
              variant={BUTTON_VARIANT.BUTTON_FILLED}
              onClick={toggle}
            />
          </Box>
        ) : null}
      </Drawer>
    </>
  );
}

export default memo(TextTriggeredDrawer);
