import {
  GRNI_FORM_FIELD_RULES,
  PO_CREATE_FORM_RULE,
  SEND_SUPPIER_EMAIL_FORM,
  moneyFormatter,
} from "../utils";
import { INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY } from "./InvoiceMatching";
import { LC_SUPPLIER_PLACEHOLDER, SUPPLIER_PLACEHOLDER } from "./dynamic";
import { EXPAND_COLLAPSE_COLUMN } from "./table";
import { ACCENTS, BUTTON_VARIANT } from "./theme";

export const PAGE_HEADER_INFO = {
  PURCHASE_ORDER: {
    desc: "Create, review, and manage purchase order creation from one simple interface.",
    header: "Purchase order management",
    buttonIcon: "add_circle",
    buttonLabel: "Create purchase request",
    buttonLabelMobile: "Create PO",
  },
};

export const DELETE_BUTTON_KEY = "delete";

export const EDIT_BUTTON_KEY = "edit";

export const TOOL_TIP_TEXT_PO = {
  netAmount: "The total value before tax",
  taxRate: "The rate of tax",
  totalAmount: "The sum of the net amount and tax amount",
  remainingAmount:
    "The remaining net amount of the purchase order after confirmed invoices matches",
};

export const PURCHASE_ORDER_CREATE_FROM = {
  ITEM_TABLE_COLUMN: (isInvoice) => {
    const columns = [
      { ...EXPAND_COLLAPSE_COLUMN, width: isInvoice ? 0 : 5 },
      { label: "Item name", key: "name", width: 20 },

      {
        label: "Quantity",
        key: "quantity",
        width: 15,
      },
      {
        label: "Unit cost",
        key: "cost",
        width: 20,
      },
      {
        label: isInvoice ? "Amount" : "Net amount",
        key: "amount",
        width: 20,
        align: "right",
      },
      { label: "Action", key: DELETE_BUTTON_KEY, width: 20, align: "center" },
    ];
    return isInvoice ? columns.slice(1) : columns;
  },
  ITEM_TABLE_ACTION: [
    { label: "Action", key: DELETE_BUTTON_KEY, width: 8, align: "center" },
  ],
  ITEM_FORM_LABELS: {
    name: "Item name",
    quantity: "Quantity",
    cost: "Unit cost",
    saveButton: "Save",
    cancelButton: "Cancel",
    totalAmount: "Total amount:",
    netAmount: "Net amount:",
    addMoreButton: "Add Item",
  },
  FORM_LABELS: {
    entity: "Entity",
    supplier: SUPPLIER_PLACEHOLDER,
    description: "Description",
    owner: "Owner",
    currency: "Currency",
    grossAmount: "Total amount",
    netAmount: "Net amount",
    taxRate: "Tax rate",
    deliveryDate: "Completion date",
    startDate: "Start date",
    businessUnit: "Business unit",
    createButtonLabel: "Create purchase request",
    createdDate: "Created date",
    rejectionReason: "Rejection reason",
    remainingAmount: "Remaining Amount",
  },
  placeholder: {
    supplier: `Select ${LC_SUPPLIER_PLACEHOLDER}`,
    description: "Enter description",
    owner: "Select owner",
    businessUnit: "Enter business unit",
    totalAmount: "Enter total amount",
    poNumber: "Select PO number",
  },
  itemSwitch: {
    label: "Item list",
    info: "Do you want to add bill of materials to this purchase request?",
  },
  viewItemList: {
    label: "View item list",
  },
  costSwitch: {
    label: "R&D cost",
    info: "Is this a technology or product development cost?",
  },
  attachmentsSwitch: {
    label: "Supporting attachments",
    info: "Do you want to attach supporting documents to this purchase request?",
  },
  rejectionReason: {
    placeholder: "No rejection reason added",
  },
  attachmentsMaxSizeError:
    "File size must be 4MB or less. Please reduce the file size or upload a different document",
  FORM_TITLE: "Create purchase request",
  // DRAWER_WIDTH: 576,
  EDIT_FORM_TITLE: "Edit purchase request",

  netAmount: {
    name: "net_amount",
    label: "Net amount",
    placeholder: "Enter net amount",
    min: 0,
    precision: 2,
    controls: false,
    suffixIcon: "info",
    suffixIconTooltipOld: "This is the total amount before tax.",
    suffixIconTooltip: TOOL_TIP_TEXT_PO.netAmount,
  },
  totalAmount: {
    suffixTooltipList: [
      {
        suffixIconTooltip: TOOL_TIP_TEXT_PO.totalAmount,
        suffixIconClassName: "",
        suffixIcon: "info",
      },
    ],
  },
  taxCode: {
    name: "tax_code_id",
    label: "Tax code",
    placeholder: "Select tax code",
  },
  taxRate: {
    name: "tax_rate",
    label: "Tax rate",
    placeholder: "%",
    disabled: true,
    formatter: (value) => `${value}%`,
    suffixTooltipList: [
      {
        suffixIconTooltip: TOOL_TIP_TEXT_PO.taxRate,
        suffixIconClassName: "",
        suffixIcon: "info",
      },
    ],
  },
  entity: {
    name: "entity_id",
    label: "Entity",
    placeholder: "Select entity",
    rules: PO_CREATE_FORM_RULE.ENTITY,
  },
  ATTACHMENT_TABLE_COLUMN: [
    { label: "Document", key: "name", width: 80 },
    { label: "", key: "actions", width: 20, align: "center" },
  ],
  UPDATE_PO_LABEL: "Update",
  RESUBMIT_PO_LABEL: "Resubmit",
  DRAFT_PO_LABEL: "Create purchase request",
};

export const PO_ACTIONS = {
  APPROVE: "approved",
  REJECT: "rejected",
  CLOSE: "closed",
  ASSIGN: "assign",
  REASSIGN: "reassign",
  UNASSIGN: "unassign",
  RESEND_APPROVAL_EMAIL: "resentApprovalEmail",
};

export const PURCHASE_ORDER_APPROVER_DRAWER = {
  [PO_ACTIONS.ASSIGN]: {
    TITLE: "Assign to workflow",
    SUBTITLE: (approvalCount) =>
      PURCHASE_ORDER_APPROVER_DRAWER.INFO(approvalCount, PO_ACTIONS.APPROVE),
  },
  [PO_ACTIONS.REASSIGN]: {
    TITLE: "Reassign to workflow",
    SUBTITLE: (approvalCount) =>
      PURCHASE_ORDER_APPROVER_DRAWER.INFO(approvalCount, PO_ACTIONS.REASSIGN),
  },
  [PO_ACTIONS.UNASSIGN]: {
    TITLE: "Unassign from workflow",
    SUBTITLE: (approvalCount) =>
      PURCHASE_ORDER_APPROVER_DRAWER.INFO(approvalCount, PO_ACTIONS.UNASSIGN),
  },

  INFO: (approvalCount, actionType) =>
    `You are about to ${actionType} ${
      approvalCount > 1 ? approvalCount : ""
    } purchase request${
      approvalCount > 1 ? "s" : ""
    } for approval. Once ${actionType}ed, ${
      approvalCount > 1 ? "these" : "this"
    } will be sent to the selected workflow.`,
  APPROVAL_TABLE_COLUMN: [
    { label: SUPPLIER_PLACEHOLDER, key: "supplier_name" },
    { label: "Description", key: "description" },
    { label: "Total amount", key: "amount" },
    { key: DELETE_BUTTON_KEY },
  ],
  SELECT_APPROVAL_LABEL: "First approver",
  SELECT_PLACEHOLDER: "Select first approver",
  WORKFLOW_PLACEHOLDER: "Select workflow",
  WORKFLOW_LABEL: "Assign to workflow",
  ASSIGN_BTN_LABEL: (approvalCount) =>
    `Assign ${approvalCount} purchase request${
      approvalCount > 1 ? "s" : ""
    } to workflow`,
  PURCHASE_ORDER_REQUEST_DETAILS: "Purchase request details",
};

export const PURCHASE_ORDER_TABS_KEYS = {
  CREATED: "created",
  IN_REVIEW: "in_review",
  COMPLETED: "completed", //Completed tab now renamed as Open tab 6432
  REJECTED: "rejected",
  CLOSED: "closed",
  DRAFT: "draft",
};

export const PURCHASE_ORDER_TABS = [
  {
    key: PURCHASE_ORDER_TABS_KEYS.CREATED,
    label: "Created",
  },
  {
    key: PURCHASE_ORDER_TABS_KEYS.IN_REVIEW,
    label: "In review",
  },
  {
    key: PURCHASE_ORDER_TABS_KEYS.REJECTED,
    label: "Rejected",
  },
  {
    key: PURCHASE_ORDER_TABS_KEYS.COMPLETED,
    label: "Open",
  },
  {
    key: PURCHASE_ORDER_TABS_KEYS.CLOSED,
    label: "Closed",
  },
];
export const PURCHASE_ORDER_TABS_WITH_DRAFT = [
  {
    key: PURCHASE_ORDER_TABS_KEYS.DRAFT,
    label: "Draft",
  },
  {
    key: PURCHASE_ORDER_TABS_KEYS.CREATED,
    label: "Created",
  },
  {
    key: PURCHASE_ORDER_TABS_KEYS.IN_REVIEW,
    label: "In review",
  },
  {
    key: PURCHASE_ORDER_TABS_KEYS.REJECTED,
    label: "Rejected",
  },
  {
    key: PURCHASE_ORDER_TABS_KEYS.COMPLETED,
    label: "Open",
  },
  {
    key: PURCHASE_ORDER_TABS_KEYS.CLOSED,
    label: "Closed",
  },
];

export const PURCHASE_ORDER_TABLE_COLUMNS_VIEW_KEY = "view";

export const PURCHASE_ORDER_TABLE_COLUMNS_STATUS_KEY = "status";

export const PURCHASE_ORDER_TABLE_COLUMNS_PO_EMAIL = {
  key: "is_po_emailed",
  tooltip: {
    title: `This PO has been emailed to the ${LC_SUPPLIER_PLACEHOLDER}`,
    placement: "left",
  },
  iconName: "attach_email",
};

export const PO_TABLE_COLUMNS_REMAINING_AMOUNT = "remainingAmount";

export const PR_WORKFLOW_KEY = "workflow_name";

export const PURCHASE_ORDER_TABLE_COLUMNS = {
  creationDate: { label: "Completion date", key: "delivery_date" },
  date: { label: "Created date", key: "created_at" },
  supplier: { label: SUPPLIER_PLACEHOLDER, key: "supplier_name" },
  desc: { label: "Description", key: "description" },
  owner: { label: "Owner", key: "owner" },
  amount: { label: "Total amount", key: "amount" },
  view: { label: "View", key: PURCHASE_ORDER_TABLE_COLUMNS_VIEW_KEY },
  status: { label: "Status", key: PURCHASE_ORDER_TABLE_COLUMNS_STATUS_KEY },
  raisedBy: { label: "Raised by", key: "raisedBy" },
  poNumber: { label: "PO number", key: "order_number" },
  nextApprover: { label: "Next Approver", key: "step_name" },
  workflow: { label: "Workflow", key: PR_WORKFLOW_KEY },
  remainingAmount: {
    label: "Remaining Amount",
    key: PO_TABLE_COLUMNS_REMAINING_AMOUNT,
  },
  poEmailed: {
    label: "PO emailed",
    key: PURCHASE_ORDER_TABLE_COLUMNS_PO_EMAIL.key,
  },
};

export const PURCHASE_ORDER_TABLE_CREATED_COLUMNS = [
  { ...PURCHASE_ORDER_TABLE_COLUMNS.date, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.supplier, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.desc, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.owner, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.raisedBy, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.amount, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.view, width: 10, align: "center" },
];

export const PURCHASE_ORDER_TABLE_CREATED_COLUMNS_WIDTH_OA = {
  web: [5, 14, 14, 14, 14, 14, 14, 10],
  mobile: [], //[0, 0, 0, 0, 0, 0, 20, 5],
};

export const PURCHASE_ORDER_TABLE_CREATED_COLUMNS_WIDTH_PU = {
  web: [15, 15, 15, 15, 15, 14, 10],
  mobile: [], //[0, 0, 0, 0, 0, 0, 20, 5],
};

export const PURCHASE_ORDER_TABLE_COMPLETED_COLUMNS_WIDTH_OA = {
  web: [5, 11, 11, 11, 15, 15, 11, 10, 10],
  mobile: [], //[0, 0, 0, 0, 0, 0, 20, 5],
};

export const PURCHASE_ORDER_TABLE_IN_REVIEW_COLUMNS = [
  { ...PURCHASE_ORDER_TABLE_COLUMNS.date, width: 10 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.supplier, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.desc, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.owner, width: 10 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.raisedBy, width: 10 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.amount, width: 10 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.nextApprover, width: 10 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.workflow, width: 10 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.view, width: 10, align: "center" },
];

export const PURCHASE_ORDER_TABLE_REJECTED_COLUMNS = [
  { ...PURCHASE_ORDER_TABLE_COLUMNS.date, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.supplier, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.desc, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.owner, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.raisedBy, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.amount, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.view, width: 10, align: "center" },
];

export const PURCHASE_ORDER_TABLE_COMPLETED_COLUMNS = [
  { ...PURCHASE_ORDER_TABLE_COLUMNS.creationDate, width: 9 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.poNumber, width: 9 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.supplier, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.desc, width: 18 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.raisedBy, width: 12 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.amount, width: 9 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.poEmailed, width: 7, align: "center" },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.remainingAmount, width: 11 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.view, width: 9, align: "center" },
];

export const PURCHASE_ORDER_TABLE_CLOSE_COLUMNS = [
  { ...PURCHASE_ORDER_TABLE_COLUMNS.creationDate, width: 10 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.poNumber, width: 10 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.supplier, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.desc, width: 20 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.raisedBy, width: 15 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.amount, width: 10 },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.poEmailed, width: 10, align: "center" },
  { ...PURCHASE_ORDER_TABLE_COLUMNS.view, width: 10, align: "center" },
];

export const LAUNCH_BUTTON = "launch";

export const PURCHASE_ORDER_APPROVAL_DRAWER = {
  TITLE: "Approve/reject purchase request",
  INFO: (approvalCount) =>
    `You are about to approve/reject ${
      approvalCount > 1 ? approvalCount : ""
    } purchase request${
      approvalCount > 1 ? "s" : ""
    }. Once this has been approved/rejected, ${
      approvalCount > 1 ? "these" : "this"
    } purchase request${
      approvalCount > 1 ? "s" : ""
    } will be moved to completed tab.`,
  APPROVAL_TABLE_COLUMN: [
    { label: SUPPLIER_PLACEHOLDER, key: "supplier_name" },
    { label: "Description", key: "description" },
    { label: "Total amount", key: "amount" },
    { label: "", key: LAUNCH_BUTTON },
  ],
  APPROVAL_BUTTON_LABEL: "Approve all",
  REJECT_BUTTON_LABEL: "Reject all",
  REMOVE_BUTTON_LABEL: "Remove from list",
};

export const PO_APPROVE_REJECT_DRAWER = {
  APPROVE_BUTTON_LABEL: "Approve",
  REJECT_BUTTON_LABEL: "Reject",
  REASSIGN_BUTTON_LABEL: "Reassign",
  UNASSIGN_BUTTON_LABEL: "Unassign",
  APPROVE_TITLE: "Approve purchase requests",
  REJECT_TITLE: "Reject purchase requests",
  REASSIGN_TITLE: "Reassign purchase requests",
  UNASSIGN_TITLE: "Unassign purchase requests",
  RESEND_APPROVAL_EMAIL_BUTTON_LABEL: "Resend approval email",
  INFO: (approvalCount, actionType) =>
    `You are about to ${
      actionType === "approved" ? "approve" : "reject"
    } ${approvalCount} purchase request${
      approvalCount > 1 ? "s" : ""
    }. Once this has been ${
      actionType === "approved" ? "approved" : "rejected"
    }, ${approvalCount > 1 ? "these" : "this"} purchase request${
      approvalCount > 1 ? "s" : ""
    } will be moved to completed tab.`,
  CONFIRM_BUTTON_LABEL: "Confirm",
  REJECTION_REASON_LABEL: "Rejection reason",
};

export const PURCHASE_ORDER_CLOSE_DRAWER = {
  TITLE: "Close purchase orders",
  TITLE_VIEW: "View purchase order",
  INFO_LABEL: "Purchase order: ",
  INFO: (approvalCount) =>
    `You are about to close ${
      approvalCount > 0 ? approvalCount : ""
    } purchase order${approvalCount > 1 ? "s" : ""}. Closing ${
      approvalCount > 1 ? "these" : "this"
    } purchase order${
      approvalCount > 1 ? "s" : ""
    } will prevent further matching and cannot be re-opened.`,
  CLOSE_TABLE_COLUMN: [
    { label: SUPPLIER_PLACEHOLDER, key: "supplier_name" },
    { label: "Description", key: "description" },
    { label: "Total amount", key: "amount" },
  ],
  CONFIRM_BUTTON_LABEL: "Confirm",
  APPROVED_BY: "Approved by: ",
  DOWNLOAD_BUTTON_LABEL: "Download PDF",
  REMOVE_BUTTON_LABEL: "Remove from list",
};

export const PURCHASE_ORDER_REVIEW_DRAWER = {
  TITLE_REVIEW: "Review purchase request",
  TITLE_VIEW: "View purchase request",
  ASSIGN_BUTTON_LABEL: "Assign to workflow",
  APPROVAL_BUTTON_LABEL: "Approve now",
  DELETE_BUTTON_LABEL: "Delete",
  DELETE_PR_BUTTON_LABEL: "Delete purchase request",
  REJECT_BUTTON_LABEL: "Reject",
  EDIT_BUTTON_LABEL: "Edit purchase request",
  APPROVE_BUTTON_LABEL: "Approve",
};

export const PURCHASE_ORDER_DOWNLOAD_DRAWER = {
  TITLE: "Purchase order",
  TITLE_REFACTOR: "Review purchase order",
  INFO_LABEL: "Purchase order: ",
  APPROVED_BY: "Approved by: ",
  REJECTED_BY: "Rejected by: ",
  DOWNLOAD_BUTTON_LABEL: "Download PDF",
  CLOSE_PO_BUTTON: {
    text: "Close PO",
    accent: ACCENTS.SECONDARY,
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
    className: "w-100",
    customSize: "large",
  },
  CLOSE_CONFIRMATION_MODAL: {
    title: (title) => `Close ${title}`,
    subTitle: `Closing this PO will prevent further invoices being matched to it and once closed cannot be re-opened.`,
    confirmButtonClassName: "flex-1",
    cancelButtonClassName: "flex-1",
  },
};

export const PO_ITEM_DETAIL_TABLE_ITEM_NAME = [
  { label: "Item name", key: "name", width: 15 },
];

export const PO_ITEM_DETAIL_TABLE = [
  { label: "Quantity", key: "quantity", width: 8 },
  { label: "Unit cost", key: "cost", width: 10 },
  { label: "Net amount", key: "net_amount", width: 10 },
];

export const PURCHASE_ORDER_BUTTONS = {
  ASSIGN_TO_WORKFLOW: "Assign to workflow",
  APPROVE_NOW: "Approve now",
  IN_REVIEW_APPROVE: "Approve/Reject",
  ACTIONS: "Actions",
  COMPLETED_DOWNLOAD: "Download",
  DELETE: "Delete",
  SEND_TO_SUPPLIER: "Email to supplier",
  CLOSE_PO: "Close purchase orders",
};

export const PURCHASE_ORDER_TOAST_MSGS = {
  created: "Purchase request created",
  updated: "Purchase request updated",
  resubmitted: "Purchase request resubmitted",
  approved: (isMulti) => `Purchase request${isMulti ? "s" : ""} approved`,
  rejected: (isMulti) => `Purchase request${isMulti ? "s" : ""} rejected`,
  deleted: (isMulti) => `Purchase request${isMulti ? "s" : ""} deleted`,
  assigned: (isMulti) =>
    `Purchase request${isMulti ? "s" : ""} assigned for approval`,
  closed: (isMulti) => `Purchase order${isMulti ? "s" : ""} closed`,
  resendApprovalEmail: "Purchase request approval emails resent",
  drafted: "Purchase request saved as draft",
};

export const PURCHASE_ORDER_DATE_KEY = "created_at";

export const NEXT_APPROVER = {
  self: "self",
  selfOrRole: "selfOrRole",
};

export const CUSTOM_FIELD_OPTION = {
  PO_LINE_ITEMS: "Onlypurchaseorderlineitems",
  PO_ONLY: "Onlypurchaseorders",
};

export const PO_GRNI_DETAIL_TABLE = [
  { label: "Date", key: "date", width: 20 },
  { label: "Description", key: "description", width: 30, ellipsis: true },
  { label: "Raiser", key: "raised_by", width: 28, ellipsis: true },
  { label: "Amount", key: "amount", width: 22, align: "right" },
  // { label: "Actions", key: "action", width: 15, align: "center" },
];

export const PO_GRNI_FORM_CONFIG = {
  createConfig: {
    title: "Record GRNI",
    button: {
      text: (isCorrection) => `Record ${isCorrection ? "correction" : "GRNI"}`,
      accent: ACCENTS.PRIMARY,
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      className: "w-100",
      htmlType: "submit",
      customSize: "large",
      showLoader: true,
    },
    notification: (isCorrection = false) =>
      !isCorrection ? `GRNI record added successfully` : "Correction recorded",
  },
  editConfig: {
    title: "Edit GRNI",
    button: {
      text: "Save GRNI",
      accent: ACCENTS.PRIMARY,
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      className: "w-100",
      htmlType: "submit",
      customSize: "large",
      showLoader: true,
    },
    notification: "GRNI record modified successfully",
  },
  closeBtn: {
    text: "Cancel",
    className: "w-100",
    accent: ACCENTS.BLACK,
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
    customSize: "large",
  },
  correctionSwitchLabel: "Do you want to record a correction?",
  formData: (isCorrection) => ({
    date: {
      label: `Date of ${isCorrection ? "correction" : "goods receipt"}`,
      name: "received_date",
      placeholder: isCorrection ? "DD/MM/YYYY" : "Date of goods receipt",
      rules: GRNI_FORM_FIELD_RULES.DATE,
      defaultValue: false,
    },
    description: {
      label: `Description of ${isCorrection ? "correction" : "goods received"}`,
      placeholder: isCorrection
        ? "Enter description"
        : "Description of goods received",
      name: "description",
      rules: GRNI_FORM_FIELD_RULES.DESCRIPTION,
    },
    amount: {
      label: `Value of ${isCorrection ? "correction" : "goods received"}`,
      currency: {
        name: "currency_code_id",
        disabled: true,
      },
      receivedAmount: {
        name: "received_amount",
        placeholder: `Value of ${
          isCorrection ? "correction" : "goods received"
        }`,
        controls: false,
        precision: 2,
      },
    },
  }),
};

export const PO_MATCHED_INVOICES_TABLE = [
  { label: "Invoice number", key: "invoice_number", ellipsis: true, width: 25 },
  { label: "Supplier", key: "payee", ellipsis: true, width: 19 },
  { label: "Description", key: "description", ellipsis: true, width: 20 },
  { label: "Amount", key: "matchedAmount", width: 15 },
  {
    label: "Type",
    key: INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY,
    width: 10,
  },
  { label: "Action", key: "action", width: 11 },
];

export const GRNI_OPERATION_CONFIG = {
  TITLE: {
    variant: "secondary",
    className: "m-t-16px m-b-8px",
    fontStyle: "semibold",
    text: "Goods receipt (GRNI)",
  },
  SUMMARY: {
    generateValue: (totalGRNIAmountWithCurrency) => {
      return `Total GRNI amount: ${totalGRNIAmountWithCurrency}`;
    },
    generateGoodsReceived: ({ props: { currencyData, totalAmountRef } }) => {
      return `Total value of goods received: ${moneyFormatter(
        totalAmountRef?.valueGoodsReceived,
        2,
        currencyData?.currency
      )}`;
    },
    displayGRNIAvailableMatchingValue: (totalGrniAvailable) => {
      return `GRNI available for invoice matching: ${totalGrniAvailable}`;
    },
    poAvailableValue: ({
      props: { currencyData, totalAmountRef, net_amount },
    }) => {
      const total =
        net_amount -
        (totalAmountRef?.totalGRNIAmount +
          totalAmountRef?.totalMatchedInvoiceAmount);
      let totalPoAvailable = moneyFormatter(
        total < 0 ? 0 : total,
        2,
        currencyData?.currency
      );

      return `PO available for GRNI: ${totalPoAvailable}`;
    },
    GRNI_TOOLTIP: {
      iconName: "info",
      grniTotalText: "Total sum of all goods received",
      grniGoodsReceived: "Sum of all positive GRNI entries, minus corrections",
      grniMatchingText:
        "The total value of goods received minus the sum of all matched invoices",
      poTotalText:
        "The purchase order net amount minus the total value of goods received",
    },
    type: {
      variant: "body",
      fontStyle: "semibold",
      className: "m-r-12px text-align-right text-nowrap",
    },
  },
  RECORD_GRNI_BUTTON: {
    text: "Record GRNI",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    className: "w-100",
    customSize: "large",
  },
  DELETE: {
    title: "Delete GRNI record",
    subTitle:
      "You are about to delete this GRNI record. Alternatively, you can add a negative balance entry if you have been invoiced for these goods received.",
    notification: "GRNI record deleted successfully",
  },
};

export const MATCHED_INVOICES_OPERATION_CONFIG = {
  TITLE: {
    variant: "secondary",
    className: "m-t-16px m-b-8px",
    fontStyle: "semibold",
    text: "Matched invoices",
  },
  MATCH_INVOICES_BUTTON: {
    text: "Match invoices",
    accent: ACCENTS.SECONDARY,
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
    className: "w-100",
    customSize: "large",
  },
  MATCH_INVOICED_VALUE_SUMMARY: {
    generateValue: (totalMatchedInvoiceAmountWithCurrency) =>
      `Total matched amount: ${totalMatchedInvoiceAmountWithCurrency}`,
    type: {
      variant: "body",
      className: "text-align-right m-t-8px text-nowrap w-100",
      fontStyle: "semibold",
    },
  },
};

export const PO_AUDIT_LOG_CONFIG = {
  AUDIT_LOG_BUTTON: {
    text: "View audit log",
    className: "m-v-16px",
    accent: ACCENTS.LINK,
    variant: BUTTON_VARIANT.LINK,
  },
  AUDIT_MODAL_TITLE: "Purchase request audit log",
  AUDIT_LOG_COLUMN: [
    { label: "Date and time", key: "date", width: 16 },
    { label: "Actioning user", key: "user", ellipsis: true, width: 16 },
    { label: "Email", key: "email", ellipsis: true, width: 18 },
    { label: "Before", key: "before", width: 25 },
    { label: "After", key: "after", width: 25 },
  ],
};

export const PO_SEND_SUPPLIER_MODAL = {
  TOOL_TIP: {
    TITLE: `This PO cannot be emailed as the supplier is no longer active.`,
    HOVER: "hover",
  },
  TITLE: (selected_items = []) => {
    return `Email PO${selected_items.length > 1 ? "s" : ""} to supplier${
      selected_items.length > 1 ? "s" : ""
    }`;
  },
  SUB_TITLE: (selected_items = []) => {
    return `The selected PO${
      selected_items.length > 1 ? "s" : ""
    } will be emailed to the ${
      selected_items.length > 1 ? "corresponding" : ""
    } supplier at the provided email address. Please review and ensure that all details are correct before proceeding.`;
  },
  SEND_BUTTON: {
    text: "Send",
  },
  CANCEL_BUTTON: {
    text: "Cancel",
  },
  PO_NUMBER_INPUT: {
    label: "PO number",
    placeholder: "PO number",
    name: "poNumber",
  },
  SUPPLIER_INPUT: {
    label: "Supplier",
    placeholder: "Supplier",
    name: "supplier",
  },
  EMAIL_INPUT: {
    label: "Email address",
    placeholder: "Email address",
    name: "emailAddress",
    rules: (validate) => (validate ? SEND_SUPPIER_EMAIL_FORM.EMAIL : []),
  },
  SUCCESS_MESSAGE: (selected_items = []) => {
    return `PO${selected_items.length > 1 ? "s" : ""} sent to supplier${
      selected_items.length > 1 ? "s" : ""
    } successfully`;
  },
};

export const UPDATE_TRIGGER_MODAL = {
  title: "Confirm update purchase request",
  subtitle_type: "purchase request",
};

export const ASSIGNMENT_TYPES = {
  ASSIGN: "assign",
  REASSIGN: "reassign",
  UNASSIGN: "unassign",
  RESET: "reset",
};

export const STATIC_TRIGGER_FIELDS = ["Owner", "Supplier"];

export const STATIC_ERP_TRIGGER_FIELDS = ["Items"];

export const AUTOMATION_TRIGGER_MODAL = {
  title: "Confirm updates",
  get_subtitle: ({ assignmentType, triggerField, type }) => {
    let subtitle = `By changing the value in the ${triggerField}, this ${type} will be `;
    switch (assignmentType) {
      case ASSIGNMENT_TYPES.ASSIGN:
        subtitle += `automatically assigned to a workflow.`;
        break;
      case ASSIGNMENT_TYPES.REASSIGN:
        subtitle += `reassigned to a different workflow.`;
        break;
      case ASSIGNMENT_TYPES.UNASSIGN:
        subtitle += `unassigned from its current workflow, requiring manual assignment.`;
        break;
      case ASSIGNMENT_TYPES.RESET:
        subtitle = `As this purchase request is being reviewed, making these edits will require re-approval from users who may have already actioned it. Are you sure you want to continue?`;
        return subtitle;
      default:
        break;
    }
    return subtitle + " Please confirm if you are happy to proceed.";
  },
};

export const SMART_APPROVAL_PO_CREATOR = {
  PO_CREATOR: {
    TITLE: {
      text: `Purchase request creators`,
      variant: "title",
      responsiveFontSize: false,
      fontSize: 24,
    },
    SUB_TITLE: {
      text: ` In addition to Organisation Admins, select the users who can create purchase requests. Users who are approvers within workflows cannot be added as purchase request creators.`,
      variant: "secondary",
    },
  },
  SEARCH_USERS_PLACEHOLDER: "Search users",
  PR_CREATOR_TOOLTIP:
    "Due to segregation of duty, this user cannot be added as a purchase request creator since they are a purchase order approver",

  APPROVER_TOOLTIP:
    "Due to segregation of duty, this user cannot be added as an approver since they are a purchase request creator",
  PR_CREATE_MODAL: ({ enabled }) => {
    return {
      title: `${enabled ? "Disable" : "Enable"} segregation of duty`,
      subTitle: enabled
        ? "Would you like to disable segregation of duty? Toggling this off will allow users to be both purchase request creators and purchase request approvers."
        : "Would you like to enable segregation of duty? Toggling this on will prevent users from being both purchase request creators and purchase request approvers.",
      mandatoryCheckboxConfirmation: false,
    };
  },
};

export const PO_REJECTION_REASON = {
  TITLE: "Reject purchase order",
  SUB_TITLE: "Are you sure you would like to reject this request?",
};

export const PURCHASE_ORDER_VIEW_ITEMS = {
  label: {
    fontStyle: "semibold",
    className: "m-b-0px",
    variant: "secondary",
  },
  value: {
    className: "m-b-15px",
    variant: "secondary",
  },
  placeholder: {
    className: "m-b-15px font-style-italic",
    variant: "secondary",
  },
};

export const PURCHASE_ORDER_GENERATED_USING_AI =
  "This purchase request has been generated using Kloo AI";

export const PURCHASE_ORDER_POPULATED_USING_AI =
  "Purchase request data populated using Kloo AI";

export const PURCHASE_REQUEST_PAGE_UPDATE = {
  SEARCHTEXT: "Search purchase requests",
  SEARCH_PURCHASE_ORDERS: "Search for purchase orders",
  LABEL: "Purchase requests",
  REVIEW_PURCHASE_REQUEST: "Review purchase request",
  PR_REJECTION_REASON: {
    TITLE: "Reject purchase request",
    SUB_TITLE: "Are you sure you would like to reject this purchase request?",
  },
  PR_SELECTED: (count) => `purchase request${count > 1 ? "s" : ""} selected`,
  PO_SELECTED: (count) => `purchase order${count === 1 ? "" : "s"} selected`,
  PR_EMAIL_UPLOAD: {
    BTN_LABEL: "Conversational purchase request via Kloo AI",
    MODAL: {
      TITLE: "Conversational purchase request via Kloo AI",
      SUBTITLE:
        "Submit a purchase request using natural language directly from your mailing box. Simply send your request to the email address below for automated creation on Kloo.",
    },
  },
};

export const TRIGGER_SUPPLIER_ID = "supplier_id";

export const GEN_AI_TRIGGER_MAPPING = [
  {
    mappingKey: PURCHASE_ORDER_CREATE_FROM.entity.label,
    value: PURCHASE_ORDER_CREATE_FROM.entity.name,
  },
  {
    mappingKey: PURCHASE_ORDER_CREATE_FROM.FORM_LABELS.supplier,
    value: TRIGGER_SUPPLIER_ID,
  },
  {
    mappingKey: PURCHASE_ORDER_CREATE_FROM.taxCode.label,
    value: PURCHASE_ORDER_CREATE_FROM.taxCode.name,
  },
];

export const itemERPFieldId = "entity_erp_fields";

export const remainingAmountTooltipConstant = {
  title:
    "The remaining amount of this purchase order is less than 5% of the original raised net amount.",
  button: "Close purchase order",
};
