import React, { useState, useEffect } from "react";
import RouterList from "./route";
import "react-phone-input-2/lib/style.css";
// import { getCookie } from './utils/constant';
import { newRefreshToken } from "./redux/action/auth";
import jwt_decode from "jwt-decode";
import CacheBuster from "./CacheBuster";
import socketConnect from "./components/socketIO/index";
import * as Sentry from "@sentry/react";
import { isEngageEnvironment, isPlatformEnvironment } from "./utils";
import { useSelector } from "react-redux";

let callRefreshed = false;

const Loader = () => {
  return <>Loading</>;
};

const App = () => {
  const refreshToken = localStorage.getItem("refresh_token");
  const [showPages, setShow] = useState(!refreshToken);

  const organizationName = useSelector(
    (state) =>
      state &&
      state?.modular &&
      state?.modular?.accountDetail?.[0]?.organization_name
  );

  useEffect(() => {
    if (organizationName) Sentry.setTag("Organization name", organizationName);
  }, [organizationName]);

  useEffect(() => {
    if (isPlatformEnvironment() && !isEngageEnvironment()) {
      Sentry.init({
        release: "1fb54ee8c2fe3a6b9bd84e01d3cb2759b3e7cd55",
        dsn: "https://766fc042edde28bbf97f50fa40112e58@o4506711065624576.ingest.us.sentry.io/4506711073226752",
        integrations: [
          // Add browser profiling integration to the list of integrations
          new Sentry.BrowserProfilingIntegration(),
          new Sentry.BrowserTracing(),
          Sentry.replayIntegration(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", "https://platform.getkloo.com"],
        tracePropagationTargets: ["https://platform.getkloo.com"],
        // Set profilesSampleRate to 1.0 to profile every transaction.
        // Since profilesSampleRate is relative to tracesSampleRate,
        // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
        // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
        // results in 25% of transactions being profiled (0.5*0.5=0.25)
        profilesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        beforeSend(event) {
          // Filter out specific errors (like 404 status)
          if (event.request && event.request.url.includes("404")) {
            return null; // Do not send this event to Sentry
          }
          return event;
        },
      });
    }
    const clarityScriptDev =
      '(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "fbjptw46oy");';
    const clarityScriptStage =
      '(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "fc1g3tg99v");';
    const clarityScriptProd =
      '(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "onmae2px8h");';
    var script = document.createElement("script");
    script.type = "text/javascript";
    if (process.env.REACT_APP_URL === "https://dev.getkloo.com/api") {
      script.text = clarityScriptDev;
      document.head.appendChild(script);
    } else if (process.env.REACT_APP_URL === "https://stage.getkloo.com/api") {
      script.text = clarityScriptStage;
      document.head.appendChild(script);
    } else if (
      process.env.REACT_APP_URL === "https://platform.getkloo.com/api"
    ) {
      script.text = clarityScriptProd;
      document.head.appendChild(script);
    }
  }, []);

  // Start of HubSpot Embed Code script added
  function hubSpotScript() {
    const scriptHubSpot = document.createElement("script");
    scriptHubSpot.src = "//js-eu1.hs-scripts.com/26056849.js";
    scriptHubSpot.type = "text/javascript";
    scriptHubSpot.id = "hs-script-loader";
    scriptHubSpot.defer = true;
    scriptHubSpot.async = true;
    document.head.appendChild(scriptHubSpot);
  }

  useEffect(() => {
    hubSpotScript();
  }, []);

  // end of HubSpot Embed Code script added

  if (refreshToken && !callRefreshed) {
    let decoded;
    try {
      const accessToken = localStorage.getItem("access_token");
      decoded = jwt_decode(accessToken);
      if (decoded?.exp) {
        const time = new Date().getTime();
        decoded = decoded?.exp * 1000 > time;
      }
    } catch (e) {}

    if (!decoded) {
      newRefreshToken().then((res) => {
        callRefreshed = true;
        setShow(true);
      });
    } else {
      callRefreshed = true;
      setShow(true);
      setTimeout(() => {
        newRefreshToken().then();
      }, 15000);
    }
    socketConnect();

    // let version = localStorage.getItem('version');
    // if(version != packageJson.version){
    //   if('caches' in window){
    //     caches.keys().then((names) => {
    //       names.forEach(name => {
    //         caches.delete(name);
    //       })
    //     })
    //   }
    //   window.location.reload(true);
    // }
    // else{
    //   localStorage.clear();
    //   localStorage.setItem('version',packageJson.version);
    // }
  }

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          showPages && (
            <div className="App">
              <RouterList />
            </div>
          )
        );
      }}
    </CacheBuster>
  );
};

export default Sentry.withProfiler(App);
