import { GRANT_TYPE, featureFlags } from "../../constants";
import {
  CLIENT_ID,
  CLIENT_SECRET,
  SSO_CLIENT_ID,
  SSO_CLIENT_SECRET,
} from "../../redux/service/apiConstant";
import {
  generateColorCode,
  getItem,
  isDevelopmentEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
  LOCAL_STORAGE_KEY,
  setItem,
} from "../../utils";

export function organizationsListHandler(orgList) {
  const data = orgList?.map((orgs) => ({
    ...orgs,
    bgcolor: generateColorCode(),
  }));
  return data || [];
}

export function refreshTokenPayload({ SSO = false, org_id = "" }) {
  return {
    refresh_token: getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN),
    org_id: org_id ? org_id : getItem(LOCAL_STORAGE_KEY.ORD_ID),
    user_id: getItem(LOCAL_STORAGE_KEY.USER_ID),
    grant_type: GRANT_TYPE.refreshToken,
    client_id: SSO ? SSO_CLIENT_ID : CLIENT_ID,
    client_secret: SSO ? SSO_CLIENT_SECRET : CLIENT_SECRET,
    scope: "*",
  };
}

export function loginRequestPayload({
  SSO = false,
  username = "",
  password = "",
}) {
  return {
    username,
    password,
    grant_type: GRANT_TYPE.password,
    client_id: SSO ? SSO_CLIENT_ID : CLIENT_ID,
    client_secret: SSO ? SSO_CLIENT_SECRET : CLIENT_SECRET,
    scope: "*",
  };
}

export function localStoragePostLoginHandler(response = {}) {
  Object.values(LOCAL_STORAGE_KEY)
    ?.filter((key) => response?.[key])
    ?.forEach((key) => {
      setItem(key, response?.[key]);
    });
}

export const removeKey = (string) => {
  return string.slice(13);
};

export function isRedirectAfterLoginEabled() {
  return (
    isDevelopmentEnvironment() &&
    featureFlags.auth.redirectAfterLogin.enableForDev
  );
}
