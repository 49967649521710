import { INVOICE_MANAGEMENT_TAB_KEYS, featureFlags } from "../../constants";
import {
  CREDIT_NOTES_TABLE_COLUMNS_ALLOCATED_AMOUNT_KEY,
  CREDIT_NOTES_TABLE_COLUMNS_INVOICE_AMOUNT_KEY,
  CREDIT_NOTES_TOAST_MSGS,
  DISABLED_INVOICE_TOOLTIP_STATUS_WISE_OBJ,
  INVOICE_LINKING_PAGE_CONFIG,
} from "../../constants/CreditNotes";
import {
  isDevelopmentEnvironment,
  roundOff,
  moneyFormatter,
  getOnlyAmount,
  isStageEnvironment,
  isPlatformEnvironment,
  getItem,
  LOCAL_STORAGE_KEY,
  isEngageEnvironment,
} from "../../utils";

const {
  FOOTER: { LESS_CREDIT_AMOUNT, REMAINING_CREDIT_AMOUNT },
} = INVOICE_LINKING_PAGE_CONFIG;

export function getCreditNoteDiff({
  currentDBAppliedInvoices = [],
  appliedInvoices = [],
  formValues,
}) {
  const invoiceApplied = appliedInvoices
    .map((invoiceData) => {
      const { id, applied_amount } = invoiceData;
      const dbAppliedAmount = getOnlyAmount(applied_amount);
      const currentAppliedAmount =
        formValues[`${CREDIT_NOTES_TABLE_COLUMNS_ALLOCATED_AMOUNT_KEY}${id}`];

      if (currentAppliedAmount !== dbAppliedAmount) {
        return {
          invoice_id: id,
          amount: currentAppliedAmount,
        };
      }
      return false;
    })
    .filter((data) => data);

  const invoiceRemoved = currentDBAppliedInvoices.filter(
    (item) => !appliedInvoices.find(({ id }) => id === item.id)
  );

  return {
    invoiceApplied,
    isInvoiceAdded: invoiceApplied.length !== 0,
    isMultipleInvoiceAdded: invoiceApplied.length > 1,
    invoiceRemoved: invoiceRemoved?.map(({ id }) => id),
    isInvoiceRemoved: invoiceRemoved.length !== 0,
    isMultipleInvoiceRemoved: invoiceRemoved.length > 1,
  };
}

export function calculateTotalAllocatedCreditNoteAmount({
  selectedInvoices,
  form,
}) {
  const total = selectedInvoices.reduce((acc, record) => {
    let fieldValue = form.getFieldValue(
      `${CREDIT_NOTES_TABLE_COLUMNS_ALLOCATED_AMOUNT_KEY}${record.id}`
    );
    if (fieldValue) {
      acc += fieldValue;
    }
    return acc;
  }, 0);
  return roundOff(total);
}

export function amountToBeCreditedFieldValidation({
  creditNoteData,
  appliedInvoices,
  record,
  form,
}) {
  const creditNoteMaxAmount = getOnlyAmount(creditNoteData?.total_amount);

  const creditNoteMaRemainingAmount = getOnlyAmount(
    creditNoteData?.remaining_amount
  );

  const formattedCreditNoteMaxAmount = moneyFormatter(
    creditNoteMaRemainingAmount,
    2
  );

  const getMaxAllocationValue = (record) =>
    getOnlyAmount(record[CREDIT_NOTES_TABLE_COLUMNS_INVOICE_AMOUNT_KEY]);

  const getInvoiceRemainingAmount = (record) => {
    return (
      creditNoteMaxAmount -
      calculateTotalAllocatedCreditNoteAmount({
        selectedInvoices: appliedInvoices.filter(
          (item) => item.id !== record.id
        ),
        form,
      })
    );
  };

  return {
    validator(_, value) {
      let convertedAllocatedGBPAmount = Number(value);
      const maxAllocationAmount = getMaxAllocationValue(record);
      const invoiceRemainingAmount = getInvoiceRemainingAmount(record);
      const maxValue = roundOff(
        maxAllocationAmount > invoiceRemainingAmount
          ? invoiceRemainingAmount
          : maxAllocationAmount
      );
      if (!value) {
        return Promise.reject("Please enter allocated amount");
      } else if (convertedAllocatedGBPAmount <= 0) {
        return Promise.reject(
          new Error("Allocated amount must be greater than zero")
        );
      } else if (
        appliedInvoices.length === 1 &&
        convertedAllocatedGBPAmount > creditNoteMaxAmount
      ) {
        return Promise.reject(
          new Error(
            `Allocated amount should be less than ${formattedCreditNoteMaxAmount}`
          )
        );
      } else if (
        appliedInvoices.length > 1 &&
        convertedAllocatedGBPAmount >= creditNoteMaxAmount
      ) {
        return Promise.reject(
          new Error(
            `Allocated amount should be less than ${formattedCreditNoteMaxAmount}`
          )
        );
      } else if (Number(value) > maxValue) {
        return Promise.reject(
          new Error(
            `Allocated amount should be less than ${moneyFormatter(maxValue)}`
          )
        );
      } else {
        return Promise.resolve();
      }
    },
  };
}

export function getCreditNoteAmountDetails({ summaryData }) {
  const helperTextArr = [
    {
      helperText: LESS_CREDIT_AMOUNT,
      value: summaryData?.lessAmount || 0,
    },
    {
      helperText: REMAINING_CREDIT_AMOUNT,
      value: summaryData?.remainingAmount || 0,
    },
  ];

  return {
    helperTextArr,
  };
}

export function checkInvoiceStatus({ invoices = [] }) {
  return invoices.map(({ status, ...rest }) => {
    const isDisabled = [
      INVOICE_MANAGEMENT_TAB_KEYS.SCHEDULED,
      INVOICE_MANAGEMENT_TAB_KEYS.PAID,
    ].includes(status);

    return {
      isDisabled,
      tooltipObjAtRowLevel: isDisabled
        ? DISABLED_INVOICE_TOOLTIP_STATUS_WISE_OBJ[status]
        : {},
      ...rest,
    };
  });
}

export function getPayloadForApplyingCreditNoteToInvoice({
  formValues,
  creditNoteData,
  appliedInvoices,
  appliedInvoicesRef,
}) {
  const {
    invoiceApplied,
    isInvoiceAdded,
    isMultipleInvoiceAdded,
    invoiceRemoved,
    isInvoiceRemoved,
    isMultipleInvoiceRemoved,
  } = getCreditNoteDiff({
    currentDBAppliedInvoices: checkInvoiceStatus({
      invoices: appliedInvoicesRef?.current?.linkedInvoices,
    })?.filter(({ isDisabled }) => !isDisabled),
    appliedInvoices: appliedInvoices.filter(({ isDisabled }) => !isDisabled),
    formValues,
  });

  const { ADDED, REMOVED, UPDATED } =
    CREDIT_NOTES_TOAST_MSGS.applyRemoveInvoice;

  let notificationText = "";
  if (isInvoiceAdded && isInvoiceRemoved) {
    notificationText = UPDATED({
      hasMultipleInvoice: true,
    });
  } else if (isInvoiceAdded) {
    notificationText = ADDED({
      hasMultipleInvoice: isMultipleInvoiceAdded,
    });
  } else if (isInvoiceRemoved) {
    notificationText = REMOVED({
      hasMultipleInvoice: isMultipleInvoiceRemoved,
    });
  }

  return {
    notificationText,
    payload: {
      id: creditNoteData?.id,
      apply: invoiceApplied,
      remove: invoiceRemoved,
    },
  };
}

export const isCreditNoteFeatureAvailableForCurrentOrgOnProd = () => {
  const CREDIT_NOTE_FEATURE_ENABLED_PROD_ORG_ID = [
    "0f9056c4-984a-4431-a3aa-db2cc147d597",
    "c9727487-3b7a-11ed-b538-a4fc776c6f93", // Kloo
    "78d87d0e-8dc8-11ee-9a90-0a1c22ac2fa6", // Avado
  ];
  const orgId = getItem(LOCAL_STORAGE_KEY.ORD_ID);
  return (
    (isPlatformEnvironment() &&
      CREDIT_NOTE_FEATURE_ENABLED_PROD_ORG_ID.includes(orgId)) ||
    true
  );
};

export function isAuditLogsEnabledInCreditNotes() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.creditNotes.auditLogs_10262.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.creditNotes.auditLogs_10262.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.creditNotes.auditLogs_10262.enableForProd)
  );
}

export function isRejectionReasonEnabledInCreditNotes() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.creditNotes.rejectionReason_10463.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.creditNotes.rejectionReason_10463.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.creditNotes.rejectionReason_10463.enableForProd)
  );
}
