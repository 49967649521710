import { Form } from "antd";
import React, {
  useEffect,
  Children,
  useImperativeHandle,
  useState,
} from "react";
import {
  Card,
  Typography,
  CommonSelect,
  CommonInputNumber,
  Button,
  When,
  Icon,
  CustomButton,
  Label,
  EnvFeatureFlag,
} from "../../../common";
import {
  getWorkflowCategory,
  getCustomFieldsOnlyDynamicAndPredefined,
  getCustomFieldIdValues,
} from "../../../../api";
import {
  amountFormatter,
  getRequiredRule,
  moneyFormatter,
  ordinal,
  convertLowerCaseWithoutSpace,
  constructClassName,
  SMART_APPROVAL_PO,
} from "../../../../utils";
import {
  ACCENTS,
  BUTTON_VARIANT,
  SMART_APPROVALS_TABS_KEYS,
  SMART_APPROVAL_PO_CREATOR,
  WORKFLOW_APPROVER_USERS_LABEL,
  WORKFLOW_FORM,
  featureFlags,
} from "../../../../constants";
import { isConditionalThresholdEnabledForPO } from "../../../../helper";

const StepFrom = React.forwardRef(
  (
    {
      formData,
      approverInputs,
      setApproverInputs,
      stepIndex = 0,
      steps,
      approverList,
      addNewStep,
      isEditView,
      handleCloseForm,
      setDisableApprovers,
      disabledApprovers,
      activeKey,
      selectedStepType,
      setSelectedStepType,
    },
    ref
  ) => {
    const [form] = Form.useForm();

    const [stepTypes, setStepTypes] = useState([]);
    const [approvers, setApprovers] = useState(approverList);
    const [internalDisabledOption, setInternalDisabledOption] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsId, setCustomFieldsId] = useState("");
    const [customFieldValues, setCustomFieldValues] = useState([]);

    const isPoTab = activeKey === SMART_APPROVAL_PO.key;

    const secondLastApproverLimit = Form.useWatch(
      `limit${approverInputs.length - 1}`,
      form
    );

    const isUserRoleStepType =
      convertLowerCaseWithoutSpace(selectedStepType || "") ===
      convertLowerCaseWithoutSpace(WORKFLOW_FORM.ROLE_USER_STEP_TYPE);

    const isConditionalThresholdStepTypeSelected =
      convertLowerCaseWithoutSpace(selectedStepType || "") ===
      convertLowerCaseWithoutSpace(
        WORKFLOW_FORM.CONDITIONAL_THRESHOLD_STEP_TYPE
      );
    useEffect(() => {
      getWorkflowCategory(activeKey).then((res) => {
        //filtering conditional threshold for Invoice and Payments Run
        if (!isPoTab) {
          res = res.filter(
            (i) =>
              convertLowerCaseWithoutSpace(i.label || "") !==
              convertLowerCaseWithoutSpace(
                WORKFLOW_FORM.CONDITIONAL_THRESHOLD_STEP_TYPE
              )
          );
        }
        setStepTypes(res);
      });
    }, []);

    useEffect(() => {
      if (![SMART_APPROVALS_TABS_KEYS.CREDIT_NOTES].includes(activeKey)) {
        getCustomFieldsOnlyDynamicAndPredefined({
          integrationType: activeKey,
        }).then((res) => setCustomFields(res));
      }
    }, [activeKey]);

    useEffect(() => {
      setCustomFieldValues([]);
      if (!!customFieldsId) {
        getCustomFieldIdValues({
          id: customFieldsId,
          integrationType: activeKey,
        }).then((res) =>
          setCustomFieldValues(
            res?.map((i) => {
              return { value: i.id, label: i.value };
            })
          )
        );
      }
    }, [customFieldsId]);

    useEffect(() => {
      setSelectedStepType(formData?.stepTypeName);
      setCustomFieldsId(formData?.custom_field_id);
      if (formData?.totalSteps) {
        form.resetFields();
        const approverFields = [];
        for (let index = 1; index <= formData.totalSteps; index++) {
          approverFields.push({
            approverLabel: `${ordinal(index)} approver`,
            approverFieldName: `approver${index}`,
            limitFieldName: `limit${index}`,
            customFieldValueName: `custom_field_value_id${index}`,
          });
        }
        setApproverInputs(approverFields);
        const approverKeys = Object.keys(formData).filter((key) =>
          key.includes("approver")
        );
        approverKeys.forEach((key) => {
          formData[key] = [
            ...approverList[0].options,
            ...approverList[1].options,
          ].find((option) => option.value === formData[key])
            ? formData[key]
            : null;
        });
        if (!!customFields.length) {
          const field = customFields.find(
            (i) => i.id === formData["custom_field_id"]
          );

          if (!field) {
            formData["custom_field_id"] = null;
            approverKeys.forEach((i, index) => {
              formData[`custom_field_value_id${index + 1}`] = null;
            });
            setCustomFieldsId(null);
          }
        }
        if (approverKeys.length) {
          setInternalDisabledOption(
            approverKeys.map((key) => `${formData[key]}~${key}`)
          );
        }
        form.setFieldsValue(formData);
      } else {
        setInternalDisabledOption([]);
      }
    }, [formData, customFields]);

    useEffect(() => {
      if (
        formData?.totalSteps &&
        !!customFieldValues.length &&
        !!formData["custom_field_id"]
      ) {
        form.resetFields();
        const approverKeys = Object.keys(formData).filter((key) =>
          key.includes("approver")
        );
        approverKeys.forEach((key) => {
          formData[key] = [
            ...approverList[0].options,
            ...approverList[1].options,
          ].find((option) => option.value === formData[key])
            ? formData[key]
            : null;
        });
        if (!!customFields.length) {
          const field = customFields.find(
            (i) => i.id === formData["custom_field_id"]
          );

          const fieldValueArray = approverKeys.map(
            (i, index) => formData[`custom_field_value_id${index + 1}`]
          );
          const areAllKeysPresent = fieldValueArray.every((key) =>
            customFieldValues.some((obj) => obj.value === key)
          );

          if (field && !areAllKeysPresent) {
            approverKeys.forEach((i, index) => {
              formData[`custom_field_value_id${index + 1}`] = null;
            });
          }

          form.setFieldsValue(formData);
        }
      }
    }, [customFieldValues]);

    useImperativeHandle(ref, () => ({
      handleForm() {
        return handleForm();
      },
      resetForm() {
        handleResetForm();
      },
    }));

    useEffect(() => {
      if (approverInputs.length > 1) {
        form.setFieldValue(
          `limit${approverInputs.length}`,
          secondLastApproverLimit
        );
      }
    }, [secondLastApproverLimit, approverInputs]);

    useEffect(() => {
      form.setFieldValue("totalSteps", approverInputs.length);
      if (approverInputs.length > 2) {
        form.validateFields(["limit2"]);
      }
    }, [approverInputs]);

    useEffect(() => {
      if (steps.length && approvers.length) {
        setDisabledOption(steps);
      }
    }, [steps, stepIndex]);

    const setDisabledOption = (options) => {
      let newDisabledOptions = [];
      options.forEach((option) => {
        if (option?.data) {
          const getCurrentApprovers = Object.keys(option.data).filter((key) =>
            key.includes("approver")
          );
          if (getCurrentApprovers.length) {
            // if (activeKey === "po") {
            if (
              option.stepIndex === stepIndex &&
              (Object.values(option.data).includes(
                WORKFLOW_FORM.AUTO_APPROVER_ID.DEV
              ) ||
                Object.values(option.data).includes(
                  WORKFLOW_FORM.AUTO_APPROVER_ID.STAGE
                ))
            ) {
              newDisabledOptions = [
                ...newDisabledOptions,
                ...getCurrentApprovers.map((key) => option.data[key]),
              ];
            } else {
              newDisabledOptions = [
                ...newDisabledOptions,
                ...getCurrentApprovers
                  .map((key) => option.data[key])
                  ?.filter(
                    (key) =>
                      key !== WORKFLOW_FORM.AUTO_APPROVER_ID.DEV &&
                      key !== WORKFLOW_FORM.AUTO_APPROVER_ID.STAGE
                  ),
              ];
            }
            // } else {
            //   newDisabledOptions = [
            //     ...newDisabledOptions,
            //     ...getCurrentApprovers.map((key) => option.data[key]),
            //   ];
            // }
            if (option.stepIndex === stepIndex) {
              const makePreviousState = getCurrentApprovers.map(
                (key) => `${option.data[key]}~${key}`
              );
              setInternalDisabledOption(makePreviousState);
            }
          }
        }
      });
      setDisableApprovers(newDisabledOptions);
    };

    const handleDisablingOption = () => {
      setApprovers(
        approvers?.map((option) => {
          const newOptions = option.options.map((item) => ({
            ...item,
            disabled: disabledApprovers.includes(item.value),
          }));
          return {
            ...option,
            options: newOptions,
          };
        })
      );
    };

    const handleAddApprover = () => {
      const nextApproverCount = approverInputs.length + 1;
      setApproverInputs((inputs) => [
        ...inputs,
        {
          approverLabel: `${ordinal(nextApproverCount)} approver`,
          approverFieldName: `approver${nextApproverCount}`,
          limitFieldName: `limit${nextApproverCount}`,
          customFieldValueName: `custom_field_value_id${nextApproverCount}`,
        },
      ]);
    };

    const handleForm = () => {
      return form.validateFields();
    };

    const handleResetForm = () => {
      form.resetFields();
    };

    const removeField = (idx) => {
      const approverFieldName = `approver${idx + 1}`;
      const approverFieldValue = form.getFieldValue(approverFieldName);
      if (approverFieldValue) {
        setDisableApprovers(
          disabledApprovers.filter((item) => item !== approverFieldValue)
        );
      }
      setApproverInputs(
        approverInputs.filter((inputs, index) => index !== idx)
      );
      form.setFieldValue(approverFieldName, null);
      form.setFieldValue(`limit${idx}`, 0);
      form.setFieldValue(`limit${idx + 1}`, null);
      form.validateFields([`limit${idx}`]);
    };

    const handleLimitValidation = (index) => ({
      validator(_, value) {
        if (Number(value) === 0) {
          return Promise.reject(
            new Error(`Amount must be greater than ${moneyFormatter(0)}`)
          );
        }
        if (!value) {
          return Promise.reject(new Error("Please enter a threshold amount"));
        }
        if (!(approverInputs.length > 2)) {
          return Promise.resolve();
        }
        const minValue = form.getFieldValue(`limit${index}`);
        if (Number(value) <= Number(minValue)) {
          return Promise.reject(
            new Error(`Amount must be greater than ${moneyFormatter(minValue)}`)
          );
        }
        return Promise.resolve();
      },
    });

    const handleApproverSelectChange = (v, fieldName) => {
      const selectedOption = approvers?.reduce((foundOption, group) => {
        return foundOption || group.options.find((opt) => opt.value === v);
      }, null);
      if (selectedOption && selectedOption?.showTooltip) {
        form.setFieldValue(fieldName, null);
        return;
      }

      const keyValue = `${v}~${fieldName}`;
      const isValueExist = internalDisabledOption.find((item) =>
        item.includes(fieldName)
      );
      if (isValueExist) {
        setInternalDisabledOption([
          ...internalDisabledOption.filter((v) => v !== isValueExist),
          keyValue,
        ]);
      } else {
        setInternalDisabledOption([...internalDisabledOption, keyValue]);
      }
      const updatedDisabledOptions = isValueExist
        ? disabledApprovers.filter((v) => v !== isValueExist.split("~")[0])
        : disabledApprovers;
      setDisableApprovers([...updatedDisabledOptions, v]);
    };

    useEffect(() => {
      handleDisablingOption();
    }, [disabledApprovers]);

    const handleClear = (fieldName) => {
      const isValueExist = internalDisabledOption.find((item) =>
        item.includes(fieldName)
      );
      if (isValueExist) {
        setInternalDisabledOption(
          internalDisabledOption.filter((v) => v !== isValueExist)
        );
        setDisableApprovers(
          disabledApprovers.filter((v) => v !== isValueExist.split("~")[0])
        );
      }
    };

    const handleStepTypeSelectChange = (options) => {
      setSelectedStepType(options.label);
      for (let i = 1; i <= approverInputs.length; i++) {
        form.setFieldValue(`approver${i}`, null);
        form.setFieldValue(`limit${i}`, 0);
      }
      if (
        convertLowerCaseWithoutSpace(options.label) ===
          convertLowerCaseWithoutSpace(WORKFLOW_FORM.ROLE_USER_STEP_TYPE) ||
        convertLowerCaseWithoutSpace(options.label) ===
          convertLowerCaseWithoutSpace(
            WORKFLOW_FORM.CONDITIONAL_THRESHOLD_STEP_TYPE
          )
      ) {
        setApproverInputs([
          {
            approverLabel: `${ordinal(1)} approver`,
            approverFieldName: `approver${1}`,
            limitFieldName: `limit${1}`,
            customFieldValueName: `custom_field_value_id${1}`,
          },
        ]);
      }
      const updatedArray = internalDisabledOption.map(
        (item) => item.split("~")[0]
      );
      setDisableApprovers(
        disabledApprovers.filter((v) => !updatedArray.includes(v))
      );
      setInternalDisabledOption([]);
    };

    const hideAutoApproverForPoPr = approvers?.map((item) => ({
      ...item,
      options: [
        SMART_APPROVALS_TABS_KEYS.PAYMENT_RUNS,
        SMART_APPROVALS_TABS_KEYS.CREDIT_NOTES,
      ].includes(activeKey)
        ? item.options.filter(
            (option) =>
              convertLowerCaseWithoutSpace(option.label) !==
              WORKFLOW_FORM.AUTO_APPROVER
          )
        : item.options,
    }));

    const getUsersOptions = isConditionalThresholdStepTypeSelected
      ? hideAutoApproverForPoPr.filter(
          (item) => item.label === WORKFLOW_APPROVER_USERS_LABEL
        )
      : hideAutoApproverForPoPr;

    return (
      <Card
        className={constructClassName([
          "step-card",
          isConditionalThresholdStepTypeSelected
            ? "step-card-wider"
            : "step-card-smaller",
        ])}
      >
        <Form form={form} initialValues={{ limit1: 0 }} preserve={false}>
          <Typography
            variant="title"
            fontStyle="semibold"
            text={`Step ${stepIndex ? stepIndex + 1 : 1}`}
            className="form-title m-b-24px"
            fontSize={"24"}
            responsiveFontSize={false}
          />
          <div className="inputs-container m-b-18px">
            <Label text={WORKFLOW_FORM.LABELS.STEP_TYPE} optional={false} />
            <CommonSelect
              wrapperClass="step-type-select"
              placeholder={WORKFLOW_FORM.PLACEHOLDERS.STEP_TYPE}
              options={stepTypes}
              name="stepType"
              newSelect
              onChange={(e, options) => handleStepTypeSelectChange(options)}
              rules={[getRequiredRule("step type", true)]}
            />
          </div>
          <When condition={isConditionalThresholdStepTypeSelected}>
            <div className="inputs-container m-b-18px">
              <Label
                text={WORKFLOW_FORM.PLACEHOLDERS.CUSTOM_FIELD}
                optional={false}
              />
              <div className="inputs-child-container">
                <CommonSelect
                  name={"custom_field_id"}
                  wrapperClass={
                    !isUserRoleStepType &&
                    !isConditionalThresholdStepTypeSelected
                      ? "approver-select"
                      : "approver-select-full-width"
                  }
                  options={customFields}
                  placeholder={
                    WORKFLOW_FORM.PLACEHOLDERS.CUSTOM_FIELD_PLACEHOLDER
                  }
                  rules={[
                    getRequiredRule(
                      "custom field",
                      true,
                      "Please select a custom expense field"
                    ),
                  ]}
                  onChange={(item) => {
                    approverInputs.forEach((i) => {
                      form.setFieldValue(i.customFieldValueName, null);
                    });
                    setCustomFieldsId(item);
                  }}
                />
              </div>
            </div>
          </When>

          {Children.toArray(
            approverInputs.map((input, idx) => (
              <div className="inputs-container m-b-18px">
                <Label
                  text={
                    !isUserRoleStepType &&
                    !isConditionalThresholdStepTypeSelected
                      ? input.approverLabel
                      : WORKFLOW_FORM.APPROVER_LABEL
                  }
                  optional={false}
                />
                <div className="inputs-child-container">
                  <CommonSelect
                    name={input.approverFieldName}
                    wrapperClass={
                      !isUserRoleStepType
                        ? "approver-select"
                        : "approver-select-full-width"
                    }
                    options={getUsersOptions}
                    isGroupingSelect
                    placeholder={
                      isEditView
                        ? WORKFLOW_FORM.PLACEHOLDERS.APPROVER_EDIT
                        : WORKFLOW_FORM.PLACEHOLDERS.APPROVER
                    }
                    rules={[getRequiredRule("an approver", true)]}
                    onSelect={(v) =>
                      handleApproverSelectChange(v, input.approverFieldName)
                    }
                    onClear={() => handleClear(input.approverFieldName)}
                    tooltipForDisabled={
                      SMART_APPROVAL_PO_CREATOR.APPROVER_TOOLTIP
                    }
                  />
                  <When condition={isConditionalThresholdStepTypeSelected}>
                    <div className="amount-label-before-custom-value">
                      <Typography
                        variant="secondary"
                        fontStyle="semibold"
                        text={
                          WORKFLOW_FORM.PLACEHOLDERS
                            .TEXT_BEFORE_CUSTOM_FIELD_VALUE
                        }
                      />
                    </div>
                    <div className="">
                      <CommonSelect
                        name={input.customFieldValueName}
                        wrapperClass={"approver-select"}
                        options={customFieldValues}
                        placeholder={
                          WORKFLOW_FORM.PLACEHOLDERS.CUSTOM_FIELD_VALUE
                        }
                        rules={[
                          getRequiredRule(
                            "custom field",
                            true,
                            "Please select a value "
                          ),
                        ]}
                      />
                    </div>
                    <div className="amount-label-after-custom-value">
                      <Typography
                        variant="secondary"
                        fontStyle="semibold"
                        text={
                          WORKFLOW_FORM.PLACEHOLDERS
                            .TEXT_AFTER_CUSTOM_FIELD_VALUE
                        }
                      />
                    </div>
                  </When>
                  <When
                    condition={
                      !isUserRoleStepType &&
                      !isConditionalThresholdStepTypeSelected
                    }
                  >
                    <div className="amount-label">
                      <Typography
                        variant="secondary"
                        fontStyle="semibold"
                        text={
                          approverInputs.length === idx + 1 ? "over" : "up to"
                        }
                      />
                    </div>
                  </When>
                  <When condition={!isUserRoleStepType}>
                    <CommonInputNumber
                      name={input.limitFieldName}
                      wrapperClass={`approver-amount-input w-158px`}
                      rules={
                        !isConditionalThresholdStepTypeSelected
                          ? approverInputs.length === idx + 1
                            ? []
                            : [handleLimitValidation(idx)]
                          : [
                              getRequiredRule(
                                "Amount",
                                false,
                                "Please enter an amount"
                              ),
                            ]
                      }
                      formatter={amountFormatter}
                      controls={false}
                      precision={2}
                      min={0}
                      disabled={
                        !isConditionalThresholdStepTypeSelected
                          ? approverInputs.length === idx + 1
                          : false
                      }
                      placeholder={WORKFLOW_FORM.PLACEHOLDERS.AMOUNT}
                    />
                  </When>
                  <When
                    condition={
                      approverInputs.length > 1 &&
                      approverInputs.length === idx + 1
                    }
                  >
                    <div
                      className="close-icon"
                      onClick={() => removeField(idx)}
                    >
                      <span className="material-icons">close</span>
                    </div>
                  </When>
                </div>
              </div>
            ))
          )}
          <When
            condition={
              !isUserRoleStepType && !isConditionalThresholdStepTypeSelected
            }
          >
            <CustomButton
              text={WORKFLOW_FORM.LABELS.ADD_APPROVER_BTN}
              icon={<Icon className="material-icons" iconName={"add_circle"} />}
              accent={ACCENTS.SECONDARY}
              variant={BUTTON_VARIANT.BUTTON_FILLED_WHITE_TEXT}
              onClick={handleAddApprover}
              disabled={approverInputs?.length >= 3}
              className="w-100"
              customSize={"large"}
            />
          </When>
          <When condition={isConditionalThresholdStepTypeSelected}>
            <Typography
              variant="secondary"
              fontStyle="semibold"
              text={WORKFLOW_FORM.PLACEHOLDERS.AUTOMATIC_APPROVE}
            />
          </When>
        </Form>
        <div className="add-step-container">
          <CustomButton
            className="add-step-btn"
            onClick={addNewStep}
            icon={
              <Icon
                className="material-icons add-step-btn-icon"
                iconName={"add_circle"}
              />
            }
            text={"Add step"}
            accent={ACCENTS.SECONDARY}
            variant={BUTTON_VARIANT.LINK}
            // <Typography
            //   variant="secondary"
            //   className={"mt-2"}
            //   text="Add step"
            // />
          />
        </div>
      </Card>
    );
  }
);

export default StepFrom;
