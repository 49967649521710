import React, { useState, useEffect, useRef } from "react";
import { Form } from "antd";
import { Typography, CustomButton, CommonInput, Box } from "..";
import {
  ACCENTS,
  BUTTON_VARIANT,
  NOTIFICATION_TYPE,
  ERROR_MSG,
} from "../../../constants";
import { POST_COMMENT, constructClassName } from "../../../utils";
import { showNotification } from "../../../utils";
import "./post-comment.scss";

const PostComment = ({
  addComment,
  eventType,
  eventId,
  setClickOnPost,
  clickOnPost,
}) => {
  const [text, setText] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [error, setError] = useState(false);
  const [form] = Form.useForm();
  const maxCharacters = 500;
  const characterCount = text.length;
  const exceeded = characterCount === maxCharacters;

  const handleChange = (e) => {
    const inputText = e.target.value;
    setText(inputText);
    form
      .validateFields(["comment"])
      .then(() => {
        setButtonDisable(false);
        setError(false);
      })
      .catch(() => {
        setButtonDisable(true);
        setError(true);
      });
  };

  const onSuccess = (successMessage) => {
    showNotification(NOTIFICATION_TYPE.success, successMessage);
    setText("");
    form.resetFields();
  };

  const onError = (e) => {
    showNotification(
      NOTIFICATION_TYPE.error,
      e?.response?.data?.message || ERROR_MSG.common
    );
  };

  const reloadTable = React.useCallback(() => {
    setClickOnPost(!clickOnPost);
  }, [clickOnPost, setClickOnPost]);

  const postComment = async () => {
    const requestPayload = {
      comment: text,
      event_type: eventType,
      event_id: eventId,
    };
    try {
      await addComment(requestPayload);
      reloadTable();
      onSuccess(POST_COMMENT.COMMENT_ADDED);
    } catch (e) {
      onError(e);
    }
  };

  return (
    <Form form={form}>
      <Box
        justifyContent="spaceBetween"
        gutter={30}
        className="post-comment-container mt-4"
        alignItem="flexStart"
      >
        <div className="textarea-container w-85">
          <CommonInput
            onChange={handleChange}
            name="comment"
            placeholder={POST_COMMENT.ADD_COMMENT_PLACEHOLDER}
            isTextArea={true}
            value={text}
            className={constructClassName(["mb-0", "comment-input"])}
            autoSize={{ minRows: 1, maxRows: 3 }}
            id="comment"
            maxLength={maxCharacters}
          />
          <Typography
            className={constructClassName([
              "counter",
              exceeded ? "text-destructive-disabled" : "",
              error ? "with-error-style" : "without-error-style",
            ])}
            text={`${characterCount} / ${maxCharacters}`}
            variant={"secondary"}
            fontSize={14}
            id="counter"
          />
        </div>
        <CustomButton
          text={POST_COMMENT.POST_COMMENT_BTN}
          accent={ACCENTS.PRIMARY}
          variant={BUTTON_VARIANT.BUTTON_FILLED}
          className="post-comment-button"
          customSize="large"
          showLoader={true}
          disabled={buttonDisable || !text}
          onClick={() => postComment()}
        />
      </Box>
    </Form>
  );
};

export default PostComment;
