import { useDispatch, useSelector } from "react-redux";
import { updateGlobalFilters } from "../redux/action/filters";
import { RESET_FILTERS } from "../redux/service/types";
import {
  deleteKeyFromAllTabs,
  addLatestAmountFilter,
  mergeAllTabsSelectedKeys,
} from "../utils";
import { INVOICE_MANAGEMENT_TAB_KEYS } from "../constants";
import { isFilterEnabledForDraft } from "../helper";

const useFilterWithRedux = ({ activeTab = "", reduxActionType }) => {
  const globalFilterData = useSelector(
    (state) => state.filters[reduxActionType]
  );
  const dispatch = useDispatch();

  // Function to update selected filter keys
  const updateSelectedFilterKeys = (selectedFilterKeys) => {
    const data = {
      ...globalFilterData,
      [activeTab]: {
        ...globalFilterData[activeTab],
        selectedFilterKeys,
      },
    };
    const allSelectedKeysCollection = mergeAllTabsSelectedKeys(data);
    const dataWithLatestFilter = addLatestAmountFilter(
      allSelectedKeysCollection,
      selectedFilterKeys
    );
    const resetFilter = Object.fromEntries(
      Object.keys(globalFilterData).map((key) => [
        key,
        {
          ...globalFilterData[key],
          selectedFilterKeys: dataWithLatestFilter,
        },
      ])
    );
    dispatch({
      type: RESET_FILTERS,
      payload: resetFilter,
    });
  };

  const modifyKeyForATab = (selectedFilterKeys) => {
    dispatch(
      updateGlobalFilters({
        type: reduxActionType,
        activeTab,
        key: "selectedFilterKeys",
        value: selectedFilterKeys,
      })
    );
  };

  // Function to update filter data
  const updateFilterData = (filterData) => {
    dispatch(
      updateGlobalFilters({
        type: reduxActionType,
        activeTab,
        key: "filterData",
        value: filterData,
      })
    );
  };

  const resetAllSelectedFilterKeys = () => {
    const resetFilter = Object.fromEntries(
      Object.keys(globalFilterData).map((key) => [
        key,
        {
          selectedFilterKeys: [],
          filterData: globalFilterData[key]?.filterData || [],
        },
      ])
    );

    dispatch({
      type: RESET_FILTERS,
      payload: resetFilter,
    });
  };

  const removeKeyFromAllTab = (key) => {
    const updatedFilterObj = deleteKeyFromAllTabs(globalFilterData, key);
    dispatch({
      type: RESET_FILTERS,
      payload: updatedFilterObj,
    });
  };

  const selectedFilterData = () => {
    if (
      activeTab !== INVOICE_MANAGEMENT_TAB_KEYS.DRAFT ||
      isFilterEnabledForDraft()
    ) {
      return globalFilterData?.[activeTab]?.selectedFilterKeys || [];
    } else {
      return [];
    }
  };

  return {
    selectedFilterKeys: selectedFilterData(),
    filterData: globalFilterData?.[activeTab]?.filterData || [],
    updateSelectedFilterKeys,
    modifyKeyForATab,
    updateFilterData,
    resetAllSelectedFilterKeys,
    removeKeyFromAllTab,
  };
};

export default useFilterWithRedux;
