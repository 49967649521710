import { memo } from "react";
import PropTypes from "prop-types";
import { constructClassName } from "../../../utils";
import { EnvFeatureFlag, Icon, Tooltip, When } from "..";
import "./Label.scss";
import { featureFlags } from "../../../constants";

function Label({
  text,
  className,
  optional = false,
  suffixIcon,
  suffixIconClassName,
  suffixIconTooltip,
  suffixTooltipList = [],
  asteriskContainerClassName,
  ...rest
}) {
  const initialTooltipObj = {
    suffixIcon,
    suffixIconClassName,
    suffixIconTooltip,
  };

  const tooltipList = suffixIconTooltip
    ? [initialTooltipObj]
    : suffixTooltipList;

  const ToolTipList = ({ items }) => {
    return items?.map((el) => {
      return (
        <When condition={el?.suffixIcon}>
          <When condition={el?.suffixIconTooltip}>
            <Tooltip
              title={el?.suffixIconTooltip}
              overlayClassName={constructClassName([
                "tooltip-inside-label",
                rest.overlayClassName,
              ])}
              placement="right"
              {...rest}
            >
              <Icon
                showIconOutline={true}
                iconName={el?.suffixIcon}
                className={constructClassName([
                  "  ms-2 ",
                  el?.suffixIconClassName,
                ])}
              />
            </Tooltip>
          </When>
          <When condition={!el?.suffixIconTooltip}>
            <Icon
              showIconOutline={true}
              iconName={el?.suffixIcon}
              className={constructClassName([
                "  ms-2 ",
                el?.suffixIconClassName,
              ])}
            />
          </When>
        </When>
      );
    });
  };

  return (
    <label
      className={constructClassName(["custom-label", className])}
      {...rest}
    >
      {`${text} `}
      <span
        className={constructClassName([
          asteriskContainerClassName,
          "required-asterisk-container d-inline-flex",
        ])}
      >
        {optional ? "" : <span className="required-asterisk ms-1 ">*</span>}
        <ToolTipList items={tooltipList} />
      </span>
    </label>
  );
}

Label.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  optional: PropTypes.bool,
};

export default memo(Label);
