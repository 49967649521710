import { memo, useCallback, useEffect, useState } from "react";
import { getInstitution, searchInstitution } from "../../../../api";
import { YapilyIntegrationConfig } from "../../../../constants/Integration/OpenBanking";
import { debounce } from "../../../../utils/constant";
import {
  Box,
  Card,
  Column,
  ComponentLoader,
  Empty,
  Image,
  Row,
  SearchInput,
  Typography,
  When,
} from "../../../common";

const { searchBoxPlaceHolder } =
  YapilyIntegrationConfig.consentJourneyStepsConfig[0];

function YapilyInstitutionsList({ nextStep }) {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function fetchInstitution() {
      getInstitution()
        .then((res) => {
          updateTableData(res);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    fetchInstitution();
  }, []);

  const inputChangeHandler = useCallback(debounce(SearchInstitution, 1000), []);

  function updateTableData(res, append = true) {
    const { data } = res.data.result;
    if (append) setRecords((prevRecords) => [...prevRecords, ...data]);
    else setRecords(() => [...data]);
    setLoading(false);
  }

  function institutionClickHandler(bankDetail) {
    nextStep(bankDetail);
  }

  function SearchInstitution(event) {
    const searchValue = (event.target.value || "").trim();
    if (searchValue.length >= 1) fetchSearchInstitution(searchValue);
  }

  function fetchSearchInstitution(search = "") {
    setLoading(true);
    searchInstitution(search).then((res) => {
      updateTableData(res, false);
    });
  }

  function handleClear() {
    setLoading(true);
    getInstitution().then((res) => {
      updateTableData(res, false);
    });
  }

  return (
    <>
      <SearchInput
        prefixCls={"yapily-bank-search"}
        placeholder={searchBoxPlaceHolder}
        onChange={inputChangeHandler}
        handleClear={handleClear}
        customSize={"large"}
      />
      <div
        className={"m-t-26px"}
        style={{ height: "50vh", overflowX: "hidden", overflowY: "auto" }}
      >
        <When condition={loading}>
          <ComponentLoader />
        </When>
        <When condition={!loading && records?.length === 0}>
          <Empty />
        </When>
        <When condition={!loading && records?.length !== 0}>
          <Row gutter={[16, 16]}>
            {records.map((institutionDetail) => {
              return (
                <YapilyInstitution
                  key={`yapily-bank-${institutionDetail?.institution_id}`}
                  institutionDetail={institutionDetail}
                  institutionClickHandler={institutionClickHandler}
                />
              );
            })}
          </Row>
        </When>
      </div>
    </>
  );
}

function YapilyInstitution({ institutionDetail, institutionClickHandler }) {
  const { media, name, key } = institutionDetail;
  return (
    <Column
      onClick={() => {
        institutionClickHandler(institutionDetail);
      }}
      key={key}
    >
      <Card className="institution-account-card">
        <Box direction="column">
          <Image rootClassName="yapily-institution-account-icon" src={media} />
          <Typography
            text={name}
            variant={"body"}
            className="yapily-institution-account-text"
            ellipsis={{
              tooltip: {
                title: name,
                placement: "bottom",
              },
            }}
          />
        </Box>
      </Card>
    </Column>
  );
}

function YapilyInstitutions({ next }) {
  return <YapilyInstitutionsList nextStep={next} />;
}

export default memo(YapilyInstitutions);
