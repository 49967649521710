import React, { useMemo } from "react";
import { Form, Select } from "antd";
import When from "../When";
import Label from "../Label";
import Icon from "../Icon";
import "./Selector.scss";
import Checkbox from "../Checkbox";
import { constructClassName } from "../../../utils";
import Typography from "../Typography";
import { Column, Image, Row, Tooltip } from "..";

const { Option, OptGroup } = Select;

const CommonSelect = (props) => {
  const {
    defaultValue,
    name,
    onBlur,
    value,
    onSelect,
    label,
    mode,
    placeholder,
    options,
    onChange,
    rules,
    className,
    validateTrigger,
    disabled = false,
    onSearch,
    wrapperClass,
    getPopupContainer,
    initialValue,
    disabledOption,
    newSelect = false,
    onClear,
    onDeselect,
    notFoundContent,
    showSearch = true,
    searchValue,
    allowClear = true,
    optionalField,
    isMultiSelect = false,
    tagRender,
    dropdownRender,
    labelCol,
    suffixIcon = false,
    suffixIconClassName,
    suffixIconTooltip,
    suffixTooltipList,
    maxTagCount,
    tooltipForDisabled,
    optionClassName,
    disabledOptionClassName = "option-bg-disabled",
    isGroupingSelect = false,
    help,
    validateStatus,
    prefixIcon = false,
    getPopupContainerForTooltip,
    wrapperClassFormItem,
    labelClassname,
  } = props;

  const isMandatoryField =
    Array.isArray(rules) && rules?.some((el) => el?.required === true);

  const MultiselectOptionComponent = ({ label, value, optionValue }) => {
    const memoizedComponent = useMemo(() => {
      return (
        <Row
          className={constructClassName([
            "w-100",
            "kid-item-cnt-child",
            "align-items-center",
            "flex",
          ])}
          key={`${label}${value}`}
        >
          <Column>
            <Checkbox
              className="kid-checkbox"
              checked={value?.includes(optionValue)}
            />
          </Column>
          <Column>
            <Typography ellipsis={{ tooltip: label }}>{label}</Typography>
          </Column>
        </Row>
      );
    }, []);
    return memoizedComponent;
  };

  const CommonOptionForGroupSelect = (props) => {
    const { label, value, disabled, showTooltip, key, tooltipForDisabled } =
      props;
    return (
      <Option
        key={key}
        value={value}
        disabled={disabled}
        className={showTooltip ? "option-bg-disabled" : ""}
      >
        <When condition={showTooltip}>
          <Tooltip title={tooltipForDisabled}>
            <Typography ellipsis={{ tooltip: label }}>{label}</Typography>
          </Tooltip>
        </When>
        <When condition={!showTooltip}>
          <Typography
            text={label}
            variant={"body"}
            style={{ width: 200, display: "contents" }}
            ellipsis={{ tooltip: `${label}` }}
          />
        </When>
      </Option>
    );
  };

  return (
    <div className={wrapperClass}>
      <Form.Item
        label={
          label ? (
            <Label
              text={label}
              optional={!isMandatoryField}
              suffixIcon={suffixIcon}
              suffixIconClassName={suffixIconClassName}
              suffixIconTooltip={suffixIconTooltip}
              suffixTooltipList={suffixTooltipList}
              className={labelClassname}
            />
          ) : null
        }
        name={name}
        rules={rules}
        validateTrigger={validateTrigger}
        initialValue={initialValue}
        colon={false}
        labelCol={labelCol}
        help={help}
        validateStatus={validateStatus}
        className={wrapperClassFormItem}
      >
        {newSelect ? (
          <Select
            getPopupContainer={(trigger) =>
              getPopupContainer ? getPopupContainer() : trigger.parentNode
            }
            className={`${className || ""} common-select`}
            clearIcon={
              <Icon iconName={"close"} className={"kloo-select-close-icon"} />
            }
            suffixIcon={<Icon iconName={"arrow_drop_down"} />}
            showArrow
            showSearch={showSearch}
            size="large"
            mode={mode}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onDeselect={onDeselect}
            onClear={onClear}
            searchValue={searchValue}
            popupClassName={"kloo-select-popup"}
            allowClear={allowClear}
            disabled={disabled}
            onSelect={onSelect}
            onSearch={onSearch}
            onBlur={onBlur}
            defaultValue={defaultValue}
            notFoundContent={notFoundContent}
            dropdownRender={dropdownRender}
            filterOption={(input, option) => {
              if (option.children) {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              } else if (option.label) {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }
            }}
            options={options}
            maxTagCount={maxTagCount}
          />
        ) : isMultiSelect ? (
          <Select
            className={constructClassName([
              "common-select kloo-multiple-select",
              className,
            ])}
            getPopupContainer={(trigger) =>
              getPopupContainer ? getPopupContainer() : trigger.parentNode
            }
            onDeselect={onDeselect}
            showArrow
            clearIcon={
              <Icon iconName={"close"} className={"kloo-select-close-icon"} />
            }
            suffixIcon={<Icon iconName={"arrow_drop_down"} />}
            mode={"multiple"}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            allowClear={allowClear}
            disabled={disabled}
            onSelect={onSelect}
            onSearch={onSearch}
            searchValue={searchValue}
            popupClassName={"kloo-select-popup"}
            onBlur={onBlur}
            defaultValue={defaultValue}
            notFoundContent={notFoundContent}
            menuItemSelectedIcon={null}
            labelInValue={false}
            dropdownRender={dropdownRender}
            filterOption={(input, option) => {
              return (
                option?.displayValue
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }}
            tagRender={tagRender}
            maxTagCount={maxTagCount}
          >
            {options &&
              options.length &&
              options.map(({ label, value: optionValue, disabled }, index) => (
                <Option
                  key={index}
                  value={optionValue}
                  disabled={disabled}
                  displayValue={label}
                  className={constructClassName([
                    optionClassName,
                    disabled ? disabledOptionClassName : "",
                  ])}
                >
                  <When condition={tooltipForDisabled && disabled}>
                    <Tooltip
                      title={tooltipForDisabled}
                      getPopupContainer={getPopupContainerForTooltip}
                    >
                      <>
                        <MultiselectOptionComponent
                          {...{ label, value, optionValue }}
                        />
                      </>
                    </Tooltip>
                  </When>
                  <When condition={!(tooltipForDisabled && disabled)}>
                    <MultiselectOptionComponent
                      {...{ label, value, optionValue }}
                    />
                  </When>
                </Option>
              ))}
          </Select>
        ) : isGroupingSelect ? (
          <Select
            getPopupContainer={(trigger) =>
              getPopupContainer ? getPopupContainer() : trigger.parentNode
            }
            className={`${className || ""} common-select`}
            clearIcon={
              <Icon iconName={"close"} className={"kloo-select-close-icon"} />
            }
            suffixIcon={<Icon iconName={"arrow_drop_down"} />}
            showArrow
            showSearch={showSearch}
            size="large"
            mode={mode}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onDeselect={onDeselect}
            onClear={onClear}
            searchValue={searchValue}
            popupClassName={"kloo-select-popup"}
            allowClear={allowClear}
            disabled={disabled}
            onSelect={onSelect}
            onSearch={onSearch}
            onBlur={onBlur}
            defaultValue={defaultValue}
            notFoundContent={notFoundContent}
            dropdownRender={dropdownRender}
            filterOption={(input, option) => {
              if (option.key) {
                const keyWithoutSuffix = option.key.replace(/_\d+$/, "");
                return (
                  keyWithoutSuffix.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              } else if (option.label) {
                return option.label
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
                  ? true
                  : false;
              }
            }}
            maxTagCount={maxTagCount}
          >
            {options &&
              options?.map((group, optionIndex) => (
                <OptGroup
                  key={`group_label_${optionIndex}`}
                  label={group.label}
                >
                  {group.options.map(
                    ({ label, value, disabled, showTooltip }, entryIndex) =>
                      CommonOptionForGroupSelect({
                        label,
                        value,
                        disabled,
                        showTooltip,
                        key: `${label}_${entryIndex}`,
                        tooltipForDisabled,
                      })
                  )}
                </OptGroup>
              ))}
          </Select>
        ) : (
          <Select
            getPopupContainer={(trigger) =>
              getPopupContainer ? getPopupContainer() : trigger.parentNode
            }
            className={`${className || ""} common-select`}
            showArrow
            clearIcon={
              <Icon iconName={"close"} className={"kloo-select-close-icon"} />
            }
            suffixIcon={<Icon iconName={"arrow_drop_down"} />}
            showSearch={showSearch}
            size="large"
            mode={mode}
            name={name}
            value={
              (options?.length &&
                value &&
                options?.find((z) => z?.value === value)?.value) ||
              null
            }
            placeholder={placeholder}
            onChange={onChange}
            allowClear={allowClear}
            disabled={disabled}
            onSelect={onSelect}
            onSearch={onSearch}
            searchValue={searchValue}
            onBlur={onBlur}
            defaultValue={defaultValue}
            notFoundContent={notFoundContent}
            onClear={onClear}
            dropdownRender={dropdownRender}
            filterOption={(input, option) => {
              if (option.children) {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              } else if (option.label) {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }
            }}
          >
            {options &&
              options.length &&
              options.map((option, index) => (
                <Option
                  key={index}
                  value={option.value}
                  disabled={
                    disabledOption &&
                    Object.values(disabledOption).includes(option.value)
                  }
                >
                  {option.label}
                </Option>
              ))}
          </Select>
        )}
      </Form.Item>
    </div>
  );
};

export default CommonSelect;
