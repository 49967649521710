import { TAG_VARIANT } from "../constants";
import {
  LC_SUPPLIER_PLACEHOLDER,
  SUPPLIERS_PLACEHOLDER,
  SUPPLIER_PLACEHOLDER,
} from "../constants/dynamic";
import {
  capitalizeFirst,
  isDevelopmentEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
} from "./functions";

// debounce ----//
export function debounce(func, timeout) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
// phone input ----//
export const phoneRegex =
  /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;

// approval -------//
export const APPROVAL_BUTTON_DETAILS = {
  CANCEL: {
    message: "Are you sure that you want to cancel this card request?",
    type: "CANCEL",
  },
  APPROVE: {
    message: "Would you like to approve this card request?",
    type: "APPROVE",
  },
  DELETE: {
    message: "Would you like to delete this card request?",
    type: "DELETE",
  },
};
export const my_approval_columns = [
  "Status",
  "Requestor",
  "Card nickname",
  "Request Type",
  "Type",
  "Amount",
  "Created date",
];
export const all_approval_columns = [
  "Status",
  "Needs approver",
  "Requestor",
  "Card nickname",
  "Request Type",
  "Type",
  "Amount",
  "Created date",
];

export const approval_initStatus = {
  "Request Cancelled": "Request_cancelled",
  Frozen: "Frozen",
  Active: "active",
  active: "active",
  "Awaiting approval": "Awaiting_approval",
  "awaiting approval": "Awaiting_approval",
  Created: "Created",
  Cancelled: "Cancelled",
  Used: "Used",
};

//  ALL CARDS -----------//
export const allCardsColumns = [
  "Status",
  "Type",
  "Cardholder",
  "Card nickname",
  "Monthly spend",
  "Monthly balance",
];
export const allCardsCancelMessage =
  "Are you sure you want to cancel this card?";

export const allCardsInitialEditCard = {
  show: false,
  details: {},
  isEditNickname: false,
};
export const allCardsInitStatus = {
  "Request Cancelled": TAG_VARIANT.REJECTED,
  Frozen: TAG_VARIANT.REJECTED,
  Active: TAG_VARIANT.ACTIVE,
  active: TAG_VARIANT.ACTIVE,
  "Awaiting approval": TAG_VARIANT.AWAITING_APPROVAL,
  "awaiting approval": TAG_VARIANT.AWAITING_APPROVAL,
  Created: TAG_VARIANT.CREATED,
  Cancelled: TAG_VARIANT.REJECTED,
  Used: TAG_VARIANT.USED,
  expired: TAG_VARIANT.REJECTED,
  Expired: TAG_VARIANT.REJECTED,
  Failed: TAG_VARIANT.REJECTED,
  failed: TAG_VARIANT.REJECTED,
};
export const teamListStatus = {
  Active: "active",
  active: "active",
  inactive: "inactive",
  deleted: "deleted",
};
// MY CARDS ---------//
export const myCardsColumns = [
  "Status",
  "Type",
  "Cardholder",
  "Card nickname",
  "Monthly spend",
  "Monthly balance",
];
export const myCardsInitStatus = {
  "Request Cancelled": TAG_VARIANT.REJECTED,
  Frozen: TAG_VARIANT.REJECTED,
  Active: TAG_VARIANT.ACTIVE,
  "Awaiting approval": TAG_VARIANT.AWAITING_APPROVAL,
  Created: TAG_VARIANT.CREATED,
  Cancelled: TAG_VARIANT.REJECTED,
  Used: TAG_VARIANT.USED,
  active: TAG_VARIANT.ACTIVE,
  "awaiting approval": TAG_VARIANT.AWAITING_APPROVAL,
  expired: TAG_VARIANT.REJECTED,
  Expired: TAG_VARIANT.REJECTED,
  Failed: TAG_VARIANT.REJECTED,
  failed: TAG_VARIANT.REJECTED,
};
export const myCardsCancelMessage =
  "Are you sure you want to cancel this card?";
export const myCardsInitialEditCard = {
  show: false,
  details: {},
  isEditNickname: false,
};

// cards tabs ----//
export let cardsTabsHeaders = {
  id: "Id".replace(/,/g, ""), // remove commas to avoid errors
  user_org_id: "User organization id".replace(/,/g, ""),
  organization_id: "Organization id".replace(/,/g, ""),
  card_type: "Card type".replace(/,/g, ""),
  card_id: "Card id".replace(/,/g, ""),
  nickname: "Nick name".replace(/,/g, ""),
  amount: "Amount",
  description: "Description",
  expirydate: "Expiry date",
  external_ref: "External ref".replace(/,/g, ""),
  request_status: "Request status".replace(/,/g, ""),
  deleted_at: "Deleted at".replace(/,/g, ""),
  created_at: "Created at".replace(/,/g, ""),
  updated_at: "Updated at".replace(/,/g, ""),
  // address: "Address",
  spent: "Spent",
  // maskedPan: "Masked pan".replace(/,/g, ''),
};

// add new card --------//
export const AddNewCardType = [
  { title: "Virtual card", isDisabled: false },
  { title: "Physical card", isDisabled: false },
];
// table Drawer ---------//
export const TableDrawerInitStatus = {
  "Request Cancelled": "Request_cancelled",
  Frozen: "Frozen",
  active: "active",
  "awaiting approval": "Awaiting_approval",
  Created: "Created",
  Cancelled: "Cancelled",
  Used: "Used",
};

// virtual Card   --------------//
export const virtualCard = [
  { label: "Single use", value: "virtual-single" },
  { label: "Multi use", value: "virtual-multi" },
];

export const PROMPTTIMEOUT = 30 * 1000;
const INACTIVE_TIME = 1000;
export const INACTIVETIMEOUT = INACTIVE_TIME * 60 * 5 - PROMPTTIMEOUT;
export const INACTIVETIMEOUTDEBOUNCETIME = 500;

//to call refreshAPI timer
export const REFRESHTIMEOUT = 1000 * 60 * 4 - PROMPTTIMEOUT;

export const CHECKLOCALSTORAGE = 500;

export const USER_ROLES_KEYS = {
  ORGANISATION_ADMIN: "OA",
  FINANCE_APPROVER: "FA",
  PLATFORM_USER: "PU",
};

export const USER_ROLES = {
  [USER_ROLES_KEYS.ORGANISATION_ADMIN]: {
    dbMappingKey: "organisation-admin",
  },
  [USER_ROLES_KEYS.FINANCE_APPROVER]: {
    dbMappingKey: "finance-approver",
  },
  [USER_ROLES_KEYS.PLATFORM_USER]: {
    dbMappingKey: "platform-user",
  },
};

export const AUDIT_CONSTANTS = {
  MODAL: {
    TITLE: "Card actions history",
    SUB_TITLE:
      "A list displaying the history of actions which have taken place on this card.",
    TABLE_COLUMN: [
      { label: "Date", key: "date", width: 20 },
      { label: "Actioning user", key: "user", ellipsis: true, width: 20 },
      { label: "Email", key: "email", ellipsis: true, width: 20 },
      { label: "Before", key: "before", width: 20 },
      { label: "After", key: "after", width: 20 },
    ],
  },
  VIEW_BTN_LABEL: "View card history",
};

export const HISTORY_CONSTANTS = {
  MODAL: {
    TITLE: "Card actions history",
    SUB_TITLE:
      "A list displaying the history of actions which have taken place on this card.",
    TABLE_COLUMN: [
      { label: "Date", key: "date", width: 20 },
      { label: "Actioning user", key: "user", width: 20 },
      { label: "Email", key: "email", ellipsis: true, width: 20 },
      { label: "Before", key: "before", width: 20 },
      { label: "After", key: "after", width: 20 },
    ],
  },
  VIEW_BTN_LABEL: "View card history",
};

export const DEFAULT_THEME = {
  PRIMARY_COLOR: "#000e1c",
};

export const ACCOUNT_PAYABLE = {
  schedule: "schedulePayment",
  reschedule: "ReSchedulePayment",
};
export const TRANSACTION_STATUS_FOR_ARCHIEVE = [
  "declined",
  "refunded",
  "reversed",
  "reversal",
];

export const OUTSTANDING_SUBSCRIPTION = {
  Automated: "Automated",
  mobilefriendly: "mobile_friendly",
  Manual: "Manual",
  touchApp: "touch_app",
  Automsg: "This subscription has been identified by Kloo",
  manualMsg: "This subscription has been manually identified",
  PlaceMent: "bottomLeft",
  Hover: "hover",
};

export const USER_TYPES = {
  QA: "organisation-admin",
  FA: "finance-approver",
  PU: "platform-user",
  AM: "analytics-manager",
};

export const OTP_MODAL_CONSTANT = {
  title: "Verify identity via OTP",
  body: (mobile) =>
    `We have sent a verification code to your mobile number ending with ${mobile}`,
  confirmButtonLabel: "Verify",
  cancelButtonLabel: "Cancel",
  placeholder: "Enter code",
};

export const DELETE_CONFIRMATION_MODAL_CONSTANT = {
  checboxConfirmationLabel: "I understand that this action cannot be undone.",
  confirmButtonLabel: "Confirm",
  cancelButtonLabel: "Cancel",
};

export const DOB_MODAL_CONSTANT = {
  title: "Date of birth required",
  subTitle:
    "To initiate your payment using Open Banking, your date of birth is required. Your details will be stored under your profile section so you won’t need to provide this for future payments.",
  confirmButtonLabel: "Confirm",
  cancelButtonLabel: "Cancel",
  successMessage: "Your date of birth has been added.",
};

export const DELETE_CONFIRMATION_WORKFLOW_MODAL_CONSTANT = {
  checboxConfirmationLabel: "I understand that this action cannot be undone.",
  confirmButtonLabel: "Delete workflow",
  loaderButtonText: "Deleting workflow",
  cancelButtonLabel: "Cancel",
  selectedDeleteText: (workflowName) =>
    `You are about to delete ${workflowName}`,
  confirmationDeleteLabel:
    "Deleting this workflow will be a permanent action which cannot be undone",
};

export const LOCAL_STORAGE_KEY = {
  EMAIL: "email",
  USER_ID: "id",
  ORD_ID: "org_id",
  USER_ORD_ID: "user_org_id",
  TABLE_CONTAINER_HEIGHT: "table-container-height",
  ORG_TYPE: "org_type",
  KLOO_LOGIN: "kloo_login",
  VERIFY_OTP: "verify_otp",
  INACTIVE_OTP: "inactive_otp",

  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  TOKEN_TYPE: "token_type",
  FIRST_NAME: "first_name",
  MIDDLE_NAME: "middle_name",
  LAST_NAME: "last_name",
  BIRTH_DATE: "birth_date",
  USER_CREATED_BY: "user_created_by",
  LOGIN_ATTEMPTS: "login_attempts",
  STATUS: "status",
  EXPIRE_IN: "expires_in",
  ADDRESS: "address",
  SSO: "SSO",
  CURRENCIES: "currencies",
  EMAIL_ACTION_TOKEN: "emailActionToken",
};

export const MODAL_TYPES = {
  OTP_MODAL: "OTP_MODAL",
  DELETE_CONFIRMATION_MODAL: "DELETE_CONFIRMATION_MODAL",
  VIEW_SELECTED_MODAL: "VIEW_SELECTED_MODAL",
  DELETE_WORKFLOW_CONFIRMATION_MODAL: "DELETE_WORKFLOW_CONFIRMATION_MODAL",
  WORKFLOW_MODAL: "WORK_FLOW_MODAL",
  ASSIGN_WORKFLOW_MODAL: "ASSIGN_WORKFLOW_MODAL",
  PAYMENT_JOURNEY_MODAL: "PAYMENT_JOURNEY_MODAL",
  INVOICE_EDIT_TRIGGER_MODAL: "INVOICE_EDIT_TRIGGER_MODAL",
  USER_DELETE_MODAL: "USER_DELETE_MODAL",
  DOB_MODAL: "DOB_MODAL",
  REJECTION_MODAL: "REJECTION_MODAL",
  SUPPLIER_ACCOUNTS_VIEW_MODAL: "SUPPLIER_ACCOUNTS_VIEW_MODAL",
  PR_CONFIGURE_PAYMENT_FILE: "PR_CONFIGURE_PAYMENT_FILE",
  AUDIT_MODAL: "AUDIT_MODAL",
  GRNI_JOURNEY_MODAL: "GRNI_JOURNEY_MODAL",
  SEND_SUPPLIER_MODAL: "SEND_SUPPLIER_MODAL",
  ITEM_LIST_MODAL: "ITEM_LIST_MODAL",
  INVOICE_MATCHING_MODAL: "INVOICE_MATCHING_MODAL",
  CONFIRM_MATCHING_CONFIRMATION_MODAL: "CONFIRM_MATCHING_CONFIRMATION_MODAL",
  UPLOADED_ATTACHMENTS_MODAL: "UPLOADED_ATTACHMENTS_MODAL",
  CONFIGURE_INTEGRATION_MODAL: "CONFIGURE_INTEGRATION_MODAL",
  PO_SELECTED_DRAFT: "PO_SELECTED_DRAFT",
  TEXT_BASED_CONFIRMATION_MODAL: "TEXT_BASED_CONFIRMATION_MODAL",
};

export const OTP_REQUEST_TYPE = {
  CARD_MONTHLY_LIMIT: "card_monthly_limit",
};

export const CARD_LIMIT_REQUEST = {
  REQUEST_RAISED_INFO: (
    amount
  ) => `You have already requested a card limit change to £${amount},
  Please note that submitting this new request will
  override this existing request.`,
};

export const EDIT_CARD_LIMIT = {
  DESCRIPTION_LABEL: "Description",
  DESCRIPTION_PLACE_HOLER: "Purpose of card limit change",
};

export const CARD_EXPENSE_TABS = {
  OUTSTANDING: "outstanding",
  TOBEREVIEW: "toBeReviewed",
  APPROVED: "approved",
};

export const CARD_EXPENSES_STATUSES = {
  DECLINED: "declined",
};

export const APPROVED_SETTLED = ["approved", "settled"];

export const CARD_EXP_LIST_TYPE = {
  MY: "my",
  ALL: "all",
};
export const ENVIRONMENT_URL = {
  DEV: "https://dev.getkloo.com",
  STAGE: "https://stage.getkloo.com",
  PLATFORM: "https://platform.getkloo.com",
};

export const OUTSTANDING_COLUMNS = [
  "Transaction status",
  "Receipt",
  "Transaction date",
  "Cardholder",
  "Card type",
  "Card nickname",
  "Merchant",
  "Billing amount",
  "Subscription",
];
export const CARD_EXPENSE_CONSTANT = {
  MARK_FOR_REVIEW: "Expense marked for review",
  MARK_REVIEW: "Mark for review",
  PENDING_SETTLEMENT: "Pending settlement",
  MARK_APPROVED: "Mark as Approved",
};

export const WORKFLOW_TYPE_INVOICE = {
  THRESHOLD: "Threshold",
  SHARED: "Shared",
  LINEAR: "Linear",
  CONTINUAL: "Continual",
};

export const OPERATORS = {
  LESS_THAN_EQUAL_TO: "<=",
  GREATER_THAN: ">",
};

export const INVOICE_MODAL_NOTES = {
  Continual:
    "The flow will start with the first approver in the approval chain who meet the threshold based on the invoice amount.",
  Threshold:
    "Determine the individual approver for an invoice, based on threshold values.",
  Linear:
    "This goes through everyone in the workflow regardless of invoice amount",
  Shared:
    "This will go to all users within the workflow and will require approval from all users, but in no order.",
};

export const DRAWER_TYPES = {
  PO_CREATE_DRAWER: "PO_CREATE_DRAWER",
  PO_ASSIGN_DRAWER: "PO_ASSIGN_DRAWER",
  PO_APPROVAL_DRAWER: "PO_APPROVAL_DRAWER",
  PO_DOWNLOAD_DRAWER: "PO_DOWNLOAD_DRAWER",
  PO_REVIEW_DRAWER: "PO_REVIEW_DRAWER",
  PO_CLOSE_DRAWER: "PO_CLOSE_DRAWER",
  PO_CLOSED_VIEW_DRAWER: "PO_CLOSED_VIEW_DRAWER",
  PR_DONWLOAD_INVOICE_DRAWER: "PR_DONWLOAD_INVOICE_DRAWER",
  SM_CREATE_SUPPLIER_DRAWER: "SM_CREATE_SUPPLIER_DRAWER",
  SM_ADD_ACCOUNTS_DRAWER: "SM_ADD_ACCOUNTS_DRAWER",
  PR_ASSIGN_DRAWER: "PR_ASSIGN_DRAWER",
  WORKFLOW_ASSIGN_DRAWER: "WORKFLOW_ASSIGN_DRAWER",
  BULK_ACTION_DRAWER: "BULK_ACTION_DRAWER",
  CREATE_INVOICE_DRAWER: "CREATE_INVOICE_DRAWER",
  SKELETON_DRAWER: "SKELETON_DRAWER",
};

export const SCROLL_TOP_CLASSNAME = "border-right-dv";
export const LOGIN_FORM_EROR_MSG =
  "Your email address or password is incorrect";

//Invoice management - smart approval

export const INVOICE_MANAGE_TOP_HEADER_INFO = {
  INVOICE_MANAGE: {
    desc: "Create, review, and manage invoice creation from one simple interface.",
    header: "Invoice management",
    buttonIcon: "add_circle",
    buttonLabel: "Upload invoice",
    buttonLabelMobile: "Create PO",
  },
};

export const INVOICE_SELECTED_TEXT = {
  SELECTED_TEXT: (invoiceselected) =>
    `${invoiceselected} ${
      invoiceselected === 1 ? "invoice" : "invoices"
    } selected`,
  buttonIcon: "add_circle",
};

export const PAGE_SIZE = "10";
export const CURRENT_PAGE = "1";
export const TOTAL_RECORD = "15";
export const PER_PAGE = "10";
export const SMART_APPROVAL_CARD_BUTTONS = {
  MANAGE: "Edit",
  DELETE: "Delete",
  VIEW: "View",
};

export const SMART_APPROVAL_CONFIGURETAB = {
  label: "Cards",
  path: "/smart-approvals/configure",
  key: "configure",
};

export const SMART_APPROVAL_INVOICETAB = {
  label: "Invoices",
  path: "/smart-approvals/invoices",
  key: "invoices",
};
export const SMART_APPROVAL_HISTORYTAB = {
  label: "History",
  path: "/smart-approvals/history",
  key: "history",
};
export const SMART_APPROVAL_PO = {
  label: "Purchase orders",
  path: "/smart-approvals/po",
  key: "po",
};
export const SMART_APPROVAL_PO_2 = {
  label: "Purchase requests",
  path: "/smart-approvals/po",
  key: "po",
};
export const SMART_APPROVAL_PR = {
  label: "Payment runs",
  path: "/smart-approvals/pr",
  key: "pr",
};

export const SMART_APPROVAL_CN = {
  label: "Credit notes",
  path: "/smart-approvals/cn",
  key: "cn",
};

export const SMART_APPROVAL_SUPPLIER = {
  label: `${SUPPLIERS_PLACEHOLDER}`,
  path: "/smart-approvals/supplier",
  key: "supplier",
};

export const ASSIGN_WORKFLOW = {
  TITLE: "Assign to workflow",
  SUB_TITLE: (type = "invoices") =>
    `Which workflow would you like to assign these ${type} to?`,
  SELECT_LABEL: "Assign to workflow",
  SELECT_LABEL_PLACEHOLDER: "Select workflow",
  SELECT_LABEL_REQ_ERROR:
    "Please select whether to assign to workflow or individual",
  PLATFORM_USER: "Platform User",
  AUTO_APPROVER: "Auto Approver",
  AUTO_APPROVE: "Auto Approve",
  MODAL_DISCLAIMER: (count, workflowName, module) =>
    `You are about to assign ${count > 1 ? count : ""} ${module}${
      count > 1 ? "s" : ""
    } for approval. Once assigned, ${
      count > 1 ? "these" : "this"
    } will be sent to the selected workflow.`,
  MODAL_RESEARCH_WORKFLOW: (workflowName) => `The ${workflowName} includes:`,
  VIEW_LESS: "View less",
  VIEW_ALL: "View all",
  DELETE_SUBTITLE: "Are you sure that you want to delete this workflow?",
  DELETE_WORKFLOW: "Delete workflow",
  DELETE_WORKFLOW_NOT_FOUND: "Workflow not found ",
  WORKFLOW_ARROW: "| ",
  WORKFLOW_LIST_HEADING: "Workflows for approving submitted invoices",
  WORKFLOW_PO_LIST_HEADING_2:
    "Workflows for approving submitted purchase requests",
  TOTAL_WORKFLOW_STEP: (total) =>
    `${total} step${total !== 1 ? "" : "s"} workflow`,
  WORKFLOW_STEP: (total) => `Step ${total}`,
  CREATE_NEW_WORKFLOW: "Create new workflow",
  INVOICES_NOTE:
    "Create and manage workflows for invoices to be approved by users within your organisation",
  PO_NOTE:
    "Create and manage workflows for purchase order to be approved by users within your organisation",
  PO_NOTE_2:
    "Create and manage workflows for purchase requests to be approved by users within your organisation",
  ASSIGN_WORKFLOW_SUCCESS_MSG: (idsLength, type = "Invoice") =>
    `${capitalizeFirst(type)}${idsLength > 1 ? "s" : ""} assigned to workflow`,
  ASSIGN_WORKFLOW_ERROR_MSG: "Please select a workflow",
  AUTO_APPROVED_SUCCESS_MSG: (idsLength, type = "Invoice") =>
    `${capitalizeFirst(type)}${idsLength > 1 ? "s" : ""} approved successfully`,
  TOTAL_WORKFLOW_STEPS: (total) => `${total} step workflow`,
};

export const SMART_APPROVAL_TOP_PANEL_NOTE =
  "Kloo's smart approvals allow finance teams to configure their approval rights and workflows for each Kloo product, ensuring that these workflows match your existing business processes";

export const SMART_APPROVAL_DEFAULT_MINIMUM_NO_OF_WORKFLOWS = 6;
export const REJECTED = "rejected";
export const SUBMITTED = "submitted";
export const REJECT = "Reject";
export const CANCEL = "Cancel";
export const CANCELLED = "cancelled";
export const NOT_SELECTABLE_STATUS_INVOICE_MANAGEMENT = [REJECTED, CANCELLED];

export const INVOICE_MANAGEMENT = "Invoice management";
export const CARDS = "Cards";

export const DUE_DATE = "Due date";
export const PAYMENT_DATE = "Payment date";

export const INVOICE_MANAGEMENT_FILTER_TAB = {
  RAISEDBY: "Raised by",
  APPROVEDBY: "Approved by",
  PAIDBY: "Paid by",
};

export const INVOICE_MANAGEMENT_FILTERS = {
  RAISEDBY: "Raised by",
  APPROVEDBY: "Approved by",
  PAIDBY: "Paid by",
  WORKFLOW: "Workflow",
  PAYEE: "Payee",
  PAYMENT_DATE: "Payment date",
  DUE_DATE: "Due date",
  SCHEDULED_DATE: "Scheduled date",
  CREATED_DATE: "Created date",
  STATUS: "Status",
  INVOICE_NUMBER: "Invoice number",
  AMOUNT: "Amount",
  PAYMENT_TYPE: "Payment type",
};

export const INVOICE_MANAGEMENT_TABS = {
  SUBMITTED_TAB: "submitted",
  IN_REVIEW: "in_review",
  TO_PAY: "approved",
  SCHEDULED: "scheduled",
  PAID: "paid",
};

export const INVOICE_MANAGEMENT_TO_PAY_TAB = "approved";
export const INVOICE_MANAGEMENT_PAID_TAB = "paid";

//Smart approval //

export const VIEW_MODAL_BUTTON_TEXT = "Edit";
export const PURCHASE_ORDER_APPROVAL = "purchase-order-approval";
export const ACCOUNTS_PAYABLE_APPROVAL = "account-payable-approval";

export const OVER = "over";
export const UPTO = "up to";
export const AND = "and";

export const SMART_APPROVAL_ACTIVE_TAB = ["invoices", "po", "pr", "cn"];
export const GLOBAL_NOT_APPLICABLE_SYMBOL = "N/A";
export const GLOBAL_DOUBLE_HYPHEN_SYMBOL = "--";
export const SMART_APPROVAL_TAB = ["invoices", "po", "pr", "cn", "history"];
export const SUPPORT_EMAIL = {
  text: "support@getkloo.com",
  href: "mailto: support@getkloo.com",
};

export const SETTING_ADMIN_MSG = {
  infoMsg:
    "All organization admins and finance approvers can mark an invoice as paid.",
};

export const Z_INDEX_FOR_SCA_POPUP = 2000;

export const AUTOMATION_FIELD_MAPPING = {
  selectTitle: "Select workflow",
  header:
    "Determine which field should be used to trigger the automation for assigning invoices to a workflow.",
  Workflow: "Workflow",
  subtitlePO: "Assign remaining values to common workflow",
  placeTitle: "Search or select field",
};

export const INVOICE_PREVENT_TYPE = {
  enablePrevent: "invoicePreventEnableCheckbox",
  disablePrevent: "invoicePreventRemoveCheckbox",
};

export const AUTOMATION_WORKFLOW = {
  DELETE_MAPPING: "DeleteMApping",
  deleteTitle: "Delete workflow mapping",
  invoice: {
    deleteSubtitle:
      "You are about to delete a workflow mapping. Please select from the options below which actions should be taken on invoices currently in automatically signed workflows.",
    header:
      "You are about to turn off automatic assignment of workflows. Please select from the options below which actions should be taken on invoices currently in automatically signed workflows.",
  },
  po: {
    deleteSubtitle:
      "You are about to delete a workflow mapping. Please select from the options below which actions should be taken on purchase orders currently in automatically signed workflows.",
    header:
      "You are about to turn off automatic assignment of workflows. Please select from the options below which actions should be taken on purchase orders currently in automatically signed workflows.",
  },
  [INVOICE_PREVENT_TYPE.enablePrevent]: {
    header:
      "Selecting this option will require new invoice to be confirmed matched before they are assigned to a workflow. What would you like to do to invoices currently in workflows which do not have a confirmed match status?",
    title: "Removal of invoices from workflows",
  },
  [INVOICE_PREVENT_TYPE.disablePrevent]: {
    header:
      "By deselecting this option, invoices of any match status can be assigned to a workflow. What would you like to happen to invoices in the submitted tab which could be assigned to workflows.",
    title: "Assignment of invoices to workflows",
  },
  title: "Disable automation",
  checkTitle: "I understand that this action cannot be undone.",
  btnConfirm: "Confirm",
  btCancel: "Cancel",
  save: "Save",
  btnDelete: "Delete label",
  selectTitle: "Select an action",
  addMapping: "Add mapping",
  ManageMapping: "Manage mapping",
};

export const CUSTOM_MAPPING_DISABLE = {
  title: "Disable custom fields",
  header:
    "You are about to disable custom fields and they will be removed from all items.  In addition, if you have automatic assignment of workflows enabled based on custom fields, automated assignment will be disabled and reassignment will be required.",
  categoryDisabled:
    "You are about to disable category fields and they will be removed from all items.  In addition, if you have automatic assignment of workflows enabled based on custom fields, automated assignment will be disabled and reassignment will be required.",
};
export const DISABLE_DROPDOWN_AUTOMATION = {
  invoice: (extraData = {}) => {
    const { checkBoxPrevent } = extraData;
    let isExtraOption = [];
    if (checkBoxPrevent) {
      isExtraOption.push({
        title: "Remove all non-confirmed matched invoices from their workflows",
        label: "Remove all non-confirmed matched invoices from their workflows",
        value: 3,
      });
    }
    return [
      {
        title:
          "Remove all automatically assigned invoices from their workflows",
        label:
          "Remove all automatically assigned invoices from their workflows",
        value: false,
      },
      {
        title: "Keep all invoices in current workflow and approval stage",
        label: "Keep all invoices in current workflow and approval stage",
        value: true,
      },
      ...isExtraOption,
    ];
  },
  po: () => [
    {
      title:
        "Remove all automatically assigned purchase orders from their workflows",
      label:
        "Remove all automatically assigned purchase orders from their workflows",
      value: false,
    },
    {
      title: "Keep all purchase orders in current workflow and approval stage",
      label: "Keep all purchase orders in current workflow and approval stage",
      value: true,
    },
  ],
  [INVOICE_PREVENT_TYPE.disablePrevent]: (extraData = {}) => {
    const { mappingData } = extraData;
    return [
      {
        title: "Keep invoices in submitted tab",
        label: "Keep invoices in submitted tab",
        value: 1,
        disabled: false,
      },
      {
        title: "Assign invoices to workflows using workflow automation rules",
        label: "Assign invoices to workflows using workflow automation rules",
        value: 2,
        disabled: mappingData.length > 1,
      },
    ];
  },
  [INVOICE_PREVENT_TYPE.enablePrevent]: () => [
    {
      title: "Keep invoices in current workflows",
      label: "Keep invoices in current workflows",
      value: 1,
    },
    {
      title: "Remove invoices from current workflows",
      label: "Remove invoices from current workflows",
      value: 2,
    },
  ],
};

// Download Modal //

export const DOWNLOAD_MODAL = {
  TITLE: "Download",
  SUBTITLE: "Please choose your preferred download format:",
  DISCLAIMER: (fileName = "files") =>
    `The maximum number of simultaneous PDF downloads is 10. Please select fewer ${fileName} to use this option or download as excel.`,
  DOWNLOAD_AS_PDF: "Download as PDF",
  DOWNLOAD_AS_EXCEL: "Download as Excel",
};

//  deleteModal custom expenses ... //
export const CUSTOM_DELETE_MODAL = {
  KEY: "customExpenses",
  categoryKey: "category",
  customCategoryKey: "custom_categories",
  btnLabel: "Delete",
  DeleteHeader: "Delete custom field",
  categoryDelete: "categoryDelete",
  categoryDeleteHeader: "Delete category field",
  categoryDisableHeader: "Disabled category field",
  subBody:
    "This custom field will be permanently deleted and will be removed from all  items. In addition, if automated assignment of workflows is enabled based on this field, automated assignment will be disabled and reassignment will be required.",
  subCategoryBody:
    "This Category field will be permanently deleted and will be removed from all  items. In addition, if automated assignment of workflows is enabled based on this field, automated assignment will be disabled and reassignment will be required.",
};

export const OWNER = "Owner";
export const PAYEE = SUPPLIER_PLACEHOLDER;
export const UPDATE_TRIGGER_MODAL = {
  title: "Confirm update invoice",
  CATEGORY_NAME: "category",
  CUSTOM: "custom",
  PAYEE: LC_SUPPLIER_PLACEHOLDER,
  OWNER: "owner",
  CATEGORY: "categories",
  CUSTOM_FIELD: "custom fields",
  KLOO_CATEGORY: " Kloo categories",
};
export const AUTOMATION_OWNER_PAYEE_TRIGGER = (key, type, tab) =>
  `By changing the value in the ${
    key === UPDATE_TRIGGER_MODAL.CATEGORY
      ? UPDATE_TRIGGER_MODAL.CATEGORY_NAME
      : key === OWNER
      ? UPDATE_TRIGGER_MODAL.OWNER
      : key === PAYEE
      ? UPDATE_TRIGGER_MODAL.PAYEE
      : key
  } field, this ${type} will be ${
    INVOICE_MANAGEMENT_TABS.SUBMITTED_TAB === tab ? "" : "re"
  }assigned to a different workflow. Please confirm if you are happy to proceed.`;

export const USER_DELETE_FORM = {
  deleteTitle: "Delete user",
  DisableTitle: "Disable user",
  deleteSubTitle:
    "This user will be deleted and will be removed from all  items. In addition, if automated assignment of workflows is enabled based on this field, automated assignment will be disabled and reassignment will be required.",
  DisableSubTitle:
    "This user will be disabled and will be removed from all  items. In addition, if automated assignment of workflows is enabled based on this field, automated assignment will be disabled and reassignment will be required.",
};

export const customDeleteHeader = (key) => `Delete ${key}`;

export const descriptionPopulate =
  "This description has been generated using Kloo AI.";

export const supplierPopulate = `${SUPPLIER_PLACEHOLDER} identified using Kloo AI.`;
export const supplierAccountPopulate = `This account has been identified using Kloo AI.`;

export const PENDING_REQUESTS_WARNING = (totalPendingRequest, teamManager) =>
  `This user has ${
    totalPendingRequest === 1 ? "a" : totalPendingRequest
  } pending ${
    totalPendingRequest === 1 ? "request" : "requests"
  } and changing their team will reassign ${
    totalPendingRequest === 1 ? "this" : "these"
  } ${
    totalPendingRequest === 1 ? "request" : "requests"
  } to ${teamManager} for approval.`;

export const TEAM_WITHOUT_TEAM_MANAGER = (totalPendingRequest, teamName) =>
  `This user has ${
    totalPendingRequest === 1 ? "a" : totalPendingRequest
  } pending ${
    totalPendingRequest === 1 ? "request" : "requests"
  } and is being moved to a team with no manager. ${
    totalPendingRequest === 1 ? "This" : "These"
  } ${
    totalPendingRequest === 1 ? "request" : "requests"
  } will default to requiring Organisation Admin approval, unless a team manager is added.`;

export const NO_TEAM_SELECTED = (totalPendingRequest) =>
  `This user has ${
    totalPendingRequest === 1 ? "a" : totalPendingRequest
  } pending ${
    totalPendingRequest === 1 ? "request" : "requests"
  }, and removing them from a team will result in ${
    totalPendingRequest === 1 ? "this" : "these"
  } ${
    totalPendingRequest === 1 ? "request" : "requests"
  } defaulting to requiring Organisation Admin approval.`;

export const NO_TEAM_SELECTED_MODAL = (totalPendingRequest, userName) =>
  `${userName} has ${
    totalPendingRequest === 1 ? "a" : totalPendingRequest
  } pending ${
    totalPendingRequest === 1 ? "request" : "requests"
  }, and removing them from a team will result in ${
    totalPendingRequest === 1 ? "this" : "these"
  } ${
    totalPendingRequest === 1 ? "request" : "requests"
  } defaulting to requiring Organisation Admin approval.`;

export const PENDING_REQUESTS_WARNING_MODAL = (
  totalPendingRequest,
  teamManager,
  userName
) =>
  `${userName} has ${
    totalPendingRequest === 1 ? "a" : totalPendingRequest
  } pending ${
    totalPendingRequest === 1 ? "request" : "requests"
  } and changing their team will reassign ${
    totalPendingRequest === 1 ? "this" : "these"
  } ${
    totalPendingRequest === 1 ? "request" : "requests"
  } to ${teamManager} for approval.`;

export const TEAM_WITHOUT_TEAM_MANAGER_MODAL = (
  totalPendingRequest,
  teamName,
  userName
) =>
  `${userName} has ${
    totalPendingRequest === 1 ? "a" : totalPendingRequest
  } pending ${
    totalPendingRequest === 1 ? "request" : "requests"
  }, and is being moved to a team with no manager. ${
    totalPendingRequest === 1 ? "This" : "These"
  } ${
    totalPendingRequest === 1 ? "request" : "requests"
  } will default to requiring Organisation Admin approval, unless a team manager is added.`;

export const OPTION_DESCRIPTION = (dataField, optionArray) => {
  const optionLabel = (
    optionArray.find((option) => option.id === dataField.option_id) || {}
  )?.option_name
    ?.replace("Only", "")
    ?.toLowerCase();
  return `${
    dataField?.attribute_type === "textbox"
      ? isDevelopmentEnvironment() ||
        isStageEnvironment() ||
        isPlatformEnvironment()
        ? "Free Text"
        : "Free Text Input"
      : `${capitalizeFirst(dataField?.attribute_type)} list`
  } for ${optionLabel}`;
};

export const PROMISE = {
  STATUS: {
    FULFILLED: "fulfilled",
    REJECTED: "rejected",
  },
};

export const FIELD_DETECTED_BY_OCR_MSG = (field) =>
  `This ${field} has been detected using KlooAI.`;

export const POST_COMMENT = {
  ADD_COMMENT_PLACEHOLDER: "Add a comment",
  POST_COMMENT_BTN: "Post comment",
  COMMENT_ADDED: "Comment added",
};
