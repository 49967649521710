import { apiClient } from "../redux/service/ApiConfig";
import { ORGID, PATH } from "../redux/service/apiConstant";
import {
  amountFormatter,
  appendKeyForFilters,
  capitalizeFirst,
  capitalizeFirstLetter,
  generateEncodedURIString,
  moneyFormatter,
  renderAmount,
} from "../utils";

export async function getAccountDetails({ orgId }) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.cards.accountDetails(orgId),
  });

  return {
    address: tempResponse?.data?.result?.[0]?.organization_address?.[0] || "",
  };
}

export async function getCardsList({
  page = 1,
  filtersUrl = "",
  searchUrl = "",
  isDownloadUrl = "",
  pageSize = 10,
  userOrgIdUrl = "",
}) {
  const pageSizeURL = generateEncodedURIString(
    "perPageCount",
    pageSize.toFixed()
  );

  const tempResponse = await apiClient({
    url: `${
      PATH.cards.cardsListing
    }/${ORGID()}?page=${page}${userOrgIdUrl}${filtersUrl}${searchUrl}${isDownloadUrl}${pageSizeURL}`,
    method: "GET",
  });
  const response = {
    data: {
      list: tempResponse?.data?.result?.data?.map(
        ({
          states,
          card_type,
          first_name,
          last_name,
          nickname,
          spent,
          balance,
          ...rest
        }) => {
          return {
            status: capitalizeFirst(states?.state_description),
            card_type: capitalizeFirst(card_type),
            cardholder: first_name + " " + last_name,
            nickname,
            spent: moneyFormatter(spent),
            balance: moneyFormatter(balance),
            states,
            first_name,
            last_name,
            ...rest,
          };
        }
      ),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
      filters: [],
    },
  };

  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}

export async function getCardsListDownload({
  page = 1,
  filtersUrl = "",
  searchUrl = "",
  isDownloadUrl = "",
  pageSize = 10,
  userOrgIdUrl = "",
}) {
  const tempResponse = await apiClient({
    url: `${
      PATH.cards.cardsListingDownload
    }/${ORGID()}?page=${page}${userOrgIdUrl}${filtersUrl}${searchUrl}${isDownloadUrl}`,
    method: "GET",
  });
  const response = {
    data: {
      downloadLink: tempResponse?.data?.result?.["download-link"],
    },
  };
  return response.data;
}

export function editCardNickName({ data, cardId }) {
  return apiClient({
    method: "PUT",
    url: PATH.cards.editCardNickName(cardId),
    data,
  });
}

export function editCardLimit({ data, cardId }) {
  return apiClient({
    method: "PUT",
    url: PATH.cards.editCardLimit(cardId),
    data,
  });
}

export const createVirtualCard = (data) => {
  return apiClient({
    method: "POST",
    url: PATH.cards.createVirtual,
    data,
  });
};

export const createVirtualCardPU = (data) => {
  return apiClient({
    method: "POST",
    url: PATH.cards.createVirtualPU,
    data,
  });
};

export const createPhysicalCard = (data) => {
  return apiClient({
    method: "POST",
    url: PATH.cards.createPhysical,
    data,
  });
};

export const createPhysicalCardPU = (data) => {
  return apiClient({
    method: "POST",
    url: PATH.cards.createPhysicalPU,
    data,
  });
};
