import { Form } from "antd";
import { BULK_ACTION_BUTTON_CONFIG } from "../../../constants/CreditNotes";
import { Box, CustomButton, Typography, When } from "../../common";
import { useEffect, useRef, useState } from "react";
import BulkDrawerTable from "./BulkDrawerTable";
import useRolesAttributesUsers from "../../../hooks/useRolesAttributesUsers";
import useWorkflow from "../../../hooks/useWorkflow";
import { calculatePercentageAmount } from "../../../utils";
import { BULK_ACTIONS, WORK_FLOW_TYPES } from "../../../constants";

function DrawerLeftSectionWithWorkflow(props) {
  const { attributes, roles } = useRolesAttributesUsers({});
  const { workFlowData } = useWorkflow({
    roles,
    attributes,
    workflowType: WORK_FLOW_TYPES[props.workflowType],
    // workflowType: WORK_FLOW_TYPES["AP"],
  });
  return (
    <>
      <DrawerLeftSectionWithoutWorkflow
        {...props}
        workFlowData={workFlowData}
      />
    </>
  );
}

function DrawerLeftSectionWithoutWorkflow({
  activeTab,
  actionType,
  parentModule,
  setSelectedModule,
  BULK_ACTION_CONTENT,
  BULK_ACTION_COLUMN,
  moduleData,
  showFormBelowTitle = false,
  RenderFormField = () => null,
  onClose,
  setSelectedItems,
  selectedModuleData,
  mainDivRef,
  onPrimaryBtnClick,
  workFlowData = [],
}) {
  const headerDivRef = useRef();

  const [form] = Form.useForm();
  const [tableHeight, setTableHeight] = useState(0);
  const [currentModuleData, setCurrentModuleData] = useState(moduleData);
  const { subTitle, primaryBTN } = BULK_ACTION_CONTENT({
    actionType,
    module: parentModule,
  });

  const handleModuleSelection = ({ record }) => {
    setSelectedModule(record);
  };

  const getMainDivHeight = () => {
    // Main Div Height is the Height of the Main Row (55%-45%)
    return mainDivRef?.current?.clientHeight || 10;
  };

  const getHeaderDivHeight = () => {
    // Header Div Height is the Height of the Left Section which excludes Drawer title & footer (120px)
    return headerDivRef?.current?.clientHeight || 10;
  };

  const calculateTableHeight = () => {
    setTableHeight(getMainDivHeight() - getHeaderDivHeight() - 120);
  };

  useEffect(() => {
    calculateTableHeight();
  }, [actionType, moduleData]);

  const DrawerHeader = () => {
    return (
      <>
        <Typography
          variant="body"
          text={subTitle(currentModuleData?.length)}
          className={"mb-2"}
        />
      </>
    );
  };

  const DrawerFooter = () => {
    const onClickHandler = async () => {
      const extraDetails = {};
      if (showFormBelowTitle) {
        const formData = await form.validateFields();
        extraDetails.formDetails = formData;
      }
      await onPrimaryBtnClick({ key: primaryBTN.key, ...extraDetails });
    };

    return (
      <Box className="w-100" justifyContent="spaceBetween">
        <CustomButton
          {...primaryBTN}
          onClick={onClickHandler}
          disabled={!currentModuleData?.length}
        />
        <CustomButton
          {...BULK_ACTION_BUTTON_CONFIG.CANCEL}
          customSize={"large"}
          className={"w-45"}
          onClick={onClose}
        />
      </Box>
    );
  };

  return (
    <>
      <div>
        <div ref={headerDivRef}>
          <DrawerHeader />
          <When condition={showFormBelowTitle}>
            <Form form={form}>
              <RenderFormField
                form={form}
                workFlowData={workFlowData}
                moduleData={selectedModuleData}
                stepPreviewMinimumHeight={
                  calculatePercentageAmount(35, getMainDivHeight()) || 0
                }
                calculateTableHeight={calculateTableHeight}
                setTableHeight={setTableHeight}
              />
            </Form>
          </When>
        </div>
        <BulkDrawerTable
          key={`${tableHeight}-bulk-table`}
          activeTab={activeTab}
          modules={currentModuleData}
          selectedModule={selectedModuleData}
          setSelectedModule={setSelectedModule}
          columns={BULK_ACTION_COLUMN}
          onClose={onClose}
          onRowClick={handleModuleSelection}
          setSelectedItems={(data) => {
            setCurrentModuleData(data);
            setSelectedItems(data);
          }}
          tableHeight={tableHeight}
        />
      </div>
      <DrawerFooter />
    </>
  );
}

function DrawerLeftSection(props) {
  return [BULK_ACTIONS.ASSIGN_TO_WORKFLOW].includes(props.actionType) ? (
    <DrawerLeftSectionWithWorkflow {...props} />
  ) : (
    <DrawerLeftSectionWithoutWorkflow {...props} />
  );
}

export default DrawerLeftSection;
