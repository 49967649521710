import { Form, Upload as AUpload } from "antd";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAiTriggers,
  getErpAccounts,
  getTrackingCatgories,
  getTaxRateList,
  checkEntityMappingStatus,
  getAuditLogByInvoiceId,
  createDraftInvoice,
  updateDraftInvoice,
  getInvoiceDetailsById,
  getOCRLineItemDetails,
  isTaxSolutionValid,
} from "../../../../api/InvoiceManagementAPI";
import {
  deleteDraftAttachment,
  getPOForDropdown,
  getTaxSolutionOptions,
  getThreshold,
  postComment,
} from "../../../../api";
import {
  ACCENTS,
  BUTTON_VARIANT,
  featureFlags,
  MATCH_TYPE,
  PURCHASE_ORDER_CREATE_FROM,
  DUPLICATE_INVOICE,
  MODULE_TYPES,
  TRIGGER_SUPPLIER_ID,
  CONFIGURATIONS_KEYS,
  FIELD_TYPE_CONFIG,
  DRAFT_INVOICE_SUCCESS_MSG_CREATE,
  DRAFT_INVOICE_SUCCESS_MSG_UPDATE,
  isSupplierIdentifyByOCR,
} from "../../../../constants";
import {
  CANCEL_LABEL,
  EDIT_LABEL,
  INVOICE_STATUS,
  INVOICE_FORM,
  INVOICE_MANAGEMENT_TAB_KEYS,
  INVOICE_LINE_ITEMS,
  PAGE_HEADER_INFO_INVOICE_MANAGEMENT,
  INVOICE_GENERATED_USING_AI,
  INVOICE_POPULATED_USING_AI,
  KLOO_INSIGHT,
  MULTIPLE_PO_DROPDOWN_OPTION,
  MULTIPLE_PO_DROPDOWN_OPTION_ID,
  WARNING_MESSAGES,
  IM_CREATE_INVOICE_FORM,
  DYNAMIC_ATTRIBUTE_TYPE,
  GEN_AI_IM_TRIGGER_MAPPING,
  INVOICE_SPLIT_CONSTANTS,
  disabledMatchingBtnToolTip,
  TRACKING_CATEGORY_KEY_PREFIX,
  INVOICE_UPLOADING_STATUS,
  LINE_ITEMS_ERRORS,
  ITEM_TABLE_COLUMN,
  IM_AUDIT_LOG_MODAL,
  PO_ERROR_MSG,
  CREATE_INVOICE_BTN_DISABLED_TOOLTIP,
  OCR_LINE_ITEMS,
  ENTITY_CLOSED_PO_TP_MSG,
  ai_alert,
  disabledPONumberForEntity,
  TAX_SOLUTION_NO_TAX_LABEL,
} from "../../../../constants/InvoiceManagement";
import { CATEGORISATION_CONSTANTS } from "../../../../constants/Categorisation";
import {
  getSupplierDetails,
  isPaidTabSelected,
  showKlooInsights,
  isAgilisysOrg,
  duplicateInvoiceHandler,
  isFileCompressionEnabled,
  CREATE_FORM_OBJECT,
  connectedErpLogo,
  isLineItemsRevampEnabled,
  isCommonErpFieldsEnabled,
  isFeat11894Enabled,
  isStatusToBeUpdatedEnabled,
  isBleepBoxOrg,
  cleanERPFieldsFromLineItemObject,
} from "../../../../helper";
import { getAccountPayableList } from "../../../../redux/action/accountPayable";
import { createPaymentAP, editPaymentAP } from "../../../../redux/action/auth";
import { getCatgorisation } from "../../../../redux/action/categorisation";
import {
  handleMappingListing,
  invoiceSupplierList,
} from "../../../../redux/action/invoice";
import {
  customExpenseGetList,
  getValidationList,
  trackingcategories,
} from "../../../../redux/action/setting";
import {
  getItem,
  MODAL_TYPES,
  OWNER,
  PAYEE,
  renderAmount,
  UPDATE_TRIGGER_MODAL,
  USER_TYPES,
  roundOff,
  generateEncodedURI,
  generateEncodedSearchURI,
  transFormDataLineItems,
  amountFormatterWithoutCurrency,
  IM_CREATE_INVOICE_FORM_RULE,
  getArrayLength,
  compareStrings,
  compressImage,
  constructClassName,
  convertJSObjectToFormData,
  showNotification,
  NOTIFICATION_TYPE,
  getOnlyAmount,
  scrollToTop,
  getDefaultCurrency,
} from "../../../../utils";
import {
  EnvFeatureFlag,
  When,
  CommonSelect,
  CommonInput,
  CustomButton,
  Icon,
  Row,
  Column,
  Typography,
  Box,
  CommonDatePicker,
  CommonInputNumber,
  Label,
  CustomAlert,
  ItemList,
  Image,
  ActionLink,
  Drawer,
  ComponentLoader,
} from "../../../common";
import { getUsersForSelect, customFieldsFromGenAI } from "../../../../api";

import "../../components/PaidDataForm/paidDataFormStyle.scss";
import { useLocation, useNavigate } from "react-router-dom";
import CommonToolTip from "../../../common/Tooltip";

import TrackingCategoriesField from "../../components/PaidDataForm/TrackingCategoriesField";
import {
  ERP_INTEGRATIONS_PAGE_CONFIG,
  ERP_PLATFORM_KEYS,
} from "../../../../constants/Integration/OpenBanking";
import CurrencySelect from "../../components/PaidDataForm/CurrencySelect";
import CustomFieldInvoice from "../CustomFieldInvoice";
import UploadInvoice from "../UploadInvoice";
import { loadingStart, loadingStop } from "../../../../redux/action/loader";
import LineItems from "../../../common/LineItems";
import useERPFields from "../../../../hooks/ERPFields";
import {
  checkLineItemsFormItemStatus,
  generateLineItemsPayload,
  getLineItemWithSuggestedERPDetails,
} from "../../../../helper/LineItems";
import PONumberInputField from "./PONumberInputField";
import { isAutoInvoiceConfirmed } from "../../../../helper/Settings/Configurations";
import InvoiceFooterButton from "./InvoiceFooterButton";

function CreateInvoiceDrawer(props) {
  const {
    open,
    activeKey,
    type,
    title,
    suppliers,
    currencies,
    xeroCategory,
    xeroCustomerContactID,
    closeDataForm,
    updateSupplier,
    reloadTable,
    setRedirect,
    setRedirectData,
    entityEnabled,
    settingsConfig,
    entityList,
    defaultEntityList,
    taxCodeData,
    customFieldValueLoader,
    selectedData,
    editModeButton,
    editMode,
    invoiceFromSupplier,
    currentPage,
    tabState,
    showModal,
    hideModal,
    setActiveKey,
    openDrawer,
    isPOFieldRequired,
    taxCodeEnabled,
  } = props;

  const {
    SUPPLIER_ACCOUNT,
    TAX_CODE,
    TAX_SOLUTION,
    SUPPLIER,
    INVOICE_NUMBER,
    AP_DESCRIPTION,
    INVOICE_DATE,
    DUE_DATE,
    AMOUNT,
    VAT_NUMBER,
    PO_NUMBER,
    VAT_RATE,
    OWNER_FIELD,
    XERO_CUSTOMER_FIELD,
    XERO_CATEGORY,
    TRACKING_CATEGORIES,
  } = INVOICE_FORM;

  const isAutoMatchEnabledForOrgConfig = settingsConfig?.find(
    (item) => item?.config_key_name === CONFIGURATIONS_KEYS.autoMatchConfirmed
  );
  const isAutoMatchEnabledForOrg =
    isAutoMatchEnabledForOrgConfig?.is_key_enabled === 1;
  const [createSelector, setCreateSelector] = useState(null);
  const [treeData, setTreeData] = useState({});
  const [dynamicOptions, setDynamicOptions] = useState({});
  const [ownerList, setOwnerList] = useState([]);

  const [docIdFile, setDocIdFile] = useState("");
  const [lineItems, setLineItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const [connectedErpPlatform, setConnectedErpPlatform] = useState("");
  const [form] = Form.useForm();
  const selectedPayeeDetails = Form.useWatch(SUPPLIER.name, form);
  const deletedLineItemIdArray = useRef([]);
  const [componentDisabled, setComponentDisabled] = useState(true);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [creatableError, setcreatableError] = useState({});
  const [size] = useState("large");
  const [isAttachmentDelete, setAttachmentDelete] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const [errorMessage, setError] = useState("");
  const [storeDuplicateInvoiceData, setStoreDuplicateInvoiceData] =
    useState(null);
  const [initialFormValue, setDefaultFormValue] = useState({ remember: true });
  const [previewImage, setPreviewImage] = useState("");

  const [TrackingDisable, setTrackingDisabel] = useState({});
  const [selectedFileList, setSelectedFileList] = useState({
    selectedFile: null,
    selectedFileList: [],
  });
  const getUpdatedSuppliers = (entityId) => updateSupplier(entityId);
  const [autoFillSupplier, setAutoFillSupplier] = useState(false);
  const [autoFillSupplierAccount, setAutoFillSupplierAccount] = useState(false);
  const [mappingDynamic, setMappingDynamic] = useState({});
  const [newCreatedField, setNewCreatedField] = useState([]);
  const [approvedPOs, setApprovedPOs] = useState([]);
  const [viewLineItems, setViewLineItems] = useState(false);
  const [transformedData, setTransformedData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [dataPopulatedByAI, setDataPopulatedByAI] = useState({});
  const [threshold, setThreshold] = useState(0);
  const [fixedAmount, setFixedAmount] = useState(0);
  const [viewEntityField, setViewEntityField] = useState(false);
  const loggedInUserOrgId = localStorage.getItem("user_org_id");
  const [fieldChanged, setFieldChanged] = useState(false);
  const [isEntityMapped, setIsEntityMapped] = useState(false);
  const [chartOfAccounts, setChartOfAccounts] = useState([]);
  const [taxRateList, setTaxRateList] = useState([]);
  const [trackingCategories, setTrackingCategories] = useState(null);
  const [ocrData, setOcrData] = useState({});
  const [entityId, setEntityId] = useState("");
  const [uploadContainerLoading, setUploadContainerLoading] = useState(false);
  const [taxSolutionOptions, setTaxSolutionOptions] = useState([]);
  const ref = useRef(null);

  const {
    ChartOfAccounts,
    TaxRate,
    TrackingCategories,
    chartsOfAccountList,
    trackingCategoriesList,
    connectedErpPlatformName,
    isERPMapped,
  } = useERPFields({
    entityId,
    form,
    getUpdatedSuppliers,
    skipHookExecution: isLineItemsRevampEnabled(),
  });

  const [entityFieldDisabled, setEntityFieldDisabled] = useState(false);
  const [triggerField, setTriggerField] = useState(null);

  const getPredefineList = useSelector(
    (state) =>
      state &&
      state?.CustomCategories &&
      state?.CustomCategories?.getPredefineList
  );

  const customField = useSelector(
    (state) =>
      state &&
      state?.CustomCategories &&
      state?.CustomCategories?.customGetExpenses
  );

  const categoriesMap = useSelector(
    (state) =>
      state &&
      state?.trackingCategories &&
      state?.trackingCategories?.trackingCategoriesMap
  );
  const initialcategories = useSelector(
    (state) =>
      state &&
      state?.trackingCategories &&
      state?.trackingCategories?.trakingCategories
  );
  const toggle_status = useSelector(
    (state) => state && state.categorisationReducer
  );

  const checkIfDefaultEntity = useCallback(
    (id) => {
      if (defaultEntityList.length > 0) {
        const fountDefaultEntity = defaultEntityList?.some(
          (item) => item?.id === id
        );
        return fountDefaultEntity;
      } else {
        return false;
      }
    },
    [defaultEntityList]
  );

  const accountDetail =
    useSelector(
      (state) =>
        state &&
        state?.modular &&
        state?.modular?.accountDetail &&
        state?.modular?.accountDetail[0]
    ) || {};
  const isErpIntegration = accountDetail?.is_erp_integration || 0;
  const currencyField = Form.useWatch("currency", form);
  const netAmount = Form.useWatch("netAmount", form);
  const itemSwitch = Form.useWatch("itemSwitch", form);
  const poNumberField = Form.useWatch(PO_NUMBER.name, form);
  const taxCodeValue = Form.useWatch(TAX_CODE.name, form);
  const taxAmount = Form.useWatch(IM_CREATE_INVOICE_FORM.taxAmount.name, form);
  const invoiceNumberCurrentValue = Form.useWatch(INVOICE_NUMBER.name, form);
  const entityValue = Form.useWatch(
    PURCHASE_ORDER_CREATE_FROM.entity.name,
    form
  );
  const OCRlineItemsConfig = settingsConfig?.find(
    (item) => item?.config_key_name === CONFIGURATIONS_KEYS.OCRlineItems
  );
  const taxSolutionValue = Form.useWatch(TAX_SOLUTION.name, form);
  const selectedTaxSolution = taxSolutionOptions.find(
    (item) => item.value === taxSolutionValue
  );

  const [klooAITooltipText, setKlooAITooltipText] = useState(
    OCR_LINE_ITEMS.GENERATE
  );
  const [showLineItems, setShowLineItems] = useState(false);
  const [lineItemsValuesChanged, setLineItemsValuesChanged] = useState(false);
  const [loadingLineItems, setLoadingLineItems] = useState(false);

  const selectedCurrency = currencies?.result?.find(
    (currency) => currency.id === currencyField
  );

  useEffect(() => {
    form.setFieldValue(
      "amount",
      renderAmount(
        getOnlyAmount(netAmount || 0) + getOnlyAmount(taxAmount || 0),
        "commaWithDecimal"
      )
    );
  }, [form, taxAmount, netAmount]);

  useEffect(() => {
    if (customFieldValueLoader) return;
    getThreshold().then((res) => {
      setThreshold(res.threshold_value || 0);
      setFixedAmount(res.fixed_value || 0);
    });

    if (selectedData?.is_supplier_matched_by_algorithm) {
      setAutoFillSupplier(true);
    }
    if (selectedData?.is_vendor_account_name_matched) {
      setAutoFillSupplierAccount(true);
    }
    let dynamicOption = dynamicOptions;
    customField &&
      customField?.length > 0 &&
      customField?.map((field) => {
        if (field?.attribute_type !== "textbox") {
          if (
            field?.attribute_type === "predefined" &&
            getPredefineList &&
            getPredefineList[field?.attribute_type]
          ) {
            let convert = JSON.stringify(
              getPredefineList[field?.attribute_type]
            )
              ?.replaceAll('"id":', '"value":')
              .replaceAll('"name":', '"title":')
              .replaceAll('"nested_child":', '"children":');
            let replace = JSON.parse(convert);
            setTreeData(replace);
          } else if (
            field?.attribute_type === "dynamic" &&
            getPredefineList &&
            getPredefineList[field?.attribute_type]
          ) {
            getPredefineList[field?.attribute_type][field?.field_name]?.length >
              0 &&
              getPredefineList[field?.attribute_type][field?.field_name]?.map(
                (name, i, arr) => {
                  let convert = JSON.stringify(arr)
                    ?.replaceAll('"id":', '"value":')
                    .replaceAll('"name":', '"label":');
                  let replace = JSON.parse(convert);
                  dynamicOption = {
                    ...dynamicOption,
                    [field?.field_name]: replace,
                  };
                }
              );
            setDynamicOptions(dynamicOption);
          }
        }
      });
    const org_id = getItem("org_id");
    dispatch(getCatgorisation(CATEGORISATION_CONSTANTS.ACCOUNT_PAYABLE, org_id))
      .then((res) => {
        setTrackingDisabel(res);
      })
      .catch((error) => {
        console.log(error);
      });
    invoiceTriggerValueField();
    getUsersForSelect({ status: null }).then((userRes) => {
      setOwnerList(userRes?.data.result?.data || []);
    });
  }, [customFieldValueLoader]);

  useEffect(() => {
    dispatch(trackingcategories());
    dispatch(getValidationList(CATEGORISATION_CONSTANTS.ACCOUNT_PAYABLE));
    dispatch(
      customExpenseGetList(
        "custom_categories",
        CATEGORISATION_CONSTANTS.ACCOUNT_PAYABLE
      )
    );
    const org_id = localStorage.getItem("org_id");
    dispatch(
      getCatgorisation(CATEGORISATION_CONSTANTS.ACCOUNT_PAYABLE, org_id)
    );
  }, []);

  useEffect(() => {
    if (invoiceFromSupplier && selectedData && suppliers?.result?.length) {
      navigate(location.pathname, { replace: true });
    }
    populateSupplierValues();
  }, [suppliers]);

  useEffect(() => {
    getTriggerField();
  }, []);

  useEffect(() => {
    setEntityHandler();
  }, [entityList, selectedData, componentDisabled, defaultEntityList]);

  useEffect(() => {
    if (
      isErpIntegration &&
      viewEntityField &&
      (selectedData?.entity_id || getArrayLength(entityList) === 1)
    ) {
      if (getArrayLength(entityList) === 1) {
        if (isCommonErpFieldsEnabled()) {
          setEntityId(entityList?.[0]?.value);
        } else {
          fetchEntityMappingStatus(entityList?.[0]?.value);
        }
      } else {
        if (isCommonErpFieldsEnabled()) {
          setEntityId(selectedData?.entity_id);
        } else {
          fetchEntityMappingStatus(selectedData?.entity_id);
        }
      }
    }
  }, [viewEntityField]);

  useEffect(() => {
    const entityToggleON = entityEnabled && entityList?.length > 0;

    if (
      selectedData?.entity_id &&
      !checkIfDefaultEntity(selectedData?.entity_id)
    ) {
      setViewEntityField(entityToggleON);
    } else {
      setViewEntityField(entityToggleON);
    }
  }, [
    selectedData,
    componentDisabled,
    entityEnabled,
    entityList?.length,
    defaultEntityList,
    checkIfDefaultEntity,
  ]);

  const setOwnerCurrency = () => {
    const ownerId = selectedData?.owner
      ? selectedData.owner
      : loggedInUserOrgId;

    const ownerShow =
      ownerList &&
      ownerList.length > 0 &&
      ownerList.some((d) => d?.value === ownerId);

    form.setFieldValue("owner", ownerShow ? ownerId : null);
    if (!selectedData) {
      form.setFieldValue("currency", getDefaultCurrency({ accountDetail }));
    }
  };

  useEffect(() => {
    setOwnerCurrency();
  }, [ownerList]);

  useEffect(() => {
    if (selectedData) {
      saveFormData({ data: selectedData });
    } else {
      form.setFieldValue("currency", getDefaultCurrency({ accountDetail }));
    }
  }, []);

  const getInvoiceDetails = async () => {
    const { lineItemWrapper, invoiceDetails } = await getInvoiceDetailsById(
      selectedData?.id,
      selectedData?.status
    );
    setLineItems(lineItemWrapper);
    setTotalAmount(invoiceDetails.net_amount);
  };

  useEffect(() => {
    if (isLineItemsRevampEnabled() && selectedData) {
      getInvoiceDetails();
    }
  }, [selectedData, activeKey]);

  useEffect(() => {
    populateErpFieldsValues();
  }, [
    chartOfAccounts,
    trackingCategories,
    chartsOfAccountList,
    trackingCategoriesList,
  ]);

  async function populateErpFieldsValues() {
    if (isErpIntegration && selectedData?.erp_tracking_categories?.length) {
      selectedData?.erp_tracking_categories?.forEach((category) => {
        const selectedTrackingCategoryFound = (
          isCommonErpFieldsEnabled()
            ? trackingCategoriesList
            : trackingCategories
        )?.[category?.type]?.find((data) => data.value === category?.value);
        if (selectedTrackingCategoryFound) {
          form.setFieldsValue({
            [`${TRACKING_CATEGORY_KEY_PREFIX}${category.type}`]: category.value,
          });
        }
      });
    }
    if (isErpIntegration && selectedData?.erp_account) {
      const selectedAccountFound = (
        isCommonErpFieldsEnabled() ? chartsOfAccountList : chartOfAccounts
      )?.find((data) => data.erp_id === selectedData?.erp_account);
      if (selectedAccountFound) {
        form.setFieldsValue({
          chartOfAccounts: selectedData?.erp_account,
        });
        const response = await fetchTaxRate(
          form.getFieldValue("entity_id") || selectedData?.entity_id,
          selectedAccountFound
        );
        form.setFieldsValue({
          taxRate:
            response?.find(
              (data) => data?.TaxType === selectedData?.erp_tax_rate
            )?.TaxType || null,
        });
      }
    }
  }

  function handleErpFieldsBeforeReset() {
    if (form.getFieldValue("entity_id") !== selectedData?.entity_id) {
      if (isCommonErpFieldsEnabled()) {
        setEntityId(selectedData?.entity_id || entityList[0]?.value);
      } else {
        fetchEntityMappingStatus(
          selectedData?.entity_id || entityList[0]?.value
        );
      }
    }
  }

  function resetErpFields() {
    populateErpFieldsValues();
    populateSupplierValues();
  }

  function populateSupplierValues() {
    if (selectedData?.supplier?.id) {
      // Don't populate supplier dropdown, if id is not present in the option list.
      const { currentSupplier, currentSupplierCurrentAccountDetails } =
        getSupplierDetails({
          allSupplierDetails: suppliers?.result,
          selectedSupplier: selectedData?.supplier?.id,
          selectedSupplierSelectedAccountDetails:
            selectedData?.supplier_account_details_id,
        });
      if (currentSupplier?.id) {
        form.setFieldsValue({
          [SUPPLIER.name]: currentSupplier.id,
        });
        if (selectedData?.supplier_account_details_id) {
          form.setFieldsValue({
            [SUPPLIER_ACCOUNT.name]:
              currentSupplierCurrentAccountDetails?.id ?? null,
          });
        }
      } else if (
        selectedData?.supplier?.status === "deleted" &&
        activeKey === INVOICE_MANAGEMENT_TAB_KEYS.PAID
      ) {
        const supplierAccountFound =
          selectedData?.supplier.supplier_accounts_with_deleted.find(
            ({ id }) => id === selectedData?.supplier_account_details_id
          );
        form.setFieldsValue({
          [SUPPLIER.name]: selectedData?.supplier?.name ?? null,
        });
        if (selectedData?.supplier_account_details_id) {
          form.setFieldsValue({
            [SUPPLIER_ACCOUNT.name]: supplierAccountFound?.account_name ?? null,
          });
        }
      }
    }
  }

  useEffect(() => {
    const duplicateInvoiceNumber =
      storeDuplicateInvoiceData?.result?.duplicate_invoice_details
        ?.invoice_number;
    const duplicateSupplierId =
      storeDuplicateInvoiceData?.result?.duplicate_invoice_details
        ?.supplier_id;
        
    if (!compareStrings(invoiceNumberCurrentValue, duplicateInvoiceNumber) || !compareStrings(selectedPayeeDetails, duplicateSupplierId)) {
      duplicateInvoiceHandler({
        data: null,
        setError,
        setUploadButtonDisabled,
      });
    } else {
      duplicateInvoiceHandler({
        data: storeDuplicateInvoiceData,
        setError,
        setUploadButtonDisabled,
      });
    }
  }, [invoiceNumberCurrentValue, selectedPayeeDetails]);

  useEffect(() => {
    if (lineItems?.length > 0) {
      let customFields = [];
      const { transformedItem, columnsArray } = transFormDataLineItems(
        lineItems,
        customFields,
        15,
        columns
      );
      setTransformedData(transformedItem);
      setColumns(columnsArray);
    }
  }, [lineItems]);

  useEffect(() => {
    const supplierNameFound = suppliers?.result?.find(
      ({ name }) => name === ocrData?.vendor_name
    );
    const supplierAccountNameFound = suppliers?.result?.find(
      ({ name }) => name === ocrData?.automated_vendor_name
    );
    const ocrSupplier = supplierNameFound
      ? supplierNameFound?.id
      : supplierAccountNameFound
      ? supplierAccountNameFound?.id
      : form.getFieldValue("supplierId");
    supplierAccountHandler(ocrData);

    setAutoFillSupplier(
      supplierAccountNameFound &&
        (ocrData?.is_supplier_matched_by_algorithm ||
          isSupplierIdentifyByOCR(ocrData)) &&
        ocrData?.automated_vendor_name !== null
        ? true
        : false
    );
    form.setFieldsValue({
      supplierId: ocrSupplier,
    });
    if (ocrSupplier) {
      onHandleTrigger(PAYEE, TRIGGER_SUPPLIER_ID);
    }
  }, [ocrData]);

  const showErrorMessage = (errorMessage) => {
    if (errorMessage?.includes("is_invoice_redirect_link_present")) {
      const data = JSON.parse(errorMessage);
      return (
        <>
          <Typography
            variant="body"
            fontSize="14"
            fontStyle="bold"
            text={DUPLICATE_INVOICE() + ": "}
            className="d-inline"
          />
          <When condition={data?.is_invoice_redirect_link_present}>
            <ActionLink
              text={data?.invoice_number}
              onClick={(e) => {
                hideModal();
                closeDataForm();
                setRedirect(true);
                setRedirectData(data);
              }}
            />
          </When>
          <When condition={!data?.is_invoice_redirect_link_present}>
            <Typography
              variant="body"
              fontSize="14"
              text={" " + data?.invoice_number}
              className="d-inline"
            />
          </When>
          <Typography
            variant="body"
            fontSize="14"
            text={" " + data?.message}
            className="d-inline"
          />
        </>
      );
    } else {
      return errorMessage;
    }
  };

  const isNewForm = type == "create";

  const isCreateTab =
    (isNewForm || type === INVOICE_MANAGEMENT_TAB_KEYS.DRAFT) &&
    (!selectedData || !componentDisabled);

  function getLineItemData(data, klooAI) {
    return data.map((item) => ({
      name: item.item,
      description: item.item,
      cost: item.unit_price,
      unit_cost: item.unit_price,
      quantity: item.quantity,
      amount: item.total_amount,
      id: item.id,
      is_ai_generated: item.is_ai_generated || klooAI ? 1 : 0,
    }));
  }

  function automateLineItems(data, klooAI) {
    setViewLineItems(true);
    const newlineItems = getLineItemData(data, klooAI);
    calculateNetAmount(newlineItems);
  }

  const calculateNetAmount = (items) => {
    let netAmount = 0;
    if (!isLineItemsRevampEnabled()) {
      netAmount = roundOff(
        items.reduce((accumulator, item) => accumulator + item.amount, 0)
      );
    } else {
      netAmount = roundOff(
        items.reduce((accumulator, item) => accumulator + item.total_amount, 0)
      );
    }
    setTotalAmount(netAmount);
    form.setFieldValue(IM_CREATE_INVOICE_FORM.netAmount.name, netAmount);
    setLineItems(items);
  };

  const setNewTotalAmount = (items) => {
    const netAmount = roundOff(
      items?.reduce(
        (accumulator, item) => accumulator + item.quantity * item.unit_price,
        0
      )
    );
    setTotalAmount(netAmount);
    form.setFieldValue(IM_CREATE_INVOICE_FORM.netAmount.name, netAmount);
  };

  const handleAutomateLineItems = (data) => {
    setDataPopulatedByAI((prevData) => ({
      ...prevData,
      line_items: "line_items",
    }));
    setKlooAITooltipText(OCR_LINE_ITEMS.REGENERATE);
    lineItems?.forEach((item) => {
      if (item?.id) {
        deleteLineItem(item.id);
      }
    });
    setFieldChanged(true);
    automateLineItems(data ? data : ocrData?.line_items, true);
  };

  async function getOnlyLineItemDataByGenAI(data) {
    let genAIlineItemData = data?.genAIData;
    const currentLineItems = data?.lineItems || [];
    const formCurrentValues = form.getFieldsValue();
    const supplierIdData = formCurrentValues[INVOICE_FORM.SUPPLIER.name];
    if (!supplierIdData) return;
    if (!genAIlineItemData) {
      const reqPayload = {
        module_type: MODULE_TYPES.AP,
        trigger_column: TRIGGER_SUPPLIER_ID,
        trigger_field_id: supplierIdData,
      };
      const payload = await getPayloadForCreateInvoice({
        data: formCurrentValues,
        needNullValues: true,
      });
      if (currentLineItems?.length) {
        payload.line_items = JSON.stringify(
          generateLineItemsPayload([...currentLineItems], isERPMapped)
        );
      }
      reqPayload.payload = payload;
      genAIlineItemData = await customFieldsFromGenAI(reqPayload);
    }

    const updatedLineItems = getLineItemWithSuggestedERPDetails({
      data: genAIlineItemData,
      lineItems: currentLineItems,
    });
    setLineItems(updatedLineItems);
  }

  const handleKlooAIClick = async () => {
    removeLineItems();
    setLoadingLineItems(true);
    if (selectedData?.id) {
      try {
        const res = await getOCRLineItemDetails(selectedData.id);
        handleAutomateLineItems(res.line_items, true);
        showNotification(NOTIFICATION_TYPE.success, OCR_LINE_ITEMS.SUCCES_MSG);
        if (isLineItemsRevampEnabled()) {
          await getOnlyLineItemDataByGenAI({
            lineItems: [...getLineItemData(res?.line_items, true)],
          });
        }
      } catch (error) {
        showNotification(NOTIFICATION_TYPE.error, OCR_LINE_ITEMS.ERROR_MSG);
      } finally {
        setLoadingLineItems(false);
      }
    } else {
      handleAutomateLineItems(null, true);
      showNotification(NOTIFICATION_TYPE.success, OCR_LINE_ITEMS.SUCCES_MSG);
      if (isLineItemsRevampEnabled()) {
        await getOnlyLineItemDataByGenAI({
          lineItems: [...getLineItemData(ocrData?.line_items, true)],
        });
      }

      setLoadingLineItems(false);
    }
  };

  const checkIsUploadExist = (isUpload) => {
    return !isUpload || (isUpload && OCRlineItemsConfig?.is_key_enabled);
  };

  function supplierAccountHandler(data) {
    const vendorAccountId = data?.vendor_account_id;
    const selectedSupplierId = data?.automated_vendor_id;
    const { currentSupplierCurrentAccountDetails } = getSupplierDetails({
      allSupplierDetails: suppliers?.result,
      selectedSupplier: selectedSupplierId,
      selectedSupplierSelectedAccountDetails: vendorAccountId,
    });
    let accountId = null;
    if (currentSupplierCurrentAccountDetails) {
      accountId = currentSupplierCurrentAccountDetails?.id;
      setAutoFillSupplierAccount(data?.is_vendor_account_name_matched);
    } else {
      setAutoFillSupplierAccount(false);
      accountId = null;
    }

    if (vendorAccountId) {
      form.setFieldsValue({
        [SUPPLIER_ACCOUNT.name]: accountId,
      });
    }
  }

  const saveFormData = ({ isUpload = false, data }) => {
    let defaultFormValue = {
      ...initialFormValue,
    };
    let tempDataPopulatedByAI = {};
    const createObj = CREATE_FORM_OBJECT({ isUpload, data, form });
    defaultFormValue = {
      ...defaultFormValue,
      ...createObj.formObject,
    };
    tempDataPopulatedByAI = {
      ...tempDataPopulatedByAI,
      ...createObj.tempAIObject,
    };

    try {
      if (isUpload) {
        setOcrData(data);
        if (data?.entity) {
          if (data?.entity?.id && viewEntityField && isErpIntegration) {
            if (isCommonErpFieldsEnabled()) {
              setEntityId(data?.entity?.id);
            } else {
              fetchEntityMappingStatus(data?.entity?.id);
            }
          }
        }
        const firstGenAITrigger = GEN_AI_IM_TRIGGER_MAPPING.find(
          ({ value }) => !!defaultFormValue[value]
        );
        if (firstGenAITrigger) {
          autofillCustomFieldsByAi(
            {
              triggerKey: firstGenAITrigger.aiMappingKey,
              triggerValue: defaultFormValue[firstGenAITrigger?.value],
            },
            defaultFormValue,
            tempDataPopulatedByAI,
            firstGenAITrigger.mappingKey,
            defaultFormValue
          );
        }
        setDataPopulatedByAI({});
        setDataPopulatedByAI(tempDataPopulatedByAI);
        setDocIdFile(data?.document_id);
      } else {
        if (data?.invoice_attachment_url) {
          defaultFormValue.file = data?.invoice_attachment_url
            ? [data?.invoice_attachment_url]
            : [];
          setPreviewImage({
            isImg: !data?.invoice_attachment_url
              ?.toLowerCase()
              ?.includes(".pdf"),
            url: data?.invoice_attachment_url || "",
          });
        }
        //Custom Field Data Set
        let SelectorValue = {};
        if (data?.custom_expense_values?.length > 0) {
          customField &&
            customField?.length > 0 &&
            customField?.map((field, i) => {
              data?.custom_expense_values?.map((item, i) => {
                if (field?.id === item?.custom_field_id) {
                  if (field?.attribute_type === "dynamic") {
                    getPredefineList &&
                      getPredefineList[field?.attribute_type] &&
                      getPredefineList[field?.attribute_type][field?.field_name]
                        ?.length > 0 &&
                      getPredefineList[field?.attribute_type][
                        field?.field_name
                      ]?.map((name, i) => {
                        if (name?.id === item?.value) {
                          defaultFormValue[field?.field_name] = {
                            label: name?.name,
                            value: item?.value ? item?.value : null,
                          };
                          SelectorValue = {
                            ...SelectorValue,
                            [field?.field_name]: item?.value,
                          };
                        }
                      });
                  } else {
                    defaultFormValue[field?.field_name] = item?.value
                      ? item?.value
                      : null;
                  }
                }
              });
            });
        }
        setCreateSelector(SelectorValue);

        //PoNumber Set
        defaultFormValue.poNumber = !!data.isSplit
          ? MULTIPLE_PO_DROPDOWN_OPTION_ID
          : data?.poNumber || null;

        //tax solution set
        defaultFormValue.taxSolution = data?.tax_solution_id;
        //Zero Category Value
        const categoryValue =
          xeroCategory &&
          xeroCategory?.result?.some(
            (data) => data.id == data?.kloo_category_id
          );
        defaultFormValue.xeroCategory = categoryValue
          ? data?.kloo_category_id
          : null;

        //Customer Value
        const xeroValue =
          xeroCustomerContactID &&
          xeroCustomerContactID?.result?.some(
            (data) => data.id == data?.account_integration_dataset_contact_id
          );
        defaultFormValue.xeroCustomerContactID =
          data?.account_integration_dataset_contact_id && xeroValue
            ? data?.account_integration_dataset_contact_id
            : null; // need to check from where to get this data

        //tracking Categories Value
        const tracking1 =
          initialcategories &&
          initialcategories[0]?.nested_child?.some(
            (data) =>
              data.id == data?.acc_integr_dataset_tracking_categories_id_first
          );
        const tracking2 =
          initialcategories &&
          initialcategories[1]?.nested_child?.some(
            (data) =>
              data.id == data?.acc_integr_dataset_tracking_categories_id_second
          );
        defaultFormValue.acc_integr_dataset_tracking_categories_id_first =
          data?.acc_integr_dataset_tracking_categories_id_first && tracking1
            ? data?.acc_integr_dataset_tracking_categories_id_first
            : null;
        defaultFormValue.acc_integr_dataset_tracking_categories_id_second =
          data?.acc_integr_dataset_tracking_categories_id_second && tracking2
            ? data?.acc_integr_dataset_tracking_categories_id_second
            : null;

        if (data?.ai_generated_values) {
          setDataPopulatedByAI(data?.ai_generated_values);
        }

        if (!defaultFormValue.vatNumber) {
          defaultFormValue.vatNumber = data?.supplier?.vat_number;
        }
      }

      if (data?.line_items?.length && checkIsUploadExist(isUpload)) {
        defaultFormValue.itemSwitch = true;
        automateLineItems(data?.line_items, true);
        setTotalAmount(data?.net_amount);
        if (isUpload) {
          setKlooAITooltipText(OCR_LINE_ITEMS.REGENERATE);
        }
      } else {
        defaultFormValue.itemSwitch = false;
        setViewLineItems(false);
      }

      setDefaultFormValue(defaultFormValue);
      form.setFieldsValue({
        ...defaultFormValue,
      });
    } catch (err) {}
  };

  const invoiceTriggerValueField = () => {
    dispatch(handleMappingListing("invoice"))
      .then((res) => {
        const valueId = res?.data?.result?.field_type_id;
        const valueFieldId = res?.data?.result?.field_id;
        dispatch(invoiceSupplierList("invoice")).then((res) => {
          const selectedDataMapped =
            res?.data?.result &&
            res?.data?.result.find(
              (supplierData) => supplierData.value === valueId
            );
          if (selectedDataMapped.type === "categories") {
          } else {
            selectedDataMapped &&
              selectedDataMapped.options &&
              selectedDataMapped.options.length > 0 &&
              selectedDataMapped.options.map((ow) => {
                if (ow.id === valueFieldId) {
                  if (ow.attribute_type == "dynamic") {
                    setMappingDynamic(ow);
                  }
                }
              });
          }
        });
      })
      .catch((err) => {});
  };

  const newCreatedDynamicSelect = (customField) => {
    const selectedFielD = customField?.find(
      (d) => d?.custom_field_id === mappingDynamic?.value
    );
    return newCreatedField?.includes(selectedFielD?.value) ? 1 : 0;
  };

  const getTriggerField = async () => {
    try {
      const response = await getAiTriggers({
        module_type: MODULE_TYPES.AP,
      });
      const { data = [] } = response;
      setTriggerField(data);
    } catch (error) {
      console.log(error);
    }
  };

  function setEntityHandler() {
    let entity_id = null;
    const entityExist = entityList?.some(
      (item) => item?.value === selectedData?.entity_id
    );
    if (getArrayLength(entityList) === 1) {
      setEntityFieldDisabled(true);
      if (selectedData?.entity_id && entityExist) {
        entity_id = selectedData?.entity_id;
      } else {
        entity_id = entityList[0].value;
      }
    } else if (selectedData?.entity_id && entityExist) {
      entity_id = selectedData?.entity_id;
    }

    if (checkIfDefaultEntity(selectedData?.entity_id)) {
      if (getArrayLength(entityList) === 1) {
        form.setFieldsValue({
          entity_id: entityList[0].value,
        });
      } else {
        form.setFieldsValue({
          entity_id: null,
        });
      }
    } else {
      form.setFieldsValue({
        entity_id,
      });
    }
  }

  const listSubmittedAccount = () => {
    listSubmittedAccount
      ? listSubmittedAccount(currentPage)
      : dispatch(getAccountPayableList("submitted", currentPage));
  };

  const handleChange = (key, options) => {
    setAutoFillSupplier(false);
    setAutoFillSupplierAccount(false);
  };

  function handleChangeSupplierAccount(value) {
    setAutoFillSupplierAccount(false);
  }

  const listUpdatedData = () => {
    if (reloadTable) {
      reloadTable();
      return;
    }
    if (tabState === "1") {
      listSubmittedAccount();
    }
  };

  const getPayloadForLineItems = (data) => {
    return JSON.stringify(
      generateLineItemsPayload(data || lineItems, isERPMapped)
    );
  };

  const getPayloadForCreateInvoice = async ({
    data,
    needNullValues = false,
    customFieldNullValues = false,
  }) => {
    let selectCustomFields = [];
    customField &&
      customField?.length > 0 &&
      customField?.map((field, i) => {
        if (data?.[field?.field_name] || customFieldNullValues) {
          if (field?.attribute_type === "dynamic") {
            selectCustomFields.push({
              custom_field_id: field?.id,
              value: data[field?.field_name]?.value
                ? data[field?.field_name]?.value
                : "",
            });
          } else {
            selectCustomFields.push({
              custom_field_id: field?.id,
              value: data[field?.field_name]
                ? data[field?.field_name]?.value || data[field?.field_name]
                : "",
            });
          }
        }
      });
    const requestPayload = {
      invoice_number: data.invoiceNumber,
      invoice_date: data.invoiceDate
        ? moment(data.invoiceDate).format("YYYY-MM-DD")
        : null,
      due_date: data.dueDate ? moment(data.dueDate).format("YYYY-MM-DD") : null,
      currency: data.currency,
      amount: getOnlyAmount(data.amount),
      custom_expense_fields: JSON.stringify(selectCustomFields),
      is_supplier_matched_by_algorithm: autoFillSupplier ? 1 : 0,
      is_vendor_account_name_matched: autoFillSupplierAccount ? 1 : 0,
      // multi PO dropdown option
      purchaseOrders: JSON.stringify([]),
      isSplit: 0,
    };
    if (isAutoInvoiceConfirmed()) {
      requestPayload.purchase_order_ids = JSON.stringify([]);
    }
    if (needNullValues || data?.entity_id || defaultEntityList?.[0]?.id) {
      requestPayload.entity_id = data?.entity_id || defaultEntityList?.[0]?.id;
    }
    if (needNullValues || data?.supplierId) {
      requestPayload.supplier_id = data?.supplierId || null;
    }
    if (data?.[SUPPLIER_ACCOUNT.name]) {
      requestPayload.supplier_account_details_id = data[SUPPLIER_ACCOUNT.name];
    }
    if (data?.acc_integr_dataset_tracking_categories_id_first) {
      requestPayload.acc_integr_dataset_tracking_categories_id_first =
        data.acc_integr_dataset_tracking_categories_id_first;
    }
    if (data?.acc_integr_dataset_tracking_categories_id_second) {
      requestPayload.acc_integr_dataset_tracking_categories_id_second =
        data.acc_integr_dataset_tracking_categories_id_second;
    }
    if (data.xeroCustomerContactID) {
      requestPayload.account_integration_dataset_contact_id =
        data.xeroCustomerContactID;
    }
    if (data.xeroCategory) {
      requestPayload.kloo_category_id = data.xeroCategory;
    }
    if (data.serviceComDate) {
      requestPayload.service_commencement_date = moment(
        data.serviceComDate
      ).format("YYYY-MM-DD");
    }
    if (data.serviceDuration) {
      requestPayload.service_duration = data.serviceDuration;
    }
    if (data.serviceDurationUnit) {
      requestPayload.service_duration_unit = data.serviceDurationUnit;
    }
    if (data.apDescription) {
      requestPayload.description = data.apDescription;
    }
    if (data.owner) {
      requestPayload.owner = data.owner;
    }
    if (data.vatRate) {
      requestPayload.vat_rate = data.vatRate;
    }
    if (data.vatNumber) {
      requestPayload.vat_number = data.vatNumber;
    }
    // multi PO dropdown option
    const selectedPODetail = checkPOExist({ poNumber: data.poNumber });

    const isSavedPO = data?.poNumber === selectedData?.poNumber;
    if (
      data.poNumber &&
      data.poNumber !== MULTIPLE_PO_DROPDOWN_OPTION_ID &&
      (selectedPODetail || isSavedPO)
    ) {
      requestPayload.purchaseOrders = JSON.stringify([data.poNumber]);
      if (isAutoInvoiceConfirmed() && selectedPODetail) {
        requestPayload.purchase_order_ids = JSON.stringify([
          {
            id: selectedPODetail?.id || null,
            allocated_amount: selectedPODetail?.remaining_amount_PO,
            currency: selectedPODetail?.currency || null,
          },
        ]);
      }
    }
    if (data.taxCode) {
      requestPayload.tax_code_id = data.taxCode;
    }
    if (data.taxSolution && isERPMapped && isSageIntactPlatform) {
      requestPayload.tax_solution_id = data.taxSolution;
    }
    if (data.poNumber && data.poNumber === MULTIPLE_PO_DROPDOWN_OPTION_ID) {
      requestPayload.isSplit = 1;
      if (selectedData?.matchedPoNumbers?.length && isAutoInvoiceConfirmed()) {
        requestPayload.purchaseOrders = JSON.stringify(
          selectedData?.matchedPoNumbers
        );
      }
    }
    if (data?.taxAmount) {
      requestPayload.tax_amount = data.taxAmount;
    } else {
      if (isStatusToBeUpdatedEnabled()) {
        requestPayload.tax_amount = 0;
      }
    }
    if (data?.netAmount) {
      requestPayload.net_amount = data.netAmount;
    } else {
      if (isStatusToBeUpdatedEnabled()) {
        requestPayload.net_amount = 0;
      }
    }
    if (deletedLineItemIdArray?.current?.length) {
      requestPayload.deleted_line_items = JSON.stringify(
        deletedLineItemIdArray.current
      );
    }
    if (isErpIntegration && data?.chartOfAccounts) {
      requestPayload.erp_account = data.chartOfAccounts;
    }
    if (isErpIntegration && data?.taxRate) {
      requestPayload.erp_tax_rate = data.taxRate;
    }
    const trackingCategorieskeys = Object.keys(data).filter((key) =>
      key.startsWith(TRACKING_CATEGORY_KEY_PREFIX)
    );
    if (isErpIntegration) {
      requestPayload.erp_tracking_categories = trackingCategorieskeys?.length
        ? JSON.stringify(
            trackingCategorieskeys
              .filter((key) => data[key] !== null)
              .map((key) => ({
                type: key.split("_")[1],
                value: data[key],
              }))
          )
        : [];
    }
    requestPayload.document_id = docIdFile;
    requestPayload.is_custom_expense_created_dynamically =
      newCreatedDynamicSelect(selectCustomFields);
    if (Object.keys(dataPopulatedByAI).length > 0) {
      requestPayload.ai_values = JSON.stringify(dataPopulatedByAI);
    }
    return requestPayload;
  };

  const createInvoice = async ({ data, isDraft }) => {
    let requestPayload = await getPayloadForCreateInvoice({ data });
    requestPayload.line_items = lineItems?.length
      ? getPayloadForLineItems()
      : [];
    let file = selectedFileList?.selectedFileList;

    let createfileOutput = null;
    if (file?.length) {
      if (isFileCompressionEnabled()) {
        if (previewImage?.isImg) {
          if (file?.[0]?.originFileObj) {
            file = await compressImage(
              selectedFileList?.selectedFileList[0].originFileObj,
              2
            );
            createfileOutput = file;
          }
        } else {
          // Will add PDF compression later
          if (file?.[0]?.originFileObj) {
            file = file[0].originFileObj;
            createfileOutput = file;
          }
        }
      } else {
        createfileOutput = file[0]?.originFileObj;
      }
    }

    if (isDraft) {
      if (createfileOutput) {
        requestPayload.invoice_attachment = createfileOutput;
      } else {
        delete requestPayload.invoice_attachment;
      }
      Object.keys(requestPayload).map((key) => {
        if (!requestPayload[key]) {
          delete requestPayload[key];
        }
      });
      requestPayload = convertJSObjectToFormData(requestPayload);
      dispatch(loadingStart());
      try {
        if (selectedData) {
          if (!createfileOutput && isAttachmentDelete) {
            await deleteDraftAttachment({ id: selectedData?.id });
          }
          const result = await updateDraftInvoice({
            data: requestPayload,
            id: selectedData?.id,
          });
        } else {
          const result = await createDraftInvoice({
            data: requestPayload,
          });
        }
        onSuccessCreateInvoice(isDraft);
        showNotification(
          NOTIFICATION_TYPE.success,
          selectedData
            ? DRAFT_INVOICE_SUCCESS_MSG_UPDATE
            : DRAFT_INVOICE_SUCCESS_MSG_CREATE
        );
        dispatch(loadingStop());
      } catch (err) {
        setError(err?.response?.data?.message);
        scrollToTop(ref);
        dispatch(loadingStop());
      }
    } else {
      if (selectedData) {
        dispatch(loadingStart());
        await editPaymentAP(requestPayload, selectedData?.id, file);
        dispatch(loadingStop());
      } else {
        return dispatch(createPaymentAP(requestPayload, file, isDraft));
      }
    }
  };

  const onSuccessCreateInvoice = (isDraft = false) => {
    setNewCreatedField([]);
    setMappingDynamic({});
    form.resetFields();
    listUpdatedData();
    setDocIdFile("");
    getUpdatedSuppliers();
    closeDataForm();
  };
  const handleCreatePayment = async ({ isDraft }) => {
    if (itemSwitch || viewLineItems) {
      const itemListError = validateItemListArray();
      form.setFields([
        {
          name: "lineItems",
          errors: itemListError ? [itemListError] : [],
        },
      ]);
      if (itemListError) {
        return;
      }
    }
    setError("");

    if (isDraft) {
      await createInvoice({ data: form.getFieldsValue(), isDraft });
    } else {
      const taxSolutionValid = await validateTaxSolution();
      if (!taxSolutionValid) {
        return;
      }
      form
        .validateFields()
        .then(async (data) => {
          await createInvoice({ data, isDraft });
        })
        .then(() => {
          onSuccessCreateInvoice(isDraft);
        })
        .catch((err) => {
          scrollToTop(ref);
          setError(err?.response?.data?.message);
          dispatch(loadingStop());
        });
    }
  };

  function fieldCategoryHandler(index) {
    switch (index) {
      case 0:
        return "first";
      case 1:
        return "second";

      default:
        return "first";
    }
  }
  const userType = useSelector(
    (state) => state && state?.menuReducer && state?.menuReducer?.userType
  );

  const isDisabledOnEdit =
    editMode === "edit" ? componentDisabled : !componentDisabled;
  const isXeroToggleEnabled = toggle_status?.xero ? isDisabledOnEdit : true;
  const isCategoryToggleEnabled = toggle_status?.category
    ? isDisabledOnEdit
    : true;

  const navigate = useNavigate();

  const checkPOExist = ({ poNumber }) => {
    const poExist = approvedPOs.find((po) => po.value === poNumber);
    return poExist;
  };

  function autoPopulateChartOfAccounts(
    customExpenseValues,
    defaultFormValue,
    tempAiData
  ) {
    const isAccountFieldAiValueExist = customExpenseValues?.find(
      ({ custom_field_id }) =>
        custom_field_id === IM_CREATE_INVOICE_FORM.chartOfAccounts.name
    );
    if (isAccountFieldAiValueExist) {
      const selectedAccount = handleErpFieldsAutoPopulate({
        value: isAccountFieldAiValueExist?.value,
        options: isCommonErpFieldsEnabled()
          ? chartsOfAccountList
          : chartOfAccounts,
      });
      if (selectedAccount) {
        defaultFormValue[IM_CREATE_INVOICE_FORM.chartOfAccounts.name] =
          selectedAccount?.erp_id || null;
        defaultFormValue[IM_CREATE_INVOICE_FORM.chartOfAccounts.name] &&
          (tempAiData[IM_CREATE_INVOICE_FORM.chartOfAccounts.name] =
            IM_CREATE_INVOICE_FORM.chartOfAccounts.name);
        autoPopulateTaxRate(
          customExpenseValues,
          defaultFormValue,
          tempAiData,
          selectedAccount
        );
      }
    }
  }

  async function autoPopulateTaxRate(
    customExpenseValues,
    defaultFormValue,
    tempAiData,
    selectedAccount
  ) {
    const taxRateResponse = await fetchTaxRate(
      form.getFieldValue("entity_id"),
      selectedAccount
    );
    const isTaxRateAiValueExist = customExpenseValues?.find(
      ({ custom_field_id }) =>
        custom_field_id === IM_CREATE_INVOICE_FORM.taxRate.name
    );
    if (isTaxRateAiValueExist) {
      defaultFormValue[IM_CREATE_INVOICE_FORM.taxRate.name] =
        handleErpFieldsAutoPopulate({
          value: isTaxRateAiValueExist?.value,
          options: taxRateResponse,
        })?.TaxType || null;
      defaultFormValue[IM_CREATE_INVOICE_FORM.taxRate.name] &&
        (tempAiData[IM_CREATE_INVOICE_FORM.taxRate.name] =
          IM_CREATE_INVOICE_FORM.taxRate.name);
    }
    form.setFieldsValue({
      ...defaultFormValue,
    });
    setDataPopulatedByAI({ ...tempAiData });
  }

  function autoPopulateTrackingCategories(
    customExpenseValues,
    defaultFormValue,
    tempAiData
  ) {
    const trackingCategorieskeys = Object.keys(form.getFieldsValue()).filter(
      (key) => key.startsWith(TRACKING_CATEGORY_KEY_PREFIX)
    );
    const isTrackingCategoriesAiValuesExists = customExpenseValues?.filter(
      (el) => trackingCategorieskeys.find((key) => key === el.custom_field_id)
    );
    if (isTrackingCategoriesAiValuesExists?.length > 0) {
      isTrackingCategoriesAiValuesExists?.forEach((item, i) => {
        defaultFormValue[item.custom_field_id] =
          (isCommonErpFieldsEnabled()
            ? trackingCategoriesList
            : trackingCategories)[item?.custom_field_id.split("_")[1]]?.find(
            (data) => data.value === item?.value
          )?.value || null;
        defaultFormValue[item.custom_field_id] &&
          (tempAiData[item.custom_field_id] = item.custom_field_id);
      });
    }
  }

  const handleErpFieldsAutoPopulate = ({ value, options }) => {
    const fieldData = options?.find((data) => data.erp_id === value);
    return fieldData || false;
  };

  const autofillCustomFieldsByAi = async (
    supplierId,
    defaultFormValue,
    tempAiData,
    key,
    formCurrentValues
  ) => {
    // debugger;
    if (key && tempAiData?.custom_expense_fields) {
      delete tempAiData.custom_expense_fields[key];
    } else {
      tempAiData.custom_expense_fields = {};
    }
    const reqPayload = {
      module_type: MODULE_TYPES.AP,
    };
    if (!supplierId) return;
    reqPayload.trigger_column = supplierId?.triggerKey;
    reqPayload.trigger_field_id = supplierId?.triggerValue;
    const payload = await getPayloadForCreateInvoice({
      data: formCurrentValues,
      needNullValues: true,
    });
    if (lineItems?.length) {
      payload.line_items = getPayloadForLineItems();
    }
    reqPayload.payload = payload;

    if (!!formCurrentValues) {
      defaultFormValue = formCurrentValues;
    }
    try {
      const data = await customFieldsFromGenAI(reqPayload);
      const customExpenseValues = data?.[0]?.custom_fields;
      let selectorValue = {};
      if (supplierId?.triggerKey === TRIGGER_SUPPLIER_ID) {
        defaultFormValue.supplierId = supplierId?.triggerValue;
        const { currentSupplierCurrentAccountDetails } = getSupplierDetails({
          allSupplierDetails: suppliers?.result,
          selectedSupplier: supplierId?.triggerValue,
          selectedSupplierSelectedAccountDetails:
            defaultFormValue.supplierAccountId,
        });
        defaultFormValue[SUPPLIER_ACCOUNT.name] =
          currentSupplierCurrentAccountDetails
            ? currentSupplierCurrentAccountDetails.id
            : undefined;
      }

      if (customExpenseValues?.length > 0) {
        customField &&
          customField?.forEach((field, i) => {
            customExpenseValues?.forEach((item, i) => {
              const itemValue = item?.value || null;
              const predefineList =
                getPredefineList?.[field?.attribute_type]?.[
                  field?.field_name
                ] || [];

              const matchingName = predefineList.find(
                (name) => name?.id === item?.value
              );

              if (matchingName) {
                defaultFormValue[field.field_name] =
                  field?.attribute_type === FIELD_TYPE_CONFIG.dynamic
                    ? {
                        label: matchingName.name,
                        value: itemValue,
                      }
                    : itemValue;
                tempAiData.custom_expense_fields[field.field_name] =
                  field.field_name;
              }
              selectorValue = {
                ...selectorValue,
                [field.field_name]: itemValue,
              };
            });
          });
        const isTaxSolutionFieldFilledByAI = customExpenseValues?.find(
          ({ custom_field_id }) => custom_field_id === TAX_SOLUTION.name
        );
        if (isTaxSolutionFieldFilledByAI) {
          defaultFormValue[TAX_SOLUTION.name] =
            isTaxSolutionFieldFilledByAI.value;
          tempAiData[TAX_SOLUTION.name] = TAX_SOLUTION.name;
        }
        const isTaxCodeFieldFilledByAI = customExpenseValues?.find(
          ({ custom_field_id }) =>
            custom_field_id === GEN_AI_IM_TRIGGER_MAPPING[2].aiMappingKey
        );
        if (isTaxCodeFieldFilledByAI) {
          defaultFormValue[GEN_AI_IM_TRIGGER_MAPPING[2].value] =
            isTaxCodeFieldFilledByAI?.value;
          tempAiData[GEN_AI_IM_TRIGGER_MAPPING[2].aiMappingKey] =
            GEN_AI_IM_TRIGGER_MAPPING[2].aiMappingKey;
          defaultFormValue[VAT_RATE.name] =
            handleVatRateField({
              taxCodeValue: isTaxCodeFieldFilledByAI.value,
            })?.field_value || null;
          tempAiData[VAT_RATE.name] = VAT_RATE.name;
        }
        autoPopulateChartOfAccounts(
          customExpenseValues,
          defaultFormValue,
          tempAiData
        );
        autoPopulateTrackingCategories(
          customExpenseValues,
          defaultFormValue,
          tempAiData
        );
      }
      setCreateSelector(selectorValue);
      defaultFormValue.xeroCategory =
        data?.[0]?.custom_category && data?.[0]?.custom_category[0]?.value;
      form.setFieldsValue({
        ...defaultFormValue,
      });
      if (isLineItemsRevampEnabled()) {
        await getOnlyLineItemDataByGenAI({
          genAIData: data,
          lineItems: [...lineItems],
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataPopulatedByAI({ ...tempAiData });
    }
  };

  const onHandleTrigger = (key, fieldName, type, dynamicCreationValue) => {
    const defaultFormValue = {
      ...initialFormValue,
      supplierAccountId: undefined,
    };
    if (fieldName === GEN_AI_IM_TRIGGER_MAPPING[0].value && isErpIntegration) {
      form.setFieldsValue({
        supplierId: null,
      });
      if (isCommonErpFieldsEnabled()) {
        setEntityId(type);
      } else {
        fetchEntityMappingStatus(type);
      }
      const trackingCategorieskeys = Object.keys(form.getFieldsValue()).filter(
        (key) => key.startsWith(TRACKING_CATEGORY_KEY_PREFIX)
      );
      trackingCategorieskeys?.map((key) => {
        inputChangedFromAiValue(key);
      });
      inputChangedFromAiValue(IM_CREATE_INVOICE_FORM.chartOfAccounts.name);
      inputChangedFromAiValue(IM_CREATE_INVOICE_FORM.taxRate.name);
    }
    const formCurrentValues = form.getFieldsValue();
    let finalValue = "";
    if (!fieldName) return;
    const generalFormFields = GEN_AI_IM_TRIGGER_MAPPING.find(
      ({ mappingKey }) => mappingKey === key
    );
    const finalFieldKey = generalFormFields?.aiMappingKey || fieldName;
    const finalFormFieldKey = generalFormFields?.value || key;
    let triggerValue = "";
    if (triggerField?.includes(finalFieldKey)) {
      switch (fieldName) {
        case TRIGGER_SUPPLIER_ID:
          form.setFields([
            {
              name: SUPPLIER_ACCOUNT.name,
              value: undefined,
            },
          ]);
          triggerValue = form.getFieldValue(SUPPLIER.name) || "";
          break;
        default:
          triggerValue =
            type === DYNAMIC_ATTRIBUTE_TYPE
              ? formCurrentValues[key]?.value || dynamicCreationValue
              : formCurrentValues[finalFormFieldKey];
      }
      finalValue = {
        triggerKey: finalFieldKey,
        triggerValue,
      };
      autofillCustomFieldsByAi(
        finalValue,
        defaultFormValue,
        dataPopulatedByAI,
        key,
        formCurrentValues
      );
    }
  };

  async function fetchEntityMappingStatus(entityId) {
    const trackingCategorieskeys = Object.keys(form.getFieldsValue()).filter(
      (key) => key.startsWith(TRACKING_CATEGORY_KEY_PREFIX)
    );
    trackingCategorieskeys?.map((key) => {
      form.setFieldValue(key, null);
    });
    setChartOfAccounts([]);
    setTaxRateList([]);
    setTrackingCategories(null);

    setConnectedErpPlatform("");
    const response = await checkEntityMappingStatus(entityId);
    if (response?.mapped) {
      form.setFieldsValue({
        [SUPPLIER.name]: null,
        chartOfAccounts: null,
        taxRate: null,
      });
      setIsEntityMapped(true);
      fetchErpAccounts(entityId);
      fetchTrackingCategories(entityId);
      getUpdatedSuppliers(entityId);
    } else {
      setIsEntityMapped(false);
      getUpdatedSuppliers();
    }
  }

  async function fetchErpAccounts(entityId) {
    const response = await getErpAccounts(entityId);
    if (response?.length) {
      setChartOfAccounts(
        response?.slice(1)?.map((data) => {
          return { ...data, label: data.name, value: data.erp_id };
        })
      );
      setConnectedErpPlatform(response[0]?.integration_name?.toLowerCase());
    }
  }

  async function fetchTrackingCategories(entityId) {
    const response = await getTrackingCatgories(entityId);
    setTrackingCategories(response);
  }

  async function fetchTaxRate(entityId, selectedAccount) {
    const response = await getTaxRateList(
      entityId,
      selectedAccount?.classification
    );
    setTaxRateList(
      response?.map((data) => {
        return { ...data, label: data?.description, value: data?.TaxType };
      })
    );
    return response;
  }

  async function handleChartOfAccountChange(e, options) {
    const selectedEntity = form.getFieldValue("entity_id");
    form.setFieldValue(IM_CREATE_INVOICE_FORM.taxRate.name, null);
    setTaxRateList([]);
    fetchTaxRate(selectedEntity, options);
  }

  function handleChartOfAccountClear() {
    form.setFieldValue(IM_CREATE_INVOICE_FORM.taxRate.name, null);
    setTaxRateList([]);
  }

  const isInvoiceWithoutAttachment = () => {
    if (
      form.getFieldValue("file") === undefined ||
      form.getFieldValue("file")?.length === 0 ||
      form.getFieldValue("file")?.[0]?.status === INVOICE_UPLOADING_STATUS
    ) {
      return true;
    } else {
      return false;
    }
  };

  const currentSupplierAllAccountDetailsOptions = useMemo(() => {
    const { currentSupplierAllAccountDetails } = getSupplierDetails({
      allSupplierDetails: suppliers?.result,
      selectedSupplier: selectedPayeeDetails,
    });
    return currentSupplierAllAccountDetails.map(({ id, account_name }) => ({
      value: id,
      label: account_name,
    }));
  }, [suppliers, selectedPayeeDetails]);

  /**
   * line item code - start
   */

  const onLineItemSave = React.useCallback((items) => {
    calculateNetAmount(items);
    if (!fieldChanged) {
      setFieldChanged(true);
    }
    inputChangedFromAiValue("line_items");
  }, []);

  const removeLineItems = () => {
    if (lineItems?.length) {
      setLineItems([]);
      setTotalAmount(null);
      inputChangedFromAiValue("line_items");
    }
    form.setFieldValue(IM_CREATE_INVOICE_FORM.netAmount.name, null);
    setFieldChanged(true);
    if (!isLineItemsRevampEnabled()) {
      deletedLineItemIdArray.current = [];
    }
  };

  const deleteLineItem = (id) => {
    if (!deletedLineItemIdArray?.current?.includes(id)) {
      deletedLineItemIdArray.current.push(id);
    }
  };

  const validateItemListArray = () => {
    let err = "";
    if (lineItems?.length) {
      for (let i = 0; i < lineItems.length; i++) {
        const item = lineItems[i];
        if (!item.name && !item.description) {
          err = LINE_ITEMS_ERRORS.name;
          break;
        }
      }
    }
    return err;
  };

  const resetForm = () => {
    let data = form.getFieldsValue();
    let createNewObj = {};
    Object.keys(data).map((key) => {
      createNewObj[key] = null;
    });
    setDefaultFormValue(createNewObj);
    form.setFieldsValue(createNewObj);
    setOwnerCurrency();
  };

  const renderBody = ({
    label,
    key,
    record,
    index,
    defaultRender,
    expandRow,
    isRowExpanded,
  }) => {
    return key === "cost" ? (
      <>
        {record?.cost || record.cost === 0
          ? `${selectedCurrency?.symbol}${renderAmount(
              record.cost,
              "commaWithDecimal"
            )}`
          : ""}
      </>
    ) : key === "amount" ? (
      <>
        {record?.amount || record.amount === 0
          ? `${selectedCurrency?.symbol}${renderAmount(
              record.amount,
              "commaWithDecimal"
            )}`
          : ""}
      </>
    ) : (
      defaultRender({ label, key, record, index, defaultRender })
    );
  };

  const finalColumns = [...ITEM_TABLE_COLUMN, ...columns];

  const showItemListModal = () => {
    showModal(MODAL_TYPES.ITEM_LIST_MODAL, {
      title: PURCHASE_ORDER_CREATE_FROM.itemSwitch.label,
      transformedData: transformedData,
      billOfMaterials: lineItems,
      renderBody: renderBody,
      finalColumns: finalColumns,
      onCancel: hideModal,
    });
  };

  const removePOandCreateDraft = () => {
    form.setFieldValue(PO_NUMBER.name, null);
    handleCreatePayment({ isDraft: true });
    hideModal();
    closeDataForm();
  };

  const handleDraftCreate = () => {
    if (!isAutoInvoiceConfirmed()) {
      handleCreatePayment({ isDraft: true });
      return;
    }
    if (poNumberField) {
      showModal(MODAL_TYPES.PO_SELECTED_DRAFT, {
        createDraft: removePOandCreateDraft,
        onCancel: hideModal,
      });
    } else {
      handleCreatePayment({ isDraft: true });
    }
  };

  /**
   * line item code - end
   */

  const fetchAuditHistory = async ({
    page,
    appliedFilter,
    filterItems,
    setFilterItems,
    searchValue,
  }) => {
    const filtersUrl = generateEncodedURI(appliedFilter, filterItems);
    const searchUrl = generateEncodedSearchURI(searchValue);
    const data = await getAuditLogByInvoiceId({
      id: selectedData?.id,
      page,
      filtersUrl,
      searchUrl,
    });
    if (page === 1) {
      setFilterItems(data.filters);
    }
    return data;
  };

  const viewAuditLogModal = () => {
    showModal(MODAL_TYPES.AUDIT_MODAL, {
      title: IM_AUDIT_LOG_MODAL.TITLE,
      showDivider: false,
      showSearch: true,
      onCancel: hideModal,
      fetchAuditHistory,
      getColumns: () => IM_AUDIT_LOG_MODAL.TABLE_COLUMN,
      addComment: postComment,
      showCommentSection: true,
      eventType: "IM",
      eventId: selectedData?.id,
    });
  };

  const Tooltip = ({ children }) => (
    <CommonToolTip
      overlayClassName=""
      placement="top"
      title={CREATE_INVOICE_BTN_DISABLED_TOOLTIP}
      trigger={
        isInvoiceWithoutAttachment() &&
        form.getFieldValue("file")?.[0]?.status !== INVOICE_UPLOADING_STATUS
          ? "hover"
          : ""
      }
    >
      <span
        className="create-update-btn-tooltip"
        style={{
          cursor: isInvoiceWithoutAttachment() ? "not-allowed" : "pointer",
        }}
      >
        {children}
      </span>
    </CommonToolTip>
  );

  const inputChangedFromAiValue = (fieldName, childField) => {
    if (dataPopulatedByAI.hasOwnProperty(fieldName)) {
      if (!!childField) {
        delete dataPopulatedByAI[fieldName][childField];
      } else {
        delete dataPopulatedByAI[fieldName];
      }
      setDataPopulatedByAI({ ...dataPopulatedByAI });
    }
  };

  const showGenAITooltipAndIcon = (fieldName, moreTooltipsList = []) => {
    let tempArr = [];
    if (isFeat11894Enabled()) {
      tempArr = moreTooltipsList;
    }
    return {
      suffixTooltipList: [
        ...tempArr,
        {
          suffixIcon: dataPopulatedByAI?.[fieldName] ? "smart_toy" : "",
          suffixIconClassName: "brand-color",
          suffixIconTooltip: INVOICE_POPULATED_USING_AI,
        },
      ],
      onChange: () => {
        if (fieldName === GEN_AI_IM_TRIGGER_MAPPING[2].aiMappingKey) {
          inputChangedFromAiValue(VAT_RATE.name);
        }
        inputChangedFromAiValue(fieldName);
      },
    };
  };

  const amountWarningMessageCondition =
    ((!!selectedData?.isSplit &&
      poNumberField === MULTIPLE_PO_DROPDOWN_OPTION_ID) ||
      (!selectedData?.isSplit && selectedData?.poNumber === poNumberField)) &&
    (selectedData?.matchType === MATCH_TYPE.CONFIRMED ||
      selectedData?.matchType === MATCH_TYPE.PARTIAL) &&
    (String(netAmount) !== selectedData?.net_amount ||
      currencyField !== selectedData?.currency?.id);

  const validateFormForPO = () => {
    if (poNumberField) {
      form.validateFields([PO_NUMBER.name]);
    }
  };

  const handleVatRateField = ({ taxCodeValue }) => {
    const taxData = taxCodeData?.options?.find(
      (data) => data.value === taxCodeValue
    );
    return taxData || false;
  };

  const handleEntityClear = () => {
    getUpdatedSuppliers();
    setEntityId("");
    form.setFieldValue(INVOICE_FORM.PO_NUMBER.name, null);
  };

  const handleValuesChange = (changedValues, values) => {
    if (Object.keys(changedValues).length) {
      setFieldChanged(true);
    }
    const { taxCode } = values;
    const taxData = handleVatRateField({ taxCodeValue: taxCode });
    if (taxCode) {
      form.setFieldValue(VAT_RATE.name, taxData.field_value);
    }
  };

  const openLineItemsTable = () => {
    setShowLineItems(true);
  };

  const handleLineItemsSave = (data, lineItemData) => {
    setShowLineItems(false);
    if (lineItemsValuesChanged || data.length !== lineItems.length) {
      setFieldChanged(true);
    }
    setNewTotalAmount(data);
    validateTaxSolution(lineItemData);
  };

  const handleLineItemsBack = () => {
    setShowLineItems(false);
  };

  const closedPos =
    selectedData?.poDetail.filter(
      (item) => item?.status === INVOICE_SPLIT_CONSTANTS.closedStatusPO
    ) || [];

  const isPoNumberDisabled = () => {
    return (
      selectedData?.matchType === MATCH_TYPE.CONFIRMED && !!closedPos.length
    );
  };

  const drawerHeader = (
    <Box justifyContent="flexStart">
      <Typography
        variant="title"
        text={PAGE_HEADER_INFO_INVOICE_MANAGEMENT.buttonLabel}
        fontSize={"24"}
        responsiveFontSize={false}
      />
    </Box>
  );

  const fetchTaxSolutionOption = (selectedValue) => {
    getTaxSolutionOptions(selectedValue).then((res) => {
      setTaxSolutionOptions(res);
    });
  };

  const {
    lineItemContainerClassName,
    lineItemHelperText,
    shouldSubmitButtonDisabledForLineItemError,
  } = checkLineItemsFormItemStatus({
    lineItems,
    isERPMapped,
    connectedErpPlatformName,
  });

  const SubmitButtonDisable =
    isInvoiceWithoutAttachment() ||
    uploadButtonDisabled ||
    shouldSubmitButtonDisabledForLineItemError;

  const isSageIntactPlatform =
    connectedErpPlatformName ===
      ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.sageIntacct
        .keyToCheckLogo && isERPMapped;

  useEffect(() => {
    if (entityValue && isSageIntactPlatform) {
      fetchTaxSolutionOption(entityValue);
    }
  }, [entityValue, isSageIntactPlatform]);

  const validateTaxSolution = async (lineItemData) => {
    const taxSolutionValue = form.getFieldValue(TAX_SOLUTION.name);
    if (!isSageIntactPlatform) {
      return true;
    }
    const itemData = lineItemData || lineItems;
    if (!taxSolutionValue || !itemData?.length) {
      return false;
    }
    try {
      const payload = {
        line_items: getPayloadForLineItems(itemData),
        tax_solution_id: taxSolutionValue,
        currency: selectedCurrency?.currency,
      };
      await isTaxSolutionValid(convertJSObjectToFormData(payload));
      form.setFields([
        {
          name: TAX_SOLUTION.name,
          errors: [],
        },
      ]);
      return true;
    } catch (error) {
      form.setFields([
        {
          name: TAX_SOLUTION.name,
          errors: [error?.response?.data?.message || TAX_SOLUTION.errorText],
        },
      ]);
      return false;
    }
  };

  const handleTaxSolutionSelection = () => {
    if (lineItems?.length) {
      setLineItems(
        lineItems.map((item) => {
          if (item.taxRate) {
            item.taxRate = null;
          }
          return item;
        })
      );
    }
  };

  const onEntitySelection = (value) => {
    form.setFieldValue(INVOICE_FORM.PO_NUMBER.name, null);
    form.setFieldValue(TAX_SOLUTION.name, null);
    onHandleTrigger(
      GEN_AI_IM_TRIGGER_MAPPING[0].mappingKey,
      GEN_AI_IM_TRIGGER_MAPPING[0].value,
      value
    );
    if (lineItems?.length) {
      setLineItems(
        lineItems.map((item) => cleanERPFieldsFromLineItemObject(item))
      );
    }
  };

  const lineItemViewSection = (
    <When condition={isLineItemsRevampEnabled()}>
      <Box
        className={constructClassName([
          "new-item-list-view p-2",
          "m-b-8px",
          lineItemContainerClassName,
        ])}
        justifyContent={"spaceBetween"}
      >
        <Box direction={"column"} alignItem={"flexStart"} rowGap={2}>
          <Label text={INVOICE_LINE_ITEMS.label} optional={!isERPMapped} />
          <Typography
            variant="body"
            text={lineItemHelperText}
            fontSize={12}
            textColorType={
              shouldSubmitButtonDisabledForLineItemError
                ? "destructive"
                : undefined
            }
          />
        </Box>
        <CustomButton text={"View"} onClick={openLineItemsTable} />
      </Box>
    </When>
  );

  return (
    <Drawer
      className="purchase-order-drawer-v1"
      onClose={closeDataForm}
      open={openDrawer}
      titleReactNode={drawerHeader}
    >
      <Form
        form={form}
        onValuesChange={handleValuesChange}
        name="data_form"
        initialValues={initialFormValue}
        layout={"vertical"}
        scrollToFirstError
        className={"position-absolute t-0px l-0px w-100 bg-white p-4"}
      >
        <div className="form-upload-container">
          <div className={`form-inside-container w-45`} ref={ref}>
            <div className="paid-dropdown align-items-center">
              {isNewForm ? (
                <Box justifyContent="flexStart">
                  <Typography
                    variant={"title"}
                    text={PAGE_HEADER_INFO_INVOICE_MANAGEMENT.buttonLabel}
                    fontSize={"24"}
                    responsiveFontSize={false}
                  />
                </Box>
              ) : title === "due" ? (
                <>
                  <Typography
                    variant={"title"}
                    text={"Paid £03.00"}
                    fontSize={"24"}
                    responsiveFontSize={false}
                  />
                </>
              ) : (
                <Typography
                  variant={"title"}
                  text={`Due ${selectedData?.currency?.symbol}
                ${renderAmount(selectedData?.amount || 0, "commaWithDecimal")}`}
                  fontSize={"24"}
                  responsiveFontSize={false}
                />
              )}
              <When
                condition={
                  userType === USER_TYPES.PU &&
                  selectedData?.is_loggedin_user_allow_to_resubmit &&
                  selectedData?.status !== INVOICE_STATUS.HOLD
                }
              >
                {editModeButton === "edit" ? (
                  <div className="paid-edit-btn">
                    <CustomButton
                      text={componentDisabled ? EDIT_LABEL : CANCEL_LABEL}
                      accent={ACCENTS.PRIMARY}
                      variant={BUTTON_VARIANT.BUTTON_FILLED}
                      onClick={() => {
                        if (!componentDisabled) {
                          handleErpFieldsBeforeReset();
                          form.resetFields();
                          resetErpFields();
                        }
                        setComponentDisabled(!componentDisabled);
                      }}
                      customSize={"large"}
                    />
                  </div>
                ) : null}
              </When>
              <When
                condition={
                  userType !== USER_TYPES.PU &&
                  selectedData?.status !== INVOICE_STATUS.HOLD
                }
              >
                {editModeButton === "edit" ? (
                  <div className="paid-edit-btn">
                    <CustomButton
                      text={componentDisabled ? EDIT_LABEL : CANCEL_LABEL}
                      accent={ACCENTS.PRIMARY}
                      variant={BUTTON_VARIANT.BUTTON_FILLED}
                      onClick={() => {
                        if (!componentDisabled) {
                          handleErpFieldsBeforeReset();
                          form.resetFields();
                          resetErpFields();
                        }
                        setComponentDisabled(!componentDisabled);
                      }}
                      customSize={"large"}
                    />
                  </div>
                ) : null}
              </When>
            </div>
            <When
              condition={
                Object.keys(dataPopulatedByAI).length > 0 ||
                uploadContainerLoading
              }
            >
              <CustomAlert
                className="ai-alert my-2 p-8px w-100"
                message={ai_alert(uploadContainerLoading, true)}
                icon={
                  !uploadContainerLoading ? (
                    <Icon
                      showIconOutline={true}
                      iconName={"smart_toy"}
                      className={"brand-color"}
                    />
                  ) : (
                    <ComponentLoader wrapperClassName="ai-alert-loader" />
                  )
                }
                type={"success"}
                showIcon
              />
              <Typography
                variant="tertiary"
                fontSize="12"
                className="kloo-ai-description"
                text={ai_alert(uploadContainerLoading)}
              />
            </When>
            <When condition={errorMessage}>
              <CustomAlert
                message={showErrorMessage(errorMessage)}
                type="error"
                className={"mb-3"}
              />
            </When>

            <When condition={viewEntityField}>
              <CommonToolTip
                title={ENTITY_CLOSED_PO_TP_MSG}
                trigger={closedPos?.length ? "hover" : []}
              >
                <CommonSelect
                  {...PURCHASE_ORDER_CREATE_FROM.entity}
                  options={entityList}
                  disabled={
                    isDisabledOnEdit || entityFieldDisabled || closedPos?.length
                  }
                  onSelect={onEntitySelection}
                  onClear={() => handleEntityClear()}
                  {...showGenAITooltipAndIcon(
                    PURCHASE_ORDER_CREATE_FROM.entity.name
                  )}
                />
              </CommonToolTip>
            </When>
            <When
              condition={
                selectedData?.entity_id !== entityValue &&
                selectedData?.poNumber
              }
            >
              <CustomAlert
                message={WARNING_MESSAGES.entityChange}
                type="warning"
                className={"mb-3"}
                showIcon
              />
            </When>
            <CommonInput
              {...INVOICE_NUMBER}
              disabled={isDisabledOnEdit}
              {...showGenAITooltipAndIcon(INVOICE_NUMBER.aiKey)}
            />
            <CommonInput
              {...AP_DESCRIPTION}
              size={size}
              disabled={isDisabledOnEdit}
              {...showGenAITooltipAndIcon(AP_DESCRIPTION.aiKey)}
            />
            <CommonDatePicker
              {...INVOICE_DATE}
              {...showGenAITooltipAndIcon(INVOICE_DATE.aiKey)}
              disabled={isDisabledOnEdit}
              suffixIcon={<Icon iconName={"calendar_month"} />}
              labelSuffixIcon={
                dataPopulatedByAI?.invoice_date ? "smart_toy" : ""
              }
            />
            <CommonDatePicker
              {...DUE_DATE}
              {...showGenAITooltipAndIcon(DUE_DATE.aiKey)}
              rules={isBleepBoxOrg() ? [] : DUE_DATE.rules}
              disabled={isDisabledOnEdit}
              labelSuffixIcon={dataPopulatedByAI?.due_date ? "smart_toy" : ""}
              suffixIcon={<Icon iconName={"calendar_month"} />}
            />
            <div className="invoice-drawer-amount-fields-section px-3 pt-3 mb-3">
              <Row span={24}>
                <Column span={8}>
                  <CurrencySelect
                    {...IM_CREATE_INVOICE_FORM.currency}
                    disabled={isDisabledOnEdit}
                    currencies={currencies}
                    onSelectHandle={validateFormForPO}
                    {...showGenAITooltipAndIcon(
                      IM_CREATE_INVOICE_FORM.currency.name
                    )}
                  />
                </Column>
                <Column span={16}>
                  <CommonInputNumber
                    {...IM_CREATE_INVOICE_FORM.netAmount}
                    rules={
                      isStatusToBeUpdatedEnabled()
                        ? [...IM_CREATE_INVOICE_FORM_RULE.NET_AMOUNT_MANDATORY]
                        : [...IM_CREATE_INVOICE_FORM_RULE.NET_AMOUNT]
                    }
                    disabled={isDisabledOnEdit || lineItems?.length}
                    formatter={amountFormatterWithoutCurrency}
                    {...showGenAITooltipAndIcon(
                      IM_CREATE_INVOICE_FORM.netAmount.aiKey,
                      IM_CREATE_INVOICE_FORM.netAmount.suffixTooltipList
                    )}
                    onKeyUp={validateFormForPO}
                  />
                </Column>
              </Row>

              <Row span={24}>
                <Column span={8}>
                  <CommonInputNumber
                    {...IM_CREATE_INVOICE_FORM.taxAmount}
                    rules={
                      isStatusToBeUpdatedEnabled()
                        ? [...IM_CREATE_INVOICE_FORM_RULE.TAX_AMOUNT_MANDATORY]
                        : [...IM_CREATE_INVOICE_FORM_RULE.TAX_AMOUNT]
                    }
                    disabled={isDisabledOnEdit}
                    formatter={amountFormatterWithoutCurrency}
                    {...showGenAITooltipAndIcon(
                      IM_CREATE_INVOICE_FORM.taxAmount.aiKey,
                      IM_CREATE_INVOICE_FORM.taxAmount.suffixTooltipList
                    )}
                  />
                </Column>
                <Column span={16}>
                  <CommonInputNumber
                    {...AMOUNT}
                    rules={
                      isStatusToBeUpdatedEnabled()
                        ? [
                            ...IM_CREATE_INVOICE_FORM_RULE.TOTAL_AMOUNT_MANDATORY,
                          ]
                        : [...IM_CREATE_INVOICE_FORM_RULE.TOTAL_AMOUNT]
                    }
                    disabled={isDisabledOnEdit}
                    {...showGenAITooltipAndIcon(
                      AMOUNT.name,
                      AMOUNT.suffixTooltipList
                    )}
                  />
                </Column>
              </Row>
            </div>
            <When
              condition={
                // if po is updated then user is allowed to change the amount and currency without warning, otherwise show this warning when invoice is matched with one or more PO
                amountWarningMessageCondition
              }
            >
              <CustomAlert
                message={WARNING_MESSAGES.amountEdit}
                type="warning"
                className={"mb-3"}
              />
            </When>
            <EnvFeatureFlag
              {...featureFlags.invoiceManagement.commonErpFields.newVersion}
            >
              <When
                condition={
                  isErpIntegration &&
                  viewEntityField &&
                  (chartsOfAccountList?.length ||
                    trackingCategoriesList?.length)
                }
              >
                <div className="invoice-drawer-tracking-categories-section px-3 pt-3 mb-3">
                  <ChartOfAccounts
                    dataPopulatedByAI={dataPopulatedByAI}
                    inputChangedFromAiValue={(data) =>
                      inputChangedFromAiValue(data)
                    }
                    isDisabledOnEdit={isDisabledOnEdit}
                  />
                  <TaxRate
                    dataPopulatedByAI={dataPopulatedByAI}
                    inputChangedFromAiValue={(data) =>
                      inputChangedFromAiValue(data)
                    }
                    isDisabledOnEdit={isDisabledOnEdit}
                  />
                  <TrackingCategories
                    dataPopulatedByAI={dataPopulatedByAI}
                    inputChangedFromAiValue={(data) =>
                      inputChangedFromAiValue(data)
                    }
                    isDisabledOnEdit={isDisabledOnEdit}
                  />
                </div>
              </When>
            </EnvFeatureFlag>
            <EnvFeatureFlag
              {...featureFlags.invoiceManagement.commonErpFields.oldVersion}
            >
              <When
                condition={
                  isErpIntegration &&
                  viewEntityField &&
                  (chartOfAccounts.length || trackingCategories)
                }
              >
                <div className="invoice-drawer-tracking-categories-section px-3 pt-3 mb-3">
                  <When condition={chartOfAccounts.length}>
                    <Box className="w-100 p-b-8px" justifyContent="flexStart">
                      <Label
                        text={IM_CREATE_INVOICE_FORM.chartOfAccounts.labelText}
                        suffixIcon="info"
                        suffixIconTooltip={
                          IM_CREATE_INVOICE_FORM.chartOfAccounts.labelText
                        }
                        optional={false}
                      />
                      <When condition={dataPopulatedByAI?.chartOfAccounts}>
                        <CommonToolTip title={INVOICE_POPULATED_USING_AI}>
                          <Icon
                            iconName={"smart_toy"}
                            className={"brand-color ms-5"}
                            showIconOutline={true}
                          />
                        </CommonToolTip>
                      </When>
                    </Box>
                    <Row>
                      <Column
                        span={3}
                        className="d-flex h-40px justify-content-center align-items-center prefix-icon"
                      >
                        <Image
                          src={connectedErpLogo(connectedErpPlatform)}
                          width={30}
                        />
                      </Column>
                      <Column span={21} className="ps-0">
                        <CommonSelect
                          {...IM_CREATE_INVOICE_FORM.chartOfAccounts}
                          disabled={isDisabledOnEdit}
                          options={chartOfAccounts}
                          onSelect={(e, options) => {
                            handleChartOfAccountChange(e, options);
                          }}
                          onClear={() => handleChartOfAccountClear()}
                          onChange={() =>
                            inputChangedFromAiValue(
                              IM_CREATE_INVOICE_FORM.chartOfAccounts.name
                            )
                          }
                        />
                      </Column>
                    </Row>
                  </When>
                  <When
                    condition={
                      connectedErpPlatform ===
                      ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.xero.key
                    }
                  >
                    <Box className="w-100 p-b-8px" justifyContent="flexStart">
                      <Label
                        text={IM_CREATE_INVOICE_FORM.taxRate.labelText}
                        suffixIcon="info"
                        suffixIconTooltip={
                          IM_CREATE_INVOICE_FORM.taxRate.labelText
                        }
                        optional={false}
                      />
                      <When condition={dataPopulatedByAI?.taxRate}>
                        <CommonToolTip title={INVOICE_POPULATED_USING_AI}>
                          <Icon
                            iconName={"smart_toy"}
                            className={"brand-color ms-5"}
                            showIconOutline={true}
                          />
                        </CommonToolTip>
                      </When>
                    </Box>
                    <Row>
                      <Column
                        span={3}
                        className="d-flex h-40px justify-content-center align-items-center prefix-icon"
                      >
                        <Image
                          src={connectedErpLogo(connectedErpPlatform)}
                          width={30}
                        />
                      </Column>
                      <Column span={21} className="ps-0">
                        <CommonSelect
                          {...IM_CREATE_INVOICE_FORM.taxRate}
                          disabled={isDisabledOnEdit || !taxRateList?.length}
                          options={taxRateList}
                          onChange={() =>
                            inputChangedFromAiValue(
                              IM_CREATE_INVOICE_FORM.taxRate.name
                            )
                          }
                        />
                      </Column>
                    </Row>
                  </When>
                  <When condition={trackingCategories}>
                    <TrackingCategoriesField
                      logo={connectedErpLogo(connectedErpPlatform)}
                      isDisabledOnEdit={isDisabledOnEdit}
                      trackingCategories={trackingCategories}
                      dataPopulatedByAI={dataPopulatedByAI}
                      inputChangedFromAiValue={(data) =>
                        inputChangedFromAiValue(data)
                      }
                    />
                  </When>
                </div>
              </When>
            </EnvFeatureFlag>
            <When condition={isSageIntactPlatform}>
              <CommonSelect
                {...TAX_SOLUTION}
                disabled={isDisabledOnEdit}
                options={taxSolutionOptions}
                onChange={validateTaxSolution}
                onSelect={handleTaxSolutionSelection}
                labelClassname="tax-solution-label"
                {...showGenAITooltipAndIcon(TAX_SOLUTION.name)}
              />
            </When>
            {lineItemViewSection}

            <Box justifyContent="spaceBetween" wrap={"nowrap"}>
              <When condition={taxCodeEnabled}>
                <CommonSelect
                  {...TAX_CODE}
                  rules={
                    isAgilisysOrg() || taxCodeData?.isRequired
                      ? IM_CREATE_INVOICE_FORM_RULE.TAX_CODE
                      : []
                  }
                  disabled={isDisabledOnEdit}
                  options={taxCodeData.options}
                  {...showGenAITooltipAndIcon(
                    GEN_AI_IM_TRIGGER_MAPPING[2].aiMappingKey
                  )}
                  onClear={() =>
                    form.setFieldValue(INVOICE_FORM.VAT_RATE.name, null)
                  }
                  wrapperClass={"w-100"}
                />
              </When>
            </Box>
            <CommonSelect
              {...SUPPLIER}
              onChange={(options) => {
                handleChange(PAYEE, options);
              }}
              onSelect={(options) => onHandleTrigger(PAYEE, "supplier_id")}
              disabled={isDisabledOnEdit}
              onClear={() => {
                form.setFields([
                  {
                    name: SUPPLIER_ACCOUNT.name,
                    value: undefined,
                  },
                ]);
              }}
              options={
                suppliers?.result?.map(({ id, name }) => ({
                  label: name,
                  value: id,
                })) || []
              }
              suffixIcon={autoFillSupplier ? "smart_toy" : ""}
            />
            <CommonSelect
              {...SUPPLIER_ACCOUNT}
              disabled={isDisabledOnEdit}
              onChange={handleChangeSupplierAccount}
              options={currentSupplierAllAccountDetailsOptions}
              suffixIcon={autoFillSupplierAccount ? "smart_toy" : ""}
            />
            <CommonToolTip
              placement="bottom"
              title={
                !entityValue
                  ? disabledPONumberForEntity
                  : disabledMatchingBtnToolTip(
                      closedPos.map((item) => item.order_number).join(", "),
                      closedPos.length > 1
                    )
              }
              trigger={isPoNumberDisabled() || !entityValue ? ["hover"] : ""}
            >
              <div>
                <PONumberInputField
                  approvedPOs={approvedPOs}
                  disabled={
                    isDisabledOnEdit ||
                    isPoNumberDisabled() ||
                    !netAmount ||
                    !entityValue
                  }
                  onChange={() => inputChangedFromAiValue(PO_NUMBER.name)}
                  dataPopulatedByAI={dataPopulatedByAI}
                  poNumberField={poNumberField}
                  selectedData={selectedData}
                  form={form}
                  threshold={threshold}
                  fixedAmount={fixedAmount}
                  componentDisabled={componentDisabled}
                  isAutoMatchEnabledForOrg={isAutoMatchEnabledForOrg}
                  isPOFieldRequired={isPOFieldRequired}
                  isReviewTab={
                    INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW === activeKey
                  }
                  uploadContainerLoading={uploadContainerLoading}
                  entityValue={entityValue}
                  setApprovedPOs={setApprovedPOs}
                />
              </div>
            </CommonToolTip>
            <When
              condition={
                (isCreateTab || (type === "submit" && !componentDisabled)) &&
                poNumberField === MULTIPLE_PO_DROPDOWN_OPTION_ID
              }
            >
              <CustomAlert
                message={
                  isCreateTab || !selectedData?.poNumber
                    ? WARNING_MESSAGES.multiPOCreation
                    : WARNING_MESSAGES.multiPOEdit
                }
                type="warning"
                className={"mb-3"}
              />
            </When>
            <CommonSelect
              {...OWNER_FIELD}
              options={ownerList}
              onChange={() => onHandleTrigger(OWNER)}
              disabled={isDisabledOnEdit}
            />
            {
              <Box justifyContent="spaceBetween" wrap={"nowrap"}>
                <CommonInput
                  name="vatNumber"
                  placeholder="VAT number"
                  size={size}
                  disabled={isDisabledOnEdit}
                  label={"VAT number"}
                  onChange={() => inputChangedFromAiValue("vat_number")}
                  suffixIcon={dataPopulatedByAI?.vat_number ? "smart_toy" : ""}
                  suffixIconClassName={"brand-color"}
                  suffixIconTooltip={INVOICE_POPULATED_USING_AI}
                  wrapperClass={"w-50"}
                />
                <CommonInputNumber
                  {...INVOICE_FORM.VAT_RATE}
                  rules={[
                    {
                      pattern: new RegExp(/\d+/g),
                      message: "Please enter valid VAT rate",
                    },
                  ]}
                  disabled={isDisabledOnEdit || taxCodeValue}
                  {...showGenAITooltipAndIcon(INVOICE_FORM.VAT_RATE.name)}
                  wrapperClass={"w-50"}
                />
              </Box>
            }
            <When
              condition={
                TrackingDisable.xero || tabState == 4 || isPaidTabSelected(type)
              }
            >
              <CommonSelect
                {...XERO_CUSTOMER_FIELD}
                onChange={() => onHandleTrigger(OWNER)}
                disabled={isDisabledOnEdit}
                options={xeroCustomerContactID?.result?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              />
            </When>
            <When
              condition={
                TrackingDisable.category ||
                tabState == 4 ||
                isPaidTabSelected(type)
              }
            >
              <CommonSelect
                {...XERO_CATEGORY}
                onSelect={() => onHandleTrigger(UPDATE_TRIGGER_MODAL.CATEGORY)}
                disabled={isCategoryToggleEnabled}
                options={xeroCategory?.result?.map(
                  ({ kloo_category_detail, name }) => ({
                    value:
                      kloo_category_detail?.kloo_category_id ||
                      kloo_category_detail?.id,
                    label: name,
                  })
                )}
              />
            </When>
            <When
              condition={
                (TrackingDisable.xero ||
                  tabState == 4 ||
                  isPaidTabSelected(type)) &&
                initialcategories &&
                initialcategories.length > 0
              }
            >
              <>
                {categoriesMap &&
                  categoriesMap.map((item, index) => {
                    return (
                      <>
                        <CommonSelect
                          label={`${TRACKING_CATEGORIES.label} ${
                            initialcategories &&
                            initialcategories?.length &&
                            initialcategories[index]?.name
                          }`}
                          rules={[
                            {
                              required: item.required === 1 ? true : false,
                              message:
                                "Please select tracking category" +
                                ` ${index + 1}`,
                            },
                          ]}
                          name={`${
                            TRACKING_CATEGORIES.name
                          }${fieldCategoryHandler(index)}`}
                          getPopupContainer={(trigger) => {
                            return trigger;
                          }}
                          disabled={isXeroToggleEnabled}
                          placeholder={TRACKING_CATEGORIES.placeholder}
                          className={constructClassName([
                            "optional",
                            item?.required === 1 ? "requiredBorder" : "",
                          ])}
                          options={
                            initialcategories &&
                            initialcategories[index] &&
                            initialcategories[index]?.nested_child &&
                            initialcategories[index]?.nested_child?.map(
                              ({ id, name }) => ({ value: id, label: name })
                            )
                          }
                        />
                      </>
                    );
                  })}
              </>
            </When>
            <When
              condition={
                TrackingDisable.customExpenses ||
                tabState == 4 ||
                isPaidTabSelected(type)
              }
            >
              <When condition={customField && customField.length > 0}>
                <CustomFieldInvoice
                  customField={customField}
                  onHandleTrigger={onHandleTrigger}
                  inputChangedFromAiValue={inputChangedFromAiValue}
                  dataPopulatedByAI={dataPopulatedByAI}
                  editMode={editMode}
                  isDisabledOnEdit={isDisabledOnEdit}
                  componentDisabled={componentDisabled}
                  treeData={treeData}
                  createSelector={createSelector}
                  setCreateSelector={setCreateSelector}
                  form={form}
                  creatableError={creatableError}
                  setcreatableError={setcreatableError}
                  newCreatedField={newCreatedField}
                  mappingDynamic={mappingDynamic}
                  dynamicOptions={dynamicOptions}
                  setDynamicOptions={setDynamicOptions}
                />
              </When>
            </When>

            <When condition={isCreateTab && !isLineItemsRevampEnabled()}>
              <Form.Item name="lineItems">
                <ItemList
                  totalAmount={totalAmount}
                  onAdd={onLineItemSave}
                  data={lineItems}
                  currencyValue={[selectedCurrency]}
                  itemSwitch={itemSwitch}
                  disabled={isDisabledOnEdit}
                  listLabel={INVOICE_LINE_ITEMS.label}
                  listInfo={INVOICE_LINE_ITEMS.info}
                  isEditView={true}
                  showExpandIcon={true}
                  allowEdit={true}
                  costFieldRequired={false}
                  quantityFieldRequired={false}
                  viewLineItems={viewLineItems}
                  setViewLineItems={setViewLineItems}
                  suffixIcon={dataPopulatedByAI?.line_items ? "smart_toy" : ""}
                  suffixIconClassName={"brand-color"}
                  suffixIconTooltip={INVOICE_POPULATED_USING_AI}
                  removeLineItems={removeLineItems}
                  inputChangedFromAiValue={inputChangedFromAiValue}
                  deleteLineItem={deleteLineItem}
                  forInvoice={true}
                  automateLineItems={handleAutomateLineItems}
                  selectedData={selectedData}
                  isKlooAIBtnDisabled={isInvoiceWithoutAttachment()}
                  klooAITooltipText={klooAITooltipText}
                />
              </Form.Item>
            </When>

            <Box justifyContent="spaceBetween" className={"m-b-15px"}>
              <When condition={!isNewForm}>
                {/* <CustomButton
                  text={"View audit log"}
                  accent={ACCENTS.LINK}
                  variant={BUTTON_VARIANT.LINK}
                  onClick={viewAuditLogModal}
                  className={"m-l-5px"}
                /> */}
                <When
                  condition={
                    componentDisabled &&
                    transformedData?.length &&
                    !isLineItemsRevampEnabled()
                  }
                >
                  <CustomButton
                    text={PURCHASE_ORDER_CREATE_FROM.viewItemList.label}
                    accent={ACCENTS.LINK}
                    variant={BUTTON_VARIANT.LINK}
                    onClick={showItemListModal}
                  />
                </When>
              </When>
            </Box>
            <EnvFeatureFlag {...featureFlags.invoiceManagement.klooInsights}>
              <When
                condition={
                  !isNewForm && showKlooInsights(activeKey, selectedData)
                }
              >
                <CustomAlert
                  className="m-b-15px p-8px w-100 align-items-center"
                  message={
                    <>
                      <Typography
                        variant="body"
                        fontStyle="semibold"
                        text={KLOO_INSIGHT + ": "}
                        className={"d-inline"}
                      />
                      <Typography
                        variant="body"
                        className={"d-inline"}
                        text={selectedData?.supplier_context_ai_line?.insight}
                      />
                    </>
                  }
                  icon={
                    <Icon
                      showIconOutline={true}
                      iconName={"smart_toy"}
                      className={"brand-color"}
                    />
                  }
                  type={"success"}
                  showIcon
                />
              </When>
            </EnvFeatureFlag>
            <div className="m-t-5px">
              <InvoiceFooterButton
                handleDraftCreate={handleDraftCreate}
                uploadButtonDisabled={uploadButtonDisabled}
                componentDisabled={componentDisabled}
                handleCreatePayment={handleCreatePayment}
                SubmitButtonDisable={SubmitButtonDisable}
                activeKey={activeKey}
                selectedData={selectedData}
                isNewForm={isNewForm}
              />
            </div>
          </div>
          <UploadInvoice
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
            form={form}
            selectedData={selectedData}
            setError={setError}
            setStoreDuplicateInvoiceData={setStoreDuplicateInvoiceData}
            setUploadButtonDisabled={setUploadButtonDisabled}
            isDisabledOnEdit={isDisabledOnEdit}
            closeDataForm={closeDataForm}
            setSelectedFileList={setSelectedFileList}
            selectedFileList={selectedFileList}
            saveFormData={saveFormData}
            setAttachmentDelete={setAttachmentDelete}
            isLineItemsRevampEnabled={isLineItemsRevampEnabled}
            showLineItems={showLineItems}
            resetForm={resetForm}
            uploadContainerLoading={uploadContainerLoading}
            setUploadContainerLoading={setUploadContainerLoading}
            lineItems={lineItems}
            deletedLineItemIdArray={deletedLineItemIdArray}
          />
        </div>
      </Form>
      <When condition={isLineItemsRevampEnabled() && showLineItems}>
        <LineItems
          previewImage={previewImage}
          module={MODULE_TYPES.AP}
          onBackClick={handleLineItemsBack}
          onSave={handleLineItemsSave}
          lineItemsTableData={lineItems}
          setLineItemsTableData={setLineItems}
          entityId={entityId}
          chartsOfAccountList={chartsOfAccountList}
          trackingCategoriesList={trackingCategoriesList}
          integrationName={connectedErpPlatformName}
          deleteLineItem={deleteLineItem}
          disabled={isDisabledOnEdit}
          setLineItemsValuesChanged={setLineItemsValuesChanged}
          onKlooAIClick={handleKlooAIClick}
          klooAITooltipText={klooAITooltipText}
          currency={selectedCurrency}
          totalAmount={totalAmount}
          loadingLineItems={loadingLineItems}
          tab={activeKey}
          disableActions={selectedData?.status === INVOICE_STATUS.APPROVED}
          isSageIntactPlatform={isSageIntactPlatform}
          taxSolution={selectedTaxSolution?.label}
        />
      </When>
    </Drawer>
  );
}

export default CreateInvoiceDrawer;
