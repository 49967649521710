import { getUsersListForCreatePaymentRun } from "../../api/PaymentRunsAPI";
import { GBP_CURRENCY_ID, featureFlags } from "../../constants";
import {
  PAYMENT_RUNS,
  PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS,
  PAYMENT_RUNS_TABS_KEYS,
} from "../../constants/PaymentRuns";
import { PAYMENT_RUNS_TABS } from "../../constants/PaymentRuns";
import {
  addDays,
  getTodaysDate,
  isDevelopmentEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
  renderAmount,
  subtractDays,
} from "../../utils";

export const checkIfUserCanCreatePR = async () => {
  const loggedInUserOrgId = localStorage.getItem("user_org_id");
  let moduleName = PAYMENT_RUNS.CREATE_PR_MODULE_NAME;
  const response = await getUsersListForCreatePaymentRun(moduleName);
  if (response?.result?.length) {
    if (response?.result?.find((user) => user?.user_id === loggedInUserOrgId)) {
      return false;
    } else {
      return true;
    }
  }
};

export function getColumnsForPaymentRuns({ selectedTab }) {
  let columnsToBeRendered = PAYMENT_RUNS_TABS.find(
    (tab) => tab.key === selectedTab
  ).columns;

  return columnsToBeRendered;
}
export function getColumnsForPrViewModal({ selectedTab }) {
  let columnsToBeRendered = PAYMENT_RUNS_TABS.find(
    (tab) => tab.key === selectedTab
  ).viewModalColumns;

  return columnsToBeRendered;
}
export function getColumnsForApproversList({ selectedTab }) {
  let columnsToBeRendered = PAYMENT_RUNS_TABS.find(
    (tab) => tab.key === selectedTab
  ).approvedListColumns;

  return columnsToBeRendered;
}

export function getScheduledDate(invoice_payments) {
  if (invoice_payments?.payment_scheduled_type === PAYMENT_RUNS.PAYMENT_TYPE) {
    let scheduledDate = invoice_payments?.payment_scheduled_at;
    return scheduledDate;
  } else {
    let scheduledDate = invoice_payments?.created_at;
    return scheduledDate;
  }
}

export function checkIfMultipleCurrencies(invoiceDetails) {
  return invoiceDetails?.some((el) => el?.currency?.id !== GBP_CURRENCY_ID);
}

export function checkIfInvoiceAmountZero(invoiceDetails) {
  return invoiceDetails?.some((el) => el?.invoice?.amount === "0.00");
}

export function getAmountIfAnotherCurrency(
  currency,
  amount,
  convert_invoice_amount
) {
  if (currency?.id === GBP_CURRENCY_ID) {
    let invoiceAmount = `${currency?.symbol}${renderAmount(
      amount,
      "commaWithDecimal"
    )}`;
    return invoiceAmount;
  } else {
    let invoiceAmountWithConvertedAmount = `£${renderAmount(
      convert_invoice_amount,
      "commaWithDecimal"
    )} (${currency?.symbol}${renderAmount(amount, "commaWithDecimal")})`;
    return invoiceAmountWithConvertedAmount;
  }
}

export const getOverDueAmount = (items) => {
  let todaysDate = getTodaysDate("YYYY-MM-DD");
  return getTotalValueFromList(
    items.filter((o) => o?.due_date < todaysDate && o?.convert_invoice_amount),
    PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.convertedInvoiceAmount.key
  );
};

export const getTotalValueFromList = (items, key) => {
  return items
    .filter((o) => o[key])
    .map((o) => o[key])
    .map((value) =>
      typeof value === "string" ? value.replace(/,/g, "") : value
    )
    .reduce((t, value) => t + Number(value), 0);
};

export const disableDateHandlerForScheduledDate = (current) => {
  return (
    current > addDays({ noOfDays: 366 }) ||
    current < subtractDays({ noOfDays: 0 })
  );
};

export const isScheduledOrPaidTab = (activeKey) => {
  if (
    activeKey === PAYMENT_RUNS_TABS_KEYS.SCHEDULED ||
    activeKey === PAYMENT_RUNS_TABS_KEYS.PAID
  ) {
    return true;
  } else {
    return false;
  }
};

export function isNewInvoiceDrawerEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.paymentRuns.invoiceDrawer.newVersion.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.paymentRuns.invoiceDrawer.newVersion.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.paymentRuns.invoiceDrawer.newVersion.enableForProd)
  );
}
