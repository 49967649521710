export const sendMessage = (channelName, message) => {
  const channel = new BroadcastChannel(channelName);
  channel.postMessage(message);
  channel.close();
};

export const addMessageListener = (channelName, callback) => {
  const channel = new BroadcastChannel(channelName);
  channel.onmessage = (event) => {
    callback(event.data);
  };
};

export const removeMessageListener = (channelName) => {
  const channel = new BroadcastChannel(channelName);
  channel.close();
};
