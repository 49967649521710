import React, { createContext, useContext, useState } from "react";
import { DRAWER_TYPES } from "../../../utils/constant";
import DownloadInvoiceDrawer from "../../PaymentRuns/DonwloadInvoiceDrawer";
import AssignPaymentRunDrawer from "../../PaymentRuns/AssignPaymentRunDrawer";
import {
  CreatePurchaseOrderDrawer,
  AssignPurchaseOrderDrawer,
  ApprovalPurchaseOrderDrawer,
  DownloadPurchaseOrderDrawer,
  ReviewPurchaseOrderDrawer,
} from "../../PurchaseOrder";
import CreateSupplier from "../../SupplierManagement/components/CreateSupplier";
import AddSupplierAccounts from "../../SupplierManagement/components/AddSupplierAccounts";
import ClosePurchaseOrderDrawer from "../../PurchaseOrder/ClosePurchaseOrder";
import WorkflowAssignDrawer from "../../CreditNotes/CreditNotesDrawer/WorkflowAssignDrawer";
import BulkActionDrawer from "../../CreditNotes/BulkActions/BulkActionDrawer";
import ClosedPOViewDrawer from "../../PurchaseOrder/ClosedPOViewDrawer";
import { CreateInvoiceDrawer } from "../../InvoiceManagement/Drawer";
import SkeletonDrawer from "../../Categorisation/LineItemCategorisation/SkeletonDrawer";

const DRAWER_COMPONENTS = {
  [DRAWER_TYPES.PO_CREATE_DRAWER]: CreatePurchaseOrderDrawer,
  [DRAWER_TYPES.CREATE_INVOICE_DRAWER]: CreateInvoiceDrawer,
  [DRAWER_TYPES.PO_ASSIGN_DRAWER]: AssignPurchaseOrderDrawer,
  [DRAWER_TYPES.PO_APPROVAL_DRAWER]: ApprovalPurchaseOrderDrawer,
  [DRAWER_TYPES.PO_DOWNLOAD_DRAWER]: DownloadPurchaseOrderDrawer,
  [DRAWER_TYPES.PO_REVIEW_DRAWER]: ReviewPurchaseOrderDrawer,
  [DRAWER_TYPES.PO_CLOSE_DRAWER]: ClosePurchaseOrderDrawer,
  [DRAWER_TYPES.PO_CLOSED_VIEW_DRAWER]: ClosedPOViewDrawer,
  [DRAWER_TYPES.PR_DONWLOAD_INVOICE_DRAWER]: DownloadInvoiceDrawer,
  [DRAWER_TYPES.SM_CREATE_SUPPLIER_DRAWER]: CreateSupplier,
  [DRAWER_TYPES.SM_ADD_ACCOUNTS_DRAWER]: AddSupplierAccounts,
  [DRAWER_TYPES.PR_ASSIGN_DRAWER]: AssignPaymentRunDrawer,
  [DRAWER_TYPES.WORKFLOW_ASSIGN_DRAWER]: WorkflowAssignDrawer,
  [DRAWER_TYPES.BULK_ACTION_DRAWER]: BulkActionDrawer,
  [DRAWER_TYPES.SKELETON_DRAWER]: SkeletonDrawer,
};

const initialState = {
  showDrawer: () => {},
  hideDrawer: () => {},
  store: {},
};

const GlobalDrawerContext = createContext(initialState);
export const useGlobalDrawerContext = () => useContext(GlobalDrawerContext);

export const GlobalDrawer = ({ children }) => {
  const [store, setStore] = useState();
  const { drawerType, drawerProps } = store || {};

  const showDrawer = (drawerType, drawerProps) => {
    setStore({
      ...store,
      isOpen: true,
      drawerType,
      drawerProps,
    });
  };

  const hideDrawer = () => {
    setStore({
      ...store,
      isOpen: false,
      drawerType: null,
      drawerProps: {},
    });
  };

  const renderComponent = () => {
    const DrawerComponent = DRAWER_COMPONENTS[drawerType];
    if (!drawerType || !DrawerComponent || !store.isOpen) {
      return null;
    }
    return (
      <DrawerComponent
        id="global-drawer"
        open={store.isOpen}
        onClose={hideDrawer}
        {...drawerProps}
      />
    );
  };

  return (
    <GlobalDrawerContext.Provider value={{ store, showDrawer, hideDrawer }}>
      {renderComponent()}
      {children}
    </GlobalDrawerContext.Provider>
  );
};
