import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import {
  appendKeyForFilters,
  capitalizeFirstLetter,
  convertJSObjectToFormData,
  formatPhoneNumber,
  generateEncodedURIString,
  sortCodeFormatter,
} from "../utils";

export async function checkSupplierUserPermission() {
  const tempResponse = await apiClient({
    url: PATH.supplierManagement.smartApproval.checkPermission,
    method: "GET",
  });
  return tempResponse?.data?.result;
}

export async function createSupplier(data) {
  const tempResponse = await apiClient({
    url: PATH.supplierManagement.create,
    method: "POST",
    data,
  });
  return tempResponse?.data?.result;
}

export async function updateSupplier(data) {
  const tempResponse = await apiClient({
    url: PATH.supplierManagement.create,
    method: "PUT",
    data,
  });
  return tempResponse?.data?.result;
}

export async function addSupplierAccount(data) {
  const tempResponse = await apiClient({
    url: PATH.supplierManagement.addAccounts,
    method: "POST",
    data,
  });
  return tempResponse?.data?.result;
}

export async function updateSupplierAccount(data) {
  const tempResponse = await apiClient({
    url: PATH.supplierManagement.addAccounts,
    method: "PUT",
    data,
  });
  return tempResponse?.data?.result;
}

export async function deleteSupplierAccount(accountId) {
  const tempResponse = await apiClient({
    url: PATH.supplierManagement.deleteSupplierAccount(accountId),
    method: "PUT",
  });
  return tempResponse?.data?.result;
}

export function editSupplier(data, supplierId) {
  return apiClient({
    url: PATH.supplierManagement.edit(supplierId),
    method: "PUT",
    data,
  });
}

export function deleteSupplier(supplierId) {
  return apiClient({
    method: "PUT",
    url: PATH.supplierManagement.delete(supplierId),
  });
}

export async function getSupplierManagementList({
  page = "",
  filtersUrl = "",
  searchUrl = "",
  pageSize = 10,
}) {
  const pageSizeURL = generateEncodedURIString(
    "perPageCount",
    pageSize.toFixed()
  );

  const tempResponse = await apiClient({
    url: `${PATH.supplierManagement.listing}?page=${page}${searchUrl}${filtersUrl}${pageSizeURL}`,
    method: "GET",
  });

  const response = {
    data: {
      list: tempResponse?.data?.result?.data?.map(({ sort_code, ...rest }) => {
        return {
          sort_code: sortCodeFormatter(sort_code),
          ...rest,
        };
      }),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
      filters: [],
    },
  };
  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}

export async function getCheatSheetList({ orgID, module }) {
  const tempResponse = await apiClient({
    url: `${PATH.supplierManagement.cheatSheetList}/${module}/${orgID}`,
    method: "GET",
  });

  return tempResponse.data;
}

export function createAndEditSupplierUserPermission(payload) {
  const tempResponse = apiClient({
    url: PATH.supplierManagement.smartApproval.createEditPermission.create,
    headers: { "Content-Type": "multipart/form-data" },
    method: "POST",
    data: convertJSObjectToFormData(payload),
  });
  return tempResponse;
}

export async function getCreateAndEditSupplierUserPermission() {
  const tempResponse = await apiClient({
    url: PATH.supplierManagement.smartApproval.createEditPermission.listing,
    method: "GET",
  });

  const response = {
    data: {
      list: tempResponse?.data?.result?.map(
        ({ first_name, last_name, user_id, ...rest }) => {
          return {
            label: `${first_name} ${last_name}`,
            value: user_id,
            first_name,
            last_name,
            user_id,
            ...rest,
          };
        }
      ),
    },
  };
  return response.data;
}

export async function getSupplierListForDropdown({ entityId = "" }) {
  const isEntityRequested = entityId ? `?entity_id=${entityId}` : "";
  const tempResponse = await apiClient({
    url: `${PATH.supplierManagement.listing}${isEntityRequested}`,
    method: "GET",
  });
  return tempResponse?.data?.result;
}

export async function isSupplierAssociatedToInvoice({ supplierId }) {
  const tempResponse = await apiClient({
    url: PATH.supplierManagement.isSupplierAssociatedToInvoice(supplierId),
    method: "GET",
  });
  return tempResponse?.data;
}

export async function isSupplierAccAssociatedToInvoice({ accountId }) {
  const tempResponse = await apiClient({
    url: PATH.supplierManagement.isSupplierAccAssociatedToInvoice(accountId),
    method: "GET",
  });
  return tempResponse?.data?.result?.isWorkflowAssigned;
}
