import { GBP_CURRENCY_ID } from "../constants";
import { PAYMENT_RUNS } from "../constants/PaymentRuns";
import {
  getAmountIfAnotherCurrency,
  getScheduledDate,
} from "../helper/PaymentRuns";
import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import {
  DEFAULT_FORMAT,
  getFormattedDate,
  GLOBAL_NOT_APPLICABLE_SYMBOL,
} from "../utils";
import {
  appendKeyForFilters,
  generateEncodedURIString,
  renderAmount,
} from "../utils/functions";

export function createPaymentRun(data) {
  return apiClient({
    url: PATH.paymentRuns.create,
    method: "POST",
    data,
  });
}

export async function getApprovedInvoicesList({
  page = 1,
  filtersUrl = "",
  searchUrl = "",
  statusUrl = "",
  pageSize = 10,
  statusNotInUrl = "",
  moduleName = "",
  sort_by = "",
  order = "",
}) {
  const pageSizeURL = generateEncodedURIString(
    "perPageCount",
    pageSize.toFixed()
  );

  const tempResponse = await apiClient({
    url: `${PATH.paymentRuns.approvedInvoicesListing}?page=${page}${statusUrl}${statusNotInUrl}${filtersUrl}${searchUrl}${moduleName}${pageSizeURL}&sort_by=${sort_by}&order=${order}`,
    method: "GET",
  });
  const response = {
    data: {
      list: tempResponse?.data?.result?.data?.map(
        ({
          supplier,
          currency,
          amount,
          invoice_payments,
          due_date,
          convert_invoice_amount,
          ...rest
        }) => {
          return {
            supplier_name: supplier?.name || GLOBAL_NOT_APPLICABLE_SYMBOL,
            priority: supplier?.priority,
            invoiceAmount: getAmountIfAnotherCurrency(
              currency,
              amount,
              convert_invoice_amount
            ),
            invoiceAmtWithoutSymbol: `${renderAmount(
              convert_invoice_amount,
              "commaWithDecimal"
            )}`,
            dueDate: `${getFormattedDate(due_date, DEFAULT_FORMAT)}`,
            supplier,
            currency,
            amount,
            due_date,
            invoice_payments,
            convert_invoice_amount,
            ...rest,
          };
        }
      ),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
      filters: [],
    },
  };

  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}

export async function getPaymentRunsList({
  page = 1,
  filtersUrl = "",
  searchUrl = "",
  statusUrl = "",
  isDownloadUrl = "",
  pageSize = 10,
  statusNotInUrl = "",
  sort_by = "",
  order = "",
}) {
  const pageSizeURL = generateEncodedURIString(
    "perPageCount",
    pageSize.toFixed()
  );

  const tempResponse = await apiClient({
    url: `${PATH.paymentRuns.listing}?page=${page}${filtersUrl}${searchUrl}${statusUrl}${isDownloadUrl}${pageSizeURL}${statusNotInUrl}&sort_by=${sort_by}&order=${order}`,
    method: "GET",
  });

  const response = {
    data: {
      list: tempResponse?.data?.result?.data?.map(
        ({
          scheduled_date,
          suppliers_name,
          total_invoice_amount,
          total_invoice_overdue_amount,
          currency,
          payment_run_details,
          payment_date,
          invoice_owner,
          ...rest
        }) => {
          return {
            supplier_name:
              suppliers_name.join(", ") || GLOBAL_NOT_APPLICABLE_SYMBOL,
            totalAmount: `${currency?.symbol || "£"}${renderAmount(
              total_invoice_amount,
              "commaWithDecimal"
            )}`,
            totalOverdueAmount: `${currency?.symbol || "£"}${renderAmount(
              total_invoice_overdue_amount,
              "commaWithDecimal"
            )}`,
            scheduledDate: `${getFormattedDate(
              scheduled_date,
              DEFAULT_FORMAT,
              GLOBAL_NOT_APPLICABLE_SYMBOL
            )}`,
            paymentDate: `${getFormattedDate(
              payment_date,
              DEFAULT_FORMAT,
              GLOBAL_NOT_APPLICABLE_SYMBOL
            )}`,
            hasAllNonGBPInvoices: payment_run_details?.every(
              (el) => el?.invoice?.currency?.id !== GBP_CURRENCY_ID
            ),
            owner_full_name: invoice_owner?.owner_full_name,
            invoice_owner,
            scheduled_date,
            total_invoice_amount,
            total_invoice_overdue_amount,
            currency,
            payment_run_details,
            payment_date,
            ...rest,
          };
        }
      ),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
    },
  };

  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}

export async function getAuditTrailHistory({ page = 1, paymentRunId }) {
  const tempResponse = await apiClient({
    url: `${PATH.paymentRuns.getAuditTrailHistory}${paymentRunId}?page=${page}`,
    method: "GET",
  });

  const response = {
    data: {
      list: tempResponse?.data?.result?.data?.map(({ ...data }) => {
        return {
          ...data,
        };
      }),
    },
  };
  response.data.filters = appendKeyForFilters(
    tempResponse?.data?.result?.meta?.filters
  );
  response.data.page = tempResponse?.data?.result?.meta?.current_page;
  response.data.pageSize = tempResponse?.data?.result?.meta?.per_page;

  return response.data;
}

export function removeInvoice(data) {
  return apiClient({
    url: PATH.paymentRuns.removeInvoice,
    method: "PATCH",
    data,
  });
}

export async function downloadPaymentRunsList({
  page = 1,
  filtersUrl = "",
  searchUrl = "",
  statusUrl = "",
  selectedIdsForDownload,
}) {
  const tempResponse = await apiClient({
    url: `${PATH.paymentRuns.downloadExcel}?page=${page}${filtersUrl}${searchUrl}${statusUrl}`,
    method: "POST",
    data: selectedIdsForDownload
      ? {
          ids: selectedIdsForDownload,
        }
      : undefined,
  });

  return {
    data: {
      downloadLink: tempResponse?.data?.result?.download_link,
    },
  };
}

export function downloadPDF(orderId) {
  return apiClient({
    url: PATH.paymentRuns.downloadPDF(orderId),
    method: "PUT",
  });
}

export async function getUsersListForCreatePaymentRun(moduleName) {
  const response = await apiClient({
    url: `${PATH.paymentRuns.getUsersListForCreatePaymentRun}?module=${moduleName}`,
    method: "GET",
  });
  return response?.data;
}

export function platformUsersInSmartApprovals() {
  let moduleName = PAYMENT_RUNS.PU_USERS_IN_SA_MODULE_NAME;
  return apiClient({
    url: `${PATH.paymentRuns.platformUsersInSmartApprovals}?module=${moduleName}`,
    method: "GET",
  });
}

export function addUsersToCreatePaymentRunList(data) {
  return apiClient({
    url: PATH.paymentRuns.addUsersToCreatePaymentRunList,
    method: "POST",
    data,
  });
}

export function removeUserFromCreatePaymentRunList(userId) {
  return apiClient({
    url: PATH.paymentRuns.removeUserFromCreatePaymentRunList(userId),
    method: "PATCH",
  });
}

export async function getUsersListForScheduleAndPay(type) {
  const response = await apiClient({
    url: `${PATH.paymentRuns.getUsersListForScheduleAndPay}?type=${type}`,
    method: "GET",
  });
  return response?.data;
}

export function addUserToScheduleAndPayList(data) {
  return apiClient({
    url: PATH.paymentRuns.addUserToScheduleAndPayList,
    method: "POST",
    data,
  });
}

export function removeUserFromScheduleAndPayList(
  approverTypeId,
  workflowId,
  role
) {
  return apiClient({
    url: PATH.paymentRuns.removeUserFromScheduleAndPayList(
      approverTypeId,
      workflowId,
      role
    ),
    method: "PATCH",
  });
}

export function markAsPaid(paymentRunId) {
  return apiClient({
    url: PATH.paymentRuns.markAsPaid(paymentRunId),
    method: "PATCH",
  });
}

export function schedulePayment(data) {
  return apiClient({
    url: PATH.paymentRuns.schedulePayment,
    method: "POST",
    data,
  });
}

export function downloadPDFInPaidView(orderId) {
  return apiClient({
    url: PATH.paymentRuns.downloadSinglePDFInPaid(orderId),
    method: "PUT",
  });
}

export function approveRejectDeleteCancelPaymentRun(data) {
  return apiClient({
    url: PATH.paymentRuns.approveOrRejectPaymentRun,
    method: "PUT",
    data,
  });
}

export const checkSelectedWorkFlowDetail = (workflowId, type) => {
  return apiClient({
    method: "GET",
    url: PATH.smart_approval.checkSelectedWorkFlowDetail(workflowId, type),
  });
};

export const downloadPaymentFile = async (data) => {
  const temp = await apiClient({
    method: "POST",
    url: PATH.paymentRuns.downloadPRFile,
    data: data,
  });
  return { downloadLink: temp?.data?.result?.["download-link"] };
};

export async function validateBacsDownloadPayment(payload) {
  const tempResponse = await apiClient({
    url: PATH.paymentRuns.validateBacsPayment,
    method: "POST",
    data: payload,
  });
  return tempResponse?.data?.result?.validate;
}

export async function getPurchaseOrderCreatorsList(module) {
  const response = await apiClient({
    url: PATH.paymentRuns.getPurchaseOrderCreatorsList(module),
    method: "GET",
  });
  return response?.data;
}

export async function getFaAndPuUsersList() {
  const res = await apiClient({
    url: PATH.paymentRuns.getFaAndPuUsers(),
    method: "GET",
  });
  return (
    res.data?.result?.user_organization?.map((organization) => ({
      value: organization.id,
      label: organization?.users
        ? `${organization?.users?.first_name} ${organization?.users?.last_name}`
        : "N/A",
    })) || []
  );
}

export function addPurchaseRequestCreator(data) {
  return apiClient({
    url: PATH.paymentRuns.addPurchaseRequestCreator,
    method: "POST",
    data,
  });
}

export function removeUserFromPrCreatorList(userId) {
  return apiClient({
    url: PATH.paymentRuns.removeUserFromPrCreatorList(userId),
    method: "PATCH",
  });
}
