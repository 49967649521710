import {
  AGILISYS_ORG_IDS,
  BLEEP_BOX_ORG_IDS,
  INVOICE_MANAGEMENT_TABS,
  INVOICE_MANAGEMENT_TAB_KEYS,
  NOTIFICATION_TYPE,
  featureFlags,
} from "../../constants";
import {
  GBP_CURRENCY_ID,
  INVOICE_STATUS,
  PAYMENT_JOURNEY_TAB_PAY_WITH_OPEN_BANKING_KEY,
  PAYMENT_TYPE_CONFIG,
  REJECTED_STATUS,
  TAB_WISE_TYPE_CONFIG,
} from "../../constants/InvoiceManagement";
import { LINE_ITEM_TABLE_COLUMNS_KEY } from "../../constants/LineItems";
import {
  addDays,
  checkIfDateIsAWeekend,
  getFormattedDate,
  getItem,
  GLOBAL_NOT_APPLICABLE_SYMBOL,
  isDevelopmentEnvironment,
  isEngageEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
  LOCAL_STORAGE_KEY,
  showNotification,
  subtractDays,
} from "../../utils";
import { isDefaultCurrencyFeatureEnabled } from "../SmartApprovals";

export function checkIfCurrentCurrencyIsGBP(currentCurrency) {
  return currentCurrency === GBP_CURRENCY_ID;
}

export function showApproveButtonInReviewTab({
  selectedData,
  userType,
  user_org_id,
}) {
  return (
    // (selectedData?.status === "submitted" &&
    //   userType === "platform-user" &&
    //   selectedData?.user_org_id === user_org_id) ||
    // (selectedData?.status === "cancelled" &&
    //   (userType === "platform-user" || userType === "finance-approver") &&
    //   selectedData?.user_org_id === user_org_id) ||
    selectedData?.is_loggedin_user_allow_to_approve
  );
}

export function showRejectButtonInReviewTab({ selectedData }) {
  return selectedData?.is_loggedin_user_allow_to_approve;
}

export function showReScheduledAndCancelButtonInScheduledTab({ selectedData }) {
  return (
    selectedData?.is_loggedin_user_allow_to_reschedule_cancel &&
    selectedData.payment_mode !== PAYMENT_TYPE_CONFIG[1].key &&
    selectedData.paymentrun_name === "N/A"
  );
}

export function isSubmittedTabSelected(currentTab) {
  return TAB_WISE_TYPE_CONFIG[0].tabKey === currentTab;
}

export function isToPayTabSelected(currentTab) {
  return INVOICE_MANAGEMENT_TAB_KEYS.TO_PAY === currentTab;
}

export function isInReviewTabSelected(currentTab) {
  return INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW === currentTab;
}

export function isPaidTabSelected(currentTab) {
  return (
    TAB_WISE_TYPE_CONFIG[2].tabKey === currentTab ||
    INVOICE_MANAGEMENT_TAB_KEYS.PAID === currentTab
  );
}

export const disableDateHandlerForScheduledPaymentJourney = (
  current,
  tabKey
) => {
  return (
    current > addDays({ noOfDays: 366 }) ||
    current < subtractDays({ noOfDays: 0 }) ||
    (tabKey === PAYMENT_JOURNEY_TAB_PAY_WITH_OPEN_BANKING_KEY &&
      current &&
      checkIfDateIsAWeekend(current))
  );
};

export const paymentNotification = (paymentType, showSubTitle = true) => {
  return showNotification(
    NOTIFICATION_TYPE.success,
    `Payment ${paymentType}`,
    showSubTitle ? `Your payment has been ${paymentType}` : ""
  );
};

const getCommonPayloadItemForPayment = ({
  formValue,
  selectedRecord,
  orgDetails,
}) => {
  let optionalPayload = {};
  if (formValue.emailToSendReceipt) {
    optionalPayload["emailToSendReceipt"] = formValue.emailToSendReceipt;
  }

  optionalPayload.supplier_account_details_id = formValue.supplierAccount;

  return {
    beneficiary_id: formValue.payee,
    invoice_id: selectedRecord.id,
    invoice_number: formValue?.invoice_number || null,
    from_acc:
      orgDetails[0]?.organization_account[0].decrypted_modulr_account_id,
    modulr_account_id: orgDetails[0]?.organization_account[0].id,
    ...optionalPayload,
  };
};

export const getValidationPaymentPayload = ({
  formValue,
  selectedRecord,
  orgDetails,
}) => {
  return {
    beneficiary_id: formValue?.payee,
    invoice_id: selectedRecord?.id,
    invoice_number: formValue?.invoice_number || null,
    supplier_account_details_id: formValue?.supplierAccount,
    payment_scheduled_type: "nowpay",
  };
};

export const getPayloadForPayNowPayment = ({
  formValue,
  selectedRecord,
  orgDetails,
}) => {
  return {
    payload: {
      ...getCommonPayloadItemForPayment({
        formValue,
        selectedRecord,
        orgDetails,
      }),
      payment_scheduled_type: "nowpay",
    },
    onSuccessNotification: () => {
      paymentNotification("made");
    },
  };
};

export const getPayloadForScheduledPayment = ({
  formValue,
  selectedRecord,
  orgDetails,
}) => {
  return {
    payload: {
      ...getCommonPayloadItemForPayment({
        formValue,
        selectedRecord,
        orgDetails,
      }),
      payment_scheduled_type: "scheduled",
      payment_scheduled_at: getFormattedDate(
        formValue.paymentDate,
        "YYYY-MM-DD"
      ),
    },
    onSuccessNotification: () => {
      paymentNotification("scheduled", true);
    },
  };
};

export const getPayloadForReScheduledPayment = ({
  formValue,
  selectedRecord,
}) => {
  return {
    payload: {
      invoice_id: selectedRecord.id,
      payment_scheduled_type:
        selectedRecord?.invoice_payments?.payment_scheduled_type,
      selectedRecord: getFormattedDate(formValue.paymentDate, "YYYY-MM-DD"),
    },
    onSuccessNotification: () => {
      paymentNotification("rescheduled");
    },
  };
};

export const hidePaymentButtons = ({ isIMOrg, accountDetail }) => {
  // Hide Payment Buttons and Schedule Tab,
  // 1. if Org is Non - Modular
  // 2. Yapily Toggle is Off
  return isIMOrg && accountDetail.is_yapily_integration === 0;
};

export const getDrawerType = ({ activeKey, selectedData }) => {
  switch (activeKey) {
    case INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED:
      return selectedData?.status === REJECTED_STATUS
        ? REJECTED_STATUS
        : "submit";
    default:
      return activeKey;
  }
};

export const getEditModeButton = ({ activeKey, selectedData }) => {
  switch (activeKey) {
    case INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW:
      return selectedData?.is_loggedin_user_allow_to_approve ? "edit" : "";
    case INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED:
      return "edit";
    case INVOICE_MANAGEMENT_TAB_KEYS.DRAFT:
      return "edit";
    case INVOICE_MANAGEMENT_TAB_KEYS.REJECTED:
      return "edit";
    default:
      return undefined;
  }
};

export const getInvoiceManagementColumn = ({
  userType,
  activeKey,
  isDev,
  isInvoiceMatchingVisible,
  isPaymentRunVisible,
  isStage,
  isProd,
}) => {
  const columnsToRender =
    INVOICE_MANAGEMENT_TABS?.find((tab) => tab.key === activeKey)?.columns ||
    [];

  if (Array.isArray(columnsToRender)) {
    return [...columnsToRender];
  } else if (userType) {
    return [
      ...columnsToRender({
        userType,
        isDev,
        isInvoiceMatchingVisible,
        isPaymentRunVisible,
        isStage,
        isProd,
      }),
    ];
  }
};

export const getRaisedByNameInColumns = ({ user_organization_details }) => {
  if (
    user_organization_details?.user?.first_name &&
    user_organization_details?.user?.last_name
  ) {
    return `${user_organization_details.user.first_name} ${user_organization_details.user.last_name}`;
  } else {
    return GLOBAL_NOT_APPLICABLE_SYMBOL;
  }
};

export const getOwnerNameInColumns = ({ owner_organization_details }) => {
  if (
    owner_organization_details?.user?.first_name &&
    owner_organization_details?.user?.last_name
  ) {
    return `${owner_organization_details.user.first_name} ${owner_organization_details.user.last_name}`;
  } else {
    return GLOBAL_NOT_APPLICABLE_SYMBOL;
  }
};

export function getSupplierName({ activeKey, record }) {
  if (activeKey === INVOICE_MANAGEMENT_TAB_KEYS.TO_PAY) {
    const supplier =
      record?.supplier?.status === INVOICE_MANAGEMENT_TAB_KEYS.DELETED
        ? GLOBAL_NOT_APPLICABLE_SYMBOL
        : record?.payee;
    return supplier;
  } else if (
    activeKey === INVOICE_MANAGEMENT_TAB_KEYS.DRAFT &&
    record?.payee === GLOBAL_NOT_APPLICABLE_SYMBOL
  ) {
    return "";
  }
  return record?.payee;
}

export const showKlooInsights = (activeKey, selectedData) => {
  const reviewStatuses = [
    INVOICE_STATUS.APPROVED,
    INVOICE_STATUS.PAYMENT_FAILED,
  ];

  const isSubmittedOrInReviewOrOnHold = [
    INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED,
    INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW,
    INVOICE_MANAGEMENT_TAB_KEYS.HOLD,
  ].includes(activeKey);

  const isNotApprovedOrPaymentFailed = !reviewStatuses.includes(
    selectedData?.invoice_on_hold_details?.pervious_status
  );

  const isNotCancelledOrRejected = ![
    INVOICE_STATUS.CANCELLED,
    INVOICE_STATUS.REJECTED,
  ].includes(selectedData?.status);

  return (
    isSubmittedOrInReviewOrOnHold &&
    isNotApprovedOrPaymentFailed &&
    isNotCancelledOrRejected
  );
};

export function isAgilisysOrg() {
  if (AGILISYS_ORG_IDS.includes(getItem(LOCAL_STORAGE_KEY.ORD_ID))) {
    return true;
  }
  return false;
}

export function isRemoveAttachmentFeatureEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.removeAttachmentImprovement
        .enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.removeAttachmentImprovement
        .enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.removeAttachmentImprovement.enableForProd)
  );
}

export function isEnableOnlyWhenConfirmedInvoice() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.enableOnlyConfirmedInvoice.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.enableOnlyConfirmedInvoice
        .enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.enableOnlyConfirmedInvoice.enableForProd)
  );
}

export function isListingApiKeysRefactored() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.refactoredKeys.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.refactoredKeys.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.refactoredKeys.enableForProd)
  );
}

export function duplicateInvoiceHandler({
  data,
  setError,
  setStoreDuplicateInvoiceData,
  setUploadButtonDisabled,
}) {
  const ifDuplicateInvoiceFound = data?.result?.is_duplicate_invoice_found;
  const duplicateInvoiceData = data?.result?.duplicate_invoice_details;
  if (ifDuplicateInvoiceFound && duplicateInvoiceData) {
    setError(JSON.stringify(data?.result?.duplicate_invoice_details));
    setUploadButtonDisabled(true);
    setStoreDuplicateInvoiceData && setStoreDuplicateInvoiceData(data);
  } else {
    setError("");
    setUploadButtonDisabled(false);
    setStoreDuplicateInvoiceData && setStoreDuplicateInvoiceData(null);
  }
}

export function isBleepBoxOrg() {
  const ordId = getItem("org_id");
  return BLEEP_BOX_ORG_IDS.includes(ordId);
}

export function cleanERPFieldsFromLineItemObject(obj) {
  const { ACCOUNT, TAX_RATE, ITEM_LIST, TRACKING_CATEGORIES } =
    LINE_ITEM_TABLE_COLUMNS_KEY;
  const keysToKeep = [
    ACCOUNT,
    TAX_RATE,
    ITEM_LIST,
    TRACKING_CATEGORIES,
    "accountTaxRates",
  ];
  for (const key in obj) {
    // If the key is in the list of keysToKeep and start with "trackingCategory_", delete it
    if (keysToKeep.includes(key) || key.startsWith("trackingCategory_")) {
      delete obj[key];
    }
  }
  return obj;
}

export function isMatchingHidden() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.hideInvoiceMatching.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.hideInvoiceMatching.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.hideInvoiceMatching.enableForProd)
  );
}

export function isCTAButtonsEnabledInOtherTabs() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.CTAButtonsInAllTabs.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.CTAButtonsInAllTabs.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.CTAButtonsInAllTabs.enableForProd)
  );
}

export function isFileCompressionEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.fileCompression.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.fileCompression.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.fileCompression.enableForProd)
  );
}

export function isSaveAsDraftInvoice() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.saveAsDraft.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.saveAsDraft.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.saveAsDraft.enableForProd)
  );
}

export function isLineItemsRevampEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.lineItemsRevamp_10919.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.lineItemsRevamp_10919.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.lineItemsRevamp_10919.enableForProd)
  );
}

export function isMatchVisibleOnHoldTab() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.matchColumnOnHoldTab.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.matchColumnOnHoldTab.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.matchColumnOnHoldTab.enableForProd)
  );
}

export function isFilterEnabledForDraft() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.isFilterEnabledForDraft.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.isFilterEnabledForDraft.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.isFilterEnabledForDraft.enableForProd)
  );
}

export function isCommonErpFieldsEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.commonErpFields.newVersion.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.commonErpFields.newVersion
        .enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.commonErpFields.newVersion.enableForProd)
  );
}

export function isFeat11423Enabled() {
  return (
    (isDevelopmentEnvironment() && featureFlags.feat11423.enableForDev) ||
    (isStageEnvironment() && featureFlags.feat11423.enableForStage) ||
    (isPlatformEnvironment() && featureFlags.feat11423.enableForProd)
  );
}

export function isResetFormWhenInvoiceDelete() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.resetFormOnInvoiceDelete_11493
        .enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.resetFormOnInvoiceDelete_11493
        .enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.resetFormOnInvoiceDelete_11493
        .enableForProd)
  );
}

export function isAutoSingleMatchEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.autoSplitMatchWhenSinglePO.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.autoSplitMatchWhenSinglePO
        .enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.autoSplitMatchWhenSinglePO.enableForProd)
  );
}

export function isStatusToBeUpdatedEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.invoiceManagement.isStatusToBeUpdatedEnabled_12177
        .enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.invoiceManagement.isStatusToBeUpdatedEnabled_12177
        .enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.invoiceManagement.isStatusToBeUpdatedEnabled_12177
        .enableForProd)
  );
}
