import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { downloadPDF, postPoComment } from "../../../api";
import {
  ACCENTS,
  BUTTON_VARIANT,
  ERROR_MSG,
  MODULE_TYPES,
  PO_ACTIONS,
  PURCHASE_ORDER_CLOSE_DRAWER,
} from "../../../constants";
import { useToggle, useUserType } from "../../../hooks";
import {
  NOTIFICATION_TYPE,
  downloadFileByJS,
  getUserOrgId,
  isGRNISectionVisible,
  isMatchedInvoiceSectionVisible,
  isOrgTypeOnlyPO,
  isPOAuditLogVisible,
  isPlatformUser,
  showNotification,
} from "../../../utils";
import {
  Box,
  Column,
  CustomButton,
  Divider,
  Drawer,
  Icon,
  PDFViewer,
  Row,
  Typography,
  When,
  AuditLogModal as CommonAuditLogModal,
} from "../../common";
import AuditLogModal from "../AuditLogModal";
import { PurchaseOrderPDFView } from "../PurchaseOrderPDFView";
import "./ClosedPOViewDrawer.scss";
import { isAuditLogsEnabledInCreditNotes } from "../../../helper/CreditNotes";
function ClosedPOViewDrawer({
  open,
  poDetails,
  onClose,
  reviewer,
  reloadTable,
  showModal,
  hideModal,
  onClickOfCloseDrawer,
  taxCodeEnabled,
  entityEnabled,
  defaultEntityList,
  version,
  purchaseOrderID,
  status,
  poId,
}) {
  const userType = useSelector(
    (state) => state && state?.menuReducer && state?.menuReducer?.userType
  );
  const accountDetail =
    useSelector(
      (state) =>
        state &&
        state?.modular &&
        state?.modular?.accountDetail &&
        state?.modular?.accountDetail[0]
    ) || {};

  const [pdfLink, setPDFLink] = useState(null);
  const [onDeleteReload, setOnDeleteReload] = useState(false);
  const { isOpen: reloadGRNI, toggle: toggleReloadGRNI } = useToggle(false);
  const [totalAmountRef, setTotalAmountRef] = useState({
    totalGRNIAmount: 0,
    totalMatchedInvoiceAmount: 0,
    valueGoodsReceived: 0,
  });
  const userTypeDetails = useUserType();

  const isPOStatusClosed = (status) => status === PO_ACTIONS.CLOSE;

  const isOnlyPOOrgType = isOrgTypeOnlyPO({
    orgType: accountDetail?.type,
    subOrgType: accountDetail?.sub_types,
  });

  useEffect(() => {
    downloadPDF(poId)
      .then((res) => {
        setPDFLink(res?.data?.result?.download_link);
      })
      .catch((e) =>
        showNotification(
          NOTIFICATION_TYPE.error,
          e?.response?.data?.message || ERROR_MSG.common
        )
      );
  }, []);

  const enableGRNIFeature =
    isPOStatusClosed(status) &&
    isGRNISectionVisible({
      userTypeDetails,
      poDetails,
      userOrgId: getUserOrgId(),
    });

  const enableMatchedInvoiceFeature =
    isPOStatusClosed(status) &&
    isMatchedInvoiceSectionVisible({ userTypeDetails }) &&
    !isOnlyPOOrgType;

  const onCloseDrawer = () => {
    onClose();
    onClickOfCloseDrawer();
    onDeleteReload && reloadTable();
  };

  const AuditModal = () => {
    if (isAuditLogsEnabledInCreditNotes()) {
      return (
        <CommonAuditLogModal
          showModal={showModal}
          details={poDetails}
          type={MODULE_TYPES.PO}
          addComment={postPoComment}
          isAuditLogEnabled={isPOAuditLogVisible({
            userTypeDetails,
            poDetails,
            userOrgId: getUserOrgId(),
          })}
        />
      );
    } else {
      return <AuditLogModal showModal={showModal} poDetails={poDetails} />;
    }
  };

  const onGRNIDataLoaded = (grniData) => {
    const gnriTotalAmount = grniData?.rawTotalAmount || 0;
    setTotalAmountRef((previous) => {
      return {
        ...previous,
        totalGRNIAmount: gnriTotalAmount,
        valueGoodsReceived: grniData.valueGoodsReceived,
      };
    });
  };

  const onMatchedInvoicesDataLoaded = (matchedInvoicesData) => {
    const matchedInvoiceAmount = matchedInvoicesData?.totalRawAmount || 0;
    setTotalAmountRef((previous) => {
      return {
        ...previous,
        totalMatchedInvoiceAmount: matchedInvoiceAmount,
      };
    });
  };

  const onDownloadClick = () => {
    downloadFileByJS(pdfLink, purchaseOrderID || "Rejected");
  };

  return (
    <Drawer
      className={"review-purchase-drawer refactored-version expanded-version"}
      open={open}
      onClose={onCloseDrawer}
      getContainer={false}
      mask={false}
      title={PURCHASE_ORDER_CLOSE_DRAWER.TITLE_VIEW}
    >
      <Row gutter={10} className={"close-wrapper h-100"}>
        <Column
          {...{ xs: 24, sm: 24, md: 24, lg: 11 }}
          className="close-po-details-View overflow-auto kloo-scrollbar"
        >
          <PurchaseOrderPDFView
            showModal={showModal}
            hideModal={hideModal}
            reloadGRNI={reloadGRNI}
            reloadMatchedInvoiceList={reloadGRNI}
            reloadTable={reloadTable}
            enableGRNIFeature={enableGRNIFeature}
            enableMatchedInvoiceFeature={enableMatchedInvoiceFeature}
            onGRNIDataLoaded={onGRNIDataLoaded}
            onMatchedInvoicesDataLoaded={onMatchedInvoicesDataLoaded}
            setOnDeleteReload={setOnDeleteReload}
            taxCodeEnabled={taxCodeEnabled}
            entityEnabled={entityEnabled}
            defaultEntityList={defaultEntityList}
            version={version}
            {...poDetails}
            totalAmountRef={totalAmountRef}
          />
          <When condition={!isPlatformUser(userType)}>
            <AuditModal />
          </When>
        </Column>
        <Column className={"w-0px p-r-8px"}>
          <Divider type="vertical" className={"h-100 "} />
        </Column>
        <Column flex="auto" className={"p-0"}>
          <Box direction="column" className={"pdf-wrapper"} wrap="nowrap">
            <Box className={"h-100 w-100"}>
              <When condition={!!pdfLink}>
                <PDFViewer url={pdfLink} />
              </When>
            </Box>
            <Box direction="column" rowGap={5} className={"p-15px"}>
              <Box>
                <Typography
                  variant="secondary"
                  text={PURCHASE_ORDER_CLOSE_DRAWER.INFO_LABEL}
                />
                <Typography
                  variant="secondary"
                  fontStyle="semibold"
                  text={purchaseOrderID}
                />
              </Box>
              <When condition={!!reviewer}>
                <Box>
                  <Typography
                    variant="secondary"
                    text={PURCHASE_ORDER_CLOSE_DRAWER.APPROVED_BY}
                  />
                  <Typography
                    variant="secondary"
                    fontStyle="semibold"
                    text={reviewer}
                  />
                </Box>
              </When>
            </Box>
          </Box>
          <Row className="purchase-drawer-footer  m-0" gutter={10}>
            <Column flex="auto">
              <CustomButton
                text={PURCHASE_ORDER_CLOSE_DRAWER.DOWNLOAD_BUTTON_LABEL}
                icon={<Icon iconName={"file_download"} />}
                accent={ACCENTS.PRIMARY}
                variant={BUTTON_VARIANT.BUTTON_FILLED}
                onClick={onDownloadClick}
                disabled={!pdfLink}
                className="w-100"
                customSize={"large"}
              />
            </Column>
          </Row>
        </Column>
      </Row>
    </Drawer>
  );
}

ClosedPOViewDrawer.propTypes = {
  open: PropTypes.bool,
  purchaseOrderList: PropTypes.array,
  reloadTable: PropTypes.func,
  onClose: PropTypes.func,
  showModal: PropTypes.func,
};

export default ClosedPOViewDrawer;
