import { Typography } from "antd";
import { memo } from "react";
import { constructClassName } from "../../../utils/functions";

const { Link: ALink } = Typography;

function Link({ className, text, href, target = "_blank" }) {
  return (
    <ALink
      className={constructClassName(["kloo-link", className])}
      href={href}
      target={target}
    >
      {text}
    </ALink>
  );
}

export default memo(Link);
