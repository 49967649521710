import { Tabs } from "antd";
import React, { memo } from "react";
import "./TabsV2.scss";
import { constructClassName } from "../../../utils/functions";

function TabsV2({ className, ...rest }) {
  return (
    <Tabs
      animated={false}
      className={constructClassName(["kloo-tab", className])}
      {...rest}
    />
  );
}

export default memo(TabsV2);
