import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import { SWITCH_PROFILE } from "../service/types";
import { loadingStart, loadingStop } from "./loader";

export const multipleOrgnaisationDetail = (userId) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.switchProfile.getOrganisationMultipleProfile(userId),
    })
      .then((response) => {
        resolve(response.data);
        dispatch({
          type: SWITCH_PROFILE,
          payload: response?.data?.result,
        });
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
