import React, { useState } from "react";
import {
  checkPaymentConsentStatus,
  getPaymentAuthorizationToken,
} from "../../../../api";
import {
  NAVIGATION_URL,
  NOTIFICATION_TYPE,
  PAYMENT_JOURNEY_FORM_CONFIG,
} from "../../../../constants";
import {
  OPTIONAL_PAYLOAD_KEY_FOR_PAYMENT_JOURNEY,
  YAPILY_PIS_JOURNEY_CONFIG,
} from "../../../../constants/InvoiceManagement";
import { YapilyIntegrationConfig } from "../../../../constants/Integration/OpenBanking";
import {
  getCurrentEnvironmentURL,
  getItem,
  GLOBAL_NOT_APPLICABLE_SYMBOL,
  LOCAL_STORAGE_KEY,
  showNotification,
  sortCodeFormatter,
} from "../../../../utils";
import { Box, CustomButton, Icon, Typography, When } from "../../../common";
import YapilyConsentSteps from "./YapilyConsentSteps";

const { consentJourneyStepsConfig, consentStatusConfig } =
  YapilyIntegrationConfig;

const { stepKey: SELECT_INSTITUTION } = consentJourneyStepsConfig[0];

const YapilyPISJourney = ({
  onClose,
  onBack,
  onComplete,
  extraData,
  paymentSuccessConfig,
  paymentFailureConfig,
  togglePaymentFinalStatus,
  paymentFinalStatus,
}) => {
  const [yapilyCurrentStep, setYapilyCurrentStep] = useState(0);
  const [stepData, setCurrentStepData] = useState({ ...extraData });

  function gotoNextStep(data) {
    setCurrentStepData({
      ...stepData,
      [yapilyCurrentStep]: data,
    });
    setYapilyCurrentStep(yapilyCurrentStep + 1);
  }

  function gotoPreviousStep() {
    if (!yapilyCurrentStep) {
      onBack();
    }
    const updatedStepData = { ...stepData };
    updatedStepData[yapilyCurrentStep] &&
      delete updatedStepData[yapilyCurrentStep];
    setCurrentStepData(updatedStepData);
    setYapilyCurrentStep(yapilyCurrentStep - 1);
  }

  const PaymentCompletedView = () => {
    const currentStepHasError =
      stepData?.[yapilyCurrentStep]?.hasError || false;
    const {
      title,
      subTitle,
      paymentDetailsLabelConfig,
      doneButton,
      icon,
      cancelButton,
    } = currentStepHasError ? paymentFailureConfig : paymentSuccessConfig;

    const getStepValue = ({ key }) => {
      let value = stepData[key];
      if (key === PAYMENT_JOURNEY_FORM_CONFIG.sortCode.name) {
        value = sortCodeFormatter(stepData[key]);
      }
      return value || GLOBAL_NOT_APPLICABLE_SYMBOL;
    };

    return (
      <>
        <Box rowGap={16} direction={"column"}>
          <Icon
            iconName={"close"}
            className={"width-full cursor-pointer text-align-right"}
            onClick={currentStepHasError ? onClose : onComplete}
          />
          <Typography {...title} />
          <Typography {...subTitle} />
          <Icon {...icon} />
        </Box>
        <div className="m-h-40px m-t-16px">
          {paymentDetailsLabelConfig.map(({ text, key }) => {
            const RenderLabel = () => {
              return (
                <Box justifyContent={"spaceBetween"} className={"m-v-8px"}>
                  <Typography
                    variant={"body"}
                    fontStyle={"semibold"}
                    fontSize={14}
                    textColorType={"tertiary"}
                    text={text}
                  />
                  <Typography
                    variant={"body"}
                    fontStyle={"semibold"}
                    fontSize={14}
                    text={getStepValue({ key })}
                  />
                </Box>
              );
            };
            return <RenderLabel />;
          })}
          <When condition={!currentStepHasError}>
            <CustomButton {...doneButton} onClick={onComplete} />
          </When>
          <When condition={currentStepHasError}>
            <Box wrap={"nowrap"} className={"m-t-16px"} columnGap={16}>
              <CustomButton
                {...doneButton}
                onClick={() => {
                  togglePaymentFinalStatus();
                  onBack();
                }}
                icon={<Icon iconName={doneButton.icon} />}
              />
              <CustomButton {...cancelButton} onClick={onClose} />
            </Box>
          </When>
        </div>
      </>
    );
  };

  const onPaymentComplete = () => {
    togglePaymentFinalStatus();
  };

  function setErrorInStep() {
    setCurrentStepData({
      ...stepData,
      [yapilyCurrentStep]: {
        hasError: true,
      },
    });
  }

  function generateAuthorizationPromise() {
    function getPayloadEnvironmentWise() {
      let currentEnvironmentURL = getCurrentEnvironmentURL();
      const email = getItem(LOCAL_STORAGE_KEY.EMAIL);

      return {
        institutionId: stepData[SELECT_INSTITUTION].institution_id,
        applicationUserId: email,
        callback: `${currentEnvironmentURL}${NAVIGATION_URL.PIS_YAPILY_LANDING_PAGE(
          {
            paymentSource: extraData.paymentSource,
          }
        )}`,
        invoiceId: extraData.invoiceId,
        supplierId: extraData.supplierId,
        supplierAccountId: extraData.supplierAccountId,
      };
    }

    const payloadToBeSent = getPayloadEnvironmentWise();
    OPTIONAL_PAYLOAD_KEY_FOR_PAYMENT_JOURNEY.forEach((key) => {
      if (extraData[key]) {
        payloadToBeSent[key] = extraData[key];
      }
    });
    return getPaymentAuthorizationToken(payloadToBeSent)
      .then(({ data }) => {
        if (data?.result?.error) {
          showNotification(NOTIFICATION_TYPE.error, "Server Error");
          setErrorInStep();
        } else {
          setCurrentStepData({
            ...stepData,
            [yapilyCurrentStep]: data?.result,
          });
        }
      })
      .catch((error) => {
        showNotification(
          NOTIFICATION_TYPE.error,
          "Server Error",
          error?.response?.data?.message
        );
        setErrorInStep();
      });
  }

  function checkIfPaymentIsSuccess(status) {
    return [consentStatusConfig[3].key, consentStatusConfig[4].key].includes(
      status
    );
  }

  function checkIfPaymentIsFailed({ error, data }) {
    if (error) return true;
    else if (data?.status)
      return [consentStatusConfig[5].key].includes(data?.status);
    else return false;
  }

  function pollingPromise(id) {
    return checkPaymentConsentStatus(id)
      .then(({ data }) => {
        if (checkIfPaymentIsSuccess(data?.result?.data?.status)) {
          setCurrentStepData({
            ...stepData,
            [yapilyCurrentStep]: data?.result?.data,
            transactionId: data?.result?.data?.id,
          });
          onPaymentComplete();
        } else if (checkIfPaymentIsFailed(data?.result)) {
          setErrorInStep();
          onPaymentComplete();
        }
      })
      .catch(() => {
        setErrorInStep();
        onPaymentComplete();
      });
  }

  return (
    <>
      <div className="yapily-pis-journey-modal-container">
        <When condition={yapilyCurrentStep <= 2 && !paymentFinalStatus}>
          <YapilyConsentSteps
            currentStep={yapilyCurrentStep}
            stepData={stepData}
            next={gotoNextStep}
            prev={gotoPreviousStep}
            showTopNavigation={true}
            onClose={onClose}
            labelConfig={YAPILY_PIS_JOURNEY_CONFIG}
            pollingPromise={pollingPromise}
            generateAuthorizationPromise={generateAuthorizationPromise}
            showFooter={false}
          />
        </When>
        <When condition={paymentFinalStatus}>
          <PaymentCompletedView />
        </When>
      </div>
    </>
  );
};

export default YapilyPISJourney;
