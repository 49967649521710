import {
  APPROVED_SETTLED,
  CARD_EXPENSE_TABS,
  USER_ROLES,
  USER_ROLES_KEYS,
  ENVIRONMENT_URL,
  OUTSTANDING_SUBSCRIPTION,
  SCROLL_TOP_CLASSNAME,
  LOCAL_STORAGE_KEY,
  AUTOMATION_OWNER_PAYEE_TRIGGER,
} from "../constant";
import AutomaticSubscriptionIcon from "../../assets/icons/AutomaticSubscriptionIcon";
import ManualSubscriptionIcon from "../../assets/icons/ManualSubscriptionIcon";
import {
  CARDS_EXPENSES_STATUS,
  GBP_CURRENCY_ID,
  MATCH_TYPE,
  ORGANISATION_TYPE,
  SELECT_TYPE_ATTRIBUTES,
} from "../../constants";
import {
  getItem,
  getMaxRule,
  getRequiredRule,
  RE_DECIMAL_NUMBER,
  SMART_APPROVAL_DEFAULT_MINIMUM_NO_OF_WORKFLOWS,
} from "..";
import { versionForNewCustomField } from "../../helper/Categorisation";
import imageCompression from "browser-image-compression";
import { isDefaultCurrencyFeatureEnabled } from "../../helper/SmartApprovals";

const Base_URL = process.env.REACT_APP_URL;

export const findMenu = (arr, str) => {
  if (!Array.isArray(arr)) {
    return false;
  }
  return arr.includes(str || "");
};

export const download = async (attachment, fileName = "Sample", e) => {
  e.stopPropagation();
  const data = await fetch(attachment);
  const blob = await data.blob();
  // saveAs(blob,name);
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export function capitalizeFirstLetter(string) {
  return string === "N/A"
    ? string
    : string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase() || "";
}
export function capitalizeFirst(string) {
  return string ? string?.charAt(0)?.toUpperCase() + string?.slice(1) : "";
}

export function capitalizeEachWord(string) {
  if (string === "N/A") {
    return string;
  }
  const words = string?.split(" ");

  const capitalizedWords = words?.map((word) => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase() || "";
  });

  return capitalizedWords?.join(" ");
}

export function constructClassName(classNames = []) {
  return [...new Set(classNames)]
    .filter((className) => className && className !== "")
    .join(" ");
}

export function isDevelopmentEnvironment() {
  return Base_URL === "https://dev.getkloo.com/api";
}

export function isStageEnvironment() {
  return Base_URL === "https://stage.getkloo.com/api";
}

export function isPlatformEnvironment() {
  return (
    Base_URL === "https://platform.getkloo.com/api" || isEngageEnvironment()
  );
}

export function isEngageEnvironment() {
  return Base_URL === "https://engage.getkloo.com/api";
}

export function isPlatformUser(userType) {
  return userType === USER_ROLES[USER_ROLES_KEYS.PLATFORM_USER].dbMappingKey;
}

export function isOrgAdmin(userType) {
  return (
    userType === USER_ROLES[USER_ROLES_KEYS.ORGANISATION_ADMIN].dbMappingKey
  );
}

export function isFaUser(userType) {
  return userType === USER_ROLES[USER_ROLES_KEYS.FINANCE_APPROVER].dbMappingKey;
}

export function getCurrentEnvironmentURL() {
  if (isPlatformEnvironment()) {
    return ENVIRONMENT_URL.PLATFORM;
  } else if (isStageEnvironment()) {
    return ENVIRONMENT_URL.STAGE;
  } else {
    return ENVIRONMENT_URL.DEV;
  }
}

export function isOrgTypeOthers({ orgType }) {
  return orgType === ORGANISATION_TYPE.OTHERS.key;
}

export function isOrgTypeOnlyPO({ orgType, subOrgType }) {
  return (
    isOrgTypeOthers({ orgType }) &&
    subOrgType?.length === 1 &&
    subOrgType?.[0] === ORGANISATION_TYPE.OTHERS.SUB_TYPE.PO
  );
}

export function getSelectedItemsFromFilters(filters, selectedkeys) {
  const temp = [];
  filters.forEach((element) => {
    element.children.forEach((child) => {
      if (selectedkeys.includes(child.key)) {
        temp.push(child);
      }
    });
  });
  return temp;
}

export function isDeepEqual(object1, object2) {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
}

export function isObject(object) {
  return object != null && typeof object === "object";
}

export function getUserName(user, fallback = "--") {
  let fullName = fallback;
  if (user?.first_name && user?.last_name) {
    fullName = `${user.first_name} ${user.last_name}`;
  }
  return fullName;
}

export function getFullAddress(address, fallback = "N/A") {
  let fullAddress = "";
  if (!address) {
    return fallback;
  }
  if (address?.addressline1) {
    fullAddress += address?.addressline1;
  }
  if (address?.addressline2) {
    fullAddress += ", " + address?.addressline2;
  }
  if (address?.city) {
    fullAddress += ", " + address?.city;
  }
  if (address?.country) {
    fullAddress += ", " + address?.country;
  }
  if (address?.postcode) {
    fullAddress += ", " + address?.postcode;
  }
  if (address?.posttown) {
    fullAddress += ", " + address?.posttown;
  }
  return fullAddress;
}

export function renderAmount(amount, format) {
  if (amount === "") {
    return;
  }
  switch (format) {
    case "commaWithDecimal":
      return Number(parseFloat(amount).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2,
      });
    default:
      return `£${(Number(amount) || 0).toFixed(2)}`;
  }
}

export function renderRequestType(requestType) {
  return requestType?.replace(/-/g, " ") || "N/A";
}

export const restrictToTwoDigitsAfterDecimal = (value) => {
  if (value !== "") {
    const t = value.split(".");
    const decimal = t[1]?.slice(0, 1) || "";
    const number = `${t[0]}.${decimal}`;
    return number;
  }
};

export function canShowOutstandingTabRowDetails(type, activity_status) {
  return (
    type === CARD_EXPENSE_TABS.OUTSTANDING &&
    APPROVED_SETTLED.includes(activity_status)
  );
}

export const blockInvalidChar = (e) => {
  if (
    !(
      ["Backspace", "ArrowLeft", "ArrowRight", "."].includes(e.key) ||
      e.key >= 0 ||
      e.key <= 9
    )
  ) {
    e.preventDefault();
  }
};

export const SubscriptionTypeMsg = (key) => {
  return key === OUTSTANDING_SUBSCRIPTION.Automated
    ? OUTSTANDING_SUBSCRIPTION.Automsg
    : key === OUTSTANDING_SUBSCRIPTION.Manual
    ? OUTSTANDING_SUBSCRIPTION.manualMsg
    : null;
};

export const getSubscriptionSymbol = (source) => {
  return source === OUTSTANDING_SUBSCRIPTION.Automated ? (
    <AutomaticSubscriptionIcon />
  ) : source === OUTSTANDING_SUBSCRIPTION.Manual ? (
    <ManualSubscriptionIcon />
  ) : (
    ""
  );
};

export const vatAmountScrollTop = () =>
  (document.getElementsByClassName(SCROLL_TOP_CLASSNAME)[0].scrollTop = 0);

export const convertJSObjectToFormData = (request) => {
  const formData = new FormData();
  for (const key in request) {
    formData.append(key, request[key]);
  }
  return formData;
};

export const convertFormDataToJSObject = (formData) => {
  let object = {};
  formData.forEach((value, key) => (object[key] = value));
  return object;
};

export const boolToYesNo = (bool) => {
  return bool ? "Yes" : "No";
};

export const getWorkFlow = (data, users, roles, attributes) => {
  return [
    ...data?.map((row) => {
      row.workflowsteps = row.workflowsteps.map((workflowstep) => {
        workflowstep.workFlowstepData = users?.find(
          ({ value }) => value === workflowstep.approver_type_id
        );
        workflowstep.workFlowstepData =
          workflowstep.workFlowstepData ||
          roles
            .filter(({ id }) => id === workflowstep.approver_type_id)
            .map((role) => ({ ...role, label: role.name }))?.[0];
        workflowstep.workFlowstepData =
          workflowstep.workFlowstepData ||
          attributes
            .filter(({ id }) => id === workflowstep.approver_type_id)
            .map((attribute) => ({ ...attribute, label: attribute.name }))?.[0];
        return workflowstep;
      });
      row.visibile = true;
      return row;
    }),
  ];
};

export const getWorkFlowV1 = (data, users, roles, attributes) => {
  return [
    ...data.map((row, index) => {
      row.workflow_children = row?.workflow_children?.length
        ? getWorkFlow(
            row.workflow_children,
            users[1]?.options,
            roles,
            attributes
          )
        : [];
      if (row.workflowsteps?.length && !row.workflow_children?.length) {
        row.workflow_children = getWorkFlow(
          [{ workflowsteps: row.workflowsteps }],
          users[1]?.options,
          roles,
          attributes
        ).map((step) => ({ ...step, isOldStep: true }));
      }
      row.label = row.workflow_name;
      row.value = row.id;
      row.visibile = index < SMART_APPROVAL_DEFAULT_MINIMUM_NO_OF_WORKFLOWS;
      return row;
    }),
  ];
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const fileNameForExcel = ({ fileName }) => {
  const fileNameArray = fileName?.split("/");
  const fileIndex = fileNameArray.length - 1;
  return fileNameArray[fileIndex];
};

export const downloadFileByJS = (fileURL, fileName, headers = {}) => {
  fetch(fileURL, { headers })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then((blob) => {
      const objectURL = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = objectURL;
      a.download = fileName;
      document.body.appendChild(a); // Append link to the body (required for some browsers)
      a.click();
      a.remove(); // Remove the link after clicking

      // Release memory once the file is downloaded
      window.URL.revokeObjectURL(objectURL);
    })
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
    });
};

export function camelize(text) {
  const a = text
    .toLowerCase()
    .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));
  return a.substring(0, 1).toLowerCase() + a.substring(1);
}

export function keyDifference({ comparingObj, obj }) {
  const comparingValue = Array.isArray(obj) ? obj : Object.keys(obj);
  return comparingValue.filter(
    (key) => !Object.keys(comparingObj).includes(key)
  );
}

const getCustomFieldExtraData = ({
  currentCustomFieldsData,
  customFieldsData,
  customFieldId,
  customFieldValue,
}) => {
  let extraDataToReturn =
    currentCustomFieldsData.find(
      ({ custom_field_id }) => custom_field_id === customFieldId
    ) || {};
  if (!Object.keys(extraDataToReturn).length && customFieldsData?.length) {
    const customFieldDataMatched = customFieldsData.find(
      ({ inputId }) => inputId === customFieldId
    );
    if (customFieldDataMatched) {
      if (customFieldDataMatched?.field_validation_configurations) {
        extraDataToReturn.field_validation_configurations =
          customFieldDataMatched.field_validation_configurations;
      }
      if (customFieldDataMatched.options?.length) {
        const customFieldOptionValueMatched =
          customFieldDataMatched.options.find(
            ({ id }) => id === customFieldValue
          );
        if (customFieldOptionValueMatched) {
          const { field_validation_config_id, id, label, name, value } =
            customFieldOptionValueMatched;
          extraDataToReturn.field_values = {
            field_validation_config_id,
            label,
            name,
            value,
            id,
          };
        }
      }
    }
  }
  return extraDataToReturn;
};

const getAllConditionalList = ({ filteredConditionalData, key, values }) => {
  let conditionalIdList = [];
  filteredConditionalData.map((conditionalData) => {
    let checkConditionalParentIndex = [
      ...[Array.from(conditionalData?.triggeredBy)[0]],
    ].indexOf(key);
    if (
      checkConditionalParentIndex >= 0 &&
      values[conditionalData?.custom_field_id]
    ) {
      conditionalIdList.push({
        id: conditionalData?.id,
        custom_field_id: conditionalData?.custom_field_id,
        value: values[conditionalData?.custom_field_id] || null,
      });
    }
  });
  return conditionalIdList;
};

export function generateCustomValues({
  customKeys,
  values,
  forEdit,
  customFieldsData = [],
  currentCustomFieldsData = [],
  filteredConditionalData = [],
}) {
  const customFields = customKeys.map((key) => ({
    custom_field_id: key,
    value: values[key],
    id: customFieldsData.find((cfd) => cfd.custom_field_id === key)?.id,
    conditional_fields: [
      ...getAllConditionalList({
        filteredConditionalData,
        key: customFieldsData.find((cfd) => cfd.custom_field_id === key)?.id,
        values,
      }),
    ],
  }));
  const newCustomData = [];
  customFields.forEach((field) => {
    if (!field?.value) {
      if (forEdit) {
        newCustomData.push({
          ...getCustomFieldExtraData({
            currentCustomFieldsData,
            customFieldsData,
            customFieldId: field.custom_field_id,
            customFieldValue: "",
          }),
          ...field,
        });
      }
    } else {
      newCustomData.push({
        ...getCustomFieldExtraData({
          currentCustomFieldsData,
          customFieldsData,
          customFieldId: field.custom_field_id,
          customFieldValue: field.value,
        }),
        ...field,
      });
    }
  });
  return newCustomData;
}

export function checkIfCustomFieldDataIsRemoved({
  customFields,
  currentCustomFields,
}) {
  return customFields?.reduce((acc, { custom_field_id, id }) => {
    if (!currentCustomFields?.[custom_field_id]) {
      acc.push(id);
    }
    return acc;
  }, []);
}

export function getCustomFieldForFromValues({ customDetails, version }) {
  let customKeyValuePair = {};
  customDetails.forEach((field) => {
    const key = versionForNewCustomField(version)
      ? field.custom_fields_has_form_option_id
      : field.custom_field_id;
    customKeyValuePair[key] = versionForNewCustomField(version)
      ? field.user_input
      : field.value;
  });
  return customKeyValuePair;
}

export const moneyFormatter = (money, decimal, currency = "GBP") => {
  const options = {
    style: "currency",
    currency,
    currencyDisplay: "narrowSymbol",
  };

  if (decimal) {
    options.minimumFractionDigits = decimal;
  }
  return new Intl.NumberFormat("en-GB", options).format(money);
};

export const roundOff = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const hasEmptyObject = (arr) => {
  for (let i = 0; i < arr.length; i++) {
    if (Object.keys(arr[i]).length === 0) {
      return true;
    }
  }
  return false;
};

export const findIndexOfEmptyObject = (arr) => {
  return arr.findIndex((obj) => Object.keys(obj).length === 0);
};

export function disableReversDecline(type, activity_status) {
  const { REVERSED, REFUND } = { ...CARDS_EXPENSES_STATUS };
  const DISABLE_FIELDS_IN_DRAWER_WHILE_ARCHIVING = [
    "reversal",
    "declined",
    REVERSED,
    REFUND,
  ];
  return (
    type === CARD_EXPENSE_TABS.OUTSTANDING &&
    DISABLE_FIELDS_IN_DRAWER_WHILE_ARCHIVING.includes(activity_status)
  );
}

const suffixes = {
  one: "st",
  two: "nd",
  few: "rd",
  other: "th",
};

export function ordinal(number) {
  const english_ordinal_rules = new Intl.PluralRules("en", { type: "ordinal" });
  const category = english_ordinal_rules.select(number);
  const suffix = suffixes[category];
  return number + suffix;
}

export function deepClone(obj) {
  if (Array.isArray(obj)) {
    return obj.map(deepClone);
  } else if (typeof obj === "object" && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, deepClone(value)])
    );
  } else {
    return obj;
  }
}

export function getCuratedFilterList({ currentFilters, filters }) {
  return currentFilters?.filter((selectedItem) => {
    const [parentId] = selectedItem.split("-");
    const parentElement = filters.find((id) => parentId === id.key);
    return (
      parentElement &&
      parentElement.children.find((childId) => selectedItem === childId.key)
    );
  });
}

export function getUserOrgId() {
  return getItem(LOCAL_STORAGE_KEY.USER_ORD_ID);
}

export function getOrgId() {
  return getItem(LOCAL_STORAGE_KEY.ORD_ID);
}

export function getMinMaxValues(values) {
  const minValue =
    values[0].replace("min_", "") === "null"
      ? ""
      : values[0].replace("min_", "");
  const maxValue =
    values[1].replace("max_", "") === "null"
      ? ""
      : values[1].replace("max_", "");
  if (minValue === "" && maxValue === "") {
    return true;
  }
  return false;
}

export function automationTriggerMsg(key, type, tab) {
  return AUTOMATION_OWNER_PAYEE_TRIGGER(key, type, tab);
}

export function sortingArray(arrayValue, sortingKey) {
  const sortingValue = arrayValue.sort((a, b) => {
    const nameA = a[sortingKey].toUpperCase();
    const nameB = b[sortingKey].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return sortingValue;
}

// Currency Conversion to GBP Function
export function convertToGBP(amount, currencies, fromCurrency = "GBP") {
  // Conversion rates to GBP
  const conversionRate = currencies.find(
    (item) => item.currency === fromCurrency
  )?.value;
  // Check if the conversion rate exists for the given currency
  if (!conversionRate) {
    return "";
  }
  // Perform the currency conversion to GBP
  const convertedAmount = amount / conversionRate;
  // Return the converted amount
  return parseFloat(convertedAmount.toFixed(2));
}

export function calculatePercentageAmount(percentage, amount) {
  const percentageAmount = (percentage / 100) * amount;
  return parseFloat(percentageAmount.toFixed(2));
}

export function getLeastThresholdValue({
  fixedAmount,
  threshold,
  poNetAmount,
}) {
  const poAmountToBeConsideredWithThreshold = calculatePercentageAmount(
    threshold,
    poNetAmount
  );

  return threshold && fixedAmount
    ? Math.min(poAmountToBeConsideredWithThreshold, fixedAmount)
    : threshold
    ? poAmountToBeConsideredWithThreshold
    : fixedAmount;
}

export function calculateMatchType({
  thresholdValue,
  invoiceAmount,
  POAmount,
  fixedValue,
  poNetAmount,
}) {
  const remainingAmount = roundOff(invoiceAmount - POAmount);
  const poRemainingAmount =
    invoiceAmount >= POAmount ? 0 : POAmount - invoiceAmount;
  if (POAmount === 0 && POAmount !== invoiceAmount) {
    return {
      matchType: MATCH_TYPE.NOT_A_MATCH,
      remainingAmount,
      invoiceAmount,
      poRemainingAmount,
    };
  }

  if (POAmount >= invoiceAmount) {
    return {
      matchType: MATCH_TYPE.CONFIRMED,
      remainingAmount: POAmount - invoiceAmount,
      invoiceAmount,
      poRemainingAmount,
    };
  }

  const thresholdAmount = calculatePercentageAmount(
    thresholdValue,
    poNetAmount
  );
  let thresholdToConsider =
    thresholdValue && fixedValue
      ? Math.min(thresholdAmount, fixedValue)
      : thresholdValue
      ? thresholdAmount
      : fixedValue;

  const isBelowThreshold = remainingAmount <= thresholdToConsider;

  return {
    matchType: isBelowThreshold ? MATCH_TYPE.THRESHOLD : MATCH_TYPE.NOT_A_MATCH,
    remainingAmount,
    invoiceAmount,
    poRemainingAmount,
  };
}

export const getOnlyAmount = (amount, symbol) => {
  const numericString = amount?.toString().replace(/[^0-9.-]+/g, "");
  return parseFloat(numericString);
};

export function getArrayLength(arr) {
  if (Array.isArray(arr)) {
    return arr?.length;
  }
  return 0;
}

export const validString = (string) => string?.length && string !== "N/A";

export const getFileNameFromURL = (url) => {
  const fileNameRegex = /[^/]+(?=\.[^.]+$)/;
  return url.match(fileNameRegex)[0];
};

export const checkIfUserHasPermission = ({
  permissionConfig = [],
  permissionsId,
}) => {
  return permissionConfig !== null && permissionConfig.includes(permissionsId);
};

export const scrollToTop = (ref) => {
  if (ref.current) {
    ref.current.scrollTop = 0;
  }
};

export const removeLastSlash = (string) => {
  return string[string.length - 1] === "/" ? string.slice(0, -1) : string;
};

export function formatPhoneNumber(phoneNumber, includeCountryCode = false) {
  if (!phoneNumber) {
    return;
  }
  // Remove all non-digit characters from the input
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Check if the phone number includes a country code
  const hasCountryCode = cleaned.length > 10;

  // Format the phone number with or without the country code
  if (includeCountryCode && hasCountryCode) {
    return `+${cleaned.replace(
      /(\d{1,3})(\d{3})(\d{3})(\d{4})/,
      "$1 $2-$3-$4"
    )}`;
  } else {
    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
}

export const allowOnlyNumberOnInputChange = (e) => {
  const inputValue = e.target.value;

  // Remove any non-numeric characters from the input
  const numericValue = allowOnlyNumberOnInputChangeUsingValue(inputValue);

  // Update the input value directly
  e.target.value = numericValue;
};

export const allowOnlyNumberOnInputChangeUsingValue = (value) => {
  // Remove any non-numeric characters from the input
  const numericValue = value.replace(/[^0-9.]/g, "");

  // Update the input value directly
  return numericValue;
};

export const handlePhoneInputChange = (e) => {
  const inputValue = e.target.value;

  // Remove all non-digit characters except the first "+"
  const numericValue = inputValue.replace(/[^\d+]/g, "");

  // Update the input value directly
  e.target.value = numericValue;
};

export function removeWhiteSpace(str) {
  return str ? str.replace(/\s/g, "") : "";
}

export function hideInAllEnvironments() {
  return (
    !isDevelopmentEnvironment() ||
    !isStageEnvironment() ||
    !isPlatformEnvironment()
  );
}

export const isEven = (number) => {
  return number % 2 === 0;
};
export const textAreaDisableEnter = (e) => {
  e.preventDefault();
};

export function generateColorCode() {
  return `#${Math?.floor(Math?.random() * 16777215)?.toString(16)}`;
}

export const getApprover = (ROLE_ATTR_ARRAY, workflowstep, APPROVER_EDIT) => {
  return ROLE_ATTR_ARRAY.includes(workflowstep?.approver_type)
    ? workflowstep?.approver?.name
    : workflowstep?.approver_type === "user" &&
      workflowstep?.approver?.status === "active"
    ? getUserName(workflowstep?.approver?.user)
    : APPROVER_EDIT;
};
export const hideCustomFieldByAiFeature = () => isPlatformEnvironment();

export const normalize = (value, previousValue) => {
  if ((RE_DECIMAL_NUMBER.test(value) && value <= 100) || !value) {
    return value;
  } else {
    return previousValue;
  }
};

export const formatCurrency = (value) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const parseCurrency = (value) => {
  const regex = new RegExp(`s*|(,*)`, "g");
  return value.replace(regex, "");
};

export const isPDF = (string) => string?.toLowerCase()?.includes(".pdf");

export const addRequiredKeysForCustomFields = (data) => {
  for (let i = 0; i < data?.length; i++) {
    const element = data[i];
    if (SELECT_TYPE_ATTRIBUTES.includes(element.type)) {
      element.inputType = "SELECT";
      element.placeHolder = "Select " + element.label;
    } else {
      element.inputType = "TEXT";
      element.placeHolder = "Enter " + element.label;
    }
    element.inputLabel = element.label;
    element.inputId = element.id;
    element.name = element.custom_fields_has_form_option_id;
    element.custom_field_id = element.custom_fields_has_form_option_id;
    element.options = element.values.map((item) => {
      return {
        ...item,
        label: item.label,
        value: item.value,
      };
    });
    element.rules = [];
    if (element.required) {
      element.rules.push(
        getRequiredRule(
          "",
          false,
          "Your organisation requires this field to be completed"
        )
      );
    }
    if (!SELECT_TYPE_ATTRIBUTES.includes(element.type)) {
      element.rules.push(getMaxRule(255));
    }
  }
  return data;
};

export const scrollToElement = ({ ref, behavior = "smooth" }) => {
  ref?.current?.scrollIntoView({ behavior });
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getPercentage = ({ part, whole }) =>
  roundOff((part / whole) * 100);

export function calculateTotalAmount(netAmount, taxPercentage) {
  const netAmountValue = Number(netAmount) || 0;
  const taxPercentageValue = Number(taxPercentage) || 0;

  const taxAmount = (netAmountValue * taxPercentageValue) / 100;
  const totalAmount = netAmountValue + taxAmount;

  return totalAmount;
}

export const GENERATE_HELPER_TEXT = ({ helperText, value, valueFontType }) => {
  return {
    key: {
      variant: "body",
      text: helperText,
      fontSize: "14",
    },
    value: {
      variant: "secondary",
      text: value,
      fontSize: "14",
      fontStyle: "semibold",
      textColorType: valueFontType,
    },
  };
};

//Generates initial filter state based on provided tabs key.
export function generateInitialFilterState(tabsKeys) {
  // Function to create initial state for each tab
  function createInitialState() {
    // Map through each tab key and create initial state for it
    const initialStateArray = Object.values(tabsKeys)?.map((key) => ({
      [key]: { selectedFilterKeys: [], filterData: [] }, // Initial state structure
    }));
    if (getArrayLength(initialStateArray) > 0) {
      return Object.fromEntries(
        initialStateArray.map((obj) => Object.entries(obj)?.[0])
      );
    } else {
      return {};
    }
  }

  if (isObject(tabsKeys)) {
    return createInitialState();
  } else {
    return {}; // Return empty object if tabsKeys is invalid
  }
}

export function findFiltersWithRanges(data) {
  return [
    ...new Set(
      data
        .filter(
          (string) =>
            string.includes(",") || string.includes("remaining_amount")
        )
        .map((string) => string.split("-")[0].split(","))
        .flat()
    ),
  ];
}

export function addLatestAmountFilter(data, selectedFilterKeys) {
  const keysWithRanges = findFiltersWithRanges(data);
  const selectedKeysWithRanges = selectedFilterKeys.filter(
    (string) => string.includes(",") || string.includes("remaining_amount")
  );

  let includedItems = keysWithRanges?.filter((string) =>
    selectedFilterKeys.some((item) => item.startsWith(string))
  );

  const removedPreviousRangeKeys = data?.filter(
    (string) => !includedItems?.some((item) => string.startsWith(item))
  );

  if (includedItems.length > 0) {
    return [...removedPreviousRangeKeys, ...selectedKeysWithRanges];
  } else {
    return data;
  }
}

export function mergeAllTabsSelectedKeys(data) {
  // Extract values of all selectedFilterkeys from each tab and combine.
  return [
    ...new Set(
      data &&
        Object.values(data).flatMap((obj) => obj?.selectedFilterKeys || [])
    ),
  ];
}

export function deleteKeyFromAllTabs(object, setOfKeysToDelete) {
  Object.entries(object).forEach(([key, value]) => {
    if (value.hasOwnProperty("selectedFilterKeys")) {
      // Filter out the specified filter keys from the 'selectedFilterKeys' array
      object[key].selectedFilterKeys = value.selectedFilterKeys.filter(
        (item) => !setOfKeysToDelete.includes(item)
      );
    }
  });
  return object;
}

export const compareStrings = (string1, string2) => {
  if (typeof string1 !== "string" || typeof string2 !== "string") {
    return false;
  }
  const formattedString1 = string1?.trim()?.toLowerCase() ?? "";
  const formattedString2 = string2?.trim().toLowerCase() ?? "";

  return formattedString1 === formattedString2;
};

export async function compressImage(file, maxSize) {
  const options = {
    maxSizeMB: maxSize,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  return imageCompression(file, options);
}

export const arrayToString = (array, delimiter = ",") => array.join(",");

export const stringToArray = (string, delimiter = ",") => string.split(",");

export const isStringOrInArray = (argument, target) => {
  if (typeof argument === "string") {
    return argument === target;
  } else if (Array.isArray(argument)) {
    return argument.includes(target);
  }
  return false;
};

export const isQAAutomationOrganisation = () => {
  const QA_AUTOMATION_ORG_IDs = [
    "5f9a497d-9fea-4e2a-9bad-500917c9c39f", // Dev // QA automation org id
    "e0a40e47-18cd-11ef-95fa-0697c1cd8491", // Dev // Dev Automation NYC org id
  ];
  const orgId = getItem(LOCAL_STORAGE_KEY.ORD_ID);
  return isDevelopmentEnvironment() && QA_AUTOMATION_ORG_IDs.includes(orgId);
};

export function getDefaultCurrency({ accountDetail }) {
  if (
    isDefaultCurrencyFeatureEnabled() &&
    accountDetail &&
    accountDetail?.default_currency_id
  ) {
    return accountDetail.default_currency_id;
  }

  return GBP_CURRENCY_ID;
}
