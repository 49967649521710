import { DEFAULT_FORMAT, PR_CONFIG_PAYMENT_FILE } from "../../utils";
import {
  LC_SUPPLIER_PLACEHOLDER,
  LC_SUPPLIERS_PLACEHOLDER,
  SUPPLIER_PLACEHOLDER,
} from "../dynamic";

export const PAGE_HEADER_INFO_PAYMENT_RUNS = {
  PAYMENT_RUNS: {
    desc: "Create a payment run so that multiple invoices can be paid in one go on the same date.",
    header: "Payment runs",
    buttonIcon: "add_circle",
    buttonLabel: "Create payment run",
    buttonLabelMobile: "Create payment run",
  },
};

export const PAYMENT_RUNS_TABS_KEYS = {
  CREATED: "submitted",
  IN_REVIEW: "myApprovals",
  TO_PAY: "to_pay",
  SCHEDULED: "scheduled",
  PAID: "paid",
};

export const PAYMENT_RUNS_BUTTONS = {
  DELETE_PR_BUTTON_LABEL: (selectedItems) =>
    `Delete payment ${selectedItems?.length > 1 ? "runs" : "run"}`,
  CANCEL_PR_BUTTON_LABEL: (selectedItems) =>
    `Cancel payment ${selectedItems?.length > 1 ? "runs" : "run"}`,
  DOWNLOAD: "Download",
};

export const PAYMENT_RUNS_TABLE_COLUMNS_VIEW_KEY = "view";
export const DELETE_BUTTON_KEY = "delete";
export const PAYMENT_RUNS_TABLE_INVOICE_NUMBER_KEY = "invoice_number";
export const PAYMENT_RUNS_TABLE_INVOICE_OWNER_KEY = "owner_full_name";
export const PAYMENT_RUNS_TABLE_SUPPLIERS_KEY = "supplier_name";
export const PAYMENT_RUNS_TABLE_PRIORITY_COLUMN_KEY = "priority";
export const DOWNLOAD_PDF_BUTTON_KEY = "download";
export const INVOICE_OWNER_NAME_KEY = "owner_full_name";
export const PAYMENT_RUNS_TABLE_COLUMNS_STATUS_KEY = "status";
export const PAYMENT_RUNS_PAYMENT_TYPE = "payment_mode";
export const PAYMENT_RUNS_DESCRIPTION = "description";
export const PAYMENT_RUNS_RAISED_BY = "raised_by";

export const PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS = {
  invoiceNumber: {
    label: "Invoice number",
    key: PAYMENT_RUNS_TABLE_INVOICE_NUMBER_KEY,
    sortingKey: "invoice_number",
  },
  dueDate: { label: "Due date", key: "dueDate", sortingKey: "due_date" },
  suppliers: {
    label: SUPPLIER_PLACEHOLDER,
    key: PAYMENT_RUNS_TABLE_SUPPLIERS_KEY,
    sortingKey: "supplier.name",
  },
  priority: {
    label: "Priority",
    key: "priority",
    sortingKey: "supplier.priority",
  },
  description: {
    label: "Description",
    key: "description",
    ellipsis: true,
    sortingKey: "description",
  },
  matchType: {
    label: "Match type",
    key: "matchType",
    sortingKey: "matchType",
  },
  invoiceAmount: {
    label: "Total amount",
    key: "invoiceAmount",
    sortingKey: "convert_invoice_amount",
  },
  invoiceAmtWithoutSymbol: {
    label: "Amount",
    key: "invoiceAmtWithoutSymbol",
    sortingKey: "convert_invoice_amount",
  },
  convertedInvoiceAmount: {
    label: "Converted amount",
    key: "convert_invoice_amount",
    sortingKey: "convert_invoice_amount",
  },
  remove: { label: "", key: DELETE_BUTTON_KEY },
};

export const PAYMENT_RUNS_TABLE_CREATED_APPROVED_LIST_COLUMNS = [
  { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.invoiceNumber, width: 15 },
  { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.dueDate, width: 15 },
  { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.suppliers, width: 15 },
  {
    ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.priority,
    width: 20,
  },
  { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.description, width: 20 },
  {
    ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.invoiceAmount,
    width: 15,
    align: "right",
  },
];

export const PAYMENT_RUNS_TABLE_CREATED_APPROVED_COLLAPSED_COLUMNS = [
  { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.invoiceNumber, width: 20 },
  { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.suppliers, width: 20 },
  {
    ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.priority,
    width: 20,
  },
  { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.dueDate, width: 15 },
  {
    ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.invoiceAmount,
    width: 25,
    align: "right",
  },
];

export const PAYMENT_RUNS_TABLE_COLUMNS = {
  paymentRunName: {
    label: "Payment run name",
    key: "name",
    sortingKey: "name",
  },
  createdBy: {
    label: "Created by",
    key: "created_by_user",
    sortingKey: "created_by_user",
  },
  suppliers: {
    label: `${SUPPLIER_PLACEHOLDER}(s)`,
    key: PAYMENT_RUNS_TABLE_SUPPLIERS_KEY,
    sortingKey: PAYMENT_RUNS_TABLE_SUPPLIERS_KEY,
  },
  overdueAmount: {
    label: "Total overdue amount",
    key: "totalOverdueAmount",
    sortingKey: "total_invoice_overdue_amount",
  },
  totalAmount: {
    label: "Total amount",
    key: "totalAmount",
    sortingKey: "total_invoice_amount",
  },
  view: {
    label: "View",
    key: PAYMENT_RUNS_TABLE_COLUMNS_VIEW_KEY,
    sortingKey: PAYMENT_RUNS_TABLE_COLUMNS_VIEW_KEY,
  },
  scheduledDate: {
    label: "Scheduled date",
    key: "scheduledDate",
    sortingKey: "scheduled_date",
  },
  paymentType: {
    label: "Payment type",
    key: PAYMENT_RUNS_PAYMENT_TYPE,
    sortingKey: PAYMENT_RUNS_PAYMENT_TYPE,
  },
  batchReferenceNumber: {
    label: "Batch reference number",
    key: "batch_ref_id",
  },
  paymentDate: {
    label: "Payment date",
    key: "paymentDate",
    sortingKey: "payment_date",
  },
};

export const PAYMENT_RUNS_TABLE_CREATED_COLUMNS = [
  { ...PAYMENT_RUNS_TABLE_COLUMNS.paymentRunName, width: 18 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.createdBy, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.scheduledDate, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.totalAmount, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.overdueAmount, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.view, width: 10, align: "center" },
];

export const PAYMENT_RUNS_TABLE_IN_REVIEW_COLUMNS = [
  { ...PAYMENT_RUNS_TABLE_COLUMNS.paymentRunName, width: 18 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.createdBy, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.scheduledDate, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.totalAmount, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.overdueAmount, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.view, width: 10, align: "center" },
];

export const PAYMENT_RUNS_TABLE_TO_PAY_COLUMNS = [
  { ...PAYMENT_RUNS_TABLE_COLUMNS.paymentRunName, width: 18 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.createdBy, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.scheduledDate, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.totalAmount, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.overdueAmount, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.view, width: 10, align: "center" },
];

export const PAYMENT_RUNS_TABLE_SCHEDULED_COLUMNS = [
  { ...PAYMENT_RUNS_TABLE_COLUMNS.paymentRunName, width: 18 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.createdBy, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.scheduledDate, width: 10 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.paymentType, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.totalAmount, width: 10 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.overdueAmount, width: 10 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.view, width: 10, align: "center" },
];

export const PAYMENT_RUNS_TABLE_PAID_COLUMNS = [
  { ...PAYMENT_RUNS_TABLE_COLUMNS.paymentRunName, width: 18 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.createdBy, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.paymentType, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.paymentDate, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.totalAmount, width: 15 },
  { ...PAYMENT_RUNS_TABLE_COLUMNS.view, width: 10, align: "center" },
];

export const VIEW_MODAL_TABLE_COLUMNS = {
  paymentRunName: { label: "Payment run name", key: "name" },
  invoiceNumber: {
    label: "Invoice number",
    key: PAYMENT_RUNS_TABLE_INVOICE_NUMBER_KEY,
  },
  invoiceOwner: {
    label: "Owner",
    key: PAYMENT_RUNS_TABLE_INVOICE_OWNER_KEY,
  },
  suppliers: { label: SUPPLIER_PLACEHOLDER, key: "suppliers" },
  priority: { label: "Priority", key: "priority" },
  description: { label: "Description", key: "description" },
  raisedBy: { label: "Raised by", key: "raised_by" },
  invoiceAmount: { label: "Amount", key: "amount" },
  remove: { label: "Remove", key: DELETE_BUTTON_KEY },
  remittance: { label: "Remittance", key: DOWNLOAD_PDF_BUTTON_KEY },
  owner_full_name: { label: "Owner", key: INVOICE_OWNER_NAME_KEY },
};

export const PR_MODAL_CREATED_COLUMNS = [
  { ...VIEW_MODAL_TABLE_COLUMNS.invoiceNumber, width: 15 },
  { ...VIEW_MODAL_TABLE_COLUMNS.suppliers, width: 20 },
  { ...VIEW_MODAL_TABLE_COLUMNS.description, width: 20 },
  { ...VIEW_MODAL_TABLE_COLUMNS.raisedBy, width: 15 },
  { ...VIEW_MODAL_TABLE_COLUMNS.owner_full_name, width: 10 },
  { ...VIEW_MODAL_TABLE_COLUMNS.invoiceAmount, width: 20, align: "right" },
];

export const PR_MODAL_IN_REVIEW_COLUMNS = [
  { ...VIEW_MODAL_TABLE_COLUMNS.invoiceNumber, width: 15 },
  { ...VIEW_MODAL_TABLE_COLUMNS.suppliers, width: 15 },
  { ...VIEW_MODAL_TABLE_COLUMNS.priority, width: 15 },
  { ...VIEW_MODAL_TABLE_COLUMNS.description, width: 10 },
  { ...VIEW_MODAL_TABLE_COLUMNS.raisedBy, width: 14 },
  { ...VIEW_MODAL_TABLE_COLUMNS.owner_full_name, width: 10 },
  { ...VIEW_MODAL_TABLE_COLUMNS.invoiceAmount, width: 15, align: "right" },
  { ...VIEW_MODAL_TABLE_COLUMNS.remove, width: 6 },
];

export const PR_MODAL_TO_PAY_AND_SCHEDULED_COLUMNS = [
  { ...VIEW_MODAL_TABLE_COLUMNS.invoiceNumber, width: 15 },
  { ...VIEW_MODAL_TABLE_COLUMNS.suppliers, width: 15 },
  { ...VIEW_MODAL_TABLE_COLUMNS.priority, width: 15 },
  { ...VIEW_MODAL_TABLE_COLUMNS.description, width: 10 },
  { ...VIEW_MODAL_TABLE_COLUMNS.raisedBy, width: 14 },
  { ...VIEW_MODAL_TABLE_COLUMNS.owner_full_name, width: 10 },
  { ...VIEW_MODAL_TABLE_COLUMNS.invoiceAmount, width: 15, align: "right" },
  { ...VIEW_MODAL_TABLE_COLUMNS.remove, width: 6 },
];

export const PR_MODAL_PAID_COLUMNS = [
  { ...VIEW_MODAL_TABLE_COLUMNS.invoiceNumber, width: 15 },
  { ...VIEW_MODAL_TABLE_COLUMNS.suppliers, width: 20 },
  { ...VIEW_MODAL_TABLE_COLUMNS.description, width: 10 },
  { ...VIEW_MODAL_TABLE_COLUMNS.raisedBy, width: 15 },
  { ...VIEW_MODAL_TABLE_COLUMNS.owner_full_name, width: 10 },
  { ...VIEW_MODAL_TABLE_COLUMNS.invoiceAmount, width: 15, align: "right" },
  { ...VIEW_MODAL_TABLE_COLUMNS.remittance, width: 15, align: "center" },
];

export const PAYMENT_RUNS_TABS = [
  {
    key: PAYMENT_RUNS_TABS_KEYS.CREATED,
    label: "Created",
    columns: PAYMENT_RUNS_TABLE_CREATED_COLUMNS,
    approvedListColumns: PAYMENT_RUNS_TABLE_CREATED_APPROVED_LIST_COLUMNS,
    viewModalColumns: PR_MODAL_CREATED_COLUMNS,
  },
  {
    key: PAYMENT_RUNS_TABS_KEYS.IN_REVIEW,
    label: "In review",
    columns: PAYMENT_RUNS_TABLE_IN_REVIEW_COLUMNS,
    viewModalColumns: PR_MODAL_IN_REVIEW_COLUMNS,
  },
  {
    key: PAYMENT_RUNS_TABS_KEYS.TO_PAY,
    label: "To pay",
    columns: PAYMENT_RUNS_TABLE_TO_PAY_COLUMNS,
    viewModalColumns: PR_MODAL_TO_PAY_AND_SCHEDULED_COLUMNS,
  },
  {
    key: PAYMENT_RUNS_TABS_KEYS.SCHEDULED,
    label: "Scheduled",
    columns: PAYMENT_RUNS_TABLE_SCHEDULED_COLUMNS,
    viewModalColumns: PR_MODAL_TO_PAY_AND_SCHEDULED_COLUMNS,
  },
  {
    key: PAYMENT_RUNS_TABS_KEYS.PAID,
    label: "Paid",
    columns: PAYMENT_RUNS_TABLE_PAID_COLUMNS,
    viewModalColumns: PR_MODAL_PAID_COLUMNS,
  },
];

export const PAYMENT_RUNS_DATE_KEY = "created_at";

export const PAYMENT_RUNS = {
  CREATE_BUTTON_TEXT: "Create a payment run",
  CREATE_PR_DRAWER: "CREATE_PR_DRAWER",
  APPROVAL_COLLAPSED_TABLE_COLUMN: [
    { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.invoiceNumber, width: 30 },
    { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.suppliers, width: 30 },
    { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.dueDate, width: 25 },
    { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.remove, width: 15 },
  ],
  APPROVAL_EXPANDED_TABLE_COLUMN: [
    { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.invoiceNumber, width: 20 },
    { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.dueDate, width: 20 },
    { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.suppliers, width: 10 },
    {
      ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.description,
      width: 15,
    },
    {
      ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.invoiceAmount,
      width: 25,
      align: "right",
    },
    { ...PAYMENT_RUNS_TABLE_APPROVED_LIST_COLUMNS.remove, width: 10 },
  ],
  CREATE_PR_BTN_LABEL: "Create payment run",
  TOTAL_INVOICES_INFO_LABELS: {
    NUMBER_OF_INVOICES: "Number of invoices",
    TOTAL_AMOUNT: "Total amount",
    TOTAL_OVERDUE_AMOUNT: "Total overdue amount",
  },
  PAYMENT_RUN_LABEL: "Payment run name",
  PAYMENT_NAME_PLACEHOLDER: "Enter payment run name",
  DATE_PICKER_LABEL: "Scheduled date",
  PAYMENT_TYPE: "scheduled",
  IM_QUERY_PARAMS: "fromInvoice",
  DATE_PICKER_PLACEHOLDER: "dd/mm/yyyy",
  MULTIPLE_CURRENCY_WARNING: (invoiceNumbers) =>
    `Invoice number${invoiceNumbers?.length > 1 ? "s" : ""} ${invoiceNumbers} ${
      invoiceNumbers?.length > 1 ? "have" : "has"
    } been created with a non-GBP currency. This means this payment run can only be marked as paid and the payment action will need to be processed outside of Kloo.`,
  MULTIPLE_CURRENCY_TOOLTIP:
    "This payment run has at least one invoice of a non-GBP currency and the payment action must be processed outside of Kloo",
  PAYMENT_RUN_MODAL_DOWNLOAD_LABEL: "Download as PDF",
  VIEW_AUDIT_TRAIL_BUTTON_LABEL: "View audit trail",
  APPROVE_BUTTON_LABEL: "Approve",
  REJECT_BUTTON_LABEL: "Reject",
  APPROVE_PR_STATUS: "approved",
  IN_REVIEW_PR_STATUS: "in-review",
  REJECT_PR_STATUS: "rejected",
  DELETE_PR_STATUS: "deleted",
  CANCEL_PR_STATUS: "cancelled",
  SCHEDULED_PR_STATUS: "scheduled",
  RESCHEDULED_PR_STATUS: "rescheduled",
  DELETE_PR_BUTTON_LABEL: "Delete payment run",
  CANCEL_PR_BUTTON_LABEL: "Cancel payment run",
  NON_KLOO_PAYMENT_ACTION_LABEL: " Non Kloo payment",
  NON_KLOO_PAYMENT_KEY: "Non-Kloo payment",
  RE_SCHEDULE_PR_BUTTON_LABEL: "Reschedule payment run",
  MARK_AS_PAID_BUTTON_LABEL: "Mark as paid",
  DOWNLOAD_PAYMENT_FILE: "Download payment file",
  SCHEDULE_PAYMENT_BUTTON_LABEL: " Schedule payment",
  TABLE_WIDTH_WHEN_COLLAPSED_DRAWER: "calc(100% - 536px)",
  TABLE_WIDTH_WHEN_EXPANDED_DRAWER: "calc(100% - 774px)",
  DOWNLOAD_MODAL_FILE_NAME: "payment runs",
  DOWNLOAD_AS_PDF_DESC: `Download individual files for each of the invoices from the selected payment run as proof of payment for your ${LC_SUPPLIERS_PLACEHOLDER}.`,
  DOWNLOAD_AS_EXCEL_DESC:
    "Download a listing of all invoices from the selected payment run and their data for review and analysis.",
  CREATE_PR_MODULE_NAME: "create-payment-runs",
  SCHEDULE_PR_MODULE_NAME: "schedule-payment-runs",
  SCHEDULE_PAY_TYPE: "account-payable-payment-runs-paynow",
  SCHEDULE_PAY_LIST_TYPE: "user",
  DELETE_WORKFLOW_TYPE: "account-payable-payment-runs",
  SEARCH_PAYMENT_RUN: "Search payment run",
  SEARCH_INVOICES: "Search invoices",
  MAKE_PAYMENT_BTN_LABEL: "Make payment",
  VIEW_BTN_LABEL: "View",
  SELECTED_PR_TEXT: (selectedPr) => `${selectedPr} Payment runs selected`,
  DELETE_PAYMENT_RUN_MODAL_TITLE: "Delete payment run",
  DELETE_PAYMENT_RUN_WARNING:
    "By removing this invoice you will delete this payment run.",
  PU_USERS_IN_SA_MODULE_NAME:
    "create-payment-runs~schedule-payment-runs~worflow-payment-run",
  SELECT_AN_APPROVER_PLACEHOLDER: "Select an approver",
  AUDIT_LOG_COLUMNS: [
    {
      key: "created_at",
      label: "",
      width: 12,
      customClass: "font-weight-semi-bold created-at-column",
      align: "center",
    },
    {
      key: "content",
      label: "",
      customClass: "font-weight-semi-bold content-column",
    },
  ],
  PR_CREATOR_MODULE_NAME: "purchase-request-creators",
  KLOO_AI: "Kloo AI",
};

export const SA_PR_WARNING_MODAL = {
  TITLE: "Warning",
  SUBTITLE_IF_USER_EXISTS_ON_BOTH_LIST: (selectedName) =>
    `${selectedName} has the ability to both schedule and pay payment runs and create payments runs. To prevent this, please configure a different user for either of these actions.`,
};

export const DOWNLOAD_INVOICE_DRAWER = {
  TITLE: "Invoice",
  DOWNLOAD_BTN_LABEL: "Download",
  CANCEL_BTN_LABEL: "Cancel",
};

export const PAYMENT_RUN_WORKFLOW_HEADING = {
  CREATE_PAYMENT_RUNS: "Create payment runs",
  SCHEDULE_AND_PAY_PAYMENT_RUNS: "Schedule and pay payment runs",
};

export const PAYMENT_RUN_NOTE = {
  CREATE_PAYMENT_RUN_NOTE:
    "Choose the users in your organisation who should be responsible for creating payment runs",
  SCHEDULE_PAYMENT_RUN_NOTE:
    "Choose the users in your organisation who should be responsible for scheduling and paying payment runs",
};

export const PAYMENT_RUNS_TOAST_MSGS = {
  created: "Payment run created",
  userAddedToCreatePrList: "User successfully added as a payment run creator",
  userAddedToScheduleList:
    "User successfully added as a payment run scheduler and payer",
  userRemovedFromCreatePrList:
    "User successfully removed as a payment run creator",
  userRemovedFromScheduleList:
    "User successfully removed as a payment run scheduler and payer",
  paymentRunMarkedAsPaid: "Payment run successfully paid",
  paymentRunScheduled: (paymentScheduledType) =>
    `Payment run successfully ${
      paymentScheduledType === PAYMENT_RUNS.SCHEDULED_PR_STATUS
        ? "scheduled"
        : "rescheduled"
    }`,
  paymentRunApproved: "Payment run approved",
  paymentRunRejected: "Payment run rejected",
  paymentRunDeleted: "Payment run deleted",
  paymentRunCancelled: "Payment run cancelled",
  paymentRunsDeleted: (selectedIds) =>
    `Payment ${selectedIds.length > 1 ? "runs" : "run"} deleted successfully`,
  paymentRunsCancelled: (selectedIds) =>
    `Payment ${selectedIds.length > 1 ? "runs" : "run"} cancelled successfully`,
  userAddedToPrCreatorList:
    "User successfully added as a payment request creator",
  userRemovedFromPrCreatorList:
    "User successfully removed as a payment request creator",
};

export const MARK_AS_PAID_MODAL = {
  TITLE: "Mark as paid",
  DISCLAIMER:
    "By marking this payment run as paid, you are indicating that this payment run will be completed off platform.",
  PROOF_OF_PAYMENT: "Send proof of payment?",
  CONFIRM_BTN_LABEL: "Confirm",
  CANCEL_BTN_LABEL: "Cancel",
};

export const SCHEDULE_PAYMENT_MODAL = {
  TITLE: (paymentScheduledType) =>
    `${
      paymentScheduledType === PAYMENT_RUNS.SCHEDULED_PR_STATUS
        ? "Schedule"
        : "Reschedule"
    } payment with Kloo`,
  SUBTITLE: `Before scheduling a payment run, please add account details to the below invoices. If an account has already been selected, you can change if required.`,
  INVOICE_NUMBER_LABEL: "Invoice number",
  SUPPLIER_LABEL: "Supplier",
  SUPPLIER_PLACEHOLDER: `Select ${LC_SUPPLIER_PLACEHOLDER}`,
  SUPPLIER_ACCOUNT_LABEL: "Account",
  SUPPLIER_ACCOUNT_PLACEHOLDER: "Select supplier account",
  FUND_ACCOUNT_LABEL: "Fund account",
  SCHEDULE_PAYMENT_DATE_LABEL: "Schedule payment",
  RESCHEDULE_PAYMENT_DATE_LABEL: "Reschedule payment",
  SCHEDULE_PAYMENT_DATE_PLACEHOLDER: "Select date",
  SORT_CODE_LABEL: "Sort code",
  ACCOUNT_NUMBER_LABEL: "Account number",
  PROOF_OF_PAYMENT: "Send proof of payment?",
  EMAIL_ADDRESS_PLACEHOLDER: "Email address",
  ADD_SUPPLIER_AC: "Add supplier account",
  SCHEDULE_BTN_LABEL: (paymentScheduledType) =>
    `${
      paymentScheduledType === PAYMENT_RUNS.SCHEDULED_PR_STATUS
        ? "Schedule"
        : "Reschedule"
    } payment`,
  CANCEL_BTN_LABEL: "Cancel",
  NEXT: "Next",
};

export const PAYMENT_RUNS_DUE_DATE_KEY = "due_date";

export const PAYMENT_RUNS_SCHEDULED_DATE_KEY = "scheduled_date";

export const STATUS_ON_ACTIVE_KEY = {
  submitted: "submitted",
  myApprovals: "in_review",
  to_pay: "approved",
  scheduled: "scheduled",
  paid: "paid",
};

export const PAYMENT_TYPE = [
  {
    icon: "payments",
    label: "Paid with Kloo",
    key: "Paid with Kloo",
  },
  {
    icon: "payments",
    label: "Pay with Kloo",
    key: "Pay with Kloo",
  },
  {
    icon: "auto_fix_high",
    label: "Paid via Open Banking",
    key: "Paid via Open Banking",
  },
  {
    icon: "download_for_offline",
    label: "Non-Kloo payment",
    key: "Non-Kloo payment",
  },
];

export const PAYMENT_RUN_ASSIGNMENT_DRAWER = {
  PR_TABLE_COLUMN: [
    {
      label: "Invoice number",
      key: "invoice_number",
      width: 20,
    },
    {
      label: SUPPLIER_PLACEHOLDER,
      key: "supplier_name",
      width: 20,
      align: "left",
    },
    { label: "Description", key: "description", width: 20, align: "left" },
    { label: "Raised by", key: "raised_by", width: 20, align: "left" },
    { label: "Amount", key: "amount", width: 20, align: "right" },
    { key: DELETE_BUTTON_KEY, width: 0 },
  ],
  TOTAL_AMOUNT: (amount) => `Total: ${amount}`,
  WORKFLOW_PLACEHOLDER: "Select workflow",
  WORKFLOW_LABEL: "Workflow",
  INFO: (approvalCount) =>
    `You are about to assign ${approvalCount} payment run${
      approvalCount > 1 ? "s" : ""
    } for approval. Once assigned, ${
      approvalCount > 1 ? "these" : "it"
    } will be sent to the selected workflow.`,

  assigned: (isMulti) =>
    `Payment run${isMulti ? "s" : ""} assigned for approval`,
};

export const REDIRECTION_TABS = {
  approved: "to_pay",
  scheduled: "scheduled",
};

export const CONFIGURATION_PAYMENT_FILE_FORM = {
  title: "Configure your BACS18 payment file",
  subTitle: `Before configuring your BACS18 payment file, please add account details to the below invoices. If an account has already been selected, you can change if required.`,

  downloadButton: {
    label: `Download`,
  },

  cancelButton: {
    label: "Cancel",
  },

  backButton: {
    label: "Back",
  },

  fileProcessingDate: {
    labelSuffixIcon: "info",
    suffixIconTooltip:
      "This is the date the payment will be executed by the bank. Note this is not the payment date.",
    placeholder: DEFAULT_FORMAT,
    label: "File processing date",
    name: "file_process_date",
    rules: PR_CONFIG_PAYMENT_FILE.FILE_PROCESSING_DATE,
    bordered: false,
    showToday: false,
    defaultValue: null,
  },

  sortCode: {
    placeholder: "Enter source sort code",
    label: "Source sort code",
    name: "source_sort_code",
    rules: PR_CONFIG_PAYMENT_FILE.SORT_CODE,
  },

  accountNumber: {
    type: "text",
    maxLength: 8,
    suffixIcon: "info",
    suffixIconTooltip:
      "The source account is the account where the money for the payment is coming from.",
    placeholder: "Enter source account number",
    label: "Source account number",
    name: "source_account_number",
    rules: PR_CONFIG_PAYMENT_FILE.ACCOUNT_NUMBER,
  },

  successMessage: ``,
  updateMessage: ``,
};
