import { SUPPLIER_MANAGEMENT } from "../../utils";
import {
  LC_SUPPLIERS_PLACEHOLDER,
  LC_SUPPLIER_PLACEHOLDER,
  SUPPLIER_PLACEHOLDER,
} from "../dynamic";
import { GBP_CURRENCY_ID } from "../InvoiceManagement";
const { ADD_SUPPLIER_FORM } = SUPPLIER_MANAGEMENT;

export const PRIORITIES = Object.freeze({
  BusinessCritical: "Business critical",
  NonCritical: "Non-critical",
});

export const SUPPLIER_ACCOUNT_SETS = Object.freeze({
  UKAccount: "UK account",
  SWIFT: "SWIFT",
  IBAN: "IBAN",
});

export const SUPPLIER_MANAGEMENT_TABS_KEYS = {
  SUPPLIERS: "suppliers",
  PURCHASE_ORDER: "po",
  INVOICES: "ap",
};

export const SUPPLIER_MANAGEMENT_TABS = [
  {
    key: SUPPLIER_MANAGEMENT_TABS_KEYS.SUPPLIERS,
    label: "Suppliers",
  },
  {
    key: SUPPLIER_MANAGEMENT_TABS_KEYS.PURCHASE_ORDER,
    label: "Purchase requests coding",
  },
  {
    key: SUPPLIER_MANAGEMENT_TABS_KEYS.INVOICES,
    label: "Invoice coding",
  },
];

export const SUPPLIER_MANAGEMENT_DOWNLOAD_TEXTS = {
  [SUPPLIER_MANAGEMENT_TABS_KEYS.PURCHASE_ORDER]: {
    dropdownInfoText: (field) =>
      `The cheat sheet for your ${field} coding at the purchase request header level driven by the supplier selected.`,
    title: "Coding for purchase requests",
    subtitle:
      "During purchase request creation, Kloo AI will auto-populate fields at both the header and line item levels. To make changes to these coding rules, select the cheat sheet type to download. Once you have made your changes, please email them to support@getkloo.com for your updates to be uploaded.",
  },
  [SUPPLIER_MANAGEMENT_TABS_KEYS.INVOICES]: {
    dropdownInfoText: (field) =>
      `The cheat sheet for your ${field} coding at the invoices header level driven by the supplier selected.`,
    title: "Coding for Invoice",
    subtitle:
      "During invoice creation, Kloo AI will auto-populate fields at both the header and line item levels. To make changes to these coding rules, select the cheat sheet type to download. Once you have made your changes, please email them to support@getkloo.com for your updates to be uploaded.",
  },
};

export const ADD_NEW_SUPPLIER_FORM = {
  TITLE: {
    add: `Add a new ${LC_SUPPLIER_PLACEHOLDER}`,
    edit: `Edit ${LC_SUPPLIER_PLACEHOLDER} details`,
  },
  saveButton: {
    label: `Save ${LC_SUPPLIER_PLACEHOLDER}`,
  },
  saveAndAddOtherButton: {
    label: "Save & add another",
  },
  updateButton: {
    label: "Save",
  },
  cancelButton: {
    label: "Cancel",
  },
  supplierName: {
    placeholder: SUPPLIER_PLACEHOLDER,
    label: SUPPLIER_PLACEHOLDER,
    name: "name",
    rules: ADD_SUPPLIER_FORM.SUPPLIER_NAME,
  },
  email: {
    placeholder: "Email",
    label: "Email address",
    name: "email",
    rules: ADD_SUPPLIER_FORM.EMAIL,
    suffixIcon: "info",
    suffixIconTooltip: `If your organisation has enabled automatic emails of POs to ${LC_SUPPLIERS_PLACEHOLDER} then it’s recommended you provide an email address so that all POs associated to this ${LC_SUPPLIER_PLACEHOLDER} are automatically sent. However, if no email is added then these POs can still be manually emailed.`,
  },
  contactNumber: {
    placeholder: "Contact number",
    label: "Contact number",
    name: "mobile",
  },
  priority: {
    placeholder: "Select priority",
    label: "Priority",
    name: "priority",
    options: [
      {
        label: PRIORITIES.BusinessCritical,
        value: PRIORITIES.BusinessCritical,
      },
      { label: PRIORITIES.NonCritical, value: PRIORITIES.NonCritical },
    ],
    allowClear: true,
  },
  SUPPLIER_ADDRESS: {
    label: "Address",
    supplierAddressToggle: {
      label: `Add ${LC_SUPPLIER_PLACEHOLDER} address`,
    },
    addressLine1: {
      placeholder: "Address line 1",
      label: "Address line 1",
      name: "addressline1",
    },
    addressLine2: {
      placeholder: "Address line 2",
      label: "Address line 2",
      name: "addressline2",
    },
    city: {
      placeholder: "City",
      label: "City",
      name: "city",
    },
    postcode: {
      placeholder: "Postcode",
      label: "Postcode",
      name: "postcode",
    },
    country: {
      placeholder: "Country",
      label: "Country",
      name: "country",
    },
  },
  ACCOUNT_FORM: {
    title: "Account details",
    addAccountButton: {
      label: "Add account details",
      iconName: "add",
    },
    addMoreAccountButton: {
      label: "Add another account",
      iconName: "add",
    },
    removeButton: {
      iconName: "delete",
    },
    SUPPLIERS_ACCOUNT_TYPES: {
      key: "account_type",
      optionType: "button",
      name: (fieldName) => [fieldName, "account_type"],
      label: "Account type",
      options: [
        {
          label: SUPPLIER_ACCOUNT_SETS.UKAccount,
          value: SUPPLIER_ACCOUNT_SETS.UKAccount,
          disabled: false,
        },
        {
          label: SUPPLIER_ACCOUNT_SETS.SWIFT,
          value: SUPPLIER_ACCOUNT_SETS.SWIFT,
          disabled: true,
        },
        {
          label: SUPPLIER_ACCOUNT_SETS.IBAN,
          value: SUPPLIER_ACCOUNT_SETS.IBAN,
          disabled: true,
        },
      ],
    },
    accountAlias: {
      key: "account_alias",
      placeholder: "Account alias",
      label: "Account alias",
      name: (fieldName) => [fieldName, "account_alias"],
    },
    accountName: {
      key: "account_name",
      placeholder: "Account name",
      label: "Account name",
      name: (fieldName) => [fieldName, "account_name"],
      rules: ADD_SUPPLIER_FORM.SUPPLIER_ACCOUNT.ACCOUNT_NAME,
    },
    accountNumber: {
      key: "acc_number",
      placeholder: "Account number",
      label: "Account number",
      name: (fieldName) => [fieldName, "acc_number"],
      rules: ADD_SUPPLIER_FORM.SUPPLIER_ACCOUNT.ACCOUNT_NUMBER,
    },
    sortCode: {
      key: "sort_code",
      placeholder: "Sort code",
      label: "Sort code",
      name: (fieldName) => [fieldName, "sort_code"],
      rules: ADD_SUPPLIER_FORM.SUPPLIER_ACCOUNT.SORT_CODE,
    },
    currency: {
      key: "currency",
      placeholder: "Currency",
      label: "Currency",
      name: (fieldName) => [fieldName, "currency"],
      rules: ADD_SUPPLIER_FORM.SUPPLIER_ACCOUNT.CURRENCY,
      initialValue: GBP_CURRENCY_ID,
      disabled: true,
      allowClear: true,
    },
    BANK_ADDRESS: {
      bankAddressToggle: {
        name: (fieldName) => [fieldName, "bank_name"],
        label: "Add bank address",
      },
      bankName: {
        placeholder: "Bank name",
        label: "Bank name",
        name: (fieldName) => [fieldName, "bank_name"],
      },
      addressLine1: {
        placeholder: "Address line 1",
        label: "Address line 1",
        name: (fieldName) => [fieldName, "addressline1"],
        rules: ADD_SUPPLIER_FORM.SUPPLIER_ACCOUNT.BANK_ADDRESS.ADDRESS_LINE_1,
      },
      addressLine2: {
        placeholder: "Address line 2",
        label: "Address line 2",
        name: (fieldName) => [fieldName, "addressline2"],
      },
      city: {
        placeholder: "City",
        label: "City",
        name: (fieldName) => [fieldName, "city"],
      },
      postcode: {
        placeholder: "Postcode",
        label: "Postcode",
        name: (fieldName) => [fieldName, "postcode"],
        rules: ADD_SUPPLIER_FORM.SUPPLIER_ACCOUNT.BANK_ADDRESS.POST_CODE,
      },
      country: {
        placeholder: "Country",
        label: "Country",
        name: (fieldName) => [fieldName, "country"],
        rules: ADD_SUPPLIER_FORM.SUPPLIER_ACCOUNT.BANK_ADDRESS.COUNTRY,
      },
    },
  },
  successMessage: `${SUPPLIER_PLACEHOLDER} created successfully`,
  updateMessage: `${SUPPLIER_PLACEHOLDER} updated successfully`,
};

export const ADD_NEW_SUPPLIER_ACCOUNT_FORM = {
  TITLE: {
    add: `Add account`,
    edit: `Edit account`,
  },
  saveButton: {
    label: `Save`,
  },
  saveAndReturnToScheduleButton: {
    label: `Save and return to schedule payment`,
  },
  saveAndReturnToPaymentRunsButtons: {
    label: `Save and return to payment run`,
  },
  updateButton: {
    label: "Save",
  },
  cancelButton: {
    label: "Cancel",
  },

  successMessage: `Account added successfully`,
  updateMessage: `Account updated successfully`,
};

export const PAGE_HEADER_INFO_SUPPLIER_MANAGEMENT = {
  SUPPLIER_MANAGEMENT: {
    desc: `Manage the ${LC_SUPPLIERS_PLACEHOLDER} that users can create or issue invoice payments against, and update their details accordingly. For any new ${LC_SUPPLIER_PLACEHOLDER}, a new entry must first be added, before invoice payments can be made created or requested against them.`,
    header: `${SUPPLIER_PLACEHOLDER} management`,
    buttonIcon: "add_card",
    buttonLabel: `Add new ${LC_SUPPLIER_PLACEHOLDER}`,
    buttonLabelMobile: `Add new ${LC_SUPPLIER_PLACEHOLDER}`,
    SEARCH: {
      placeholder: `Search ${LC_SUPPLIER_PLACEHOLDER}`,
    },
  },
};
export const SUPPLIER_MANAGEMENT_DEFAULT_ROW_HEIGHT = 48;
export const SUPPLIER_MANAGEMENT_DEFAULT_PAGE_SIZE = 10;

export const SUPPLIER_MANAGEMENT_TABLE_COLUMNS = {
  supplierName: {
    label: SUPPLIER_PLACEHOLDER,
    key: "name",
    align: "left",
  },
  emailAddress: {
    label: "Email address",
    key: "email",
    align: "left",
  },
  contactNumber: {
    label: "Contact number",
    key: "supplier_contact_no",
    align: "center",
  },
  priority: {
    label: "Priority",
    key: "priority",
    align: "left",
  },
  accountInformation: {
    label: "Account information",
    key: "accountInformation",
    align: "center",
  },
  action: {
    label: "Actions",
    key: "action",
    align: "center",
  },
};

export const SUPPLIER_MANAGEMENT_TABLE_COLUMNS_HEADING = [
  { ...SUPPLIER_MANAGEMENT_TABLE_COLUMNS.supplierName, width: 12 },
  {
    ...SUPPLIER_MANAGEMENT_TABLE_COLUMNS.emailAddress,
    width: 10,
  },
  {
    ...SUPPLIER_MANAGEMENT_TABLE_COLUMNS.contactNumber,
    width: 10,
  },
  { ...SUPPLIER_MANAGEMENT_TABLE_COLUMNS.priority, width: 12 },
  { ...SUPPLIER_MANAGEMENT_TABLE_COLUMNS.accountInformation, width: 10 },
  { ...SUPPLIER_MANAGEMENT_TABLE_COLUMNS.action, width: 10 },
];

export const SUPPLIER_DELETE_MODAL = {
  title: `Delete ${LC_SUPPLIER_PLACEHOLDER}`,
  action: "Confirm action",
  subTitle: "Are you sure you want to delete?",
  onDeleteMessage: `${SUPPLIER_PLACEHOLDER} deleted successfully`,
  subBody: `You are about to delete this ${LC_SUPPLIER_PLACEHOLDER} permanently. In addition, if automated assignment of workflows is enabled based on this field, automated assignment will be disabled and reassignment will be required.`,
};

export const SUPPLIER_VIEW_ACCOUNT_MODAL = {
  title: `${SUPPLIER_PLACEHOLDER} delete field`,
  action: "Confirm action",
  subTitle: "Are you sure you want to delete?",
  onDeleteMessage: `${SUPPLIER_PLACEHOLDER} deleted successfully`,
  tabs: ["Accounts", "View history"],
};

export const ACCOUNT_DELETE_MODAL = {
  title: `Delete account`,
  action: "Confirm action",
  subTitle: "Are you sure you want to delete?",
  onDeleteMessage: `Account deleted successfully`,
  subBody: `This account will be permanently deleted and will be removed from all items.`,
};

export const DELETION_FAIL_MSG = {
  SUPPLIER_DELETION:
    "This supplier has associated invoices and POs and cannot be deleted now.",
  SUPPLIER_ACC_DELETION:
    "This account has associated invoices and cannot be deleted now.",
};

export const SUPPLIER_MANAGEMENT_AUDIT_COLUMN = [
  { label: "Date and time", key: "date", width: 16 },
  { label: "Actioning user", key: "user", ellipsis: true, width: 16 },
  { label: "Email", key: "email", ellipsis: true, width: 18 },
  { label: "Before", key: "before", width: 25 },
  { label: "After", key: "after", width: 25 },
];

export const SUPPLIER_AUDIT_TRAIL_MAPPING = [
  {
    key: "name",
    action: `name`,
    type: "basic",
  },
  {
    key: "email",
    action: `email address`,
    type: "basic",
  },
  {
    key: "mobile",
    action: `contact number`,
    type: "basic",
  },
  {
    key: "priority",
    action: `priority`,
    type: "priority",
  },
  {
    key: "addressline1",
    action: `address line 1`,
    type: "address",
  },
  {
    key: "addressline2",
    action: `address line 2`,
    type: "address",
  },
  {
    key: "city",
    action: `city`,
    type: "address",
  },
  {
    key: "postcode",
    action: `postcode`,
    type: "address",
  },
  {
    key: "country",
    action: `country`,
    type: "address",
  },
  {
    key: "sort_code",
    action: `sort code`,
    type: "account",
  },
  {
    key: "acc_number",
    action: `account number`,
    type: "account",
  },
  {
    key: "account_alias",
    action: `account alias`,
    type: "account",
  },
  {
    key: "account_name",
    action: `account name`,
    type: "account",
  },
];

export const SUPPLIER_MANAGEMENT_AUDIT_DEFAULT_ROW_HEIGHT = 48;
export const SUPPLIER_MANAGEMENT_AUDIT_DEFAULT_PAGE_SIZE = 10;

export const isSupplierIdentifyByOCR = (data) => {
  return data?.supplier_identified_by === "ocr";
};
