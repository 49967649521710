import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Column,
  Row,
  Table,
  Typography,
  Icon,
  When,
  CustomButton,
  EnvFeatureFlag,
  CommonInput,
} from "../../common";
import { PurchaseOrderPDFView } from "../PurchaseOrderPDFView";
import { approvePurchaseOrder } from "../../../api";
import { NOTIFICATION_TYPE, showNotification } from "../../../utils";
import {
  ACCENTS,
  BUTTON_VARIANT,
  ERROR_MSG,
  LAUNCH_BUTTON,
  PO_ACTIONS,
  PO_APPROVE_REJECT_DRAWER,
  PURCHASE_ORDER_APPROVAL_DRAWER,
  PURCHASE_ORDER_TABS_KEYS,
  PURCHASE_ORDER_TOAST_MSGS,
  featureFlags,
} from "../../../constants";
import "./approval-purchase-order.scss";
import { Form } from "antd";

function ApprovalPurchaseOrderDrawer({
  open,
  purchaseOrderList,
  reloadTable,
  onClose,
  activeKey,
  showModal,
  hideModal,
  actionType,
  setSelectedItems,
  taxCodeEnabled,
  entityEnabled,
  defaultEntityList,
  version,
}) {
  const [detail, setDetail] = useState(purchaseOrderList?.[0] || {});
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [orderList, setOrderList] = useState(purchaseOrderList);
  const [form] = Form.useForm();

  const handleApproval = async (status) => {
    const data = await form.validateFields();
    try {
      let payload = {
        ids: orderList.map((item) => item.id),
        is_workflow_assigned: activeKey !== PURCHASE_ORDER_TABS_KEYS.CREATED,
      };
      if (
        activeKey !== PURCHASE_ORDER_TABS_KEYS.CREATED &&
        status === PO_ACTIONS.REJECT
      ) {
        payload.reason = data?.rejectionReason || null;
      }
      await approvePurchaseOrder(status, payload);
      showNotification(
        NOTIFICATION_TYPE.success,
        status === PO_ACTIONS.APPROVE
          ? PURCHASE_ORDER_TOAST_MSGS.approved(orderList.length > 1)
          : PURCHASE_ORDER_TOAST_MSGS.rejected(orderList.length > 1)
      );
      reloadTable();
      onClose();
    } catch (e) {
      showNotification(
        NOTIFICATION_TYPE.error,
        e?.response?.data?.message || ERROR_MSG.common
      );
    }
  };

  const handleRowSelection = ({ record }) => {
    setDetail(record);
  };

  const renderBody = ({ label, key, record, index, defaultRender }) => {
    return key === LAUNCH_BUTTON
      ? renderLaunch({ label, key, record, index, defaultRender })
      : defaultRender({ label, key, record, index, defaultRender });
  };

  const renderLaunch = ({ label, key, record, index }) => {
    return <Icon iconName="launch" />;
  };

  const handleRemove = () => {
    const filterRecords = orderList.filter((item) => item.id !== detail.id);
    setOrderList(filterRecords);
    setSelectedItems(filterRecords);
    const ifSelectedInvoiceExist = filterRecords.findIndex(
      (item) => item.id === detail.id
    );
    if (ifSelectedInvoiceExist === -1) {
      setDetail(filterRecords[0]);
      setSelectedRowIndex(0);
    } else {
      setDetail(filterRecords[ifSelectedInvoiceExist]);
      setSelectedRowIndex(ifSelectedInvoiceExist);
    }
  };

  return (
    <Drawer
      className="approval-drawer"
      open={open}
      onClose={onClose}
      title={
        activeKey !== PURCHASE_ORDER_TABS_KEYS.CREATED
          ? actionType === PO_ACTIONS.APPROVE
            ? PO_APPROVE_REJECT_DRAWER.APPROVE_TITLE
            : PO_APPROVE_REJECT_DRAWER.REJECT_TITLE
          : PURCHASE_ORDER_APPROVAL_DRAWER.TITLE
      }
      width={1200}
    >
      <Row>
        <Column {...{ xs: 24, sm: 24, md: 24, lg: 11 }}>
          <Typography
            className="m-b-26px"
            variant="secondary"
            text={
              activeKey !== PURCHASE_ORDER_TABS_KEYS.CREATED
                ? PO_APPROVE_REJECT_DRAWER.INFO(orderList?.length, actionType)
                : PURCHASE_ORDER_APPROVAL_DRAWER.INFO(orderList?.length)
            }
          />
          <When
            condition={
              actionType === PO_ACTIONS.REJECT &&
              activeKey !== PURCHASE_ORDER_TABS_KEYS.CREATED
            }
          >
            <Form form={form} name="reject-form" layout={"vertical"}>
              <CommonInput
                name="rejectionReason"
                placeholder={PO_APPROVE_REJECT_DRAWER.REJECTION_REASON_LABEL}
                maxLength={200}
              />
            </Form>
          </When>
          <Table
            key={Object.values({ orderList, selectedRowIndex }).join("-")}
            tableClassName="m-b-0px"
            className={
              actionType === PO_ACTIONS.REJECT &&
              activeKey !== PURCHASE_ORDER_TABS_KEYS.CREATED
                ? "approval-reject-table"
                : ""
            }
            columns={PURCHASE_ORDER_APPROVAL_DRAWER.APPROVAL_TABLE_COLUMN}
            data={orderList}
            enableRowHighlight
            onRowClick={handleRowSelection}
            renderBody={renderBody}
            enableSerialNumber
            defaultSelectedRow={selectedRowIndex}
            showScrollBar={true}
          />
        </Column>
        <Column
          {...{
            xs: 24,
            sm: 24,
            md: 24,
            lg: { ...{ span: 12, offset: 1 } },
          }}
        >
          <PurchaseOrderPDFView
            className="m-b-60px"
            showModal={showModal}
            hideModal={hideModal}
            {...detail}
            taxCodeEnabled={taxCodeEnabled}
            entityEnabled={entityEnabled}
            defaultEntityList={defaultEntityList}
            version={version}
          />
          <When condition={orderList?.length > 1}>
            <CustomButton
              text={PURCHASE_ORDER_APPROVAL_DRAWER.REMOVE_BUTTON_LABEL}
              icon={<Icon iconName={"delete_outline"} />}
              accent={ACCENTS.DESTRUCTIVE}
              variant={BUTTON_VARIANT.BUTTON_TEXT}
              onClick={handleRemove}
              disabled={orderList.length === 1}
              className="m-b-80px remove-list"
              customSize={"large"}
            />
          </When>
        </Column>
      </Row>

      <Row>
        <Column span={24} className="purchase-drawer-footer p-l-30px p-r-30px">
          <Row gutter={24}>
            <When condition={activeKey === PURCHASE_ORDER_TABS_KEYS.CREATED}>
              <Column
                className="gutter-row"
                {...{ xs: 12, sm: 12, md: 12, lg: 6 }}
              >
                <CustomButton
                  text={PURCHASE_ORDER_APPROVAL_DRAWER.APPROVAL_BUTTON_LABEL}
                  accent={ACCENTS.PRIMARY}
                  variant={BUTTON_VARIANT.BUTTON_FILLED}
                  onClick={() => handleApproval(PO_ACTIONS.APPROVE)}
                  showLoader
                  className="w-100"
                  customSize={"large"}
                />
              </Column>
              <Column
                className="gutter-row"
                {...{ xs: 12, sm: 12, md: 12, lg: 6 }}
              >
                <CustomButton
                  text={PURCHASE_ORDER_APPROVAL_DRAWER.REJECT_BUTTON_LABEL}
                  accent={ACCENTS.DESTRUCTIVE}
                  variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                  onClick={() => handleApproval(PO_ACTIONS.REJECT)}
                  showLoader
                  className={"w-100"}
                  customSize={"large"}
                />
              </Column>
            </When>
            <When condition={activeKey !== PURCHASE_ORDER_TABS_KEYS.CREATED}>
              <Column
                className="gutter-row"
                {...{ xs: 12, sm: 12, md: 12, lg: 6 }}
              >
                <CustomButton
                  text={PO_APPROVE_REJECT_DRAWER.CONFIRM_BUTTON_LABEL}
                  accent={ACCENTS.PRIMARY}
                  variant={BUTTON_VARIANT.BUTTON_FILLED}
                  onClick={() => handleApproval(actionType)}
                  showLoader
                  className={"w-100"}
                  customSize={"large"}
                />
              </Column>
              <Column
                className="gutter-row"
                {...{ xs: 12, sm: 12, md: 12, lg: 6 }}
              >
                <CustomButton
                  text="Cancel"
                  accent={ACCENTS.SECONDARY}
                  variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                  customSize={"large"}
                  className={"w-100"}
                  onClick={onClose}
                />
              </Column>
            </When>
          </Row>
        </Column>
      </Row>
    </Drawer>
  );
}

ApprovalPurchaseOrderDrawer.propTypes = {
  open: PropTypes.bool,
  purchaseOrderList: PropTypes.array,
  reloadTable: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default ApprovalPurchaseOrderDrawer;
