import React, { useState } from "react";
import { Form } from "antd";
import {
  ACCENTS,
  BUTTON_VARIANT,
  DRAFT_CONFIRMATION_MODAL,
  INVOICE_REJECTION_REASON,
  PAYMENT_JOURNEY_FAILED_CONFIG,
} from "../../../../constants";
import { CustomButton, Modal, Typography } from "../../../common";

const DraftConfirmationModal = ({ onCancel, createDraft }) => {
  return (
    <Modal
      width={600}
      showDivider={false}
      hide={onCancel}
      getContainer={false}
      centered
      isOpen={true}
      title={DRAFT_CONFIRMATION_MODAL.title}
      zIndex={2000}
    >
      <Typography
        className="m-b-20px m-t-20px"
        variant="secondary"
        fontSize="16"
        text={DRAFT_CONFIRMATION_MODAL.subTitle}
      />

      <div className="otp-btn-container m-t-25px">
        <CustomButton
          text={DRAFT_CONFIRMATION_MODAL.confirmButtonLabel}
          accent={ACCENTS.PRIMARY}
          variant={BUTTON_VARIANT.BUTTON_FILLED}
          htmlType="submit"
          className="m-r-20px"
          onClick={createDraft}
          customSize={"large"}
        />
        <CustomButton
          text={PAYMENT_JOURNEY_FAILED_CONFIG.cancelButton.text}
          accent={ACCENTS.SECONDARY}
          variant={BUTTON_VARIANT.BUTTON_HOLLOW}
          onClick={onCancel}
          customSize={"large"}
        />
      </div>
    </Modal>
  );
};

export default DraftConfirmationModal;
