import { memo, useEffect, useState } from "react";
import {
  Box,
  Button,
  Icon,
  Image,
  Modal,
  PDFViewer,
  Typography,
  When,
} from "../../common";
import EmbeddedIframe from "../../common/EmbeddedIframe/EmbeddedIframe";
import { getBase64 } from "../../../utils";
import "../UploadAttachmentForPO/style.scss";

function UploadedAttachmentsModal({
  title,
  open,
  onCancel,
  showDivider = false,
  file,
  fileURL,
}) {
  const [previewImage, setPreviewImage] = useState("");

  useEffect(() => {
    handlePreview(file);
  }, []);

  const handlePreview = async (file) => {
    var extension = file.name.substr(file.name.lastIndexOf(".") + 1);
    if (!fileURL) {
      const preview = await getBase64(file);
      fetch(preview)
        .then((res) => res.blob())
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          setPreviewImage({
            isImg: extension.toLowerCase() !== "pdf",
            url: url,
          });
        });
    } else {
      setPreviewImage({
        isImg: extension.toLowerCase() !== "pdf",
        url: fileURL,
      });
    }
  };

  const renderTitle = () => {
    return (
      <Box justifyContent="spaceBetween">
        <Typography
          variant={"title"}
          fontSize={24}
          responsiveFontSize={false}
          text={title}
        />
        <Button
          icon={<Icon iconName="open_in_new" />}
          onClick={() => window.open(previewImage.url, "_blank")}
          className={"m-r-16px"}
        />
      </Box>
    );
  };

  return (
    <>
      <Modal
        isOpen={open}
        width={1200}
        title={renderTitle()}
        hide={onCancel}
        showDivider={showDivider}
      >
        <Box className="attachment-modal-height">
          <When condition={previewImage?.isImg}>
            <Image className="w-100" src={previewImage.url} />
          </When>
          <When condition={!previewImage?.isImg}>
            {/* <EmbeddedIframe
              parentClassName="attachment-modal-pdf-section"
              iframeURL={previewImage.url}
            /> */}
            <When condition={!!previewImage.url}>
              <PDFViewer
                className="attachment-modal-pdf-section"
                url={previewImage.url}
              />
            </When>
          </When>
        </Box>
      </Modal>
    </>
  );
}

export default memo(UploadedAttachmentsModal);
