import React, { useEffect, useRef } from "react";
import { Form, InputNumber } from "antd";
import "./InputNumber.scss";
import Label from "../Label";
import { constructClassName } from "../../../utils";

const CommonInputNumber = (props) => {
  const {
    type,
    name,
    value,
    label,
    onChange,
    placeholder,
    rules,
    validateTrigger,
    className,
    disabled,
    readOnly,
    formatter,
    parser,
    min,
    max,
    defaultValue,
    prefix,
    addonBefore,
    addonAfter,
    controls,
    onKeyDown,
    onBlur,
    step,
    precision,
    wrapperClass,
    onlyInput = false,
    optionalField,
    suffixIcon = false,
    suffixIconClassName,
    suffixIconTooltip,
    suffixTooltipList,
    isRequiredField = false,
    labelCol,
    wrapperCol,
    ...rest
  } = props;

  const isMandatoryField =
    isRequiredField ||
    (Array.isArray(rules) && rules?.some((el) => el?.required === true));

  const optionalFieldClassName = isMandatoryField ? "" : "optional-field";

  const inputRef = useRef(null);

  useEffect(() => {
    const handleMouseWheel = (e) => {
      e.preventDefault(); // Prevent the default scroll behavior
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener("mousewheel", handleMouseWheel, {
        passive: false,
      });
      return () => {
        inputElement.removeEventListener("mousewheel", handleMouseWheel);
      };
    }
  }, []);

  return onlyInput ? (
    <InputNumber
      type={type}
      className={`${
        className || ""
      } common-input-number ${optionalFieldClassName}`}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      readOnly={readOnly}
      formatter={formatter}
      defaultValue={defaultValue}
      parser={parser}
      min={min}
      max={max}
      prefix={prefix}
      addonBefore={addonBefore}
      addonAfter={addonAfter}
      controls={controls}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      step={step}
      precision={precision}
      {...rest}
    />
  ) : (
    <Form.Item
      name={name}
      label={
        label ? (
          <Label
            text={label}
            optional={!isMandatoryField}
            suffixIcon={suffixIcon}
            suffixIconClassName={suffixIconClassName}
            suffixIconTooltip={suffixIconTooltip}
            suffixTooltipList={suffixTooltipList}
            {...rest}
          />
        ) : null
      }
      rules={rules}
      validateTrigger={validateTrigger}
      className={wrapperClass}
      colon={false}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      <InputNumber
        ref={inputRef}
        type={type}
        className={constructClassName([
          className,
          "common-input-number",
          optionalFieldClassName,
        ])}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        formatter={formatter}
        defaultValue={defaultValue}
        parser={parser}
        min={min}
        max={max}
        prefix={prefix}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
        controls={controls}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        step={step}
        precision={precision}
        {...rest}
      />
    </Form.Item>
  );
};

export default CommonInputNumber;
