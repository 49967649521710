import React, { Suspense, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import NotFound from "../components/common/DefaultPage/NotFound";
import LayoutMain from "../components/Layout";
import Loader from "../components/common/Loader";
import { PERMISSIONS } from "../constants";
import { useUserType } from "../hooks";
import { useCheckIfUserInSmartApprovals } from "../hooks/useCheckIfUserInSmartApprovals";
import { findMenu, isEmpty, removeLastSlash } from "../utils";
import PrivateRoute from "./PrivateRoute";
import { routes } from "./routes";
import { isKlooValidUser } from "../helper";

const LayoutContainer = ({ children }) => {
  const location = useLocation();
  const currentPathname = removeLastSlash(location.pathname);

  const validUser = isKlooValidUser(currentPathname);

  const layoutAvoidingRoutes = routes
    .filter((route) => !route.isPrivate)
    .map((route) => route.path);

  return validUser &&
    !layoutAvoidingRoutes.includes(currentPathname) &&
    !currentPathname.includes("registerUser") ? (
    <LayoutMain>{children}</LayoutMain>
  ) : (
    <>{children}</>
  );
};

const RouterList = () => {
  const MenuList = useSelector(
    (state) => state && state?.menuReducer && state?.menuReducer?.menuList
  );
  const { isPlatformUser } = useUserType();
  const { userExistsInSmartApprovals } = useCheckIfUserInSmartApprovals();

  function checkSettingsRoutes(el) {
    return findMenu(MenuList[PERMISSIONS.SETTING.key], el?.viewSubLabel);
  }

  function isRouteValid(el) {
    return el.viewLabel === true
      ? true
      : el.viewLabel === "Payment Runs" &&
        isPlatformUser &&
        !userExistsInSmartApprovals
      ? false
      : el?.viewLabel === PERMISSIONS.SETTING.key
      ? checkSettingsRoutes(el)
      : MenuList[el?.viewLabel]
      ? true
      : false;
  }

  const newRoutes = useMemo(() => {
    const data = routes?.map((el) => {
      return {
        ...el,
        isView: isEmpty(MenuList) ? true : isRouteValid(el),
      };
    });
    return data;
  }, [MenuList, userExistsInSmartApprovals]);

  const { loader } = useSelector((state) => state);
  useEffect(() => {
    if (loader) {
      const element = document.getElementsByTagName("body")[0];
      element.classList.add("loader");
    } else {
      const element = document.getElementsByTagName("body")[0];
      element.classList.remove("loader");
      setTimeout(() => {
        const RouterList = document.getElementById("RouterList");
        RouterList?.classList?.remove?.("hide-RouterList");
        const loader = document.getElementById("appLoader");
        loader?.classList?.remove?.("apploader");
      }, 3000);
    }
  }, [loader]);

  return (
    <div className="RouterList hide-RouterList" id="RouterList">
      {loader && <Loader />}
      <LayoutContainer>
        <Suspense fallback={<Loader />}>
          <Routes>
            {newRoutes &&
              newRoutes?.map((route, index) => {
                return route?.isView ? (
                  <Route
                    path={route.path}
                    key={index}
                    exact={route.exact}
                    element={
                      <>
                        {route.isPrivate ? (
                          <PrivateRoute
                            isLayout={route.isLayout}
                            isView={route.viewLabel}
                            route={route}
                          >
                            <route.element />
                          </PrivateRoute>
                        ) : (
                          <route.element />
                        )}
                      </>
                    }
                  />
                ) : (
                  <Route
                    path={route.path}
                    key={index}
                    exact={true}
                    element={<NotFound />}
                  />
                );
              })}
          </Routes>
        </Suspense>
      </LayoutContainer>
    </div>
  );
};
export default RouterList;
