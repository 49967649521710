import React, { useEffect, useState } from "react";
import { Progress, Steps } from "antd/lib";
import Image from "../Image";
import Box from "../Box";
import CompletedCheckIcon from "../../../assets/images/check_circle_completed.svg";
import InprogressCheckIcon from "../../../assets/images/check_circle_in_progress.svg";
import PendingCheckIcon from "../../../assets/images/check_circle_pending.svg";
import Typography from "../Typography";
import CustomButton from "../CustomButton";
import { useTimer } from "../../../hooks";
import {
  approvalPendingStatus,
  ERROR_MSG,
  successResendEmailMsg,
  WORK_FLOW_TYPES,
} from "../../../constants";
import {
  LOCAL_STORAGE_KEY,
  NOTIFICATION_TYPE,
  getDateAndTimeForApproval,
  getItem,
  showNotification,
} from "../../../utils";
import { getWorkflowSteps, resendApprovalEmail } from "../../../api";
import When from "../When";
import "./workflow-step.scss";

export default function WorkflowStep({ type, id }) {
  const [stepsData, setStepsData] = useState([]);
  const [seconds, startTimer] = useTimer(5);
  const [nextApproverIndex, setNextApproverIndex] = useState(null);

  const handleResendApprovalEmail = (stepData) => {
    const data = {
      payload: [
        {
          event_ref_id: stepData?.event_ref_id,
          type,
          workflow_id: stepData?.workflow_id,
        },
      ],
    };
    startTimer();
    resendApprovalEmail({ data })
      .then(() => {
        showNotification(
          NOTIFICATION_TYPE.success,
          successResendEmailMsg(type === WORK_FLOW_TYPES.AP)
        );
      })
      .catch((err) => {
        showNotification(
          NOTIFICATION_TYPE.error,
          "",
          err?.response?.data?.message || ERROR_MSG.common
        );
      });
  };

  const StepContent = ({ step, findNextApprover, index }) => (
    <Box>
      <Typography
        className="description"
        variant="body"
        text={
          step.approved_at
            ? getDateAndTimeForApproval(step.approved_at)
            : approvalPendingStatus
        }
      />
      <When
        condition={
          [WORK_FLOW_TYPES.AP, WORK_FLOW_TYPES.PO].includes(type) &&
          index === findNextApprover
        }
      >
        <CustomButton
          onClick={() => handleResendApprovalEmail(step)}
          text={
            <div className="timer-label">
              <When condition={seconds}>
                <Progress
                  type="circle"
                  percent={(seconds / 5) * 100} // Convert seconds to percentage
                  format={(percent) => `${Math.round((percent / 100) * 5)}`}
                  size="small"
                  strokeColor="#000E1C"
                  className="m-r-10px"
                />
              </When>
              Resend approval email
            </div>
          }
          disabled={seconds}
        />
      </When>
    </Box>
  );

  useEffect(() => {
    getWorkflowSteps({
      type,
      data: {
        event_ref_ids: id,
      },
    }).then((res) => {
      const findNextApprover = res?.data?.result?.findIndex(
        (data) => !data.approved_by
      );
      setNextApproverIndex(findNextApprover);
      setStepsData(res?.data?.result);
    });
  }, []);

  const getCheckIcon = (step, index, nextApproverIndex) => {
    if (step.approved_by) {
      return CompletedCheckIcon;
    }
    if (!step.approved_by && index === nextApproverIndex) {
      return InprogressCheckIcon;
    }
    return PendingCheckIcon;
  };

  const getStepName = (item) => {
    return `${item.step_name} ${
      item.approver_id === getItem(LOCAL_STORAGE_KEY.USER_ORD_ID) ? "(You)" : ""
    }`;
  };

  const steps =
    stepsData.length &&
    stepsData.map((step, index) => ({
      title: (
        <Typography
          variant="secondary"
          text={getStepName(step)}
          ellipsis={{
            tooltip: getStepName(step),
          }}
        />
      ),
      icon: <Image src={getCheckIcon(step, index, nextApproverIndex)} />,
      description: (
        <StepContent
          step={step}
          findNextApprover={nextApproverIndex}
          index={index}
        />
      ),
    }));

  return (
    <When condition={steps.length}>
      <div className="step-work-container">
        <Steps className="m-b-20px" labelPlacement="vertical" items={steps} />
      </div>
    </When>
  );
}
