import React, { memo, useState } from "react";
import { Button as AButton } from "antd";
import PropTypes from "prop-types";

function Button({
  text,
  className,
  showLoader,
  onClick,
  size,
  disabled,
  icon,
  loaderText,
  ...rest
}) {
  const [loading, setLoading] = useState(false);

  const handleLoadingState = () => {
    setLoading(true);
    onClick()?.finally(() => setLoading(false));
  };
  return (
    <AButton
      className={`custom-button ${className}`}
      onClick={showLoader ? handleLoadingState : onClick}
      size={size}
      disabled={disabled || loading}
      icon={icon}
      {...rest}
      loading={loading}
    >
      {loading && loaderText ? loaderText : text}
    </AButton>
  );
}

Button.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(Button);
