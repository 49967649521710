import React, { useState } from "react";
import { CommonSelect } from "..";
import { Empty } from "antd";
import { scriptTagRegex, showNotification } from "../../../utils";
import { ERROR_MSG, NOTIFICATION_TYPE } from "../../../constants";
import "./custom-select.scss";

function CustomSelect({ onAddOption, isCreatable, ...rest }) {
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState();

  const handleAddValue = async () => {
    setLoading(true);
    onAddOption(search)
      .catch((e) => {
        showNotification(
          NOTIFICATION_TYPE.error,
          e?.response?.data?.message || ERROR_MSG.common
        );
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = (searchValue) => {
    setSearch(searchValue.replace(scriptTagRegex, "").substring(0, 100));
  };

  return (
    <CommonSelect
      onSearch={handleSearch}
      searchValue={search}
      notFoundContent={
        isCreatable && search ? (
          <div
            className="dynamically-created-input"
            onClick={loading ? null : handleAddValue}
          >
            {loading ? `Creating "${search}"...` : `Create "${search}"`}
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    />
  );
}

export default CustomSelect;
