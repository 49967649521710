import { useState } from "react";
import PropTypes from "prop-types";
import { Button, CustomButton, Icon, When } from "..";
import { Popover } from "antd";
import "./filter.scss";
import FilterListItem from "./FilterListItem";
import { useEffect } from "react";
import { ACCENTS, BUTTON_VARIANT } from "../../../constants";

export default function Filter({
  items,
  value,
  onSelectedChange,
  label,
  variables,
  customSize,
  getLastRemovedKidId,
}) {
  const [selected, setSelected] = useState(value);
  const [addFilterStatus, setAddFilterStatus] = useState(false);

  const menuOnSelect = (selectedKeys) => {
    onSelectedChange && onSelectedChange({ keys: selectedKeys });
    setSelected(selectedKeys);
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const kids = (
    <div>
      {items?.map((item, index) => {
        return (
          <FilterListItem
            key={`${Object.values(variables).join("-filterList")}-${index}`}
            selected={selected}
            onSelectedChange={menuOnSelect}
            item={item}
            variables={variables}
            addFilterOpen={addFilterStatus}
            getLastRemovedKidId={getLastRemovedKidId}
          />
        );
      })}
    </div>
  );

  const filterBtn = (
    <CustomButton
      text={label}
      icon={<Icon iconName={"filter_alt"} />}
      accent={ACCENTS.SECONDARY}
      variant={BUTTON_VARIANT.BUTTON_HOLLOW}
      className="filterButton m-r-8px m-v-4px"
      customSize={customSize}
      disabled={!items.length}
    />
  );

  return (
    <div
      key={`${Object.values(variables).join("-")}`}
      style={{ whiteSpace: "wrap" }}
    >
      <When condition={!items.length}>{filterBtn}</When>
      <When condition={!!items.length}>
        <Popover
          overlayClassName="filter-multi"
          content={kids}
          trigger="click"
          placement="bottomLeft"
          onOpenChange={(visible) => {
            setAddFilterStatus(visible);
          }}
        >
          <div>{filterBtn}</div>
        </Popover>
      </When>
    </div>
  );
}

Filter.propTypes = {
  items: PropTypes.array,
  value: PropTypes.array,
  onSelectedChange: PropTypes.func,
  getLastRemovedKidId: PropTypes.func,
  label: PropTypes.string,
  variables: PropTypes.object,
};

Filter.defaultProps = {
  value: [],
  items: [],
  label: "Add filter",
  variables: {},
};
