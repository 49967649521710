import { useEffect, useRef } from "react";
import {
  ACCENTS,
  BUTTON_VARIANT,
  VIEW_LESS_BUTTON,
  VIEW_MORE_BUTTON,
} from "../../../constants";
import { useToggle } from "../../../hooks";
import ComponentLoader from "../ComponentLoader";
import CustomButton from "../CustomButton";
import Divider from "../Divider";
import Column from "../Grid/Column";
import Row from "../Grid/Row";
import Icon from "../Icon";
import When from "../When";

function SectionTransition({
  loading,
  sectionData,
  maxCount = 12,
  renderElementInTheSection,
  autoOpen,
  isSectionShow = true,
  callParentFunction = false,
  callFunction,
  showMoreData = false,
  borderSize = 20,
  buttonSize = 4,
}) {
  const { isOpen: viewMore, toggle: setViewMore } = useToggle();
  const sectionMountedWithData = useRef(false);
  const getElementsToRender = viewMore
    ? sectionData
    : sectionData.slice(0, maxCount);

  useEffect(() => {
    if (!viewMore && showMoreData) {
      setViewMore();
    }
  }, [showMoreData]);

  const Section = () => {
    return (
      <>
        <Row className={"w-100"} gutter={[24, 24]}>
          {getElementsToRender.map(({ label, value, ...rest }) => {
            return (
              <Column span={6} key={value}>
                {renderElementInTheSection({
                  label,
                  value,
                  ...rest,
                })}
              </Column>
            );
          })}
        </Row>
      </>
    );
  };

  const showDataOrHide = () => {
    if (callParentFunction) {
      callFunction(!viewMore);
    }
    setViewMore();
  };

  useEffect(() => {
    if (
      autoOpen &&
      sectionMountedWithData.current &&
      sectionData.length > maxCount &&
      !viewMore
    ) {
      setViewMore();
    }
    if (sectionData.length && !sectionMountedWithData.current) {
      sectionMountedWithData.current = true;
    }
  }, [sectionData]);

  return (
    <>
      <When condition={loading}>
        <ComponentLoader wrapperClassName={"w-100"} />
      </When>
      <When condition={sectionData?.length}>
        <When condition={isSectionShow}>
          <Section />
        </When>
        <When condition={sectionData?.length > maxCount}>
          <Row className="align-items-center w-100">
            <Column
              {...{
                xs: borderSize,
                sm: borderSize,
                md: borderSize,
                lg: borderSize,
                xl: borderSize,
                xxl: 22,
              }}
            >
              <Divider orientation="right" />
            </Column>
            <Column
              {...{
                xs: buttonSize,
                sm: buttonSize,
                md: buttonSize,
                lg: buttonSize,
                xl: buttonSize,
                xxl: 2,
              }}
            >
              <CustomButton
                variant={BUTTON_VARIANT.LINK}
                accent={ACCENTS.SECONDARY}
                icon={
                  <Icon
                    iconName={
                      viewMore ? "keyboard_arrow_up" : "keyboard_arrow_down"
                    }
                  />
                }
                text={viewMore ? VIEW_LESS_BUTTON : VIEW_MORE_BUTTON}
                onClick={showDataOrHide}
              />
            </Column>
          </Row>
        </When>
      </When>
    </>
  );
}

export default SectionTransition;
