import { NOTIFICATION_TYPE, showNotification } from "../../utils/notifications";
import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import { WORKFLOW_CONSTANT } from "../../constants/invoices";
import { loadingStart, loadingStop } from "./loader";

export const createNewWorkflow = (data, refreshData) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.invoice.createNewWorkflow,
      data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          WORKFLOW_CONSTANT.WORKFLOW_CREATED
        );
        resolve(response.data?.result);
        refreshData();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateWorkflow = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.invoice.updateWorkflow,
      data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          WORKFLOW_CONSTANT.WORKFLOW_UPDATED
        );
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getWorkflowCategoryList = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.invoice.getWorkflowCategory,
      data,
    })
      .then((response) => {
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const teamManagerAndApList = () => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.smart_approval.teamManagerAndApList,
    })
      .then((response) => {
        resolve(response?.data?.result);
      })
      .catch((error) => {
        reject(error);
      })
  });
};
export const invoiceSupplierList = (tabType) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.invoice.invoiceSupplierList(tabType),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveToggleAutomation = (data, methods) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: methods ? "POST" : "GET",
      url: PATH.invoice.saveToggleAutomation,
      data: methods ? data : {},
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const createMApping = (type, data, editMode) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: editMode ? "PUT" : "POST",
      url: PATH.invoice.createMApping(type),
      data: data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const deleteMapping = (activeTab, data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "DELETE",
      url: PATH.invoice.deleteMapping(activeTab),
      data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const addMappingListing = (api) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.invoice.addMappingListing(api),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const handleMappingListing = (type, Id) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.invoice.handleMappingListing(type),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
