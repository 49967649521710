import { NOTIFICATION_TYPE, showNotification } from "../../utils/notifications";
import { apiClient } from "../service/ApiConfig";
import { CLIENT_ID, CLIENT_SECRET, PATH } from "../service/apiConstant";
import {
  LOGIN_USER,
  LOGOUT_USER,
  EXPENSES_LIST,
  UPDATE_PROFILE,
  ORGANISATION_AMOUNT,
  USER_ADDRESS,
  USER_ALL_DATA,
  USER_FILTER_DATA,
  FILTER_TYPE_USER,
  USER_FILTER_RESET,
  EXPENSES_FILTER_DATA,
  EXPENSES_FILTER_ITEM,
} from "../service/types";
import { loadingStart, loadingStop } from "./loader";
import { CARD_EXPENSE_CONSTANT, LOCAL_STORAGE_KEY } from "../../utils/constant";
import { profileConstants } from "../../constants/Profile";
import {
  INVOICE_SUCCESS_MSG_CREATE,
  DRAFT_INVOICE_SUCCESS_MSG_CREATE,
} from "../../constants";
import { isFileCompressionEnabled, refreshTokenPayload } from "../../helper";
import { getItem, capitalizeEachWord, saveCurrenciesToLS } from "../../utils";

export const loginUser = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.login,
      data,
    })
      .then(async (response) => {
        // if (false) {
        //   const payload = {
        //     refresh_token: response.data.result[0].refresh_token,
        //     org_id: response.data.result[0].org_id,
        //     user_id: response.data.result[0].id,
        //     grant_type: "refresh_token",
        //     client_id: CLIENT_ID,
        //     client_secret: CLIENT_SECRET,
        //     scope: "*",
        //   };
        //   const refreshLoginData = await refreshLogin(payload);

        //   if (refreshLoginData?.result[0]) {
        //     response.data.result[0].refresh_token =
        //       refreshLoginData?.result[0].refresh_token;
        //     response.data.result[0].access_token =
        //       refreshLoginData?.result[0].access_token;
        //   }
        // }
        dispatch({
          type: LOGIN_USER,
          payload: response.data.result[0],
        });
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const afterLoginAction = (response) => ({
  type: LOGIN_USER,
  payload: response,
});

export const getsanctionCheck = (id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.auth.sanctionCheck(id),
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sanctionCheck = (data, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.sanctionCheck(id),
      data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyOtp = (data, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.verifyRegOTP(),
      data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const userRegistration = (data, id) => (dispatch) => {
  delete data.userId;
  data.questions[0].question_id = "71640d17-b32b-4842-8c00-b1af7e26bb6e";
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.auth.selfRegistration(id),
      data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logoutUser = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.token_revoke,
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        dispatch({
          type: LOGOUT_USER,
          payload: [],
        });
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const refreshLoginWithoutDipatch = (data, record) => {
  const refreshLoginValue = {
    grant_type: "refresh_token",
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    scope: "*",
    refresh_token: localStorage.getItem("refresh_token"),
    org_id: localStorage.getItem("org_id"),
    user_id: localStorage.getItem("id"),
  };
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.refresh_login,
      data: data || refreshLoginValue,
    })
      .then((response) => {
        const details = response?.data?.result[0] || {};
        resolve(response?.data);
        const Payload = {
          ...record,
          refresh_token: details?.refresh_token,
          access_token: details?.access_token,
        };
      })
      .catch((error) => {
        // alert(2)
        // if (error?.response?.status === 422 || error?.response?.status === undefined) {
        //   return;
        // }
        reject(error);
      });
  });
};

export const refreshLogin = (data, record) => (dispatch) => {
  const refreshLoginValue = {
    grant_type: "refresh_token",
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    scope: "*",
    refresh_token: localStorage.getItem("refresh_token"),
    org_id: localStorage.getItem("org_id"),
    user_id: localStorage.getItem("id"),
  };
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.refresh_login,
      data: data || refreshLoginValue,
    })
      .then((response) => {
        const details = response?.data?.result[0] || {};
        resolve(response?.data);
        const Payload = {
          ...record,
          refresh_token: details?.refresh_token,
          access_token: details?.access_token,
        };
        dispatch({
          type: LOGIN_USER,
          payload: Payload,
        });
      })
      .catch((error) => {
        // alert(3)
        // if (error?.response?.status === 422 || error?.response?.status === undefined) {
        //   return;
        // }
        reject(error);
      });
  });
};

export const forgotPassword = (data, email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.auth.forgotPassword(email),
      data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Password changed successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const forgotPasswordVerify = (data) => (dispatch) => {
  const email = data.email;
  delete data.email;
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.forgotPasswordVerify(email),
      data,
    })
      .then((response) => {
        showNotification(NOTIFICATION_TYPE.success, "OTP sent via SMS");
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const changePassword = (data, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.auth.changePassword(id),
      data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Password changed successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/* Team API */

export const createNewTeam = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.teams.createTeam,
      data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Team created successfully"
        );
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UpdateTeam = (data, id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.auth.updateTeamMember(id),
      data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Team details updated successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DeleteTeam = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.deleteTeamMember,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getNotInTeamMembers = () => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getNotInTeamMembersList,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const getSelectedTeamMember = (teamId, orgId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getSelectedTeamMemberList(teamId, orgId),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getOTPrequirment = () => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.auth.otpRequirment(),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const getUserManagerListForDropDown = (teamId) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getUserManagerListForDropDown(teamId),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const getUserMemeberListForDropDown = (id) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getUserMemeberListForDropDown(id),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSelectedTeamManager = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getSelectedTeamManagerList,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCurrentMembers = (teamId) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getCurrentMembersList(teamId),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const getNotTeamMembers = () => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getNotTeamMemberList(),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const getCurrentManager = (teamId) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getCurrentManagerList(teamId),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const addMemberToTeam = (teamId, userId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.teams.addNewMember(teamId, userId),
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Member added to the team successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addManager = (teamId, userId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.teams.addNewManager(teamId, userId),
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Manager assigned for the team successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const unassignMember = (teamId, userId) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.teams.unassignMember(teamId, userId),
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Member removed from the team successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const unassignManager = (teamId, userId) => (dispatch) => {
  dispatch(loadingStart());

  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.teams.unassignManager(teamId, userId),
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Manager removed from the team successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const getTeamsList = (data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getAllTeamsList,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const getTeam = (data, id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getTeamByID(id),
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getNestedTeamListing = (data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getNastedTeam,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

/* User API */

export const GetUserById =
  (team, data, status = "active", role, filterUrl) =>
  (dispatch) => {
    dispatch(loadingStart());
    return new Promise((resolve, reject) => {
      apiClient({
        method: "GET",
        url: PATH.user.GetUser(team, data, status, role, filterUrl),
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => dispatch(loadingStop()));
    });
  };

//-------------------All Card filter get list----------
export const getFilterListAllCard = (orgId) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.user.getFilterListAllCard(orgId),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

//-------------------My Cards filter get list----------
export const getFilterListMyCard = (orgId, userOrgId) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.user.getFilterListMyCard(orgId, userOrgId),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const getUserByDetail = (id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.auth.GetUser(id),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const assignUserToTeam = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.teams.assignUserToTeam(data.teamId, data.userOrgId),
      data,
    })
      .then((response) => {
        // showNotification(NOTIFICATION_TYPE.success, 'User', ' Created Successfully')
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUserToTeam = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.teams.updateUserToTeam(data.teamId, data.userOrgId),
      data,
    })
      .then((response) => {
        // showNotification(NOTIFICATION_TYPE.success, 'User', ' Created Successfully')
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const assignUserToRole = (data) => {
  const body = {
    status: "active",
  };
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.teams.assignUserToRole(
        data.role,
        data.userOrgId,
        data.organization
      ),
      data: body,
    })
      .then((response) => {
        // showNotification(NOTIFICATION_TYPE.success, 'User', ' Created Successfully')
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createUser = (data, type) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.user.createNewUser(type),
      data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Invitation sent successfully"
        );
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUser = (data, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.user.UpdateUser(id),
      data,
    })
      .then((response) => {
        // showNotification(
        //   NOTIFICATION_TYPE.success,
        //   "User details updated successfully"
        // );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSelectedUser = (id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "DELETE",
      url: PATH.user.DeleteUser(id),
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "User deleted successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const invitationLink = (id, type) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.user.invitationLink(id, type),
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Invitation link sent successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const disableUser = (id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.user.disableUser(id),
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "User disabled successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const activeUser = (id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.user.activeUser(id),
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "User enabled successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resendInvite = (id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.user.resendInvite(id),
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Invitation sent successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const removeInvite = (id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.user.removeInvite(id),
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Invitation removed successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllTeams = () => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getAllTeamsList,
    })
      .then((response) => {
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllRoles = (orgId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getAllRoles(orgId),
    })
      .then((response) => {
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getUserRole = () => {
  const user_org_id = localStorage.getItem("user_org_id");
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.teams.getUserRole(user_org_id),
    })
      .then((response) => {
        resolve(response?.data?.result[0]?.role?.name);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const teamStatus = (team_id, status) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.teams.teamStatus(team_id, status),
    })
      .then((response) => {
        // showNotification(NOTIFICATION_TYPE.success, 'User', ' Created Successfully')
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const getPendingRequest = (userOrgId, teamId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.user.getPendingRequest(userOrgId, teamId),
    })
      .then((response) => {
        resolve(response?.data?.result);
      })
      .catch((error) => {
        reject(error?.response?.data?.message);
      });
  });
};

//   setting address  //
export const createAddress = (id, data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.user.createUserAddress(id),
      data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Address updated successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// -------------    profile update ----------- //

export const UpdateSelectedUserProfile =
  (userId, data, analytics = false) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      apiClient({
        method: "PUT",
        url: PATH.user.UpdateUserProfile(userId, analytics),
        data,
      })
        .then((response) => {
          resolve(response.data);

          showNotification(
            NOTIFICATION_TYPE.success,
            profileConstants.updateSuccessMessage
          );
          dispatch({
            type: UPDATE_PROFILE,
            payload: response.data.result,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

export const UserProfileUpdate = (email, CountryCode, Contact) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.user.UserProfileUpdate(email, CountryCode, Contact),
    })
      .then((response) => {
        resolve(response);
        showNotification(NOTIFICATION_TYPE.success, "OTP sent via SMS");
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UserProfileVerify = (data, userId, CountryCode, Phone) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.user.UserProfileVerify(userId, CountryCode, Phone),
      data,
    })
      .then((response) => {
        resolve(response);
        // showNotification(
        //   NOTIFICATION_TYPE.success,
        //   "verify otp successfully"
        // );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UpdateMobileModular = (data, userOrgId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.user.mobileModularUpdate(userOrgId),
      data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/* Transaction API */

export const getAllTransactionList = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.cards.getTransactionList,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GetCustBilling = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.GetCustomerBilling,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GetCardList = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.CardList,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const ApproveSelectedCard = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.ApproveCard,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const BlockSelectedCard = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.BlockCard,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UnblockSelectedCard = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.UnblockCard,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DeleteSelectedcard = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.DeleteCard,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DeclineSlectedCard = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.DeclineCard,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const EditSelectedCardLimit = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.EditCardLimit,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CancelSelectedCardReq = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.CancelCardReq,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CreateSelectedUserAddress = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.user.CreateUserAddress,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserAddress = (id, addressId, data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.user.createUserAddress(id, addressId),
    })
      .then((response) => {
        resolve(response.data);
        dispatch({
          type: USER_ADDRESS,
          payload: response?.data?.result[0],
        });
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const verifyEmailAddress = (email) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.auth.verifyEmail(email),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const verifyEmailAddressRegistration = (
  email,
  contact,
  country_code
) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.auth.verifyEmailRegistration(email, contact, country_code),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const loginWithOtp = (data, userId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.verifyOtp(userId),
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const loginCardWithOtp = (data, userId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.verifyCardOtp(userId),
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllNotifications = (userId, orgId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.user.getNotification(orgId, userId),
    })
      .then((response) => {
        resolve(response?.data?.result || []);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ---------Account Payable API---------------

export const getSuppliersList = (searchURL, entityId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.accountPayable.getSuppliers(searchURL, entityId),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCountry = () => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.accountPayable.getCountriesList,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCurrencies = () => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.accountPayable.getCurrencyList,
    })
      .then((response) => {
        resolve(response.data);
        saveCurrenciesToLS(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getServiceDurationUnit = () => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.accountPayable.getServiceDurationUnitList,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getXeroCategory = (page_name = "") => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.accountPayable.getXeroCategoryList(page_name),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getXeroCustomer = () => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.cardExpenses.getXeroCustomerList,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createPayment = (data, file) => {
  var formData = new FormData();
  if (file?.length) {
    formData.append("invoice_attachment", file[0].originFileObj);
  }
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.accountPayable.createPayment,
      data: formData,
    })
      .then((response) => {
        showNotification(NOTIFICATION_TYPE.success, INVOICE_SUCCESS_MSG_CREATE);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createPaymentAP = (data, file) => (dispatch) => {
  dispatch(loadingStart());
  var formData = new FormData();
  if (isFileCompressionEnabled()) {
    if (file) {
      formData.append("invoice_attachment", file);
    }
  } else {
    if (file?.length) {
      formData.append("invoice_attachment", file[0].originFileObj);
    }
  }

  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.accountPayable.createPayment,
      data: formData,
    })
      .then((response) => {
        showNotification(NOTIFICATION_TYPE.success, INVOICE_SUCCESS_MSG_CREATE);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const ocrReceiptsUpload = (data, type) => {
  var bodyFormData = new FormData();
  bodyFormData.append("receipt_attachment", data);
  const url = type
    ? PATH.accountPayable.ocrReceiptsUpload + `/${type}`
    : PATH.accountPayable.ocrReceiptsUpload;
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url,
      data: bodyFormData,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const accountsPayableFileDelete = (id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.accountPayable.apFiledelete(id),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const assignSuppliers = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.accountPayable.assignSuppliers,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editPayment = (data, id, file) => {
  var formData = new FormData();
  if (file?.length && file[0]?.originFileObj) {
    formData.append("invoice_attachment", file[0].originFileObj);
  }
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });

  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.accountPayable.updatePayment(id),
      data: formData,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Payment details updated successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editPaymentAP = (data, id, file) => {
  var formData = new FormData();
  if (isFileCompressionEnabled()) {
    if (file?.name) {
      formData.append("invoice_attachment", file);
    }
  } else {
    if (file?.length && file[0]?.originFileObj) {
      formData.append("invoice_attachment", file[0].originFileObj);
    }
  }
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });

  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.accountPayable.updatePayment(id),
      data: formData,
    })
      .then((response) => {
        console.log(response, "update payment");

        showNotification(
          NOTIFICATION_TYPE.success,
          "Invoice details updated successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const approvePayment = (id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.accountPayable.approvePayment(id),
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          response?.data?.result.records_updated
            ? "Invoice approved successfully"
            : response?.data?.result.status
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const rejectPayment = (id, data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.accountPayable.rejectPayment(id),
      data,
    })
      .then((response) => {
        showNotification(NOTIFICATION_TYPE.success, "Invoice rejected");
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ----------------Card expenses-------------------------

export const getAllExpensesList =
  (status = "outstanding", page = 1, visibility = "my", searchKey = null) =>
  (dispatch) => {
    dispatch(loadingStart());
    return new Promise((resolve, reject) => {
      apiClient({
        method: "GET",
        url: PATH.cardExpenses.getExpensesList(status, page, visibility),
      })
        .then((response) => {
          const details = {
            current_page: response?.data?.result?.meta?.current_page,
            data: response?.data?.result?.data?.map((record) => {
              if (
                record?.card_expense_details[0] &&
                record?.card_expense_details[0]?.activity_status === "reversal"
              ) {
                record.card_expense_details[0].activity_status = "reversed";
              }
              return record;
            }),
            last_page: response?.data?.result?.meta?.last_page,
            per_page: response?.data?.result?.meta?.per_page,
            total: response?.data?.result?.meta?.total,
            filters: response?.data?.result?.meta?.filters,
          };
          resolve(response.data.result);
          dispatch({
            type: EXPENSES_LIST,
            payload: details,
            status,
          });
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => dispatch(loadingStop()));
    });
  };

//    card expenses filter //
export const cardExpensesFilter =
  (status = "outstanding", page = 1, expensesFilterUrl) =>
  (dispatch) => {
    dispatch(loadingStart());
    return new Promise((resolve, reject) => {
      apiClient({
        method: "GET",
        url: PATH.cardExpenses.cardExpensesFilter(
          status,
          page,
          expensesFilterUrl
        ),
      })
        .then((response) => {
          const details = {
            current_page: response?.data?.result?.meta?.current_page,
            data: response?.data?.result?.data?.map((record) => {
              if (
                record?.card_expense_details[0] &&
                record?.card_expense_details[0]?.activity_status === "reversal"
              ) {
                record.card_expense_details[0].activity_status = "reversed";
              }
              return record;
            }),
            last_page: response?.data?.result?.meta?.last_page,
            per_page: response?.data?.result?.meta?.per_page,
            total: response?.data?.result?.meta?.total,
          };
          resolve(response.data.result);
          dispatch({
            type: EXPENSES_LIST,
            payload: details,
            status,
          });
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => dispatch(loadingStop()));
    });
  };
export const getFilterListExpenses = (status) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.cardExpenses.getExpensesFilterList(status),
    })
      .then((response) => {
        resolve(response.data);
        dispatch({
          type: EXPENSES_FILTER_ITEM,
          payload: response.data,
        });
      })
      .catch((error) => {
        reject(error);
      });
    // .finally(() => dispatch(loadingStop()));
  });
};
//-------------------cardexpenses filter data-----  //
export const expensesFilterData = (data) => (dispatch) => {
  return dispatch({
    type: EXPENSES_FILTER_DATA,
    payload: data,
  });
};

export const getXeroDepartment = () => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.cardExpenses.getXeroDepartmentList,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getXeroCustomerContactID = () => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.cardExpenses.getXeroCustomerContactIDList,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getVatRate = () => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.cardExpenses.getVatRateList,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const expenseFileUpload = (data, file) => {
  var bodyFormData = new FormData();
  if (file?.length) {
    bodyFormData.append("receipt_attachment", file[0].originFileObj);
  }
  Object.keys(data).forEach((k) => {
    bodyFormData.append(k, data[k]);
  });
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.cardExpenses.expenseFileUpload,
      data: bodyFormData,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const expenseFileDelete = (id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.cardExpenses.expenseFileDelete(id),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const markExpenseForReview = (data, file) => {
  var reviewFormData = new FormData();
  if (file?.length && file[0]?.originFileObj) {
    reviewFormData.append("receipt_attachment", file[0].originFileObj);
  }
  Object.keys(data).forEach((k) => {
    reviewFormData.append(k, data[k]);
  });
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.cardExpenses.markForReview,
      data: reviewFormData,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Expense marked for review"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const markExpenseForReviewCE = (data, file) => {
  // var reviewFormData = new FormData();
  // if (file?.length && file[0]?.originFileObj) {
  //   reviewFormData.append("receipt_attachment", file[0].originFileObj);
  // }
  // Object.keys(data).forEach((k) => {
  //   reviewFormData.append(k, data[k]);
  // });
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.cardExpenses.markForReview,
      data: data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          response?.data?.result?.data?.message
            ? response?.data?.result?.data?.message
            : CARD_EXPENSE_CONSTANT.MARK_FOR_REVIEW
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const markExpenseAsApproved = (data, file, reviewId) => {
  var approvedFormData = new FormData();
  if (file?.length && file[0]?.originFileObj) {
    approvedFormData.append("receipt_attachment", file[0].originFileObj);
  }
  Object.keys(data).forEach((k) => {
    approvedFormData.append(k, data[k]);
  });
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.cardExpenses.markAsApproved(reviewId),
      data: approvedFormData,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          response?.data?.result.records_updated
            ? "Expense has been marked as approved."
            : response?.data?.result.status
        );
        resolve(response.data);
      })
      .catch((error) => {
        showNotification(
          NOTIFICATION_TYPE.error,
          error?.response?.data?.message
        );
        reject(error);
      });
  });
};

export const archiveExpense = (data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.cardExpenses.archiveExpenses,
      data: data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          `${
            data?.expense_ids?.split?.(",")?.length > 1
              ? "Expenses have"
              : " Expense has"
          } been archived successfully`
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const saveExpenseAsDraft = (data, file, id) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.cardExpenses.saveAsDraft(id),
      data: data,
    })
      .then((response) => {
        // showNotification(
        //   NOTIFICATION_TYPE.success,
        //   "Expense saved as draft"
        // );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const saveExpenseAsDraftCE = (data, file, id) => {
  // var draftFormData = new FormData();
  // if (file?.length && file[0]?.originFileObj) {
  //   draftFormData.append("receipt_attachment", file[0].originFileObj);
  // }
  // Object.keys(data).forEach((k) => {
  //   draftFormData.append(k, data[k]);
  // });
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.cardExpenses.saveAsDraft(id),
      data: data,
    })
      .then((response) => {
        showNotification(NOTIFICATION_TYPE.success, "Expense saved as draft");
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const SaveOutstandingAsDraft = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.cardExpenses.saveOutstandingAsDraft,
      data: data,
    })
      .then((response) => {
        showNotification(NOTIFICATION_TYPE.success, "Expense saved as draft");
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

////-----------card ecpenses download excel ---//
export const downloadCardExpenseListing = async (
  statustab,
  expensesPageNo,
  expensesUrl
) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.cardExpenses.downloadExpenseList(
        statustab,
        expensesPageNo,
        expensesUrl
      ),
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getAlluserDownload = async (pageNo, userurl) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.user.downloadUser(pageNo, userurl),
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        showNotification(
          NOTIFICATION_TYPE.error,
          "There seems to be an issue. Please try again."
        );
        reject(error);
      });
  });
};
// export const getAlluserDownload = () =>  (dispatch)=>{
//   dispatch(loadingStart());
//   return new Promise((resolve, reject) => {
//     apiClient({
//       method: "GET",
//       url: PATH.user.downloadUser,
//     })
//       .then((response) => {
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       }).finally(()=>dispatch(loadingStop()));
//   });
// };

//  ----------------------  Registration form  --------------------------//

// Registration analytics //

export const RegistrationAnalytics = (data, invite) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.RegistrationAnalytics(invite),
      data,
    })
      .then((response) => {
        resolve(response.data);
        if (response.data.result.msg === "OTP sent via SMS") {
          showNotification(
            NOTIFICATION_TYPE.success,

            "OTP sent via SMS"
          );
          return;
        }
        // showNotification(
        //   NOTIFICATION_TYPE.success,
        //   "Registration",
        //   "Your registration Successfully"
        // );
      })
      .catch((error) => {
        reject(error);
        // showNotification(
        //   NOTIFICATION_TYPE.error,
        //   "Registration failed",
        //   "Please try again later"
        // );
      });
  });
};
// registration user //

export const UserAnalytics = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.UserAnalytics(),
      data,
    })
      .then((response) => {
        resolve(response.data);
        // showNotification(
        //   NOTIFICATION_TYPE.success,
        //   "Registration",
        //   "Your registration Successfully"
        // );
      })
      .catch((error) => {
        reject(error);
        showNotification(
          NOTIFICATION_TYPE.error,
          "Registration failed",
          "Please try again later"
        );
      });
  });
};
export const UpdateUserAnalytics = (data, userOrgId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.auth.updateUserAnalytics(userOrgId),
      data,
    })
      .then((response) => {
        resolve(response.data);
        // showNotification(
        //   NOTIFICATION_TYPE.success,
        //   "Registration",
        //   "Your registration Successfully"
        // );
      })
      .catch((error) => {
        reject(error);
        showNotification(
          NOTIFICATION_TYPE.error,
          "Registration failed",
          "Please try again later"
        );
      });
  });
};

export const otpVerifyForAnalytics = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.verifyOtpAnalytics(),
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const lastLoginUpdate = (userOrgId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.auth.lastLoginUpdate(userOrgId),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const organisationAmount = (org_id) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.user.OrganisationAmount(org_id),
    })
      .then((res) => {
        resolve(res?.data?.result?.availableBalance);
        dispatch({
          type: ORGANISATION_AMOUNT,
          payload: res?.data?.result?.availableBalance,
        });
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const registrationViaLink = (userOrgId) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.auth.RegistrationLink(userOrgId),
    })
      .then((res) => {
        resolve(res?.data?.result);
        // dispatch({
        //   type:ORGANISATION_AMOUNT,
        //   payload:res?.data?.result?.availableBalance,
        // })
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

//  -------------------  filter users page ---------------

export const usersFilter =
  (orgId, page = 1, userFilterUrl) =>
  (dispatch) => {
    dispatch(loadingStart());
    return new Promise((resolve, reject) => {
      apiClient({
        method: "GET",
        url: PATH.user.usersFilter(orgId, page, userFilterUrl),
      })
        .then((response) => {
          let userList = {};
          userList = response?.data?.result?.user_organization;
          resolve(response?.data?.result);
          dispatch({
            type: USER_ALL_DATA,
            payload: userList,
            userFilterUrl: userFilterUrl,
          });
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => dispatch(loadingStop()));
    });
  };

//-------------------users filter data-----  //
export const usersFilterData = (data) => (dispatch) => {
  return dispatch({
    type: USER_FILTER_DATA,
    payload: data,
  });
};
export const resetUserFilter = () => (dispatch) => {
  return dispatch({
    type: USER_FILTER_RESET,
  });
};

export const listFilterType =
  (orgId, teams, roles, page = 1, userFilterUrl) =>
  (dispatch) => {
    dispatch(loadingStart());
    return new Promise((resolve, reject) => {
      apiClient({
        method: "GET",
        url: PATH.user.filterType(),
      })
        .then((response) => {
          let filterList = [];
          filterList = response?.data?.result;
          filterList.users = filterList?.users.map((item) => {
            return {
              full_name: capitalizeEachWord(item?.full_name?.toLowerCase()),
            };
          });
          resolve(filterList);
          dispatch({
            type: FILTER_TYPE_USER,
            payload: filterList,
          });
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => dispatch(loadingStop()));
    });
  };
export const newRefreshToken = async () => {
  try {
    const SSO = getItem(LOCAL_STORAGE_KEY.SSO);
    const payload = refreshTokenPayload({ SSO: SSO });
    const refreshLoginData = await refreshLoginWithoutDipatch(payload);
    if (refreshLoginData.result[0]) {
      Object.keys(refreshLoginData.result[0]).forEach((k) => {
        if (refreshLoginData.result[0][k]) {
          localStorage.setItem(k, refreshLoginData.result[0][k]);
        }
      });
    }
  } catch (e) {
    // alert(e?.response?.status)
    // if (e?.response?.status === 422 || e?.response?.status === undefined) {
    //   console.log("Undefined")
    //   return;
    // }
    localStorage.clear();
    window.location.reload();
  }
};
