import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";

export function sendRequest(data) {
  return apiClient({
    url: PATH.emailAction.sendRequest,
    method: "POST",
    data,
  });
}

export function verifyInvoiceStatus(data) {
  return apiClient({
    url: PATH.emailAction.verifyInvoiceStatus,
    method: "POST",
    data,
  });
}
