import {
  CARD_REQUESTS_TABLE_ALL_APPROVAL_COLUMNS,
  CARD_REQUESTS_TABLE_MY_APPROVAL_COLUMNS,
  CARD_REQUESTS_TABS,
  CARD_REQUESTS_TABS_KEYS,
  CARD_REQUESTS_TABS_PU,
  CARD_REQUEST_PAGE_CONFIG,
} from "../../constants/CardRequests";
import {
  getFormattedDateInUTC,
  getItem,
  GLOBAL_NOT_APPLICABLE_SYMBOL,
  isPlatformUser,
  LOCAL_STORAGE_KEY,
  moneyFormatter,
} from "../../utils";

const { STATUS, CARD_TYPE, CREATED_AT_DATE_FORMAT, NEEDS_APPROVER_CONFIG } =
  CARD_REQUEST_PAGE_CONFIG;

function getTStatus(record) {
  if (record?.request_status === 10) {
    return STATUS[0].title;
  } else {
    if (getItem(LOCAL_STORAGE_KEY.USER_ORD_ID) === record?.user_org_id) {
      return STATUS[1].title;
    } else {
      return STATUS[2].title;
    }
  }
}

function getTCardType(card_type) {
  const cardTypeFound =
    card_type && CARD_TYPE.find(({ key }) => key === card_type);
  return cardTypeFound ? cardTypeFound.title : GLOBAL_NOT_APPLICABLE_SYMBOL;
}

function renderRequestType(requestType) {
  return requestType?.replace(/-/g, " ") || GLOBAL_NOT_APPLICABLE_SYMBOL;
}

function getTAmount(amount) {
  return moneyFormatter(amount);
}

function getTCreatedDate(record) {
  return getFormattedDateInUTC(
    record.created_at,
    CREATED_AT_DATE_FORMAT,
    GLOBAL_NOT_APPLICABLE_SYMBOL
  );
}

function getTNeedsApprover(record) {
  if (record?.step_name) {
    if (record.step_name === NEEDS_APPROVER_CONFIG[0].key) {
      return record?.manager_name
        ? record.manager_name
        : NEEDS_APPROVER_CONFIG[0].title;
    } else {
      return record.step_name;
    }
  } else {
    return GLOBAL_NOT_APPLICABLE_SYMBOL;
  }
}

export function getTabsForCardRequestPage(isPlatformUser) {
  return isPlatformUser ? CARD_REQUESTS_TABS_PU : CARD_REQUESTS_TABS;
}

export function getColumnsForCardRequestPage(activeTabKey) {
  return activeTabKey === CARD_REQUESTS_TABS_KEYS.MY_APPROVALS
    ? CARD_REQUESTS_TABLE_MY_APPROVAL_COLUMNS
    : CARD_REQUESTS_TABLE_ALL_APPROVAL_COLUMNS;
}

export function getAllApprovalDataFromListing(listingData, key) {
  return listingData.map((record, index) => {
    return {
      key: `${key}_${index}`,
      tStatus: getTStatus(record),
      tNeedsApprover: getTNeedsApprover(record),
      tRequestorName: record?.requester_name || GLOBAL_NOT_APPLICABLE_SYMBOL,
      tNickName: record?.nickname,
      tRequestType: renderRequestType(record?.type),
      tType: getTCardType(record?.card_type),
      tAmount: getTAmount(record?.amount),
      tCreatedAt: getTCreatedDate(record),
      ...record,
    };
  });
}

export function getMyApprovalDataFromListing(listingData, key) {
  return listingData.map((record, index) => {
    return {
      key: `${key}_${index}`,
      tStatus: getTStatus(record),
      tRequestorName: record?.requester_name || GLOBAL_NOT_APPLICABLE_SYMBOL,
      tNickName: record?.nickname,
      tRequestType: renderRequestType(record?.type),
      tType: getTCardType(record?.card_type),
      tAmount: getTAmount(record?.amount),
      tCreatedAt: getTCreatedDate(record),
      ...record,
    };
  });
}

export function getCardRequestPagePerPageSize({ height }) {
  let pageSize = (height - 170) / CARD_REQUEST_PAGE_CONFIG.ROW_HEIGHT;
  return pageSize > CARD_REQUEST_PAGE_CONFIG.DEFAULT_PAGE_SIZE
    ? pageSize
    : CARD_REQUEST_PAGE_CONFIG.DEFAULT_PAGE_SIZE;
}
