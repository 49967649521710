import { Table } from "antd";
import { memo } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { DEFAULT_THEME } from "../../../utils";
import { Empty } from "../index";
import "./ATable.scss";

function ATable(props) {
  const tableColumn = props.columns
    .filter(({ visible = true }) => visible)
    .map((colData) => {
      return {
        ...colData,
        key: colData.dataIndex,
        title: props.renderHeaders({ title: colData.title }),
        render: (text, record) =>
          props.renderBody({
            record,
            key: colData.dataIndex,
            defaultRender: () => text,
          }),
      };
    });

  const CLoader = {
    indicator: (
      <LoadingOutlined
        style={{ fontSize: 32, color: DEFAULT_THEME.PRIMARY_COLOR }}
        spin
      />
    ),
  };

  return (
    <Table
      dataSource={props.data}
      columns={tableColumn}
      loading={props.data === null ? CLoader : false}
      pagination={false}
      locale={{
        emptyText: <Empty />,
      }}
      onRow={(record) => {
        return {
          onClick: () => props.onRowClick({ record }),
        };
      }}
      tableLayout={"auto"}
    />
  );
}

export default memo(ATable);
