import React from "react";
import {
  Box,
  CustomButton,
  Icon,
  KlooAIButton,
  Typography,
  When,
} from "../../../common";
import { OCR_LINE_ITEMS, WORK_FLOW_TYPES_TO_TEXT } from "../../../../constants";
import { LINE_ITEMS, LINE_ITEM_HEADER } from "../../../../constants/LineItems";
import { capitalizeFirst } from "../../../../utils";

const LineItemsHeader = ({
  module,
  onBackClick,
  disabled,
  lineItemsTableData,
  onKlooAIClick,
  klooAITooltipText,
  showKlooAIButton,
}) => {
  return (
    <Box justifyContent="spaceBetween" className="p-3">
      <Box>
        <CustomButton
          {...LINE_ITEM_HEADER.BACK_BTN}
          icon={<Icon {...LINE_ITEM_HEADER.BACK_BTN.ICON} />}
          onClick={() => onBackClick()}
        />
        <Typography
          {...LINE_ITEM_HEADER.TITLE}
          text={
            capitalizeFirst(WORK_FLOW_TYPES_TO_TEXT[module]) +
            " " +
            LINE_ITEMS.LINE_ITEMS
          }
        />
      </Box>
      <When condition={!disabled && showKlooAIButton}>
        <KlooAIButton
          btnWithToolTip={true}
          onClick={onKlooAIClick}
          toolTipTitle={OCR_LINE_ITEMS.toolTipTitle(
            !lineItemsTableData?.length
              ? OCR_LINE_ITEMS.GENERATE
              : klooAITooltipText
          )}
        />
      </When>
    </Box>
  );
};

export default LineItemsHeader;
