import {
  YapilyHelpDesktopIcon,
  YapilyHelpLoginIcon,
  YapilyHelpQRCodeIcon,
} from "../../../assets/images/Yapily";
import {
  PAYMENT_JOURNEY_PAY_NOW_KEY,
  PAYMENT_JOURNEY_SCHEDULE_KEY,
} from "../../InvoiceManagement";
import { ACCENTS, BUTTON_VARIANT } from "../../theme";
import XeroLogo from "../../../assets/images/logo-xero.png";
import SageIntacctLogo from "../../../assets/images/SageIntacctLogoWithBorder.png";
import SageBusinessCloudLogo from "../../../assets/images/SageBusinessCloudLogo.jpeg";

const yapilyAccountLinkingConfig = {
  buttonTitle: "Import via Yapily",
  buttonHelperText: "Connect new bank account",
};

// Active Institutions Config

const yapilyActiveInstitutionTableConfig = [
  {
    key: "account_name",
    renderAccountDetail: ({ account_name }) => `${account_name}`,
  },
  {
    key: "type",
    renderAccountDetail: ({ type }) => `${type}`,
  },
  {
    key: "account_ident_sortcode",
    renderAccountDetail: ({ account_ident_sortcode, account_id }) =>
      `${account_ident_sortcode} / ${account_id}`,
  },
];

const yapilyConsentActionButtonConfig = [
  {
    key: "withdraw",
    title: "Withdraw",
    deleteModalConfig: {
      title: "Withdraw consent",
      subTitle:
        "You are about to withdraw a consent. Withdrawing this consent will be a permanent action which cannot be undone.",
    },
  },
  {
    key: "renew",
    title: "Renew",
  },
  {
    key: "reconnect",
    title: "Reconnect",
  },
];

const yapilyActiveInstitutionConfig = {
  consentExpiresAtText: (expiryDate, remainingDays) =>
    `Consent expires: ${expiryDate} (${remainingDays} days)`,
  consentExpiredText: (expiryDate) => `Consent expired: ${expiryDate}`,
  consentActionButtonConfig: [...yapilyConsentActionButtonConfig],
  totalInstitutionTextRender: (totalAccounts) =>
    `Following ${totalAccounts} accounts are linked with this consent:`,
  institutionDetailsTable: [...yapilyActiveInstitutionTableConfig],
};

// Consent Journey Config

const yapilyConsentStatusConfig = [
  {
    key: "active",
  },
  {
    key: "inactive",
  },
  {
    key: "AUTHORIZED",
  },
  {
    key: "COMPLETED",
  },
  {
    key: "CONSUMED",
  },
  {
    key: "REJECTED",
  },
];

const yapilyConsentJourneySourceConfig = [
  {
    key: "CREATE_CONSENT",
  },
  {
    key: "RENEW_CONSENT",
    action: "renew",
    successMsg: "Consent renewed successfully",
  },
  {
    key: "RECONNECT_CONSENT",
    action: "renew",
    successMsg: "Consent reconnected successfully",
  },
  {
    key: "EDIT_CONSENT",
    action: "re_authorize",
  },
  {
    key: "WITHDRAW_CONSENT",
    successMsg: "Consent withdrawn successfully",
  },
];

const yapilyConsentJourneyStepsConfig = [
  {
    key: "step_0",
    title: "Select your bank",
    subTitle: () =>
      "Select from the list of banks you would like to connect your Yapily account with.",
    searchBoxPlaceHolder: "Search bank name",
    stepKey: 0,
  },
  {
    key: "step_1",
    title: "Authentication",
    subTitle: (bankName) =>
      `Scan the QR and authenticate with your ${bankName} app.`,
    buttonText: "Use desktop login",
    dividerText: "OR",
    footerButtonText: "Back",
    needHelpSection: {
      triggeredText: "Need help?",
      title: "Let us help you!",
    },
    stepKey: 1,
  },
  {
    key: "step_2",
    title: "Authentication",
    subTitle: (bankName) =>
      `We are waiting to hear back from ${bankName}. Please wait a second.`,
    buttonText: "Relaunch your bank login",
    footerButtonText: "Back",
    stepKey: 2,
  },
];

const yapilyConsentJourneyHelperConfig = [
  {
    text: "Open your mobile phone camera and scan the QR Code.",
    key: "yapily-help-step-1",
    imgConfig: {
      width: 140,
      height: 140,
      src: YapilyHelpQRCodeIcon,
    },
  },
  {
    text: "Login and approve access for Yapily Connect.",
    key: "yapily-help-step-2",
    imgConfig: {
      width: 140,
      height: 140,
      src: YapilyHelpLoginIcon,
    },
  },
  {
    text: "Continue back on your desktop.",
    key: "yapily-help-step-3",
    imgConfig: {
      width: 225,
      height: 140,
      src: YapilyHelpDesktopIcon,
    },
  },
];

// Terms and Conditions Config

const yapilyTermsAndConditionsConfig = [
  {
    text: "By using the service, you agree to Yapily’s",
    type: "normalText",
    key: "yapily-tnc1",
  },
  {
    text: "Terms & Conditions",
    linkURL:
      "https://drive.google.com/file/d/11mNukqbBA_EbEBJd7bn9Idj1iiG8QWIL/view",
    type: "linkText",
    key: "yapily-tnc2",
  },
  {
    text: "and",
    type: "normalText",
    key: "yapily-tnc3",
  },
  {
    text: "Privacy Policy.",
    linkURL: "https://www.yapily.com/legal/privacy-policy",
    type: "linkText",
    key: "yapily-tnc4",
  },
];

const yapilyFooterConfig = {
  readMoreConfig: {
    readMoreTriggerText: "Read More.",
    readMoreDrawerTitle: "Disclaimer",
    readMoreStatement: (
      <>
        By using this service you will be securely redirected to your bank to
        confirm your consent for Yapily Connect to read and receive the
        following information:
        <ul>
          <li>Identification details </li>
          <li>Account(s) details</li>
          <li>Balances</li>
          <li>Interest rates</li>
          <li>Other transactional and account information</li>
        </ul>
      </>
    ),
    readMoreTnCHelperText:
      "For more information please refer to the Yapily Connect’s ",
    readMoreTnCText: "Terms & Conditions.",
    readMoreTnCLink:
      "https://drive.google.com/file/d/11mNukqbBA_EbEBJd7bn9Idj1iiG8QWIL/view",
    readMoreHelperText: "Yapily will retrieve your account information. ",
  },
  termsAndConditionsConfig: [...yapilyTermsAndConditionsConfig],
};

// Landing Page Configuration

const yapilyLandingPageStatusConfig = [
  {
    key: "success",
    variant: "success",
    title: "Congratulations! You are now connected.",
    icon: "check_circle_outline",
    bodyText:
      "Your financial data import is running in the background. Once it's done, you can view the imported data on the homepage.",
  },
  {
    key: "error",
    variant: "fail",
    icon: "cancel",
    title: "Something went wrong while importing data !",
    bodyText:
      "Please try again by relaunching the bank login or scanning the QR code.",
  },
];

const yapilyLandingPagePISConfig = [
  {
    key: `${PAYMENT_JOURNEY_PAY_NOW_KEY}_success`,
    variant: "success",
    title: "Payment completed",
    icon: "check_circle_outline",
    bodyText: "The payment has been processed successfully.",
    source: PAYMENT_JOURNEY_PAY_NOW_KEY,
  },
  {
    key: `${PAYMENT_JOURNEY_SCHEDULE_KEY}_success`,
    variant: "success",
    title: "Payment scheduled",
    icon: "check_circle_outline",
    bodyText: "The payment has been scheduled successfully.",
    source: PAYMENT_JOURNEY_SCHEDULE_KEY,
  },
];

const yapilyLandingPageConfig = {
  landingPageStatusConfig: [...yapilyLandingPageStatusConfig],
  landingPagePISConfig: {
    flag: "PISSource",
    labelConfig: [...yapilyLandingPagePISConfig],
    failureConfig: {
      key: "error",
      variant: "fail",
      icon: "cancel",
      title: "Payment failed",
      bodyText: "Sorry, your payment was unsuccessful. Please try again.",
    },
  },
};

export const YapilyIntegrationConfig = {
  accountLinkingConfig: { ...yapilyAccountLinkingConfig },
  activeInstitutionConfig: {
    ...yapilyActiveInstitutionConfig,
  },
  consentStatusConfig: [...yapilyConsentStatusConfig],
  consentJourneySourceConfig: [...yapilyConsentJourneySourceConfig],
  consentJourneyStepsConfig: [...yapilyConsentJourneyStepsConfig],
  consentJourneyHelperConfig: [...yapilyConsentJourneyHelperConfig],
  footerConfig: { ...yapilyFooterConfig },
  landingPageConfig: { ...yapilyLandingPageConfig },
};

export const OpenBankingConfig = {
  title: "Open banking",
  subTitle:
    "Import transaction data from non-Kloo bank accounts, to allow for visualisations and analytics of all business spend.",
};

export const REDIRECT_YAPILY_WINDOW_NAME = "kloo-routed-yapily-tab";

export const ERP_INTEGRATIONS_PAGE_CONFIG = {
  HEADER_CONFIG: {
    desc: {
      variant: "secondary",
      text: "Connect Kloo to your ERP/accounting platform to sync master data, and select data to pass to your ERP/accounting platform.",
    },
    header: {
      variant: "title",
      className: "m-b-16px",
      text: "ERP Integrations",
      fontSize: 24,
      responsiveFontSize: false,
    },
    buttonLabel: "",
  },
  TITLE_CARD_DETAILS: {
    xero: {
      key: "xero",
      logo: XeroLogo,
      title: "Connect Xero",
      cardClassName: "xero-card-design",
    },
    sageIntacct: {
      key: "sage-intacct",
      logo: SageIntacctLogo,
      keyToCheckLogo: "sage intacct",
      title: "Connect Sage Intacct",
      cardClassName: "sage-card-design",
    },
    sageBusinessCloud: {
      key: "sage-business-cloud-accounting",
      logo: SageBusinessCloudLogo,
      title: "Connect Sage Business Cloud",
      cardClassName: "sage-card-design",
    },
  },
  CONNECTION_STATUSES: {
    complete: "COMPLETE",
  },
  DISCONNECT_SUCCESS_MSG: "Diconnected successfully",
  DATA_DELETED_SUCCESS_MSG: "Data deleted successfully",
  SYNC_IN_PROGRESS_MSG: "Sync in progress",
  INTEGRATIONS_NOT_ENABLED: {
    notEnabled: {
      variant: "title",
      text: "Your organization doesn't have any integrations enabled.",
      fontSize: 22,
      responsiveFontSize: false,
    },
    contact: {
      variant: "title",
      prefixText: "For more information, please contact ",
      fontSize: 21,
      responsiveFontSize: false,
    },
  },
};

export const ERP_SYNCING_STATUSES = {
  success: {
    key: "success",
    icon: "check_box",
    iconClassName: "success-icon-color",
  },
  inProgress: {
    key: "in progress",
    icon: "cloud",
    iconClassName: "progress-icon-color",
  },
  error: {
    key: "failed",
    icon: "warning",
    iconClassName: "warning-icon-color",
  },
  noData: {
    key: "no_content",
  },
  unmapped: {
    key: "unmapped",
    icon: "close",
    iconClassName: "close-icon-color",
  },
};

export const CONNECTED_ERP_DETAILS = {
  ORG_NAME_CONFIG: {
    variant: "title",
    fontSize: 22,
    responsiveFontSize: true,
    fontStyle: "regular",
  },
  LAST_SYNC_CONFIG: {
    variant: "body",
    className: "m-b-8px",
    textColorType: "tertiary",
    text: "Last sync on",
    responsiveFontSize: true,
  },
  BUTTONS_CONFIG: {
    configureIntegration: {
      text: "Configure integration now",
      className: "w-100",
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      accent: ACCENTS.PRIMARY,
      customSize: "large",
      key: "configure",
    },
    syncData: {
      text: "Sync data",
      className: "w-100",
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      accent: ACCENTS.PRIMARY,
      customSize: "large",
      key: "sync",
    },
    integrationSettings: {
      text: "Integration settings",
      className: "w-100",
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      accent: ACCENTS.PRIMARY,
      customSize: "large",
      key: "settings",
    },
    disconnect: {
      text: "Disconnect",
      className: "w-100",
      variant: BUTTON_VARIANT.BUTTON_HOLLOW,
      accent: ACCENTS.SECONDARY,
      customSize: "large",
      key: "disconnect",
    },
  },
  LIST_HEADINGS_CONFIG: {
    masterData: {
      variant: "secondary",
      fontStyle: "semibold",
      text: "Master data",
    },
    dataSynced: {
      variant: "secondary",
      fontStyle: "semibold",
      text: "Data synced to ERP",
    },
  },
  TEMP_BE_SAGE_KEY: "sage intacct",
  MULTI_ENTITY_COMPANY_NAME: "Complete integration multi-entity",
  INVOICES_KEY: "INVOICES",
  TRACKING_CATEGORIES_KEY: "TRACKING CATEGORIES",
};

export const CONFIGURE_INTEGRATION_MODAL = {
  TITLE: "Complete integration",
  HEADINGS: {
    ENTITY_MAPPING: {
      text: "Entity mapping",
      tooltip: "Entity mapping",
    },
    ENTRIES_CREATED: {
      text: "Entries created in Xero",
      tooltip: "Entries created in Xero",
    },
  },
  ENTITY: {
    placeholder: "Select entity",
  },
  ERP_COMPANY: {
    placeholder: "Select company",
  },
  BUTTONS_CONFIG: {
    deleteButton: {
      iconName: "close",
      variant: BUTTON_VARIANT.BUTTON_ONLY_ICON,
      accent: ACCENTS.DESTRUCTIVE,
    },
  },
  ENTITY_MAPPING_STATUSES: {
    mapped: "mapped",
    unmapped: "unmapped",
  },
};

export const ERP_CONNECTION_COMPANY_NAME_CONFIG = {
  Xero: {
    keyToCheckLogo: "Xero",
    className: "xero",
  },
  "Sage Intacct": {
    keyToCheckLogo: "Sage Intacct",
    className: "sage-intacct",
  },
};

export const SINGLE_ENTITY_ERP_KEYS = ["xero", "sage business cloud"];

export const ERP_PLATFORM_KEYS = {
  XERO: "xero",
  SAGE_INTACCT: "sage-intacct",
};

export const DISCONNECT_CONFIRMATION_MODAL_CONSTANTS = {
  title: "Disconnect your ERP",
  subtitles: [
    "Are you sure you want to disconnect your ERP platform from Kloo? This will prevent syncs from occurring and your ERP platform will no longer receive any data from Kloo.",
    "To confirm, type DISCONNECT in the below field",
  ],
  confirmationText: "DISCONNECT",
  primaryBtnText: "Confirm",
  secondaryBtnText: "Cancel",
  inputPlaceholder: "Type DISCONNECT",
};

export const BC_ORG_ID = "c972762d-3b7a-11ed-b538-a4fc776c6f93";

export const ITG_ORG_ID = "3720d975-7bf3-11ef-9d5f-06a414f175cf";

export const ERP_LINE_ITEM_ORGS = [
  "011c05cb-27d4-11ef-ae92-0a062f6564f3",
  "1d25ab3e-1368-11ef-a760-0a062f6564f3",
  "0f9056c4-984a-4431-a3aa-db2cc147d597",
  "03e71671-137a-11ef-a760-0a062f6564f3",
  ITG_ORG_ID,
];
