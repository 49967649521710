import { Drawer as ADrawer } from "antd";
import { memo } from "react";
import useMobile from "../../../hooks/useMobile";
import { constructClassName } from "../../../utils/functions";
import Typography from "../Typography";
import "./Drawer.scss";

function Drawer({
  className,
  width,
  open,
  children,
  onClose,
  title,
  extra,
  mask,
  maskClosable,
  showTitle = true,
  getContainer,
  titleReactNode = null,
  ...rest
}) {
  const { isMobile } = useMobile();

  return (
    <ADrawer
      closable={false}
      destroyOnClose={true}
      className={constructClassName(["kloo-drawer", className])}
      title={
        titleReactNode ? (
          titleReactNode
        ) : showTitle ? (
          <Typography
            variant="title"
            text={title}
            fontSize={"24"}
            responsiveFontSize={false}
          />
        ) : (
          false
        )
      }
      open={open}
      onClose={onClose}
      mask={mask}
      maskClosable={maskClosable}
      extra={
        extra || (
          <span onClick={onClose} className="kloo-close-button material-icons">
            close
          </span>
        )
      }
      size={width ? null : isMobile ? "default" : "large"}
      width={width}
      getContainer={getContainer}
      {...rest}
    >
      {children}
    </ADrawer>
  );
}

export default memo(Drawer);
