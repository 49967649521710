import { Typography } from "../components/common";
import { getAuditDataToDisplay } from "../helper";
import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import {
  appendKeyForFilters,
  generateEncodedURIString,
} from "../utils/functions";

export async function getAuditHistoryByCardId({ id, page = 1, filtersUrl }) {
  const tempResponse = await apiClient({
    url: `${PATH.audit.getAuditHistoryByCardId(id)}?page=${page}${filtersUrl}`,
    method: "GET",
  });
  const response = {
    data: {
      list: tempResponse?.data?.result?.data?.map(
        ({ id, email, actioning_user, before, after, created_date }) => {
          return {
            id,
            email,
            user: actioning_user,
            before: before.map((item) => (
              <Typography
                text={`${item.action}${item.value ? `: ${item.value}` : ""}`}
                variant={"body"}
                ellipsis={{
                  tooltip: `${item.action}${
                    item.value ? `: ${item.value}` : ""
                  }`,
                }}
              />
            )),
            after: after.map((item) => (
              <Typography
                text={`${item.action}${item.value ? `: ${item.value}` : ""}`}
                variant={"body"}
                ellipsis={{
                  tooltip: `${item.action}${
                    item.value ? `: ${item.value}` : ""
                  }`,
                }}
              />
            )),
            date: created_date,
          };
        }
      ),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
    },
  };

  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}

export async function getAuditHistoryBySupplierId({
  supplier_auditable_id,
  page = 1,
  pageSize,
}) {
  const pageSizeURL = generateEncodedURIString(
    "perPageCount",
    pageSize.toFixed()
  );
  const supplierAuditableId = generateEncodedURIString(
    "supplier_auditable_id",
    supplier_auditable_id,
    false
  );

  const tempResponse = await apiClient({
    url: `${PATH.audit.auditHistoryBySupplierId}?page=${page}${pageSizeURL}${supplierAuditableId}`,
    method: "GET",
  });

  const response = {
    data: {
      list: tempResponse?.data?.result?.data?.map(
        ({
          id,
          email,
          actioning_user,
          content: { before, after },
          created_date,
          event,
        }) => {
          return {
            id,
            email,
            user: actioning_user,
            before: getAuditDataToDisplay({
              data: before,
              key: "before",
              event,
            }),
            after: getAuditDataToDisplay({ data: after, key: "after", event }),
            date: created_date,
          };
        }
      ),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
    },
  };

  return response.data;
}

export async function postComment(data) {
  return apiClient({
    url: PATH.accountPayable.postComment,
    method: "POST",
    data,
  });
}

export async function getAuditLogById({
  id,
  page = 1,
  filtersUrl,
  pageSize = 10,
  type = "PO",
}) {
  const pageSizeURL = generateEncodedURIString(
    "perPageCount",
    pageSize.toFixed()
  );

  const URL_MAP = {
    PO: PATH.purchaseOrder.auditLog,
    CN: PATH.credit_notes.auditLog,
  };

  const baseUrl = URL_MAP[type];

  const tempResponse = await apiClient({
    url: `${baseUrl}?auditable_id=${id}&page=${page}${pageSizeURL}${filtersUrl}`,
    method: "GET",
  });
  const response = {
    data: {
      list: tempResponse?.data?.result?.data
        ?.filter(
          (data) =>
            data?.content?.before?.length || data?.content?.after?.length
        )
        .map(({ id, email, actioning_user, content, created_at }) => {
          return {
            id,
            email,
            user: actioning_user,
            before: content?.before?.map((item) => (
              <Typography
                text={`${item.action}${item.value ? `: ${item.value}` : ""}`}
                variant={"body"}
                ellipsis={{
                  tooltip: `${item.action}${
                    item.value ? `: ${item.value}` : ""
                  }`,
                }}
              />
            )),
            after: content?.after?.map((item) => (
              <Typography
                text={`${item.action}${item.value ? `: ${item.value}` : ""}`}
                variant={"body"}
                ellipsis={{
                  tooltip: `${item.action}${
                    item.value ? `: ${item.value}` : ""
                  }`,
                }}
              />
            )),
            date: created_at,
          };
        }),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
    },
  };

  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}
