import { ActionLink, Typography } from "../../components/common";
import {
  SUPPLIER_PLACEHOLDER,
  SUPPLIER_AUDIT_TRAIL_MAPPING,
} from "../../constants";
import {
  checkIfUserHasPermission,
  capitalizeFirstLetter,
} from "../../utils/functions/common";

export function checkIfCurrentUserCanPerformActions({ permissionConfig = [] }) {
  return (
    checkIfUserHasPermission({
      permissionConfig,
      permissionsId: "All",
    }) ||
    checkIfUserHasPermission({
      permissionConfig,
      permissionsId: "Create",
    })
  );
}

export const getSupplierDetails = ({
  allSupplierDetails,
  selectedSupplier,
  selectedSupplierSelectedAccountDetails,
}) => {
  let currentSupplier = "";
  let currentSupplierCurrentAccountDetails = "";
  let currentSupplierAllAccountDetails = [];

  if (allSupplierDetails && allSupplierDetails.length) {
    if (selectedSupplier) {
      const supplierFound = allSupplierDetails.find(
        ({ id }) => id === selectedSupplier
      );
      if (supplierFound) {
        // Supplier
        currentSupplier = supplierFound;
        currentSupplierAllAccountDetails =
          supplierFound?.supplier_accounts || [];
        if (selectedSupplierSelectedAccountDetails) {
          const supplierAccountDetailsFound =
            supplierFound?.supplier_accounts?.find(
              ({ id }) => id === selectedSupplierSelectedAccountDetails
            );
          if (supplierAccountDetailsFound) {
            // Supplier Account Details
            currentSupplierCurrentAccountDetails = supplierAccountDetailsFound;
          }
        }
      }
    }
  }

  return {
    currentSupplier,
    currentSupplierCurrentAccountDetails,
    currentSupplierAllAccountDetails,
  };
};

export function getFullContact(dialCode, phoneNumber) {
  return `${dialCode ?? ""} ${phoneNumber ?? ""}`.trim();
}

export function getAuditDataToDisplay({ data, key: dataKey, event }) {
  const deletedArr = [];
  const currentAccountName =
    data?.find(({ same_account_name }) => same_account_name)
      ?.same_account_name || "";

  if (
    (event === "supplier_address_updated" ||
      event === "supplier_address_created") &&
    data?.length === 5
  ) {
    const fieldsUpdated = data?.reduce(
      (acc, fieldValue) => {
        const currValue = Object.values(fieldValue)[0];
        currValue === null
          ? acc.deletedFieldCounter++
          : acc.modifiedFieldCounter++;
        if (!(currValue === null && dataKey === "before"))
          acc.allAddressFieldUpdated.push(currValue);
        return acc;
      },
      {
        deletedFieldCounter: 0,
        modifiedFieldCounter: 0,
        allAddressFieldUpdated: [],
      }
    );

    const allAddressFieldUpdated =
      fieldsUpdated.modifiedFieldCounter === 5 ||
      fieldsUpdated.deletedFieldCounter === 5
        ? fieldsUpdated.allAddressFieldUpdated
        : [];

    if (allAddressFieldUpdated.length) {
      return (
        <div>
          {SUPPLIER_PLACEHOLDER} address
          {allAddressFieldUpdated[0] !== null
            ? `: ${allAddressFieldUpdated.join(", ")}`
            : " deleted"}
        </div>
      );
    }
  }
  return (
    <>
      {data?.map((item) => {
        return Object.entries(item).map(([key, value]) => {
          const actionData = SUPPLIER_AUDIT_TRAIL_MAPPING.find(
            ({ key: mappingKey }) => mappingKey === key
          );
          if (!actionData) return null;
          let dataToBeDisplayed = "";
          if (value !== null) {
            switch (event) {
              case "supplier_created":
                dataToBeDisplayed = `${SUPPLIER_PLACEHOLDER} ${value} has been created`;
                break;
              case "supplier_account_created":
                dataToBeDisplayed = `${SUPPLIER_PLACEHOLDER} account: ${value} has been added`;
                break;
              default:
                switch (actionData?.type) {
                  case "account":
                    const accountNameTitle =
                      currentAccountName !== ""
                        ? `${currentAccountName} ${actionData.action}`
                        : actionData.action;
                    dataToBeDisplayed = `${capitalizeFirstLetter(
                      accountNameTitle
                    )}: ${value}`;
                    break;
                  default:
                    dataToBeDisplayed = `${SUPPLIER_PLACEHOLDER} ${actionData.action}: ${value}`;
                    break;
                }
                break;
            }
          } else {
            if (dataKey === "after") {
              if (actionData?.type === "priority" && data?.length === 1) {
                dataToBeDisplayed = `${SUPPLIER_PLACEHOLDER} ${actionData.action}: No priority assigned`;
              } else if (actionData?.type === "address") {
                dataToBeDisplayed = `${SUPPLIER_PLACEHOLDER} ${actionData.action} deleted`;
              } else {
                deletedArr.push(actionData.action);
              }
            }
          }
          return <div>{dataToBeDisplayed}</div>;
        });
      })}
      {deletedArr && deletedArr.length ? (
        <div>
          {SUPPLIER_PLACEHOLDER} {deletedArr.join(", ")}{" "}
          {deletedArr.length > 1 ? "has been deleted" : "deleted"}
        </div>
      ) : null}
    </>
  );
}

const extractEmail = (text) => {
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/;
  const match = text.match(emailRegex);
  return match ? match[0] : null;
};

const HighlightEmail = ({ text }) => {
  const email = extractEmail(text);

  if (!email) {
    return <Typography text={text}></Typography>;
  }

  // Split the text into parts: before the email, the email itself, and after the email
  const parts = text.split(email);

  return (
    <span>
      <Typography
        variant={"secondary"}
        className="d-inline"
        fontSize={14}
        text={parts[0]}
      />
      <ActionLink
        href={`mailto:${email}`}
        style={{ color: "blue", fontWeight: "bold" }}
        text={email}
        className="d-inline"
      ></ActionLink>

      <Typography
        variant={"secondary"}
        fontSize={14}
        text={parts[1]}
        className="d-inline"
      />
    </span>
  );
};

export default HighlightEmail;
