import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  CommonInput,
  CommonInputNumber,
  CommonSelect,
  CustomButton,
  Divider,
  Icon,
  Table,
  Tooltip,
  Typography,
  When,
} from "../..";
import {
  ACTION_COLUMN,
  ERP_COLUMNS,
  LINE_ITEMS_FORM,
  LINE_ITEMS_TOGGLE_TYPE,
  LINE_ITEM_TABLE_COLUMNS,
  LINE_ITEM_TABLE_COLUMNS_KEY,
  NON_ERP_COLUMNS,
  TOOLTIP_TEXT,
} from "../../../../constants/LineItems";
import { SmartToyIcon } from "../../../../assets/icons";
import {
  ACCENTS,
  BUTTON_VARIANT,
  INPUT_TYPES,
  INVOICE_MANAGEMENT_TAB_KEYS,
  MODULE_TYPES,
  PURCHASE_ORDER_POPULATED_USING_AI,
  TAX_RATE_STATUS,
  TRACKING_CATEGORY_KEY_PREFIX,
} from "../../../../constants";
import { Form } from "antd";
import "./LineItemsTable.scss";
import { EditableFormItem } from "../EditableFormItem";
import { getTaxRateList, getTaxRatesByEntity } from "../../../../api";
import {
  addTaxRateConfigToEachLineItem,
  getLineItemAmountFields,
  getNetAmount,
} from "../../../../helper/LineItems";
import { useERPFields } from "../../../../hooks";
import {
  BC_ORG_ID,
  ERP_INTEGRATIONS_PAGE_CONFIG,
  ERP_LINE_ITEM_ORGS,
  ERP_PLATFORM_KEYS,
} from "../../../../constants/Integration/OpenBanking";
import RenderCustomField from "../../CustomField/RenderCustomField";
import {
  IM_CREATE_INVOICE_FORM_RULE,
  LINE_ITEMS_FORM_RULES,
  LOCAL_STORAGE_KEY,
  getItem,
  isPlatformEnvironment,
  roundOff,
} from "../../../../utils";
import { toCapitalCase } from "../../../../helper";

const LineItemsTable = ({
  entityId,
  lineItemsTableData,
  setLineItemsTableData,
  deleteLineItem,
  disabled,
  setLineItemsValuesChanged,
  currency,
  editingIndex,
  setEditingIndex,
  form,
  addLineItemForm,
  loadingLineItems,
  customFields = [],
  setFieldOptions,
  tab,
  disableActions,
  module,
  erpFieldsOptional,
  taxSolution,
  setTrackingCategories,
  setAccounts,
  setIsERPMapped,
}) => {
  const addFormQty = Form.useWatch(
    LINE_ITEM_TABLE_COLUMNS.quantity.key,
    addLineItemForm
  );
  const addFormUnitPrice = Form.useWatch(
    LINE_ITEM_TABLE_COLUMNS.unit_cost.key,
    addLineItemForm
  );
  const addFormTaxRate = Form.useWatch(
    LINE_ITEM_TABLE_COLUMNS.tax_rate.key,
    addLineItemForm
  );
  const addFormItemList = Form.useWatch(
    LINE_ITEM_TABLE_COLUMNS.item_list.key,
    addLineItemForm
  );
  const {
    ChartOfAccounts,
    TaxRate,
    ItemList,
    TrackingCategories,
    chartsOfAccountList,
    itemList,
    trackingCategoriesList,
    connectedErpPlatformName,
    taxRateList,
    isFetchingData,
    isERPMapped,
  } = useERPFields({
    entityId,
    isOnlyComponent: true,
    taxSolution,
  });

  useEffect(() => {
    if (Object.keys(trackingCategoriesList)?.length) {
      setTrackingCategories(trackingCategoriesList);
    }
    if (chartsOfAccountList) {
      setAccounts(chartsOfAccountList);
    }
    setIsERPMapped(isERPMapped);
  }, [trackingCategoriesList, chartsOfAccountList]);

  const columnClassForERP = `${connectedErpPlatformName
    .split(" ")
    .join("-")}-column`;
  const isSageIntacctConnection =
    connectedErpPlatformName ===
    ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.sageIntacct.keyToCheckLogo;
  const [taxRates, setTaxRates] = useState([]);
  const [sageTaxRates, setSageTaxRate] = useState([]);
  /**
   * A function to toggle the edit mode of a record in the line items table.
   *
   * @param {Object} record - The record to toggle edit mode for.
   * @param {number} index - The index of the record in the line items table.
   * @return {void}
   */
  const toggleEdit = async (index, type) => {
    if (type === LINE_ITEMS_TOGGLE_TYPE.EDIT) {
      setEditingIndex(index);
    } else {
      if (tab && tab === INVOICE_MANAGEMENT_TAB_KEYS.DRAFT) {
        const values = form.getFieldsValue();
        saveData(values, type, index);
      } else {
        form
          .validateFields()
          .then((values) => {
            saveData(values, type, index);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const saveData = (values, type, index) => {
    if (type === LINE_ITEMS_TOGGLE_TYPE.SAVE) {
      setLineItemsTableData(
        lineItemsTableData.map((item, i) => {
          if (index === i) {
            const newData = getUpdatedListItem(
              customFields,
              index,
              values["line_items"][index]
            );
            return {
              ...item,
              net_amount: getNetAmount(values["line_items"][index], true),
              ...form.getFieldsValue()["line_items"]?.[index],
              isEditMode: !item.isEditMode,
              ...newData,
            };
          } else {
            return item;
          }
        })
      );
      setEditingIndex(-1);
    }
  };

  const getERPColumns = () => {
    const columns = [...ERP_COLUMNS];
    columns.splice(2, 0, {
      ...LINE_ITEM_TABLE_COLUMNS.account,
      widthInPX: 150,
      customClass: "dyanmic-erp-column",
    });
    let startIndexForCategories = 2;
    for (const key in trackingCategoriesList) {
      if (Object.hasOwnProperty.call(trackingCategoriesList, key)) {
        columns.splice(++startIndexForCategories, 0, {
          label: key,
          key: LINE_ITEM_TABLE_COLUMNS_KEY.TRACKING_CATEGORIES,
          subKey: key,
          placeholder: key,
          widthInPX: 150,
          customClass: "dyanmic-erp-column",
        });
      }
    }
    columns.splice(columns.length - 2, 0, {
      ...LINE_ITEM_TABLE_COLUMNS.tax_rate,
      widthInPX: 150,
      customClass: "dyanmic-erp-column",
    });

    // if (
    //   isFeat12219Enabled() &&
    //   ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.sageIntacct
    //     .keyToCheckLogo === connectedErpPlatformName
    // ) {
    //   columns.splice(columns.length - 2, 0, {
    //     ...LINE_ITEM_TABLE_COLUMNS.trx_amount,
    //     widthInPX: 150,
    //     customClass: "dyanmic-erp-column",
    //   });
    // }
    if (
      connectedErpPlatformName !==
        ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.xero.key &&
      (ERP_LINE_ITEM_ORGS.includes(getItem(LOCAL_STORAGE_KEY.ORD_ID)) ||
        !isPlatformEnvironment())
    ) {
      columns.splice(columns.length - 2, 0, {
        ...LINE_ITEM_TABLE_COLUMNS.item_list,
        widthInPX: 150,
        customClass: "dyanmic-erp-column",
      });
    }
    return columns;
  };

  const getCustomFieldsColumns = () => {
    return (
      customFields?.map((field) => ({
        key: field.name,
        ...field,
      })) || []
    );
  };

  const finalColumns = connectedErpPlatformName
    ? getERPColumns()
    : NON_ERP_COLUMNS;

  const columnsToRender = disabled
    ? finalColumns.slice(0, finalColumns.length - 1)
    : finalColumns;

  const columnsWithCustomFields = [
    ...columnsToRender,
    ...getCustomFieldsColumns(),
  ];
  const isActionAllowed = !disabled && !disableActions;
  if (isActionAllowed) {
    columnsWithCustomFields.push(ACTION_COLUMN);
  }

  useEffect(() => {
    if (lineItemsTableData?.length) {
      form.setFieldValue("line_items", lineItemsTableData);
    }
  }, [lineItemsTableData, form]);

  useEffect(() => {
    if (taxSolution && !sageTaxRates.length) {
      getTaxRateList(entityId, null, null, taxSolution).then((res) => {
        setSageTaxRate(
          res?.map((data) => {
            return {
              ...data,
              label: data?.description,
              value: data?.TaxType,
              disabled: data?.Status?.toLowerCase() !== TAX_RATE_STATUS.active,
            };
          })
        );
      });
    }
  }, []);

  const getUpdatedListItem = (customKeys, selectedIndex, values) => {
    let innerCustomFields = [];
    if (!customFields?.length) {
      return {};
    }

    customKeys.forEach((fields) => {
      const keyName = fields.custom_field_id;
      if (selectedIndex && lineItemsTableData?.[selectedIndex]) {
        innerCustomFields =
          lineItemsTableData[selectedIndex]?.innerCustomFields?.filter(
            (field) => field.custom_field_id !== keyName
          ) || [];
      }
      if (!values[keyName]) {
        return;
      }

      let itemCustomField = {
        custom_field_id: fields.custom_field_id,
        value: values[keyName] || null,
        id: fields.id,
        user_input: fields.user_input,
        label: fields.label,
      };
      if (fields.inputType === INPUT_TYPES.SELECT && values[keyName]) {
        const valueAsText = fields.options.find(
          (option) => option.value === values[keyName]
        ).label;
        itemCustomField.valueAsText = valueAsText;
      }
      innerCustomFields.push(itemCustomField);
      if (selectedIndex && lineItemsTableData?.[selectedIndex]) {
        lineItemsTableData[selectedIndex].innerCustomFields = innerCustomFields;
      }
    });

    return {
      innerCustomFields,
      storedCustomKeys: selectedIndex
        ? lineItemsTableData?.[selectedIndex]?.storedCustomKeys || []
        : [],
    };
  };

  const addLineItem = () => {
    if (tab && tab === INVOICE_MANAGEMENT_TAB_KEYS.DRAFT) {
      const values = addLineItemForm.getFieldsValue();
      saveFooterData(values);
    } else {
      addLineItemForm.validateFields().then((values) => {
        saveFooterData(values);
      });
    }
  };

  const saveFooterData = (values) => {
    const newData = getUpdatedListItem(customFields, null, values);
    if (lineItemsTableData?.length) {
      setLineItemsTableData([
        ...lineItemsTableData,
        {
          ...values,
          accountTaxRates: taxRateList,
          isEditMode: false,
          is_ai_generated: 0,
          ...newData,
        },
      ]);
    } else {
      setLineItemsTableData([
        {
          ...values,
          accountTaxRates: taxRateList,
          isEditMode: false,
          is_ai_generated: 0,
          ...newData,
        },
      ]);
    }
    addLineItemForm.resetFields();
    setLineItemsValuesChanged(true);
    setEditingIndex(-1);
  };

  const removeLineItem = (record, index) => {
    if (record.id) {
      deleteLineItem(record.id);
    }
    if (editingIndex === index) {
      setEditingIndex(-1);
    }
    setLineItemsTableData(lineItemsTableData.filter((item, i) => i !== index));
  };

  const renderHeaders = ({ label, key, subKey, customClass }) => {
    const title = erpFieldsOptional
      ? TOOLTIP_TEXT.NON_MANDATORY_FIELD(connectedErpPlatformName)
      : TOOLTIP_TEXT.MANDATORY_FIELD(connectedErpPlatformName);
    switch (key) {
      case LINE_ITEM_TABLE_COLUMNS_KEY.ACCOUNT:
        return (
          <Tooltip title={title} placement="top" className="cursor-pointer">
            <span className={columnClassForERP}>
              {LINE_ITEM_TABLE_COLUMNS.account.label}
            </span>
          </Tooltip>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.TAX_RATE:
        return (
          <Tooltip title={title} placement="top" className="cursor-pointer">
            <span className={columnClassForERP}>
              {LINE_ITEM_TABLE_COLUMNS.tax_rate.label}
            </span>
          </Tooltip>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.TRX_AMOUNT:
        return (
          <Tooltip title={""} placement="top" className="cursor-pointer">
            <span className={columnClassForERP}>
              {LINE_ITEM_TABLE_COLUMNS.trx_amount.label}
            </span>
          </Tooltip>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.ITEM_LIST:
        return (
          <Tooltip title={title} placement="top" className="cursor-pointer">
            <span className={columnClassForERP}>
              {LINE_ITEM_TABLE_COLUMNS.item_list.label}
            </span>
          </Tooltip>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.TRACKING_CATEGORIES:
        return (
          <Tooltip title={title} placement="top" className="cursor-pointer">
            <span className={columnClassForERP}>{toCapitalCase(subKey)}</span>
          </Tooltip>
        );
      default:
        return (
          <Typography
            fontSize={12}
            fontStyle={"semibold"}
            variant="body"
            text={label}
          />
        );
    }
  };

  async function fetchTaxRate(selectedAccount) {
    try {
      const isSageIntacct =
        connectedErpPlatformName ===
        ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.sageIntacct
          .keyToCheckLogo;
      const response = await getTaxRateList(
        entityId,
        selectedAccount?.classification,
        isSageIntacct ? selectedAccount?.account_number : null,
        isSageIntacct ? taxSolution : null
      );
      return response?.map((data) => {
        return {
          ...data,
          label: data?.description,
          value: data?.TaxType,
          disabled: data?.Status?.toLowerCase() !== TAX_RATE_STATUS.active,
        };
      });
    } catch (error) {
      return [];
    }
  }

  const handleAccountChange = async (data, index) => {
    if (taxSolution) {
      return;
    }
    form.setFieldValue(
      ["line_items", index, LINE_ITEM_TABLE_COLUMNS_KEY.TAX_RATE],
      null
    );
    let selectedAccount = null;
    if (data) {
      selectedAccount = chartsOfAccountList.find(
        (account) => account?.erp_id === data
      );
    }
    if (selectedAccount) {
      const accountTaxRates = await fetchTaxRate(selectedAccount);
      setLineItemsTableData(
        lineItemsTableData.map((item, i) => {
          if (index === i) {
            return {
              ...form.getFieldsValue()["line_items"]?.[index],
              chartOfAccounts: data,
              accountTaxRates: accountTaxRates,
            };
          } else {
            return item;
          }
        })
      );
    } else {
      setLineItemsTableData(
        lineItemsTableData.map((item, i) => {
          if (i === index) {
            return {
              ...form.getFieldsValue()["line_items"]?.[index],
              chartOfAccounts: null,
              taxRate: null,
              accountTaxRates: [],
            };
          } else {
            return item;
          }
        })
      );
    }
  };

  const renderBody = ({
    label,
    key,
    subKey,
    record,
    index,
    defaultRender,
    customClass,
  }) => {
    const trackingCategoriesColumns = [];
    for (const key in trackingCategoriesList) {
      if (Object.hasOwnProperty.call(trackingCategoriesList, key)) {
        trackingCategoriesColumns.push(key);
      }
    }
    switch (key) {
      case LINE_ITEM_TABLE_COLUMNS_KEY.IS_AI_GENERATED:
        return (
          <Form form={form} onValuesChange={handleValuesChange}>
            {(values) => (
              <When
                condition={values["line_items"]?.[index]?.is_ai_generated !== 0}
              >
                <SmartToyIcon fillColor="#66DDD6" />
              </When>
            )}
          </Form>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.DESCRIPTION:
        return (
          <>
            <EditableFormItem
              editing={index === editingIndex}
              displayValue={record.description}
            >
              <CommonInput
                {...LINE_ITEMS_FORM.DESCRIPTION}
                name={[index, LINE_ITEM_TABLE_COLUMNS_KEY.DESCRIPTION]}
                onChange={() => {
                  inputChangedFromAiValue(
                    LINE_ITEM_TABLE_COLUMNS_KEY.DESCRIPTION,
                    index
                  );
                }}
              />
            </EditableFormItem>
          </>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.QUANTITY:
        return (
          <EditableFormItem
            editing={index === editingIndex}
            displayValue={record.quantity}
          >
            <CommonInputNumber
              {...LINE_ITEMS_FORM.QUANTITY}
              name={[index, LINE_ITEM_TABLE_COLUMNS_KEY.QUANTITY]}
              onChange={() => {
                inputChangedFromAiValue(
                  LINE_ITEM_TABLE_COLUMNS_KEY.QUANTITY,
                  index,
                  record
                );
              }}
            />
          </EditableFormItem>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.UNIT_COST:
        return (
          <EditableFormItem
            editing={index === editingIndex}
            displayValue={getLineItemAmountFields(
              record.unit_cost || 0,
              currency
            )}
          >
            <CommonInputNumber
              {...LINE_ITEMS_FORM.UNIT_COST}
              name={[index, LINE_ITEM_TABLE_COLUMNS_KEY.UNIT_COST]}
              onChange={() => {
                inputChangedFromAiValue(
                  LINE_ITEM_TABLE_COLUMNS_KEY.UNIT_COST,
                  index,
                  record
                );
              }}
            />
          </EditableFormItem>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.ACCOUNT:
        return (
          <Form form={form} onValuesChange={handleValuesChange}>
            <Form.List name="line_items">
              {(fields, { add, remove }) => {
                return (
                  <EditableFormItem
                    editing={index === editingIndex}
                    displayValue={
                      chartsOfAccountList.find(
                        (account) =>
                          account.erp_id ===
                          record?.[LINE_ITEM_TABLE_COLUMNS_KEY.ACCOUNT]
                      )?.label
                    }
                  >
                    <ChartOfAccounts
                      preventTaxListLoad={true}
                      inputChangedFromAiValue={(data, e) => {
                        inputChangedFromAiValue(data, index, record);
                        handleAccountChange(e, index);
                      }}
                      formItemName={[
                        index,
                        LINE_ITEM_TABLE_COLUMNS_KEY.ACCOUNT,
                      ]}
                      rules={
                        erpFieldsOptional
                          ? []
                          : IM_CREATE_INVOICE_FORM_RULE.CHART_OF_ACCOUNTS
                      }
                    />
                  </EditableFormItem>
                );
              }}
            </Form.List>
          </Form>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.TRACKING_CATEGORIES:
        return (
          <Form form={form} onValuesChange={handleValuesChange}>
            <Form.List name="line_items">
              {(fields, { add, remove }) => {
                return (
                  <EditableFormItem
                    editing={index === editingIndex}
                    displayValue={
                      trackingCategoriesList[subKey]?.find(
                        (category) =>
                          category.value ===
                          record[TRACKING_CATEGORY_KEY_PREFIX + subKey]
                      )?.label
                    }
                  >
                    <Form.Item>
                      <TrackingCategories
                        renderSpecificCategory={subKey}
                        inputChangedFromAiValue={(data) =>
                          inputChangedFromAiValue(data, index, record)
                        }
                        formItemName={[
                          index,
                          TRACKING_CATEGORY_KEY_PREFIX + subKey,
                        ]}
                        rules={
                          erpFieldsOptional
                            ? []
                            : IM_CREATE_INVOICE_FORM_RULE.TRACKING_CATEGORIES
                        }
                      />
                    </Form.Item>
                  </EditableFormItem>
                );
              }}
            </Form.List>
          </Form>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.TAX_RATE:
        const taxOptions = taxSolution ? sageTaxRates : record?.accountTaxRates;
        return (
          <EditableFormItem
            editing={index === editingIndex}
            displayValue={
              taxOptions?.find((tax) => tax.TaxType === record.taxRate)
                ?.description
            }
          >
            <CommonSelect
              {...LINE_ITEMS_FORM.TAX_RATE}
              name={[index, LINE_ITEM_TABLE_COLUMNS_KEY.TAX_RATE]}
              options={taxOptions}
              getPopupContainer={() => document.body}
              disabled={!taxOptions?.length}
              onChange={() => {
                inputChangedFromAiValue(
                  LINE_ITEM_TABLE_COLUMNS_KEY.TAX_RATE,
                  index,
                  record
                );
              }}
              rules={
                erpFieldsOptional || !taxOptions?.length
                  ? []
                  : IM_CREATE_INVOICE_FORM_RULE.TAX_RATE
              }
            />
          </EditableFormItem>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.TRX_AMOUNT:
        return (
          <EditableFormItem
            editing={index === editingIndex}
            displayValue={record.trx_amount}
          >
            <CommonInputNumber
              {...LINE_ITEMS_FORM.TRX_AMOUNT}
              name={[index, LINE_ITEM_TABLE_COLUMNS_KEY.TRX_AMOUNT]}
              onChange={() => {
                inputChangedFromAiValue(
                  LINE_ITEM_TABLE_COLUMNS_KEY.TRX_AMOUNT,
                  index,
                  record
                );
              }}
            />
          </EditableFormItem>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.ITEM_LIST:
        return (
          <EditableFormItem
            editing={index === editingIndex}
            displayValue={
              itemList.find(
                (item) =>
                  item.erp_id ===
                  record?.[LINE_ITEM_TABLE_COLUMNS_KEY.ITEM_LIST]
              )?.label
            }
          >
            <CommonSelect
              {...LINE_ITEMS_FORM.ITEM_LIST}
              name={[index, LINE_ITEM_TABLE_COLUMNS_KEY.ITEM_LIST]}
              options={itemList}
              getPopupContainer={() => document.body}
              disabled={!itemList?.length}
              onChange={() => {
                inputChangedFromAiValue(
                  LINE_ITEM_TABLE_COLUMNS_KEY.ITEM_LIST,
                  index,
                  record
                );
              }}
              rules={
                erpFieldsOptional ? [] : IM_CREATE_INVOICE_FORM_RULE.ITEM_LIST
              }
            />
          </EditableFormItem>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.NET_AMOUNT:
        return (
          <EditableFormItem
            editing={false}
            displayValue={getLineItemAmountFields(
              getNetAmount(record, true) || 0,
              currency
            )}
          />
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.EDIT:
        return (
          <Box columnGap={8}>
            <When condition={index !== editingIndex}>
              <CustomButton
                icon={<Icon iconName={"edit"} fontSize={18} />}
                accent={ACCENTS.BLACK}
                variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
                onClick={() => {
                  toggleEdit(index, LINE_ITEMS_TOGGLE_TYPE.EDIT);
                }}
                className="edit-icon-in-table"
                disabled={editingIndex !== -1 && index !== editingIndex}
              />
            </When>
            <When condition={index === editingIndex}>
              <Icon
                iconName={"done"}
                onClick={() => {
                  toggleEdit(index, LINE_ITEMS_TOGGLE_TYPE.SAVE);
                }}
              />
            </When>
            <CustomButton
              icon={<Icon iconName={"close"} />}
              accent={ACCENTS.DESTRUCTIVE}
              variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
              onClick={() => removeLineItem(record, index)}
              className="edit-icon-in-table"
            />
          </Box>
        );
      default:
        const customField = customFields.find(
          (field) => field.custom_field_id === key
        );

        if (customField) {
          const displayValue = record.innerCustomFields.find(
            (field) => field.custom_field_id === key
          );
          return (
            <EditableFormItem
              editing={index === editingIndex}
              displayValue={displayValue?.valueAsText || displayValue?.value}
            >
              <RenderCustomField
                field={customField}
                setFields={setFieldOptions}
                showLabel={false}
                name={[index, customField.name]}
                wrapperClass={"custom-field-width"}
                getPopupContainer={() => document.body}
              />
            </EditableFormItem>
          );
        }
        return defaultRender({ label, key, record, index, defaultRender });
    }
  };

  const inputChangedFromAiValue = (fieldName, index) => {
    form.setFieldValue(["line_items", index, "is_ai_generated"], 0);
  };

  const renderFooter = ({
    label,
    key,
    subKey,
    customClass,
    index,
    ...rest
  }) => {
    switch (key) {
      case LINE_ITEM_TABLE_COLUMNS_KEY.DESCRIPTION:
        return (
          <Form form={addLineItemForm} className="custom-field-width">
            <CommonInput
              {...LINE_ITEMS_FORM.DESCRIPTION}
              name={LINE_ITEM_TABLE_COLUMNS.description.key}
            />
          </Form>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.QUANTITY:
        return (
          <Form form={addLineItemForm} className="custom-field-width">
            <CommonInputNumber
              {...LINE_ITEMS_FORM.QUANTITY}
              name={LINE_ITEM_TABLE_COLUMNS.quantity.key}
            />
          </Form>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.UNIT_COST:
        return (
          <Form form={addLineItemForm} className="custom-field-width">
            <CommonInputNumber
              {...LINE_ITEMS_FORM.UNIT_COST}
              name={LINE_ITEM_TABLE_COLUMNS.unit_cost.key}
            />
          </Form>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.ACCOUNT:
        return (
          <Form form={addLineItemForm} className="custom-field-width">
            <Form.Item>
              <ChartOfAccounts
                inputChangedFromAiValue={(data) => {}}
                formItemName={LINE_ITEM_TABLE_COLUMNS.account.key}
                rules={
                  erpFieldsOptional
                    ? []
                    : IM_CREATE_INVOICE_FORM_RULE.CHART_OF_ACCOUNTS
                }
              />
            </Form.Item>
          </Form>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.TRACKING_CATEGORIES:
        return (
          <Form form={addLineItemForm} className="custom-field-width">
            <Form.Item name={TRACKING_CATEGORY_KEY_PREFIX + subKey}>
              <TrackingCategories
                renderSpecificCategory={subKey}
                inputChangedFromAiValue={(data) => {}}
                rules={
                  erpFieldsOptional
                    ? []
                    : IM_CREATE_INVOICE_FORM_RULE.TRACKING_CATEGORIES
                }
              />
            </Form.Item>
          </Form>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.TAX_RATE:
        return (
          <Form form={addLineItemForm} className="custom-field-width">
            <Form.Item>
              <TaxRate
                inputChangedFromAiValue={(data) => {}}
                formItemName={LINE_ITEM_TABLE_COLUMNS.tax_rate.key}
                rules={
                  erpFieldsOptional ? [] : IM_CREATE_INVOICE_FORM_RULE.TAX_RATE
                }
                options={taxSolution ? sageTaxRates : null}
              />
            </Form.Item>
          </Form>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.TRX_AMOUNT:
        return (
          <Form form={addLineItemForm} className="custom-field-width">
            <Form.Item>
              <CommonInputNumber
                {...LINE_ITEMS_FORM.TRX_AMOUNT}
                name={LINE_ITEM_TABLE_COLUMNS.trx_amount.key}
              />
            </Form.Item>
          </Form>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.ITEM_LIST:
        return (
          <Form form={addLineItemForm} className="custom-field-width">
            <Form.Item>
              <ItemList
                inputChangedFromAiValue={(data) => {}}
                formItemName={LINE_ITEM_TABLE_COLUMNS.item_list.key}
                rules={
                  erpFieldsOptional ? [] : IM_CREATE_INVOICE_FORM_RULE.ITEM_LIST
                }
              />
            </Form.Item>
          </Form>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.NET_AMOUNT:
        return (
          <div>
            {getLineItemAmountFields(
              getNetAmount(
                {
                  [LINE_ITEM_TABLE_COLUMNS_KEY.UNIT_COST]: addFormUnitPrice,
                  [LINE_ITEM_TABLE_COLUMNS_KEY.QUANTITY]: addFormQty,
                  [LINE_ITEM_TABLE_COLUMNS_KEY.TAX_RATE]: addFormTaxRate,
                  accountTaxRates: taxRateList,
                },
                true
              ) || 0,
              currency
            )}
          </div>
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.EDIT:
        return (
          <CustomButton
            text="Add"
            onClick={addLineItem}
            className="m-auto"
            disabled={editingIndex !== -1}
          />
        );
      default:
        if (rest?.inputLabel) {
          return (
            <Form form={addLineItemForm} className="custom-field-width">
              <RenderCustomField
                field={rest}
                setFields={setFieldOptions}
                showLabel={false}
                getPopupContainer={() => document.body}
              />
            </Form>
          );
        }
        return null;
    }
  };
  const handleValuesChange = (changedValues, values) => {
    if (Object.keys(changedValues).length) {
      setLineItemsValuesChanged(true);
    }
  };

  return (
    <>
      <div
        className={`line-items-table-body px-3 ${
          loadingLineItems ? "hidden" : ""
        }`}
      >
        <Divider className={"m-0px"} />
        <Form form={form} onValuesChange={handleValuesChange}>
          <Form.List name="line_items">
            {(fields, { add, remove }) => {
              return (
                <Table
                  tableClassName="m-b-0px kloo-scrollbar"
                  columns={columnsWithCustomFields}
                  data={lineItemsTableData}
                  showExpandIcon={false}
                  renderBody={renderBody}
                  renderHeaders={renderHeaders}
                  showFooter={disabled ? false : true}
                  footerColumns={columnsWithCustomFields}
                  renderFooter={renderFooter}
                  footerData={[{}]}
                  showScrollBar={true}
                />
              );
            }}
          </Form.List>
        </Form>
      </div>
    </>
  );
};

export default LineItemsTable;
