import { notification } from "antd";
import Icon from "../components/common/Icon";
import Typography from "../components/common/Typography";
export const NOTIFICATION_TYPE = {
  success: "success",
  info: "info",
  warning: "warning",
  error: "error",
};

const NOTIFICATION_ICON = {
  success: "check_circle",
  info: "info",
  warning: "warning",
  error: "cancel",
};

const serverErrorMsg = "There is some internal error";

const klooServerError =
  "An error occurred. Please try again or contact support@getkloo.com for assistance";

const key = "updatable";
export const showNotification = (type, message, description, duration = 5) => {
  notification[type]({
    key,
    message: message ? (
      <Typography
        variant={description ? "title" : "body"}
        text={message === serverErrorMsg ? klooServerError : message}
        responsiveFontSize={false}
        fontSize={description ? 18 : 16}
      />
    ) : null,
    description: description ? (
      <Typography variant={"body"} text={description} fontSize={16} />
    ) : null,
    duration: duration,
    style: {
      top: "0px",
    },
    icon: <Icon iconName={NOTIFICATION_ICON[type]} className={type} />,
    className: "kloo-notification",
  });
};
