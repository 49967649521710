import socketIOClient from "socket.io-client";
import { Subject } from "rxjs";
let socketAdded = false;
const socketResponse = new Subject();
const socketConnect = () => {
  if (socketAdded) {
    return;
  }
  socketAdded = true;
  const ENDPOINT = "/";
  const socket = socketIOClient(ENDPOINT, { path: "/api/node" });
  socket.on("notification", (data) => {
    socketResponse.next(data);
  });
};
export default socketConnect;
export { socketConnect, socketResponse };
