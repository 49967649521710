import React, { useState } from "react";
import CommonToolTip from "../Tooltip";
import {
  Box,
  ComponentLoader,
  CustomButton,
  Image,
  Typography,
  When,
} from "..";
import CompletedCheckIcon from "../../../assets/images/check_circle_completed.svg";
import InprogressCheckIcon from "../../../assets/images/check_circle_in_progress.svg";
import PendingCheckIcon from "../../../assets/images/check_circle_pending.svg";
import { getWorkflowSteps } from "../../../api";
import { ERROR_MSG } from "../../../constants";
import {
  LOCAL_STORAGE_KEY,
  NOTIFICATION_TYPE,
  getItem,
  showNotification,
} from "../../../utils";
import "./workflow-tooltip.scss";

export default function WorkFlowToolTip({
  children,
  id,
  type,
  onViewWorkflow,
}) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [stepsData, setStepsData] = useState([]);
  const [errMsg, setErrorMsg] = useState("");

  const handleOpenChange = async (open) => {
    setErrorMsg("");
    setLoading(true);
    setOpen(open);
    try {
      if (!open) return;
      const res = await getWorkflowSteps({
        type,
        data: {
          event_ref_ids: id,
        },
      });
      setStepsData(res?.data?.result);
    } catch (error) {
      showNotification(
        NOTIFICATION_TYPE.error,
        "",
        error?.response?.data?.message || ERROR_MSG.common
      );
      setErrorMsg(ERROR_MSG.fetchingFail);
    } finally {
      setLoading(false);
    }
  };

  const findNextApproval = stepsData.findIndex((data) => !data.approved_by);

  const getCheckIcon = (step, index) => {
    if (step.approved_by) {
      return CompletedCheckIcon;
    }
    if (!step.approved_by && index === findNextApproval) {
      return InprogressCheckIcon;
    }
    return PendingCheckIcon;
  };

  const getStepName = (item) => {
    return `${item.step_name} ${
      item.approver_id === getItem(LOCAL_STORAGE_KEY.USER_ORD_ID) ? "(You)" : ""
    }`;
  };

  const handleOnClickViewWorkflow = () => {
    setOpen(false);
    onViewWorkflow();
  };

  const tooltipContent = () => (
    <div className="p-10px">
      <div className="list-container">
        <When condition={loading}>
          <ComponentLoader />
        </When>
        <When condition={!loading}>
          {stepsData.map((item, index) => (
            <>
              <Box
                justifyContent="flexStart"
                alignContent="flexStart"
                alignItem="flexStart"
              >
                <Box rowGap={0} direction="column" wrap="nowrap">
                  <Image src={getCheckIcon(item, index)} />
                  <When condition={index !== stepsData.length - 1}>
                    <div className="line" />
                  </When>
                </Box>
                <Typography
                  variant="body"
                  className="w-70"
                  text={getStepName(item)}
                  ellipsis={{
                    tooltip: getStepName(item),
                  }}
                />
              </Box>
            </>
          ))}
          <When condition={errMsg}>
            <Typography variant="body" text={errMsg} />
          </When>
        </When>
      </div>
      <CustomButton
        className="w-100"
        text="Full workflow details"
        onClick={handleOnClickViewWorkflow}
      />
    </div>
  );

  const overlayStyle = { width: "222px" };
  return (
    <CommonToolTip
      color="white"
      title={tooltipContent}
      overlayStyle={overlayStyle}
      placement="bottom"
      destroyTooltipOnHide={true}
      onOpenChange={handleOpenChange}
      autoAdjustOverflow={false}
      open={open}
    >
      {children}
    </CommonToolTip>
  );
}
