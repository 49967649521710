import { memo } from "react";
import { YapilyIntegrationConfig } from "../../../constants/Integration/OpenBanking";
import { Box, Link, TextTriggeredDrawer, Typography } from "../../common";
import "./YapilyAccount.scss";

const { readMoreConfig, termsAndConditionsConfig } =
  YapilyIntegrationConfig.footerConfig;

const {
  readMoreHelperText,
  readMoreTriggerText,
  readMoreDrawerTitle,
  readMoreStatement,
  readMoreTnCHelperText,
  readMoreTnCText,
  readMoreTnCLink,
} = readMoreConfig;

function ReadMoreDrawer() {
  return (
    <TextTriggeredDrawer
      triggerText={readMoreTriggerText}
      drawerTitle={readMoreDrawerTitle}
    >
      <Typography variant={"secondary"} text={readMoreStatement} />
      <Box justifyContent="flexStart" columnGap={4}>
        <Typography variant={"secondary"} text={readMoreTnCHelperText} />
        <Link text={readMoreTnCText} href={readMoreTnCLink} />
      </Box>
    </TextTriggeredDrawer>
  );
}

function YapilyUserServiceConfirmation() {
  return (
    <div className="yapily-terms-footer m-t-16px">
      <Box columnGap={4} rowGap={0}>
        {termsAndConditionsConfig.map(({ type, text, linkURL, key }) => {
          switch (type) {
            case "linkText":
              return <Link key={key} text={text} href={linkURL} />;
            default:
              return <Typography key={key} variant={"secondary"} text={text} />;
          }
        })}
      </Box>
      <Typography
        variant={"secondary"}
        text={
          <>
            {readMoreHelperText}
            <ReadMoreDrawer />
          </>
        }
      />
    </div>
  );
}

export default memo(YapilyUserServiceConfirmation);
