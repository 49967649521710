import React from "react";
import "./preview-approval-path.scss";
import { Typography, Box, When, Icon, Divider } from "..";
import { constructClassName, getApprover } from "../../../utils";
import { WORKFLOW_FORM, ROLE_ATTR_ARRAY } from "../../../constants";

function PreviewApprovalPath({ workflowData, className, showName = true }) {
  return (
    <div
      className={constructClassName([
        className,
        "workflow-section kloo-scrollbar",
      ])}
    >
      <When condition={showName}>
        <Typography
          className="m-b-17px"
          fontSize={18}
          variant="body"
          text={`Approval path for ${workflowData?.workflow_name}`}
          fontStyle={"semibold"}
        />
      </When>
      <div>
        {workflowData?.workflow_children?.map((workflow, dataIndex) => {
          return (
            <div className="w-100">
              {workflow?.workflowsteps?.map((workflowstep, index) => {
                const isLast =
                  index === workflow?.workflowsteps?.length - 1 &&
                  dataIndex === workflowData?.workflow_children?.length - 1;
                return (
                  <Box wrap="nowrap" justifyContent="start">
                    <Box direction="column" rowGap={0} columnGap={0}>
                      <Icon
                        iconName="check_circle"
                        className={constructClassName([
                          isLast ? "add-last-item-width" : "",
                          "check-circle-color",
                          "text-align-center",
                        ])}
                      />
                      <When condition={!isLast}>
                        <Divider type="vertical" className="vertical-line" />
                      </When>
                    </Box>
                    <Typography
                      className="approver-path-name-txt  ms-2"
                      variant="body"
                      text={getApprover(
                        ROLE_ATTR_ARRAY,
                        workflowstep,
                        WORKFLOW_FORM.PLACEHOLDERS.APPROVER_EDIT
                      )}
                    />
                  </Box>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PreviewApprovalPath;
