import { useState, useEffect, useCallback } from "react";

const useTimer = (initialTime) => {
  const [timeLeft, setTimeLeft] = useState(0);

  const startTimer = useCallback(() => {
    setTimeLeft(initialTime);
  }, [initialTime]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [timeLeft]);

  return [timeLeft, startTimer];
};

export default useTimer;
