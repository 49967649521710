import { getItem, LOCAL_STORAGE_KEY } from "../utils";

export * from "./Integration";
export * from "./Categorisation";
export * from "./InvoiceManagement";
export * from "./CardRequest";
export * from "./CardExpenses";
export * from "./Cards";
export * from "./SmartApprovals";
export * from "./SupplierManagement";
export * from "./Auth";
export * from "./InvoiceMatching";
export * from "./PurchaseOrder";
export * from "./CreateInvoice";
export * from "./Dashboard";

export function splitArrayIntoSubArrays(arr, chunkSize) {
  const subArrays = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    subArrays.push(arr.slice(i, i + chunkSize));
  }
  return subArrays;
}

export const buildQueryParams = (params) => {
  return Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== "")
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
};

export function toCapitalCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function getUserNameAndEmail() {
  const fullName = `${getItem(LOCAL_STORAGE_KEY.FIRST_NAME)} ${getItem(
    LOCAL_STORAGE_KEY.LAST_NAME
  )}`;
  const email = getItem(LOCAL_STORAGE_KEY.EMAIL);

  return email ? { fullName, email } : {};
}
