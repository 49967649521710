import { Layout, Menu, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useIdleTimer, workerTimers } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CARDS_MODULE_NAVIGATION_URL,
  NAVIGATION_URL,
  ORGANISATION_TYPE,
  PERMISSIONS,
  PURCHASE_REQUEST_PAGE_UPDATE,
  SUPPLIER_PLACEHOLDER,
} from "../../constants";
import { useUserType } from "../../hooks";
import useOnClickOutside from "../../hooks/useClickOutside";
import {
  getCurrencies,
  logoutUser,
  organisationAmount,
  refreshLogin,
  usersFilterData,
} from "../../redux/action/auth";
import { getcodatStatus } from "../../redux/action/codat";
import { getMenuList } from "../../redux/action/menu";
import { modulaAccountDetails } from "../../redux/action/modular";
import {
  getBannerNotifications,
  notificationsData,
} from "../../redux/action/notifications";
import { multipleOrgnaisationDetail } from "../../redux/action/switchProfile";
import { getItem, removeLastSlash, setItem } from "../../utils";
import {
  INACTIVETIMEOUT,
  INACTIVETIMEOUTDEBOUNCETIME,
  LOCAL_STORAGE_KEY,
  PROMPTTIMEOUT,
  REFRESHTIMEOUT,
  USER_TYPES,
} from "../../utils/constant";
import { NOTIFICATION_TYPE, showNotification } from "../../utils/notifications";
import Notifications from "../Notifications/notifications";
import { socketResponse } from "../socketIO";
import SwitchProfile from "../SwitchProfile/SwitchProfile";
import { UserInactiveModal } from "./userInactiveModal";
import { useCheckIfUserInSmartApprovals } from "../../hooks/useCheckIfUserInSmartApprovals";
import AppHeader from "./AppHeader";
import AppBodyContainer from "./AppBodyContainer";
import AppSider from "./AppSider";
import "./sidenav.scss";
import { Content } from "antd/lib/layout/layout";
import {
  isMatchingHidden,
  isReverifyRequired,
  refreshTokenPayload,
} from "../../helper";
import { refreshToken } from "../../api/LoginAPI";
import { isCreditNoteFeatureAvailableForCurrentOrgOnProd } from "../../helper/CreditNotes";
import { InactiveLogout, When } from "../common";

const initState = { isRightSidebar: false, isLeftSidebar: true };
const LayoutMain = ({ children }) => {
  const [selectedSidebar, setSelectedSidebar] = useState(
    NAVIGATION_URL.DASHBOARD
  );
  const [orgData, setOrgData] = useState([]);
  const [showSideData, setshowSideData] = useState([]);
  const [isOpen, setIsOpen] = useState(initState);
  const [notifications, setNotifications] = useState([]);
  let perviousNotification = localStorage.getItem("bannerNotification");
  perviousNotification = perviousNotification
    ? JSON.parse(perviousNotification)
    : null;
  const [bannerNotifications, setBannerNotifications] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPathname = removeLastSlash(location.pathname);
  const isCardsModulesOpen =
    CARDS_MODULE_NAVIGATION_URL.includes(currentPathname);
  const ref = useRef();
  const [confirmPopup, setConfirmPopUp] = useState(false);
  const [ShowSwitchProfile, setShowSwitchProfile] = useState(false);
  const { isPlatformUser } = useUserType();
  const { userExistsInSmartApprovals } = useCheckIfUserInSmartApprovals();
  const onLoadWindow = useRef(false);
  const org_type = getItem("org_type");
  const isOrgOthers = org_type === ORGANISATION_TYPE.OTHERS.key;

  const usersData = useSelector(
    (state) => state && state?.manageUsers && state?.manageUsers?.usersFilter
  );
  const MenuList = useSelector(
    (state) => state && state?.menuReducer && state?.menuReducer?.menuList
  );
  const modularAccount = useSelector(
    (state) => state && state?.modular && state?.modular?.accountDetail
  );
  const OrgAmount = useSelector(
    (state) => state && state?.modular && state?.modular?.organisationAmount
  );
  const organization_address =
    modularAccount[0] && modularAccount[0]?.organization_address[0];
  const userType = useSelector(
    (state) => state && state?.menuReducer && state?.menuReducer?.userType
  );

  const cards = MenuList?.Cards;

  useOnClickOutside(ref, () => sidebarHandle("right"));

  useEffect(() => {
    const orgId = localStorage.getItem("user_org_id");
    const id = localStorage.getItem("org_id");
    const user_id = localStorage.getItem("id");

    if (orgId) {
      dispatch(getMenuList(orgId)); //write org id here
      dispatch(modulaAccountDetails(id)); //write org id here
      user_id &&
        dispatch(multipleOrgnaisationDetail(user_id)).then((res) => {
          const data =
            (res &&
              res?.result.map((el) => {
                return {
                  ...el,
                  bgcolor: `#${Math.floor(Math.random() * 16777215).toString(
                    16
                  )}`,
                };
              })) ||
            [];
          setOrgData([...data] || []);
        });
    }
    getBannerNotifications().then((data) => {
      const notiicationMessage = { ...data, show: true };
      if (data && !perviousNotification) {
        setBannerNotifications(notiicationMessage);
      } else if (
        data &&
        ((perviousNotification?.result?.message !== data?.result?.message &&
          data?.result?.id !== perviousNotification?.result?.id) ||
          perviousNotification?.show)
      ) {
        setBannerNotifications(notiicationMessage);
      } else {
        setBannerNotifications(perviousNotification);
      }
    });
    getCurrencies();
  }, []);

  useEffect(() => {
    const mySocketSub = socketResponse.subscribe((data) =>
      dispatch(notificationsData(data))
    );
    return () => {
      mySocketSub.unsubscribe();
    };
  });

  useEffect(() => {
    const org_id = localStorage.getItem("org_id");
    if (
      userType &&
      userType !== USER_TYPES.PU &&
      userType !== USER_TYPES.AM &&
      !isOrgOthers
    ) {
      dispatch(organisationAmount(org_id)); //write org id here
    }
  }, [userType]);

  useEffect(() => {
    dispatch(getcodatStatus());
  }, []);

  const handleVisibility = (item, route) => {
    if (item.key.split("/")[1] === route[1]) {
      item.key = `/${route?.slice(1)?.join("/")}`;
    }
    item.visible = item?.parentLabel
      ? MenuList[item?.parentLabel]?.includes(item.viewLabel)
      : !!MenuList[item.viewLabel];
    if (
      item.key === NAVIGATION_URL.SPLIT_INVOICE ||
      item.key === NAVIGATION_URL.SINGLE_MATCHING_INVOICE
    ) {
      item.key = NAVIGATION_URL.INVOICE_MANAGEMENT;
    } else if (item.key === NAVIGATION_URL.CREDIT_NOTES_LINK_INVOICE) {
      item.key = NAVIGATION_URL.CREDIT_NOTES;
    }
    if (
      item.key === NAVIGATION_URL.PAYMENT_RUNS &&
      isPlatformUser &&
      !userExistsInSmartApprovals
    ) {
      item.visible = false;
    }
    return item.visible ? item : null;
  };

  useEffect(() => {
    const leftSideData = [
      {
        key: NAVIGATION_URL.DASHBOARD,
        label: "Home",
        viewLabel: PERMISSIONS.DASHBOARD.key,
      },
      {
        key: "cards",
        label: "Cards",
        children: [
          {
            key: NAVIGATION_URL.CARDS,
            label: "Card management",
            viewLabel: PERMISSIONS.CARDS.key,
          },
          {
            key: NAVIGATION_URL.CARD_EXPENSES,
            label: "Card expenses",
            viewLabel: PERMISSIONS.CARD_EXPENSES.key,
          },
          {
            key: NAVIGATION_URL.APPROVALS,
            label: "Card requests",
            viewLabel: PERMISSIONS.APPROVALS.key,
          },
        ],
      },
      {
        key: "Account payable",
        label: "Accounts payable",
        children: [
          {
            key: NAVIGATION_URL.PO_MANAGEMENT,
            label: PURCHASE_REQUEST_PAGE_UPDATE.LABEL,
            viewLabel: PERMISSIONS.PO.key,
          },
          {
            key: NAVIGATION_URL.INVOICE_MANAGEMENT,
            label: "Invoice management",
            viewLabel: PERMISSIONS.INVOICE_MANAGEMENT.key,
          },
          {
            key: NAVIGATION_URL.PAYMENT_RUNS,
            label: "Payment runs",
            viewLabel: PERMISSIONS.PAYMENT_RUNS.key,
          },
        ],
      },

      {
        key: NAVIGATION_URL.FUNDS,
        label: "Funds",
        viewLabel: PERMISSIONS.FUNDS.key,
      },

      {
        key: "settings",
        label: "Settings",
        viewLabel: PERMISSIONS.SETTING.read,
        parentLabel: PERMISSIONS.SETTING.key,
        children: [
          {
            key: NAVIGATION_URL.INTEGRATIONS,
            label: "Integrations",
            parentLabel: PERMISSIONS.SETTING.key,
            viewLabel: PERMISSIONS.SETTING.INTEGRATION.read,
          },
          {
            key: NAVIGATION_URL.ERP_CONTACT_CREATION,
            label: "ERP contact creation",
            parentLabel: PERMISSIONS.SETTING.key,
            viewLabel: PERMISSIONS.SETTING.INTEGRATION.read,
          },
          {
            key: NAVIGATION_URL.USERS,
            label: "Users",
            viewLabel: PERMISSIONS.USERS.key,
          },
          {
            key: NAVIGATION_URL.TEAMS,
            label: "Teams",
            viewLabel: PERMISSIONS.TEAMS.key,
          },
          {
            key: NAVIGATION_URL.TRANSACTION,
            label: "Transactions",
            viewLabel: PERMISSIONS.TRANSACTIONS.key,
          },
          {
            key: NAVIGATION_URL.CATEGORISATION,
            label: "Categorisation",
            parentLabel: PERMISSIONS.SETTING.key,
            viewLabel: PERMISSIONS.SETTING.CATEGORISATION.read,
          },
          {
            key: NAVIGATION_URL.SMART_APPROVALS("po"),
            label: "Smart approvals",
            parentLabel: PERMISSIONS.SETTING.key,
            viewLabel: PERMISSIONS.SETTING.SMART_APPROVAL.read,
          },
          {
            key: NAVIGATION_URL.SUPPLIER_MANAGEMENT,
            label: `${SUPPLIER_PLACEHOLDER} management`,
            parentLabel: PERMISSIONS.PAYEE_MANAGEMENT.key,
            viewLabel: PERMISSIONS.PAYEE_MANAGEMENT.read,
          },
          {
            key: NAVIGATION_URL.CONFIGURATIONS,
            label: "Configurations",
            parentLabel: PERMISSIONS.SETTING.key,
            viewLabel: PERMISSIONS.SETTING.CONFIGURATION.read,
          },
          {
            key: NAVIGATION_URL.ADDRESS,
            label: "Delivery address",
            parentLabel: PERMISSIONS.SETTING.key,
            viewLabel: PERMISSIONS.SETTING.ADDRESS.read,
          },
        ],
      },
    ];
    if (isCreditNoteFeatureAvailableForCurrentOrgOnProd()) {
      leftSideData[2].children.push({
        key: NAVIGATION_URL.CREDIT_NOTES,
        label: "Credit notes",
        viewLabel: PERMISSIONS.CREDIT_NOTES.key,
      });
    }

    if (!isMatchingHidden()) {
      leftSideData[2].children = [
        ...leftSideData[2].children.slice(0, 2),
        {
          key: NAVIGATION_URL.INVOICE_MATCHING,
          label: "Invoice matching",
          viewLabel: PERMISSIONS.INVOICE_MATCHING.key,
        },
        ...leftSideData[2].children.slice(2),
      ];
    }

    if (MenuList) {
      const route = location?.pathname?.split("/");
      const updatedTabs = [];
      leftSideData.forEach((item) => {
        if (item?.children) {
          const newChild = [];
          item.children.forEach((subItem) => {
            const itemAllowed = handleVisibility(subItem, route);
            if (itemAllowed) {
              newChild.push(itemAllowed);
            }
          });
          item.children = newChild;
          if (item.viewLabel) {
            item.visible = item?.parentLabel
              ? MenuList[item?.parentLabel]?.includes(item.viewLabel)
              : !!MenuList[item.viewLabel];
            if (item.visible && item.children.length) {
              updatedTabs.push(item);
            }
          } else {
            if (item.children.length) {
              updatedTabs.push(item);
            }
          }
        } else {
          const checkedItem = handleVisibility(item, route);
          if (checkedItem) {
            updatedTabs.push(item);
          }
        }
      });
      setSelectedSidebar(
        `/${route?.slice(1)?.join("/")}` || NAVIGATION_URL.DASHBOARD
      );
      setshowSideData([...updatedTabs]);
    }
    // }
  }, [location?.pathname, MenuList, cards, userExistsInSmartApprovals]);

  useEffect(() => {
    // getNotifications();
    dispatch(usersFilterData({ ...usersData, PageNo: 1, selection: [] }));
  }, [location?.pathname]);

  const sidebarHandle = (type) => {
    let dumpState = initState;
    if (type === "left") {
      dumpState = {
        ...isOpen,
        isLeftSidebar: !isOpen.isLeftSidebar,
      };
    } else {
      dumpState = {
        ...isOpen,
        isRightSidebar: !isOpen.isRightSidebar,
      };
    }
    setIsOpen({ ...dumpState });
  };

  // profile menu code

  const handleLogout = () => {
    var form_data = new FormData();
    form_data.append("token_id", localStorage.getItem("access_token"));
    dispatch(logoutUser(form_data))
      .then((res) => {
        if (res?.statusCode === 201) {
          localStorage.removeItem("kloo_login");
          showNotification(
            NOTIFICATION_TYPE.success,
            "Logout success!",
            "You have logged out successfully"
          );
          navigate(NAVIGATION_URL.LOGIN);
        }
      })
      .catch((err) => {
        showNotification(
          NOTIFICATION_TYPE.error,
          "Logout failed!",
          "Something went wrong"
        );
      });
  };

  const Items = [
    {
      label: "Profile",
      key: "/profile",
    },
    {
      label: "Change password",
      key: "/change-password",
    },
    {
      label: "Switch organisation",
      key: "Switch_organisation",
    },
    {
      label: "Logout",
      key: "logout",
    },
  ];

  const profileMenu = (
    <Menu
      selectedKeys={[selectedSidebar]}
      className="profile-dropdown"
      onClick={(e) => {
        if (e.key === "logout") {
          handleLogout();
        } else if (e.key === "Switch_organisation") {
          setShowSwitchProfile(true);
          return;
        } else {
          navigate(`${e.key}`);
        }
      }}
      items={Items?.filter((el) => {
        if (el.key === "Switch_organisation" && orgData?.length === 1) {
          return false;
        }
        if (el.key === "/change-password" && getItem(LOCAL_STORAGE_KEY.SSO)) {
          return false;
        }
        return true;
      })}
    />
  );
  // profile menu code

  //refresh modal code

  const newRefreshToken = async () => {
    try {
      const SSO = getItem(LOCAL_STORAGE_KEY.SSO);
      const payload = refreshTokenPayload({ SSO: SSO });
      await refreshToken(payload);
      // setItem(LOCAL_STORAGE_KEY.VERIFY_OTP, true);
    } catch (e) {
      localStorage.clear();
      window.location.reload();
    }
  };

  useEffect(() => {
    let timer = setInterval(() => {
      newRefreshToken();
    }, REFRESHTIMEOUT);
    let timer2nd = setInterval(() => {
      if (!localStorage.getItem("id")) {
        window.location.reload();
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
      clearTimeout(timer2nd);
    };
  }, []);

  //refresh and inactive modal code

  useEffect(() => {
    window.scrollTo(10, 10);
  }, []);

  // window resize code
  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize?.innerWidth < 768) {
      const dumpState = {
        ...isOpen,
        isLeftSidebar: isOpen.isLeftSidebar,
      };
      setIsOpen({ ...dumpState });
    }
  }, [windowSize, window]);

  useEffect(() => {
    if (getWindowSize().innerWidth < 768) {
      onLoadWindow.current = true;
      const dumpState = {
        ...isOpen,
        isLeftSidebar: false,
      };
      setIsOpen({ ...dumpState });
    } else {
      onLoadWindow.current = false;
    }
  }, []);

  // window resize code

  return (
    <div>
      {ShowSwitchProfile && (
        <Modal
          centered
          className="swicth-modal"
          open={true}
          closable={true}
          footer={null}
          onCancel={() => setShowSwitchProfile(false)}
        >
          <SwitchProfile
            loggedIn={true}
            orgData={orgData}
            setShowSwitchProfile={setShowSwitchProfile}
          />
        </Modal>
      )}
      <When condition={isReverifyRequired() && !isCardsModulesOpen}>
        <InactiveLogout isFromCardsModule={false} />
      </When>
      <Layout>
        <AppHeader
          sidebarHandle={sidebarHandle}
          OrgAmount={OrgAmount}
          userType={userType}
          isOrgOthers={isOrgOthers}
          modularAccount={modularAccount}
          organizationAddress={organization_address}
          profileMenu={profileMenu}
          isOpen={isOpen}
        />
        <Layout hasSider className="site-layout">
          <AppSider
            selectedSidebar={selectedSidebar}
            showSideData={showSideData}
            isOpen={isOpen}
          />
          <Content>
            <Notifications
              windowSize={windowSize}
              bannerNotifications={bannerNotifications}
              isLeftSidebar={isOpen.isLeftSidebar}
            />
            <AppBodyContainer
              selectedSidebar={selectedSidebar}
              isOpen={isOpen}
              ref={ref}
              notifications={notifications}
            >
              {children}
            </AppBodyContainer>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default LayoutMain;
