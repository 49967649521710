import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";

export function getInstitution() {
  return apiClient({
    url: `${PATH.yapilyIntegration.getInstitution}`,
    method: "GET",
  });
}

export function searchInstitution(search) {
  return apiClient({
    url: `${PATH.yapilyIntegration.getInstitution}?search=${search}`,
    method: "GET",
  });
}

export function getAuthorizationToken(data) {
  return apiClient({
    url: `${PATH.yapilyIntegration.getAuthorizationToken}`,
    method: "POST",
    data,
  });
}

export function storeConsentToken(data) {
  return apiClient({
    url: `${PATH.yapilyIntegration.storeConsentToken}`,
    method: "POST",
    data,
  });
}

export function checkConsentStatus(consentId) {
  return apiClient({
    url: `${PATH.yapilyIntegration.checkConsentStatus(consentId)}`,
    method: "GET",
  });
}

export function renewConsentToken(data) {
  return apiClient({
    url: `${PATH.yapilyIntegration.renewConsentToken}`,
    method: "POST",
    data,
  });
}

export function reAuthorizeConsentToken(data) {
  return apiClient({
    url: `${PATH.yapilyIntegration.reAuthorizeConsentToken}`,
    method: "POST",
    data,
  });
}

export function withdrawConsentToken(consentId) {
  return apiClient({
    url: `${PATH.yapilyIntegration.withdrawConsent(consentId)}`,
    method: "PUT",
  });
}

export function getPaymentAuthorizationToken(data) {
  return apiClient({
    url: `${PATH.yapilyIntegration.getPaymentAuthorizationToken}`,
    method: "POST",
    data,
  });
}

export function checkPaymentConsentStatus(consentId) {
  return apiClient({
    url: `${PATH.yapilyIntegration.checkPaymentConsentStatus(consentId)}`,
    method: "GET",
  });
}
