import React, { useRef, useState } from "react";
import { Box, Drawer } from "../../common";
import DrawerLeftSection from "./DrawerLeftSection";
import DrawerRightSection from "./DrawerRightSection";

function BulkActionDrawer(props) {
  const { BULK_ACTION_CONTENT, actionType, moduleData, parentModule, onClose } =
    props;
  const { title } = BULK_ACTION_CONTENT({
    actionType,
    module: parentModule,
  });
  const [selectedModuleData, setSelectedModule] = useState(moduleData[0]);
  const mainDivRef = useRef();

  return (
    <Drawer open={true} title={title} width={"80%"} onClose={onClose}>
      <div
        className={"h-100 d-flex align-item-flex-start column-gap-4"}
        ref={mainDivRef}
      >
        <Box
          className={"w-55"}
          direction={"column"}
          justifyContent={"spaceBetween"}
          wrap={"nowrap"}
          alignItem={"flexStart"}
        >
          <DrawerLeftSection
            {...props}
            selectedModuleData={selectedModuleData}
            setSelectedModule={setSelectedModule}
            mainDivRef={mainDivRef}
          />
        </Box>
        <div className={"w-45"} key={`${actionType}-right-section-drawer`}>
          <DrawerRightSection selectedModuleData={selectedModuleData} />
        </div>
      </div>
    </Drawer>
  );
}

export default BulkActionDrawer;
