import { DEFAULT_FORMAT, REGISTRATION_FORM } from "../../utils";
import { NAVIGATION_URL } from "../navigationUrl";
import { ACCENTS, BUTTON_VARIANT } from "../theme";
const { SSO, ANALYTICS_TYPE } = REGISTRATION_FORM;

export const KBA_QUESTION_ID = "71640d17-b32b-4842-8c00-b1af7e26bb6e";

export const companyTurnoverOptions = [
  { value: "Less then 5M", label: "Less then 5M" },
  { value: "5m - 9.9M", label: "5m - 9.9M" },
  { value: "10M - 24.9M", label: "10M - 24.9MM" },
  { value: "25M - 49.9M", label: "25M - 49.9M" },
  { value: "50M - 99.9M", label: "50M - 99.9M" },
  { value: "100M - 249.9M", label: "100M - 249.9M" },
  { value: "250M - 499.9M", label: "250M - 499.9M" },
  { value: "500M+", label: "500M+" },
  { value: "500M - 1BN", label: "500M - 1BN" },
  { value: "1BN+", label: "1BN+" },
];
export const employeeCountOptions = [
  { value: "1 - 10", label: "1 - 10" },
  { value: "11 - 50", label: "11 - 50" },
  { value: "51 - 100", label: "51 - 100" },
  { value: "101 - 200", label: "101 - 200" },
  { value: "201 - 500", label: "201 - 500" },
  { value: "501 - 1000", label: "501 - 1000" },
  { value: "1001 - 5000", label: "1001 - 5000" },
  { value: "5001+", label: "5001+" },
];

export const RegistrationConstants = {
  title: "Create your Kloo account",
  country: {
    label: "Select country",
  },
  password: {
    label: "Enter password",
  },
  passwordExist: {
    label: "Enter your existing Kloo password",
  },
  confirmPassword: {
    label: "Confirm password",
  },
  submitButtonLabel: "Submit",
  privacyPolicy: {
    label: "Privacy policy",
    link: "https://getkloo.com/privacy-policy",
  },
  termsAndCondition: {
    label: "Terms and conditions",
    link: "https://getkloo.com/terms-conditions",
  },
};

export const REGISTRATION_TYPES = {
  SELF: "self",
  ANALYTICS: "analytics",
  SSO: "SSO",
  OTHERS: "Others",
};

export const REGISTRATION = {
  TITLE: {
    [REGISTRATION_TYPES.SSO]: {
      step1: "Sign up for Kloo",
      step2: "Create your Kloo account",
    },
    [REGISTRATION_TYPES.ANALYTICS]: "Register for Kloo Analytics",
    [REGISTRATION_TYPES.SELF]: "Create your Kloo account",
  },
  microsoftButton: {
    label: "Sign up with Microsoft",
  },
  steps: [
    { key: 0, title: "Create account" },
    { key: 1, title: "Verify identity via OTP" },
  ],

  REGISTRATION_FORM_ITEMS: {
    firstName: {
      name: "first_name",
      placeholder: "First name",
      size: "large",
      label: "First name",
      maxLength: 50,
      rules: ANALYTICS_TYPE.FIRST_NAME,
    },
    lastName: {
      name: "last_name",
      placeholder: "Surname",
      size: "large",
      label: "Surname",
      maxLength: 50,
      rules: ANALYTICS_TYPE.LAST_NAME,
    },
    jobTitle: {
      name: "job_title",
      placeholder: "Job title",
      size: "large",
      label: "Job title",
      maxLength: 50,
      rules: ANALYTICS_TYPE.JOB_TITLE,
    },
    companyName: {
      name: "company_name",
      placeholder: "Company name",
      size: "large",
      label: "Company name",
      maxLength: 50,
      rules: ANALYTICS_TYPE.COMPANY_NAME,
    },

    companyTurnover: {
      placeholder: "Annual turnover",
      label: "Annual turnover",
      name: "turnover",
      size: "large",
      showSearch: true,
    },
    employeeHeadcount: {
      placeholder: "Employee headcount",
      label: "Employee headcount",
      name: "headcount",
      size: "large",
      howSearch: true,
    },
    country: {
      placeholder: "Select country",
      label: "Country",
      name: "country",
      rules: ANALYTICS_TYPE.COUNTRY,
    },
    email: {
      placeholder: `Email`,
      label: `Email`,
      name: "email",
      size: "large",
      rules: ANALYTICS_TYPE.EMAIL,
    },
    existingPassword: {
      placeholder: `Enter your existing Kloo password`,
      label: ``,
      name: "password",
      type: "password",
      size: "large",
      isPassword: true,
      rules: ANALYTICS_TYPE.PASSWORD_EXIST,
    },
    password: {
      placeholder: `Enter password`,
      label: `Password`,
      name: "password",
      type: "password",
      size: "large",
      prefixIcon: false,
      rules: ANALYTICS_TYPE.PASSWORD,
    },
    confirmPassword: {
      placeholder: `Confirm password`,
      label: `Confirm password`,
      name: "confirmPassword",
      hasFeedback: true,
      type: "password",
      size: "large",
      isPassword: true,
      rules: ANALYTICS_TYPE.CONFIRM_PASSWORD,
    },
    mobileNumber: {
      placeholder: "Mobile number",
      label: "Mobile number",
      name: "contact",
      rules: ANALYTICS_TYPE.MOBILE,
      defaultCountry: "gb",
      country: "gb",
      enableSearchField: true,
      disableSearchIcon: true,
      enableSearch: true,
      autoFocus: true,
      preferredCountries: ["us", "gb"],
    },
    dob: {
      name: "birth_date",
      label: "Birth date",
      format: DEFAULT_FORMAT,
      suffixIcon: "calendar_month",
      rules: ANALYTICS_TYPE.DOB,
      placeholder: "Enter birth date",
    },
    KBAQuestion: {
      name: "question",
      label: "What is the name of your favourite childhood friend?",
      isTextArea: true,
      allowClear: false,
      rules: ANALYTICS_TYPE.KBA_QUESTION,
      placeholder: "What is the name of your favourite childhood friend?",
    },
    signUpButton: {
      text: "Sign up",
      accent: ACCENTS.PRIMARY,
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      className: "w-100",
      htmlType: "submit",
      customSize: "large",
      showLoader: true,
    },
    next: {
      text: "Next",
      className: "w-100",
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      accent: ACCENTS.PRIMARY,
      htmlType: "submit",
      key: "submit",
      customSize: "large",
    },
    verifyButton: {
      text: "Verify",
      accent: ACCENTS.PRIMARY,
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      className: "w-100",
      htmlType: "submit",
      customSize: "large",
    },
  },
  invalidPayload: "The payload is invalid.",
  invalidURL: "Invalid URL !!",
  errorMessage: {
    title: "Registration failed",
    subTitle: "Please try again later",
    accountExist: "Account already exists",
  },

  FORGOT_PASSWORD: {
    text: "Forgot password?",
    link: NAVIGATION_URL.FORGOT_PASSWORD,
  },
};
export const SSO_REGISTRATION = {
  title: { step1: "Sign up for Kloo", step2: "Create your Kloo account" },
  microsoftButton: {
    label: "Sign up with Microsoft",
  },
  SSO_REGISTRATION_FORM: {
    country: {
      placeholder: "Select country",
      label: "Country",
      name: "country",
      rules: SSO.COUNTRY,
    },
    mobileNumber: {
      placeholder: "Mobile number",
      label: "Mobile number",
      name: "contact",
      rules: SSO.MOBILE,
      defaultCountry: "gb",
      country: "gb",
      enableSearchField: true,
      disableSearchIcon: true,
      enableSearch: true,
      autoFocus: true,
      preferredCountries: ["us", "gb"],
    },
    dob: {
      name: "birth_date",
      label: "Birth date",
      format: DEFAULT_FORMAT,
      suffixIcon: "calendar_month",
      rules: SSO.DOB,
      placeholder: "Enter birth date",
    },
    KBAQuestion: {
      name: "question",
      label: "What is the name of your favourite childhood friend?",
      isTextArea: true,
      allowClear: false,
      rules: SSO.KBA_QUESTION,
      placeholder: "What is the name of your favourite childhood friend?",
    },
    signUpButton: {
      text: "Sign up",
      accent: ACCENTS.PRIMARY,
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      className: "w-100",
      htmlType: "submit",
      customSize: "large",
      showLoader: true,
    },
  },
};
