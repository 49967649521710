import { getItem, LOCAL_STORAGE_KEY } from "../utils";

export const isOTPVerified = () => {
  return getItem(LOCAL_STORAGE_KEY.VERIFY_OTP) === "true";
};

export const isInactiveOTPVerifyRequired = () => {
  return getItem(LOCAL_STORAGE_KEY.INACTIVE_OTP) === "true";
};

export const isKlooLoginPresent = () => {
  return !!JSON.parse(localStorage.getItem("kloo_login"));
};

export const useAuth = () => {
  return isKlooLoginPresent() && isOTPVerified();
};
