import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import { getItem } from "../utils";

export function getUsersForSelect({ status = null }) {
  return apiClient({
    url: PATH.user.userListForSelect(getItem("org_id"), status),
    method: "GET",
  });
}

export function getAllRoles() {
  return apiClient({
    url: PATH.teams.getAllRoles(),
    method: "GET",
  });
}

export async function getUsers({
  forSelect,
  status = "active",
  team,
  page,
  role,
}) {
  if (forSelect) {
    const res = await apiClient({
      url: PATH.user.GetUser(team, page, status, role),
      method: "GET",
    });
    return (
      res.data?.result?.user_organization?.map((organization) => ({
        value: organization.id,
        label: organization?.users
          ? `${organization?.users?.first_name} ${organization?.users?.last_name}`
          : "N/A",
      })) || []
    );
  } else {
    return apiClient({
      url: PATH.user.GetUser(team, page, status, role),
      method: "GET",
    });
  }
}

export function getTeamManagerWithAttribute() {
  return apiClient({
    url: PATH.smart_approval.teamManagerAndApList,
    method: "GET",
  });
}
