import { INPUT_TYPES, SELECT_TYPE_ATTRIBUTES } from "../constants";
import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import { getRequiredRule } from "../utils";

export async function getCustomFields({ categoryType, pageName }) {
  const res = await apiClient({
    url: PATH.custom_expenses.getCustomFields(categoryType, pageName),
    method: "GET",
  });
  const isSelect = (type) => SELECT_TYPE_ATTRIBUTES.includes(type);
  const payload = res.data.result.map((field) => ({
    inputType: isSelect(field.attribute_type)
      ? INPUT_TYPES.SELECT
      : INPUT_TYPES.TEXT,
    inputId: field.id,
    inputLabel: field.field_name,
    name: field.id,
    type: field.attribute_type,
    placeHolder: getRequiredRule(
      field.field_name,
      isSelect(field.attribute_type)
    ).message,
    required: field.required,
    rules: field.required
      ? [
          getRequiredRule(
            "",
            false,
            "Your organisation requires this field to be completed"
          ),
        ]
      : [],
    options: field.field_values,
    isSelect: isSelect(field.attribute_type),
    fieldOptions: field.field_options,
    field_validation_configurations: {
      field_name: field.field_name,
      id: field.id,
    },
  }));
  return payload;
}

export async function getCustomFieldValuesWithPagination({
  integrationType,
  fieldId,
  page = 1,
  pageSize = 10,
  search,
}) {
  const tempResponse = await apiClient({
    url: `${PATH.customConditionalField.getCustomFieldValuesWithPagination({
      integrationType,
      fieldId,
    })}?page=${page}&per_page=${pageSize}${search ? `&search=${search}` : ""}`,
    method: "GET",
  });
  const response = {
    list: tempResponse?.data?.result?.data?.map(({ value, id }) => ({
      text: value,
      finalText: value,
      isEdit: false,
      id: id,
      isShowText: true,
      oldValue: value,
    })),
    pagination: tempResponse?.data?.result?.meta,
  };
  return response;
}

export async function getDropDownValues(customFieldId, customFieldType) {
  const response = await apiClient({
    url: PATH.custom_expenses.getCustomFieldValues(
      customFieldId,
      customFieldType
    ),
    method: "GET",
  });
  const payload = response?.data?.result?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return payload;
}

export async function createOption(fieldId, type, data) {
  return apiClient({
    method: "POST",
    url: PATH.custom_expenses.createChildlist(fieldId, null, type),
    data,
  });
}

export async function createOptionV2(type, id, data) {
  return apiClient({
    method: "POST",
    url: PATH.custom_expenses.createChildlistV2(type, id),
    data,
  });
}

export async function getCustomFieldsOnlyDynamicAndPredefined({
  integrationType,
}) {
  const res = await apiClient({
    url: PATH.customConditionalField.getCustomFieldsOnlyDynamicAndPredefined({
      integrationType,
    }),
    method: "GET",
  });
  return res?.data?.result || [];
}

export async function getCustomFieldIdValues({ id, integrationType }) {
  const res = await apiClient({
    url: PATH.customConditionalField.getCustomFieldValues({
      id,
      integrationType,
    }),
    method: "GET",
  });
  return res?.data?.result?.values || [];
}

export async function getCustomFieldsAndValues({ id, integrationType }) {
  const res = await apiClient({
    url: PATH.customConditionalField.getCustomFields({ integrationType }),
    method: "GET",
  });
  return res?.data?.result || [];
}
