import { Form } from "antd";
import Modal from "../Modal";
import CustomAlert from "../CustomAlert";
import CommonInput from "../Input";
import Box from "../Box";
import CustomButton from "../CustomButton";
import GRNIListContainer from "../../PurchaseOrder/GRNIListContainer";
import { useMemo } from "react";
import {
  CONFIRM_MATCHING_CONFIRMATION_MODAL,
  GRNI_OFFSET_RESET_SUFFIX_CONFIG,
  MATCH_TYPE,
} from "../../../constants";
import Icon from "../Icon";
import "./confirm-matching-confirmation-modal.scss";
import { calculateRemainingAmountAfterOffset } from "../../../helper";
import { amountFormatterWithoutCurrency } from "../../../utils";
import CommonInputNumber from "../Input/InputNumber";
import CommonToolTip from "../Tooltip";

function ConfirmMatchingConfirmationModal({
  open,
  onCancel,
  poDetails,
  invoiceDetails,
  threshold,
  onConfirmMatchClicked,
}) {
  const [form] = Form.useForm();
  const { TITLE, SUBTITLE, FORM_FIELDS, CTA } =
    CONFIRM_MATCHING_CONFIRMATION_MODAL;
  const { match, cancel } = CTA;
  const { TOOLTIP, ICON_BUTTON } = GRNI_OFFSET_RESET_SUFFIX_CONFIG;

  const formInitialValue = useMemo(() => {
    return {
      ...calculateRemainingAmountAfterOffset({
        initialFlow: true,
        poDetail: poDetails[0],
        invoiceDetail: invoiceDetails,
        threshold,
      }),
    };
  }, []);

  const handleForm = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        await onConfirmMatchClicked(values);
      })
      .catch((e) => {});
  };

  const isRematch = invoiceDetails?.matchType === MATCH_TYPE.CONFIRMED;

  const handleOffsetChange = (value) => {
    const calculatedValues = calculateRemainingAmountAfterOffset({
      offsetValue: value,
      poDetail: poDetails[0],
      invoiceDetail: invoiceDetails,
      threshold,
    });

    form.setFieldsValue({
      [FORM_FIELDS[1].field.name]: calculatedValues[FORM_FIELDS[1].field.name],
      [FORM_FIELDS[2].field.name]: calculatedValues[FORM_FIELDS[2].field.name],
    });
  };

  return (
    <Modal
      width={1000}
      title={TITLE({ isRematch })}
      subtitle={SUBTITLE({ isRematch })}
      isOpen={open}
      showDivider={false}
      hide={onCancel}
    >
      <GRNIListContainer
        id={poDetails[0]?.id}
        showGRNISectionByDefault={true}
        currencyData={{ currency: poDetails[0]?.currency }}
        isScrollEnabled
      />
      <Form
        className={"m-t-20px grni-offset-modal"}
        initialValues={formInitialValue}
        form={form}
        labelAlign={"left"}
      >
        {FORM_FIELDS.map((data, index) => {
          const currencySymbol = poDetails[0]?.symbol || "";
          const isOffsetAmountField = index === 0;

          if (!isOffsetAmountField)
            return (
              <CommonInput
                {...data.field}
                prefix={currencySymbol}
                formatter={amountFormatterWithoutCurrency}
              />
            );

          return (
            <Box
              wrap={"nowrap"}
              justifyContent={"spaceBetween"}
              alignItem={"flexStart"}
            >
              <CommonInputNumber
                {...data.field}
                rules={data.rules({
                  minOffsetAmount: formInitialValue.minimumOffsetValue,
                  maxOffsetAmount: formInitialValue.maximumOffsetValue,
                  currency: poDetails[0]?.currency,
                })}
                prefix={currencySymbol}
                onChange={handleOffsetChange}
                formatter={amountFormatterWithoutCurrency}
                addonAfter={
                  <CommonToolTip {...TOOLTIP}>
                    <CustomButton
                      {...ICON_BUTTON}
                      icon={<Icon iconName={"refresh"} />}
                      onClick={() => form.resetFields()}
                    />
                  </CommonToolTip>
                }
              />
              <CustomAlert
                message={data.helperText}
                icon={<Icon iconName="info" className="icon-style" />}
                type={"success"}
                showIcon
              />
            </Box>
          );
        })}
        <Box className={"m-t-16px"}>
          <CustomButton {...match} onClick={handleForm} />
          <CustomButton {...cancel} onClick={onCancel} />
        </Box>
      </Form>
    </Modal>
  );
}

export default ConfirmMatchingConfirmationModal;
