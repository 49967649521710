import React, { useEffect, useState } from "react";
import { CommonSelect, CustomAlert, Icon, When } from "../../../common";
import { isAutoInvoiceConfirmed } from "../../../../helper/Settings/Configurations";
import {
  CONFIGURATIONS_KEYS,
  INVOICE_FORM,
  INVOICE_SPLIT_CONSTANTS,
  MATCH_TYPE,
  MULTIPLE_PO_DROPDOWN_OPTION,
  PO_ERROR_MSG,
} from "../../../../constants";
import {
  calculateMatchType,
  convertToGBP,
  getCurrenciesFromLS,
  getRequiredRule,
} from "../../../../utils";
import { getPOForDropdown } from "../../../../api";

function PONumberInputField(props) {
  const {
    approvedPOs,
    disabled,
    onChange,
    dataPopulatedByAI,
    poNumberField,
    selectedData,
    form,
    threshold,
    fixedAmount,
    isAutoMatchEnabledForOrg,
    isPOFieldRequired,
    componentDisabled,
    isReviewTab,
    uploadContainerLoading,
    entityValue,
    setApprovedPOs,
  } = props;

  const [isPOValid, setIsPOValid] = useState("");
  const [closedPoError, setClosedPOError] = useState("");
  const [isPOSelected, setIsPOSelected] = useState(false);

  useEffect(() => {
    if (entityValue) {
      getPOForDropdown({ entityId: entityValue }).then((res) => {
        // multi PO dropdown option
        res?.unshift(MULTIPLE_PO_DROPDOWN_OPTION);
        setApprovedPOs(res);
      });
    }
  }, [entityValue]);

  const isMultiPurchaseOn =
    isAutoInvoiceConfirmed() && isAutoMatchEnabledForOrg;

  const validatePO = () => ({
    validator(_, value) {
      setClosedPOError("");
      setIsPOValid(false);
      if (value && value !== MULTIPLE_PO_DROPDOWN_OPTION.value) {
        const poExist = approvedPOs.find((po) => po.value === value);
        if (
          selectedData?.matchType === MATCH_TYPE.CONFIRMED &&
          selectedData?.matchedPoNumbers.join("") === value
        ) {
          if (poExist?.status === INVOICE_SPLIT_CONSTANTS.closedStatusPO) {
            setClosedPOError(PO_ERROR_MSG.closedPO);
            return Promise.resolve();
          } else {
            return Promise.resolve();
          }
        }

        if (!poExist) {
          return Promise.resolve();
        }

        if (poExist?.status === INVOICE_SPLIT_CONSTANTS.closedStatusPO) {
          setClosedPOError(PO_ERROR_MSG.closedPO);
          return Promise.resolve();
        }
        const currencyList = getCurrenciesFromLS();
        const convertedRemainingGBPAmount = convertToGBP(
          poExist.remaining_amount_PO,
          currencyList,
          poExist?.remaining_amount_PO_currency
        );

        const convertedPONetAmount = convertToGBP(
          poExist.net_amount,
          currencyList,
          poExist?.remaining_amount_PO_currency
        );
        const convertedInvoiceGBPAmount = convertToGBP(
          form.getFieldValue("netAmount"),
          currencyList,
          currencyList?.find(
            (currency) => currency.id === form.getFieldValue("currency")
          ).currency
        );

        const matchType = calculateMatchType({
          thresholdValue: threshold,
          invoiceAmount: convertedInvoiceGBPAmount,
          POAmount: convertedRemainingGBPAmount,
          poNetAmount: convertedPONetAmount,
          fixedValue: fixedAmount,
        });
        if (matchType?.matchType === MATCH_TYPE.NOT_A_MATCH) {
          return Promise.reject(
            isPOSelected
              ? PO_ERROR_MSG.inSufficientAmount
              : PO_ERROR_MSG.inSufficientAmountForDetected
          );
        }

        setIsPOValid(true);
        return Promise.resolve();
      }
      return Promise.resolve();
    },
  });

  const setPOFieldDirty = () => {
    setIsPOSelected(true);
  };

  const onPOChange = (props) => {
    onChange(props);
  };

  const helperTextForPO = () => {
    const isPOChanged = selectedData?.id
      ? poNumberField !== selectedData.poNumber
      : true;
    if (poNumberField && closedPoError && isPOChanged) {
      return <div className="ant-form-item-explain-error">{closedPoError}</div>;
    }
    return null;
  };

  const getPOrules = () => {
    const rules = [validatePO];
    if ((isReviewTab && componentDisabled) || uploadContainerLoading) {
      return rules;
    }
    if (isPOFieldRequired) {
      rules.push(getRequiredRule(`a ${INVOICE_FORM.PO_NUMBER.label}`, true));
    }
    return rules;
  };

  return (
    <>
      <CommonSelect
        {...INVOICE_FORM.PO_NUMBER}
        options={approvedPOs}
        disabled={disabled}
        onChange={onPOChange}
        suffixIcon={dataPopulatedByAI?.poNumber ? "smart_toy" : ""}
        rules={getPOrules()}
        onSelect={setPOFieldDirty}
        help={helperTextForPO()}
      />
      <When condition={isMultiPurchaseOn}>
        <When condition={isPOValid}>
          <CustomAlert
            className={"mb-3"}
            message={INVOICE_FORM.PO_NUMBER.validPOMessage}
            icon={
              <Icon
                showIconOutline={true}
                iconName={"smart_toy"}
                className={"brand-color"}
              />
            }
            type={"success"}
            showIcon
          />
        </When>
      </When>
    </>
  );
}

export default PONumberInputField;
