import React, { memo, useState } from "react";
import { Button as AButton } from "antd";
import PropTypes from "prop-types";
import { constructClassName } from "../../../utils";
import "./customButtonStyle.scss";
import { ACCENTS, BUTTON_VARIANT, SIZE } from "../../../constants";

function CustomButton({
  text,
  className,
  showLoader,
  onClick,
  size,
  customSize = "",
  disabled,
  icon,
  loaderText,
  variant = BUTTON_VARIANT.BUTTON_FILLED,
  accent = ACCENTS.PRIMARY,
  ...rest
}) {
  const [loading, setLoading] = useState(false);

  const handleLoadingState = () => {
    setLoading(true);
    onClick()?.finally(() => setLoading(false));
  };
  const separator = BUTTON_VARIANT.LINK === variant ? " " : "-";
  return (
    <AButton
      className={constructClassName([
        `${variant}${separator}${accent}`,
        `${customSize}`,
        className,
      ])}
      onClick={showLoader ? handleLoadingState : onClick}
      size={size}
      disabled={disabled || loading}
      icon={icon}
      {...rest}
      loading={loading}
    >
      {loading && loaderText ? loaderText : text}
    </AButton>
  );
}

CustomButton.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  accent: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(CustomButton);
