import { apiClient } from "../redux/service/ApiConfig";
import { Base_URL, PATH } from "../redux/service/apiConstant";

export async function getAutomationListAPI(type, entity) {
  const tempResponse = await apiClient({
    url: `${PATH.invoice.invoiceSupplierList(type)}${
      entity ? `?entity_id=${entity}` : ""
    }`,
    method: "GET",
  });

  const response = {
    data: tempResponse?.data?.result || [],
  };
  return response.data;
}

export async function getAutomationTogglesAPI() {
  const tempResponse = await apiClient({
    url: `${PATH.smart_approval.automationToggleStatusList}`,
    method: "GET",
  });

  const response = {
    data: tempResponse?.data?.result || [],
  };
  return response.data;
}

export async function saveAutomationTogglesAPI(data) {
  const tempResponse = await apiClient({
    url: `${PATH.smart_approval.automationToggleStatusList}`,
    method: "POST",
    data,
  });

  const response = {
    data: tempResponse?.data?.result || [],
  };
  return response.data;
}

export async function getAutomationFieldValueAPI(apiPath) {
  const tempResponse = await apiClient({
    url: `${Base_URL}/${apiPath}`,
    method: "GET",
  });

  const response = {
    data: tempResponse?.data?.result || [],
  };
  return response.data;
}

export async function saveAutomationMapping(type, data) {
  return apiClient({
    url: PATH.smart_approval.saveAutomationMapping(type),
    method: "POST",
    data,
  });
}

export async function updateAutomationMapping(type, data) {
  return apiClient({
    url: PATH.smart_approval.saveAutomationMapping(type),
    method: "PUT",
    data,
  });
}

export async function getMappedAutomation(type, data) {
  const res = await apiClient({
    url: `${PATH.smart_approval.getMappedAutomation(type)}?statusCode=200`,
    method: "GET",
  });
  return res?.data?.result;
}

export async function deleteMappedAutomation(type, ids) {
  const res = await apiClient({
    url: PATH.smart_approval.getMappedAutomation(type),
    method: "DELETE",
    data: ids,
  });
  return res?.data?.result;
}

export async function updateMappedAutomation(type, data) {
  return apiClient({
    url: PATH.smart_approval.updateMappedAutomation(type),
    method: "POST",
    data,
  });
}

export async function getIntegrationCount(type, id) {
  const res = await apiClient({
    url: PATH.smart_approval.getIntegrationCount(type, id),
    method: "GET",
  });
  return res?.data?.result;
}

export async function getWorkFlowPreventCount({ checkBoxPrevent }) {
  const res = await apiClient({
    url: PATH.smart_approval.getWorkflowPreventCount({
      checkBoxPrevent: checkBoxPrevent ? 1 : 0,
    }),
    method: "GET",
  });
  return res?.data?.result?.invoiceCount;
}

export async function savePreventWorkFlowApi({ type, data }) {
  const tempResponse = await apiClient({
    url: `${PATH.smart_approval.savePreventWorkflow({ type })}`,
    method: "POST",
    data,
  });

  const response = {
    data: tempResponse?.data?.result || [],
  };
  return response.data;
}

export async function downloadWorkFlowMappingList({ type }) {
  const res = await apiClient({
    url: PATH.smart_approval.downloadMappingListExcel({ type }),
    method: "GET",
  });

  return res?.data?.result?.download_link;
}
