import { capitalizeFirst } from "../utils";
import { ACCENTS, BUTTON_VARIANT } from "./theme";

export const AUDIT_LOG_CONFIG = {
  AUDIT_LOG_BUTTON: {
    text: "View audit log",
    className: "m-v-16px",
    accent: ACCENTS.LINK,
    variant: BUTTON_VARIANT.LINK,
  },
  AUDIT_MODAL_TITLE: (type = "Purchase request") =>
    `${capitalizeFirst(type)} audit log`,
  AUDIT_LOG_COLUMN: [
    { label: "Date and time", key: "date", width: 16 },
    { label: "Actioning user", key: "user", ellipsis: true, width: 16 },
    { label: "Email", key: "email", ellipsis: true, width: 18 },
    { label: "Before", key: "before", width: 25 },
    { label: "After", key: "after", width: 25 },
  ],
};
