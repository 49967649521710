import { memo } from "react";
import {
  REDIRECT_YAPILY_WINDOW_NAME,
  YapilyIntegrationConfig,
} from "../../../../constants/Integration/OpenBanking";
import { openYapilyDesktopLogin } from "../../../../helper";
import {
  Box,
  Button,
  Column,
  ComponentLoader,
  CustomButton,
  Image,
  Row,
  TextTriggeredDrawer,
  Typography,
  When,
} from "../../../common";
import { ACCENTS, BUTTON_VARIANT } from "../../../../constants";

const { consentJourneyStepsConfig, consentJourneyHelperConfig } =
  YapilyIntegrationConfig;

const { buttonText, needHelpSection, dividerText } =
  consentJourneyStepsConfig[1];

function NeedHelpDrawer() {
  return (
    <TextTriggeredDrawer
      triggerText={needHelpSection.triggeredText}
      drawerTitle={needHelpSection.title}
    >
      <Row align={"center"} justify={"center"} gutter={[35, 35]}>
        {consentJourneyHelperConfig.map((data) => {
          const {
            text,
            key,
            imgConfig: { width, height, src },
          } = data;
          return (
            <Column sm={16} md={8} key={key}>
              <Box direction="column">
                <Image width={width} height={height} src={src} />
                <Typography variant={"secondary"} text={text} />
              </Box>
            </Column>
          );
        })}
      </Row>
    </TextTriggeredDrawer>
  );
}

function YapilyQROrDesktopAuthentication({
  stepData,
  currentStep,
  next,
  loaderText,
}) {
  const currentStepData = stepData[currentStep];

  function handleDesktopLogin() {
    openYapilyDesktopLogin(
      currentStepData?.authorisationUrl,
      REDIRECT_YAPILY_WINDOW_NAME
    );
    next(currentStepData);
  }

  if (!currentStepData)
    return (
      <Box rowGap={16} direction={"column"}>
        <ComponentLoader />
        <When condition={loaderText && loaderText !== ""}>
          <Typography variant={"body"} text={loaderText} />
        </When>
      </Box>
    );

  return (
    <>
      <Image width={160} height={160} src={currentStepData?.qrCodeUrl || ""} />
      <NeedHelpDrawer />
      <Typography variant={"secondary"} text={dividerText} />
      <CustomButton
        text={buttonText}
        accent={ACCENTS.PRIMARY}
        variant={BUTTON_VARIANT.BUTTON_FILLED}
        onClick={handleDesktopLogin}
        disabled={!currentStepData?.authorisationUrl}
        customSize={"large"}
      />
    </>
  );
}

function YapilyAuthentication(props) {
  return (
    <Box direction={"column"} className="yapily-qr-institution-login-container">
      <YapilyQROrDesktopAuthentication {...props} />
    </Box>
  );
}

export default memo(YapilyAuthentication);
