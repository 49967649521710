import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";

export function verifyCardOTP(userId, data) {
  return apiClient({
    url: PATH.auth.verifyCardOtp(userId),
    method: "POST",
    data,
  });
}

export async function requestOTP(email) {
  const response = await apiClient({
    url: PATH.auth.verifyEmail(email),
    method: "GET",
  });
  return response?.data?.result;
}
