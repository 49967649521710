import { useEffect, useState } from "react";
import { getCountry } from "../redux/action/auth";

export default function useCountries(label = "nickname", value = "nickname") {
  const [countries, setCountries] = useState([]);
  const [rawCountry, setRawCountry] = useState([]);

  useEffect(() => {
    getCountry().then((res) => {
      const data = res?.result?.map((country) => {
        return {
          label: country[label],
          value: country[value],
        };
      });
      setCountries(data || []);
      setRawCountry(res?.result);
    });
  }, [label, value]);

  return { countries, rawCountry };
}
