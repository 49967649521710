import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import { LOCAL_STORAGE_KEY, getItem } from "../utils";

export async function getThreshold() {
  const tempResponse = await apiClient({
    url: PATH.threshold.getThreshold(getItem(LOCAL_STORAGE_KEY.ORD_ID)),
    method: "GET",
  });
  return tempResponse?.data?.result?.data;
}

export async function saveThreshold(data) {
  return apiClient({
    url: PATH.threshold.saveThreshold,
    method: "POST",
    data,
  });
}
