import { Form } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Column,
  CommonDatePicker,
  CommonInput,
  CustomButton,
  Icon,
  Label,
  Modal,
  Row,
  Typography,
  When,
  CustomAlert,
} from "..";
import InvoicesList from "../../PaymentRuns/InvoicesList";
import {
  downloadPaymentFile,
  validateBacsDownloadPayment,
} from "../../../api/PaymentRunsAPI";
import { ACCENTS, BUTTON_VARIANT, ERROR_MSG } from "../../../constants";
import {
  CONFIGURATION_PAYMENT_FILE_FORM,
  SCHEDULE_PAYMENT_MODAL,
} from "../../../constants/PaymentRuns";
import { getSupplierDetails } from "../../../helper";
import {
  allowOnlyNumberOnInputChange,
  DEFAULT_FORMAT,
  disablePreviousDates,
  downloadFileByJS,
  getFormattedDate,
  convertJSObjectToFormData,
} from "../../../utils";

function ConfigurePaymentFileModal({
  open,
  onCancel,
  onConfirm,
  prDetails,
  invoiceDetails,
  suppliers,
  paymentRunFromSupplier,
  paymentScheduledType,
  showErrorMessage,
}) {
  const {
    title,
    subTitle,
    downloadButton,
    backButton,
    fileProcessingDate,
    sortCode,
    accountNumber,
  } = CONFIGURATION_PAYMENT_FILE_FORM;

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [step, setStep] = useState(1);
  const [step1ConfigData, setStep1ConfigData] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const modularAccount = useSelector(
    (state) => state && state?.modular && state?.modular?.accountDetail[0]
  );

  function handleSupplierSelected(index, value) {
    form1.setFieldsValue({
      [`supplierAccount${index}`]: null,
    });
  }

  async function onNextStep() {
    const values = await form1.validateFields();
    const supplierAccountDetails = invoiceDetails?.map(({ invoice }, index) => {
      const supplier_id = values[`supplier${index}`] || invoice?.supplier?.id;
      const supplier_account_id =
        values[`supplierAccount${index}`] ||
        invoice?.supplier_account_details_id;
      const { currentSupplier, currentSupplierCurrentAccountDetails } =
        getSupplierDetails({
          allSupplierDetails: suppliers?.result,
          selectedSupplier: supplier_id,
          selectedSupplierSelectedAccountDetails: supplier_account_id,
        });
      return {
        invoice_id: invoice?.id,
        supplier_id: supplier_id,
        supplier_name: currentSupplier?.name,
        supplier_accounts_details_id: supplier_account_id,
        supplier_account_name:
          currentSupplierCurrentAccountDetails?.account_name,
        amount: invoice.amount,
        invoice_number: invoice.invoice_number,
      };
    });
    let payload = {
      pr_name: prDetails?.name,
      payment_run_id: prDetails?.id,
      from_acc: modularAccount?.organization_account?.[0]?.accountNumber,
      modulr_account_id: modularAccount?.organization_account?.[0]?.id,
      payment_invoice_supplier_account: supplierAccountDetails,
    };

    try {
      const newPayment_invoice = supplierAccountDetails?.map(
        ({
          invoice_id,
          supplier_id,
          supplier_accounts_details_id,
          invoice_number,
        }) => {
          return {
            invoice_id,
            supplier_id,
            supplier_accounts_details_id,
            invoice_number,
          };
        }
      );

      const checkValidationStatus = await validateBacsDownloadPayment(
        convertJSObjectToFormData({
          payment_invoice_supplier_acount: JSON.stringify(newPayment_invoice),
        })
      );
      if (checkValidationStatus) {
        setStep1ConfigData(payload);
        setStep(2);
      } else {
        setErrorMessage(ERROR_MSG.common);
      }
    } catch (e) {
      setErrorMessage(e?.response?.data?.message || ERROR_MSG.common);
    }
  }

  async function onDownload() {
    const values = await form2.validateFields();
    let payload = {
      ...values,
      file_process_date: getFormattedDate(
        values?.file_process_date,
        DEFAULT_FORMAT
      ),
      ...step1ConfigData,
    };
    downloadPaymentFile(payload)
      .then((res) => {
        downloadFileByJS(res?.downloadLink, payload?.pr_name);
        onConfirm();
      })
      .finally(() => onCancel());
  }

  return (
    <Modal
      isOpen={open}
      hide={onCancel}
      width={700}
      showHeader={false}
      showCloseIcon={false}
    >
      <Box justifyContent="spaceBetween" alignItem="flexStart">
        <Box
          direction="row"
          rowGap="0px"
          columnGap="0px"
          justifyContent="spaceBetween"
          className="width-full"
        >
          <Typography
            className={"text-align-center "}
            variant="title"
            text={title}
          />
          <Icon
            iconName={"close"}
            onClick={onCancel}
            className="cursor-pointer"
          />
        </Box>

        <When condition={step === 1}>
          <Typography
            variant="secondary"
            className={"m-b-4px"}
            text={subTitle}
          />
          <Form
            form={form1}
            layout="vertical"
            autoComplete="off"
            className="w-100"
            scrollToFirstError
          >
            <Row span={24} className="w-100">
              <Column span={8}>
                <Label
                  text={SCHEDULE_PAYMENT_MODAL.INVOICE_NUMBER_LABEL}
                  optional={true}
                />
              </Column>
              <Column span={8}>
                <Label text={SCHEDULE_PAYMENT_MODAL.SUPPLIER_LABEL} />
              </Column>
              <Column span={8}>
                <Label text={SCHEDULE_PAYMENT_MODAL.SUPPLIER_ACCOUNT_LABEL} />
              </Column>
            </Row>
            {invoiceDetails?.map(({ invoice }, index) => {
              return (
                <InvoicesList
                  invoice={invoice}
                  index={index}
                  form={form1}
                  suppliers={suppliers}
                  prDetails={prDetails}
                  paymentRunFromSupplier={paymentRunFromSupplier}
                  handleSupplierSelected={handleSupplierSelected}
                  download
                />
              );
            })}
            <When condition={errorMessage}>
              <CustomAlert
                message={showErrorMessage(errorMessage)}
                type="error"
                className={"mb-3"}
              />
            </When>
            <Box className="m-t-20px" columnGap={25}>
              <CustomButton
                htmlType="submit"
                text={SCHEDULE_PAYMENT_MODAL.NEXT}
                accent={ACCENTS.PRIMARY}
                variant={BUTTON_VARIANT.BUTTON_FILLED}
                onClick={onNextStep}
                showLoader={true}
                className="w-30"
                customSize={"large"}
              />
              <CustomButton
                text={SCHEDULE_PAYMENT_MODAL.CANCEL_BTN_LABEL}
                accent={ACCENTS.SECONDARY}
                variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                onClick={() => onCancel()}
                className="w-30"
                customSize={"large"}
              />
            </Box>
          </Form>
        </When>
        <When condition={step === 2}>
          <Form
            name="configure_payment_file"
            layout="vertical"
            autoComplete="off"
            className="w-100"
            scrollToFirstError
            form={form2}
          >
            <CommonDatePicker
              {...fileProcessingDate}
              disabledDate={disablePreviousDates}
            />
            <CommonInput
              type="text"
              {...accountNumber}
              onInput={allowOnlyNumberOnInputChange}
            />

            <CommonInput
              type="text"
              {...sortCode}
              maxLength={6}
              onInput={allowOnlyNumberOnInputChange}
            />

            <Box direction={"row"} wrap="nowrap" className={"w-50 m-auto"}>
              <CustomButton
                text={downloadButton.label}
                accent={ACCENTS.PRIMARY}
                variant={BUTTON_VARIANT.BUTTON_FILLED}
                onClick={onDownload}
                className={"w-50"}
                showLoader={true}
                customSize={"large"}
              />
              <CustomButton
                text={backButton.label}
                accent={ACCENTS.SECONDARY}
                variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                onClick={() => setStep(1)}
                className={"w-50"}
                customSize={"large"}
              />
            </Box>
          </Form>
        </When>
      </Box>
    </Modal>
  );
}

export default ConfigurePaymentFileModal;
