export const WORKFLOW_FORM = {
  MODAL_TITLE_CREATE: "Create a workflow",
  MODAL_TITLE_EDIT: "Edit workflow",
  LABELS: {
    WF_NAME: "Workflow name",
    STEP_TYPE: "Step type",
    ADD_APPROVER_BTN: "Add approver",
    CREATE_WF_BTN: "Create workflow",
    UPDATE_WF_BTN: "Update workflow",
    ABANDON_WF_BTN: "Abandon workflow",
    CANCEL_WF_BTN: "Cancel",
    ADD_STEP_BTN: "Add step",
  },
  PLACEHOLDERS: {
    WF_NAME: "Enter workflow name",
    STEP_TYPE: "Select step type",
    APPROVER: "Select an approver",
    APPROVER_EDIT: "Unassigned approver",
    CUSTOM_FIELD: "Conditional field",
    CUSTOM_FIELD_PLACEHOLDER: "Select a custom expense field",
    CUSTOM_FIELD_VALUE: "Select a value",
    TEXT_BEFORE_CUSTOM_FIELD_VALUE: "when value is",
    TEXT_AFTER_CUSTOM_FIELD_VALUE: "and amount is over",
    AUTOMATIC_APPROVE: "Otherwise automatically approve",
    AMOUNT: "Enter an amount",
  },
  TOAST_MSG: {
    CREATE: "Workflow created",
    UPDATE: "Workflow updated",
  },
  WARNING_MSG_IM: (activeKey) =>
    `Making changes to this workflow will reset the approval journey of any ${activeKey} which are being reviewed by this workflow.`,
  WARNING_MSG_PO:
    "Making changes to this workflow will reset the approval journey of purchase order which are being reviewed by this workflow.",
  ROLE_USER_STEP_TYPE: "roleoruser",
  CONDITIONAL_THRESHOLD_STEP_TYPE: "conditionalthreshold",
  APPROVER_LABEL: "Approver",
  AUTO_APPROVER: "autoapprove",
  WARNING_LABEL: {
    po: "purchase orders",
    pr: "payment runs",
    cn: "credit note",
    invoices: "invoices",
  },
  AUTO_APPROVER_ID: {
    DEV: "f37f8404-53eb-4aa8-9cc5-9d1a7b3cc541_attribute",
    STAGE: "1e286468-0427-4fff-8f96-fc2a5501fe95_attribute",
  },
};
export const LAMBDA_API_ENDPOINTS = {
  dev: "/outsideapp/invoke-bulk-email",
  stage: "/outsideapp/invoke-po-api",
  platform: "/powerautomate/invoke-po-api",
  engage: "/outsideapp/invoke-po-api",
};

export const approvalPendingStatus = "Pending";

export const successResendEmailMsg = (isInvoice) =>
  `${isInvoice ? "Invoice" : "Purchase request"} approval email resent`;
