export const WORKFLOW_CONSTANT = {
  WORKFLOW_CREATED: "Workflow created successfully.",
  WORKFLOW_UPDATED: "Workflow updated successfully.",
  WORKFLOW_NAME: "Workflow name",
  WORKFLOW_NAME_PLACEHOLDER: "Enter workflow name",
  WORKFLOW_TYPE_PLACEHOLDER: "Select workflow type",
  WORKFLOW_APPROVER_DROPDOWN_PLACEHOLDER: "Select an approver",
  WORKFLOW_TYPE: "Workflow type",
  ADD_APPROVER_STEP: ` Add approver step`,
  MANAGE_WORKFLOW_LABEL: (workflowName) => `Manage ‘${workflowName}’ workflow`,
  NEW_WORKFLOW_LABEL: "Create new workflow",
  OVER: "over",
  UPTO: "up to",
  APPROVER: (index) => `approver${index}`,
  INVOICE_AMOUNT: (index) => `invoiceAmount${index}`,
  CREATE_WORKFLOW: "Create workflow",
  CONFIRM: "Confirm",
  CANCEL: "Cancel",
  DELETE: "Delete",
  APPROVER_LABEL: " approver",
  ROLES_LABEL: "Roles",
  USERS_LABEL: "Users",
  THRESHOLD_ERROR_MSG: "Please enter a threshold amount",
  WORKFLOW_NAME_ERROR: "Please enter a unique workflow name",
  APPROVER_ERROR: "Please select an approver",
  STEP: "Step",
  ABANDON_WORKFLOW: "Abandon workflow",
  ADD_STEP: "Add step",
};

export const NUMERICAL_SUFFIX = {
  1: "st",
  2: "nd",
  3: "rd",
  th: "th",
};

export const WORKFLOW_TYPE_INVOICE = {
  THRESHOLD: "Threshold",
  SHARED: "Shared",
  LINEAR: "Linear",
  CONTINUAL: "Continual",
};

export const OPERATORS = {
  LESS_THAN_EQUAL_TO: "<=",
  GREATER_THAN: ">",
};

export const INVOICE_MODAL_NOTES = {
  Continual:
    "The flow will start with the first approver in the approval chain who meet the threshold based on the invoice amount.",
  Threshold:
    "Determine the individual approver for an invoice, based on threshold values.",
  Linear:
    "This goes through everyone in the workflow regardless of invoice amount",
  Shared:
    "This will go to all users within the workflow and will require approval from all users, but in no order.",
};
