import { LOADER_START, LOADER_STOP } from "../service/types";

const initialState = false;
export default function loader(state = initialState, action) {
  switch (action.type) {
    case LOADER_START:
      return {
        ...state,
        menuList: action.payload?.scopes,
        userType: action.payload?.role,
      };
    case LOADER_STOP:
      return action.payload;
    default:
      return state;
  }
}
