import { Form } from "antd";
import { useEffect, useState } from "react";
import { createGRNI, getThreshold, updateGRNI } from "../../../api";
import { NOTIFICATION_TYPE, PO_GRNI_FORM_CONFIG } from "../../../constants";
import { useCurrencies } from "../../../hooks";
import {
  convertJSObjectToFormData,
  DEFAULT_FORMAT,
  getFormattedDate,
  getFormattedMoment,
  getLeastThresholdValue,
  GRNI_FORM_FIELD_RULES,
  negativeZeroAmountFormatterWithoutCurrency,
  PAYLOAD_DEFAULT_FORMAT,
  showNotification,
} from "../../../utils";
import CurrencySelect from "../../InvoiceManagement/components/PaidDataForm/CurrencySelect";
import CustomButton from "../CustomButton";
import DatePicker from "../DatePicker";
import Column from "../Grid/Column";
import Row from "../Grid/Row";
import CommonInput from "../Input";
import CommonInputNumber from "../Input/InputNumber";
import Label from "../Label";
import Modal from "../Modal";
import When from "../When";
import CustomAlert from "../CustomAlert";
import Switch from "../Switch";
import Typography from "../Typography";
import Box from "../Box";

const isGRNITypeCreate = (type) => type === "create";

function GRNIJourneyModal({
  open,
  onCancel,
  type,
  poData,
  GRNIData,
  onSuccessComplete,
  totalAmountObj,
}) {
  const { formData, createConfig, editConfig, closeBtn } = PO_GRNI_FORM_CONFIG;

  const { rawCurrencies } = useCurrencies();
  const [errorMsg, setErrorMsg] = useState("");
  const [threshold, setThreshold] = useState(null);
  const [isCorrection, setIsCorrection] = useState(false);
  const [form] = Form.useForm();

  const modalConfig = isGRNITypeCreate(type) ? createConfig : editConfig;

  const {
    date,
    description,
    amount: { label, currency, receivedAmount },
  } = formData(isCorrection);

  const initialFormValue = {
    [currency.name]: "",
    [date.name]: GRNIData?.date
      ? getFormattedMoment(GRNIData.date, DEFAULT_FORMAT)
      : "",
    [description.name]: GRNIData?.[description.name],
    [receivedAmount.name]: GRNIData?.[receivedAmount.name],
  };

  useEffect(() => {
    if (rawCurrencies?.result?.length)
      form.setFieldValue(
        currency.name,
        isGRNITypeCreate(type)
          ? poData?.currencyData?.id
          : GRNIData?.[currency.name]
      );
    if (!threshold) {
      getThreshold().then((res) => {
        setThreshold(res);
      });
    }
  }, [rawCurrencies]);

  const handleFormFinish = async () => {
    const formData = await form.validateFields();
    let payload = { ...formData };
    payload.is_correction = isCorrection ? 1 : 0;

    if (payload?.[date.name]) {
      payload[date.name] = getFormattedDate(
        payload[date.name],
        PAYLOAD_DEFAULT_FORMAT
      );
    }
    payload.purchase_order_id = poData.id;
    const operationPromise = isGRNITypeCreate(type)
      ? createGRNI({
          data: convertJSObjectToFormData(payload),
        })
      : updateGRNI({
          id: GRNIData.id,
          data: payload,
        });

    try {
      await operationPromise;
      showNotification(
        NOTIFICATION_TYPE.success,
        modalConfig.notification(isCorrection)
      );
      onSuccessComplete();
      onCancel();
    } catch (err) {
      setErrorMsg(err?.response?.data?.message);
    }
  };

  const getGRNIMaxAmount = () => {
    const poAmountToBeConsidered = Number(poData?.net_amount);
    const poAmountToBeConsideredWithThreshold =
      poAmountToBeConsidered +
      getLeastThresholdValue({
        fixedAmount: threshold?.fixed_value,
        threshold: threshold?.threshold_value,
        poNetAmount: poAmountToBeConsidered,
      });

    const maxValue =
      poAmountToBeConsideredWithThreshold -
      totalAmountObj.totalGRNIAmount -
      totalAmountObj.totalMatchedInvoiceAmount;

    return isCorrection ? -0.01 : maxValue;
  };

  const getGRNIMinAmount = () => {
    const minValue =
      totalAmountObj.totalGRNIAmount === 0
        ? 0
        : -totalAmountObj.totalGRNIAmount;
    return isCorrection ? -totalAmountObj.totalGRNIAmount : minValue;
  };

  return (
    <Modal
      width={550}
      title={modalConfig.title}
      isOpen={open}
      showDivider={false}
      hide={onCancel}
    >
      <Form
        form={form}
        initialValues={initialFormValue}
        className={"m-t-16px"}
        layout="vertical"
      >
        <When condition={errorMsg !== ""}>
          <CustomAlert message={errorMsg} type="error" className={"mb-3"} />
        </When>
        <Box
          justifyContent="flexStart"
          alignItem="center"
          alignContent="center"
          className="m-b-20px"
        >
          <Typography
            variant="body"
            text={PO_GRNI_FORM_CONFIG.correctionSwitchLabel}
            fontStyle="bold"
          />

          <Switch
            isFormItem={false}
            onChange={setIsCorrection}
            checked={isCorrection}
          />
        </Box>
        <DatePicker {...date} />
        <CommonInput {...description} isTextArea={true} />
        <Form.Item label={label ? <Label text={label} /> : null} colon={false}>
          <Row>
            <Column span={4}>
              <CurrencySelect {...currency} currencies={rawCurrencies} />
            </Column>
            <Column span={20}>
              <CommonInputNumber
                {...receivedAmount}
                rules={GRNI_FORM_FIELD_RULES.RECEIVED_AMOUNT({
                  currency: poData?.currencyData?.currency,
                  maxAmount: getGRNIMaxAmount(),
                  minAmount: getGRNIMinAmount(),
                })}
                formatter={negativeZeroAmountFormatterWithoutCurrency}
              />
            </Column>
          </Row>
        </Form.Item>
        <Row>
          <Column span={12}>
            <CustomButton
              {...modalConfig.button}
              text={modalConfig.button.text(isCorrection)}
              onClick={handleFormFinish}
            />
          </Column>
          <Column span={12}>
            <CustomButton {...closeBtn} onClick={onCancel} />
          </Column>
        </Row>
      </Form>
    </Modal>
  );
}

export default GRNIJourneyModal;
