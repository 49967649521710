import PropTypes from "prop-types";
import {
  isDevelopmentEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
} from "../../../utils/functions";
import { When } from "..";

function EnvFeatureFlag({
  children,
  enableForDev,
  enableForStage,
  enableForProd,
}) {
  const isDev = isDevelopmentEnvironment();
  const isStage = isStageEnvironment();
  const isProd = isPlatformEnvironment();

  return (
    <>
      <When
        condition={
          (enableForDev && isDev) ||
          (enableForStage && isStage) ||
          (enableForProd && isProd)
        }
      >
        {children}
      </When>
    </>
  );
}

EnvFeatureFlag.propTypes = {
  enableForDev: PropTypes.bool,
  enableForStage: PropTypes.bool,
  enableForProd: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};

EnvFeatureFlag.defaultProps = {
  enableForDev: false,
  enableForStage: false,
  enableForProd: false,
  children: <></>,
};

export default EnvFeatureFlag;
