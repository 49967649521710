import React, { createContext, useContext, useState } from "react";
import { MODAL_TYPES } from "../../../utils/constant";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import DeleteWorkFlowConfirmationModal from "./DeleteWorkFlowConfirmationModal";
import OTPModal from "./OTPModal";
import ViewWorkFlowModal from "./ViewSelectedWorkflowModal";
import WorkFlowModal from "../../WorkFlowModal";
import AssignWorkFlowModal from "./AssignToWorkflowConfirmationModal";
import PaymentJourneyModal from "./PaymentJourneyModal";
import DOBModal from "./DOBModal";
import RejectionModal from "./rejectionModal";
import SupplierAccountsViewModal from "./SupplierAccountsViewModal";
import AuditModal from "../../Cards/component/AuditModal";
import ConfigurePaymentFileModal from "./ConfigurePaymentFileModal";
import GRNIJourneyModal from "./GRNIJourneyModal";
import SendSupplierModal from "../../PurchaseOrder/SendSupplierModal";
import ItemListModal from "./ItemListModal";
import InvoiceMatchingModal from "../../InvoiceManagement/components/InvoiceMatchingModal/InvoiceMatchingModal";
import ConfirmMatchingConfirmationModal from "./ConfirmMatchingConfirmationModal";
import UploadedAttachmentsModal from "../../PurchaseOrder/ViewUploadedAttachments/index";
import ConfigureIntegrationModal from "../../ERP/ConfigureIntegration/index";
import DraftConfirmationModal from "../../InvoiceManagement/Drawer/CreateInvoiceDrawer/DraftConfirmationModal";
import TextBasedConfirmationModal from "./TextBasedConfirmationModal";

const MODAL_COMPONENTS = {
  [MODAL_TYPES.OTP_MODAL]: OTPModal,
  [MODAL_TYPES.DELETE_CONFIRMATION_MODAL]: DeleteConfirmationModal,
  [MODAL_TYPES.DELETE_WORKFLOW_CONFIRMATION_MODAL]:
    DeleteWorkFlowConfirmationModal,
  [MODAL_TYPES.VIEW_SELECTED_MODAL]: ViewWorkFlowModal,
  [MODAL_TYPES.WORKFLOW_MODAL]: WorkFlowModal,
  [MODAL_TYPES.ASSIGN_WORKFLOW_MODAL]: AssignWorkFlowModal,
  [MODAL_TYPES.PAYMENT_JOURNEY_MODAL]: PaymentJourneyModal,
  [MODAL_TYPES.USER_DELETE_MODAL]: DeleteConfirmationModal,
  [MODAL_TYPES.DOB_MODAL]: DOBModal,
  [MODAL_TYPES.REJECTION_MODAL]: RejectionModal,
  [MODAL_TYPES.SUPPLIER_ACCOUNTS_VIEW_MODAL]: SupplierAccountsViewModal,
  [MODAL_TYPES.PR_CONFIGURE_PAYMENT_FILE]: ConfigurePaymentFileModal,
  [MODAL_TYPES.AUDIT_MODAL]: AuditModal,
  [MODAL_TYPES.GRNI_JOURNEY_MODAL]: GRNIJourneyModal,
  [MODAL_TYPES.SEND_SUPPLIER_MODAL]: SendSupplierModal,
  [MODAL_TYPES.ITEM_LIST_MODAL]: ItemListModal,
  [MODAL_TYPES.INVOICE_MATCHING_MODAL]: InvoiceMatchingModal,
  [MODAL_TYPES.CONFIRM_MATCHING_CONFIRMATION_MODAL]:
    ConfirmMatchingConfirmationModal,
  [MODAL_TYPES.UPLOADED_ATTACHMENTS_MODAL]: UploadedAttachmentsModal,
  [MODAL_TYPES.CONFIGURE_INTEGRATION_MODAL]: ConfigureIntegrationModal,
  [MODAL_TYPES.PO_SELECTED_DRAFT]: DraftConfirmationModal,
  [MODAL_TYPES.TEXT_BASED_CONFIRMATION_MODAL]: TextBasedConfirmationModal,
};

const initialState = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
};

const GlobalModalContext = createContext(initialState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal = ({ children }) => {
  const [store, setStore] = useState();
  const { modalType, modalProps } = store || {};

  const showModal = (modalType, modalProps) => {
    setStore({
      ...store,
      isOpen: true,
      modalType,
      modalProps,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      isOpen: false,
      modalType: null,
      modalProps: {},
    });
  };

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];
    if (!modalType || !ModalComponent || !store.isOpen) {
      return null;
    }
    return (
      <ModalComponent
        id="global-modal"
        open={store.isOpen}
        onCancel={hideModal}
        {...modalProps}
      />
    );
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
