import React from "react";
import { Column, CustomButton, Row, When } from "../../../common";
import {
  BUTTON_CONFIG_TYPE,
  INVOICE_FORM,
  INVOICE_MANAGEMENT_TAB_KEYS,
} from "../../../../constants";

function InvoiceFooterButton(props) {
  const {
    handleDraftCreate,
    uploadButtonDisabled,
    componentDisabled,
    handleCreatePayment,
    SubmitButtonDisable,
    activeKey,
    selectedData,
    isNewForm,
  } = props;

  const { SUBMIT_INVOICE_BUTTON, SAVE_AS_DRAFT_BUTTON } = INVOICE_FORM;
  const { displayMode, editMode } = BUTTON_CONFIG_TYPE;

  const DraftButton = () => {
    return (
      <CustomButton
        {...SAVE_AS_DRAFT_BUTTON}
        onClick={() => {
          handleDraftCreate();
        }}
        disabled={uploadButtonDisabled}
      />
    );
  };

  const SubmitInvoiceButton = () => {
    return (
      <CustomButton
        {...SUBMIT_INVOICE_BUTTON}
        onClick={() => {
          handleCreatePayment({ isDraft: false });
        }}
        disabled={SubmitButtonDisable}
      />
    );
  };

  const displayButtonFunc = () => {
    const buttonArray = [
      {
        key: "submitInvoice",
        tab: [
          INVOICE_MANAGEMENT_TAB_KEYS.DRAFT,
          INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED,
        ],
        component: SubmitInvoiceButton,
        [editMode]: true,
        [displayMode]: false,
      },
      {
        key: "draft",
        tab: [
          INVOICE_MANAGEMENT_TAB_KEYS.DRAFT,
          INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED,
        ],
        component: DraftButton,
        [displayMode]: false,
        [editMode]: true,
      },
    ];

    let checkKeyName =
      componentDisabled && selectedData ? displayMode : editMode;

    return buttonArray.filter((bItem) => {
      return (
        bItem[checkKeyName] && (bItem.tab.indexOf(activeKey) >= 0 || isNewForm)
      );
    });
  };

  const checkDisplayButtonLength = displayButtonFunc().length;

  return (
    <div className="pay-and-schedule-btn">
      <When condition={checkDisplayButtonLength}>
        <Row>
          {displayButtonFunc().map((item, index) => {
            return (
              <Column
                span={
                  index === checkDisplayButtonLength - 1 &&
                  checkDisplayButtonLength % 2 !== 0
                    ? 24
                    : 12
                }
              >
                {item.component()}
              </Column>
            );
          })}
        </Row>
      </When>
    </div>
  );
}

export default InvoiceFooterButton;
