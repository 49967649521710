import Search from "antd/lib/transfer/search";
import { memo } from "react";
import { ReactComponent as Search_icon } from "../../../assets/images/search_icon.svg";
import { constructClassName } from "../../../utils";
import "./SearchInput.scss";

function SearchInput({
  placeholder,
  enterButtonText = "Search",
  onChange,
  handleClear,
  value,
  prefixCls,
  customSize = "",
}) {
  return (
    <Search
      prefixCls={constructClassName([
        "kloo-search-input kloo-input",
        customSize,
        prefixCls,
      ])}
      placeholder={placeholder}
      enterButton={enterButtonText}
      prefix={Search_icon}
      onChange={onChange}
      handleClear={handleClear}
      value={value}
    />
  );
}

export default memo(SearchInput);
