import {
  Box,
  Checkbox,
  DatePicker,
  InputRange,
  Popover,
  Radio,
  TextField,
  Typography,
  When,
} from "..";
import { Typography as ATypography } from "antd";
import { FixedSizeList as List } from "react-window";
import "./filter.scss";
import { useEffect, useState } from "react";
import { constructClassName } from "../../../utils";
import FilterSliderRange from "../FilterSliderRange";

const doesArrayIncludes = (data, key) => {
  return data.findIndex((item) => item === key) > -1;
};

export default function FilterListItem(props) {
  const {
    item,
    selected,
    onSelectedChange,
    variables,
    addFilterOpen,
    getLastRemovedKidId,
  } = props;
  const {
    key,
    children,
    type,
    label,
    enableSearch,
    enableSingleSelection = false,
  } = item;

  const [search, setSearch] = useState("");
  const [visibleChildren, setVisibleChildren] = useState(children || []);

  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // commented to not clear search on selection
  // useEffect(() => {
  //   setSearch("");
  // }, [selected, item]);

  const handleSelection = ({ kid }) => {
    const kidId = kid.key;
    if (enableSingleSelection) {
      onSelectedChange([kidId]);
    } else {
      if (doesArrayIncludes(selected, kidId)) {
        const filteredData = selected.filter(
          (selectedItem) => selectedItem !== kidId
        );
        getLastRemovedKidId && getLastRemovedKidId(kidId);
        onSelectedChange(filteredData);
      } else {
        onSelectedChange([...selected, kidId]);
      }
    }
  };

  const handleDateSelection = (dateJS, dateString) => {
    let selectedWithDateValue = [];
    if (dateString?.join("").length) {
      selectedWithDateValue = [
        ...selected.filter((option) => !option.includes(key)),
        `${key}-${dateString}`,
      ];
    } else {
      selectedWithDateValue = selected.filter(
        (option) => !option.includes(key)
      );
    }
    onSelectedChange(selectedWithDateValue);
  };

  useEffect(() => {
    if (!addFilterOpen) {
      setSearch("");
      setTimeout(() => {
        setVisibleChildren(children);
      }, 50);
    } else {
      setVisibleChildren(
        children?.filter((kid) =>
          kid?.label?.toLowerCase().includes(search?.toLowerCase())
        )
      );
    }
  }, [children, search, addFilterOpen]);

  const SubMenu = ({ index, style }) => {
    const kid = visibleChildren[index];
    return (
      <div
        className={constructClassName(["p-v-5px", "p-h-12px", "kid-item-body"])}
        onClick={() => handleSelection({ kid })}
        style={style}
      >
        <Box
          justifyContent="flexStart"
          alignItem="center"
          className={constructClassName(["w-100", "kid-item-cnt-child"])}
        >
          <When condition={enableSingleSelection}>
            <Radio
              className="kid-checkbox"
              checked={selected.includes(kid?.key)}
            />
          </When>
          <When condition={!enableSingleSelection}>
            <Checkbox
              className="kid-checkbox"
              checked={selected.includes(kid?.key)}
            />
          </When>
          <ATypography.Text
            style={{ maxWidth: 250 }}
            ellipsis={{ tooltip: kid?.label }}
          >
            {kid?.label}
          </ATypography.Text>
        </Box>
      </div>
    );
  };

  const kidsList = (
    <div>
      <When condition={enableSearch}>
        <div className={constructClassName(["p-v-5px", "p-h-12px"])}>
          <div className={constructClassName(["w-100", "kid-item-cnt-child"])}>
            <TextField value={search} onChange={setSearch} />
          </div>
        </div>
      </When>
      <div className="menu-submenu m-t-10px">
        <List
          height={300}
          itemCount={visibleChildren.length}
          itemSize={30}
          width={330}
        >
          {SubMenu}
        </List>
      </div>
    </div>
  );

  const kidsRange = (
    <div onClick={preventDefaults}>
      <div
        justifyContent="spaceBetween"
        alignItem="center"
        className={constructClassName([
          "w-100",
          "h-auto",
          "kid-item-cnt-child",
          "p-v-5px",
          "p-h-12px",
        ])}
      >
        <When condition={key === "remaining_amount"}>
          <FilterSliderRange
            label={key}
            selected={selected}
            setSelected={onSelectedChange}
          />
        </When>
        <When condition={key !== "remaining_amount"}>
          <InputRange
            label={key}
            selected={selected}
            setSelected={onSelectedChange}
          />
        </When>
      </div>
    </div>
  );

  const kidsDate = (
    <div onClick={preventDefaults}>
      <Box
        justifyContent="spaceBetween"
        alignItem="center"
        className={constructClassName([
          "w-100",
          "kid-item-cnt-child",
          "p-v-5px",
          "p-h-12px",
        ])}
      >
        <DatePicker
          allowClear={false}
          withRange={true}
          defaultRangeValue={[]}
          onChange={handleDateSelection}
        />
      </Box>
    </div>
  );

  const kids = (() => {
    switch (type) {
      case "date":
        return kidsDate;
      case "range":
        return kidsRange;
      default:
        return kidsList;
    }
  })();

  return (
    <Popover
      overlayClassName="kloo-multi-level-filter-popup filter-multi p-l-0px"
      placement="rightTop"
      content={kids}
      trigger="click"
      showArrow={false}
    >
      {/* dont remove this, this div holds a refernce */}
      <div
        className={constructClassName(["p-v-5px", "p-h-12px", "kid-item-body"])}
      >
        <Box
          justifyContent="spaceBetween"
          alignItem="center"
          className={constructClassName(["w-100", "kid-item-cnt"])}
        >
          <Box>
            <div
              className={
                selected
                  ?.map((selectedItem) => selectedItem.split("-")[0])
                  .includes(key)
                  ? " dot active"
                  : "dot inactive"
              }
            />
            <Typography
              className={constructClassName(["p-l-12px", "kid-item"])}
            >
              {label}
            </Typography>
          </Box>
          <i className="material-icons filter-icon">arrow_right</i>
        </Box>
      </div>
    </Popover>
  );
}
