import { Grid } from "antd";

const { useBreakpoint } = Grid;

const useMobile = () => {
  const screens = useBreakpoint();

  return {
    isMobile: !screens?.sm,
    isDesktop: screens?.lg,
  };
};

export default useMobile;
