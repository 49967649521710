import { localStoragePostLoginHandler } from "../helper/Auth";
import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";

export function refreshToken(data) {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.refresh_login,
      data: data,
    })
      .then((response) => {
        if (response?.data?.statusCode === 201) {
          resolve(response?.data?.result[0] || {});
          localStoragePostLoginHandler(response?.data?.result[0]);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}

export async function loginUser({ data, SSO = false }) {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: SSO ? PATH.auth.sso_login : PATH.auth.login,
      data: data,
    })
      .then((response) => {
        if (response?.data?.statusCode === 201) {
          resolve(response?.data?.result[0] || {});
          localStoragePostLoginHandler(response?.data?.result[0]);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}

export async function lastLoginUpdate(userOrgId) {
  const tempResponse = await apiClient({
    method: "PUT",
    url: PATH.auth.lastLoginUpdate(userOrgId),
  });
  return tempResponse.data;
}

export async function getOTPRequirement() {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.auth.otpRequirement,
  });
  return tempResponse?.data?.result?.otp_screen;
}

export async function authenticateLoginOtp(data, userId) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.auth.verifyOtp(userId),
    data,
  });
  return tempResponse?.data;
}

export async function verifyEmailAddress(email) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.auth.verifyEmail(email),
  });
  return tempResponse?.data;
}

export async function checkSSOEnabled(data) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.auth.checkSSOEnabled,
    data,
  });
  return tempResponse?.data?.result;
}
