import { Box, Typography } from "../../../common";

function TextDisplay({ text, title }) {
  if (text) {
    return (
      <Box
        direction="column"
        alignContent="flexStart"
        justifyContent="flexStart"
        alignItem="flexStart"
        rowGap={0}
      >
        <Typography variant={"secondary"} text={title} />
        <Typography variant={"secondary"} fontStyle="semibold" text={text} />
      </Box>
    );
  }
}

export default TextDisplay;
