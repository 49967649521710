import React from "react";
import {
  Box,
  Column,
  CommonSelect,
  Icon,
  Image,
  Label,
  Row,
  When,
} from "../../../common";
import {
  IM_CREATE_INVOICE_FORM,
  INVOICE_POPULATED_USING_AI,
  TRACKING_CATEGORY_KEY_PREFIX,
} from "../../../../constants";
import CommonToolTip from "../../../common/Tooltip";

export default function TrackingCategoriesField({
  logo,
  isDisabledOnEdit,
  trackingCategories,
  dataPopulatedByAI,
  inputChangedFromAiValue,
}) {
  const trackingCategorieskeys = Object.keys(dataPopulatedByAI).filter((key) =>
    key.startsWith(TRACKING_CATEGORY_KEY_PREFIX)
  );
  return (
    <>
      <Box className="w-100 p-b-8px" justifyContent="flexStart">
        <Label
          text={IM_CREATE_INVOICE_FORM.trackingCategories.labelText}
          suffixIcon="info"
          suffixIconTooltip={
            IM_CREATE_INVOICE_FORM.trackingCategories.labelText
          }
          optional={true}
        />
        <When condition={trackingCategorieskeys?.length}>
          <CommonToolTip title={INVOICE_POPULATED_USING_AI}>
            <Icon
              iconName={"smart_toy"}
              className={"brand-color ms-5"}
              showIconOutline={true}
            />
          </CommonToolTip>
        </When>
      </Box>
      {Object.keys(trackingCategories).map((key) => (
        <Row>
          <Column
            span={3}
            className="d-flex h-40px justify-content-center align-items-center prefix-icon"
          >
            <Image src={logo} width={30} />
          </Column>
          <Column span={21} className="ps-0">
            <CommonSelect
              name={`${TRACKING_CATEGORY_KEY_PREFIX}${key}`}
              placeholder={`${key}`}
              disabled={isDisabledOnEdit}
              options={trackingCategories[key]}
              onChange={() =>
                inputChangedFromAiValue(`${TRACKING_CATEGORY_KEY_PREFIX}${key}`)
              }
            />
          </Column>
        </Row>
      ))}
    </>
  );
}
