import { SET_NOTIFICATION, SET_NOTIFICATIONS } from "../service/types";

const initialState = {
  allNotification: [],
  currentNotification: null,
};
export default function notificationsReducer(state = initialState, action) {
  const allNotification = [...state.allNotification];
  switch (action.type) {
    case SET_NOTIFICATION:
      allNotification.push(action.payload);
      return { ...state, allNotification, currentNotification: action.payload };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        allNotification: action.payload,
        currentNotification: null,
      };
    default:
      return state;
  }
}
