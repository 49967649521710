import { Avatar } from "antd";
import React from "react";

const CommonAvatar = ({
  margin = "0 3px",
  className,
  color = "#fff",
  title = "",
  backgroundColor = `#${Math.floor(Math?.random() * 16777215)?.toString(16)}`,
  size = "small",
  gap,
}) => {
  let matches = title?.match(/\b(\w)/g);
  let acronym = matches?.join("")?.toUpperCase();
  return title ? (
    <Avatar
      style={{
        backgroundColor: backgroundColor,
        verticalAlign: "middle",
        color: color,
        margin: margin,
      }}
      size={size}
      gap={gap}
      className={className}
    >
      {acronym}
    </Avatar>
  ) : (
    ""
  );
};

export default React.memo(CommonAvatar);
