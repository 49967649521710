import React from "react";
import { Box, CustomButton, Divider, Typography, When } from "../../../common";
import { LINE_ITEMS, LINE_ITEM_FOOTER } from "../../../../constants/LineItems";
import {
  getLineItemAmountFields,
  getNetAmount,
} from "../../../../helper/LineItems";

const LineItemsFooter = ({
  onSave,
  onCancel,
  lineItemsTableData,
  disabled,
  currency,
  initialTotalAmount,
}) => {
  if (disabled) {
    return null;
  }
  const TOTAL_AMOUNT = lineItemsTableData?.reduce((sum, item) => {
    return sum + getNetAmount(item, true);
  }, 0);
  const TOTAL_AMOUNT_CHANGED =
    Number(initialTotalAmount)?.toFixed(2) !== Number(TOTAL_AMOUNT)?.toFixed(2);

  const containerStyle = { position: "absolute", bottom: 0, width: "100%" };
  return (
    <div style={containerStyle}>
      <Divider className={"m-0px"} />
      <Box justifyContent="spaceBetween" className="p-3 gray-bg" columnGap={20}>
        <Box
          direction={`${disabled ? "row" : "column"}`}
          alignItem="flex-start"
          columnGap={disabled ? 6 : 0}
          className={`${disabled ? "w-100" : "w-50"}`}
          justifyContent="flexStart"
          alignContent="flexStart"
        >
          <When
            condition={!TOTAL_AMOUNT_CHANGED || !Number(initialTotalAmount)}
          >
            <Box className="d-inline-flex">
              <Typography
                className="d-inline-block"
                variant="body"
                text={
                  <>
                    {LINE_ITEMS.SUMMARY}
                    <Typography
                      className="d-inline-block"
                      variant="body"
                      fontStyle="semibold"
                      text={getLineItemAmountFields(
                        TOTAL_AMOUNT || 0,
                        currency
                      )}
                    />
                  </>
                }
              />
            </Box>
          </When>
          <When condition={Number(initialTotalAmount) && TOTAL_AMOUNT_CHANGED}>
            <Box>
              <Typography
                variant="body"
                className="d-inline-block"
                text={
                  <>
                    {LINE_ITEMS.SUMMARY_CHANGED}
                    <Box className="d-inline-flex" columnGap={6}>
                      <Typography
                        variant="body"
                        fontStyle="semibold"
                        className="d-inline-block"
                        text={getLineItemAmountFields(
                          initialTotalAmount || 0,
                          currency
                        )}
                      />
                      <Typography
                        variant="body"
                        text={LINE_ITEMS.TO}
                        className="d-inline-block"
                      />
                      <Typography
                        variant="body"
                        fontStyle="semibold"
                        className="d-inline-block"
                        text={getLineItemAmountFields(
                          TOTAL_AMOUNT || 0,
                          currency
                        )}
                      />
                    </Box>
                  </>
                }
              ></Typography>
            </Box>
          </When>
        </Box>
        <When condition={!disabled}>
          <Box className="w-40" justifyContent="spaceBetween">
            <CustomButton {...LINE_ITEM_FOOTER.SAVE} onClick={onSave} />
            <CustomButton {...LINE_ITEM_FOOTER.CANCEL} onClick={onCancel} />
          </Box>
        </When>
      </Box>
    </div>
  );
};

export default LineItemsFooter;
