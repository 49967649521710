import { PERMISSIONS } from "../Permissions";
import { LC_SUPPLIERS_PLACEHOLDER, SUPPLIERS_PLACEHOLDER } from "../dynamic";
import { ACCENTS, BUTTON_VARIANT } from "../theme";

export const SMART_APPROVALS_CONFIG = {
  pageHeader: {
    header: "Smart approvals",
  },
  searchField: {
    placeholder: "Search",
  },
};

export const SMART_APPROVALS_TABS_KEYS = {
  PURCHASE_REQUEST: "po",
  INVOICES: "invoices",
  PAYMENT_RUNS: "pr",
  CREDIT_NOTES: "cn",
  CARDS: "configure",
  SUPPLIER: "supplier",
  HISTORY: "history",
};

export const SHOW_SEARCH_FIELD_TAB_CONFIG = [
  SMART_APPROVALS_TABS_KEYS.INVOICES,
  SMART_APPROVALS_TABS_KEYS.PURCHASE_REQUEST,
  SMART_APPROVALS_TABS_KEYS.PAYMENT_RUNS,
  SMART_APPROVALS_TABS_KEYS.CREDIT_NOTES,
  SMART_APPROVALS_TABS_KEYS.HISTORY,
];

export const SMART_APPROVALS_TABS_PERMISSION_CONFIG = {
  [SMART_APPROVALS_TABS_KEYS.PURCHASE_REQUEST]: {
    parentKey: PERMISSIONS.SETTING.key,
    permissionKey: PERMISSIONS.SETTING.SMART_APPROVAL.PURCHASE_ORDER.read,
  },
  [SMART_APPROVALS_TABS_KEYS.INVOICES]: {
    parentKey: PERMISSIONS.SETTING.key,
    permissionKey: PERMISSIONS.SETTING.SMART_APPROVAL.INVOICE.read,
  },
  [SMART_APPROVALS_TABS_KEYS.PAYMENT_RUNS]: {
    parentKey: PERMISSIONS.SETTING.key,
    permissionKey: PERMISSIONS.SETTING.SMART_APPROVAL.PAYMENT_RUN.read,
  },
  [SMART_APPROVALS_TABS_KEYS.CREDIT_NOTES]: {
    parentKey: PERMISSIONS.SETTING.key,
    permissionKey: PERMISSIONS.SETTING.SMART_APPROVAL.CREDIT_NOTES.read,
  },
  [SMART_APPROVALS_TABS_KEYS.CARDS]: {
    parentKey: PERMISSIONS.SETTING.key,
    permissionKey: PERMISSIONS.SETTING.SMART_APPROVAL.CARDS.read,
  },
  [SMART_APPROVALS_TABS_KEYS.SUPPLIER]: {
    parentKey: PERMISSIONS.SETTING.key,
    permissionKey: PERMISSIONS.SETTING.SMART_APPROVAL.SUPPLIER.read,
  },
  [SMART_APPROVALS_TABS_KEYS.HISTORY]: {
    parentKey: PERMISSIONS.SETTING.key,
    permissionKey: PERMISSIONS.SETTING.SMART_APPROVAL.HISTORY.read,
  },
};

export const SMART_APPROVALS_TABS = [
  {
    label: "Purchase requests",
    key: SMART_APPROVALS_TABS_KEYS.PURCHASE_REQUEST,
  },
  {
    label: "Invoices",
    key: SMART_APPROVALS_TABS_KEYS.INVOICES,
  },
  {
    label: "Payment runs",
    key: SMART_APPROVALS_TABS_KEYS.PAYMENT_RUNS,
  },
  {
    label: "Credit notes",
    key: SMART_APPROVALS_TABS_KEYS.CREDIT_NOTES,
  },
  {
    label: "Cards",
    key: SMART_APPROVALS_TABS_KEYS.CARDS,
  },
  {
    label: `${SUPPLIERS_PLACEHOLDER}`,
    key: SMART_APPROVALS_TABS_KEYS.SUPPLIER,
  },
  {
    label: "History",
    key: SMART_APPROVALS_TABS_KEYS.HISTORY,
  },
];

export const SMART_APPROVAL_INVOICE_CONFIG = {
  SCHEDULE_PAY_CANCEL: {
    TITLE: {
      text: "Schedule, pay and cancel invoices",
      fontSize: 20,
      variant: "title",
      responsiveFontSize: false,
      className: "m-v-10px",
    },
    ALERT: {
      banner: true,
      type: "info",
      className: "m-b-8px banner-user-approve",
      message:
        "All organization admins and finance approvers can mark an invoice as paid.",
    },
    USER_DROPDOWN: {
      clearIcon: true,
      placeholder: "Select an approver",
      newSelect: true,
    },
    CTA_BUTTON: {
      SAVE: {
        text: "Save",
        accent: ACCENTS.PRIMARY,
        variant: BUTTON_VARIANT.BUTTON_FILLED,
        customSize: "large",
        className: "w-100",
      },
      CANCEL: {
        text: "Cancel",
        accent: ACCENTS.SECONDARY,
        variant: BUTTON_VARIANT.BUTTON_HOLLOW,
        customSize: "large",
        className: "w-100",
      },
    },
  },
};

export const SMART_APPROVAL_CARDS_CONFIG = {
  TITLE: {
    fontSize: 20,
    variant: "title",
    responsiveFontSize: false,
    className: "m-v-10px",
  },
  CTA_BUTTON: {
    SAVE: {
      text: "Save",
      accent: ACCENTS.PRIMARY,
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      customSize: "large",
    },
    CANCEL: {
      text: "Cancel",
      accent: ACCENTS.SECONDARY,
      variant: BUTTON_VARIANT.BUTTON_HOLLOW,
      customSize: "large",
    },
  },
};

export const SMART_APPROVAL_PERMISSION_KEYS = {
  PURCHASE_REQUEST: {
    CREATOR: "purchase-request-creators",
  },
  INVOICES: {
    PAY_NOW: "account-payable-paynow",
  },
};

export const SMART_APPROVAL_TAB_WISE_NOTIFICATIONS_CONFIG = {
  PURCHASE_REQUEST: {
    CREATOR: {
      ADDED: "User successfully added as a purchase request creator",
      DELETED: "User successfully removed as a purchase request creator",
    },
  },
};

export const INVOICE_AUTOMATION = {
  title: "Workflow assignment automation",
  subtitle:
    "Determine which field should be used to trigger the automation for assigning invoices to a workflow.",
};

export const AUTOMATION_CONSTANT = {
  DISABLED_INFO_TOOLTIP:
    "Please delete all current workflow mappings to select a new trigger field.",
  WORKFLOW_COLUMN: "Workflow",
  BTN_LABELS: {
    ADD_MAPPING: "Add mapping",
    MANAGE_MAPPING: "Manage mapping",
    SAVE: "Save",
    LOADING_SAVE: "Saving...",
    DELETE: "Delete",
    LOADING_DELETE: "Deleting...",
    CANCEL: "Cancel",
    ADD: "Add",
  },
  FIELD_SELECT_PLACEHOLDER: "Search or select field",
};

export const REMAINING_OPTION = {
  label: "Assign remaining values to common workflow",
  value: "remaining_values",
};

export const AUTOMATION_TITLES = {
  invoices: {
    title: "Workflow assignment automation",
    subtitle:
      "Determine which field should be used to trigger the automation for assigning invoices to a workflow.",
  },
  po: {
    title: "Workflow assignment automation",
    subtitle:
      "Determine which field should be used to trigger the automation for assigning purchase orders to a workflow.",
  },
};

export const AUTOMATION_TITLES_2 = {
  invoices: {
    title: "Workflow assignment automation",
    subtitle:
      "Determine which field should be used to trigger the automation for assigning invoices to a workflow.",
  },
  po: {
    title: "Workflow assignment automation",
    subtitle:
      "Determine which field should be used to trigger the automation for assigning purchase requests to a workflow.",
  },
};

export const INTEGRATION_TYPE = {
  invoices: "invoice",
  po: "po",
};

export const SMART_APPROVAL_SUPPLIER_CONFIG = {
  CREATE_EDIT_SUPPLIER: {
    TITLE: {
      text: `Manage ${LC_SUPPLIERS_PLACEHOLDER}`,
      variant: "title",
      responsiveFontSize: false,
      fontSize: 24,
      className: "p-t-24px p-b-16px",
    },
    SUB_TITLE: {
      text: `In addition to Organisation Admins, determine Finance Approvers who can create, edit, and delete ${LC_SUPPLIERS_PLACEHOLDER}.`,
      variant: "secondary",
      className: "p-b-24px",
    },
  },
};

export const WORKFLOW_APPROVER_USERS_LABEL = "Users";

export const PREVENT_ASSIGNMENT_WORKFLOW = {
  text: "Prevent assignment of invoices to workflows without a confirmed match status",
  tootTip: {
    iconName: "info",
    text: "When selected, invoices will require a confirmed match status before automated or manual assignment to workflow is permitted. ",
  },
};

export const WORKFLOW_ALERT_MESSAGE = {
  title: {
    text: "Contact Kloo customer support to manage automated assignment of worfklows",
    fontSize: "16",
    variant: "body",
    fontStyle: "semibold",
    className: "mb-2",
  },
  subTitle: {
    text: "For bulk uploading or updating your organisation’s automated workflow mappings, download your mapping sheet and send your configurations to ",
    variant: "body",
  },
  subTitle2: {
    text: " to get these uploaded.",
    variant: "body",
  },
  downloadButton: {
    text: "Download",
  },
};
