import react, { memo, useEffect, useState } from "react";
import { Box, Button, CustomButton, Modal, Typography, When } from "..";
import {
  ASSIGN_WORKFLOW,
  moneyFormatter,
  OVER,
  SMART_APPROVAL_CARD_BUTTONS,
  UPTO,
  convertLowerCaseWithoutSpace,
  AND,
} from "../../../utils";
import "./viewworkflowmodal.scss";
import { ACCENTS, BUTTON_VARIANT, WORKFLOW_FORM } from "../../../constants";
import { getCustomFieldsAndValues } from "../../../api";

const ViewWorkFlowModal = ({
  open,
  onCancel,
  subTitle,
  title,
  workflowsteps,
  onEdit,
  onDelete,
  activeKey,
}) => {
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const doesCustomFieldExist = workflowsteps?.some((step) =>
      step?.workflowsteps?.some((innerStep) => !!innerStep?.custom_field_id)
    );

    if (doesCustomFieldExist) {
      getCustomFieldsAndValues({ integrationType: activeKey }).then((res) => {
        let tempArr = [];

        Object.keys(res).forEach(
          (item) => (tempArr = [...tempArr, ...res[item]])
        );
        setCustomFields(tempArr);
      });
    }
  }, []);
  return (
    <Modal
      isOpen={open}
      title={title}
      hide={onCancel}
      width={800}
      showDivider={false}
    >
      <div justifyContent="center" className="view-modal-container">
        <Typography
          className="workflow-subtile-txt"
          variant="body"
          text={subTitle}
        />
        <div className="workflow-content-section">
          {workflowsteps?.map((workflow, workflowIndex) => (
            <div className="workflow-content">
              <When condition={!workflow?.isOldStep}>
                <Typography
                  className="workflow-total-step"
                  variant="body"
                  text={ASSIGN_WORKFLOW.WORKFLOW_STEP(workflowIndex + 1)}
                />
              </When>
              {workflow.workflowsteps.map((workflowstep, index) => {
                const isLast = index === workflow.workflowsteps?.length - 1;
                const isRoleUserStepType =
                  convertLowerCaseWithoutSpace(
                    workflow?.workflow_category?.workflow_type
                  ) ===
                  convertLowerCaseWithoutSpace(
                    WORKFLOW_FORM.ROLE_USER_STEP_TYPE
                  );
                const isConditionalThresholdStepType =
                  convertLowerCaseWithoutSpace(
                    workflow?.workflow_category?.workflow_type
                  ) ===
                  convertLowerCaseWithoutSpace(
                    WORKFLOW_FORM.CONDITIONAL_THRESHOLD_STEP_TYPE
                  );

                const field = customFields.find(
                  (i) => i.id === workflowstep?.custom_field_id
                );

                const customFieldLabel = field?.label;
                const customFieldValueLabel = field?.values.find(
                  (i) => i.value === workflowstep?.custom_field_value_id
                )?.label;

                return (
                  <>
                    <When condition={!isConditionalThresholdStepType}>
                      <div className="workflow-step-data">
                        <Typography
                          className="workflow-approver-name-txt"
                          variant="body"
                          text={
                            workflowstep?.workFlowstepData?.label ||
                            WORKFLOW_FORM.PLACEHOLDERS.APPROVER_EDIT
                          }
                        />
                        <When condition={!isRoleUserStepType}>
                          <Box justifyContent="flexStart">
                            <Typography
                              className="workflow-approver-over-upto-txt"
                              variant="body"
                              text={isLast ? OVER : UPTO}
                            />
                            <Typography
                              className="workflow-approver-value-txt"
                              variant="body"
                              text={moneyFormatter(
                                workflowstep?.workflow_step_threshold
                                  ?.threshold_limit,
                                0
                              )}
                            />
                          </Box>
                        </When>
                      </div>
                      <span className="workflow-approver-name-arrow">
                        <When condition={!isLast}>
                          {ASSIGN_WORKFLOW.WORKFLOW_ARROW}
                        </When>
                      </span>
                    </When>
                    <When condition={isConditionalThresholdStepType}>
                      <div className="workflow-step-data">
                        <Typography
                          className="workflow-approver-name-txt"
                          variant="body"
                          text={ASSIGN_WORKFLOW.AUTO_APPROVE}
                        />

                        <Box justifyContent="flexStart">
                          <Typography
                            className="workflow-approver-over-upto-txt"
                            variant="body"
                            text={UPTO}
                          />
                          <Typography
                            className="workflow-approver-value-txt"
                            variant="body"
                            text={moneyFormatter(
                              workflowstep?.workflow_step_threshold
                                ?.threshold_limit,
                              0
                            )}
                          />
                        </Box>
                      </div>
                      <span className="workflow-approver-name-arrow">
                        {ASSIGN_WORKFLOW.WORKFLOW_ARROW}
                      </span>
                      <div className="workflow-step-data">
                        <Typography
                          className="workflow-approver-name-txt"
                          variant="body"
                          text={
                            workflowstep?.workFlowstepData?.label ||
                            WORKFLOW_FORM.PLACEHOLDERS.APPROVER_EDIT
                          }
                        />
                        <Box justifyContent="flexStart">
                          <Typography
                            className="workflow-approver-over-upto-txt"
                            variant="body"
                            text={isLast ? OVER : UPTO}
                          />
                          <Typography
                            className="workflow-approver-value-txt"
                            variant="body"
                            text={moneyFormatter(
                              workflowstep?.workflow_step_threshold
                                ?.threshold_limit,
                              0
                            )}
                          />
                        </Box>
                        <When condition={!!customFields.length && !!field}>
                          <Box justifyContent="flexStart">
                            <Typography
                              className="workflow-approver-over-upto-txt"
                              variant="body"
                              text={AND}
                            />
                            <Typography
                              className="workflow-approver-value-txt"
                              variant="body"
                              text={customFieldLabel + " ="}
                            />
                            <Typography
                              className="workflow-approver-value-txt"
                              variant="body"
                              text={customFieldValueLabel}
                            />
                          </Box>
                        </When>
                      </div>
                    </When>
                  </>
                );
              })}
            </div>
          ))}
        </div>
      </div>
      <div className="edit-workflow-btn">
        <CustomButton
          text={"Edit workflow"}
          accent={ACCENTS.PRIMARY}
          variant={BUTTON_VARIANT.BUTTON_FILLED}
          onClick={onEdit}
          customSize={"large"}
        />
        <CustomButton
          text={SMART_APPROVAL_CARD_BUTTONS.DELETE}
          accent={ACCENTS.DESTRUCTIVE}
          variant={BUTTON_VARIANT.BUTTON_HOLLOW}
          onClick={onDelete}
          customSize={"large"}
        />
      </div>
    </Modal>
  );
};
export default memo(ViewWorkFlowModal);
