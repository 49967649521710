import { combineReducers } from "redux";
import auth from "./auth";
import cards from "./cards";
import transactions from "./transactions";
import loader from "./loader";
import accountPayable from "./accountPayable";
import cardExpenses from "./cardExpenses";
import currency from "./currency";
import { modular } from "./modular";
import codat from "./codat";
import menuReducer from "./menu";
import categoriesReducer from "./categories";
import manageUsers from "./manageUser";
import trackingCategories from "./setting";
import CustomCategories from "./customexpenses";
import { funds } from "./funds";
import { LOGOUT_USER } from "../service/types";
import { switchProfile } from "./switchProfile";
import notification from "./notifications";
import categorisationReducer from "./categorisation";
import DashboardReducer from "./dashboard";
import filters from "./filters";
import dashboardFilter from "./dashboardFilter";
const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }
  return allReducers(state, action);
};
const allReducers = combineReducers({
  loader,
  auth,
  cards,
  manageUsers,
  accountPayable,
  transactions,
  notification,
  currency,
  modular,
  codat,
  menuReducer,
  cardExpenses,
  categoriesReducer,
  trackingCategories,
  CustomCategories,
  funds,
  switchProfile,
  categorisationReducer,
  dashboard: DashboardReducer,
  filters,
  dashboardFilter,
});

export default rootReducer;
