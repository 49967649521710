import { CustomButton, Icon, InfiniteScrollTable } from "../../common";
import { ACCENTS, BUTTON_VARIANT } from "../../../constants";
import { useState } from "react";
import { constructClassName } from "../../../utils";

function BulkDrawerTable(props) {
  const {
    columns,
    onRowClick,
    setSelectedItems,
    activeTab,
    modules,
    selectedModule,
    setSelectedModule,
    onClose,
    tableHeight,
  } = props;
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);

  const handleRemoveSelection = (e, record) => {
    e.stopPropagation();
    const filterModules = modules?.filter(
      (moduleWise) => moduleWise.id !== record.id
    );
    if (!filterModules.length) {
      setSelectedItems([]);
      onClose();
      return;
    }
    setSelectedItems(filterModules);
    const ifSelectedModuleExist = filterModules.findIndex(
      (item) => item.id === selectedModule.id
    );
    if (ifSelectedModuleExist === -1) {
      setSelectedModule(filterModules[0]);
      setSelectedRowIndex(0);
    } else {
      setSelectedModule(filterModules[ifSelectedModuleExist]);
      setSelectedRowIndex(ifSelectedModuleExist);
    }
  };

  const renderBody = ({ label, key, record, index, defaultRender }) => {
    switch (key) {
      case "action":
        return (
          <CustomButton
            accent={ACCENTS.DESTRUCTIVE}
            variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
            icon={<Icon iconName={"close"} />}
            onClick={(e) => handleRemoveSelection(e, record)}
          />
        );
      default:
        return defaultRender({ label, key, record, index, defaultRender });
    }
  };

  return (
    <InfiniteScrollTable
      key={Object.values({ activeTab, selectedRowIndex }).join("-")}
      columns={columns}
      renderBody={renderBody}
      onRowClick={({ record }) => onRowClick({ record })}
      className={constructClassName(["p-0"])}
      tableClassName={"m-0"}
      enableRowHighlight
      defaultSelectedRow={selectedRowIndex}
      showScrollBar={true}
      data={modules}
      isInfiniteScroll={false}
      enableBorder={true}
      height={`${tableHeight}px`}
    />
  );
}

export default BulkDrawerTable;
