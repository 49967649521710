import React from "react";
import { Layout, Menu } from "antd";
import { Link, Navigate } from "react-router-dom";
import { Icon } from "../common";
const { Sider } = Layout;

export default function AppSider({ selectedSidebar, showSideData }) {
  const onClickMenu = () => {
    document.getElementsByClassName("inactive-content")[0].scrollTo(0, 0);
  };

  return (
    <Sider
      trigger={null}
      collapsible={false}
      className={`kloo-sidebar`}
      id="kloo-sidebar"
    >
      <div className="menu-wrapper" id="menu-wrapper">
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[selectedSidebar]}
          expandIcon={<Icon iconName={"expand_more"} />}
          onClick={onClickMenu}
          // items={showSideData}
        >
          {showSideData &&
            showSideData.map((items) =>
              items?.children ? (
                <Menu.SubMenu
                  title={items.label}
                  menu
                  className="hover-settings-sidemenu"
                >
                  {items.children.map((subItem) => (
                    <Menu.Item key={subItem.key}>
                      <Link to={subItem.key}>{subItem.label}</Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <>
                  <Menu.Item key={items.key}>
                    <Link to={items.key}>{items.label}</Link>
                  </Menu.Item>
                </>
              )
            )}
        </Menu>
      </div>
    </Sider>
  );
}
