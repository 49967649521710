import { CODAT_DETAILS, CODAT_STATUS } from "../service/types";

const intialstate = {
  codatData: "",
  codatStatus: "",
};
export default function codat(state = { intialstate }, action) {
  switch (action.type) {
    case CODAT_DETAILS:
      return { ...state, codatData: action.payload };
    case CODAT_STATUS:
      return { ...state, codatStatus: action.payload };
    default:
      return state;
  }
}
