export const SERIAL_NUMBER_COLUMN_KEY = "serialNumber";
export const SERIAL_NUMBER_COLUMN_LABEL = "Sr. No";

export const SELECTION_COLUMN_KEY = "selection";
export const SELECTION_COLUMN_LABEL = "Select";

export const EXPANDABLE_COLUMN_KEY = "expandable";
export const EXPANDABLE_COLUMN_LABEL = "expand";

export const SERIAL_NUMBER_COLUMN = {
  key: SERIAL_NUMBER_COLUMN_KEY,
  label: SERIAL_NUMBER_COLUMN_LABEL,
  width: 5,
};

export const SELECTION_COLUMN = {
  key: SELECTION_COLUMN_KEY,
  label: SELECTION_COLUMN_LABEL,
  width: 5,
  clickable: false,
};

export const EXPAND_COLLAPSE_COLUMN = {
  key: EXPANDABLE_COLUMN_KEY,
  label: EXPANDABLE_COLUMN_LABEL,
  width: 15,
};
