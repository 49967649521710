import { Form, Input } from "antd";
import React, { useCallback, useState } from "react";
import { constructClassName, tagsNotAllowed } from "../../../utils";
import Box from "../Box";
import Icon from "../Icon";
import When from "../When";
import "./Password.scss";
function Password(props) {
  const {
    name,
    label,
    value,
    placeholder,
    rules,
    validateTrigger = "onChange",
    disabled,
    size,
    autoComplete = "new-password",
    checkList = true,
    hasFeedback,
    className,
    prefixIcon = <Icon iconName={"lock"} />,
    ...rest
  } = props;
  const [passwordValue, setPasswordValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isBlur, setIsBlur] = useState(false);

  const isRequiredRule =
    rules?.filter((el) => el.hasOwnProperty("required")) || [];

  const isTagRuleExist =
    rules?.filter((el) => el?.message === tagsNotAllowed()?.message) || [];

  const validatorFunctions =
    rules?.filter((item) => typeof item === "function") || [];

  function checkRuleValid(el, result) {
    if (el.hasOwnProperty("required")) {
      return el?.required ? !!result : true;
    }
    if (el.hasOwnProperty("pattern")) {
      return el?.pattern?.test(result);
    }
    if (el.hasOwnProperty("min")) {
      return String(result)?.length >= el?.min;
    }
    if (el.hasOwnProperty("max")) {
      return String(result)?.length <= el?.max;
    }
    return true;
  }

  const rulesList = useCallback(
    (result) => {
      const data = rules?.map((el) => {
        return {
          ...el,
          valid: checkRuleValid(el, result),
        };
      });
      return data?.filter(
        (item) =>
          !(
            item.hasOwnProperty("required") ||
            item?.message === tagsNotAllowed()?.message ||
            typeof item === "function"
          )
      );
    },
    [rules, passwordValue]
  );

  function passwordValidator() {
    return {
      validator(rule, result) {
        setPasswordValue(result);
        const isAnyRuleInvalid = rulesList(result)?.some((el) => !el.valid);
        if (isAnyRuleInvalid) {
          return Promise.reject();
        } else {
          return Promise.resolve();
        }
      },
    };
  }
  const isAllRuleValid = !rulesList(passwordValue)?.some((el) => !el.valid);
  const showCheckList =
    rules &&
    rules?.length > 0 &&
    !disabled &&
    passwordValue !== undefined &&
    (isFocused || passwordValue !== "") &&
    !(isAllRuleValid && isBlur) &&
    checkList;

  const optionalFieldClassName = isRequiredRule ? "" : "optional-field";

  return (
    <>
      <Form.Item
        name={name}
        label={label}
        className={`password-form-item ${
          showCheckList && "remove-margin-bottom"
        }`}
        rules={[
          ...isRequiredRule,
          ...isTagRuleExist,
          passwordValidator,
          ...validatorFunctions,
        ]}
        hasFeedback={hasFeedback}
        validateTrigger={validateTrigger}
      >
        <Input.Password
          iconRender={(visible) => (
            <Icon iconName={visible ? "visibility" : "visibility_off"} />
          )}
          prefix={prefixIcon}
          className={constructClassName([
            className || "",
            "kloo-input",
            optionalFieldClassName,
          ])}
          type={"password"}
          placeholder={placeholder}
          size={size}
          disabled={disabled}
          autoComplete={autoComplete}
          value={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsBlur(true)}
          {...rest}
        />
      </Form.Item>
      <When condition={showCheckList}>
        <Box
          justifyContent="start"
          rowGap={1}
          direction={"column"}
          className={"password-rule-container"}
          alignContent="flexStart"
          alignItem="flexStart"
        >
          <span className="checklist-title">
            Your password must include the following:
          </span>
          {rulesList(passwordValue)?.map((item) => {
            if (!item?.message) {
              return <></>;
            }
            return (
              <div className="icon-message-container">
                <Icon
                  className={`checklist-icon  ${
                    item?.valid ? "password-valid" : "password-error"
                  }`}
                  iconName={item?.valid ? "check_circle" : "cancel"}
                />
                <p className={`mb-0 password-text`}>{item?.message}</p>
              </div>
            );
          })}
        </Box>
      </When>
    </>
  );
}

export default Password;
