import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import { debounce as debounceFn } from "../../../utils/constant";
import { constructClassName } from "../../../utils";
import "../../../assets/scss/FormElement.scss";

function TextField({
  onSubmit,
  onChange,
  className,
  debounce,
  debounceTimeout,
  inputClassName,
  allowClear,
  customSize = "",
  ...rest
}) {
  const debouncedOnChange = !debounce
    ? onChange
    : debounceFn(onChange, debounceTimeout);

  const debouncedOnSubmit = !debounce
    ? onSubmit
    : debounceFn(onSubmit, debounceTimeout);

  return (
    <div className={className}>
      <Input
        className={constructClassName([
          inputClassName,
          `kloo-input ${customSize}`,
        ])}
        onPressEnter={(e) => debouncedOnSubmit(e.target.value, e)}
        onChange={(e) => debouncedOnChange(e.target.value, e)}
        allowClear={allowClear}
        {...rest}
      />
    </div>
  );
}

TextField.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  debounce: PropTypes.bool,
  debounceTimeout: PropTypes.number,
};

TextField.defaultProps = {
  placeholder: "Search",
  debounce: false,
  allowClear: true,
  debounceTimeout: 500,
  onSubmit: () => {},
  onChange: () => {},
};

export default TextField;
