import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import {
  appendKeyForFilters,
  generateEncodedSearchURI,
} from "../utils/functions";

export async function getSmartApprovalHistory({
  page = 1,
  filtersUrl = "",
  searchUrl = "",
}) {
  const tempResponse = await apiClient({
    url: `${PATH.smart_approval.getSmartApprovalAuditHistory}?page=${page}${filtersUrl}${searchUrl}`,
    method: "GET",
  });

  const response = {
    data: {
      list: tempResponse?.data?.result?.data?.map(
        ({ id, email, actioning_user, before, after, created_at }) => {
          return {
            id,
            email,
            user: actioning_user,
            before: before,
            after: after,
            date: created_at,
          };
        }
      ),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
    },
  };

  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}

export async function getSmartApprovalCardSetting() {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.smart_approval.smartApprovalGETListing,
  });

  return tempResponse?.data;
}

export async function getModuleWiseCreatorsList(module) {
  const response = await apiClient({
    url: PATH.smartApprovalRefactor.getModuleWiseCreatorsList(module),
    method: "GET",
  });
  return response?.data;
}

export async function getAllWorkFlowListForSmartApproval(search = "", type) {
  const searchUrl = generateEncodedSearchURI(search);

  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.smartApprovalRefactor.getAllWorkflowList(type, searchUrl),
  });

  return tempResponse?.data;
}

export async function getUserDataForSupplier() {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.user.GetUser(
      "",
      "",
      "active",
      "",
      "whereIn:roles.name~Finance%20Approver|Organisation%20Admin"
    ),
  });

  let userData = [];
  if (tempResponse.data?.result?.user_organization) {
    tempResponse.data?.result?.user_organization?.map((organization) => {
      if (
        organization?.users?.first_name?.length ||
        organization?.users?.last_name?.length
      ) {
        userData.push({
          value: organization.user_id,
          label: organization?.users
            ? `${organization?.users?.first_name} ${organization?.users?.last_name}`
            : "N/A",
        });
      }
    });
  }
  return userData;
}
