import { Form } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ActionLink,
  Box,
  Column,
  CommonInput,
  CommonSelect,
  CustomAlert,
  CustomButton,
  DatePicker,
  Icon,
  Modal,
  Row,
  Space,
  Switch,
  Tabs,
  Typography,
  When,
} from "..";
import {
  createPaymentRequest,
  reSchedulePaymentRequest,
  validatePaymentRequest,
} from "../../../api/InvoiceManagementAPI";
import { ERROR_MSG, NAVIGATION_URL } from "../../../constants";
import {
  PAYMENT_JOURNEY_FAILED_CONFIG,
  PAYMENT_JOURNEY_FORM_CONFIG,
  PAYMENT_JOURNEY_PAY_NOW_KEY,
  PAYMENT_JOURNEY_RESCHEDULE_KEY,
  PAYMENT_JOURNEY_SCHEDULED_CONFIG,
  PAYMENT_JOURNEY_SCHEDULE_KEY,
  PAYMENT_JOURNEY_SUCCESS_CONFIG,
  PAYMENT_JOURNEY_TAB_CONFIG,
  PAYMENT_JOURNEY_TAB_PAY_WITH_OPEN_BANKING_KEY,
} from "../../../constants/InvoiceManagement";
import { SCHEDULE_PAYMENT_MODAL } from "../../../constants/PaymentRuns";
import {
  disableDateHandlerForScheduledPaymentJourney,
  getPayloadForPayNowPayment,
  getPayloadForReScheduledPayment,
  getPayloadForScheduledPayment,
  getSupplierDetails,
  getValidationPaymentPayload,
  paymentNotification,
} from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import { modulaAccountDetails } from "../../../redux/action/modular";
import {
  DEFAULT_FORMAT,
  LOCAL_STORAGE_KEY,
  checkIfDateIsAWeekend,
  constructClassName,
  getDateAndTimeInTimeForOpenBankingYapilyPayment,
  getFormattedDateInUTC,
  getItem,
  getTodaysDate,
  moneyFormatter,
} from "../../../utils";
import YapilyPISJourney from "../../OpenBanking/YapilyAccount/ConsentJourney/YapilyPISJourney";
import YapilyUserServiceConfirmation from "../../OpenBanking/YapilyAccount/YapilyUserServiceConfirmation";
import "./PaymentJourneyModal.scss";

const {
  payee,
  entity,
  sortCode,
  accountNumber,
  proofOfPayment,
  proofOfPaymentEmail,
  paymentDate,
  suppliersAccount,
} = PAYMENT_JOURNEY_FORM_CONFIG;

function checkIfModalIsPayNow(modalType) {
  return modalType === PAYMENT_JOURNEY_PAY_NOW_KEY;
}

function checkIfModalIsScheduledPayment(modalType) {
  return modalType === PAYMENT_JOURNEY_SCHEDULE_KEY;
}

function checkIfModalIsRescheduledPayment(modalType) {
  return modalType === PAYMENT_JOURNEY_RESCHEDULE_KEY;
}

function checkIfTabIsPayWithOpenBanking(activeTab) {
  return activeTab === PAYMENT_JOURNEY_TAB_PAY_WITH_OPEN_BANKING_KEY;
}

function getPaymentReferenceNumber(selectedRecord) {
  return `KL${selectedRecord.id.split("-")[0]}`;
}

const getPaymentModalTab = ({ showModular, showOpenBanking }) => {
  let tabConfig = [];

  if (showModular) {
    tabConfig.push(PAYMENT_JOURNEY_TAB_CONFIG[0]);
  }
  if (showOpenBanking) {
    tabConfig.push(PAYMENT_JOURNEY_TAB_CONFIG[1]);
  }

  return {
    tabConfig,
    tabKey: tabConfig[0].key,
  };
};

const PaymentItemForm = ({
  form,
  type,
  isProofOfPaymentEnabled,
  toggleProofOfPayment,
  activeKey,
  selectedRecord,
  suppliers,
  tabItemConfig,
  invoiceFromSupplier,
  showErrorMessage,
  errorMessage,
  viewEntityField = false,
  entityList = [],
  entity_id,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedPayeeDetails = Form.useWatch(payee.name, form);
  const selectedSupplierSelectedAccountDetails = Form.useWatch(
    suppliersAccount.name,
    form
  );

  useEffect(() => {
    if (invoiceFromSupplier) {
      setTimeout(() => {
        navigate(location.pathname, { replace: true });
      }, 2000);
    }
  }, []);

  const { currentSupplier } = getSupplierDetails({
    allSupplierDetails: suppliers?.result,
    selectedSupplier: selectedRecord?.supplier?.id,
    selectedSupplierSelectedAccountDetails,
  });

  const setResetField = ({ isSupplierAccountDropdownReset, valueObject }) => {
    const resetSupplierAccountDropdown = {
      name: suppliersAccount.name,
      value: undefined,
      errors: [],
    };

    let resetFieldArray = [
      {
        name: sortCode.name,
        value: valueObject ? valueObject?.sort_code : undefined,
        errors: valueObject ? [] : undefined,
      },
      {
        name: accountNumber.name,
        value: valueObject ? valueObject?.acc_number : undefined,
        errors: valueObject ? [] : undefined,
      },
    ];

    if (isSupplierAccountDropdownReset)
      resetFieldArray.push(resetSupplierAccountDropdown);

    form.setFields(resetFieldArray);
  };

  const handleSupplierSelected = (selectedSupplier) => {
    setResetField({ isSupplierAccountDropdownReset: true });
  };

  const handleSupplierAccountSelected = (value) => {
    const { currentSupplierCurrentAccountDetails } = getSupplierDetails({
      allSupplierDetails: suppliers?.result,
      selectedSupplier: selectedPayeeDetails,
      selectedSupplierSelectedAccountDetails: value,
    });

    setResetField({
      isSupplierAccountDropdownReset: false,
      valueObject: currentSupplierCurrentAccountDetails,
    });
  };

  const getSupplierAccountsOption = useMemo(() => {
    const { currentSupplierAllAccountDetails } = getSupplierDetails({
      allSupplierDetails: suppliers?.result,
      selectedSupplier: selectedPayeeDetails,
    });
    return currentSupplierAllAccountDetails.map(({ id, account_name }) => ({
      value: id,
      label: account_name,
    }));
  }, [selectedPayeeDetails, suppliers.result]);

  const showPaymentData =
    checkIfModalIsScheduledPayment(type) ||
    checkIfModalIsRescheduledPayment(type);

  const disableAllFormItem =
    checkIfModalIsRescheduledPayment(type) ||
    (currentSupplier && currentSupplier.id);

  const goToSupplierManagement = (supplierId) => {
    const supplier = suppliers?.result?.find(({ id }) => id === supplierId);
    navigate(NAVIGATION_URL.SUPPLIER_MANAGEMENT, {
      state: {
        supplier: { supplier, supplierName: supplier?.name },
        invoiceDetails: { selectedRecord, type: type },
      },
    });
  };

  return (
    <>
      <When condition={viewEntityField && entity_id}>
        <CommonSelect
          {...entity}
          defaultValue={entity_id}
          options={entityList}
        />
      </When>
      <CommonSelect
        {...payee}
        options={suppliers?.result?.map(({ name, id }) => ({
          label: name,
          value: id,
        }))}
        onChange={handleSupplierSelected}
        disabled={disableAllFormItem}
        readOnly={disableAllFormItem}
      />
      <CommonSelect
        {...suppliersAccount}
        options={getSupplierAccountsOption}
        onChange={handleSupplierAccountSelected}
        readOnly={disableAllFormItem}
        dropdownRender={
          !selectedPayeeDetails || getSupplierAccountsOption?.length !== 0
            ? null
            : () => (
                <>
                  <Space className="p-l-8px p-r-8px p-b-4px">
                    <ActionLink
                      onClick={() =>
                        goToSupplierManagement(selectedPayeeDetails)
                      }
                      text={SCHEDULE_PAYMENT_MODAL.ADD_SUPPLIER_AC}
                    />
                  </Space>
                </>
              )
        }
      />
      <When condition={showPaymentData}>
        <DatePicker
          {...paymentDate}
          defaultValue={false}
          disabledDate={(current) => {
            return disableDateHandlerForScheduledPaymentJourney(
              current,
              activeKey
            );
          }}
        />
      </When>
      <Row>
        <Column span={12}>
          <CommonInput {...sortCode} disabled={true} readOnly={true} />
        </Column>
        <Column span={12}>
          <CommonInput {...accountNumber} disabled={true} readOnly={true} />
        </Column>
      </Row>
      <When condition={checkIfTabIsPayWithOpenBanking(activeKey)}>
        <Box justifyContent={"spaceBetween"}>
          <Typography {...tabItemConfig.paymentReferenceConfig} />
          <Typography
            variant={"body"}
            fontStyle={"semibold"}
            text={getPaymentReferenceNumber(selectedRecord)}
          />
        </Box>
        <When condition={checkIfModalIsPayNow(type)}>
          <Box justifyContent={"spaceBetween"} className={"m-t-16px"}>
            <Typography {...tabItemConfig.paymentDateConfig} />
            <Typography
              variant={"body"}
              fontStyle={"semibold"}
              text={getTodaysDate(DEFAULT_FORMAT)}
            />
          </Box>
        </When>
      </When>
      <Box
        justifyContent="spaceBetween"
        wrap={"nowrap"}
        className={constructClassName([
          "m-b-8px",
          checkIfTabIsPayWithOpenBanking(activeKey) ? "m-t-16px" : "",
        ])}
      >
        <Typography {...proofOfPayment} />
        <Switch
          name="proofOfPaymentSwitch"
          onChange={toggleProofOfPayment}
          checked={isProofOfPaymentEnabled}
        />
      </Box>
      <When condition={errorMessage}>
        <CustomAlert
          message={showErrorMessage(errorMessage)}
          type="error"
          className={"mb-3"}
        />
      </When>
      <When condition={isProofOfPaymentEnabled}>
        <CommonInput {...proofOfPaymentEmail} />
      </When>
    </>
  );
};

const PaymentItemCTA = ({
  config,
  onCancel,
  form,
  handleFormComplete,
  selectedRecord,
}) => {
  const handleSubmitClick = async () => {
    return form
      .validateFields()
      .then(async (values) => {
        await handleFormComplete({
          ...values,
          invoice_number: selectedRecord?.invoice_number || null,
        });
      })
      .catch((e) => {});
  };

  return config.map((buttonProps) => {
    return (
      <CustomButton
        {...buttonProps}
        showLoader={buttonProps.key === "cancel" ? false : true}
        onClick={buttonProps.key === "cancel" ? onCancel : handleSubmitClick}
        customSize={"large"}
      />
    );
  });
};

const PaymentJourneyItem = (props) => {
  const {
    type,
    activeKey,
    selectedRecord,
    form,
    isProofOfPaymentEnabled,
    suppliers,
    invoiceFromSupplier,
  } = props;
  const tabItemConfig = PAYMENT_JOURNEY_TAB_CONFIG.find(
    ({ key }) => key === activeKey
  );
  const actionConfig = tabItemConfig.tabConfig.find(({ key }) => key === type);

  const { currentSupplier, currentSupplierCurrentAccountDetails } =
    getSupplierDetails({
      allSupplierDetails: suppliers?.result,
      selectedSupplier: selectedRecord?.supplier?.id
        ? selectedRecord?.supplier?.id
        : invoiceFromSupplier
        ? invoiceFromSupplier?.selectedSupplier?.id
        : null,
      selectedSupplierSelectedAccountDetails: invoiceFromSupplier
        ? invoiceFromSupplier?.selectedSupplier?.supplier_accounts?.[0]?.id
        : selectedRecord?.supplier_account_details_id || "",
    });

  const initialFormValue =
    checkIfModalIsRescheduledPayment(type) ||
    (selectedRecord?.supplier && currentSupplier?.id)
      ? {
          [sortCode.name]: currentSupplierCurrentAccountDetails.sort_code,
          [accountNumber.name]: currentSupplierCurrentAccountDetails.acc_number,
          [payee.name]: currentSupplier?.id,
        }
      : invoiceFromSupplier && currentSupplier?.id
      ? {
          [sortCode.name]: currentSupplierCurrentAccountDetails.sort_code,
          [accountNumber.name]: currentSupplierCurrentAccountDetails.acc_number,
          [payee.name]: currentSupplier?.id,
        }
      : {};
  if (
    (selectedRecord?.supplier && currentSupplier?.id) ||
    invoiceFromSupplier
  ) {
    initialFormValue[suppliersAccount.name] =
      currentSupplierCurrentAccountDetails.id;
  }
  return (
    <>
      <Form form={form} initialValues={initialFormValue} layout="vertical">
        <PaymentItemForm {...props} tabItemConfig={tabItemConfig} />
        <When condition={checkIfTabIsPayWithOpenBanking(activeKey)}>
          <Typography
            text={tabItemConfig.consentApprovalLabel}
            variant="secondary"
            className={constructClassName([
              !isProofOfPaymentEnabled ? "m-t-16px" : "",
            ])}
          />
        </When>
        <When condition={actionConfig?.scheduleConfirmationLabel}>
          <CustomAlert
            type="warning"
            className={
              checkIfTabIsPayWithOpenBanking(activeKey) ? "m-t-16px" : ""
            }
            message={
              <Typography
                variant="body"
                text={actionConfig.scheduleConfirmationLabel}
                fontSize={16}
              />
            }
          />
        </When>
        <Box columnGap={32} className={"m-t-16px"}>
          <PaymentItemCTA {...props} config={actionConfig.buttonConfig} />
        </Box>
      </Form>
    </>
  );
};

const PaymentJourneyModal = ({
  open,
  onCancel,
  onConfirm,
  type,
  selectedRecord,
  suppliers,
  isYapilyEnabled,
  isModularEnabled,
  invoiceFromSupplier,
  showErrorMessage,
  entityList,
  entity_id,
  viewEntityField,
}) => {
  const [form] = Form.useForm();
  const { tabConfig, tabKey } = getPaymentModalTab({
    showModular: isModularEnabled,
    showOpenBanking: isYapilyEnabled,
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [activeKey, setActiveKey] = useState(tabKey);
  const formValueRef = useRef(null);
  const { isOpen: isProofOfPaymentEnabled, toggle: toggleProofOfPayment } =
    useToggle();
  const { isOpen: payingWithOpenBanking, toggle: toggleYapilyPIS } =
    useToggle();
  const { isOpen: paymentFinalStatus, toggle: togglePaymentFinalStatus } =
    useToggle();

  const dispatch = useDispatch();

  const changeTabs = (tabState) => {
    if (
      checkIfTabIsPayWithOpenBanking(tabState) &&
      checkIfModalIsScheduledPayment(type)
    ) {
      if (checkIfDateIsAWeekend(form.getFieldValue(paymentDate.name))) {
        form.setFieldValue(paymentDate.name, "");
      }
    }
    setErrorMessage("");
    setActiveKey(tabState);
  };

  async function handlePaymentJourneyType({ type, formValue, orgDetails }) {
    let paymentAPIDetails = [];
    switch (type) {
      case PAYMENT_JOURNEY_PAY_NOW_KEY:
        paymentAPIDetails = getPayloadForPayNowPayment({
          formValue,
          orgDetails,
          selectedRecord,
        });
        break;
      case PAYMENT_JOURNEY_SCHEDULE_KEY:
        paymentAPIDetails = getPayloadForScheduledPayment({
          formValue,
          orgDetails,
          selectedRecord,
        });
        break;
      case PAYMENT_JOURNEY_RESCHEDULE_KEY:
        paymentAPIDetails = getPayloadForReScheduledPayment({
          formValue,
          selectedRecord,
        });
        break;
      default:
    }
    try {
      switch (type) {
        case PAYMENT_JOURNEY_PAY_NOW_KEY:
        case PAYMENT_JOURNEY_SCHEDULE_KEY:
          await createPaymentRequest(paymentAPIDetails.payload);
          break;
        case PAYMENT_JOURNEY_RESCHEDULE_KEY:
          await reSchedulePaymentRequest(paymentAPIDetails.payload);
          break;
        default:
      }
      paymentAPIDetails.onSuccessNotification();
      onConfirm();
    } catch (e) {
      setErrorMessage(e?.response?.data?.message || ERROR_MSG.common);
    }
  }

  const handleFormComplete = async (values) => {
    formValueRef.current = values;
    const orgId = getItem(LOCAL_STORAGE_KEY.ORD_ID);
    const modularAccount = await dispatch(modulaAccountDetails(orgId));
    if (checkIfTabIsPayWithOpenBanking(activeKey)) {
      try {
        const checkValidationStatus = await validatePaymentRequest(
          getValidationPaymentPayload({
            selectedRecord,
            formValue: values,
            orgDetails: modularAccount,
          })
        );
        if (checkValidationStatus) {
          toggleYapilyPIS();
        } else {
          setErrorMessage(ERROR_MSG.common);
        }
      } catch (e) {
        setErrorMessage(e?.response?.data?.message || ERROR_MSG.common);
      }
    } else {
      try {
        await handlePaymentJourneyType({
          type,
          formValue: values,
          orgDetails: modularAccount,
        });
      } catch (e) {}
    }
  };

  const generateDataForOpenPayment = () => {
    const formValue = formValueRef.current || {};

    const { currentSupplierCurrentAccountDetails } = getSupplierDetails({
      allSupplierDetails: suppliers?.result,
      selectedSupplier: formValue[payee.name],
      selectedSupplierSelectedAccountDetails: formValue[suppliersAccount.name],
    });

    const supplierDetails =
      suppliers?.result?.find(({ id }) => id === formValue[payee.name]) || {};

    let newFeatureWiseData = {
      supplierAccountId: currentSupplierCurrentAccountDetails.id,
      supplierAccountName: currentSupplierCurrentAccountDetails.account_name,
      [sortCode.name]: currentSupplierCurrentAccountDetails.sort_code,
      [accountNumber.name]: currentSupplierCurrentAccountDetails.acc_number,
    };

    return {
      invoiceId: selectedRecord.id,
      supplierId: supplierDetails.id,
      scheduledPaymentDate: getFormattedDateInUTC(
        formValue[paymentDate.name],
        DEFAULT_FORMAT
      ),
      paymentDate: getTodaysDate(DEFAULT_FORMAT),
      totalPaid: moneyFormatter(selectedRecord?.amount),
      [payee.name]: supplierDetails.name,
      [sortCode.name]: supplierDetails.sort_code,
      [accountNumber.name]: supplierDetails.acc_number,
      paymentReference: getPaymentReferenceNumber(selectedRecord),
      [proofOfPaymentEmail.name]: formValue[proofOfPaymentEmail.name],
      paymentDateTime: getDateAndTimeInTimeForOpenBankingYapilyPayment(
        formValue[paymentDate.name]
      ),
      paymentSource: type,
      ...newFeatureWiseData,
    };
  };

  const handlePaymentComplete = () => {
    paymentNotification(checkIfModalIsPayNow(type) ? "made" : "scheduled");
    onConfirm();
  };

  return (
    <>
      <Modal
        isOpen={open}
        hide={onCancel}
        showHeader={false}
        width={650}
        maskClosable={false}
        showCloseIcon={true}
        className="payment-journey-modal"
        footer={
          payingWithOpenBanking && !paymentFinalStatus ? (
            <YapilyUserServiceConfirmation />
          ) : null
        }
      >
        <When condition={!payingWithOpenBanking}>
          <Row>
            <Column span={23}>
              <Typography
                className={"text-align-center m-b-12px"}
                variant="title"
                text={PAYMENT_JOURNEY_FORM_CONFIG.title}
                fontSize={"24"}
                responsiveFontSize={false}
              />
            </Column>
            <Column span={1}>
              <Icon
                {...PAYMENT_JOURNEY_FORM_CONFIG.backButton}
                onClick={onCancel}
              />
            </Column>
          </Row>
          <Tabs
            items={tabConfig}
            onChange={changeTabs}
            activeKey={activeKey}
            className={"m-b-8px"}
          />
          <PaymentJourneyItem
            type={type}
            activeKey={activeKey}
            handleFormComplete={handleFormComplete}
            onCancel={onCancel}
            selectedRecord={selectedRecord}
            suppliers={suppliers}
            form={form}
            isProofOfPaymentEnabled={isProofOfPaymentEnabled}
            toggleProofOfPayment={toggleProofOfPayment}
            invoiceFromSupplier={invoiceFromSupplier}
            showErrorMessage={showErrorMessage}
            errorMessage={errorMessage}
            entityList={entityList}
            entity_id={entity_id}
            viewEntityField={viewEntityField}
          />
        </When>
        <When condition={payingWithOpenBanking}>
          <YapilyPISJourney
            onClose={onCancel}
            extraData={generateDataForOpenPayment()}
            onBack={toggleYapilyPIS}
            onComplete={handlePaymentComplete}
            paymentSuccessConfig={
              checkIfModalIsPayNow(type)
                ? PAYMENT_JOURNEY_SUCCESS_CONFIG
                : PAYMENT_JOURNEY_SCHEDULED_CONFIG
            }
            paymentFailureConfig={PAYMENT_JOURNEY_FAILED_CONFIG}
            paymentFinalStatus={paymentFinalStatus}
            togglePaymentFinalStatus={togglePaymentFinalStatus}
          />
        </When>
      </Modal>
    </>
  );
};

export default PaymentJourneyModal;
