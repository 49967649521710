import Paragraph from "antd/lib/typography/Paragraph";
import { memo } from "react";
import { TOOLTIP_BG_COLOR } from "../../../constants";
import useMobile from "../../../hooks/useMobile";
import { constructClassName } from "../../../utils/functions";
import "./Typography.scss";

function BodyText({
  text,
  fontStyle = "regular",
  fontSize = "22",
  mobileFontSize = "20",
  responsiveFontSize = false,
  className,
  textColorType = "primary",
  ellipsis,
  disabled,
}) {
  const { isMobile } = useMobile();

  const renderedFontSize =
    responsiveFontSize && isMobile ? mobileFontSize : fontSize;

  const clubbedName = constructClassName([
    "kloo-body-text",
    `kloo-font-weight-${fontStyle}`,
    `kloo-font-${renderedFontSize}`,
    `kloo-text-color-type-${textColorType}`,
    className,
  ]);

  return (
    <Paragraph
      ellipsis={
        ellipsis && {
          tooltip: { title: ellipsis?.tooltip, color: TOOLTIP_BG_COLOR },
        }
      }
      className={clubbedName}
      disabled={disabled}
    >
      {text}
    </Paragraph>
  );
}

export default memo(BodyText);
