import { useEffect, useRef, useState } from "react";
import {
  ACCENTS,
  BUTTON_VARIANT,
  SMART_APPROVAL_PO_CREATOR,
} from "../../../constants";
import { useDebounceState } from "../../../hooks";
import { isDeepEqual } from "../../../utils";
import {
  Box,
  CommonSelect,
  CustomButton,
  Divider,
  Icon,
  SectionTransition,
  Switch,
  Typography,
  When,
} from "../../common";
import "./users-permission.scss";

const UserSelectDropdown = (props) => {
  const {
    reloadSection,
    createEditPermissionUsers,
    dropdownList,
    addUserToList,
    disabledOption,
    tooltipForDisabled,
    removeUser,
    users,
    toggleProps,
  } = props;

  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser, debouncedSelectedUser] =
    useDebounceState(createEditPermissionUsers);

  const selectValueRef = useRef(createEditPermissionUsers);
  const selectValueRefUI = useRef(selectedUser);

  const updateUsers = () => {
    const dbDetails = selectValueRef?.current || [];
    const uiDetails = selectValueRefUI?.current || [];

    if (!isDeepEqual(dbDetails, uiDetails)) {
      const userToBeAdded = uiDetails.filter(
        (value) => !dbDetails.includes(value)
      );
      if (userToBeAdded.length) {
        addUserToList(userToBeAdded[0]).then(() => {
          reloadSection();
        });
      }
    }
  };

  useEffect(() => {
    if (!toggleProps?.checked) {
      selectValueRef.current = [];
      selectValueRefUI.current = [];
      setSelectedUser([]);
    }
  }, [toggleProps]);

  const disabledSelect = !toggleProps?.checked || false;

  useEffect(() => {
    selectValueRef.current = createEditPermissionUsers;
    updateUsers();
  }, [createEditPermissionUsers]);

  useEffect(() => {
    updateUsers();
  }, [debouncedSelectedUser]);

  const checkBeforeRemovingUser = (userId) => {
    const selectedUserId = users?.find(({ value }) => value === userId)?.id;
    selectedUserId &&
      removeUser(selectedUserId).then(() => {
        reloadSection();
      });
  };

  return (
    <CommonSelect
      placeholder={SMART_APPROVAL_PO_CREATOR.SEARCH_USERS_PLACEHOLDER}
      mode={"multiple"}
      searchValue={search}
      onSearch={setSearch}
      onBlur={() => setSearch("")}
      options={dropdownList?.map(({ label, value, disabled }) => ({
        label,
        value,
        disabled,
      }))}
      disabled={disabledSelect}
      allowClear={false}
      disabledOption={disabledOption}
      isMultiSelect={true}
      onChange={(value) => {
        selectValueRefUI.current = value;
        setSelectedUser(value);
      }}
      onDeselect={(value) => checkBeforeRemovingUser(value)}
      value={selectedUser}
      wrapperClass={"select-wrapper-class"}
      tooltipForDisabled={tooltipForDisabled}
      getPopupContainerForTooltip={() => document.body}
      tagRender={(item) => {
        return (
          <When
            condition={!search && item.value === selectValueRefUI?.current[0]}
          >
            <span className="ant-select-selection-placeholder no-tag">
              {SMART_APPROVAL_PO_CREATOR.SEARCH_USERS_PLACEHOLDER}
            </span>
          </When>
        );
      }}
    />
  );
};

const UsersPermissionComponent = (props) => {
  const deleteClickRef = useRef(false);
  const {
    createEditPermissionUsers,
    reloadSection,
    title,
    subTitle,
    removeUser,
    users,
    toggleProps,
  } = props;
  const [userPermissionForManipulation, setUserPermissionForManipulation] =
    useState(null);

  useEffect(() => {
    setUserPermissionForManipulation(
      createEditPermissionUsers?.map(({ value }) => value) || []
    );
    deleteClickRef.current = false;
  }, [createEditPermissionUsers]);

  const handleDeleteOnClick = async (deleteUser) => {
    await removeUser(deleteUser).then(() => {
      reloadSection();
      deleteClickRef.current = true;
    });
  };

  const showUserDropdown = toggleProps?.checked;

  return (
    <div className="users-permission-container">
      <Box
        justifyContent={"flexStart"}
        alignItem={"center"}
        className={"p-b-16px"}
      >
        <Typography {...title} />
        <When condition={toggleProps}>
          <Switch {...toggleProps} />
        </When>
      </Box>
      <Typography
        {...subTitle}
        className={showUserDropdown ? "p-b-24px" : ""}
      />
      <When condition={showUserDropdown}>
        <When condition={userPermissionForManipulation !== null}>
          <UserSelectDropdown
            {...props}
            key={
              deleteClickRef.current
                ? userPermissionForManipulation?.join("-")
                : ""
            }
            createEditPermissionUsers={userPermissionForManipulation}
          />
        </When>
        <SectionTransition
          loading={
            createEditPermissionUsers === null ||
            userPermissionForManipulation === null
          }
          sectionData={createEditPermissionUsers || []}
          renderElementInTheSection={({ label, id }) => {
            return (
              <Box
                wrap="nowrap"
                className={"p-v-12px p-h-16px approver-list-card"}
                alignContent="flexStart"
              >
                <Typography
                  variant={"body"}
                  ellipsis={{ tooltip: label }}
                  text={label}
                  className={"w-80"}
                />
                <CustomButton
                  accent={ACCENTS.DESTRUCTIVE}
                  variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
                  icon={<Icon iconName={"close"} />}
                  className={"w-20"}
                  onClick={() => {
                    handleDeleteOnClick(id);
                  }}
                />
              </Box>
            );
          }}
        />
      </When>
      <Divider className={"mt-4 m-b-12px"} />
    </div>
  );
};

export default UsersPermissionComponent;
