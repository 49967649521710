import { memo, useState } from "react";
import useToggle from "../../../hooks/useToggle";
import {
  DELETE_CONFIRMATION_MODAL_CONSTANT,
  Z_INDEX_FOR_SCA_POPUP,
} from "../../../utils/constant";
import Box from "../Box";
import Button from "../Button";
import Checkbox from "../Checkbox";
import Modal from "../Modal";
import Typography from "../Typography";
import When from "../When";
import Icon from "../Icon";
import {
  NOTIFICATION_TYPE,
  showNotification,
} from "../../../utils/notifications";
import CustomButton from "../CustomButton";
import { ACCENTS, BUTTON_VARIANT } from "../../../constants";
import { constructClassName } from "../../../utils";

function DeleteConfirmationModal({
  open,
  onCancel,
  title,
  subTitle,
  childComponent,
  mandatoryCheckboxConfirmation = true,
  onConfirm,
  confirmBtnLabel = DELETE_CONFIRMATION_MODAL_CONSTANT.confirmButtonLabel,
  confirmBtnAccent = ACCENTS.PRIMARY,
  confirmBtnVariant = BUTTON_VARIANT.BUTTON_FILLED,
  deleteOnConfirm,
  showCloseIcon = false,
  disableCancelButton = false,
  width,
  showButtons = true,
  confirmButtonClassName = "",
  cancelButtonClassName = "",
}) {
  const { isOpen: isChecked, toggle } = useToggle();

  const [confirmClicked, setConfirmClicked] = useState(false);

  function handleCheckboxConfirmation() {
    toggle();
  }

  function handleConfirmClicked() {
    setConfirmClicked(true);
    deleteOnConfirm()
      .then(() => {
        onConfirm && onConfirm();
      })
      .catch((error) => {
        onCancel();
        showNotification(
          NOTIFICATION_TYPE.error,
          "",
          error?.response?.data?.message
        );
      });
  }

  return (
    <Modal
      isOpen={open}
      showHeader={false}
      getContainer={false}
      zIndex={Z_INDEX_FOR_SCA_POPUP}
      width={width}
      hide={onCancel}
      closable={true}
      showCloseIcon={true}
    >
      <Box rowGap={16}>
        <Box justifyContent={"flexStart"}>
          <Box
            direction="row"
            rowGap="0px"
            columnGap="0px"
            justifyContent="spaceBetween"
            className="width-full"
          >
            <Typography
              variant="title"
              text={title}
              fontSize={"24"}
              responsiveFontSize={false}
              className={"text-break mb-3"}
            />
          </Box>
          {subTitle && <Typography variant="body" text={subTitle} />}
          {childComponent && childComponent()}
          <When condition={mandatoryCheckboxConfirmation}>
            <Checkbox
              disabled={confirmClicked}
              onChange={handleCheckboxConfirmation}
              checked={isChecked}
            >
              {DELETE_CONFIRMATION_MODAL_CONSTANT.checboxConfirmationLabel}
            </Checkbox>
          </When>
        </Box>
        <When condition={showButtons}>
          <Box className={"w-100"}>
            <CustomButton
              text={confirmBtnLabel}
              accent={confirmBtnAccent}
              variant={confirmBtnVariant}
              disabled={mandatoryCheckboxConfirmation && !isChecked}
              loading={confirmClicked}
              showLoader={true}
              onClick={handleConfirmClicked}
              className={constructClassName([
                "m-r-20px",
                confirmButtonClassName,
              ])}
              customSize={"large"}
            />
            <CustomButton
              text={DELETE_CONFIRMATION_MODAL_CONSTANT.cancelButtonLabel}
              accent={ACCENTS.SECONDARY}
              variant={BUTTON_VARIANT.BUTTON_HOLLOW}
              onClick={onCancel}
              className={constructClassName([cancelButtonClassName])}
              disabled={disableCancelButton && confirmClicked}
              customSize={"large"}
            />
          </Box>
        </When>
      </Box>
    </Modal>
  );
}

export default memo(DeleteConfirmationModal);
