import React, { memo } from "react";
import { DatePicker as ADatePicker, Form } from "antd";
import moment from "moment";
import { Icon, Label } from "../../common";
import "../CommonDatePicker/CommonDatePicker.scss";
import { constructClassName } from "../../../utils";

const { RangePicker } = ADatePicker;

function DatePicker({
  name,
  label,
  rules,
  onChange,
  validateTrigger,
  format = "DD/MM/YYYY",
  picker,
  withRange,
  noDisabledDate = false,
  defaultRangeValue = [moment(moment(), format), moment(moment(), format)],
  suffixIcon = <Icon iconName={"calendar_month"} />,
  bordered,
  placeholder,
  className,
  defaultValue = moment(moment(), format),
  showToday = true,
  disabledDate,
}) {
  const isMandatoryField =
    Array.isArray(rules) && rules?.some((el) => el?.required === true);

  // const disabledDate = (current) => current?.valueOf() > Date.now(); // disabled future date for selection
  if (withRange) {
    return (
      <RangePicker
        popupClassName="kloo-range-picker"
        defaultValue={defaultRangeValue}
        format={format}
        picker={picker}
        onChange={onChange}
        // disabledDate={disabledDate}
      />
    );
  }
  return (
    <Form.Item
      name={name}
      label={label ? <Label text={label} optional={!isMandatoryField} /> : null}
      rules={rules}
      validateTrigger={validateTrigger}
    >
      <ADatePicker
        popupClassName="kloo-date-picker"
        suffixIcon={suffixIcon}
        clearIcon={<Icon iconName={"cancel"} />}
        className={constructClassName(["common-date-picker", className])}
        defaultValue={defaultValue}
        format={format}
        picker={picker}
        onChange={onChange}
        showToday={showToday}
        disabledDate={disabledDate}
        bordered={bordered}
        placeholder={placeholder}
        // disabledDate={disabledDate}
      />
    </Form.Item>
  );
}

export default memo(DatePicker);
