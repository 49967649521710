import { LOCAL_STORAGE_KEY } from "./constant";

export function getItem(item) {
  return localStorage.getItem(item);
}

export function setItem(key, value) {
  return localStorage.setItem(key, value);
}

export function removeItem(key) {
  return localStorage.removeItem(key);
}

export function saveCurrenciesToLS(value) {
  setItem(LOCAL_STORAGE_KEY.CURRENCIES, JSON.stringify(value));
}

export function getCurrenciesFromLS() {
  return JSON.parse(getItem(LOCAL_STORAGE_KEY.CURRENCIES));
}
