import {
  INVOICE_MANAGEMENT_TAB_KEYS,
  PURCHASE_ORDER_TABS_KEYS,
} from "../../constants";
import { generateInitialFilterState } from "../../utils";
import {
  INVOICE_FILTERS,
  PURCHASE_ORDER_FILTERS,
  RESET_FILTERS,
} from "../service/types";

const initialState = {
  [INVOICE_FILTERS]: generateInitialFilterState(INVOICE_MANAGEMENT_TAB_KEYS),
  [PURCHASE_ORDER_FILTERS]: generateInitialFilterState(
    PURCHASE_ORDER_TABS_KEYS
  ),
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case INVOICE_FILTERS:
      return {
        ...state,
        [INVOICE_FILTERS]: {
          ...state?.[INVOICE_FILTERS],
          [action.payload.activeTab]: {
            ...state?.[INVOICE_FILTERS]?.[action.payload?.activeTab],
            [action.payload?.key]: action.payload?.value,
          },
        },
      };
    case PURCHASE_ORDER_FILTERS:
      return {
        ...state,
        [PURCHASE_ORDER_FILTERS]: {
          ...state?.[PURCHASE_ORDER_FILTERS],
          [action.payload.activeTab]: {
            ...state?.[PURCHASE_ORDER_FILTERS]?.[action.payload?.activeTab],
            [action.payload?.key]: action.payload?.value,
          },
        },
      };
    case RESET_FILTERS:
      return {
        ...state,
        [INVOICE_FILTERS]: action.payload,
        [PURCHASE_ORDER_FILTERS]: action.payload,
      };
    default:
      return state;
  }
}
