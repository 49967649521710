import { memo } from "react";
import { constructClassName } from "../../../utils/functions";
import ComponentLoader from "../ComponentLoader";
import InfiniteScroll from "react-infinite-scroll-component";

function InfinitelyScroll({
  dataLength,
  onNext,
  hasMore,
  loader = <ComponentLoader />,
  height,
  children,
  className,
  style,
}) {
  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={onNext}
      hasMore={hasMore}
      loader={loader}
      height={height}
      style={style}
      className={constructClassName("kloo-infinite-scroll", className)}
    >
      {children}
    </InfiniteScroll>
  );
}

export default memo(InfinitelyScroll);
