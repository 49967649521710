import React, { Component } from "react";
import Typography from "../common/Typography";
import Box from "../common/Box";
import CustomButton from "../common/CustomButton";
import { ACCENTS, BUTTON_VARIANT } from "../../constants";
import {
  isDevelopmentEnvironment,
  isEngageEnvironment,
  isPlatformEnvironment,
} from "../../utils";
import * as Sentry from "@sentry/react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  state = {
    hasError: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      this.setState({
        hasError: false,
      });
    }
  }

  static getDerivedStateFromError() {
    const element = document.getElementsByTagName("body")[0];
    element.classList.remove("loader");
    const RouterList = document.getElementById("RouterList");
    RouterList?.classList?.remove?.("hide-RouterList");
    const loader = document.getElementById("appLoader");
    loader?.classList?.remove?.("apploader");
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (isDevelopmentEnvironment()) {
      console.log(error, errorInfo);
    }
    if (isPlatformEnvironment() && !isEngageEnvironment()) {
      Sentry.captureException(error, { extra: errorInfo });
    }
  }

  handleClick() {
    window.location.reload();
    this.setState({
      hasError: false,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box className="h-100" direction={"column"}>
          <Typography
            variant="title"
            text={"Oops!"}
            fontSize={24}
            mobileFontSize={18}
            desktopFontSize={18}
          />
          <Typography
            variant="body"
            text={"An error has occured. We are looking into this."}
            fontSize={24}
            mobileFontSize={18}
            desktopFontSize={18}
          />
          <CustomButton
            text={"Try Again"}
            accent={ACCENTS.PRIMARY}
            variant={BUTTON_VARIANT.BUTTON_FILLED}
            onClick={this.handleClick}
            customSize={"large"}
          />
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
