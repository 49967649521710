import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Typography,
  Box,
  Row,
  Column,
  When,
  CustomButton,
  Icon,
  PDFViewer,
  AuditLogModal as CommonAuditLogModal,
} from "../../common";
import {
  constructClassName,
  downloadFileByJS,
  getUserOrgId,
  isGRNISectionVisible,
  isMatchedInvoiceSectionVisible,
  isOrgTypeOnlyPO,
  isPOAuditLogVisible,
  MODAL_TYPES,
  NOTIFICATION_TYPE,
  removeMessageListener,
  showNotification,
  stringToArray,
} from "../../../utils";
import {
  approvePurchaseOrder as closePurchaseOrder,
  downloadPDF,
  postPoComment,
} from "../../../api";
import {
  ACCENTS,
  BUTTON_VARIANT,
  ERROR_MSG,
  GRNI_OPERATION_CONFIG,
  MATCHED_INVOICES_OPERATION_CONFIG,
  NAVIGATION_URL,
  PO_ACTIONS,
  PURCHASE_REQUEST_PAGE_UPDATE,
  PURCHASE_ORDER_DOWNLOAD_DRAWER,
  PURCHASE_ORDER_TOAST_MSGS,
  PURCHASE_ORDER_CLOSE_DRAWER,
  MODULE_TYPES,
} from "../../../constants";
import "./download-purchase-order.scss";
import { PurchaseOrderPDFView } from "../PurchaseOrderPDFView";
import { useNavigate } from "react-router-dom";
import AuditLogModal from "../AuditLogModal";
import { useToggle, useUserType } from "../../../hooks";
import { useSelector } from "react-redux";
import { isMatchingHidden } from "../../../helper";
import { isAuditLogsEnabledInCreditNotes } from "../../../helper/CreditNotes";

const { MATCH_INVOICES_BUTTON } = MATCHED_INVOICES_OPERATION_CONFIG;
const { RECORD_GRNI_BUTTON } = GRNI_OPERATION_CONFIG;
const { CLOSE_PO_BUTTON, CLOSE_CONFIRMATION_MODAL } =
  PURCHASE_ORDER_DOWNLOAD_DRAWER;

const isPOStatusApproved = (status) => status === PO_ACTIONS.APPROVE;

function DownloadPurchaseOrderDrawer({
  open,
  purchaseOrderID,
  onClose,
  poId,
  reviewer,
  status,
  reloadTable,
  poDetails,
  showModal,
  hideModal,
  onClickOfCloseDrawer,
  version,
  taxCodeEnabled,
  entityEnabled,
  defaultEntityList,
  isGRNIEnabledForOrg,
}) {
  const [pdfLink, setPDFLink] = useState(null);
  const [onDeleteReload, setOnDeleteReload] = useState(false);
  const { isOpen: reloadGRNI, toggle: toggleReloadGRNI } = useToggle(false);
  const [totalAmountRef, setTotalAmountRef] = useState({
    totalGRNIAmount: 0,
    totalMatchedInvoiceAmount: 0,
    valueGoodsReceived: 0,
  });
  const navigate = useNavigate();
  const userTypeDetails = useUserType();
  const accountDetail =
    useSelector(
      (state) =>
        state &&
        state?.modular &&
        state?.modular?.accountDetail &&
        state?.modular?.accountDetail[0]
    ) || {};

  const isOnlyPOOrgType = isOrgTypeOnlyPO({
    orgType: accountDetail?.type,
    subOrgType: accountDetail?.sub_types,
  });

  useEffect(() => {
    downloadPDF(poId)
      .then((res) => {
        setPDFLink(res?.data?.result?.download_link);
      })
      .catch((e) =>
        showNotification(
          NOTIFICATION_TYPE.error,
          e?.response?.data?.message || ERROR_MSG.common
        )
      );
  }, []);

  const onDownloadClick = () => {
    downloadFileByJS(pdfLink, purchaseOrderID || "Rejected");
  };

  const onGRNIDataLoaded = (grniData) => {
    const gnriTotalAmount = grniData?.rawTotalAmount || 0;
    setTotalAmountRef((previous) => {
      return {
        ...previous,
        totalGRNIAmount: gnriTotalAmount,
        valueGoodsReceived: grniData.valueGoodsReceived,
      };
    });
  };

  const onMatchedInvoicesDataLoaded = (matchedInvoicesData) => {
    const matchedInvoiceAmount = matchedInvoicesData?.totalRawAmount || 0;
    setTotalAmountRef((previous) => {
      return {
        ...previous,
        totalMatchedInvoiceAmount: matchedInvoiceAmount,
      };
    });
  };

  const closePOApiCall = async () => {
    try {
      let payload = {
        ids: [poDetails.id],
        is_workflow_assigned: true,
      };
      await closePurchaseOrder(PO_ACTIONS.CLOSE, payload);
      showNotification(
        NOTIFICATION_TYPE.success,
        PURCHASE_ORDER_TOAST_MSGS.closed(false)
      );
      hideModal();
      closeDrawer();
      reloadTable();
    } catch (e) {
      showNotification(
        NOTIFICATION_TYPE.error,
        e?.response?.data?.message || ERROR_MSG.common
      );
    }
  };

  const ApprovedPOCTA = () => {
    const handleRecordGRNIClick = () => {
      showModal(MODAL_TYPES.GRNI_JOURNEY_MODAL, {
        type: "create",
        poData: poDetails,
        totalAmountObj: totalAmountRef,
        onSuccessComplete: toggleReloadGRNI,
      });
    };

    const handleMatchInvoicesClick = () => {
      if (isMatchingHidden()) {
        navigate(NAVIGATION_URL.SINGLE_MATCHING_INVOICE);
        return;
      }
      navigate(NAVIGATION_URL.INVOICE_MATCHING);
    };

    const openConfirmationModal = (PO_DATA) => {
      showModal(MODAL_TYPES.DELETE_CONFIRMATION_MODAL, {
        ...CLOSE_CONFIRMATION_MODAL,
        title: CLOSE_CONFIRMATION_MODAL.title(PO_DATA?.order_number),
        mandatoryCheckboxConfirmation: false,
        deleteOnConfirm: async () => closePOApiCall(PO_DATA?.id),
        onCancel: () => {
          hideModal();
        },
      });
    };

    return (
      <Box
        columnGap={20}
        className={"purchase-drawer-footer-refactored p-h-30px p-v-15px"}
      >
        <When condition={enableGRNIFeature}>
          <When condition={isGRNIEnabledForOrg}>
            <div className={"w-50 flex-1"}>
              <CustomButton
                icon={<Icon iconName={"add"} />}
                onClick={handleRecordGRNIClick}
                {...RECORD_GRNI_BUTTON}
              />
            </div>
          </When>
          <When condition={!isMatchingHidden()}>
            <When condition={enableMatchedInvoiceFeature}>
              <div className={"w-50 flex-1"}>
                <CustomButton
                  onClick={handleMatchInvoicesClick}
                  {...MATCH_INVOICES_BUTTON}
                />
              </div>
            </When>
          </When>
          <When condition={isMatchingHidden()}>
            <div className={"w-50 flex-1"}>
              <CustomButton
                onClick={() => openConfirmationModal(poDetails)}
                {...CLOSE_PO_BUTTON}
              />
            </div>
          </When>
        </When>
        <When condition={!isMatchingHidden()}>
          <CustomButton
            onClick={() => openConfirmationModal(poDetails)}
            {...CLOSE_PO_BUTTON}
          />
        </When>
      </Box>
    );
  };

  const enableGRNIFeature =
    isPOStatusApproved(status) &&
    isGRNISectionVisible({
      userTypeDetails,
      poDetails,
      userOrgId: getUserOrgId(),
    });

  const enableMatchedInvoiceFeature =
    isPOStatusApproved(status) &&
    isMatchedInvoiceSectionVisible({ userTypeDetails }) &&
    !isOnlyPOOrgType;

  const closeDrawer = () => {
    onClose();
    onClickOfCloseDrawer();
    onDeleteReload && reloadTable();
  };

  const drawerDynamicClasses = () => {
    let className = "";
    if (enableGRNIFeature) {
      className = "with-close-po-cta";
    }
    return ["po-details-scroll-view", className, "kloo-scrollbar"];
  };

  return (
    <Drawer
      className={
        "download-purchase-order-drawer refactored-version expanded-version"
      }
      open={open}
      onClose={closeDrawer}
      getContainer={false}
      mask={false}
      title={PURCHASE_ORDER_CLOSE_DRAWER.TITLE_VIEW}
    >
      <Box wrap={"nowrap"} columnGap={16} alignItem={"flexStart"}>
        <div className={"w-60"}>
          <div className={constructClassName(drawerDynamicClasses())}>
            <PurchaseOrderPDFView
              className={"m-r-8px"}
              showModal={showModal}
              hideModal={hideModal}
              reloadGRNI={reloadGRNI}
              reloadMatchedInvoiceList={reloadGRNI}
              reloadTable={reloadTable}
              enableGRNIFeature={enableGRNIFeature}
              enableMatchedInvoiceFeature={enableMatchedInvoiceFeature}
              onGRNIDataLoaded={onGRNIDataLoaded}
              onMatchedInvoicesDataLoaded={onMatchedInvoicesDataLoaded}
              setOnDeleteReload={setOnDeleteReload}
              taxCodeEnabled={taxCodeEnabled}
              entityEnabled={entityEnabled}
              defaultEntityList={defaultEntityList}
              version={version}
              {...poDetails}
              totalAmountRef={totalAmountRef}
              isGRNIEnabledForOrg={isGRNIEnabledForOrg}
            />
            <When condition={isAuditLogsEnabledInCreditNotes()}>
              <CommonAuditLogModal
                showModal={showModal}
                details={poDetails}
                type={MODULE_TYPES.PO}
                addComment={postPoComment}
                isAuditLogEnabled={isPOAuditLogVisible({
                  userTypeDetails,
                  poDetails,
                  userOrgId: getUserOrgId(),
                })}
              />
            </When>
            <When condition={!isAuditLogsEnabledInCreditNotes()}>
              <AuditLogModal showModal={showModal} poDetails={poDetails} />
            </When>
          </div>

          <ApprovedPOCTA />
        </div>
        <div className={"w-40"}>
          <Row>
            <When condition={!!pdfLink}>
              <PDFViewer url={pdfLink} />
            </When>
          </Row>
          <Row>
            <Column
              span={24}
              className="purchase-drawer-footer-refactored p-h-30px p-b-15px"
            >
              <Row>
                <Column className="gutter-row m-t-10px m-b-10px" span={24}>
                  <Box>
                    <Typography
                      variant="secondary"
                      text={PURCHASE_ORDER_DOWNLOAD_DRAWER.INFO_LABEL}
                    />
                    <Typography
                      variant="secondary"
                      fontStyle="semibold"
                      text={purchaseOrderID}
                    />
                  </Box>
                </Column>
              </Row>
              <When condition={!!reviewer}>
                <Row>
                  <Column className="gutter-row m-t-10px m-b-10px" span={24}>
                    <Box>
                      <Typography
                        variant="secondary"
                        text={
                          status === PO_ACTIONS.APPROVE
                            ? PURCHASE_ORDER_DOWNLOAD_DRAWER.APPROVED_BY
                            : PURCHASE_ORDER_DOWNLOAD_DRAWER.REJECTED_BY
                        }
                      />
                      <Typography
                        variant="secondary"
                        fontStyle="semibold"
                        text={reviewer}
                      />
                    </Box>
                  </Column>
                </Row>
              </When>
              <Row gutter={24}>
                <Column className="gutter-row" span={24}>
                  <CustomButton
                    text={PURCHASE_ORDER_DOWNLOAD_DRAWER.DOWNLOAD_BUTTON_LABEL}
                    icon={<Icon iconName={"file_download"} />}
                    accent={ACCENTS.PRIMARY}
                    variant={BUTTON_VARIANT.BUTTON_FILLED}
                    onClick={onDownloadClick}
                    disabled={!pdfLink}
                    className="w-100"
                    customSize={"large"}
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </div>
      </Box>
    </Drawer>
  );
}

DownloadPurchaseOrderDrawer.propTypes = {
  open: PropTypes.bool,
  purchaseOrderLink: PropTypes.string,
  purchaseOrderID: PropTypes.string,
  onClose: PropTypes.func,
  reloadTable: PropTypes.func,
};

export default DownloadPurchaseOrderDrawer;
