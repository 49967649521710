import { INVOICE_MANAGEMENT_TAB_KEYS } from "../../constants";
import { NOTIFICATION_TYPE, showNotification } from "../../utils/notifications";
import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import {
  ACCOUNT_PAYABLE_FILTER,
  BENEFICIRIES_LIST,
  PAYABLE_LIST,
  PAYABLE_LIST_PAID,
  PAYABLE_LIST_REVIEW,
  PAYABLE_LIST_TO_PAY,
  UPDATE_APURL,
} from "../service/types";
import { loadingStart, loadingStop } from "./loader";

export const getAccountPayableList =
  (status = "submitted", page = 1, extraParams = "") =>
  (dispatch) => {
    dispatch(loadingStart());
    return new Promise((resolve, reject) => {
      apiClient({
        method: "GET",
        url: PATH.accountPayable.getlist(status, page, extraParams),
      })
        .then((response) => {
          const details = {
            current_page: response?.data?.result?.meta?.current_page,
            data: response?.data?.result?.data,
            last_page: response?.data?.result?.meta?.last_page,
            per_page: response?.data?.result?.meta?.per_page,
            total: response?.data?.result?.meta?.total,
            filters: response?.data?.result?.meta?.filters,
          };
          resolve(response.data.result);
          dispatch({
            type: UPDATE_APURL,
            payload: "",
          });
          dispatch({
            type: PAYABLE_LIST,
            payload: details,
          });
          // need to remove in refactored version
          //start
          if (status === INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW) {
            dispatch({
              type: PAYABLE_LIST_REVIEW,
              payload: details,
            });
          }
          if (status === INVOICE_MANAGEMENT_TAB_KEYS.PAID) {
            dispatch({
              type: PAYABLE_LIST_PAID,
              payload: details,
            });
          }
          if (status === INVOICE_MANAGEMENT_TAB_KEYS.TO_PAY) {
            dispatch({
              type: PAYABLE_LIST_TO_PAY,
              payload: details,
            });
          }
          // end
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => dispatch(loadingStop()));
    });
  };
export const getBeneficiariesList = () => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.accountPayable.beneficiaries,
    })
      .then((response) => {
        resolve(response.data.result);
        dispatch({
          type: BENEFICIRIES_LIST,
          payload: response?.data?.result,
        });
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const createBeneficiaries = (data, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.accountPayable.beneficiaries,
      data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Beneficiary",
          " created successfully"
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createPayment = (data, type) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.accountPayable.invoicePayment,
      data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Payment",
          type === "payNow"
            ? "done successfully"
            : " Payment scheduled successfully"
        );
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//    reschedulePayment create /////////
export const reschedulePayment = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.accountPayable.reschedulePayment,
      data,
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Payment rescheduled successfully"
        );
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

////      reschedulePayment cancel payment  ///////
export const rescheduleCancelPayment = (id, statusKey) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.accountPayable.rescheduleCanceldelete(id, statusKey),
    })
      .then((response) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          "Payment cancelled successfully"
        );
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const checkPaymentHandler = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.accountPayable.checkPaymentOption,
      data,
    })
      .then((response) => {
        // showNotification(NOTIFICATION_TYPE.success, response)
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const downloadAccontPayableListing = async (
  statustab,
  accountPayablePageNo,
  accountPayableUrl
) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.accountPayable.downloadAccountPayList(
        statustab,
        accountPayablePageNo,
        accountPayableUrl
      ),
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const AccountFilterData = (data) => (dispatch) => {
  return dispatch({
    type: ACCOUNT_PAYABLE_FILTER,
    payload: data,
  });
};

// --------------account payble filter ----- //
export const accountPayableFilter =
  (accountStatus, page = 1, AccountUrl) =>
  (dispatch) => {
    dispatch(loadingStart());
    return new Promise((resolve, reject) => {
      apiClient({
        method: "GET",
        url: PATH.accountPayable.accountPayableFilter(
          accountStatus,
          page,
          AccountUrl
        ),
      })
        .then((response) => {
          const details = {
            current_page: response?.data?.result?.meta?.current_page,
            data: response?.data?.result?.data,
            last_page: response?.data?.result?.meta?.last_page,
            per_page: response?.data?.result?.meta?.per_page,
            total: response?.data?.result?.meta?.total,
            filters: response?.data?.result?.meta?.filters,
          };
          resolve(response?.data?.result);
          dispatch({
            type: UPDATE_APURL,
            payload: AccountUrl,
          });
          dispatch({
            type: PAYABLE_LIST,
            payload: details,
          });
          // need to remove in refactored version
          //start
          if (accountStatus === INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW) {
            dispatch({
              type: PAYABLE_LIST_REVIEW,
              payload: details,
            });
          }
          if (accountStatus === INVOICE_MANAGEMENT_TAB_KEYS.PAID) {
            dispatch({
              type: PAYABLE_LIST_PAID,
              payload: details,
            });
          }
          if (accountStatus === INVOICE_MANAGEMENT_TAB_KEYS.TO_PAY) {
            dispatch({
              type: PAYABLE_LIST_TO_PAY,
              payload: details,
            });
          }
          // end
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => dispatch(loadingStop()));
    });
  };

export const getFilterListAccount = (tabStatus) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.accountPayable.getFilterAccountList(tabStatus),
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
// ------------------ Smart Approval Invoice WorkFlow -------------//
export const workFlowList = (page = 1, searchUrl = "") => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.smart_approval.smartApprovalworkFlowList(page, searchUrl),
      searchUrl,
    })
      .then((response) => {
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const workFlowListV1 = (
  page = 1,
  searchUrl = "",
  type = "account-payable-approval"
) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.smart_approval.smartApprovalworkFlowListV1(
        page,
        searchUrl,
        type
      ),
      searchUrl,
    })
      .then((response) => {
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const assignToWorkFlow = (workflowId, invoicesIds) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.smart_approval.assignWorkflow,
      data: {
        workflow_id: workflowId,
        invoices: invoicesIds,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const assignWorkFlowInvoicePO = (
  type = "account-payable-approval",
  workflowId,
  refIds
) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.smart_approval.assignWorkFlowToInvoicePo,
      data: {
        workflow_id: workflowId,
        type: type,
        event_ref_ids: refIds,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteWorkFlowV1 = (workflowId, type) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.smart_approval.deleteWorkFlowV1(workflowId, type),
    })
      .then((response) => {
        resolve(response.data);
        showNotification(
          NOTIFICATION_TYPE.success,
          "Workflow deleted successfully."
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteWorkFlow = (workflowId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.smart_approval.deleteWorkFlow(workflowId),
    })
      .then((response) => {
        resolve(response.data);
        showNotification(
          NOTIFICATION_TYPE.success,
          "Workflow deleted successfully."
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllWorkFlowList = (type = "account-payable-approval") => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.smart_approval.getAllWorkFlows(type),
    })
      .then((response) => {
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSelectedWorkFlowDetail = (workflowId, type) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.smart_approval.getSelectedWorkFlowDetail(workflowId, type),
    })
      .then((response) => {
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// mark as paid faster payment //
export const MarkAsPaidPayment = (invoiceId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.accountPayable.markAsFasterPayment(invoiceId),
    })
      .then((response) => {
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// approve payment

export const approveNowPayment = (invoiceId) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.accountPayable.approveNowPayment(invoiceId),
    })
      .then((response) => {
        resolve(response.data);
        showNotification(
          NOTIFICATION_TYPE.success,
          "Invoice approved successfully"
        );
        resolve(response.data?.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
