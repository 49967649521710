import { useEffect, useState } from "react";
import { getAllRoles, getTeamManagerWithAttribute, getUsers } from "../api";

function useApproverDropdown() {
  const [approvers, setApprovers] = useState([]);

  useEffect(() => {
    Promise.all([getTeamManagerWithAttribute(), getAllRoles()]).then(
      setApproverDropdownValue
    );
  }, []);

  const setApproverDropdownValue = async (res) => {
    const otherRoles =
      res[0]?.data?.result?.map((option) => ({
        label: option.name,
        value: `${option.id}_attribute`,
      })) || [];
    const roles =
      res[1]?.data?.result
        ?.filter((item) => item.name !== "Platform User")
        ?.map((option) => ({
          label: option.name,
          value: `${option.id}_role`,
        })) || [];
    const users = await getUsers({ forSelect: true });
    setApprovers([
      {
        label: "Roles",
        options: [...roles, ...otherRoles],
      },
      {
        label: "Users",
        options: users.map((option) => ({
          ...option,
        })),
      },
    ]);
  };

  return [approvers];
}

export default useApproverDropdown;
