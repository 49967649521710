import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ACCENTS,
  BUTTON_VARIANT,
  PO_SEND_SUPPLIER_MODAL,
  NOTIFICATION_TYPE,
  ERROR_MSG,
} from "../../constants";
import { sendEmailtoSupplier } from "../../api";
import {
  Box,
  Column,
  CustomButton,
  Label,
  Modal,
  Row,
  CommonInput,
} from "../common";
import { showNotification } from "../../utils";
import CommonToolTip from "../common/Tooltip";

function SendSupplierModal({ selectedItems, open, onCancel, onEmailSuccess }) {
  const [form] = Form.useForm();
  const [initialFormValue, setInitiatFormValue] = useState({});
  const [selectedPO, setSelectedPO] = useState([]);

  const onSuccess = () => {
    onCancel();
    onEmailSuccess();
    showNotification(
      NOTIFICATION_TYPE.success,
      PO_SEND_SUPPLIER_MODAL.SUCCESS_MESSAGE(selectedItems)
    );
  };

  const onError = (e) => {
    showNotification(
      NOTIFICATION_TYPE.error,
      e?.response?.data?.message || ERROR_MSG.common
    );
  };

  const handleSendEmail = async () => {
    const values = await form.validateFields();
    const sendPoDetails = selectedPO.reduce(
      (acc, { po_supplier_id, po_supplier_deleted_at, allPoId }, index) => {
        if (!po_supplier_deleted_at) {
          allPoId?.map((poId) => {
            acc.push({
              po_id: poId,
              po_supplier_id: po_supplier_id,
              email: values[PO_SEND_SUPPLIER_MODAL.EMAIL_INPUT.name + index],
            });
          });
        }
        return acc;
      },
      []
    );
    try {
      const response = await sendEmailtoSupplier({
        po_suppliers: [...sendPoDetails],
      });
      if (response?.result?.[0]) {
        // selectedPO.map((poItem, index) => {
        //   poItem.allPoId?.map((poId) => {
        //     let findPoIndex = selectedItems.findIndex(
        //       (purOrdItem) => purOrdItem.id == poId
        //     );
        //     if (findPoIndex >= 0) {
        //       selectedItems[findPoIndex].po_supplier_email =
        //         values[PO_SEND_SUPPLIER_MODAL.EMAIL_INPUT.name + index];
        //     }
        //   });
        // });
        onSuccess();
      } else {
        onError(response?.message);
      }
    } catch (e) {
      onError(e);
    }
  };

  const poNumberFunction = (allPoNumber) => {
    return allPoNumber.length == 2
      ? allPoNumber.join(" and ")
      : allPoNumber.join(", ");
  };

  useEffect(() => {
    let poArray = [];
    selectedItems.forEach(
      ({
        order_number,
        supplier_name,
        po_supplier_email,
        po_supplier_id,
        po_supplier_deleted_at,
        id,
      }) => {
        let findPoExist = poArray.findIndex(
          (poItem) => poItem.po_supplier_id == po_supplier_id
        );
        if (findPoExist >= 0) {
          poArray[findPoExist]["allPoNumber"].push(order_number);
          poArray[findPoExist]["allPoId"].push(id);
        } else {
          poArray.push({
            po_supplier_id,
            po_supplier_email,
            supplier_name,
            allPoNumber: [order_number],
            po_supplier_deleted_at,
            allPoId: [id],
          });
        }
      }
    );
    const setForm = poArray.reduce(
      (acc, { supplier_name, po_supplier_email, allPoNumber }, index) => {
        acc[PO_SEND_SUPPLIER_MODAL.PO_NUMBER_INPUT.name + index] =
          poNumberFunction(allPoNumber);
        acc[PO_SEND_SUPPLIER_MODAL.SUPPLIER_INPUT.name + index] = supplier_name;
        acc[PO_SEND_SUPPLIER_MODAL.EMAIL_INPUT.name + index] =
          po_supplier_email;
        return acc;
      },
      {}
    );
    setSelectedPO([...poArray]);
    setInitiatFormValue({ ...setForm });
    Object.keys(setForm).map((_fields) => {
      form.setFieldValue(_fields, setForm[_fields] || null);
    });
  }, []);

  return (
    <Modal
      isOpen={open}
      title={PO_SEND_SUPPLIER_MODAL.TITLE(selectedItems)}
      subtitle={PO_SEND_SUPPLIER_MODAL.SUB_TITLE(selectedItems)}
      hide={onCancel}
      showDivider={false}
      className="w-75"
    >
      <Form form={form} initialValues={initialFormValue} layout="vertical">
        <Row span={24} className="w-100 mb-2">
          <Column span={8}>
            <Label
              text={PO_SEND_SUPPLIER_MODAL.PO_NUMBER_INPUT.label}
              optional={true}
            />
          </Column>
          <Column span={8}>
            <Label text={PO_SEND_SUPPLIER_MODAL.SUPPLIER_INPUT.label} />
          </Column>
          <Column span={8}>
            <Label text={PO_SEND_SUPPLIER_MODAL.EMAIL_INPUT.label} />
          </Column>
        </Row>
        {selectedPO?.map((poItem, index) => {
          const isSupplierDeleted = poItem?.po_supplier_deleted_at;
          return (
            <>
              <Row span={24} className="w-100">
                <Column span={8}>
                  <CommonToolTip
                    title={poNumberFunction(poItem.allPoNumber)}
                    trigger={PO_SEND_SUPPLIER_MODAL.TOOL_TIP.HOVER}
                  >
                    <>
                      <CommonInput
                        {...PO_SEND_SUPPLIER_MODAL.PO_NUMBER_INPUT}
                        label=""
                        name={
                          PO_SEND_SUPPLIER_MODAL.PO_NUMBER_INPUT.name + index
                        }
                        className=" w-90"
                        disabled={true}
                      />
                    </>
                  </CommonToolTip>
                </Column>
                <Column span={8}>
                  <CommonInput
                    {...PO_SEND_SUPPLIER_MODAL.SUPPLIER_INPUT}
                    label=""
                    name={PO_SEND_SUPPLIER_MODAL.SUPPLIER_INPUT.name + index}
                    className=" w-90"
                    disabled={true}
                  />
                </Column>
                <Column span={8}>
                  <CommonToolTip
                    title={PO_SEND_SUPPLIER_MODAL.TOOL_TIP.TITLE}
                    trigger={
                      isSupplierDeleted
                        ? PO_SEND_SUPPLIER_MODAL.TOOL_TIP.HOVER
                        : ""
                    }
                  >
                    <>
                      <CommonInput
                        className="w-90"
                        {...PO_SEND_SUPPLIER_MODAL.EMAIL_INPUT}
                        rules={PO_SEND_SUPPLIER_MODAL.EMAIL_INPUT.rules(
                          !isSupplierDeleted
                        )}
                        label=""
                        name={PO_SEND_SUPPLIER_MODAL.EMAIL_INPUT.name + index}
                        disabled={isSupplierDeleted}
                      />
                    </>
                  </CommonToolTip>
                </Column>
              </Row>
            </>
          );
        })}
        <Box className="m-t-30px" columnGap={60}>
          <CustomButton
            text={PO_SEND_SUPPLIER_MODAL.SEND_BUTTON.text}
            accent={ACCENTS.PRIMARY}
            variant={BUTTON_VARIANT.BUTTON_FILLED}
            showLoader={true}
            onClick={handleSendEmail}
            className="w-15"
            customSize={"large"}
            disabled={
              !selectedItems.filter((poItem) => !poItem?.po_supplier_deleted_at)
                .length
            }
          />
          <CustomButton
            text={PO_SEND_SUPPLIER_MODAL.CANCEL_BUTTON.text}
            accent={ACCENTS.SECONDARY}
            variant={BUTTON_VARIANT.BUTTON_HOLLOW}
            onClick={onCancel}
            className="w-15"
            customSize={"large"}
          />
        </Box>
      </Form>
    </Modal>
  );
}

export default SendSupplierModal;
