import { TOGGLE_STATUS } from "../service/types";

const initialState = {
  category: true,
  xero: true,
  customExpenses: true,
};
export default function categorisationReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_STATUS:
      return action.payload;
    default:
      return state;
  }
}
