import React from "react";
import { ComponentLoader, Typography } from "../../../common";
import "./kloo-ai-loader.scss";

const AIComponentLoader = ({ text = "" }) => {
  return (
    <>
      <ComponentLoader wrapperClassName={"async-loader"} />
      <Typography
        variant="body"
        fontStyle="semibold"
        text={`Kloo AI is generating ${text}`}
        className={`m-b-5px font-weight-600`}
      />
    </>
  );
};

export default AIComponentLoader;
