import React, { memo } from "react";
import PropTypes from "prop-types";
import { Tag as ATag } from "antd";
import { constructClassName } from "../../../utils";
import { TAG_VARIANT } from "../../../constants";
import "./CustomTag.scss";

function CustomTag({ text, variant = TAG_VARIANT.ACTIVE, className, ...rest }) {
  return (
    <ATag className={constructClassName([variant, className])} {...rest}>
      {text}
    </ATag>
  );
}

CustomTag.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default memo(CustomTag);
