import React from "react";
import { useNavigate } from "react-router-dom";
import { ACCENTS, BUTTON_VARIANT } from "../../../constants";
import NotFoundImage from "../../../assets/images/page-not-found.svg";
import Box from "../Box";
import Image from "../Image";
import Typography from "../Typography";
import CustomButton from "../CustomButton";
import "./NotFound.scss";
import { useAuth } from "../../../hooks/useAuth";

const NotFound = () => {
  const validUser = useAuth();

  const navigate = useNavigate();
  return (
    <Box className="not-found">
      <Box
        direction={"column"}
        className={"not-found-inner-wrap text-align-center p-4"}
      >
        <Image src={NotFoundImage} alt="Page not found" className="mw-100" />
        <Typography
          variant={"secondary"}
          fontSize={16}
          text={"We’re sorry, the page you requested could not be found."}
          className="mb-4"
        />
        {!validUser && (
          <CustomButton
            text={"Go back to login"}
            variant={BUTTON_VARIANT.BUTTON_FILLED}
            accent={ACCENTS.PRIMARY}
            onClick={() => navigate("/login")}
            customSize={"large"}
          />
        )}
      </Box>
    </Box>
  );
};

export default NotFound;
