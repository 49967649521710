import { Typography } from "../components/common";
import { MATCH_TYPE, PURCHASE_ORDER_TABS_KEYS } from "../constants";
import {
  isInvoiceMatchedViaSplitting,
  versionForNewCustomField,
} from "../helper";
import { isAgilisysOrgForPO } from "../helper/PurchaseOrder";
import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import {
  DEFAULT_FORMAT,
  LOCAL_STORAGE_KEY,
  getFormattedDate,
  getItem,
} from "../utils";
import {
  appendKeyForFilters,
  generateEncodedURIString,
  moneyFormatter,
  renderAmount,
  roundOff,
} from "../utils/functions";
import { getInvoiceForMatching } from "./InvoicePOMatchingAPI";

export function getSuppliers() {
  return apiClient({
    url: PATH.purchaseOrder.supplierList,
    method: "GET",
  });
}

export function createPurchaseOrder(data, isDraft) {
  return apiClient({
    url: `${PATH.purchaseOrder.create(isDraft)}`,
    headers: { "Content-Type": "multipart/form-data" },
    method: "POST",
    data,
  });
}

export function updatePurchaseOrder(purchaseOrderId, data, isDraft) {
  return apiClient({
    url: `${PATH.purchaseOrder.update(purchaseOrderId, isDraft)}?_method=PUT`,
    headers: { "Content-Type": "multipart/form-data" },
    method: "POST",
    data,
  });
}

export function resubmitPurchaseOrder(purchaseOrderId, data, isDraft) {
  return apiClient({
    url: `${PATH.purchaseOrder.resubmit(purchaseOrderId, isDraft)}?_method=PUT`,
    headers: { "Content-Type": "multipart/form-data" },
    method: "POST",
    data,
  });
}

export function deletePurchaseOrder(data) {
  return apiClient({
    url: `${PATH.purchaseOrder.create(false)}`,
    method: "DELETE",
    data: {
      ids: data,
    },
  });
}

export function assignPurchaseOrder(data) {
  return apiClient({
    url: PATH.purchaseOrder.assign,
    method: "PATCH",
    data,
  });
}

export function approvePurchaseOrder(status, data) {
  return apiClient({
    url: PATH.purchaseOrder.approve(status),
    method: "PATCH",
    data,
  });
}

export function downloadPDF(orderId) {
  return apiClient({
    url: PATH.purchaseOrder.downloadPDF(orderId),
    method: "PUT",
  });
}

export async function getPurchaseOrderList({
  page = 1,
  filtersUrl = "",
  searchUrl = "",
  statusUrl = "",
  isDownloadUrl = "",
  pageSize = 10,
  statusNotInUrl = "",
  nextApprover = "",
  version,
  cancelPreviousRequest = false,
}) {
  const pageSizeURL = generateEncodedURIString(
    "perPageCount",
    pageSize.toFixed()
  );

  const tempResponse = await apiClient({
    url: `${PATH.purchaseOrder.listing({
      apiVersion: "v1",
    })}?page=${page}${filtersUrl}${searchUrl}${statusUrl}${nextApprover}${isDownloadUrl}${pageSizeURL}${statusNotInUrl}`,
    method: "GET",
    cancelPreviousRequest,
  });

  const response = {
    data: {
      list: tempResponse?.data?.result?.data?.map(
        ({
          users_owner_first_name,
          users_owner_last_name,
          created_by_first_name,
          created_by_last_name,
          gross_amount,
          currency,
          bill_of_materials,
          attachments,
          ...rest
        }) => {
          const selectedTab = statusUrl.split("&status=")[1];
          const shownActiveOwnersOnly = [
            PURCHASE_ORDER_TABS_KEYS.CREATED,
            PURCHASE_ORDER_TABS_KEYS.IN_REVIEW,
          ].includes(selectedTab);
          let owner = "";
          if (
            !shownActiveOwnersOnly ||
            (shownActiveOwnersOnly && rest.owner_status === "active") ||
            (isAgilisysOrgForPO() &&
              (rest.owner_status === "active" ||
                rest.owner_status === "invited"))
          ) {
            owner =
              (isNaN(users_owner_first_name) ? users_owner_first_name : "") +
              " " +
              (isNaN(users_owner_last_name) ? users_owner_last_name : "");
          }
          const currencyData = currency || "";
          return {
            owner,
            raisedBy:
              (isNaN(created_by_first_name) ? created_by_first_name : "") +
              " " +
              (isNaN(created_by_last_name) ? created_by_last_name : ""),
            currencyData,
            gross_amount,
            amount: `${currency.symbol}${renderAmount(
              gross_amount,
              "commaWithDecimal"
            )}`,
            netAmount: `${moneyFormatter(
              rest?.net_amount,
              0,
              currency?.currency
            )}`,
            remainingAmount:
              rest?.remaining_amount || rest?.remaining_amount === 0
                ? `${currency.symbol}${renderAmount(
                    rest.remaining_amount,
                    "commaWithDecimal"
                  )}`
                : "",
            bill_of_materials: bill_of_materials?.map((item) => {
              return {
                amount: `${currency.symbol}${renderAmount(
                  item.total_amount,
                  "commaWithDecimal"
                )}`,
                price: `${currency.symbol}${renderAmount(
                  item.unit_price,
                  "commaWithDecimal"
                )}`,
                ...item,
              };
            }),
            attachments: attachments?.map((attachment) => {
              return {
                name: attachment.original_filename,
                isAttachedFile: true,
                ...attachment,
              };
            }),
            ...rest,
          };
        }
      ),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
    },
  };

  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}

export async function downloadPurchaseOrderList({
  page = 1,
  filtersUrl = "",
  searchUrl = "",
  statusUrl = "",
  selectedIdsForDownload,
  statusNotInUrl = "",
  nextApprover = "",
}) {
  const tempResponse = await apiClient({
    url: `${PATH.purchaseOrder.downloadExcel}?page=${page}${filtersUrl}${searchUrl}${statusUrl}${nextApprover}${statusNotInUrl}`,
    method: "POST",
    data: selectedIdsForDownload
      ? {
          ids: selectedIdsForDownload,
        }
      : undefined,
  });

  return {
    data: {
      downloadLink: tempResponse?.data?.result?.download_link,
    },
  };
}

export function createSupplierInPO(data) {
  return apiClient({
    url: PATH.purchaseOrder.addSupplier,
    method: "POST",
    data,
  });
}

export async function downloadGRNIList({ id }) {
  const tempResponse = await apiClient({
    url: PATH.purchaseOrder.GRNI.downloadExcel(id),
    method: "GET",
  });

  return {
    data: {
      downloadLink: tempResponse?.data?.result?.download_link,
    },
  };
}

export async function getGRNIList({ id, currencyShortName }) {
  const tempResponse = await apiClient({
    url: PATH.purchaseOrder.GRNI.listing(id),
    method: "GET",
  });

  let extraDetails = {
    sum: 0,
    currency: currencyShortName,
    totalPositiveAmount: 0,
    totalCorrectionAmount: 0,
  };

  let response = {
    list: tempResponse?.data?.result?.data?.map(
      ({
        received_date,
        received_amount,
        first_name,
        last_name,
        is_correction,
        ...rest
      }) => {
        extraDetails.sum += received_amount || 0;
        if (received_amount > 0 && !rest.invoice_id) {
          extraDetails.totalPositiveAmount += received_amount;
        }
        if (is_correction) {
          extraDetails.totalCorrectionAmount -= received_amount;
        }
        return {
          date: getFormattedDate(received_date, DEFAULT_FORMAT),
          amount: `${moneyFormatter(
            received_amount,
            2,
            extraDetails.currency
          )}`,
          raised_by: `${first_name} ${last_name}`,
          received_amount,
          received_date,
          first_name,
          last_name,
          customRowClass: is_correction ? "destructive-background" : "",
          is_correction,
          ...rest,
        };
      }
    ),
  };

  response.totalAmount = extraDetails.currency
    ? `${moneyFormatter(extraDetails.sum, 2, extraDetails.currency)}`
    : 0;
  response.rawTotalAmount = roundOff(extraDetails.sum);
  response.valueGoodsReceived =
    extraDetails.totalPositiveAmount - extraDetails.totalCorrectionAmount;

  response.currency = extraDetails.currency;

  return response;
}

export async function getMatchedInvoiceList({ poId, currency }) {
  const tempResponse = await getInvoiceForMatching({
    page: 1,
    pageSize: 50,
    filtersUrl: `&filter=whereIn:match_type~Confirmed%7CPartial%7CSuggested`,
    searchUrl: "",
    poNumberUrl: generateEncodedURIString("poNumber", poId, true),
  });
  const sumOfInvoicesMatched = tempResponse?.list?.reduce(
    (acc, invoiceData) => {
      const { rawMatchedAmount, matchType } = invoiceData;
      switch (matchType) {
        case MATCH_TYPE.CONFIRMED:
        case MATCH_TYPE.PARTIAL:
          acc += rawMatchedAmount;
          break;
        default:
      }

      return acc;
    },
    0
  );

  tempResponse.totalAmount = `${moneyFormatter(
    sumOfInvoicesMatched,
    2,
    currency
  )}`;

  tempResponse.totalRawAmount = roundOff(sumOfInvoicesMatched);

  return tempResponse;
}

export function createGRNI({ data }) {
  return apiClient({
    url: PATH.purchaseOrder.GRNI.create,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    data,
  });
}

export function updateGRNI({ id, data }) {
  return apiClient({
    url: PATH.purchaseOrder.GRNI.update(id),
    method: "PUT",
    data,
  });
}

export function deleteGRNI({ id }) {
  return apiClient({
    url: PATH.purchaseOrder.GRNI.delete(id),
    method: "DELETE",
  });
}

export function addAttachments(data, poId) {
  return apiClient({
    url: PATH.purchaseOrder.addAttachments(poId),
    method: "POST",
    data,
  });
}

export function removeAttachment(data) {
  return apiClient({
    url: PATH.purchaseOrder.removeAttachment,
    method: "PUT",
    data: {
      attachmentIds: data,
    },
  });
}

export async function getAuditLogByPOId({
  id,
  page = 1,
  filtersUrl,
  pageSize = 10,
}) {
  const pageSizeURL = generateEncodedURIString(
    "perPageCount",
    pageSize.toFixed()
  );

  const tempResponse = await apiClient({
    url: `${PATH.purchaseOrder.auditLog}?auditable_id=${id}&page=${page}${pageSizeURL}${filtersUrl}`,
    method: "GET",
  });
  const response = {
    data: {
      list: tempResponse?.data?.result?.data
        ?.filter(
          (data) =>
            data?.content?.before?.length || data?.content?.after?.length
        )
        .map(({ id, email, actioning_user, content, created_at }) => {
          return {
            id,
            email,
            user: actioning_user,
            before: content?.before?.map((item) => (
              <Typography
                text={`${item.action}${item.value ? `: ${item.value}` : ""}`}
                variant={"body"}
                ellipsis={{
                  tooltip: `${item.action}${
                    item.value ? `: ${item.value}` : ""
                  }`,
                }}
              />
            )),
            after: content?.after?.map((item) => (
              <Typography
                text={`${item.action}${item.value ? `: ${item.value}` : ""}`}
                variant={"body"}
                ellipsis={{
                  tooltip: `${item.action}${
                    item.value ? `: ${item.value}` : ""
                  }`,
                }}
              />
            )),
            date: created_at,
          };
        }),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
    },
  };

  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}

export async function getPOForDropdown({ entityId }) {
  const res = await apiClient({
    url: `${PATH.purchaseOrder.poDropdown}${
      entityId ? `?entity_id=${entityId}` : ""
    }`,
    method: "GET",
  });
  return res?.data?.result;
}

export async function sendEmailtoSupplier(data) {
  const res = await apiClient({
    url: PATH.purchaseOrder.sendEmailtoSupplier,
    method: "PATCH",
    data,
  });
  return res?.data;
}

export async function getPurchaseOrderCreatorsList(module) {
  const response = await apiClient({
    url: PATH.purchaseOrder.getPurchaseOrderCreatorsList(module),
    method: "GET",
  });
  return response?.data;
}

export async function getFaAndPuUsersList() {
  const res = await apiClient({
    url: PATH.purchaseOrder.getFaAndPuUsers(),
    method: "GET",
  });
  return (
    res.data?.result?.user_organization?.map((organization) => ({
      value: organization.id,
      label: organization?.users
        ? `${organization?.users?.first_name} ${organization?.users?.last_name}`
        : "N/A",
    })) || []
  );
}

export function addPurchaseRequestCreator(data) {
  return apiClient({
    url: PATH.purchaseOrder.addPurchaseRequestCreator,
    method: "POST",
    data,
  });
}

export function removeUserFromPrCreatorList(userId) {
  return apiClient({
    url: PATH.purchaseOrder.removeUserFromPrCreatorList(userId),
    method: "PATCH",
  });
}

export async function postPoComment(data) {
  return apiClient({
    url: PATH.purchaseOrder.postComment,
    method: "POST",
    data,
  });
}

export async function getOrgAIEmail() {
  const orgId = getItem(LOCAL_STORAGE_KEY.ORD_ID);
  const result = await apiClient({
    url: PATH.purchaseOrder.getOrgAIEmail(orgId),
    method: "GET",
  });

  return result.data.result[0];
}

export async function getPurchaseOrderDetail(id) {
  const result = await apiClient({
    url: PATH.purchaseOrder.getPurchaseOrder(id),
    method: "GET",
  });
  const res = result?.data?.result[0];

  return {
    ...res,
    currencyData: res?.po_currency,
    owner_id: res?.owner,
    po_supplier_id: res?.supplier_id,
  };
}
