import { CATEGORY_ALL_DATA, TAX_RATES, XERO_CATEGORY } from "../service/types";

const initialState = {
  taxList: {},
  xeroList: {},
  categoryAllList: {},
};
export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case CATEGORY_ALL_DATA:
      return { ...state, categoryAllList: action.payload };
    case TAX_RATES:
      return { ...state, taxList: action.payload };
    case XERO_CATEGORY:
      return { ...state, xeroList: action.payload };
    default:
      return state;
  }
}
