import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  CustomAlert,
  Box,
  Button,
  CarouselStep,
  CommonInput,
  CustomButton,
  Modal,
  Typography,
  When,
  Label,
} from "../common";
import StepFrom from "./components/StepForm";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import { loadingStart, loadingStop } from "../../redux/action/loader";
import {
  getPayloadForWorkflow,
  getRequiredRule,
  manipulateDataForWorkFlowForm,
  showNotification,
} from "../../utils";
import {
  createWorkFlow,
  updateWorkFlow,
  updateWorkFlowLambdaApi,
} from "../../api";
import {
  ERROR_MSG,
  NOTIFICATION_TYPE,
  WORKFLOW_FORM,
  TYPE_OF_WORKFLOW,
  ACCENTS,
  BUTTON_VARIANT,
} from "../../constants";
import "./work-flow-modal.scss";
import { isWorkflowApiChangeToLambda } from "../../helper";

const initialApproverField = [
  {
    approverLabel: "1st approver",
    approverFieldName: "approver1",
    limitFieldName: "limit1",
    customFieldValueName: `custom_field_value_id1`,
  },
];

function WorkFlowModal({
  open,
  onCancel,
  workFlowData,
  approverOptions,
  approverList,
  activeKey,
  onSuccess,
}) {
  const [activeStep, setActiveStep] = useState(null);
  const [approverInputs, setApproverInputs] = useState(initialApproverField);
  const [steps, setSteps] = useState([]);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [disabledApprovers, setDisableApprovers] = useState([]);
  const [selectedStepType, setSelectedStepType] = useState();

  const stepFromRef = useRef();
  const stepCarouselRef = useRef();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (workFlowData) {
      const newSteps = manipulateDataForWorkFlowForm(workFlowData);
      setSteps(newSteps.steps);
      setActiveStep(newSteps.steps[0]);
      form.setFieldValue("workFlowName", newSteps.workflow_name);
    }
  }, [workFlowData]);

  const addNewStep = async () => {
    await stepCarouselRef.current.handleStepAdd(null);
  };

  const saveStepForm = async ({ shouldAdd }) => {
    return stepFromRef.current.handleForm().then((res) => {
      const keys = Object.keys(res);
      const stepId = keys.map((item) => res[item]).join("-");
      if (shouldAdd) {
        setApproverInputs(initialApproverField);
        stepFromRef.current.resetForm();
      }
      return {
        stepId: stepId,
        data: {
          ...res,
          totalSteps: approverInputs.length,
          stepTypeName: selectedStepType,
        },
      };
    });
  };

  const handleSubmit = async () => {
    form.validateFields().then(async (values) => {
      const finalSteps = await stepCarouselRef.current.handleStepAdd(
        null,
        false
      );
      dispatch(loadingStart());
      if (finalSteps?.length) {
        let payload = {
          workflow_name: values.workFlowName,
          workflow_type: TYPE_OF_WORKFLOW[activeKey],
          workflow_stories: getPayloadForWorkflow(finalSteps),
        };
        setDisabledSubmit(true);
        if (workFlowData) {
          payload.workflow_id = workFlowData.id;
          updateWorkFlowLambdaApi(payload)
            .then(handleSuccess)
            .catch(handleFailure)
            .finally(() => dispatch(loadingStop()));
        } else {
          createWorkFlow(payload)
            .then(handleSuccess)
            .catch(handleFailure)
            .finally(() => dispatch(loadingStop()));
        }
      }
    });
  };

  const handleCloseForm = async () => {
    await stepCarouselRef.current.goToPrevious();
  };

  const handleSuccess = () => {
    showNotification(
      NOTIFICATION_TYPE.success,
      workFlowData
        ? WORKFLOW_FORM.TOAST_MSG.UPDATE
        : WORKFLOW_FORM.TOAST_MSG.CREATE
    );
    onCancel();
    onSuccess();
    setDisabledSubmit(false);
  };

  const handleFailure = (e) => {
    showNotification(
      NOTIFICATION_TYPE.error,
      e?.response?.data?.message || ERROR_MSG.common
    );
    setDisabledSubmit(false);
  };

  return (
    <Modal
      isOpen={open}
      width={1200}
      hide={onCancel}
      title={
        workFlowData
          ? WORKFLOW_FORM.MODAL_TITLE_EDIT
          : WORKFLOW_FORM.MODAL_TITLE_CREATE
      }
      showDivider={false}
    >
      <Form form={form} className="workflow-form">
        <div className="work-flow-name-container-wrap">
          <div className="work-flow-name-container m-b-18px">
            <Label text={WORKFLOW_FORM.LABELS.WF_NAME} optional={false} />
            <CommonInput
              wrapperClass="name-input"
              name="workFlowName"
              placeholder={WORKFLOW_FORM.PLACEHOLDERS.WF_NAME}
              maxLength={40}
              rules={[
                getRequiredRule(
                  "",
                  false,
                  "Please enter a unique workflow name"
                ),
              ]}
            />
          </div>
          <StepFrom
            steps={steps}
            ref={stepFromRef}
            approverOptions={approverOptions}
            stepIndex={activeStep?.stepIndex}
            formData={activeStep?.data}
            approverInputs={approverInputs}
            setApproverInputs={setApproverInputs}
            approverList={approverList}
            addNewStep={addNewStep}
            isEditView={!!workFlowData}
            handleCloseForm={handleCloseForm}
            disabledApprovers={disabledApprovers}
            setDisableApprovers={setDisableApprovers}
            activeKey={activeKey}
            setSelectedStepType={setSelectedStepType}
            selectedStepType={selectedStepType}
          />
          <Box className="p-t-16px step-carousel-container">
            <CarouselStep
              ref={stepCarouselRef}
              steps={steps}
              addStep={saveStepForm}
              updateSteps={setSteps}
              editStep={({ step, index }) => {
                setActiveStep({ ...step });
              }}
            />
          </Box>
          <Box className="warn-box">
            <When condition={!!workFlowData}>
              <CustomAlert
                type="warning"
                message={
                  <Typography
                    variant="body"
                    text={WORKFLOW_FORM.WARNING_MSG_IM(
                      WORKFLOW_FORM.WARNING_LABEL[activeKey]
                    )}
                  />
                }
              />
            </When>
          </Box>
          <div className="btn-container m-t-16px">
            <CustomButton
              text={
                workFlowData
                  ? WORKFLOW_FORM.LABELS.UPDATE_WF_BTN
                  : WORKFLOW_FORM.LABELS.CREATE_WF_BTN
              }
              accent={ACCENTS.PRIMARY}
              variant={BUTTON_VARIANT.BUTTON_FILLED}
              onClick={handleSubmit}
              disabled={disabledSubmit}
              customSize={"large"}
            />
            <CustomButton
              text={
                workFlowData
                  ? WORKFLOW_FORM.LABELS.CANCEL_WF_BTN
                  : WORKFLOW_FORM.LABELS.ABANDON_WF_BTN
              }
              accent={ACCENTS.SECONDARY}
              variant={BUTTON_VARIANT.BUTTON_HOLLOW}
              onClick={onCancel}
              customSize={"large"}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
}

WorkFlowModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  workFlowData: PropTypes.object,
  workflowType: PropTypes.string,
};

export default WorkFlowModal;
