import axios from "axios";
import { refreshLoginWithoutDipatch } from "../action/auth";
import { CLIENT_ID, CLIENT_SECRET, PATH } from "./apiConstant";
import jwt_decode from "jwt-decode";
import { refreshTokenPayload } from "../../helper";
import { LOCAL_STORAGE_KEY, getItem } from "../../utils";
let authCall = false;

const defaultHeaders = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};
// export const defaultAxois = axios.create({
// });

const refreshTokenFromAPI = async (apiData) => {
  try {
    authCall = true;
    const SSO = getItem(LOCAL_STORAGE_KEY.SSO);
    const refreshLoginValue = refreshTokenPayload({ SSO: SSO });
    let refreshLoginData = await axios({
      method: "post",
      url: PATH.auth.refresh_login,
      data: refreshLoginValue,
      headers: {
        "Content-Type": "application/json",
      },
    });
    refreshLoginData = refreshLoginData?.data;

    if (refreshLoginData.result[0]) {
      Object.keys(refreshLoginData.result[0]).forEach((k) => {
        localStorage.setItem(k, refreshLoginData.result[0][k]);
      });
    }
    return apiData && apiClient(apiData);
  } catch (err) {
    if (err?.response?.status === 401) {
      // localStorage.clear();
      // sessionStorage.clear();
      // window.location.reload();
    }
  }
};

let cancelTokenSource;
export function apiClient({
  url,
  data = {},
  method = "",
  headers = {},
  noHeaders,
  cancelPreviousRequest = false,
  ...rest
}) {
  return new Promise(async (resolve, reject) => {
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");

    const requestPayload = {
      url,
      data,
      method,
      headers,
      noHeaders,
      ...rest,
    };
    if (accessToken === "" || accessToken === null) {
      delete axios.defaults.headers.common["Authorization"];
    }

    if (cancelPreviousRequest) {
      cancelTokenSource.cancel("Request canceled - new request initiated");
    }

    cancelTokenSource = axios.CancelToken.source();

    // toast.configure();
    axios({
      method,
      url,
      headers: {
        ...(noHeaders ? {} : defaultHeaders),
        ...headers,
      },
      data,
      ...rest,
    })
      .then(async (res) => {
        if (accessToken) {
          try {
            const decoded = jwt_decode(accessToken);
            if (decoded?.exp) {
              const time = new Date().getTime();
              if (decoded?.exp * 1000 <= time && !authCall) {
                await refreshTokenFromAPI();
                authCall = false;
              }
            }
          } catch (e) {
            // await refreshTokenFromAPI()
          }
        }
        if (
          res?.response?.status === 401 ||
          res?.data?.message === "Unauthenticated"
        ) {
          res = await refreshTokenFromAPI(requestPayload);
        }
        if (res) {
          resolve(res);
        } else {
          if (res.data && res.data.error) {
            console.error(res.data.error);
            reject(res.data.error);
            if (res.data.error.code === "ER_UNAUTHORIZED_CLIENT") {
              localStorage.clear();
              delete axios.defaults.headers.common["Authorozation"];
            } else if (res.data.statusCode === 400) {
            }
          } else {
            reject(res.data.error);
          }
        }
      })
      .catch(async (err) => {
        if (err?.response?.status === 401) {
          const res = await refreshTokenFromAPI(requestPayload);
          if (res) {
            resolve(res);
            return;
          }
        }
        reject(err);
      });
  });
}
