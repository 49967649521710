import { Form, Select } from "antd";
import { useState } from "react";
import { Label, TextField } from "../../../common";
import "./CurrencySelect.scss";
import { constructClassName } from "../../../../utils";

const { Option } = Select;
function CurrencySelect({
  disabled,
  className,
  currencies,
  onSelectHandle,
  optionalField = false,
  label,
  name,
  onChange,
  suffixIcon,
  suffixIconClassName,
  suffixIconTooltip,
  suffixTooltipList,
  showDropdownTextAsLabel = false,
  renderCurrencyLabel,
  rules = [],
  ...rest
}) {
  const [value, setValue] = useState("");
  const optionalFieldClassName = optionalField ? "optional-field" : "";
  return (
    <Form.Item
      name={name}
      label={
        label ? (
          <Label
            text={label}
            optional={optionalField}
            suffixIcon={suffixIcon}
            suffixIconClassName={suffixIconClassName}
            suffixIconTooltip={suffixIconTooltip}
            suffixTooltipList={suffixTooltipList}
          />
        ) : null
      }
      colon={false}
    >
      <Select
        placeholder="Currency"
        disabled={disabled}
        getPopupContainer={(trigger) => {
          return trigger;
        }}
        style={{ width: 70 }}
        optionLabelProp="label"
        dropdownMatchSelectWidth={false}
        popupClassName="currency-dropdown"
        showArrow
        onSelect={onSelectHandle}
        onDropdownVisibleChange={() => {
          setValue("");
        }}
        onChange={onChange}
        className={constructClassName([
          "common-currency-select",
          className || "",
          optionalFieldClassName,
        ])}
        dropdownRender={(menu) => (
          <>
            <TextField
              className={"currency-search"}
              value={value}
              onChange={setValue}
            />
            {menu}
          </>
        )}
        {...rest}
      >
        {currencies?.result
          ?.filter(
            ({ symbol, currency, currency_name }) =>
              currency.toLowerCase().includes(value.toLowerCase()) ||
              currency_name.toLowerCase().includes(value.toLowerCase()) ||
              symbol.includes(value)
          )
          .map((currencyData) => {
            const { id, symbol, currency, currency_name } = currencyData;
            const currencyLabel = `${symbol} - ${currency} - ${currency_name}`;
            return (
              <Option
                value={id}
                label={
                  showDropdownTextAsLabel
                    ? renderCurrencyLabel({ ...currencyData })
                    : symbol
                }
                key={id}
              >
                {renderCurrencyLabel
                  ? renderCurrencyLabel({ ...currencyData })
                  : currencyLabel}
              </Option>
            );
          })}
      </Select>
    </Form.Item>
  );
}

export default CurrencySelect;
