import { Form } from "antd";
import {
  CONFIRM_MATCHING_CONFIRMATION_MODAL,
  GRNI_OFFSET_RESET_SUFFIX_CONFIG,
  INVOICE_MATCHING_TABLE_COLUMNS_ALLOCATED_AMOUNT_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_GRNI_OFFSET_LABEL_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_PO_AFTER_MATCH_LABEL_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_PO_AFTER_OFFSET_LABEL_KEY,
  INVOICE_SPLITTING_GRNI_OFFSET_CONFIG,
  TOOLTIP_BG_COLOR,
} from "../../../../constants";
import {
  Box,
  Column,
  CommonInputNumber,
  CustomButton,
  Icon,
  InfiniteScrollTable,
  Popover,
  Row,
  Table,
} from "../../../common";
import CommonToolTip from "../../../common/Tooltip";
import {
  GRNI_OFFSET_MODAL_FIELD_RULES,
  amountFormatterWithoutCurrency,
  getOnlyAmount,
  moneyFormatter,
} from "../../../../utils";
import { calculateRemainingAmountAfterOffset } from "../../../../helper";

function GRNIOffsetTabularView({
  poDetails,
  allocatedAmount,
  form,
  tableHeight,
  threshold,
}) {
  const { GRNI_OFFSET_COLUMN_CONFIG } = INVOICE_SPLITTING_GRNI_OFFSET_CONFIG;
  const { FORM_FIELDS } = CONFIRM_MATCHING_CONFIRMATION_MODAL;
  const { TOOLTIP, ICON_BUTTON } = GRNI_OFFSET_RESET_SUFFIX_CONFIG;

  const getInvoiceAndPoDetails = ({ record, index }) => {
    return {
      poDetail: {
        remaining_amount: `${record?.remainingAmountInGBP}`,
        total_grni_amount: `${record?.total_grni_amount}`,
        net_amount: `${record?.net_amount}`,
      },
      invoiceDetail: {
        rawAmount:
          allocatedAmount[
            `${INVOICE_MATCHING_TABLE_COLUMNS_ALLOCATED_AMOUNT_KEY}${record.id}`
          ] || `0`,
      },
      threshold,
    };
  };

  const handleOffsetChange = ({ record, index, value }) => {
    const calculatedValues = calculateRemainingAmountAfterOffset({
      offsetValue: value,
      ...getInvoiceAndPoDetails({ record, index }),
    });

    form.setFieldsValue({
      [`${FORM_FIELDS[1].field.name}${index}`]:
        calculatedValues[FORM_FIELDS[1].field.name],
      [`${FORM_FIELDS[2].field.name}${index}`]:
        calculatedValues[FORM_FIELDS[2].field.name],
    });
  };

  const renderHeaders = ({
    label,
    key,
    record,
    index,
    defaultRender,
    tooltip,
  }) => {
    return (
      <Box>
        {defaultRender({ label, key, record, index, defaultRender })}
        <CommonToolTip title={tooltip || ""}>
          <Icon iconName={"info"} />
        </CommonToolTip>
      </Box>
    );
  };

  const handleResetClick = ({ key, record, index, value }) => {
    handleOffsetChange({
      record,
      index,
      value,
    });
    form.setFieldsValue({
      [`${key}${index}`]: value,
    });
    form.validateFields();
  };

  const renderBody = ({ label, key, record, index, defaultRender }) => {
    const currencyShortName = record?.currency?.currency || record?.currency;
    const currencySymbol = record?.currency?.symbol || record?.symbol;
    const calculatedValues = calculateRemainingAmountAfterOffset({
      initialFlow: true,
      ...getInvoiceAndPoDetails({ record, index }),
    });
    const isGRNIOffsetEnable = getOnlyAmount(record?.total_grni_amount) > 0;
    const NON_GRNI_PO_SYMBOL = "-";
    const GRNI_INPUT_FIELD_CONFIG = {
      wrapperClass: "allocated-amount-text-field",
      prefix: currencySymbol,
      controls: false,
      precision: 2,
      formatter: amountFormatterWithoutCurrency,
      isRequiredField: true,
      placeholder: "Enter offset amount",
      addonAfter: (
        <Popover {...TOOLTIP} color={TOOLTIP_BG_COLOR}>
          <CustomButton
            {...ICON_BUTTON}
            icon={<Icon iconName={"refresh"} />}
            onClick={() =>
              handleResetClick({
                key,
                record,
                index,
                value: calculatedValues.minimumOffsetValue,
              })
            }
          />
        </Popover>
      ),
    };

    switch (key) {
      case INVOICE_MATCHING_TABLE_COLUMNS_ALLOCATED_AMOUNT_KEY:
        return `${moneyFormatter(
          allocatedAmount[`${key}${record.id}`],
          2,
          currencyShortName
        )}`;
      case INVOICE_MATCHING_TABLE_COLUMNS_PO_AFTER_OFFSET_LABEL_KEY:
      case INVOICE_MATCHING_TABLE_COLUMNS_PO_AFTER_MATCH_LABEL_KEY:
        if (!isGRNIOffsetEnable) return NON_GRNI_PO_SYMBOL;
        return (
          <Form form={form}>
            {(values) => (
              <>
                <Form.Item
                  name={`${key}${index}`}
                  className="allocated-amount-text-field"
                  preserve={false}
                  initialValue={calculatedValues?.[key]}
                >
                  {currencySymbol}
                  {values[`${key}${index}`] || calculatedValues?.[key]}
                </Form.Item>
              </>
            )}
          </Form>
        );
      case INVOICE_MATCHING_TABLE_COLUMNS_GRNI_OFFSET_LABEL_KEY:
        if (!isGRNIOffsetEnable) return NON_GRNI_PO_SYMBOL;
        return (
          <Form
            form={form}
            initialValues={{
              [`${key}${index}`]: calculatedValues[FORM_FIELDS[0].field.name],
            }}
          >
            <CommonInputNumber
              {...GRNI_INPUT_FIELD_CONFIG}
              name={`${key}${index}`}
              rules={GRNI_OFFSET_MODAL_FIELD_RULES.OFFSET_AMOUNT({
                minOffsetAmount: calculatedValues.minimumOffsetValue,
                maxOffsetAmount: calculatedValues.maximumOffsetValue,
                currency: currencyShortName,
              })}
              onChange={(value) =>
                handleOffsetChange({
                  label,
                  key,
                  record,
                  index,
                  defaultRender,
                  value,
                })
              }
            />
          </Form>
        );
      default:
        return defaultRender({ label, key, record, index, defaultRender });
    }
  };

  return (
    <Row className={"flex-1 m-h-0px"}>
      <Column span={24}>
        <Row className={"m-h-16px"}>
          <Column span={24}>
            <Row>
              <InfiniteScrollTable
                columns={GRNI_OFFSET_COLUMN_CONFIG}
                renderBody={renderBody}
                renderHeaders={renderHeaders}
                data={poDetails}
                className={"p-h-0px"}
                tableClassName={"m-v-0px"}
                height={`${tableHeight}px`}
                isInfiniteScroll={false}
                showScrollBar={true}
              />
            </Row>
          </Column>
        </Row>
      </Column>
    </Row>
  );
}

export default GRNIOffsetTabularView;
