import React, { useEffect, useState } from "react";
import { Form } from "antd";
import {
  Box,
  ComponentLoader,
  CustomButton,
  Icon,
  PDFViewer,
  When,
} from "../../../common";
import Upload from "../../../common/Upload";
import InnerImageZoom from "react-inner-image-zoom";

import { ACCENTS, BUTTON_VARIANT, INVOICE_FORM } from "../../../../constants";
import {
  MAX_FILE_SIZE,
  NOTIFICATION_TYPE,
  getBase64,
  showNotification,
} from "../../../../utils";

import {
  duplicateInvoiceHandler,
  isResetFormWhenInvoiceDelete,
} from "../../../../helper";
import { ocrReceiptsUpload } from "../../../../redux/action/auth";

function UploadInvoice(props) {
  const {
    previewImage,
    setPreviewImage,
    form,
    selectedData,
    setError,
    setStoreDuplicateInvoiceData,
    setUploadButtonDisabled,
    isDisabledOnEdit,
    closeDataForm,
    setSelectedFileList,
    selectedFileList,
    saveFormData,
    setAttachmentDelete,
    isLineItemsRevampEnabled,
    showLineItems,
    resetForm,
    uploadContainerLoading,
    setUploadContainerLoading,
    deletedLineItemIdArray,
    lineItems,
  } = props;

  const { PO_NUMBER } = INVOICE_FORM;
  const [uploadArea, setUploadArea] = useState(true);
  const [uploadAreaDisabled, setUploadAreaDisabled] = useState(false);

  useEffect(() => {
    if (selectedData?.invoice_attachment_url) {
      setUploadArea(!selectedData?.invoice_attachment_url);
      setSelectedFileList({
        selectedFile: null,
        selectedFileList: [selectedData?.invoice_attachment_url],
      });
    }
  }, []);

  const handlePreview = async (file) => {
    var extension = file.name.substr(file.name.lastIndexOf(".") + 1);
    const preview = await getBase64(file);
    fetch(preview)
      .then((res) => res.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        setPreviewImage({
          isImg: extension.toLowerCase() !== "pdf",
          url: url,
        });
      });
  };

  const removeImage = async (file) => {
    if (selectedData) {
      const savedLineItems = lineItems?.map((item) => item.id) || [];
      if (savedLineItems.length) {
        deletedLineItemIdArray.current = savedLineItems;
      }
    }
    form.setFields([
      {
        name: "file",
        value: undefined,
      },
    ]);
    if (isResetFormWhenInvoiceDelete()) {
      resetForm();
    }
    setPreviewImage("");
    setUploadArea(!uploadArea);
    if (selectedData?.invoice_attachment_url) {
      setAttachmentDelete(true);
    }
  };

  const dataFromFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onChangeUpload = (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = [info.file];
        setUploadAreaDisabled(true);
        break;
      case "done":
        nextState.selectedFile = info.file;
        nextState.selectedFileList = [info.file];
        setUploadAreaDisabled(false);
        break;

      default:
        nextState.selectedFile = null;
        nextState.selectedFileList = [];
    }
    setSelectedFileList(nextState);
  };

  const UploadRequest = async ({ file, onSuccess }) => {
    try {
      setUploadContainerLoading(true);
      const data = await ocrReceiptsUpload(file);
      duplicateInvoiceHandler({
        data,
        setError,
        setStoreDuplicateInvoiceData,
        setUploadButtonDisabled,
      });
      saveFormData({ isUpload: true, data: data?.result });

      await form.validateFields([PO_NUMBER.name]);
    } catch (e) {
      if (!e.errorFields) {
        setUploadContainerLoading(false);
        showNotification(
          NOTIFICATION_TYPE.error,
          e?.response?.data?.message ??
            "Could not read the uploaded file, please fill the payment form fields manually."
        );
      }
    } finally {
      setUploadContainerLoading(false);
      handlePreview(file);
      setUploadArea(!uploadArea);
      setTimeout(() => {
        onSuccess("ok");
      }, 500);
    }
  };
  const fileValidations = MAX_FILE_SIZE(10);

  return (
    <Box
      wrap="nowrap"
      direction="column"
      alignItem="flexStart"
      alignContent="flexStart"
      justifyContent="flexStart"
      className={"w-55 p-l-20px"}
    >
      <Box className="position-absolute r-0px close-and-delete-buttons">
        <When condition={previewImage && !uploadContainerLoading}>
          <CustomButton
            icon={
              <Icon iconName={"delete"} className={"material-icons-outlined"} />
            }
            accent={ACCENTS.DESTRUCTIVE}
            variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
            onClick={removeImage}
            disabled={isDisabledOnEdit || uploadAreaDisabled}
          />
        </When>
        <CustomButton
          icon={<Icon iconName={"close"} />}
          accent={ACCENTS.BLACK}
          variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
          onClick={() => {
            closeDataForm();
          }}
        />
      </Box>
      <When condition={uploadArea && !uploadContainerLoading}>
        <Box className="inv-upload-cnt w-100 p-t-15px">
          <Form.Item
            name="file"
            valuePropName="fileList"
            getValueFromEvent={dataFromFile}
            noStyle
          >
            <Upload
              accept=".png,.jpg,.jpeg,.pdf"
              className="cursor-pointer"
              showUploadList={false}
              maxCount={1}
              multiple={false}
              {...fileValidations}
              onChange={onChangeUpload}
              fileList={selectedFileList?.selectedFileList}
              customRequest={UploadRequest}
              disabled={isDisabledOnEdit || uploadAreaDisabled}
            />
          </Form.Item>
        </Box>
      </When>
      <When condition={!(isLineItemsRevampEnabled() && showLineItems)}>
        <When condition={previewImage && !uploadContainerLoading}>
          <Box className="w-100 p-t-15px inv-upload-cnt">
            <When condition={!previewImage?.isImg}>
              <PDFViewer url={previewImage.url} />
            </When>
            <When condition={previewImage?.isImg}>
              <InnerImageZoom
                zoomScale={0.8}
                moveType="drag"
                hideHint={true}
                hideCloseButton={true}
                src={previewImage.url}
                zoomSrc={previewImage.url}
              />
            </When>
          </Box>
        </When>
      </When>
      <When condition={uploadContainerLoading}>
        <Box className="w-100 p-t-15px inv-upload-cnt">
          <ComponentLoader />
        </Box>
      </When>
    </Box>
  );
}

export default UploadInvoice;
