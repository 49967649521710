import React from "react";
import { Typography } from "../common";

export default function AppBodyContainer({
  selectedSidebar,
  isOpen,
  children,
  ref,
  notifications,
}) {
  return (
    <div className={`bodyCenter ${selectedSidebar?.split("/")[1] || ""}`}>
      <div
        className={`${
          isOpen.isLeftSidebar || isOpen.isRightSidebar
            ? isOpen.isLeftSidebar
              ? isOpen.isRightSidebar
                ? "active-content"
                : "inactive-content"
              : "collapse-content"
            : "inactive-content"
        }`}
      >
        {children}
      </div>
      {isOpen.isRightSidebar && (
        <div
          ref={ref}
          className={`rightSider ${isOpen.isRightSidebar ? "active" : ""}`}
        >
          <Typography
            variant={"title"}
            text={"Inbox"}
            fontSize={"24"}
            responsiveFontSize={false}
            className={"right-silder-title"}
          />
          {notifications && notifications.length ? (
            notifications.map((notification, index) => (
              <div className="rightSiderBox" key={index}>
                <span className="rightSiderOnlyText">{notification}</span>
              </div>
            ))
          ) : (
            <div className="empty_notification">Notification not found.</div>
          )}
        </div>
      )}
    </div>
  );
}
