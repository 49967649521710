import { memo, useEffect } from "react";
import { YapilyIntegrationConfig } from "../../../../constants/Integration/OpenBanking";
import {
  Box,
  Button,
  Card,
  CustomButton,
  Icon,
  Typography,
  When,
} from "../../../common";
import YapilyUserServiceConfirmation from "../YapilyUserServiceConfirmation";
import YapilyAuthentication from "./YapilyAuthentication";
import YapilyConsentConfirmation from "./YapilyConsentConfirmation";
import YapilyInstitutions from "./YapilyInstitutions";
import "./ConsentJourney.scss";
import { constructClassName } from "../../../../utils";
import useRafPolling from "../../../../hooks/useRafPolling";
import useMobile from "../../../../hooks/useMobile";
import "./ConsentJourney.scss";
import { ACCENTS, BUTTON_VARIANT } from "../../../../constants";

const { consentJourneyStepsConfig } = YapilyIntegrationConfig;

const ConsentJourneyTitle = ({ title }) => {
  return (
    <Typography
      variant={"title"}
      text={title}
      fontSize={"24"}
      responsiveFontSize={false}
    />
  );
};

const ConsentJourneySubTitle = ({ stepData, labelConfig, currentStep }) => {
  const { subTitle } = labelConfig[currentStep];
  return (
    <Typography
      variant={"secondary"}
      className={"yapily-consent-subTitle m-v-16px"}
      text={subTitle(
        stepData[consentJourneyStepsConfig[0].stepKey]?.full_name || ""
      )}
    />
  );
};

const ConsentJourneyTopNavigation = ({
  prev,
  onClose,
  labelConfig,
  currentStep,
}) => {
  const { title } = labelConfig[currentStep];
  return (
    <Box justifyContent="spaceBetween">
      <Icon
        iconName={"arrow_back"}
        className={"cursor-pointer"}
        onClick={prev}
      />
      <ConsentJourneyTitle title={title} />
      <Icon iconName={"close"} className={"cursor-pointer"} onClick={onClose} />
    </Box>
  );
};

const ConsentJourneyStep = (props) => {
  const { currentStep, next } = props;
  return (
    <>
      <When condition={currentStep === consentJourneyStepsConfig[0].stepKey}>
        <YapilyInstitutions next={next} />
      </When>
      <When condition={currentStep !== consentJourneyStepsConfig[0].stepKey}>
        <YapilyAuthenticationJourney {...props} />
      </When>
    </>
  );
};

const YapilyAuthenticationJourney = ({
  currentStep,
  stepData,
  next,
  onComplete,
  pollingPromise,
  generateAuthorizationPromise,
  labelConfig,
}) => {
  useRafPolling(() => {
    if (stepData && stepData[consentJourneyStepsConfig[1].stepKey]?.id) {
      pollingPromise(stepData[consentJourneyStepsConfig[1].stepKey].id);
    }
  }, 5000);

  useEffect(() => {
    generateAuthorizationPromise();
  }, []);

  switch (currentStep) {
    case consentJourneyStepsConfig[1].stepKey:
      return (
        <YapilyAuthentication
          stepData={stepData}
          next={next}
          onComplete={onComplete}
          currentStep={currentStep}
          loaderText={labelConfig[currentStep]?.loaderText}
        />
      );
    case consentJourneyStepsConfig[2].stepKey:
      return (
        <YapilyConsentConfirmation
          stepData={stepData}
          loaderText={labelConfig[currentStep]?.loaderText}
        />
      );
    default:
      return null;
  }
};

function YapilyConsentSteps(props) {
  const {
    currentStep,
    prev,
    showTopNavigation = false,
    className,
    labelConfig,
    showFooter = true,
  } = props;

  const { isMobile } = useMobile();
  const { title, footerButtonText } = labelConfig[currentStep];

  const cardActions =
    currentStep !== consentJourneyStepsConfig[0].stepKey && !showTopNavigation
      ? [
          <CustomButton
            text={footerButtonText}
            accent={ACCENTS.SECONDARY}
            variant={BUTTON_VARIANT.BUTTON_HOLLOW}
            onClick={prev}
            customSize={"large"}
          />,
        ]
      : [];

  return (
    <div
      className={constructClassName([
        "yapily-authentication-page",
        currentStep === consentJourneyStepsConfig[0].stepKey || isMobile
          ? "width-full"
          : "centered-page",
        className,
      ])}
    >
      <Card bordered={false} actions={cardActions}>
        <When condition={showTopNavigation}>
          <ConsentJourneyTopNavigation {...props} />
        </When>
        <When condition={!showTopNavigation}>
          <ConsentJourneyTitle title={title} />
        </When>
        <ConsentJourneySubTitle {...props} />
        <ConsentJourneyStep {...props} />
      </Card>
      <When condition={showFooter}>
        <YapilyUserServiceConfirmation />
      </When>
    </div>
  );
}

export default memo(YapilyConsentSteps);
