import { LINE_ITEM_ENTITY_INTEGRATION_KEY } from "../constants";
import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";

export async function getConfigList() {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.configurations.getConfigList,
  });
  return tempResponse?.data?.result;
}

export async function updateConfig({ data }) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.configurations.updateConfiguration,
    data,
  });
  return tempResponse?.data?.result;
}

export async function updateRequiredTaxCode({ data, isPo }) {
  const tempResponse = await apiClient({
    method: "PUT",
    url: PATH.configurations.taxCode.requiredTaxCode({ isPo }),
    data,
  });
  return tempResponse?.data?.result;
}

export async function getTaxCodeList({ key }) {
  const params = key ? `?config_key_name=${key}` : "";
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.configurations.taxCode.taxCodesList(params),
  });
  return tempResponse?.data?.result;
}

export async function addTaxCode({ data }) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.configurations.taxCode.taxCodesList(),
    data,
  });
  return tempResponse?.data?.result;
}

export async function updateTaxCode({ data }) {
  const tempResponse = await apiClient({
    method: "PUT",
    url: PATH.configurations.taxCode.taxCodesList(),
    data,
  });
  return tempResponse?.data?.result;
}

export async function deleteTaxCode({ data }) {
  const tempResponse = await apiClient({
    method: "PATCH",
    url: PATH.configurations.taxCode.taxCodesList(),
    data,
  });
  return tempResponse?.data?.result;
}

export async function addEntity({ data }) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.configurations.entity.list(),
    data,
  });
  return tempResponse?.data?.result;
}
export async function deleteEntity({ data }) {
  const tempResponse = await apiClient({
    method: "PATCH",
    url: PATH.configurations.entity.list(),
    data,
  });
  return tempResponse?.data?.result;
}

export async function getEntityList({ key }) {
  const params = key ? `?config_key_name=${key}` : "";
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.configurations.entity.list(params),
  });
  return tempResponse?.data?.result;
}

export async function addPrefixInEntity({ data }) {
  const tempResponse = await apiClient({
    method: "PUT",
    url: PATH.configurations.entity.list(),
    data,
  });
  return tempResponse?.data?.result;
}

export async function getEntityListWithERPDetails(data) {
  const { integrationType = LINE_ITEM_ENTITY_INTEGRATION_KEY.INVOICE } =
    data || {};
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.configurations.lineItem.list({ integrationType }),
  });
  return tempResponse?.data?.result;
}

export async function updateToggleDataOfEntityListWithERPDetails(data) {
  const tempResponse = await apiClient({
    method: "PUT",
    url: PATH.configurations.lineItem.update,
    data,
  });
  return tempResponse?.data?.result;
}

export async function getGrniLogged() {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.configurations.getGrniLogged,
  });
  return tempResponse?.data?.result?.result;
}
