import { Icon } from "../../components/common";
import {
  CREATE_CREDIT_NOTES_FORM_RULE,
  WORKFLOW_SELECT_RULE,
  allowOnlyNumberOnInputChangeUsingValue,
} from "../../utils";
import { INVOICE_MANAGEMENT_TAB_KEYS } from "../InvoiceManagement";
import { LC_SUPPLIER_PLACEHOLDER, SUPPLIER_PLACEHOLDER } from "../dynamic";
import { TOOL_TIP_TEXT_PO } from "../purchaseOrder";
import { ACCENTS, BUTTON_VARIANT, SIZE, TAG_VARIANT } from "../theme";

export const CREDIT_NOTES_TABLE_COLUMNS_VIEW_KEY = "view";
export const CREDIT_NOTES_TABLE_COLUMNS_STATUS_KEY = "applied_status";
export const CREDIT_NOTES_TABLE_COLUMNS_CREDIT_NOTE_NUMBER_KEY =
  "credit_note_number";
export const CREDIT_NOTES_TABLE_COLUMNS_CREDIT_NOTE_DATE_KEY =
  "credit_note_date";
export const CREDIT_NOTES_TABLE_COLUMNS_INVOICE_NUMBER_KEY = "invoice_number";
export const CREDIT_NOTES_TABLE_COLUMNS_INVOICE_AMOUNT_KEY =
  "totalAmountWithAppliedCreditNote";
export const CREDIT_NOTES_TABLE_COLUMNS_APPLIED_INVOICE_AMOUNT_KEY =
  "applied_amount";
export const CREDIT_NOTES_TABLE_COLUMNS_ADD_ACTION_KEY = "add";
export const CREDIT_NOTES_TABLE_COLUMNS_REMOVE_ACTION_KEY = "remove";
export const CREDIT_NOTES_TABLE_COLUMNS_ALLOCATED_AMOUNT_KEY =
  "allocated_credit_note_amount";
export const CREDIT_NOTES_TABLE_COLUMNS_TOTAL_DUE_KEY = "totalDue";
export const CN_WORKFLOW_KEY = "workflow_name";

export const PAGE_HEADER_INFO = {
  desc: "",
  header: "Credit notes",
  buttonIcon: "add_circle",
  buttonLabel: "Submit credit note",
  buttonLabelMobile: "Submit credit note",
  searchLabel: "Search credit notes",
};

export const CREDIT_NOTES_TABS_KEYS = {
  SUBMITTED: "submitted",
  IN_REVIEW: "in_review",
  COMPLETED: "completed",
  REJECTED: "rejected",
};

export const CN_SELECTED = (count) =>
  `credit note${count > 1 ? "s" : ""} selected`;

export const CREDIT_NOTES_BUTTONS = {
  ASSIGN_TO_WORKFLOW: "Assign to workflow",
  APPROVE_NOW: "Approve now",
  IN_REVIEW_APPROVE: "Approve/Reject",
  ACTIONS: "Actions",
  COMPLETED_DOWNLOAD: "Download",
  REJECT: "Reject",
  DELETE: "Delete",
  SEND_TO_SUPPLIER: "Email to supplier",
  APPROVALS: "Approvals",
  DELETE_CREDIT_NOTE: "Delete credit note",
  UPDATE: "Update",
  CANCEL: "Cancel",
  APPROVE: "Approve",
  EDIT: "Edit",
  SUBMIT: "Submit credit note",
  RESUBMIT: "Resubmit",
  CONFIRM: "Confirm",
  DOWNLOAD: "Download",
};

export const CREDIT_NOTES_TABS = [
  {
    key: CREDIT_NOTES_TABS_KEYS.SUBMITTED,
    label: "Submitted",
  },
  {
    key: CREDIT_NOTES_TABS_KEYS.IN_REVIEW,
    label: "In review",
  },
  {
    key: CREDIT_NOTES_TABS_KEYS.REJECTED,
    label: "Rejected",
  },
  {
    key: CREDIT_NOTES_TABS_KEYS.COMPLETED,
    label: "Completed",
  },
];

export const CREDIT_NOTES_TABLE_COLUMNS = {
  status: { label: "Status", key: CREDIT_NOTES_TABLE_COLUMNS_STATUS_KEY },
  creditNoteNumber: {
    label: "Credit note number",
    key: CREDIT_NOTES_TABLE_COLUMNS_CREDIT_NOTE_NUMBER_KEY,
  },
  date: { label: "Credit note date", key: "credit_note_date" },
  supplier: { label: SUPPLIER_PLACEHOLDER, key: "supplier_name" },
  invoice: { label: "Reference invoice number", key: "invoice_number" },
  desc: { label: "Description", key: "description" },
  next_approver: { label: "Next approver", key: "step_name" },
  workflow: { label: "Workflow", key: CN_WORKFLOW_KEY },
  amount: { label: "Total amount", key: "total_amount" },
  remaining_amount: {
    label: "Remaining credit amount",
    key: "remaining_amount",
  },
  view: { label: "View", key: CREDIT_NOTES_TABLE_COLUMNS_VIEW_KEY },
};

export const CREDIT_NOTES_TABWISE_COLUMNS = {
  [CREDIT_NOTES_TABS_KEYS.SUBMITTED]: [
    { ...CREDIT_NOTES_TABLE_COLUMNS.date, width: 15 },
    {
      ...CREDIT_NOTES_TABLE_COLUMNS.creditNoteNumber,
      width: 15,
    },
    { ...CREDIT_NOTES_TABLE_COLUMNS.supplier, width: 15 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.invoice, width: 15 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.desc, width: 20 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.amount, width: 10 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.view, width: 10, align: "center" },
  ],
  [CREDIT_NOTES_TABS_KEYS.IN_REVIEW]: [
    { ...CREDIT_NOTES_TABLE_COLUMNS.date, width: 10 },
    {
      ...CREDIT_NOTES_TABLE_COLUMNS.creditNoteNumber,
      width: 12,
    },
    { ...CREDIT_NOTES_TABLE_COLUMNS.supplier, width: 12 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.invoice, width: 14 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.desc, width: 10 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.next_approver, width: 10 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.workflow, width: 10 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.amount, width: 10 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.view, width: 8, align: "center" },
  ],
  [CREDIT_NOTES_TABS_KEYS.REJECTED]: [
    { ...CREDIT_NOTES_TABLE_COLUMNS.date, width: 15 },
    {
      ...CREDIT_NOTES_TABLE_COLUMNS.creditNoteNumber,
      width: 15,
    },
    { ...CREDIT_NOTES_TABLE_COLUMNS.supplier, width: 15 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.invoice, width: 15 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.desc, width: 20 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.amount, width: 10 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.view, width: 10, align: "center" },
  ],
  [CREDIT_NOTES_TABS_KEYS.COMPLETED]: [
    { ...CREDIT_NOTES_TABLE_COLUMNS.status, width: 10, align: "center" },
    {
      ...CREDIT_NOTES_TABLE_COLUMNS.creditNoteNumber,
      width: 15,
    },
    { ...CREDIT_NOTES_TABLE_COLUMNS.date, width: 10 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.supplier, width: 10 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.invoice, width: 15 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.desc, width: 10 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.amount, width: 10 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.remaining_amount, width: 10 },
    { ...CREDIT_NOTES_TABLE_COLUMNS.view, width: 10, align: "center" },
  ],
};

export const CREDIT_NOTES_EMAIL_UPLOAD = {
  BUTTON: {
    accent: ACCENTS.SECONDARY,
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
    customSize: SIZE.LARGE,
    text: "Email upload with Kloo AI",
  },
  MODAL: {
    title: "Email upload with Kloo AI",
    subTitle:
      "Upload credit notes directly from your mailbox with Kloo AI. Simply forward credit notes to the email address below for automated upload to Kloo.",
    mandatoryCheckboxConfirmation: false,
    showButtons: false,
    showCloseIcon: true,
    width: 575,
  },
};

export const CREDIT_NOTE_POPULATED_USING_AI =
  "Credit note data populated using Kloo AI";

export const CREDIT_NOTE_FORM = {
  credit_note_number: {
    name: "credit_note_number",
    rules: CREATE_CREDIT_NOTES_FORM_RULE.CREDIT_NOTE_NUMBER,
    placeholder: "Enter credit note number",
    suffixIconClassName: "brand-color",
    suffixIconTooltip: CREDIT_NOTE_POPULATED_USING_AI,
    label: "Credit note number",
  },
  credit_note_date: {
    name: "credit_note_date",
    placeholder: "Select credit note date",
    rules: CREATE_CREDIT_NOTES_FORM_RULE.CREDIT_NOTE_DATE,
    label: "Credit note date",
    suffixIconClassName: "brand-color",
    suffixIconTooltip: CREDIT_NOTE_POPULATED_USING_AI,
    format: "DD-MM-YYYY",
  },
  supplier: {
    label: SUPPLIER_PLACEHOLDER,
    placeholder: `Select ${LC_SUPPLIER_PLACEHOLDER}`,
    name: "supplier_id",
    rules: CREATE_CREDIT_NOTES_FORM_RULE.SUPPLIER,
    suffixIconClassName: "brand-color",
    suffixIconTooltip: CREDIT_NOTE_POPULATED_USING_AI,
  },
  invoiceNumber: {
    name: "invoice_number",
    label: "Reference invoice number",
    rules: CREATE_CREDIT_NOTES_FORM_RULE.INVOICE_NUMBER,
    placeholder: "Select reference invoice number",
    suffixIconClassName: "brand-color",
    suffixIconTooltip: CREDIT_NOTE_POPULATED_USING_AI,
  },
  currency: {
    name: "currency_id",
    label: "Currency",
    suffixIconClassName: "brand-color",
    suffixIconTooltip: CREDIT_NOTE_POPULATED_USING_AI,
  },
  net_amount: {
    name: "net_amount",
    label: "Net amount",
    placeholder: "Enter net amount",
    min: 0,
    precision: 2,
    controls: false,
    rules: CREATE_CREDIT_NOTES_FORM_RULE.NET_AMOUNT,
    suffixTooltipList: [
      {
        suffixIconTooltip: TOOL_TIP_TEXT_PO.netAmount,
        suffixIconClassName: "",
        suffixIcon: "info",
      },
    ],
  },
  tax_rate: {
    name: "tax_rate",
    label: "Tax rate",
    placeholder: "Enter tax rate amount",
    min: 0,
    precision: 2,
    controls: false,
    prefix: "%",
    rules: CREATE_CREDIT_NOTES_FORM_RULE.TAX_RATE,
    className: "flex-row-reverse",
    suffixTooltipList: [
      {
        suffixIconTooltip: TOOL_TIP_TEXT_PO.taxRate,
        suffixIconClassName: "",
        suffixIcon: "info",
      },
    ],
  },
  total_amount: {
    name: "total_amount",
    label: "Total amount",
    suffixTooltipList: [
      {
        suffixIconTooltip: TOOL_TIP_TEXT_PO.totalAmount,
        suffixIconClassName: "",
        suffixIcon: "info",
      },
    ],
  },
  remaining_amount: {
    name: "remaining_amount",
    label: "Remaining credit amount",
  },
  description: {
    name: "description",
    label: "Description",
    placeholder: "Enter description",
    rules: CREATE_CREDIT_NOTES_FORM_RULE.DESCRIPTION,
    suffixIconClassName: "brand-color",
    suffixIconTooltip: CREDIT_NOTE_POPULATED_USING_AI,
  },
  rejection_reason: {
    label: "Rejection reason",
    placeholder: "No rejection reason added",
  },
};

export const CREATE_BTN_DISABLED_TOOLTIP =
  "Please upload a credit note to proceed";
export const CREDIT_NOTE_UPLOADING_STATUS = "uploading";

export const CREDIT_NOTES_CREATE_FORM = {
  SUBMIT_FORM_TITLE: "Submit credit note",
  VIEW_FORM_TITLE: "View credit note",
};

export const GBP = "eb0e6736-72c1-41cc-9664-e52d79d0b8a7";

export const APPROVAL_BUTTONS = {
  APPROVE: {
    text: CREDIT_NOTES_BUTTONS.APPROVE_NOW,
    className: "w-100",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    key: CREDIT_NOTES_BUTTONS.APPROVE_NOW,
  },
  REJECT: {
    text: CREDIT_NOTES_BUTTONS.REJECT,
    className: "w-100",
    accent: ACCENTS.DESTRUCTIVE,
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
    key: CREDIT_NOTES_BUTTONS.REJECT,
  },
  ASSIGN_TO_WORKFLOW: {
    text: CREDIT_NOTES_BUTTONS.ASSIGN_TO_WORKFLOW,
    className: "w-100",
    accent: ACCENTS.SECONDARY,
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
    key: CREDIT_NOTES_BUTTONS.ASSIGN_TO_WORKFLOW,
  },
};

export const MODAL_TEXTS = {
  "Approve now": {
    TITLE: `Approve credit note`,
    SUBTITLE: (number) => `Credit note number: ${number}`,
    DESCRIPTION: `You are about to approve this credit note. Once approved, this credit note will be moved to the completed tab, ready to be paired with an invoice`,
    CHECKLABEL: "I understand that this action cannot be undone.",
  },
  Approve: {
    TITLE: `Approve credit note`,
    SUBTITLE: (number) => `Credit note number: ${number}`,
    DESCRIPTION: `You are about to approve this credit note. Once approved, this credit note will be moved to the completed tab, ready to be paired with an invoice`,
    CHECKLABEL: "I understand that this action cannot be undone.",
  },
  Reject: {
    TITLE: `Reject credit note`,
    SUBTITLE: (number) => `Credit note number: ${number}`,
    DESCRIPTION: `You are about to reject this credit note. Once rejected, this credit note will be moved to the rejected tab, where it cannot be paired with an invoice`,
  },
  Delete: {
    TITLE: `Delete credit note`,
    SUBTITLE: (number) => `Credit note number: ${number}`,
    DESCRIPTION: `You are about to delete this credit note. Are you sure you would like to remove this credit note from your platform?`,
    CHECKLABEL: "I understand that this action cannot be undone.",
  },
};

export const CREDIT_NOTES_VIEW_ITEMS = {
  label: {
    fontStyle: "semibold",
    className: "m-b-0px",
    variant: "secondary",
  },
  value: {
    className: "m-b-15px",
    variant: "secondary",
  },
  placeholder: {
    className: "m-b-15px font-style-italic",
    variant: "secondary",
  },
};

export const CREDIT_NOTES_TOAST_MSGS = {
  created: "Credit note uploaded",
  updated: "Credit note updated",
  resubmitted: "Credit note resubmitted",
  approved: (isMulti) => `Credit note${isMulti ? "s" : ""} approved`,
  rejected: (isMulti) => `Credit note${isMulti ? "s" : ""} rejected`,
  deleted: (isMulti) => `Credit note${isMulti ? "s" : ""} deleted`,
  assigned: (isMulti) =>
    `Credit note${isMulti ? "s" : ""} assigned for approval`,
  applyRemoveInvoice: {
    ADDED: ({ hasMultipleInvoice }) =>
      `Invoice${hasMultipleInvoice ? "s" : ""} applied`,
    REMOVED: ({ hasMultipleInvoice }) =>
      `Invoice${hasMultipleInvoice ? "s" : ""} removed`,
    UPDATED: ({ hasMultipleInvoice }) =>
      `Invoice${hasMultipleInvoice ? "s" : ""} updated`,
  },
};

export const CREDIT_NOTES_DRAWER_HEADER = {
  TITLE: {
    variant: "title",
    fontSize: "24",
    responsiveFontSize: false,
  },
  GET_TITLE_TEXT: (id) =>
    id
      ? CREDIT_NOTES_CREATE_FORM.VIEW_FORM_TITLE
      : CREDIT_NOTES_CREATE_FORM.SUBMIT_FORM_TITLE,
  EDIT_BUTTON: {
    text: CREDIT_NOTES_BUTTONS.EDIT,
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    customSize: "large",
  },
  CLOSE_ICON_BUTTON: {
    icon: <Icon iconName={"close"} />,
    accent: ACCENTS.BLACK,
    variant: BUTTON_VARIANT.BUTTON_ONLY_ICON,
  },
};

export const BULK_ACTIONS = {
  APPROVE: "approve",
  APPROVE_NOW: "approveNow",
  ASSIGN_TO_WORKFLOW: "assignToWorkflow",
  REJECT: "reject",
  DELETE: "delete",
};

export const BULK_ACTION_BUTTON_CONFIG = {
  APPROVE_NOW: {
    label: CREDIT_NOTES_BUTTONS.APPROVE_NOW,
    text: CREDIT_NOTES_BUTTONS.APPROVE_NOW,
    key: BULK_ACTIONS.APPROVE_NOW,
  },
  ASSIGN_TO_WORKFLOW: {
    label: CREDIT_NOTES_BUTTONS.ASSIGN_TO_WORKFLOW,
    text: CREDIT_NOTES_BUTTONS.ASSIGN_TO_WORKFLOW,
    key: BULK_ACTIONS.ASSIGN_TO_WORKFLOW,
  },
  APPROVE: {
    label: CREDIT_NOTES_BUTTONS.APPROVE,
    text: CREDIT_NOTES_BUTTONS.APPROVE,
    key: BULK_ACTIONS.APPROVE,
  },
  REJECT: {
    label: CREDIT_NOTES_BUTTONS.REJECT,
    text: CREDIT_NOTES_BUTTONS.REJECT,
    key: BULK_ACTIONS.REJECT,
  },
  DELETE: {
    label: CREDIT_NOTES_BUTTONS.DELETE,
    text: CREDIT_NOTES_BUTTONS.DELETE,
    key: BULK_ACTIONS.DELETE,
  },
  CANCEL: {
    text: CREDIT_NOTES_BUTTONS.CANCEL,
    accent: ACCENTS.SECONDARY,
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
  },
  CONFIRM: {
    text: CREDIT_NOTES_BUTTONS.CONFIRM,
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
  },
};

export const CREDIT_NOTES_BULK_ACTION_BUTTON = {
  [CREDIT_NOTES_TABS_KEYS.SUBMITTED]: {
    BUTTON_CONFIG: [
      BULK_ACTION_BUTTON_CONFIG.APPROVE_NOW,
      BULK_ACTION_BUTTON_CONFIG.ASSIGN_TO_WORKFLOW,
      BULK_ACTION_BUTTON_CONFIG.REJECT,
      BULK_ACTION_BUTTON_CONFIG.DELETE,
    ],
  },
  [CREDIT_NOTES_TABS_KEYS.IN_REVIEW]: {
    BUTTON_CONFIG: [
      BULK_ACTION_BUTTON_CONFIG.APPROVE,
      BULK_ACTION_BUTTON_CONFIG.REJECT,
    ],
  },
  [CREDIT_NOTES_TABS_KEYS.REJECTED]: {
    BUTTON_CONFIG: [
      {
        text: CREDIT_NOTES_BUTTONS.DELETE,
        key: BULK_ACTIONS.DELETE,
        accent: ACCENTS.DESTRUCTIVE,
        variant: BUTTON_VARIANT.BUTTON_HOLLOW,
      },
    ],
  },
};

export const BULK_ACTION_CONTENT_MODULE = ({ actionType, module }) => {
  const pluralModule = `${module}s`;
  const contentByType = {
    [BULK_ACTIONS.APPROVE_NOW]: {
      title: `Approve ${pluralModule}`,
      subTitle: (modules) =>
        modules > 1
          ? `You are about to approve ${modules} ${pluralModule}.`
          : `You are about to approve this ${module}.`,
      primaryBTN: {
        text: CREDIT_NOTES_BUTTONS.APPROVE_NOW,
        className: "w-45",
        customSize: "large",
        showLoader: true,
      },
    },
    [BULK_ACTIONS.APPROVE]: {
      title: `Approve ${pluralModule}`,
      subTitle: (modules) =>
        modules > 1
          ? `You are about to approve ${modules} ${pluralModule}.`
          : `You are about to approve this ${module}.`,
      primaryBTN: {
        text: CREDIT_NOTES_BUTTONS.APPROVE,
        className: "w-45",
        customSize: "large",
        showLoader: true,
      },
    },
    [BULK_ACTIONS.REJECT]: {
      title: `Reject ${pluralModule}`,
      subTitle: (modules) =>
        modules > 1
          ? `You are about to reject ${modules} ${pluralModule}. Once these have been rejected, these ${pluralModule} will be moved to the rejected tab.`
          : `You are about to reject this ${module}. Once this has been rejected, this ${module} will be moved to the rejected tab.`,
      primaryBTN: {
        text: CREDIT_NOTES_BUTTONS.REJECT,
        accent: ACCENTS.DESTRUCTIVE,
        variant: BUTTON_VARIANT.BUTTON_HOLLOW,
        className: "w-45",
        customSize: "large",
        showLoader: true,
      },
    },
    [BULK_ACTIONS.DELETE]: {
      title: `Delete ${pluralModule}`,
      subTitle: (modules) =>
        modules > 1
          ? `You are about to delete ${modules} ${pluralModule}. Please note that deleted ${pluralModule} can be re-submitted`
          : `You are about to delete this ${module}. Please note that deleted ${pluralModule} can be re-submitted.`,
      primaryBTN: {
        text: CREDIT_NOTES_BUTTONS.CONFIRM,
        className: "w-45",
        customSize: "large",
        showLoader: true,
      },
    },
    [BULK_ACTIONS.ASSIGN_TO_WORKFLOW]: {
      title: "Assign to workflow",
      subTitle: (modules) =>
        `Which workflow would you like to assign ${
          modules > 1 ? "these" : "this"
        } ${module}${modules > 1 ? "s" : ""} to?`,
      primaryBTN: {
        text: CREDIT_NOTES_BUTTONS.ASSIGN_TO_WORKFLOW,
        className: "w-45",
        customSize: "large",
        showLoader: true,
      },
    },
  };

  return contentByType[actionType];
};

export const CN_BULK_ACTION_COLUMN = [
  { ...CREDIT_NOTES_TABLE_COLUMNS.creditNoteNumber, width: 25 },
  { ...CREDIT_NOTES_TABLE_COLUMNS.supplier, width: 25 },
  { ...CREDIT_NOTES_TABLE_COLUMNS.desc, width: 20 },
  { ...CREDIT_NOTES_TABLE_COLUMNS.amount, width: 20 },
  {
    key: "action",
    width: 10,
  },
];

export const BULK_ACTION_REJECTION_REASON = {
  label: "Rejection reason",
  placeholder: "Enter rejection reason",
  wrapperClass: "w-100",
  name: "rejection",
  maxLength: 300,
  labelCol: { span: 24 },
};

export const CREDIT_NOTES_DONWLOAD_MODAL = {
  FILE_NAME: "credit-notes",
  DOWNLOAD_AS_PDF_DESC: `Download individual files for each of the credit notes from the selected credit notes`,
  DOWNLOAD_AS_EXCEL_DESC:
    "Download a listing of all credit notes from the selected credit notess and their data for review and analysis.",
};

export const CREDIT_NOTES_TAG_CLASSNAMES = {
  Paid: TAG_VARIANT.ACTIVE,
  Unassigned: TAG_VARIANT.INACTIVE,
  Assigned: TAG_VARIANT.AWAITING_APPROVAL,
};

export const BULK_ACTION_WORKFLOW = {
  WORKFLOW_SELECT: {
    label: "Assign to workflow",
    name: "workFlowName",
    placeholder: "Select workflow",
    rules: WORKFLOW_SELECT_RULE,
    labelCol: { span: 24 },
  },
};

export const INVOICE_LINKING_PAGE_TABLE_ROW_HEIGHT = 48;
export const INVOICE_LINKING_PAGE_TABLE_DEFAULT_PAGE_SIZE = 10;

export const CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS = {
  invoice: {
    label: "Invoice number",
    key: CREDIT_NOTES_TABLE_COLUMNS_INVOICE_NUMBER_KEY,
  },
  description: {
    label: "Description",
    key: "description",
  },
  dueDate: { label: "Due date", key: "due_date" },
  totalAmount: {
    label: "Total amount",
    key: CREDIT_NOTES_TABLE_COLUMNS_INVOICE_AMOUNT_KEY,
  },
  totalAmountAfterCredit: {
    label: "Total amount after credit",
    key: "invoice_amount",
  },
  amount: {
    label: "Applied credit amount",
    key: CREDIT_NOTES_TABLE_COLUMNS_APPLIED_INVOICE_AMOUNT_KEY,
  },
  add: { label: "", key: CREDIT_NOTES_TABLE_COLUMNS_ADD_ACTION_KEY },
  allocatedCreditNoteAmount: {
    label: "Amount to be credited",
    key: CREDIT_NOTES_TABLE_COLUMNS_ALLOCATED_AMOUNT_KEY,
    ellipsis: false,
  },
  totalDue: {
    label: "Total due",
    key: CREDIT_NOTES_TABLE_COLUMNS_TOTAL_DUE_KEY,
  },
  remove: {
    label: "Remove",
    key: CREDIT_NOTES_TABLE_COLUMNS_REMOVE_ACTION_KEY,
  },
};

export const INVOICE_LINKING_CONFIG = {
  ADD_INVOICE_BUTTON: {
    text: "Add to invoice",
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    accent: ACCENTS.PRIMARY,
    className: "w-100",
  },
  LINKED_INVOICE_SECTION: {
    TITLE: ({ hasMultipleInvoices }) => {
      return {
        text: `This credit note has been added to the below invoice${
          hasMultipleInvoices ? "s" : ""
        }`,
        variant: "body",
        fontSize: 12,
      };
    },
    EDIT_BUTTON: {
      text: "Edit",
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      accent: ACCENTS.PRIMARY,
    },

    COLUMNS: [
      { ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.invoice, width: 30 },
      { ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.dueDate, width: 20 },
      {
        ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.totalAmountAfterCredit,
        width: 30,
        align: "right",
      },
      {
        ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.amount,
        width: 30,
        align: "right",
      },
    ],
  },
  FLOATING_DRAWER: {
    TITLE: {
      responsiveFontSize: false,
      fontSize: "24",
      variant: "title",
      text: "Credit note details",
    },
  },
  FLOATING_BUTTON: {
    TITLE: {
      className: "invoice-icon-title",
      variant: "body",
      text: "Credit note details",
    },
  },
};

export const INVOICE_LINKING_PAGE_CONFIG = {
  HEADER: {
    variant: "title",
    fontSize: 24,
    responsiveFontSize: false,
    text: "Apply credit note",
  },
  SUGGESTED_INVOICE: {
    TITLE: {
      variant: "secondary",
      fontSize: "16",
      text: "1. Select the invoices that you want to apply the credit note to.",
    },
    COLUMNS: [
      { ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.add, width: 5 },
      { ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.invoice, width: 25 },
      { ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.description, width: 30 },
      { ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.dueDate, width: 20 },
      { ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.totalAmount, width: 20 },
    ],
  },
  APPLIED_INVOICE: {
    TITLE: {
      variant: "secondary",
      fontSize: "16",
      text: "2. Determine the credit note split across the selected invoices to change the amount due to be paid.",
    },
    COLUMNS: [
      { ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.invoice, width: 20 },
      { ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.dueDate, width: 10 },
      {
        ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.totalAmount,
        width: 20,
      },
      {
        ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.allocatedCreditNoteAmount,
        width: 30,
      },
      {
        ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.totalDue,
        width: 10,
        align: "left",
      },
      {
        ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.remove,
        width: 10,
        align: "center",
      },
    ],
    COLUMNS_WITH_DESCRIPTION: [
      { ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.invoice, width: 15 },
      {
        ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.description,
        width: 15,
        align: "left",
      },
      { ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.dueDate, width: 10 },
      {
        ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.totalAmount,
        width: 15,
      },
      {
        ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.allocatedCreditNoteAmount,
        width: 25,
      },
      {
        ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.totalDue,
        width: 10,
        align: "left",
      },
      {
        ...CREDIT_NOTES_WITH_INVOICE_TABLE_COLUMNS.remove,
        width: 10,
        align: "center",
      },
    ],
    AMOUNT_INPUT: {
      wrapperClass: "allocated-amount-text-field w-100 m-b-0px",
      step: 0.01,
      controls: false,
      prefix: "£",
      placeholder: "Enter amount",
      onInput: allowOnlyNumberOnInputChangeUsingValue,
      precision: 2,
      min: 0,
    },
  },
  FOOTER: {
    LESS_CREDIT_AMOUNT: "Total allocated credit amount:",
    REMAINING_CREDIT_AMOUNT: "Remaining credit amount:",
    CTA: [
      {
        variant: BUTTON_VARIANT.BUTTON_FILLED,
        accent: ACCENTS.PRIMARY,
        text: "Apply credit",
        showLoader: true,
        className: "w-45",
      },
      {
        variant: BUTTON_VARIANT.BUTTON_FILLED,
        accent: ACCENTS.WHITE,
        text: "Cancel",
        className: "w-45",
      },
    ],
  },
};

export const ICON_ONLY_BUTTON_SECONDARY_CONFIG = {
  variant: BUTTON_VARIANT.BUTTON_ONLY_ICON,
  accent: ACCENTS.SECONDARY,
};

export const ICON_ONLY_BUTTON_DESTRUCTIVE_CONFIG = {
  variant: BUTTON_VARIANT.BUTTON_ONLY_ICON,
  accent: ACCENTS.DESTRUCTIVE,
};

export const CREDIT_NOTE_DOWNLOAD_BUTTON = {
  text: "Download",
  className: "mx-auto",
  accent: ACCENTS.PRIMARY,
  variant: BUTTON_VARIANT.BUTTON_FILLED,
  customSize: SIZE.SMALL,
};

export const DISABLED_INVOICE_TOOLTIP_STATUS_WISE_OBJ = {
  [INVOICE_MANAGEMENT_TAB_KEYS.PAID]: {
    title: "This invoice has already been paid",
  },
  [INVOICE_MANAGEMENT_TAB_KEYS.SCHEDULED]: {
    title: "This invoice has been scheduled for payment",
  },
};
