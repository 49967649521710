// loader
export const LOADER_START = "LOADER_START";
export const LOADER_STOP = "LOADER_STOP";

// auth type
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
// update profile
export const UPDATE_PROFILE = "UPDATE_PROFILE";
// cards
export const CARD_LIST = "CARD_LIST";
export const MY_CARDS = "MY_CARDS";
export const PENDING_CARDS = "PENDING_CARDS";
export const MY_APPROVAL_CARDS = "MY_APPROVAL_CARDS";
export const ALL_APPROVAL_CARDS = "ALL_APPROVAL_CARDS";
export const CARD_FILTER = "CARD_FILTER";
export const CARD_OPTIONS = "CARD_OPTIONS";
export const CARD_TOTAL_DATA = "CARD_TOTAL_DATA";

// transactions
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";

//account payable
export const PAYABLE_LIST = "PAYABLE_LIST";
export const PAYABLE_LIST_REVIEW = "PAYABLE_LIST_REVIEW";
export const PAYABLE_LIST_TO_PAY = "PAYABLE_LIST_TO_PAY";
export const PAYABLE_LIST_PAID = "PAYABLE_LIST_PAID";
export const UPDATE_APURL = "UPDATE_APURL";
export const BENEFICIRIES_LIST = "BENEFICIRIES_LIST";
export const ACCOUNT_PAYABLE_FILTER = "ACCOUNT_PAYABLE_FILTER";
export const GEN_AI_INVOICE_EMAIL_ID = "GEN_AI_INVOICE_EMAIL_ID";
//currency list
export const CURRENCY_LIST = "CURRENCY_LIST";

// modular
export const MODULAR_ACCOUNT_DETAILS = "MODULAR_ACCOUNT_DETAILS";

// card expenses

export const EXPENSES_LIST = "EXPENSES_LIST";
export const EXPENSES_FILTER_DATA = "EXPENSES_FILTER_DATA";
export const EXPENSES_FILTER_ITEM = "EXPENSES_FILTER_ITEM";

// codat

export const CODAT_DETAILS = "CODAT_DETAILS";
export const CODAT_STATUS = "CODAT_STATUS";

//Menu
export const MENU_LIST = "MENU_LIST";

//categories
export const CATEGORY_ALL_DATA = "CATEGORY_ALL_DATA";
export const TAX_RATES = "TAX_RATES";
export const XERO_CATEGORY = "XERO_CATEGORY";

//ManageUser
export const USER_FILTER_URL = "USER_FILTER_URL";
export const USER_ALL_DATA = "USER_ALL_DATA";
export const FILTER_TYPE_USER = "FILTER_TYPE_USER";

//organisation amount
export const ORGANISATION_AMOUNT = "ORGANISATION_AMOUNT";

//address
export const USER_ADDRESS = "USER_ADDRESS";

//USER FILTER
export const USER_FILTER_DATA = "USER_FILTER_DATA";
export const USER_FILTER_SELECTION = "USER_FILTER_SELECTION";
export const USER_FILTER_RESET = "USER_FILTER_RESET";

//setting

export const TRACKING_CATEGORIES = "TRACKING_CATEGORIES";
export const TRACKING_CATEGORIES_MAP = "TRACKING_CATEGORIES_MAP";

//   CUSTOM EXPENSES FIELD //
export const CUSTOM_GET_EXPENSES = "CUSTOM_GET_EXPENSES";
export const GET_PREDEFINE_LIST = "GET_PREDEFINE_LIST";
export const GET_ACCOUNT_CUSTOM = "GET_ACCOUNT_CUSTOM";

//funds

export const FUNDS_ACCOUNT = "FUNDS_ACCOUNT";

//socketConnection

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const UPDATE_THRESHOLD_AMOUNT = "UPDATE_THRESHOLD_AMOUNT";

//
export const SWITCH_PROFILE = "SWITCH_PROFILE";

//categorisation
export const TOGGLE_STATUS = "TOGGLE_STATUS";

//Dashboard
export const DASHBOARD_GENRE = "DASHBOARD_GENRE";

//List Filter
export const INVOICE_FILTERS = "INVOICE_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";
export const DASHBOARD_FILTERS_DATA = "DASHBOARD_FILTERS_DATA";
export const DASHBOARD_SELECTED_FILTERS = "DASHBOARD_SELECTED_FILTERS";

export const PURCHASE_ORDER_FILTERS = "PURCHASE_ORDER_FILTERS";
export const RESET_PO_FILTERS = "RESET_PO_FILTERS";

// custom fields
export const UPDATE_CUSTOM_FIELD = "UPDATE_CUSTOM_FIELD";
export const DELETE_CUSTOM_FIELD_OPTION = "DELETE_CUSTOM_FIELD_OPTION";
export const Edit_CUSTOM_FIELD_NAME = "Edit_CUSTOM_FIELD_NAME";
