import {
  FILTER_TYPE_USER,
  USER_ALL_DATA,
  USER_FILTER_DATA,
  USER_FILTER_SELECTION,
  USER_FILTER_RESET,
  USER_FILTER_URL,
} from "../service/types";

const initialState = {
  manageUsersList: {},
  usersFilter: {},
  filterType: [],
  selectedFilter: [],
  userFilterUrl: "",
};

export default function manageUsers(state = initialState, action) {
  switch (action.type) {
    case USER_FILTER_URL:
      return { ...state, createUrl: action.payload };
    case USER_ALL_DATA:
      return {
        ...state,
        manageUsersList: action.payload,
        userFilterUrl: action.userFilterUrl,
      };
    case USER_FILTER_DATA:
      return { ...state, usersFilter: action.payload };
    case FILTER_TYPE_USER:
      return { ...state, filterType: action.payload };
    case USER_FILTER_SELECTION:
      return { ...state, selectedFilter: action.payload };
    case USER_FILTER_RESET:
      return { ...initialState };
    default:
      return state;
  }
}
