import { SWITCH_PROFILE } from "../service/types";

const initialValue = {
  multiOrganisationList: [],
};
export const switchProfile = (state = initialValue, action) => {
  switch (action.type) {
    case SWITCH_PROFILE:
      return { ...state, multiOrganisationList: action.payload };
    default:
      return state;
  }
};
