import { useEffect, useState } from "react";
import { platformUsersInSmartApprovals } from "../api/PaymentRunsAPI";
import { getItem, LOCAL_STORAGE_KEY } from "../utils";

export const useCheckIfUserInSmartApprovals = () => {
  const [userExistsInSmartApprovals, setUserExistsInSmartApprovals] =
    useState(false);
  const getPlatformUsersInSmartApprovals = async () => {
    const response = await platformUsersInSmartApprovals();
    const userId = localStorage.getItem("id");
    const canShow =
      response?.data?.result &&
      Object.values(response?.data?.result)
        .flat()
        .some(({ user_id }) => user_id === userId);
    setUserExistsInSmartApprovals(canShow);
  };
  useEffect(() => {
    if (getItem(LOCAL_STORAGE_KEY.EMAIL)) getPlatformUsersInSmartApprovals();
  }, []);
  return {
    userExistsInSmartApprovals,
  };
};
