import React, { useState } from "react";
import { Form } from "antd";
import { Typography, CommonInput, CustomButton, Modal, CustomAlert } from "..";
import {
  ACCENTS,
  BUTTON_VARIANT,
  INVOICE_REJECTION_REASON,
  PAYMENT_JOURNEY_FAILED_CONFIG,
} from "../../../constants";

const RejectionModal = ({
  title,
  subtitle,
  inputMaxLength,
  open,
  onCancel,
  onConfirm,
  onSuccess,
  zIndex,
  childComponent,
}) => {
  const [form] = Form.useForm();
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleConfirmClick = async () => {
    const values = await form.validateFields();
    const requestPayload = {
      reason: values.rejectionReason,
    };
    try {
      setConfirmClicked(true);
      await onConfirm(requestPayload);
      onCancel();
      onSuccess();
    } catch (error) {
      setConfirmClicked(false);
      setErrorMessage(error?.response?.data?.message);
    }
  };

  return (
    <Modal
      width={600}
      isOpen={open}
      zIndex={zIndex}
      showDivider={false}
      hide={onCancel}
      getContainer={false}
      centered
      title={title}
    >
      {errorMessage && <CustomAlert message={errorMessage} type="error" />}
      <Form form={form} name="data_form" layout={"vertical"}>
        <Typography
          className="m-b-20px m-t-20px"
          variant="secondary"
          fontSize="16"
          text={subtitle}
        />
        {childComponent && childComponent()}
        <CommonInput
          name="rejectionReason"
          placeholder={INVOICE_REJECTION_REASON.REJECTION_REASON_LABEL}
          className="paid-data-form-inputtag optional"
          maxLength={inputMaxLength}
        />

        <div className="otp-btn-container m-t-25px">
          <CustomButton
            text={INVOICE_REJECTION_REASON.REJECT_BUTTON_LABEL}
            accent={ACCENTS.DESTRUCTIVE}
            variant={BUTTON_VARIANT.BUTTON_HOLLOW}
            loading={confirmClicked}
            showLoader={true}
            htmlType="submit"
            className="m-r-20px"
            onClick={handleConfirmClick}
            customSize={"large"}
          />
          <CustomButton
            text={PAYMENT_JOURNEY_FAILED_CONFIG.cancelButton.text}
            accent={ACCENTS.SECONDARY}
            variant={BUTTON_VARIANT.BUTTON_HOLLOW}
            onClick={onCancel}
            customSize={"large"}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default RejectionModal;
