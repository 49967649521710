import { memo, useState } from "react";
import useToggle from "../../../hooks/useToggle";
import {
  DELETE_CONFIRMATION_WORKFLOW_MODAL_CONSTANT,
  PURCHASE_ORDER_APPROVAL,
  ACCOUNTS_PAYABLE_APPROVAL,
  ASSIGN_WORKFLOW,
} from "../../../utils/constant";
import Box from "../Box";
import Button from "../Button";
import Checkbox from "../Checkbox";
import Modal from "../Modal";
import Typography from "../Typography";
import When from "../When";
import {
  NOTIFICATION_TYPE,
  showNotification,
} from "../../../utils/notifications";
import {
  deleteWorkFlow,
  deleteWorkFlowV1,
} from "../../../redux/action/accountPayable";
import { CustomAlert, CustomButton } from "..";
import { ACCENTS, BUTTON_VARIANT, TYPE_OF_WORKFLOW } from "../../../constants";

function DeleteWorkFlowConfirmationModal({
  open,
  onCancel,
  title,
  subTitle,
  mandatoryCheckboxConfirmation = false,
  onConfirm,
  deleteOnConfirm,
  openModal,
  setDeleteModalVisible,
  workFlowTitle,
  workFlowID,
  workFlowDeleted,
  activeKey,
  isInlineNotification,
  hideModal,
  selectedWorkFlowDetail,
}) {
  const { isOpen: isChecked, toggle } = useToggle();
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function handleCheckboxConfirmation() {
    toggle();
  }
  const onDeleteModalClose = () => {
    setErrorMessage("");
    setDeleteModalVisible(false);
  };

  const onConfirmDelete = () => {
    setErrorMessage("");
    setConfirmClicked(true);
    deleteWorkFlowV1(workFlowID, TYPE_OF_WORKFLOW[activeKey])
      .then(() => {
        hideModal();
        onDeleteModalClose();
        setConfirmClicked(false);
        workFlowDeleted();
        showNotification(
          NOTIFICATION_TYPE.success,
          "Workflow deleted successfully."
        );
      })
      .catch((e) => {
        setConfirmClicked(false);
        if (isInlineNotification) {
          setErrorMessage(
            e?.response?.data?.message ? e?.response?.data?.message : e.message
          );
        } else {
          showNotification(
            NOTIFICATION_TYPE.error,
            e?.response?.data?.message ? e?.response?.data?.message : e.message
          );
        }
      });
  };
  return (
    <Modal isOpen={openModal} showHeader={false} showDivider={false}>
      <Box rowGap={16}>
        <Box justifyContent={"flexStart"}>
          <Typography
            className={"width-full"}
            variant="title"
            text={ASSIGN_WORKFLOW.DELETE_WORKFLOW}
            fontSize={"24"}
            responsiveFontSize={false}
          />
          <When condition={selectedWorkFlowDetail}>
            <CustomAlert
              className="w-100"
              message={selectedWorkFlowDetail}
              type="warning"
            />
          </When>
          <When condition={errorMessage}>
            <CustomAlert
              className="w-100"
              message={errorMessage}
              type="error"
            />
          </When>
          <Typography
            variant="body"
            text={DELETE_CONFIRMATION_WORKFLOW_MODAL_CONSTANT.selectedDeleteText(
              workFlowTitle
            )}
          />
          <Typography
            variant="body"
            text={
              DELETE_CONFIRMATION_WORKFLOW_MODAL_CONSTANT.confirmationDeleteLabel
            }
          />
          <When condition={mandatoryCheckboxConfirmation}>
            <Checkbox
              disabled={confirmClicked}
              onChange={handleCheckboxConfirmation}
              checked={isChecked}
            >
              {
                DELETE_CONFIRMATION_WORKFLOW_MODAL_CONSTANT.checboxConfirmationLabel
              }
            </Checkbox>
          </When>
        </Box>
        <Box>
          <CustomButton
            text={
              DELETE_CONFIRMATION_WORKFLOW_MODAL_CONSTANT.confirmButtonLabel
            }
            showLoader={true}
            loaderText={
              DELETE_CONFIRMATION_WORKFLOW_MODAL_CONSTANT.loaderButtonText
            }
            accent={ACCENTS.PRIMARY}
            variant={BUTTON_VARIANT.BUTTON_FILLED}
            onClick={onConfirmDelete}
            className="m-r-20px"
            customSize={"large"}
          />
          <CustomButton
            text={DELETE_CONFIRMATION_WORKFLOW_MODAL_CONSTANT.cancelButtonLabel}
            accent={ACCENTS.SECONDARY}
            variant={BUTTON_VARIANT.BUTTON_HOLLOW}
            onClick={onDeleteModalClose}
            customSize={"large"}
          />
        </Box>
      </Box>
    </Modal>
  );
}
export default memo(DeleteWorkFlowConfirmationModal);
