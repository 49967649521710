import { PERMISSIONS } from "../../constants";
import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import { MENU_LIST } from "../service/types";
import { loadingStart, loadingStop } from "./loader";

export const getMenuList = (orgId) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.menu.menuList(orgId),
    })
      .then((response) => {
        const tempResponse = response?.data?.result;
        if (
          tempResponse?.scopes?.[PERMISSIONS.INVOICE_MATCHING.key] &&
          Object.values(
            tempResponse?.scopes?.[PERMISSIONS.INVOICE_MATCHING.key]
          ).includes(PERMISSIONS.INVOICE_MATCHING.readIM) &&
          Object.values(
            tempResponse?.scopes?.[PERMISSIONS.INVOICE_MATCHING.key]
          ).includes(PERMISSIONS.INVOICE_MATCHING.readPO)
        ) {
          tempResponse.scopes[PERMISSIONS.INVOICE_MATCHING.key] = [
            PERMISSIONS.INVOICE_MATCHING.readIM,
          ];
          tempResponse.scopes[PERMISSIONS.SETTING.key] = [
            ...tempResponse.scopes[PERMISSIONS.SETTING.key],
            PERMISSIONS.SETTING.THRESHOLD_MATCHING.read,
          ];
        } else {
          delete tempResponse.scopes[PERMISSIONS.INVOICE_MATCHING.key];
        }
        resolve(tempResponse);
        dispatch({
          type: MENU_LIST,
          payload: tempResponse,
        });
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
