import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import { CODAT_DETAILS, CODAT_STATUS } from "../service/types";
import { loadingStart, loadingStop } from "./loader";
import { NOTIFICATION_TYPE, showNotification } from "../../utils/notifications";

export const CodatData = (data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.codat.codatDetails,
      data,
    })
      .then((response) => {
        resolve(response);
        dispatch({
          type: CODAT_DETAILS,
          payload: response?.data?.result,
        });
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const disconnectXero = (data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.codat.codatDetails,
      data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const whenSetupDone = (data) => (dispatch) => {
  // dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.codat.setupDone,
      data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getcodatStatus = () => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.codat.codatDetails,
    })
      .then((res) => {
        resolve(res);
        dispatch({
          type: CODAT_STATUS,
          payload: res?.data?.result,
        });
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const getauthorizationRedirectlink = () => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.codat.authorization_redirect_link,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const syncHandler = (data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.codat.syncData,
    })
      .then((response) => {
        showNotification(NOTIFICATION_TYPE.success, "Your account has been synched successfully", "");
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const setuprefreshHandler = (data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.codat.setupDoneRefresh,
    })
      .then((response) => {
        // showNotification(NOTIFICATION_TYPE.success, 'Sync successfully', '')
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
