import {
  DASHBOARD_FILTERS_DATA,
  DASHBOARD_SELECTED_FILTERS,
} from "../service/types";

const initialState = {
  [DASHBOARD_SELECTED_FILTERS]: [],
  [DASHBOARD_FILTERS_DATA]: [],
};

export default function dashboardFilter(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_FILTERS_DATA:
      return {
        ...state,
        [DASHBOARD_FILTERS_DATA]: action.payload,
      };
    case DASHBOARD_SELECTED_FILTERS:
      return {
        ...state,
        [DASHBOARD_SELECTED_FILTERS]: action.payload,
      };

    default:
      return state;
  }
}
