import { useEffect, useState } from "react";
import {
  generateInitialFilterState,
  deleteKeyFromAllTabs,
  mergeAllTabsSelectedKeys,
} from "../utils";

export const useFilter = ({ tabsKeysObj, activeTab = "" }) => {
  const initialState = generateInitialFilterState(tabsKeysObj);
  const [refactorFilter, setRefactorFilter] = useState(initialState);

  useEffect(() => {
    updateSelectedFilterKeys(mergeAllTabsSelectedKeys(refactorFilter));
  }, [activeTab]);

  // Function to update selected filter keys
  const updateSelectedFilterKeys = (selectedFilterKeys) => {
    setRefactorFilter((prevState) => ({
      ...prevState,
      [activeTab]: {
        ...prevState?.[activeTab],
        selectedFilterKeys,
      },
    }));
  };

  // Function to update filter data
  const updateFilterData = (filterData) => {
    setRefactorFilter((prevState) => ({
      ...prevState,
      [activeTab]: {
        ...prevState?.[activeTab],
        filterData,
      },
    }));
  };

  // Function to reset selectedFilterKeys for all tabs to an empty array
  const resetAllSelectedFilterKeys = () => {
    const resetFilter = Object.fromEntries(
      Object.keys(refactorFilter).map((key) => [
        key,
        {
          selectedFilterKeys: [],
          filterData: refactorFilter[key]?.filterData || [],
        },
      ])
    );

    setRefactorFilter(resetFilter);
  };

  const removeKeyFromAllTab = (key) => {
    const updatedFilterObj = deleteKeyFromAllTabs(refactorFilter, key);
    setRefactorFilter(updatedFilterObj);
  };

  return {
    selectedFilterKeys: refactorFilter?.[activeTab]?.selectedFilterKeys || [],
    filterData: refactorFilter?.[activeTab]?.filterData || [],
    updateSelectedFilterKeys,
    updateFilterData,
    resetAllSelectedFilterKeys,
    removeKeyFromAllTab,
  };
};
export default useFilter;
