import React, { useEffect, useState } from "react";
import { ASSIGN_WORKFLOW } from "../../../utils";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import {
  Button,
  Drawer,
  Column,
  Row,
  Table,
  Typography,
  When,
  PreviewWorkflow,
  CustomButton,
  Box,
  EnvFeatureFlag,
  PreviewApprovalPath,
} from "../../common";
import CommonSelect from "../../common/Selector";
import { loadingStart, loadingStop } from "../../../redux/action/loader";
import { assignWorkFlow, getWorkFlows } from "../../../api";
import { PO_ASSIGN_FORM_RULE } from "../../../utils/validator";
import {
  MODAL_TYPES,
  NOTIFICATION_TYPE,
  showNotification,
  GLOBAL_NOT_APPLICABLE_SYMBOL,
} from "../../../utils";
import {
  ACCENTS,
  BUTTON_VARIANT,
  ERROR_MSG,
  WORK_FLOW_TYPES,
  featureFlags,
} from "../../../constants";
import {
  PAYMENT_RUN_ASSIGNMENT_DRAWER,
  PAYMENT_RUNS_RAISED_BY,
  PAYMENT_RUNS_TABLE_SUPPLIERS_KEY,
  PAYMENT_RUNS_DESCRIPTION,
} from "../../../constants/PaymentRuns";
import { getAmountIfAnotherCurrency } from "../../../helper/PaymentRuns";
import "./assign-payment-run.scss";

const AssignPaymentRunDrawer = ({
  open,
  onClose,
  paymentRunsList,
  showModal,
  hideModal,
  reloadTable,
}) => {
  const [form] = Form.useForm();
  const [approverList, setApproverList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getWorkFlows(WORK_FLOW_TYPES.PR).then((res) => setApproverList(res));
  }, []);

  const approverValue = Form.useWatch("firstApprover", form);
  const selectedApprover = approverList?.find(
    (workFlow) => workFlow.value === approverValue
  );

  const manipulateFunc = (paymentRun) => {
    const invoice = paymentRun.payment_run_details.map((item) => ({
      invoice_number: item?.invoice?.invoice_number,
      supplier_name:
        item?.invoice?.supplier?.name || GLOBAL_NOT_APPLICABLE_SYMBOL,
      description: item?.invoice?.description || GLOBAL_NOT_APPLICABLE_SYMBOL,
      raised_by: `${item?.invoice?.user_organization_details?.user?.first_name} ${item?.invoice?.user_organization_details?.user?.last_name}`,
      amount: getAmountIfAnotherCurrency(
        item?.invoice?.currency,
        item?.invoice?.amount,
        item?.invoice?.invoice_convert_amount
      ),
    }));
    return invoice;
  };

  const renderBody = ({ label, key, record, index, defaultRender }) => {
    return defaultRender({ label, key, record, index, defaultRender });
  };

  const handleAssignWorkflow = async () => {
    dispatch(loadingStart());
    try {
      await assignWorkFlow({
        type: WORK_FLOW_TYPES.PR,
        workflow_id: approverValue,
        event_ref_ids: paymentRunsList.map((item) => item.id),
      });

      hideModal();
      reloadTable();
      onClose();
      showNotification(
        NOTIFICATION_TYPE.success,
        PAYMENT_RUN_ASSIGNMENT_DRAWER.assigned(paymentRunsList?.length > 1)
      );
    } catch (e) {
      showNotification(
        NOTIFICATION_TYPE.error,
        e?.response?.data?.message || ERROR_MSG.common
      );
    } finally {
      dispatch(loadingStop());
    }
  };

  const onSubmit = () => {
    showModal(MODAL_TYPES.ASSIGN_WORKFLOW_MODAL, {
      workflowName: selectedApprover?.workflow?.workflow_name,
      workflowSteps: selectedApprover?.workflow,
      confirm: handleAssignWorkflow,
      recordsCount: paymentRunsList.length,
      module: "payment run",
    });
  };

  return (
    <Drawer
      className="assign-pr-drawer"
      open={open}
      width={1080}
      onClose={onClose}
      title={ASSIGN_WORKFLOW.TITLE}
    >
      <Row style={{ overflow: "hidden" }}>
        <Column {...{ xs: 24, sm: 24, md: 24, lg: 10 }}>
          <Typography
            className="m-b-26px"
            variant="secondary"
            text={PAYMENT_RUN_ASSIGNMENT_DRAWER.INFO(paymentRunsList.length)}
          />
          <Form
            form={form}
            onFinish={onSubmit}
            className="w-100"
            layout="vertical"
            initialValues={{
              firstApprover: null,
            }}
          >
            <CommonSelect
              name="firstApprover"
              placeholder={PAYMENT_RUN_ASSIGNMENT_DRAWER.WORKFLOW_PLACEHOLDER}
              label={PAYMENT_RUN_ASSIGNMENT_DRAWER.WORKFLOW_LABEL}
              rules={PO_ASSIGN_FORM_RULE.APPROVER}
              options={approverList}
            />
            <When condition={selectedApprover?.workflow}>
              <PreviewApprovalPath workflowData={selectedApprover?.workflow} />
            </When>
            <CustomButton
              className="w-100"
              accent={ACCENTS.PRIMARY}
              variant={BUTTON_VARIANT.BUTTON_FILLED}
              text={ASSIGN_WORKFLOW.TITLE}
              disabled={!selectedApprover}
              htmlType="submit"
              customSize={"large"}
            />
          </Form>
        </Column>
        <Column
          {...{
            xs: 24,
            sm: { ...{ span: 24 } },
            md: { ...{ span: 24 } },
            lg: { ...{ span: 13, offset: 1 } },
          }}
        >
          <div className="scrollable">
            {paymentRunsList.map((paymentRun) => {
              return (
                <div className="pr-table m-b-40px">
                  <Box justifyContent="spaceBetween" className="p-15px">
                    <Typography
                      variant="body"
                      text={paymentRun?.name}
                      fontStyle={"semibold"}
                      fontSize={"16"}
                    />
                    <Typography
                      fontStyle={"semibold"}
                      variant="body"
                      text={PAYMENT_RUN_ASSIGNMENT_DRAWER.TOTAL_AMOUNT(
                        paymentRun?.totalAmount
                      )}
                      fontSize={"16"}
                    />
                  </Box>
                  <Table
                    tableClassName="m-b-0px"
                    columns={PAYMENT_RUN_ASSIGNMENT_DRAWER.PR_TABLE_COLUMN}
                    data={manipulateFunc(paymentRun)}
                    renderBody={renderBody}
                  />
                </div>
              );
            })}
          </div>
        </Column>
      </Row>
    </Drawer>
  );
};

export default AssignPaymentRunDrawer;
