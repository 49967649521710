import { useState } from "react";
import { useDebounce } from "use-debounce";

const useDebounceState = (defaultValue, timeout = 500) => {
  const [value, setValue] = useState(defaultValue);

  const [debouncedValue] = useDebounce(value, timeout);
  return [value, setValue, debouncedValue];
};

export default useDebounceState;
