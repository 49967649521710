import { memo } from "react";
import useMobile from "../../../hooks/useMobile";
import { constructClassName } from "../../../utils/functions";
import "./Typography.scss";

function TitleText({
  text,
  fontStyle = "semibold",
  fontSize = "22",
  mobileFontSize = "20",
  desktopFontSize = "30",
  responsiveFontSize = true,
  className,
  textColorType = "primary",
}) {
  const { isMobile, isDesktop } = useMobile();

  let renderedFontSize = fontSize;
  if (responsiveFontSize) {
    if (isMobile) {
      renderedFontSize = mobileFontSize;
    } else if (isDesktop) {
      renderedFontSize = desktopFontSize;
    }
  }

  const clubbedName = constructClassName([
    "kloo-title-text",
    `kloo-font-weight-${fontStyle}`,
    `kloo-font-${renderedFontSize}`,
    `kloo-text-color-type-${textColorType}`,
    className,
  ]);

  return <h1 className={clubbedName}>{text}</h1>;
}

export default memo(TitleText);
