import { Dropdown } from "antd";
import React, { useState } from "react";
import { CustomButton, Icon, Tooltip } from "..";
import "./action-btn-dropdown.scss";

function ActionButtonDropDown({
  label,
  buttons,
  accent,
  variant,
  customSize,
  btnClassName = "",
  justifyContent = "justify-content-between",
  placement = "top",
  disabled,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const items = buttons.map((props, index) => {
    return {
      label: props?.tooltip ? (
        <Tooltip {...props?.tooltip}>
          <>
            <CustomButton {...props} />
          </>
        </Tooltip>
      ) : (
        <CustomButton {...props} />
      ),
      key: index,
    };
  });

  const menuProps = {
    items,
  };

  const handleOpenChange = (flag) => {
    setIsOpen(flag);
  };

  return (
    <Dropdown
      overlayClassName={"action-btn-dropdown-root"}
      trigger={["click"]}
      menu={menuProps}
      onOpenChange={handleOpenChange}
      open={isOpen}
      getPopupContainer={(trigger) => trigger.parentNode}
      placement={placement}
      disabled={disabled}
    >
      <CustomButton
        className={`${btnClassName} action-btn-dropdown flex-row-reverse ${justifyContent}`}
        accent={accent}
        variant={variant}
        customSize={customSize}
        text={label}
        icon={<Icon iconName={"keyboard_arrow_down"} />}
      />
    </Dropdown>
  );
}

export default ActionButtonDropDown;
