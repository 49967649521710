import React, { memo } from "react";
import { constructClassName } from "../../../utils/functions";
import "./Radio.scss";
import { Radio as ARadio } from "antd";
import When from "../When";

function Radio({ isGroup = false, className, ...rest }) {
  return (
    <>
      <When condition={isGroup}>
        <ARadio.Group {...rest} />
      </When>
      <When condition={!isGroup}>
        <ARadio
          className={constructClassName(["kloo-radio", className])}
          {...rest}
        />
      </When>
    </>
  );
}

export default Radio;
