import { Form } from "antd";
import React from "react";
import {
  Box,
  CommonInput,
  CommonSelect,
  CustomButton,
  Divider,
  Icon,
  Typography,
  When,
} from "../../../common";
import AddressContainer from "../AddressContainer";
import {
  ACCENTS,
  ADD_NEW_SUPPLIER_FORM,
  BUTTON_VARIANT,
  GBP_CURRENCY_ID,
} from "../../../../constants";
import { allowOnlyNumberOnInputChange } from "../../../../utils";

function SuppliersAccountForm({
  countriesList,
  currencies: currencyOptions,
  clearIcon = true,
  isSubmitting,
}) {
  const {
    BANK_ADDRESS,
    title,
    accountAlias,
    accountName,
    accountNumber,
    sortCode,
    currency,
    addAccountButton,
  } = ADD_NEW_SUPPLIER_FORM.ACCOUNT_FORM;

  const {
    bankAddressToggle,
    bankName,
    addressLine1,
    addressLine2,
    city,
    country,
    postcode,
  } = BANK_ADDRESS;

  return (
    <div className="supplier_account_wrapper">
      <Form.List name="account_details" className="mt-10">
        {(fields, { add: addMoreAccount, remove }) => (
          <>
            {fields.map((field, index) => (
              <>
                <When condition={index === 0}>
                  <Box justifyContent="spaceBetween" className={"w-100 mb-3"}>
                    <Typography
                      variant="secondary"
                      fontSize="16"
                      fontStyle={"semibold"}
                      text={title}
                    />
                    <When condition={clearIcon}>
                      <CustomButton
                        onClick={() => remove(field.name)}
                        text={"Cancel"}
                        accent={ACCENTS.DESTRUCTIVE}
                        variant={BUTTON_VARIANT.LINK}
                        disabled={isSubmitting}
                        customSize={"large"}
                      />
                    </When>
                  </Box>
                </When>
                {/* <Box
                  direction="column"
                  alignContent="flexStart"
                  alignItem="flexStart"
                >
                  <Box justifyContent="spaceBetween" className={"w-100"}>
                    <Typography
                      variant="secondary"
                      fontSize="16"
                      className="mb-1"
                      fontStyle={"semibold"}
                      text={accountTypeLabel}
                    />
                    <CustomButton
                      onClick={() => remove(field.name)}
                      text={"clear"}
                      accent={ACCENTS.DESTRUCTIVE}
                      variant={BUTTON_VARIANT.LINK}
                      className={"red-color"}
                    />
                  </Box>
                  <Radio
                    isGroup
                    buttonStyle={"solid"}
                    defaultValue={AccountTypes[0].value}
                    optionType={optionType}
                    className="account_type_selection"
                    name={accountTypeName(field.name)}
                    label={accountTypeLabel}
                  >
                    {AccountTypes?.map(({ label, value, disabled }) => (
                      <Radio value={value} disabled={disabled}>
                        {label}
                      </Radio>
                    ))}
                  </Radio>
                </Box> */}
                <CommonInput
                  type="text"
                  {...accountAlias}
                  name={accountAlias.name(field.name)}
                />
                <CommonInput
                  type="text"
                  {...accountName}
                  name={accountName.name(field.name)}
                />
                <CommonInput
                  name={accountNumber.name(field.name)}
                  placeholder={accountNumber.placeholder}
                  label={accountNumber.label}
                  rules={accountNumber.rules}
                  maxLength={8}
                  onInput={allowOnlyNumberOnInputChange}
                />
                <CommonInput
                  name={sortCode.name(field.name)}
                  placeholder={sortCode.placeholder}
                  label={sortCode.label}
                  rules={sortCode.rules}
                  maxLength={6}
                  onInput={allowOnlyNumberOnInputChange}
                />
                <CommonSelect
                  {...currency}
                  name={currency.name(field.name)}
                  options={currencyOptions}
                />
                {/* <AddressContainer
                  addressToggle={{
                    ...bankAddressToggle,
                    name: bankAddressToggle.name(field.name),
                  }}
                  addressLine1={{
                    ...addressLine1,
                    name: addressLine1.name(field.name),
                  }}
                  addressLine2={{
                    ...addressLine2,
                    name: addressLine2.name(field.name),
                  }}
                  city={{
                    ...city,
                    name: city.name(field.name),
                  }}
                  country={{
                    ...country,
                    name: country.name(field.name),
                    options: countriesList,
                  }}
                  postcode={{
                    ...postcode,
                    name: postcode.name(field.name),
                  }}
                  defaultToggleValue={false}
                >
                  <CommonInput type="text" {...bankName} />
                </AddressContainer> */}
                <Divider />
              </>
            ))}
            <When condition={fields?.length === 0}>
              <Form.Item>
                <CustomButton
                  icon={<Icon iconName={addAccountButton.iconName} />}
                  text={addAccountButton.label}
                  accent={ACCENTS.SECONDARY}
                  variant={BUTTON_VARIANT.BUTTON_TEXT}
                  onClick={() => addMoreAccount()}
                  customSize={"large"}
                />
              </Form.Item>
            </When>
          </>
        )}
      </Form.List>
    </div>
  );
}

export default SuppliersAccountForm;
