import { useNavigate } from "react-router-dom";
import { NAVIGATION_URL, getStatusLabel } from "../../../constants";
import {
  INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS_PO_NUMBER_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_AMOUNT_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_INVOICE_NUMBER_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_MATCH_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_PO_NUMBER_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_SELECTION_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_STATUS_KEY,
  INVOICE_MATCHING_TAG_CLASSNAMES,
  MATCH_TYPE,
} from "../../../constants/InvoiceMatching";
import {
  ActionLink,
  ComponentLoader,
  CustomTag,
  Icon,
  InfiniteScrollTable,
  Typography,
} from "../../common";
import InvoiceMatchType from "./InvoiceMatchType";

function InvoiceMatchingTable(props) {
  const {
    activeKey,
    columns,
    onNext,
    search,
    isOpen,
    onRowClick,
    selectedFilters,
    enableBorder = false,
    enableSelection = false,
    enableSingleSelection = false,
    className,
    onSelectionChange,
    selectedItems,
    height,
    handleSelection,
    tableRef,
    onDataLoad,
    showScrollBar,
  } = props;

  const navigate = useNavigate();

  const goToInvoiceManagement = ({ record, isSplittingModalOpen = false }) => {
    navigate(NAVIGATION_URL.INVOICE_MANAGEMENT, {
      state: {
        invoice: { ...record },
        isSplittingModalOpen,
        persistFilters: true,
      },
    });
  };

  const goToPOManagement = (invoice) => {
    navigate(NAVIGATION_URL.PO_MANAGEMENT, {
      state: {
        purchaseOrder: { ...invoice?.poDetail?.[0] },
        persistFilters: true,
      },
    });
  };

  const goToPOManagementFromManual = (invoice) => {
    navigate(NAVIGATION_URL.PO_MANAGEMENT, {
      state: { purchaseOrder: { ...invoice }, persistFilters: true },
    });
  };

  const renderBody = ({ label, key, record, index, defaultRender }) => {
    return key === INVOICE_MATCHING_TABLE_COLUMNS_STATUS_KEY ? (
      <CustomTag
        variant={INVOICE_MATCHING_TAG_CLASSNAMES[record[key]]}
        text={getStatusLabel(record[key])}
      />
    ) : key === INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY ? (
      <InvoiceMatchType matchType={record[key]} />
    ) : key === INVOICE_MATCHING_TABLE_COLUMNS_MATCH_KEY ? (
      <Icon iconName={"arrow_forward"} className={"txt-color"} />
    ) : key === INVOICE_MATCHING_TABLE_COLUMNS_AMOUNT_KEY ? (
      <div className={"m-r-24px"}>
        <Typography
          text={record[key]}
          variant={"body"}
          ellipsis={{
            tooltip: record[key],
          }}
        />
      </div>
    ) : key === INVOICE_MATCHING_TABLE_COLUMNS_INVOICE_NUMBER_KEY ? (
      <ActionLink
        onClick={(e) => {
          e.stopPropagation();
          goToInvoiceManagement({ record });
        }}
        text={
          <Typography
            text={record[key]}
            variant={"body"}
            className="text-link"
            ellipsis={{
              tooltip: record[key],
            }}
          />
        }
      />
    ) : key === INVOICE_MATCHING_TABLE_COLUMNS_PO_NUMBER_KEY ? (
      record?.purchase_order_exists ? (
        <ActionLink
          onClick={() => goToPOManagement(record)}
          text={
            <Typography
              text={record[key]}
              variant={"body"}
              className="text-link"
              ellipsis={{
                tooltip: record[key],
              }}
            />
          }
        />
      ) : (
        defaultRender({ label, key, record, index, defaultRender })
      )
    ) : key === INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS_PO_NUMBER_KEY ? (
      <ActionLink
        onClick={() => goToPOManagementFromManual(record)}
        text={
          <Typography
            text={record[key]}
            variant={"body"}
            className="text-link"
            ellipsis={{
              tooltip: record[key],
            }}
          />
        }
      />
    ) : key === INVOICE_MATCHING_TABLE_COLUMNS_SELECTION_KEY ? (
      <Icon
        iconName={"add_circle"}
        onClick={() => handleSelection({ record })}
      />
    ) : (
      defaultRender({ label, key, record, index, defaultRender })
    );
  };

  const handleArrowClick = (record) => {
    if (
      record?.[INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY] ===
        MATCH_TYPE.PARTIAL ||
      (record?.[INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY] ===
        MATCH_TYPE.CONFIRMED &&
        record?.poDetail?.length > 1)
    ) {
      goToInvoiceManagement({ record, isSplittingModalOpen: true });
    } else {
      onRowClick(record);
    }
  };

  const renderHeaders = ({ label, key, record, index, defaultRender }) => {
    return key === INVOICE_MATCHING_TABLE_COLUMNS_AMOUNT_KEY ? (
      <div className={"m-r-24px"}>{label}</div>
    ) : (
      defaultRender({ label, key, record, index, defaultRender })
    );
  };

  return (
    <InfiniteScrollTable
      className={className}
      key={Object.values({ activeKey, isOpen }).join("-")}
      columns={columns}
      onNext={onNext}
      renderBody={renderBody}
      renderHeaders={renderHeaders}
      variables={{
        filters: selectedFilters,
        search,
        activeKey,
        isOpen,
      }}
      LoaderComponent={<ComponentLoader wrapperClassName={"h-auto"} />}
      enableSelection={enableSelection}
      enableSingleSelection={enableSingleSelection}
      enableBorder={enableBorder}
      onRowClick={({ record }) => !!onRowClick && handleArrowClick(record)}
      onSelectionChange={onSelectionChange}
      selectedItems={selectedItems}
      height={height || undefined}
      ref={tableRef}
      onDataLoad={onDataLoad}
      showScrollBar={showScrollBar}
    />
  );
}

export default InvoiceMatchingTable;
