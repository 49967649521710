function DownloadExcelIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <mask
        id="mask0_5719_14344"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25"
      >
        <rect x="0.5" y="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5719_14344)">
        <path d="M19.9346 4.9939L16.25 1.30078V4.9939H19.9346Z" fill="white" />
        <path
          d="M7.72656 11.4844H10.1162V14.0191H7.72656V11.4844Z"
          fill="white"
        />
        <path
          d="M11.875 11.4844H17.4999V14.0191H11.875V11.4844Z"
          fill="white"
        />
        <path
          d="M7.72656 15.7773H10.1162V18.3121H7.72656V15.7773Z"
          fill="white"
        />
        <path
          d="M20.2209 6.56841H15.3377C14.9696 6.56841 14.6712 6.27002 14.6712 5.90194V1H5.66647C5.29837 1 5 1.29839 5 1.66648V22.6135C5 22.9816 5.29839 23.28 5.66647 23.28H19.5544C19.9225 23.28 20.2209 22.9816 20.2209 22.6135V6.56841ZM19.0746 18.9604C19.0746 19.4476 18.6796 19.8428 18.1922 19.8428L7.02854 19.8426C6.54127 19.8426 6.14614 19.4476 6.14614 18.9602V10.7872C6.14614 10.3 6.5411 9.90484 7.02854 9.90484H18.1922C18.6795 9.90484 19.0746 10.2998 19.0746 10.7872V18.9604Z"
          fill="white"
        />
        <path
          d="M11.875 15.7773H17.4999V18.3121H11.875V15.7773Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
export default DownloadExcelIcon;
