import { memo } from "react";
import { Empty as KlooEmpty } from "antd";
import "./Empty.scss";

function Empty({ height, description }) {
  return (
    <div style={{ height: height }} className="kloo-empty">
      <KlooEmpty description={description} />
    </div>
  );
}

export default memo(Empty);
