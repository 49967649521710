import {
  CARDS_EXPENSES_KEYS,
  CARDS_EXPENSES_TABLE_COLUMNS,
  CARDS_EXPENSES_TABLE_COLUMNS_STATUS_KEY,
  CARDS_EXPENSES_TABLE_COLUMNS_XERO_STATUS_KEY,
  CARDS_EXPENSES_TABS,
  NOTIFICATION_TYPE,
  featureFlags,
  selectAllCheckboxItemsKey,
} from "../../constants";
import {
  disableReversDecline,
  isDevelopmentEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
  showNotification,
} from "../../utils";

const { transactionStatusApplicableForArchiving } = CARDS_EXPENSES_TABS[0];

export function getColumnsForCardExpenses({ selectedTab, isXero }) {
  let columnsToBeRendered = CARDS_EXPENSES_TABS.find(
    (tab) => tab.key === selectedTab
  ).columns;
  if (selectedTab === CARDS_EXPENSES_KEYS.APPROVED) {
    if (isXero) {
      columnsToBeRendered = [
        {
          ...CARDS_EXPENSES_TABLE_COLUMNS.xeroStatus,
          width: 15,
          align: "center",
        },
        ...columnsToBeRendered({ isXero: true }),
      ];
    } else {
      columnsToBeRendered = [...columnsToBeRendered({ isXero: false })];
    }
  }
  return columnsToBeRendered;
}

export function renderStatusForOutstandingAndApprovedTab(statusKey) {
  return [
    CARDS_EXPENSES_TABLE_COLUMNS_STATUS_KEY,
    CARDS_EXPENSES_TABLE_COLUMNS_XERO_STATUS_KEY,
  ].includes(statusKey);
}

export function checkIfRecordCanBeArchived(status) {
  return transactionStatusApplicableForArchiving.includes(status);
}

export function checkIfDrawerShowsOnlyDetails(status, selectedTab) {
  let applicableStatus =
    CARDS_EXPENSES_TABS.find((tab) => tab.key === selectedTab)
      ?.transactionStatusApplicableForShowingOnlyDetails || [];
  return applicableStatus?.includes(status);
}

export function getSelectAllDropdownRecords(selectedTab, lengthConfig) {
  let checkboxItems =
    CARDS_EXPENSES_TABS.find((tab) => tab.key === selectedTab)
      .selectedAllCheckboxItems || [];

  return checkboxItems
    .filter(({ key }) => lengthConfig[key])
    .map((item) => {
      return {
        ...item,
        value: lengthConfig[item.key],
        label: item.label(lengthConfig[item.key]),
      };
    });
}

export const checkIfSelectAllDropdownChecked = (
  selectedAllDropdownItem = []
) => {
  return (
    (selectedAllDropdownItem?.[0]?.key || "") ===
    selectAllCheckboxItemsKey.AVAILABLE_ITEMS
  );
};

export const expenseApprovedNotification = ({ data }) => {
  showNotification(
    NOTIFICATION_TYPE.success,
    data?.result.records_updated
      ? `${
          data?.result.records_updated > 1 ? "Expenses" : "Expense"
        } has been marked as approved.`
      : data?.result.status
  );
};

export const isCustomExpenseRequired = ({ type, status, fieldData }) => {
  return !disableReversDecline(type, status)
    ? fieldData?.required === 1
      ? true
      : false
    : false;
};
