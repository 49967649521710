import React, { useState } from "react";
import PropTypes from "prop-types";
import "./PageTopContainer.scss";
import {
  Card,
  Row,
  Column,
  Button,
  Typography,
  When,
  ActionLink,
  CustomButton,
  Icon,
  Box,
} from "..";
import useMobile from "./../../../hooks/useMobile";
import { ACCENTS, BUTTON_VARIANT, SIZE } from "../../../constants";

function PageTopContainer({
  desc,
  header,
  buttonIcon,
  buttonLabel,
  buttonLabelMobile,
  onButtonClick,
  renderRight,
  bordered,
  style,
  buttonDisabled = false,
  addonBefore = <></>,
  hideCreateButton = false,
  showDesc,
}) {
  const [showFullDescription, setFullDescription] = useState(false);
  // read more caption show header
  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };
  const description = showFullDescription ? desc : desc?.slice(0, 100);

  const renderRightElement = (
    <div className="card_header_right ms-3">{renderRight && renderRight()}</div>
  );

  const { isMobile } = useMobile();
  return (
    <Card bordered={bordered} style={style}>
      <Row align={"middle"}>
        <Column span={isMobile ? 24 : 18}>
          <Box alignItem={"center"} justifyContent={"spaceBetween"}>
            <Typography
              variant={"title"}
              text={header}
              fontSize={"24"}
              responsiveFontSize={false}
            />
            <When condition={!hideCreateButton}>
              <Box>
                {addonBefore}
                <When condition={!!buttonLabel}>
                  <CustomButton
                    text={isMobile ? buttonLabelMobile : buttonLabel}
                    icon={<Icon iconName={buttonIcon} />}
                    accent={ACCENTS.PRIMARY}
                    variant={BUTTON_VARIANT.BUTTON_FILLED}
                    customSize={SIZE.LARGE}
                    onClick={onButtonClick}
                    disabled={buttonDisabled}
                  />
                </When>
              </Box>
            </When>
          </Box>
        </Column>
        <When condition={!isMobile}>
          <Column span={6}>{renderRightElement}</Column>
        </When>
      </Row>
      <When condition={showDesc && desc}>
        <Row>
          <Column>
            <Typography
              className="m-t-15px"
              variant={"body"}
              text={
                !isMobile || desc?.length <= 100 ? (
                  desc
                ) : (
                  <Typography
                    variant="body"
                    text={
                      <>
                        {description}{" "}
                        <ActionLink
                          variant={"body"}
                          onClick={showFullDescriptionHandler}
                          text={
                            showFullDescription ? " Read less" : "...Read more"
                          }
                        />
                      </>
                    }
                  />
                )
              }
            />
          </Column>
        </Row>
      </When>
      <Row>
        <When condition={isMobile}>
          <Column span={24}>{renderRightElement}</Column>
        </When>
      </Row>
    </Card>
  );
}

PageTopContainer.propTypes = {
  desc: PropTypes.string,
  header: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
  renderRight: PropTypes.func,
  bordered: PropTypes.bool,
  addonBefore: PropTypes.element,
};

PageTopContainer.defaultProps = {
  // desc: "Create, review, and manage from one simple interface.",
  // header: "Page header",
  bordered: false,
};

export default PageTopContainer;
