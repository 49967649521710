import { Popover as APopover } from "antd";
import { TOOLTIP_BG_COLOR } from "../../../constants";
import { constructClassName } from "../../../utils";

export default function Popover(props) {
  const { className } = props;
  return (
    <APopover
      overlayClassName={constructClassName(["kloo-popover", className])}
      {...props}
    />
  );
}
