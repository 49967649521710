import { featureFlags } from "../../constants";
import { count_keys } from "../../constants/Dashboard";
import {
  isDevelopmentEnvironment,
  isObject,
  isPlatformEnvironment,
  isStageEnvironment,
} from "../../utils";

export function dashboardCardsArrayMaker(cards_object) {
  const DASHBOARD_CARDS_MAP = Object.keys(cards_object)?.map((item, i) => {
    const match = count_keys?.find((el) => el?.key === item);
    if (match) {
      const cardsCount = cards_object[item];
      match["count"] = isObject(cardsCount) ? 0 : cardsCount ?? 0;
      match["totalInvoice"] = (cardsCount && cardsCount?.totalInvoice) ?? 0;
      match["overdue"] = (cardsCount && cardsCount?.overdue) ?? 0;
      match["totalCards"] = (cardsCount && cardsCount?.totalCards) ?? 0;
      return match;
    } else {
      return item;
    }
  });
  return DASHBOARD_CARDS_MAP;
}

export function onCardClickNavigationHandler(item, navigate) {
  navigate(`..${item?.navigate}`, {
    state: { tab: item?.route, filter: item?.filter },
  });
}
