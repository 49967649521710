import {
  CARD_LIST,
  CARD_FILTER,
  MY_CARDS,
  CARD_TOTAL_DATA,
  MY_APPROVAL_CARDS,
  ALL_APPROVAL_CARDS,
  CARD_OPTIONS,
} from "../service/types";

const initialState = {
  card: [],
  myCards: {},
  myApprovalsCards: {},
  allApprovalsCards: {},
  cardFilter: {},
  cardTotalData: {},
  filterTrigger: false,
};
export default function cards(state = initialState, action) {
  switch (action.type) {
    case CARD_LIST:
      return { ...state, card: action.payload };
    case MY_CARDS:
      return { ...state, myCards: action.payload };
    case CARD_FILTER:
      return { ...state, cardFilter: action.payload };
    case MY_APPROVAL_CARDS:
      return { ...state, myApprovalsCards: action.payload };
    case ALL_APPROVAL_CARDS:
      return { ...state, allApprovalsCards: action.payload };
    case CARD_TOTAL_DATA:
      return { ...state, cardTotalData: action.payload };
    case CARD_OPTIONS:
      return { ...state, filterTrigger: !state.filterTrigger };
    default:
      return state;
  }
}
