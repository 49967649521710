export const featureFlags = {
  cards: {
    editLimitRequest: {
      enableForDev: true,
      enableForStage: false,
      enableForProd: false,
    },
    pdfViewer_9919: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
  },
  purchaseOrder: {
    aiEmailModal: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: false,
    },
    resetWorkflow_9890: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
    itemsAutomationEnabled_11922: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
    priorityUserForApprovals_12193: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
  },
  invoiceManagement: {
    klooInsights: {
      enableForDev: false,
      enableForStage: false,
      enableForProd: false,
    },
    enableOnlyConfirmedInvoice: {
      enableForDev: false,
      enableForStage: false,
      enableForProd: false,
    },
    refactoredKeys: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
    hideInvoiceMatching: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
    CTAButtonsInAllTabs: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
    fileCompression: {
      enableForDev: true,
      enableForStage: false,
      enableForProd: false,
    },
    saveAsDraft: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
    isFilterEnabledForDraft: {
      enableForDev: false,
      enableForStage: false,
      enableForProd: false,
    },
    lineItemsRevamp_10919: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
    matchColumnOnHoldTab: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
    commonErpFields: {
      newVersion: {
        enableForDev: true,
        enableForStage: true,
        enableForProd: true,
      },
      oldVersion: {
        enableForDev: false,
        enableForStage: false,
        enableForProd: false,
      },
    },
    resetFormOnInvoiceDelete_11493: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
    isStatusToBeUpdatedEnabled_12177: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
    autoSplitMatchWhenSinglePO: {
      enableForDev: true,
      enableForStage: false,
      enableForProd: false,
    },
  },
  invoicePOField: {
    newVersion: {
      enableForDev: true,
      enableForStage: false,
      enableForProd: false,
    },
    oldVersion: {
      enableForDev: false,
      enableForStage: true,
      enableForProd: true,
    },
  },
  registrationRefactor: {
    oldVersion: {
      enableForDev: false,
      enableForStage: true,
      enableForProd: true,
    },
    newVersion: {
      enableForDev: true,
      enableForStage: false,
      enableForProd: false,
    },
  },
  smartApproval: {
    conditionalThresholdInvoices: {
      enableForDev: false,
      enableForStage: false,
      enableForProd: false,
    },
    conditionalThresholdPr: {
      enableForDev: false,
      enableForStage: false,
      enableForProd: false,
    },
  },
  auth: {
    redirectAfterLogin: {
      enableForDev: true,
      enableForStage: false,
      enableForProd: false,
    },
  },
  paymentRuns: {
    invoiceDrawer: {
      oldVersion: {
        enableForDev: false,
        enableForStage: true,
        enableForProd: true,
      },
      newVersion: {
        enableForDev: true,
        enableForStage: false,
        enableForProd: false,
      },
    },
  },
  creditNotes: {
    auditLogs_10262: {
      enableForDev: false,
      enableForStage: false,
      enableForProd: false,
    },
    rejectionReason_10463: {
      enableForDev: false,
      enableForStage: false,
      enableForProd: false,
    },
  },
  settings: {
    supplierCheatSheet: {
      enableForDev: true,
      enableForStage: true,
      enableForProd: true,
    },
    categorisation: {
      showLineItemSectionInInvoice_10948: {
        enableForDev: true,
        enableForStage: false,
        enableForProd: false,
      },
    },
    smartApproval: {
      oldVersion: {
        enableForDev: true,
        enableForStage: true,
        enableForProd: true,
      },
      newVersion: {
        enableForDev: false,
        enableForStage: false,
        enableForProd: false,
      },
    },
    integrations: {
      sageBusinessCloud: {
        enableForDev: false,
        enableForStage: false,
        enableForProd: false,
      },
      multiEntityTooltip_11257: {
        enableForDev: true,
        enableForStage: false,
        enableForProd: false,
      },
      disconnectModal: {
        enableForDev: true,
        enableForStage: true,
        enableForProd: false,
      },
      isWorkflowAmountHidden_11768: {
        enableForDev: true,
        enableForStage: true,
        enableForProd: false,
      },
    },
    configurations: {
      showLineItemSectionInInvoice_11017: {
        enableForDev: true,
        enableForStage: false,
        enableForProd: false,
      },
      showDefaultOwnerSelection_11292: {
        enableForDev: false,
        enableForStage: false,
        enableForProd: false,
      },
      isAutoInvoiceConfirmed_11343: {
        enableForDev: true,
        enableForStage: true,
        enableForProd: true,
      },
    },
  },
  feat11423: {
    enableForDev: true,
    enableForStage: true,
    enableForProd: true,
  },
  feat11547: {
    enableForDev: true,
    enableForStage: false,
    enableForProd: false,
  },
  feat11894: {
    enableForDev: true,
    enableForStage: false,
    enableForProd: false,
  },
  reverifyOTP_12208: {
    enableForDev: true,
    enableForStage: true,
    enableForProd: true,
  },
};
