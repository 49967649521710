import moment from "moment";
export const FORM_KEY_ARRAY = [
  {
    dataKey: "invoice_number",
    requestKey: "invoiceNumber",
    aiKey: "invoice_number",
    isUpload: true,
    isEdit: true,
    isForm: true,
  },
  {
    dataKey: "invoice_date",
    requestKey: "invoiceDate",
    aiKey: "invoice_date",
    isUpload: true,
    isDate: true,
    isEdit: true,
    isForm: true,
  },
  {
    dataKey: "due_date",
    requestKey: "dueDate",
    aiKey: "due_date",
    isUpload: true,
    isDate: true,
    isEdit: true,
    isForm: true,
  },
  {
    dataKey: "vat_number",
    requestKey: "vatNumber",
    aiKey: "vat_number",
    isUpload: true,
    isEdit: true,
    isForm: true, //check for edit
  },
  {
    dataKey: "amount",
    requestKey: "amount",
    aiKey: "amount",
    isUpload: true,
    isEdit: true,
    isForm: true,
  },
  {
    dataKey: "tax_amount",
    requestKey: "taxAmount",
    aiKey: "tax_amount",
    isUpload: true,
    isEdit: true,
    isForm: true,
  },
  {
    dataKey: "net_amount",
    requestKey: "netAmount",
    aiKey: "net_amount",
    isUpload: true,
    isEdit: true,
    isForm: true,
  },
  {
    dataKey: "currency.id",
    requestKey: "currency",
    aiKey: "currency",
    isUpload: true,
    isEdit: true,
    isForm: true,
  },
  {
    dataKey: "description",
    requestKey: "apDescription",
    aiKey: "description",
    isUpload: true,
    isEdit: true,
    isForm: true,
  },
  {
    dataKey: "entity.id",
    requestKey: "entity_id",
    aiKey: "entity_id",
    isUpload: true,
    isForm: true,
  },
  {
    dataKey: "service_commencement_date",
    requestKey: "serviceComDate",
    isUpload: true,
    isEdit: true,
    isDate: true,
    isForm: true,
  },
  {
    dataKey: "service_duration",
    requestKey: "serviceDuration",
    isUpload: true,
    isDate: true,
    isForm: true,
  },
  {
    dataKey: "purchase_order_number",
    requestKey: "poNumber",
    aiKey: "poNumber",
    isUpload: true,
  },
  {
    aiKey: "line_items",
    isUpload: true,
  },
  {
    dataKey: "supplier.name",
    requestKey: "supplierName",
    isEdit: true,
  },
  {
    dataKey: "supplier.country",
    requestKey: "country",
    isEdit: true,
  },
  {
    dataKey: "vat_rate",
    requestKey: "vatRate",
    isEdit: true,
  },
  {
    dataKey: "tax_code_id",
    requestKey: "taxCode",
    isEdit: true,
  },
  {
    dataKey: "service_duration_unit",
    requestKey: "serviceDurationUnit",
    isEdit: true,
  },
  {
    dataKey: "service_duration",
    requestKey: "serviceDuration",
    isEdit: true,
  },
  {
    dataKey: "team_id",
    requestKey: "team",
    isEdit: true,
  },
  {
    dataKey: "reject_reason.reason",
    requestKey: "rejectionReason",
    isEdit: true,
  },
];

function accessNestedObject(obj, path) {
  const keys = path?.split(".");
  let current = obj;

  for (const key of keys) {
    if (current && typeof current === "object" && key in current) {
      current = current[key];
    } else {
      return undefined;
    }
  }

  return current;
}

export const CREATE_FORM_OBJECT = ({ isUpload, data, form }) => {
  try {
    const objectArray = FORM_KEY_ARRAY.filter((formObj) => {
      return formObj.hasOwnProperty(isUpload ? "isUpload" : "isEdit");
    });
    let tempAIObject = {};
    let formObject = {};

    for (const { aiKey, dataKey, requestKey, isDate, isForm } of objectArray) {
      if (isDate && dataKey && accessNestedObject(data, dataKey)) {
        formObject[requestKey] = moment(accessNestedObject(data, dataKey));
      } else if (dataKey && accessNestedObject(data, dataKey)) {
        formObject[requestKey] = accessNestedObject(data, dataKey);
      }

      if (isForm && isUpload && !formObject[requestKey]) {
        form.getFieldValue(requestKey);
      }

      if (aiKey && isUpload && formObject[requestKey]) {
        tempAIObject[aiKey] = aiKey;
      }
    }

    return {
      tempAIObject,
      formObject,
    };
  } catch (err) {}
};
