import {
  CARDS_MODULE_NAVIGATION_URL,
  CARDS_TABS_KEYS,
  featureFlags,
} from "../../constants";
import {
  isInactiveOTPVerifyRequired,
  isKlooLoginPresent,
  isOTPVerified,
} from "../../hooks/useAuth";
import {
  isDevelopmentEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
} from "../../utils";

export function isReverifyRequired() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.reverifyOTP_12208.enableForDev) ||
    (isStageEnvironment() && featureFlags.reverifyOTP_12208.enableForStage) ||
    (isPlatformEnvironment() && featureFlags.reverifyOTP_12208.enableForProd)
  );
}
export const getCurrentTabNameFromURL = ({ path }) => {
  const active = path;
  const arr = active?.split("/");
  return (arr && arr[arr.length - 1]) || CARDS_TABS_KEYS.ALL_CARDS;
};

export function isKlooValidUser(path) {
  return !isReverifyRequired() || CARDS_MODULE_NAVIGATION_URL.includes(path)
    ? isKlooLoginPresent() && isOTPVerified()
    : isInactiveOTPVerifyRequired()
    ? isKlooLoginPresent() && !isOTPVerified() && isReverifyRequired()
    : isKlooLoginPresent() && isOTPVerified();
}
