import React, { useEffect, useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import When from "../When";
import { constructClassName } from "../../../utils/functions";
import "../../../assets/scss/Table.scss";
import { useToggle } from "../../../hooks";

import {
  SERIAL_NUMBER_COLUMN_KEY,
  SELECTION_COLUMN_KEY,
  SERIAL_NUMBER_COLUMN,
  SELECTION_COLUMN,
  EXPANDABLE_COLUMN_KEY,
  EXPAND_COLLAPSE_COLUMN,
} from "../../../constants";
import TableBody from "./TableBody";
import TableColumn from "./TableColumn";
import TableFooter from "./TableFooter";

const Table = ({
  className,
  tableClassName,
  isInfiniteScroll,
  columns: propColumns,
  data,
  renderHeaders,
  renderBody,
  enableSerialNumber,
  enableSelection,
  enableSingleSelection,
  onSelectionChange,
  onRowClick,
  enableRowHighlight,
  selectedItems,
  columnsWidth,
  onSelectAll,
  enableSelectAllV1 = false,
  renderSelectAllBody,
  selectedAllDropdownItem,
  handleSelectedAllDropdownItem,
  enableBorder,
  renderExpandedDiv,
  showHeader,
  showBody,
  showExpandIcon,
  closeExpandedDiv,
  dynamicWidth,
  defaultSelectedRow,
  disableBackgroundColor,
  customBackgroundClass,
  showScrollBar,
  tableHeight,
  onExpand,
  tableBodyClassName,
  tableFooterClassName,
  showFooter,
  footerColumns,
  footerData,
  renderFooter,
}) => {
  const [columns, setColumns] = useState([...propColumns]);
  const [selectedData, setSelectedData] = useState(selectedItems);
  const { isOpen, toggle } = useToggle(false);
  const doesArrayIncludes = (data, record) => {
    return data.findIndex((item) => item.id === record.id) > -1;
  };

  const handleSelection = ({ record }) => {
    if (enableSingleSelection) {
      setSelectedData([record]);
    } else {
      if (doesArrayIncludes(selectedData, record)) {
        const filteredData = selectedData.filter(
          (item) => item.id !== record.id
        );
        setSelectedData(filteredData);
      } else {
        setSelectedData([...selectedData, record]);
      }
    }
  };

  const handleAllSelection = ({ allSelected }) => {
    setSelectedData(
      allSelected ? data.filter((item) => !item.isSelectionDisabled) : []
    );
    onSelectAll && allSelected && onSelectAll();
  };

  useEffect(() => {
    addColumns(columns);
    // if (
    //   enableSerialNumber &&
    //   columns.findIndex((item) => item.key === SERIAL_NUMBER_COLUMN_KEY) === -1
    // ) {
    //   setColumns([SERIAL_NUMBER_COLUMN, ...columns]);
    // }
    // if (
    //   enableSelection &&
    //   columns.findIndex((item) => item.key === SELECTION_COLUMN_KEY) === -1
    // ) {
    //   setColumns([SELECTION_COLUMN, ...columns]);
    // }
  }, [
    enableSelection,
    enableSerialNumber,
    enableSingleSelection,
    !!renderExpandedDiv,
  ]);

  useEffect(() => {
    closeExpandedDiv && toggle();
  }, [closeExpandedDiv]);

  useEffect(() => {
    onSelectionChange && onSelectionChange(selectedData);
  }, [selectedData]);

  useEffect(() => {
    if (selectedItems && selectedData !== selectedItems) {
      onSelectionChange && onSelectionChange(selectedItems);
    }
    selectedItems && setSelectedData(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    propColumns && addColumns(propColumns);
  }, [propColumns]);

  const addColumns = (defaultColumns) => {
    const tempColumns = [...defaultColumns];

    const serialNumberIndex = defaultColumns.findIndex(
      (item) => item.key === SERIAL_NUMBER_COLUMN_KEY
    );
    const selectionIndex = defaultColumns.findIndex(
      (item) => item.key === SELECTION_COLUMN_KEY
    );
    const expandableIndex = defaultColumns.findIndex(
      (item) => item.key === EXPANDABLE_COLUMN_KEY
    );
    if (!!renderExpandedDiv && expandableIndex === -1 && showExpandIcon) {
      // setColumns([EXPAND_COLLAPSE_COLUMN, ...defaultColumns]);
      tempColumns.unshift(EXPAND_COLLAPSE_COLUMN);
    }

    if (enableSerialNumber && serialNumberIndex === -1) {
      // setColumns([SERIAL_NUMBER_COLUMN, ...defaultColumns]);
      tempColumns.unshift(SERIAL_NUMBER_COLUMN);
    }

    if (enableSelection && selectionIndex === -1) {
      tempColumns.unshift({
        ...SELECTION_COLUMN,
        align: enableSelectAllV1 ? "left" : undefined,
      });
    }

    if (!enableSerialNumber && serialNumberIndex > -1) {
      tempColumns.splice(serialNumberIndex, 1);
    }

    if (!enableSelection && selectionIndex > -1) {
      tempColumns.splice(selectionIndex, 1);
    }

    setColumns(tempColumns);
  };

  // If Data is present and enabled record id matches with the selected record id, check Select All
  const filteredData = data?.filter(
    (selectedElem) => !selectedElem.isSelectionDisabled
  );

  const isAllSelected =
    filteredData?.length > 0 &&
    selectedData?.length === filteredData?.length &&
    filteredData.every(
      (elem) =>
        !!selectedData.find((selectedElem) => elem.id === selectedElem.id)
    );

  // If Data is empty, disable Select All.
  // If All of the record are disable, disabled Select All
  const isAllDisabled =
    data?.length === 0 || data?.every((elem) => elem.isSelectionDisabled);

  function expandRow({ index, selectedRow, onExpand = false }) {
    const finalSelectedRow =
      selectedRow === 0 ? index : !isOpen ? index : selectedRow;

    const ExpandDivCondition = onExpand
      ? selectedRow === index
      : finalSelectedRow === index;

    if (ExpandDivCondition) {
      toggle();
    }
  }

  return (
    <div
      className={constructClassName(
        !isInfiniteScroll
          ? [
              "common_table p-h-0px",
              className,
              !showScrollBar && "hide-scroll-bar",
              showBody ? tableBodyClassName : "",
              showFooter ? tableFooterClassName : "",
            ]
          : "" // please remove this from here and InfiniteTable @mukesh
      )}
      style={{ height: `${tableHeight}` }}
    >
      <table
        className={constructClassName([
          "dataTable",
          enableBorder ? "table-border" : "",
          tableClassName,
        ])}
        style={{ width: `${dynamicWidth}` }}
      >
        <When condition={showHeader}>
          <TableColumn
            handleSelection={handleAllSelection}
            allSelected={isAllSelected}
            isAllDisabled={isAllDisabled}
            columns={columns}
            renderHeaders={renderHeaders}
            columnsWidth={columnsWidth}
            enableSelectAllV1={enableSelectAllV1}
            renderSelectAllBody={(props) => {
              return renderSelectAllBody({
                selectableRecords: filteredData,
                ...props,
              });
            }}
            dataInSync={selectedData.length === selectedItems.length}
            selectedAllDropdownItem={selectedAllDropdownItem}
            handleSelectedAllDropdownItem={handleSelectedAllDropdownItem}
            onSelectAll={onSelectAll}
            enableSingleSelection={enableSingleSelection}
            renderExpandedDiv={renderExpandedDiv}
            // ref={ref}
          />
        </When>
        <When condition={showBody && data?.length}>
          <TableBody
            handleSelection={handleSelection}
            columns={columns}
            data={data}
            renderBody={renderBody}
            selectedData={selectedData}
            onRowClick={onRowClick}
            enableRowHighlight={enableRowHighlight}
            columnsWidth={columnsWidth}
            enableSingleSelection={enableSingleSelection}
            renderExpandedDiv={renderExpandedDiv}
            doesArrayIncludes={doesArrayIncludes}
            // ref={ref}
            expandRow={expandRow}
            isOpen={isOpen}
            defaultSelectedRow={defaultSelectedRow}
            disableBackgroundColor={disableBackgroundColor}
            customBackgroundClass={customBackgroundClass}
            onExpand={onExpand}
          />
        </When>
        <When condition={showFooter}>
          <TableFooter
            columns={footerColumns}
            data={footerData}
            renderFooter={renderFooter}
          />
        </When>
      </table>
    </div>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  tableBodyClassName: PropTypes.string,
  tableFooterClassName: PropTypes.string,
  isInfiniteScroll: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  renderHeaders: PropTypes.func,
  renderBody: PropTypes.func,
  showFooter: PropTypes.bool,
  footerColumns: PropTypes.array,
  footerData: PropTypes.array,
  renderFooter: PropTypes.func,
  enableSerialNumber: PropTypes.bool,
  enableSelection: PropTypes.bool,
  enableSingleSelection: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  onRowClick: PropTypes.func,
  enableRowHighlight: PropTypes.bool,
  selectedItems: PropTypes.array,
  columnsWidth: PropTypes.object,
  onSelectAll: PropTypes.func,
  enableBorder: PropTypes.bool,
  renderExpandedDiv: PropTypes.func,
  showHeader: PropTypes.bool,
  showBody: PropTypes.bool,
  showExpandIcon: PropTypes.bool,
  dynamicWidth: PropTypes.string,
  disableBackgroundColor: PropTypes.bool,
};

Table.defaultProps = {
  isInfiniteScroll: false,
  columns: [],
  data: [],
  enableSerialNumber: false,
  enableSelection: false,
  enableSingleSelection: false,
  enableRowHighlight: false,
  selectedItems: [],
  columnsWidth: { web: [], mobile: [] },
  onSelectAll: () => {},
  enableBorder: false,
  showHeader: true,
  showBody: true,
  showExpandIcon: true,
  dynamicWidth: "100%",
  disableBackgroundColor: false,
  showFooter: false,
  footerColumns: [],
  footerData: [],
  renderFooter: () => {},
};

export default Table;
