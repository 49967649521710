import React from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Column,
  Row,
  useGlobalDrawerContext,
  When,
  CustomButton,
  Icon,
  Box,
  AuditLogModal as CommonAuditLogModal,
  ActionButtonDropdown,
} from "../../common";
import { PurchaseOrderPDFView } from "../PurchaseOrderPDFView";
import {
  approvePurchaseOrder,
  deletePurchaseOrder,
  postPoComment,
} from "../../../api";
import { useSelector } from "react-redux";
import {
  constructClassName,
  DRAWER_TYPES,
  getUserOrgId,
  isFaUser,
  isPlatformUser,
  isPOAuditLogVisible,
  isPOEditButtonVisible,
  MODAL_TYPES,
  NOTIFICATION_TYPE,
  showNotification,
} from "../../../utils";
import {
  ACCENTS,
  BUTTON_VARIANT,
  ERROR_MSG,
  PO_ACTIONS,
  PURCHASE_REQUEST_PAGE_UPDATE,
  PURCHASE_ORDER_REVIEW_DRAWER,
  PURCHASE_ORDER_TABS_KEYS,
  PURCHASE_ORDER_TOAST_MSGS,
  MODULE_TYPES,
  PO_APPROVE_REJECT_DRAWER,
} from "../../../constants";
import "./review-purchase-order.scss";
import AuditLogModal from "../AuditLogModal";
import { useUserType } from "../../../hooks";
import { isAuditLogsEnabledInCreditNotes } from "../../../helper/CreditNotes";

function ReviewPurchaseOrderDrawer({
  open,
  poDetails,
  onClose,
  fromInReview = false,
  fromRejected = false,
  fromCreated = false,
  customFields,
  reloadTable,
  activeKey,
  showModal,
  hideModal,
  setShowPurchaseOrderDrawer,
  setPODetails,
  onClickOfCloseDrawer,
  taxCodeEnabled,
  entityEnabled,
  defaultEntityList,
  version,
  createButtonDisabled,
}) {
  const userType = useSelector(
    (state) => state && state?.menuReducer && state?.menuReducer?.userType
  );
  const userTypeDetails = useUserType();
  const { showDrawer } = useGlobalDrawerContext();

  const handleApproval = async (status, rejectionReason) => {
    try {
      let payload = {
        ids: [poDetails.id],
        is_workflow_assigned: activeKey !== PURCHASE_ORDER_TABS_KEYS.CREATED,
      };
      if (rejectionReason) {
        payload.reason = rejectionReason?.reason;
      }
      await approvePurchaseOrder(status, payload);
      handleSuccess(null, status);
    } catch (e) {
      handleError(e);
    }
  };

  const handleApproveNow = async () => {
    await handleApproval(PO_ACTIONS.APPROVE);
  };

  const handlePODelete = async () => {
    if (fromInReview) {
      showModal(MODAL_TYPES.REJECTION_MODAL, {
        title: PURCHASE_REQUEST_PAGE_UPDATE.PR_REJECTION_REASON.TITLE,
        subtitle: PURCHASE_REQUEST_PAGE_UPDATE.PR_REJECTION_REASON.SUB_TITLE,
        inputMaxLength: 200,
        zIndex: 2000,
        onConfirm: (rejectionReason) =>
          handleApproval(PO_ACTIONS.REJECT, rejectionReason),
      });
      return;
    }
    try {
      await deletePurchaseOrder([poDetails.id]);
      handleSuccess(true);
    } catch (e) {
      handleError(e);
    }
  };

  const handleSuccess = (isDelete, status) => {
    showNotification(
      NOTIFICATION_TYPE.success,
      isDelete
        ? PURCHASE_ORDER_TOAST_MSGS.deleted()
        : status === PO_ACTIONS.APPROVE
        ? PURCHASE_ORDER_TOAST_MSGS.approved(false)
        : PURCHASE_ORDER_TOAST_MSGS.rejected(false)
    );
    reloadTable();
    onCloseDrawer();
  };

  const handleError = (e) => {
    showNotification(
      NOTIFICATION_TYPE.error,
      e?.response?.data?.message || ERROR_MSG.common
    );
  };

  const handleEdit = () => {
    setShowPurchaseOrderDrawer(true);
    setPODetails(poDetails);
    onClose();
  };

  const handleAssign = async ({ actionType }) => {
    showDrawer(DRAWER_TYPES.PO_ASSIGN_DRAWER, {
      purchaseOrderList: [poDetails],
      actionType: actionType,
      reloadTable,
      showModal,
      hideModal,
    });
  };

  const onCloseDrawer = () => {
    onClose();
    onClickOfCloseDrawer();
  };

  const isFaUserAndCreateButtonDisabled =
    isFaUser(userType) && !createButtonDisabled;

  let shouldHideApproveNowButton;
  shouldHideApproveNowButton =
    isFaUserAndCreateButtonDisabled || fromInReview || fromRejected;

  const AuditModal = () => {
    if (isAuditLogsEnabledInCreditNotes()) {
      return (
        <CommonAuditLogModal
          showModal={showModal}
          details={poDetails}
          type={MODULE_TYPES.PO}
          addComment={postPoComment}
          isAuditLogEnabled={isPOAuditLogVisible({
            userTypeDetails,
            poDetails,
            userOrgId: getUserOrgId(),
          })}
        />
      );
    } else {
      return <AuditLogModal showModal={showModal} poDetails={poDetails} />;
    }
  };

  const canApprove = poDetails?.is_loggedin_user_allow_to_approve;

  const approvalBtnDisableCondition = fromInReview ? !canApprove : false;

  const conditionToRenderApprovalBtn = () => {
    if (fromInReview) {
      return canApprove;
    } else {
      return !fromRejected;
    }
  };

  const workflowButtons = () => {
    const buttons = [];
    buttons.push(
      {
        key: PO_ACTIONS.REASSIGN,
        text: PO_APPROVE_REJECT_DRAWER.REASSIGN_BUTTON_LABEL,
        className: "w-100",
        accent: ACCENTS.PRIMARY,
        variant: BUTTON_VARIANT.BUTTON_FILLED,
        onClick: () => handleAssign({ actionType: PO_ACTIONS.REASSIGN }),
      },
      {
        key: PO_ACTIONS.REASSIGN,
        text: PO_APPROVE_REJECT_DRAWER.UNASSIGN_BUTTON_LABEL,
        className: "w-100",
        accent: ACCENTS.SECONDARY,
        variant: BUTTON_VARIANT.BUTTON_HOLLOW,
        onClick: () => handleAssign({ actionType: PO_ACTIONS.UNASSIGN }),
      }
    );
    return buttons;
  };

  return (
    <Drawer
      className={"review-purchase-drawer expanded-version"}
      open={open}
      onClose={onCloseDrawer}
      getContainer={false}
      mask={false}
      title={
        fromInReview
          ? PURCHASE_ORDER_REVIEW_DRAWER.TITLE_REVIEW
          : PURCHASE_ORDER_REVIEW_DRAWER.TITLE_VIEW
      }
    >
      <Row>
        <Column span={24}>
          <PurchaseOrderPDFView
            {...poDetails}
            taxCodeEnabled={taxCodeEnabled}
            entityEnabled={entityEnabled}
            defaultEntityList={defaultEntityList}
            showModal={showModal}
            hideModal={hideModal}
            version={version}
          />
          <When condition={!isPlatformUser(userType)}>
            <When condition={shouldHideApproveNowButton}>
              <AuditModal />
            </When>
          </When>
        </Column>
      </Row>
      <Row>
        <Column span={24} className="purchase-drawer-footer p-l-24px p-r-24px">
          <Row gutter={24} justify="end">
            <When condition={isPOEditButtonVisible(poDetails)}>
              <Column className="gutter-row" span={6}>
                <CustomButton
                  text={PURCHASE_ORDER_REVIEW_DRAWER.EDIT_BUTTON_LABEL}
                  icon={<Icon iconName={"edit"} />}
                  accent={ACCENTS.SECONDARY}
                  variant={BUTTON_VARIANT.BUTTON_TEXT}
                  onClick={handleEdit}
                  className={shouldHideApproveNowButton ? "float-end" : "w-100"}
                  showLoader={true}
                  customSize={"large"}
                />
              </Column>
              <When
                condition={activeKey === PURCHASE_ORDER_TABS_KEYS.IN_REVIEW}
              >
                <Column className="gutter-row" span={6}>
                  <ActionButtonDropdown
                    btnClassName={constructClassName(["w-100"])}
                    justifyContent="justify-content-center"
                    variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                    accent={ACCENTS.SECONDARY}
                    label="Workflow"
                    buttons={workflowButtons()}
                    customSize={"large"}
                  />
                </Column>
              </When>
              <When
                condition={
                  !shouldHideApproveNowButton &&
                  !isPlatformUser(userType) &&
                  activeKey !== PURCHASE_ORDER_TABS_KEYS.DRAFT
                }
              >
                <Column className="gutter-row" span={6}>
                  <CustomButton
                    text={PURCHASE_ORDER_REVIEW_DRAWER.APPROVAL_BUTTON_LABEL}
                    accent={ACCENTS.SECONDARY}
                    variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                    onClick={handleApproveNow}
                    className="w-100"
                    showLoader={true}
                    customSize={"large"}
                    disabled={!canApprove}
                  />
                </Column>
              </When>
            </When>
            <When
              condition={
                conditionToRenderApprovalBtn() &&
                activeKey !== PURCHASE_ORDER_TABS_KEYS.DRAFT
              }
            >
              <When condition={!(isPlatformUser(userType) && fromCreated)}>
                <Column className="gutter-row" span={6}>
                  <CustomButton
                    text={
                      fromInReview
                        ? PURCHASE_ORDER_REVIEW_DRAWER.APPROVE_BUTTON_LABEL
                        : PURCHASE_ORDER_REVIEW_DRAWER.ASSIGN_BUTTON_LABEL
                    }
                    accent={ACCENTS.PRIMARY}
                    variant={BUTTON_VARIANT.BUTTON_FILLED}
                    onClick={
                      fromInReview
                        ? handleApproveNow
                        : () => handleAssign({ actionType: PO_ACTIONS.ASSIGN })
                    }
                    className="w-100"
                    showLoader={true}
                    customSize={"large"}
                    disabled={approvalBtnDisableCondition}
                  />
                </Column>
              </When>
              <Column className="gutter-row" span={6}>
                <CustomButton
                  text={
                    fromInReview
                      ? PURCHASE_ORDER_REVIEW_DRAWER.REJECT_BUTTON_LABEL
                      : PURCHASE_ORDER_REVIEW_DRAWER.DELETE_BUTTON_LABEL
                  }
                  accent={ACCENTS.DESTRUCTIVE}
                  variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                  onClick={handlePODelete}
                  className="w-100"
                  showLoader={true}
                  customSize={"large"}
                  disabled={approvalBtnDisableCondition}
                />
              </Column>
            </When>
            <When condition={fromRejected}>
              <Column className="gutter-row" span={6}>
                <CustomButton
                  text={PURCHASE_ORDER_REVIEW_DRAWER.DELETE_PR_BUTTON_LABEL}
                  accent={ACCENTS.DESTRUCTIVE}
                  variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                  onClick={handlePODelete}
                  className="w-100"
                  showLoader={true}
                  customSize={"large"}
                />
              </Column>
            </When>
          </Row>
        </Column>
      </Row>
      <When
        condition={
          !shouldHideApproveNowButton &&
          activeKey !== PURCHASE_ORDER_TABS_KEYS.DRAFT
        }
      >
        <AuditModal />
      </When>
      <Box className={"m-b-80px"} />
    </Drawer>
  );
}

ReviewPurchaseOrderDrawer.propTypes = {
  open: PropTypes.bool,
  purchaseOrderList: PropTypes.array,
  reloadTable: PropTypes.func,
  onClose: PropTypes.func,
  showModal: PropTypes.func,
};

export default ReviewPurchaseOrderDrawer;
