import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import ConfigureStore from "./store/store";
import "./utils/interceptors";
import "@material-design-icons/font";
import { MICROSOFT_CONFIG } from "./api/MicrosoftAPI";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import "antd/dist/antd.css";
import "../src/assets/scss/App.scss";

const msalInstance = new PublicClientApplication(MICROSOFT_CONFIG);
const root = ReactDOM.createRoot(document.getElementById("root"));

const store = ConfigureStore();

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
