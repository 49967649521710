import React, { useEffect, useState } from "react";
import { UserInactiveModal } from "../../Layout/userInactiveModal";
import { useIdleTimer, workerTimers } from "react-idle-timer";
import {
  getItem,
  INACTIVETIMEOUT,
  INACTIVETIMEOUTDEBOUNCETIME,
  LOCAL_STORAGE_KEY,
  NOTIFICATION_TYPE,
  PROMPTTIMEOUT,
  setItem,
  showNotification,
} from "../../../utils";
import { NAVIGATION_URL } from "../../../constants";
import { logoutUser } from "../../../redux/action/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

function InactiveLogout({ isFromCardsModule = true }) {
  const promptTimeout = PROMPTTIMEOUT;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmPopup, setConfirmPopUp] = useState(false);

  const closeConfirmPopup = () => {
    // newRefreshToken()
    setConfirmPopUp(false);
    reset();
  };
  const handleLogout = () => {
    var form_data = new FormData();
    form_data.append("token_id", localStorage.getItem("access_token"));
    dispatch(logoutUser(form_data))
      .then((res) => {
        if (res?.statusCode === 201) {
          localStorage.removeItem("kloo_login");
          localStorage.setItem("timed_out", new Date().toISOString());
          navigate(NAVIGATION_URL.LOGIN);
        }
      })
      .catch((err) => {
        showNotification(
          NOTIFICATION_TYPE.error,
          "Logout failed!",
          "Something went wrong"
        );
      });
  };

  const handleOnIdle = (event) => {
    if (isFromCardsModule) {
      setConfirmPopUp(false);
      handleLogout();
    } else {
      setItem(LOCAL_STORAGE_KEY.VERIFY_OTP, false);
      setItem(LOCAL_STORAGE_KEY.INACTIVE_OTP, true);
    }
  };

  const onPrompt = () => {
    if (isFromCardsModule) {
      setConfirmPopUp(true);
      const mergeIFrame = document.getElementById("merge-link-iframe");
      if (mergeIFrame) mergeIFrame.style.display = "none";
    }
  };

  const handleOnActive = (event) => {
    if (isFromCardsModule) {
      setConfirmPopUp(false);
    }
  };
  const handleOnAction = (event) => {
    // console.log('user did something', event)
  };
  const { getRemainingTime, getLastActiveTime, isPrompted, reset } =
    useIdleTimer({
      timeout: INACTIVETIMEOUT,
      crossTab: true,
      syncTimers: 200,
      isPrompted: true,
      promptTimeout,
      onPrompt,
      timers: workerTimers,
      onIdle: handleOnIdle,
      onActive: handleOnActive,
      onAction: handleOnAction,
      debounce: INACTIVETIMEOUTDEBOUNCETIME,
    });

  useEffect(() => {
    if (isPrompted() && isFromCardsModule) {
      setConfirmPopUp(true);
    }
    if (isPrompted() && !isFromCardsModule) {
      setItem(LOCAL_STORAGE_KEY.VERIFY_OTP, false);
      setItem(LOCAL_STORAGE_KEY.INACTIVE_OTP, true);
    }
  }, [getRemainingTime, isPrompted]);

  return (
    <>
      {confirmPopup && (
        <UserInactiveModal
          onContinue={closeConfirmPopup}
          promptTimeout={PROMPTTIMEOUT}
        />
      )}
    </>
  );
}

export default InactiveLogout;
