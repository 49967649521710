import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";

export async function getVisibilyAndOptionList({ integrationType }) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.customConditionalField.getVisibiltyAndOptionList({
      integrationType,
    }),
  });
  return tempResponse?.data?.result;
}

export async function createCustomField({ integrationType, data }) {
  return apiClient({
    method: "POST",
    url: PATH.customConditionalField.createCustomField({ integrationType }),
    data,
  });
}

export async function updateCustomFields({
  integrationType,
  data,
  customFieldId,
}) {
  return apiClient({
    method: "PUT",
    url: PATH.customConditionalField.updateCustomeField({
      integrationType,
      customFieldId,
    }),
    data,
  });
}

export async function getCustomFieldDetailById({
  integrationType,
  customFieldId,
}) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.customConditionalField.getCustomFieldDetailById({
      integrationType,
      customFieldId,
    }),
  });
  return tempResponse?.data?.result;
}

export async function deleteCustomExpense({ integrationType, customFieldId }) {
  const tempResponse = await apiClient({
    method: "DELETE",
    url: PATH.customConditionalField.deleteCustomExpense({
      integrationType,
      customFieldId,
    }),
  });
  return tempResponse?.data?.result;
}

export async function getCategorisationToggleData() {
  const tempResponse = await apiClient({
    url: PATH.categorisation.getCategorisationToggleDataV2(),
    method: "GET",
  });
  return tempResponse?.data?.result;
}

export async function getCustomFieldsV2({ integrationType }) {
  const tempResponse = await apiClient({
    url: PATH.customConditionalField.getCustomFields({ integrationType }),
    method: "GET",
  });
  return tempResponse?.data?.result;
}

export async function updateCategorisationToggleData(type, data) {
  const tempResponse = await apiClient({
    url: PATH.categorisation.updateCategorisationToggleV2(type),
    method: "PATCH",
    data,
  });
  return tempResponse?.data?.result;
}

export async function customExpenseList(type) {
  const tempResponse = await apiClient({
    url: PATH.categorisation.customExpenseList(type),
    method: "GET",
  });
  return tempResponse?.data?.result;
}

export async function defaultCustomSequence({ data, integrationType }) {
  const tempResponse = await apiClient({
    url: PATH.categorisation.defaultConditionalSequence({
      integrationType,
    }),
    method: "POST",
    data,
  });
  return tempResponse?.data?.result;
}

export async function getConditionalList({
  integrationType,
  field_visibility,
}) {
  const tempResponse = await apiClient({
    url: PATH.categorisation.getConditionalList({
      integrationType,
      field_visibility,
    }),
    method: "GET",
  });
  return tempResponse?.data?.result;
}

export async function getCustomFieldsByPoId(id, lineItemIds) {
  const tempResponse = await apiClient({
    url: PATH.customConditionalField.getCustomFieldsByPOId(id, lineItemIds),
    method: "GET",
  });
  return tempResponse?.data?.result;
}
