import React from "react";
// import logo from "../../../assets/images/logo.png";
import logo from "../../../assets/images/kloo-white-logo-title.svg";
import TopRightLogo from "../../../assets/images/registration-top-corner.svg";
import BottomLeftLogo from "../../../assets/images/registration-bottom-corner.svg";
import { Row, Column } from "..";
import { Link } from "react-router-dom";
import { Box } from "../../common";
import "./image-background-container.scss";

export function ImageBackgroundContainer({ children }) {
  return (
    // <>
    //   <Row justify="center">
    //     <Column span={22}>
    //       <header className="App-header">
    //         <div className="with-logout">
    //           <img src={logo} className="App-logo" alt="logo" />
    //         </div>
    //       </header>
    //     </Column>
    //   </Row>
    //   <div className="img-container kloo-log-bg">
    //     <div className="child-container">{children}</div>
    //   </div>
    // </>

    <div className="kloo-log-bg">
      <Box className="kloo-log-bg-left">
        <div className="kloo-log-bg-top-right-corner">
          <img src={TopRightLogo} alt="top-logo" />
        </div>
        <div className="kloo-log-bg-logo-container">
          <Link to="/">
            <img src={logo} className="kloo-log-bg-logo" alt="logo" />
          </Link>
        </div>
        <div className="kloo-log-bg-bottom-left-corner">
          <img src={BottomLeftLogo} alt="bottom-logo" />
        </div>
      </Box>

      <Box wrap={"nowrap"} className="kloo-log-bg-right">
        <div className="child-container">{children}</div>
      </Box>
    </div>
  );
}

export default ImageBackgroundContainer;
