import React from "react";
import { Box, CustomButton, Icon, Modal, Tabs, Typography } from "..";
import ViewAccounts from "../../SupplierManagement/components/ViewAccounts";
import {
  ACCENTS,
  BUTTON_VARIANT,
  SUPPLIER_VIEW_ACCOUNT_MODAL,
} from "../../../constants";

function SupplierAccountsViewModal({
  open,
  onCancel,
  title,
  selectedSupplier,
  getCurrencyNameFromId,
  countries,
  reloadTable,
  currencies,
  currentUserPermissionConfig,
}) {
  const { tabs } = SUPPLIER_VIEW_ACCOUNT_MODAL;
  const accountTabs = [
    {
      key: "1",
      label: tabs[0],
      children: (
        <ViewAccounts
          getCurrencyNameFromId={getCurrencyNameFromId}
          countries={countries}
          reloadTable={reloadTable}
          currencies={currencies}
          currentUserPermissionConfig={currentUserPermissionConfig}
          selectedSupplier={selectedSupplier}
          onCancel={onCancel}
        />
      ),
    },
  ];

  return (
    <Modal
      isOpen={open}
      hide={onCancel}
      width={1000}
      showHeader={false}
      showCloseIcon={false}
    >
      <Box justifyContent="spaceBetween" alignItem="flexStart">
        <Typography
          className={"text-align-center m-b-12px"}
          variant="title"
          text={title}
          fontSize={"24"}
          responsiveFontSize={false}
        />

        <CustomButton
          onClick={onCancel}
          icon={<Icon iconName={"close"} />}
          variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
          accent={ACCENTS.BLACK}
        />
      </Box>
      <Box justifyContent="flexStart" className={"w-100"}>
        <Tabs items={accountTabs} className={"w-100"} />
      </Box>
    </Modal>
  );
}

export default SupplierAccountsViewModal;
