import { useEffect, useState } from "react";
import {
  ACCENTS,
  BUTTON_VARIANT,
  ERROR_MSG,
  INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY,
  MATCHED_INVOICES_OPERATION_CONFIG,
  NAVIGATION_URL,
  PO_GRNI_MATCH_DATA,
  PO_MATCHED_INVOICES_TABLE,
} from "../../../constants";
import { Box, CustomButton, Icon, Table, Typography, When } from "../../common";
import {
  NOTIFICATION_TYPE,
  addMessageListener,
  isStringOrInArray,
  removeMessageListener,
  showNotification,
  stringToArray,
} from "../../../utils";
import { getMatchedInvoiceList } from "../../../api";
import { useNavigate } from "react-router-dom";
import InvoiceMatchType from "../../InvoiceMatching/components/InvoiceMatchType";
import "./matched-invoices-list-container.scss";
import {
  isFeat11423Enabled,
  isInvoiceMatchedViaSplitting,
  isMatchingHidden,
} from "../../../helper";

const { TITLE, MATCH_INVOICED_VALUE_SUMMARY } =
  MATCHED_INVOICES_OPERATION_CONFIG;

function MatchedInvoicesListContainer(props) {
  const navigate = useNavigate();
  const [matchedInvoicesData, setMatchedInvoicesData] = useState(null);
  const { isGRNIEnabledForOrg } = props;

  const fetchMatchedInvoiceList = () => {
    getMatchedInvoiceList({
      poId: props.order_number,
      currency: props?.currencyData?.currency,
    })
      .then((data) => {
        props.onMatchedInvoicesDataLoaded &&
          props.onMatchedInvoicesDataLoaded(data);
        setMatchedInvoicesData(data);
      })
      .catch((err) => {
        setMatchedInvoicesData({});
        showNotification(
          NOTIFICATION_TYPE.error,
          err?.response?.data?.message || ERROR_MSG.common
        );
      })
      .finally(() => {
        props?.showPoAvailableTextToggle(true);
      });
  };

  useEffect(() => {
    if (isFeat11423Enabled()) {
      addMessageListener(PO_GRNI_MATCH_DATA.CHANNEL_NAME, (message) => {
        if (message) {
          if (isStringOrInArray(stringToArray(message), props.order_number)) {
            fetchMatchedInvoiceList();
          }
        }
      });
    }
    return () => {
      removeMessageListener(PO_GRNI_MATCH_DATA.CHANNEL_NAME);
    };
  }, []);

  useEffect(() => {
    fetchMatchedInvoiceList();
  }, [props.reloadMatchedInvoiceList]);

  const goToInvoiceMatching = (invoice) => {
    if (isMatchingHidden()) {
      navigate(NAVIGATION_URL.SINGLE_MATCHING_INVOICE, {
        state: {
          invoice: { ...invoice, id: invoice.invoice_id },
          isGRNIEnabledForOrg,
        },
      });
      return;
    }
    navigate(NAVIGATION_URL.INVOICE_MATCHING, {
      state: { invoice: { ...invoice, id: invoice.invoice_id } },
    });
  };

  const goToInvoiceManagementSplittingModal = (invoice) => {
    navigate(NAVIGATION_URL.INVOICE_MANAGEMENT, {
      state: { invoice, isSplittingModalOpen: true, isGRNIEnabledForOrg },
    });
  };

  const renderBody = ({ label, key, record, index, defaultRender }) => {
    switch (key) {
      case "action":
        return (
          <Box wrap={"nowrap"}>
            <CustomButton
              icon={<Icon iconName={"arrow_forward"} />}
              accent={ACCENTS.BLACK}
              variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
              onClick={(event) => {
                event.stopPropagation();
                if (isInvoiceMatchedViaSplitting({ invoiceData: record })) {
                  goToInvoiceManagementSplittingModal(record);
                } else {
                  goToInvoiceMatching(record);
                }
              }}
            />
          </Box>
        );
      case INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY:
        return <InvoiceMatchType matchType={record[key]} />;
      default:
        return defaultRender({
          label,
          key,
          record,
          index,
          defaultRender,
        });
    }
  };

  return (
    <>
      <When
        condition={
          matchedInvoicesData !== null && matchedInvoicesData?.list?.length
        }
      >
        <Typography {...TITLE} />
        <Table
          className="p-0px h-100"
          tableClassName="matched-invoices-listing-table m-b-0px"
          columns={PO_MATCHED_INVOICES_TABLE}
          data={matchedInvoicesData?.list}
          renderBody={renderBody}
          enableBorder={true}
        />
        <Typography
          {...MATCH_INVOICED_VALUE_SUMMARY.type}
          text={MATCH_INVOICED_VALUE_SUMMARY.generateValue(
            matchedInvoicesData?.totalAmount
          )}
        />
      </When>
    </>
  );
}

export default MatchedInvoicesListContainer;
