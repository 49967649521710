import { memo, useEffect } from "react";
import {
  ACCENTS,
  BUTTON_VARIANT,
  PURCHASE_ORDER_CREATE_FROM,
} from "../../../constants";
import { MODAL_TYPES } from "../../../utils";
import {
  Box,
  CustomButton,
  Icon,
  Label,
  Table,
  Typography,
  When,
  useGlobalModalContext,
} from "../../common";
import Upload from "../../common/Upload";
import "./style.scss";

function UploadAttachmentForPO({
  customRequest,
  viewAttachments,
  setViewAttachments,
  attachments,
  removeSelectedAttachment,
  uploadContainerDisabled = false,
}) {
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    if (attachments?.length) {
      setViewAttachments(true);
    }
  }, []);

  const handleViewAttachments = () => {
    setViewAttachments(!viewAttachments);
  };

  const showUploadedAttachments = (record) => {
    showModal(MODAL_TYPES.UPLOADED_ATTACHMENTS_MODAL, {
      title: record?.name,
      file: record,
      fileURL: record?.attachment_url,
    });
  };

  function renderActionColumn({ record, index }) {
    return (
      <>
        <Box direction="row">
          <CustomButton
            icon={<Icon iconName={"visibility"} />}
            accent={ACCENTS.BLACK}
            variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
            onClick={() => showUploadedAttachments(record)}
          />
          <CustomButton
            icon={
              <Icon iconName={"close"} className={"material-icons-outlined"} />
            }
            accent={ACCENTS.DESTRUCTIVE}
            variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
            onClick={() => removeSelectedAttachment(record, index)}
            disabled={uploadContainerDisabled}
          />
        </Box>
      </>
    );
  }

  const renderBody = ({
    label,
    key,
    record,
    index,
    defaultRender,
    expandRow,
    isRowExpanded,
  }) => {
    return key === "actions"
      ? renderActionColumn({
          label,
          key,
          record,
          index,
          defaultRender,
          isRowExpanded,
          expandRow,
        })
      : defaultRender({ label, key, record, index, defaultRender });
  };

  return (
    <>
      <Box
        justifyContent="spaceBetween"
        wrap="noWrap"
        className="attachment-cnt px-2 py-2 m-t-16px"
      >
        <div>
          <Label
            text={PURCHASE_ORDER_CREATE_FROM.attachmentsSwitch.label}
            optional={true}
          />
          <Typography
            variant="body"
            text={PURCHASE_ORDER_CREATE_FROM.attachmentsSwitch.info}
            fontSize={12}
          />
        </div>
        <CustomButton
          icon={
            <Icon iconName={viewAttachments ? "expand_less" : "expand_more"} />
          }
          accent={ACCENTS.SECONDARY}
          variant={BUTTON_VARIANT.BUTTON_TEXT}
          onClick={handleViewAttachments}
          disabled={uploadContainerDisabled}
        />
      </Box>
      <When condition={viewAttachments}>
        <div className="upload-po-section">
          <When condition={attachments?.length}>
            <div className="attachment-list-section p-10px">
              <Table
                tableClassName="m-b-0px"
                columns={PURCHASE_ORDER_CREATE_FROM.ATTACHMENT_TABLE_COLUMN}
                renderBody={renderBody}
                data={attachments}
                enableBorder={true}
              />
            </div>
          </When>
          <When condition={viewAttachments && attachments?.length < 5}>
            <Upload
              accept=".png,.jpg,.jpeg,.pdf"
              maxCount={5}
              className={"d-flex flex-column-reverse cursor-pointer"}
              showUploadList={false}
              maxSize={4}
              maxSizeErrorMessage={
                PURCHASE_ORDER_CREATE_FROM.attachmentsMaxSizeError
              }
              uploadedFiles={attachments}
              customRequest={customRequest}
              disabled={uploadContainerDisabled}
            />
          </When>
        </div>
      </When>
    </>
  );
}

export default memo(UploadAttachmentForPO);
