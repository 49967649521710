import { memo, useEffect } from "react";
import PropTypes from "prop-types";
import useToggle from "../../../hooks/useToggle";
import { constructClassName } from "../../../utils";
import Box from "../Box";
import Checkbox from "../Checkbox";
import Popover from "../Popover";
import Icon from "../Icon";
import { selectAllCheckboxItemsKey } from "../../../constants";

function SelectAllPopOver({
  handleSelection,
  allSelected,
  isAllDisabled,
  selectedAllDropdownItem,
  handleSelectedAllDropdownItem,
  renderSelectAllBody,
  dataInSync,
  onSelectAll,
}) {
  const { isOpen, toggle } = useToggle();
  const { isOpen: checkboxStatus, toggle: toggleCheckBoxStatus } = useToggle();

  const toggleCheckboxAndUpdateParent = () => {
    if (!checkboxStatus && onSelectAll) onSelectAll();
    toggleCheckBoxStatus();
  };

  const { data, children } = renderSelectAllBody({
    handleSelection,
    togglePopOver: toggle,
    toggleCheckBoxStatus: toggleCheckboxAndUpdateParent,
  });

  const singleDropdown =
    data.length === 1 &&
    data[0].key === selectAllCheckboxItemsKey.AVAILABLE_ITEMS;
  const selectAllStatus = singleDropdown ? checkboxStatus : allSelected;
  const selectAllDisableStatus = singleDropdown
    ? !singleDropdown
    : isAllDisabled;

  useEffect(() => {
    if (dataInSync && !singleDropdown) {
      if (allSelected) {
        handleSelectedAllDropdownItem({
          item: selectedAllDropdownItem.length
            ? selectedAllDropdownItem[0]
            : data[0],
        });
      } else if (!allSelected) {
        handleSelectedAllDropdownItem({ item: undefined });
      }
    }
  }, [dataInSync, allSelected]);

  const handleCheckboxChange = () => {
    if (singleDropdown) {
      toggleCheckboxAndUpdateParent();
    } else {
      handleSelection({ allSelected: !allSelected });
    }
    handleSelectedAllDropdownItem({
      item: selectAllStatus ? undefined : data[0],
    });
    if (!selectAllStatus) toggle();
  };

  return (
    <Box
      className={"kloo-select-all-column-header"}
      wrap={"nowrap"}
      justifyContent={"flexStart"}
      rowGap={0}
      columnGap={0}
    >
      <Checkbox
        checked={selectAllStatus}
        disabled={selectAllDisableStatus}
        onChange={handleCheckboxChange}
      />
      <Popover
        content={children}
        trigger="click"
        placement={"bottomLeft"}
        open={!selectAllDisableStatus && isOpen}
        onOpenChange={toggle}
        showArrow={false}
        overlayClassName={"kloo-select-all-column-header-popover"}
      >
        <Icon
          iconName={"arrow_drop_down"}
          className={constructClassName([
            "kloo-select-all-dropdown",
            selectAllStatus ? "active" : undefined,
            selectAllDisableStatus ? "disabled" : undefined,
          ])}
        />
      </Popover>
    </Box>
  );
}

SelectAllPopOver.propTypes = {
  allSelected: PropTypes.bool,
  isAllDisabled: PropTypes.bool,
  dataInSync: PropTypes.bool,
  selectedAllDropdownItem: PropTypes.array,
  handleSelectedAllDropdownItem: PropTypes.func,
  handleSelection: PropTypes.func,
  renderSelectAllBody: PropTypes.func,
};

SelectAllPopOver.defaultProps = {
  allSelected: false,
  isAllDisabled: false,
  dataInSync: false,
  selectedAllDropdownItem: [],
  handleSelectedAllDropdownItem: () => {},
  handleSelection: () => {},
  renderSelectAllBody: () => {},
};

export default memo(SelectAllPopOver);
