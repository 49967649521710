import { GRNI_OFFSET_MODAL_FIELD_RULES } from "../../utils";
import { LINE_ITEM_TABLE_COLUMNS } from "../LineItems";
import { SUPPLIER_PLACEHOLDER } from "../dynamic";
import { ACCENTS, BUTTON_VARIANT, TAG_VARIANT } from "../theme";

export const INVOICE_MATCHING_TABLE_COLUMNS_STATUS_KEY = "status";
export const INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY = "matchType";
export const INVOICE_MATCHING_TABLE_COLUMNS_MATCH_KEY = "match";
export const INVOICE_MATCHING_TABLE_COLUMNS_REMAINING_AMOUNT_KEY =
  "remainingAmount";
export const INVOICE_MATCHING_TABLE_COLUMNS_AMOUNT_KEY = "amount";
export const INVOICE_MATCHING_TABLE_COLUMNS_INVOICE_NUMBER_KEY =
  "invoice_number";
export const INVOICE_MATCHING_TABLE_COLUMNS_PO_NUMBER_KEY = "poNumber";
export const INVOICE_MATCHING_TABLE_COLUMNS_SELECTION_KEY = "selectionAdd";
export const INVOICE_MATCHING_TABLE_COLUMNS_SELECTION_REMOVE_KEY =
  "selectionRemove";
export const INVOICE_MATCHING_TABLE_COLUMNS_ALLOCATED_AMOUNT_KEY =
  "allocatedAmount";
export const INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS_PO_NUMBER_KEY =
  "order_number";
export const INVOICE_MATCHING_TABLE_COLUMNS_PO_NUMBER_KEY_LABEL = "PO number";
export const INVOICE_MATCHING_TABLE_COLUMNS_GRNI_OFFSET_LABEL_KEY =
  "grniOffset";
export const INVOICE_MATCHING_TABLE_COLUMNS_PO_AFTER_OFFSET_LABEL_KEY =
  "poAmountRemainingAfterOffset";
export const INVOICE_MATCHING_TABLE_COLUMNS_PO_AFTER_MATCH_LABEL_KEY =
  "poAmountLeftAfterMatch";

export const INVOICE_MATCHING_SUGGESTED_INFO =
  "This match has been suggested by Kloo AI.";

export const INVOICE_MATCHING_DEFAULT_ROW_HEIGHT = 48;
export const INVOICE_MATCHING_DEFAULT_PAGE_SIZE = 10;

export const PAGE_HEADER_INFO_INVOICE_MATCHING = {
  desc: "Match your organisations approved purchase orders with invoices so that you can compare planned spend and committed spend.",
  header: "Invoice matching ",
};

export const PAGE_HEADER_INFO_INVOICE_MATCHING_SUGGESTED = {
  desc: "Match invoices against approved purchase orders, to ensure that actual spend is aligned with approved spend.",
  header: "Invoice matching ",
};

export const PAGE_HEADER_INFO_INVOICE_MATCHING_MANUAL = {
  desc: "Match your organisation purchase orders with approved invoices so that you can compare planned spend and committed spend.",
  header: "Invoice matching ",
};

export const INVOICE_MATCHING_TAG_CLASSNAMES = {
  approved: TAG_VARIANT.ACTIVE,
  rejected: TAG_VARIANT.REJECTED,
  failed: TAG_VARIANT.REJECTED,
  submitted: TAG_VARIANT.AWAITING_APPROVAL,
};

export const INVOICE_MATCHING_TABLE_COLUMNS = {
  invoiceNumber: {
    label: "Invoice number",
    key: INVOICE_MATCHING_TABLE_COLUMNS_INVOICE_NUMBER_KEY,
    align: "left",
  },
  poNumber: {
    label: "PO number",
    key: INVOICE_MATCHING_TABLE_COLUMNS_PO_NUMBER_KEY,
    align: "left",
  },
  payee: {
    label: SUPPLIER_PLACEHOLDER,
    key: "payee",
    align: "left", // altered in API
  },
  description: {
    label: "Description",
    key: "description",
    align: "left",
  },
  owner: {
    label: "Owner",
    key: "owner",
    align: "left",
  },
  remainingAmount: {
    label: "Remaining amount",
    key: INVOICE_MATCHING_TABLE_COLUMNS_REMAINING_AMOUNT_KEY,
    align: "right", // altered in API
  },
  amount: {
    label: "Total amount",
    key: INVOICE_MATCHING_TABLE_COLUMNS_AMOUNT_KEY,
    align: "right", // altered in API
  },
  matchType: {
    label: "Match type",
    key: INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY,
    align: "center",
  },
  match: {
    label: "Match",
    key: INVOICE_MATCHING_TABLE_COLUMNS_MATCH_KEY,
    align: "center",
  },
};

export const INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS = {
  invoiceNumber: {
    label: "PO number",
    key: INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS_PO_NUMBER_KEY,
    align: "center",
  },
  supplier: {
    label: SUPPLIER_PLACEHOLDER,
    key: "supplier_name",
    align: "left",
  },
  description: {
    label: "Description",
    key: "description",
    align: "left",
  },
  totalGRNIAmount: {
    label: "Total GRNI amount",
    key: "total_grni_amount",
    align: "right",
  },
  totalAmount: {
    label: "Net amount",
    key: "net_amount",
    align: "right", // altered in API
  },
  remainingAmount: {
    label: "Remaining net amount",
    key: "remaining_amount",
    align: "right", // altered in API
  },
  amount: {
    label: "Remaining amount",
    key: "remaining_amount",
    align: "right", // altered in API
  },
  allocatedAmount: {
    label: "Allocated amount",
    key: INVOICE_MATCHING_TABLE_COLUMNS_ALLOCATED_AMOUNT_KEY,
    align: "left", // altered in API
  },
  selectionModalColumn: {
    label: "",
    key: INVOICE_MATCHING_TABLE_COLUMNS_SELECTION_KEY,
    align: "left",
  },
  deleteColumn: {
    label: "",
    key: INVOICE_MATCHING_TABLE_COLUMNS_SELECTION_REMOVE_KEY,
    align: "right",
  },
  grossAmount: {
    label: "Net amount",
    key: "gross_amount",
    align: "right", // altered in API
  },
  grniOffset: {
    label: "GRNI offset",
    key: INVOICE_MATCHING_TABLE_COLUMNS_GRNI_OFFSET_LABEL_KEY,
  },
  poAmountAfterOffset: {
    label: "PO amount after offset",
    key: INVOICE_MATCHING_TABLE_COLUMNS_PO_AFTER_OFFSET_LABEL_KEY,
  },
  poAmountAfterMatch: {
    label: "PO amount after match",
    key: INVOICE_MATCHING_TABLE_COLUMNS_PO_AFTER_MATCH_LABEL_KEY,
  },
};

export const INVOICE_MATCHING_MODAL_TABLE_LISTING_COLUMNS = [
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.selectionModalColumn,
    width: 8,
  },
  { ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.invoiceNumber, width: 12 },
  { ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.supplier, width: 20 },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.description,
    width: 15,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.totalGRNIAmount,
    width: 15,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.remainingAmount,
    key: "formattedRemainingAmount",
    width: 15,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.grossAmount,
    key: "formattedGrossAmount",
    width: 15,
  },
];

export const INVOICE_MATCHING_MODAL_TABLE_LISTING_COLUMNS_WITHOUT_GRNI = [
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.selectionModalColumn,
    width: 10,
  },
  { ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.invoiceNumber, width: 15 },
  { ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.supplier, width: 20 },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.description,
    width: 20,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.remainingAmount,
    key: "formattedRemainingAmount",
    width: 17,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.grossAmount,
    key: "formattedGrossAmount",
    width: 18,
  },
];

export const INVOICE_MATCHING_MODAL_SELECTED_TABLE_LISTING_COLUMNS = [
  { ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.invoiceNumber, width: 12 },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.supplier,
    width: 10,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.description,
    width: 15,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.totalGRNIAmount,
    width: 15,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.remainingAmount,
    key: "formattedRemainingAmount",
    width: 20,
  },
  { ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.allocatedAmount, width: 20 },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.deleteColumn,
    width: 8,
    ellipsis: false,
  },
];

export const INVOICE_MATCHING_MODAL_SELECTED_TABLE_LISTING_COLUMNS_WITHOUT_GRNI =
  [
    { ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.invoiceNumber, width: 17 },
    {
      ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.supplier,
      width: 15,
    },
    {
      ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.description,
      width: 20,
    },
    {
      ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.remainingAmount,
      key: "formattedRemainingAmount",
      width: 20,
    },
    {
      ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.allocatedAmount,
      width: 20,
    },
    {
      ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.deleteColumn,
      width: 8,
      ellipsis: false,
    },
  ];

export const INVOICE_MATCHING_TABLE_COLUMNS_SUBMITTED = [
  {
    ...INVOICE_MATCHING_TABLE_COLUMNS.invoiceNumber,
    width: 15,
  },
  { ...INVOICE_MATCHING_TABLE_COLUMNS.payee, width: 20 },
  {
    ...INVOICE_MATCHING_TABLE_COLUMNS.description,
    width: 15,
  },
  { ...INVOICE_MATCHING_TABLE_COLUMNS.owner, width: 15 },
  { ...INVOICE_MATCHING_TABLE_COLUMNS.amount, width: 10 },
  { ...INVOICE_MATCHING_TABLE_COLUMNS.matchType, width: 20 },
  { ...INVOICE_MATCHING_TABLE_COLUMNS.match, width: 5 },
];

export const INVOICE_MATCHING_TABLE_COLUMNS_AUTO_MATCHED = [
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.invoiceNumber,
    width: 20,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.supplier,
    width: 20,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.description,
    width: 15,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.totalGRNIAmount,
    width: 15,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.remainingAmount,
    width: 15,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.totalAmount,
    width: 15,
  },
];

export const INVOICE_MATCHING_TABLE_COLUMNS_AUTO_MATCHED_WITHOUT_GRNI = [
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.invoiceNumber,
    width: 20,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.supplier,
    width: 20,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.description,
    width: 20,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.remainingAmount,
    width: 20,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.totalAmount,
    width: 20,
  },
];

export const INVOICE_MATCHING_TABLE_COLUMNS_MANUAL_MATCHED = [
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.invoiceNumber,
    width: 15,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.supplier,
    width: 15,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.description,
    width: 20,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.totalGRNIAmount,
    width: 15,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.remainingAmount,
    width: 15,
  },
  {
    ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.totalAmount,
    width: 15,
  },
];

export const key_net_amount = "net_amount";

export const INVOICE_INFO_VALUES = [
  {
    label: "Invoice number",
    key: "invoice_number",
  },
  {
    label: INVOICE_MATCHING_TABLE_COLUMNS_PO_NUMBER_KEY_LABEL,
    key: "matchedPoNumbers",
  },
  {
    label: SUPPLIER_PLACEHOLDER,
    key: "payee",
  },
  {
    label: "Description",
    key: "description",
  },
  {
    label: "Net amount",
    key: key_net_amount,
  },
  {
    label: "Owner",
    key: "owner_name",
  },
];

export const IM_PO_MATCHING_LOCAL_STORAGE_KEYS = {
  CONTAINER: "im-po-matching-container",
  TABLE_CONTAINER: "im-po-matching-table-container",
};

export const MATCH_TYPE = {
  CONFIRMED: "Confirmed",
  SUGGESTED: "Suggested",
  THRESHOLD: "Threshold",
  NOT_A_MATCH: "NotAMatch",
  PARTIAL: "Partial",
};

export const MATCH_TYPE_LABEL = {
  CONFIRMED: "Confirmed match",
  SUGGESTED: "Suggested match by Kloo AI",
  PARTIAL: "Partial match",
  NOT_A_MATCH: "No match",
};

export const INVOICE_MATCHING_BTN_LABELS = {
  MATCH: "Match",
  MATCHING: "Matching...",
  UNMATCH: "Unmatch",
  UNMATCHING: "Unmatching...",
  REMATCH: "Rematch",
  REMATCHING: "Rematching...",
  CONTINUE: "Continue",
};

export const INVOICE_MATCHING_QUERY_PARAM = {
  invoiceId: "invoiceId",
};

export const REMATCH_MODAL = {
  TITLE: "Rematch invoice",
  SUB_TITLE:
    "Are you sure you would like to rematch this invoice? Any purchase orders which have been matched to this invoices will be unmatched.",
  EXTRA_DETAIL: ({ item = [] }) => {
    const setSVariable = item.length > 1 ? "s" : "";
    const setOneorMoreVariable = item.length > 1 ? " one ore more" : "another";
    return `As your organisation requires invoice${setSVariable} to be confirmed matched before they are approved, rematching this invoice will unassign it from its workflow unless it’s rematched completely to ${setOneorMoreVariable} purchase order${setSVariable}.`;
  },
};

export const MATCHING_MODAL = {
  TITLE: "Invoice splitting",
};

export const UN_MATCH_MODAL = {
  TITLE: "Unmatch invoice",
  SUB_TITLE:
    "Are you sure you want to unmatch this invoice? The matched purchase order will have its remaining amount restored according to the total amount of this invoice.",
  SUB_TITLE_2: ({ item = [] }) => {
    return `As your organisation requires invoices to be confirmed matched before they are approved, unmatching this invoice will unassign it from its workflow and move the invoice back to the submitted tab.`;
  },
};

export const INVOICE_MATCHING_TOAST_MSG = {
  MATCHED: "Invoice successfully matched",
  UNMATCHED: "Invoice successfully unmatched",
  REMATCHED: "Invoice successfully rematched",
};

export const INVOICE_MATCHING_CONTENT = {
  reconcileLabel: (amount) => `PO amount left to reconcile: ${amount}`,
  noMatchInfo:
    "This invoice cannot be matched with this purchase order as its amount exceeds the remaining amount beyond the threshold matching range",
  isMatchInfo: (matchType) =>
    `Invoice will be marked as ‘${matchType} matched’`,
};

export const MATCHING_INFO_TAG =
  "To begin, select any invoice to match with against an approved list of purchase orders.";

export const getMatchTypeIconLabel = (key) => {
  const matchType = {
    [MATCH_TYPE.CONFIRMED]: {
      icon: "check_circle",
      label: MATCH_TYPE_LABEL.CONFIRMED,
      className: "green-color",
    },
    [MATCH_TYPE.SUGGESTED]: {
      icon: "search",
      label: MATCH_TYPE_LABEL.SUGGESTED,
      className: "primary-color",
    },
    [MATCH_TYPE.NOT_A_MATCH]: {
      icon: "cancel",
      label: MATCH_TYPE_LABEL.NOT_A_MATCH,
      className: "red-color",
    },
  };
  return matchType[key];
};

export const CONFIRM_MATCHING_CONFIRMATION_MODAL = {
  TITLE: ({ isRematch }) => `Confirm ${isRematch ? "re" : ""}match`,
  SUBTITLE: ({ isRematch }) =>
    `To ${
      isRematch ? "re" : ""
    }match this invoice, you can offset the GRNI balance by the minimum amount specified in the 'Offset amount' field to ensure you are within the remaining PO budget, including the threshold matching range.`,
  FORM_FIELDS: [
    {
      field: {
        label: "Offset amount",
        name: "offsetAmount",
        placeholder: "Enter offset amount",
        wrapperClass: "offset-amount-field-grni",
        precision: 2,
        controls: false,
        isRequiredField: true,
        labelCol: { span: "12" },
        wrapperCol: { span: "12" },
      },
      rules: (data) => GRNI_OFFSET_MODAL_FIELD_RULES.OFFSET_AMOUNT(data),
      helperText:
        "This is the minimum GRNI offset amount required to match the invoice.",
    },
    {
      field: {
        label: "PO amount remaining after offset",
        name: "poAmountRemainingAfterOffset",
        disabled: true,
        labelCol: { span: "12" },
        wrapperCol: { span: "12" },
      },
    },
    {
      field: {
        label: "PO amount left after match",
        name: "poAmountLeftAfterMatch",
        disabled: true,
        labelCol: { span: "12" },
        wrapperCol: { span: "12" },
      },
    },
  ],
  CTA: {
    match: {
      text: "Confirm",
      accent: ACCENTS.PRIMARY,
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      customSize: "large",
      showLoader: true,
    },
    cancel: {
      text: "Cancel",
      accent: ACCENTS.SECONDARY,
      variant: BUTTON_VARIANT.BUTTON_HOLLOW,
      customSize: "large",
    },
  },
};

export const INVOICE_SINGLE_MATCHING_CONSTANT = {
  REMAINING_PURCHASE_AMOUNT: (amount) =>
    `Purchase order remaining amount after match: ${amount}`,
  GRNI_OFFSET_NOTIFICATION:
    "The selected purchase order has GRNI recorded against it. Continue to offset the GRNI value against this purchase order.",
};

export const INVOICE_SPLITTING_CONSTANT = {
  REMAINING_POS_AMOUNT: (amount) =>
    `Remaining amount of invoice to match: ${amount}`,
  REMAINING_INVOICE_AMOUNT: (amount) =>
    `Remaining net amount of invoice to match: ${amount}`,
  SPLIT_TYPE: {
    PARTIAL: "Partial",
    CONFIRMED: "Confirmed",
  },
  CONFIRMATION_TEXT: (type) =>
    `Confirming this split will mark this invoice as a ${type} match`,
  REMAINING_INVOICE_AMOUNT_1: (amount) =>
    `Invoice net amount remaining to match: ${amount}`,
  SPLIT_TYPE_1: {
    PARTIAL: "partial",
    CONFIRMED: "confirmed",
  },
  CONFIRMATION_TEXT_1: (type) =>
    `This invoice will be marked as a ${type} match`,
  BUTTONS_LABEL: {
    SPLIT: "Split",
    CONFIRM: "Confirm",
    CANCEL: "Cancel",
    CONTINUE: "Continue",
  },
  INFO_MSG: "Add the purchase orders which should be split across this invoice",
  GREATER_ALLOCATED_AMOUNT_ERR:
    "The sum of allocated amounts exceeds the total invoice amount.",
  SPLIT_SUCCESS_MSG: "Invoice successfully split",
};

export const INVOICE_SPLITTING_GRNI_OFFSET_CONFIG = {
  TITLE: "GRNI Offset",
  SUBTITLE: "Enter the amount of GRNI to deduct from each purchase order.",
  GRNI_OFFSET_NOTIFICATION_1:
    "One or more of the selected purchase orders have a GRNI value logged against it.",
  GRNI_OFFSET_NOTIFICATION_2:
    "Please click Continue to detect the GRNI value against each purchase order.",
  GRNI_OFFSET_COLUMN_CONFIG: [
    {
      ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.invoiceNumber,
      tooltip: "The purchase order number for this PO",
      width: 13,
    },
    {
      ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.allocatedAmount,
      tooltip: "The amount being deducted from this purchase order",
      width: 16,
      align: "center",
    },
    {
      ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.totalGRNIAmount,
      tooltip:
        "The value of the goods received not invoiced against this purchase order",
      width: 16,
      align: "center",
    },
    {
      ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.grniOffset,
      tooltip:
        "The amount of GRNI you want to offset from this purchase order. The minimum deduction amount has already been pre-calculated based of the allocated amount",
      width: 17,
      align: "center",
    },
    {
      ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.poAmountAfterOffset,
      tooltip:
        "The remaining amount of the purchase order left after the offset has been added",
      width: 19,
      align: "center",
    },
    {
      ...INVOICE_MATCHING_AUTO_MANUAL_TABLE_COLUMNS.poAmountAfterMatch,
      tooltip:
        "The remaining amount of the purchase order left after the match has been completed",
      width: 19,
      align: "center",
    },
  ],
};

export const GRNI_OFFSET_RESET_SUFFIX_CONFIG = {
  TOOLTIP: {
    title: "Reset to default value",
    trigger: ["hover"],
    placement: "top",
  },
  ICON_BUTTON: {
    variant: BUTTON_VARIANT.BUTTON_ONLY_ICON,
    accent: ACCENTS.BLACK,
  },
};

export const INVOICE_DETAILS_LINE_ITEMS_COLUMNS = {
  itemName: {
    label: "Item name",
    key: "name",
    align: "left",
  },
  quantity: {
    label: "Quantity",
    key: "quantity",
    align: "left",
  },
  unitCost: {
    label: "Unit cost",
    key: "cost",
    align: "left",
  },
  amount: {
    label: "Amount",
    key: "amount",
    align: "right",
  },
};

export const INVOICE_DETAILS_LINE_ITEMS_COLUMNS_SMALL = [
  INVOICE_DETAILS_LINE_ITEMS_COLUMNS.itemName,
  INVOICE_DETAILS_LINE_ITEMS_COLUMNS.amount,
];

export const INVOICE_DETAILS_LINE_ITEMS_COLUMNS_EXPANDED = [
  INVOICE_DETAILS_LINE_ITEMS_COLUMNS.itemName,
  INVOICE_DETAILS_LINE_ITEMS_COLUMNS.quantity,
  INVOICE_DETAILS_LINE_ITEMS_COLUMNS.unitCost,
  INVOICE_DETAILS_LINE_ITEMS_COLUMNS.amount,
];

export const INVOICE_DETAILS_LINE_ITEMS_COLUMNS_EXPANDED_WITH_ERP_FIELDS = [
  LINE_ITEM_TABLE_COLUMNS.description,
  LINE_ITEM_TABLE_COLUMNS.quantity,
  LINE_ITEM_TABLE_COLUMNS.unit_cost,
  LINE_ITEM_TABLE_COLUMNS.total_amount,
];

export const tooltipForClosedPO =
  "You cannot unmatch this invoice as it is matched to a closed PO. ";
