import React, { useState, useCallback, useEffect } from "react";
import { Select, Spin, Form } from "antd";
import debounce from "lodash/debounce";
import Label from "../Label";
import Icon from "../Icon";

const { Option } = Select;

const PaginatedSelect = React.memo(
  ({
    label,
    name,
    fetchOptions,
    rules,
    placeholder,
    wrapperClassFormItem,
    className,
    pageSize = 20,
  }) => {
    const [options, setOptions] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [hasFetched, setHasFetched] = useState(false);

    // Fetch options function with debounce
    const fetchMoreOptions = useCallback(
      debounce(async () => {
        if (fetching) return;

        setFetching(true);
        const newOptions = await fetchOptions(currentPage, pageSize);
        if (newOptions.length < pageSize) {
          setHasMore(false); // No more options to load
        }
        setOptions((prevOptions) => [...prevOptions, ...newOptions]);
        setFetching(false);
        setCurrentPage((prevPage) => prevPage + 1);
      }, 300),
      [currentPage, fetching, pageSize, fetchOptions]
    );

    // Handler for dropdown focus
    const handleFocus = () => {
      if (!hasFetched) {
        setHasFetched(true);
        fetchMoreOptions(); // Initial fetch when dropdown is focused
      }
    };

    // Handle scrolling inside the dropdown to fetch more options
    const handlePopupScroll = (e) => {
      const { target } = e;
      if (
        !fetching &&
        hasMore &&
        target.scrollTop + target.offsetHeight === target.scrollHeight
      ) {
        fetchMoreOptions();
      }
    };

    return (
      <Form.Item
        label={label ? <Label text={label} /> : null}
        name={name}
        rules={rules}
        colon={false}
        className={wrapperClassFormItem}
      >
        <Select
          className={`${className || ""} common-select`}
          clearIcon={
            <Icon iconName={"close"} className={"kloo-select-close-icon"} />
          }
          suffixIcon={<Icon iconName={"arrow_drop_down"} />}
          placeholder={placeholder}
          showSearch
          size="large"
          labelInValue
          filterOption={false}
          onFocus={handleFocus}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          onPopupScroll={handlePopupScroll}
          style={{ width: "100%" }}
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  }
);

export default PaginatedSelect;
