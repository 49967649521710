import { Collapse } from "antd";
import { memo } from "react";
import { constructClassName } from "../../../utils/functions";

const { Panel: APanel } = Collapse;

function Panel({ className, ...rest }) {
  return (
    <APanel
      className={constructClassName(["kloo-collapse-panel", className])}
      {...rest}
    />
  );
}

export default memo(Panel);
