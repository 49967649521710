import { Typography as TypographyAnt } from "antd";
import BodyText from "./BodyText";
import SecondaryText from "./SecondaryText";
import TertiaryText from "./TertiaryText";
import TitleText from "./TitleText";

export default function Typography(props) {
  if (props.variant === "title") return <TitleText {...props} />;
  if (props.variant === "secondary") return <SecondaryText {...props} />;
  if (props.variant === "body") return <BodyText {...props} />;
  if (props.variant === "tertiary") return <TertiaryText {...props} />;

  return <TypographyAnt {...props} />;
}
