import { Checkbox, When } from "..";
import SelectAllPopOver from "./SelectAllPopOver";
import {
  SELECTION_COLUMN_KEY,
  EXPANDABLE_COLUMN_KEY,
} from "../../../constants";
import "../../../assets/scss/Table.scss";

function defaultColumnRender({ label, key }) {
  return label;
}

function defaultSelectionHeaderRender({
  label,
  key,
  handleSelection,
  allSelected,
  isAllDisabled,
  enableSingleSelection,
}) {
  return (
    <>
      <When condition={!enableSingleSelection}>
        <Checkbox
          checked={!isAllDisabled && allSelected}
          disabled={isAllDisabled}
          onChange={() => handleSelection({ allSelected: !allSelected })}
        />
      </When>
      <When condition={enableSingleSelection}>
        {defaultColumnRender({ label, key })}
      </When>
    </>
  );
}

function defaultExpandableHeaderRender({ label, key }) {
  return (
    <>
      <When condition={key !== EXPANDABLE_COLUMN_KEY}>
        {defaultColumnRender({ label, key })}
      </When>
    </>
  );
}

function TableColumn({
  columns,
  renderHeaders,
  handleSelection,
  allSelected,
  isAllDisabled,
  enableSelectAllV1,
  renderSelectAllBody,
  selectedAllDropdownItem,
  handleSelectedAllDropdownItem,
  dataInSync,
  onSelectAll,
  enableSingleSelection,
  renderExpandedDiv,
}) {
  return (
    <thead>
      <tr>
        {!!columns?.length &&
          columns
            .filter(({ visible = true }) => visible)
            .map(({ label, key, align, width, ...rest }, colIndex) => (
              <th
                style={{
                  width: width
                    ? width + "%"
                    : rest.widthInPX
                    ? rest.widthInPX
                    : "",
                  textAlign: align ? align : "",
                }}
                key={`table_column_${label}`}
              >
                {key === SELECTION_COLUMN_KEY ? (
                  enableSelectAllV1 ? (
                    <SelectAllPopOver
                      handleSelection={handleSelection}
                      allSelected={allSelected}
                      isAllDisabled={isAllDisabled}
                      renderSelectAllBody={renderSelectAllBody}
                      selectedAllDropdownItem={selectedAllDropdownItem}
                      handleSelectedAllDropdownItem={
                        handleSelectedAllDropdownItem
                      }
                      dataInSync={dataInSync}
                      onSelectAll={onSelectAll}
                      {...rest}
                    />
                  ) : (
                    defaultSelectionHeaderRender({
                      label,
                      key,
                      handleSelection,
                      defaultRender: defaultColumnRender,
                      allSelected,
                      isAllDisabled,
                      enableSingleSelection,
                      ...rest,
                    })
                  )
                ) : !!renderExpandedDiv ? (
                  defaultExpandableHeaderRender({
                    label,
                    key,
                    defaultRender: defaultColumnRender,
                    ...rest,
                  })
                ) : (
                  renderHeaders({
                    label,
                    key,
                    defaultRender: defaultColumnRender,
                    ...rest,
                  })
                )}
              </th>
            ))}
      </tr>
    </thead>
  );
}
TableColumn.defaultProps = {
  renderHeaders: defaultColumnRender,
};

export default TableColumn;
