import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import { TOGGLE_STATUS } from "../service/types";
import { loadingStart, loadingStop } from "./loader";

export const updateCategorisation = (data, page_name) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.categorisation.saveCategorisationToggleData(page_name),
      data,
    })
      .then((response) => {
        resolve(response?.data?.result);
        dispatch({
          type: TOGGLE_STATUS,
          payload: response?.data?.result || {},
        });
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const getCatgorisation =
  (page_name, orgId, callback, avoidLoader = false) =>
  (dispatch) => {
    if (!avoidLoader) {
      dispatch(loadingStart());
    }
    return new Promise((resolve, reject) => {
      apiClient({
        method: "GET",
        url: PATH.categorisation.getCategorisationToggleData(page_name, orgId),
      })
        .then((response) => {
          resolve(response?.data?.result);
          dispatch({
            type: TOGGLE_STATUS,
            payload: response?.data?.result || {},
          });
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          if (callback) {
            callback();
          }
          dispatch(loadingStop());
        });
    });
  };
