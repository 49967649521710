import React, { useEffect, useState } from "react";
import {
  ActionLink,
  Box,
  CustomButton,
  ItemList,
  Typography,
  When,
} from "../../common";
import { ArrowBackIcon } from "../../../assets/icons";
import {
  INVOICE_INFO_VALUES,
  INVOICE_MATCHING_TABLE_COLUMNS_PO_NUMBER_KEY_LABEL,
  key_net_amount,
} from "../../../constants/InvoiceMatching";
import {
  ACCENTS,
  BUTTON_VARIANT,
  CURRENCIES,
  IN_LINE_ITEM_SPLIT_VIEW_COLUMN,
} from "../../../constants";
import "./../styles.scss";
import {
  convertToGBP,
  getCurrenciesFromLS,
  moneyFormatter,
} from "../../../utils";
import { getCustomExpenseValuesForInvoice } from "../../../api";

const DisplayInfo = ({ label, value, poExists, goToPOManagement }) => (
  <Box
    direction="column"
    justifyContent="flexStart"
    alignContent="flexStart"
    alignItem="flexStart"
    className={"m-t-20px w-100"}
  >
    <Typography
      text={label}
      fontSize={14}
      fontStyle={"semibold"}
      variant={"tertiary"}
      className="invoice-info-subtitle m-b-0px"
    />
    {!!value &&
    label === INVOICE_MATCHING_TABLE_COLUMNS_PO_NUMBER_KEY_LABEL &&
    poExists &&
    value?.length ? (
      value?.map((item) => (
        <ActionLink onClick={goToPOManagement} text={<div>{item}</div>} />
      ))
    ) : (
      <Typography
        text={Array.isArray(value) ? "N/A" : value || "N/A"}
        fontSize={14}
        variant={"body"}
      />
    )}
  </Box>
);

function InvoiceInfo(props) {
  const {
    selectedInvoice,
    resetSelectedInvoice,
    showLineItems = false,
    goToPOManagement,
  } = props;

  const [customFormFieldDetails, setCustomFormFieldDetails] = useState([]);

  const goToPOManagementFromInfo = () => {
    goToPOManagement(selectedInvoice);
  };

  useEffect(() => {
    getCustomExpenseValuesForInvoice({ id: selectedInvoice?.invoice_id }).then(
      (res) => {
        setCustomFormFieldDetails(res?.custom_form_field_details);
      }
    );
  }, []);

  const lineItems = selectedInvoice?.line_items?.map((item) => ({
    name: item.item,
    cost: item.unit_price,
    quantity: item.quantity,
    amount: item.total_amount,
    id: item.id,
  }));

  return (
    <>
      <Box justifyContent={"flexStart"} className={"pt-2"}>
        <div>
          <CustomButton
            icon={<ArrowBackIcon />}
            accent={ACCENTS.BLACK}
            variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
            className={"back-icon-button"}
            onClick={resetSelectedInvoice}
          />
        </div>
        <div>
          <Typography
            fontSize={24}
            responsiveFontSize={false}
            variant={"title"}
            text={"Invoice"}
          />
        </div>
      </Box>

      <Box
        className={`p-v-8px flex-grow-1 w-100 overflow-auto invoice-info-box ${
          showLineItems ? "" : "p-h-24px"
        }`}
        direction="column"
        justifyContent="flexStart"
        alignItem="flexStart"
        wrap={"nowrap"}
      >
        <When condition={showLineItems}>
          <Typography
            text={`Invoice net amount: ${moneyFormatter(
              selectedInvoice.net_amount,
              2,
              selectedInvoice?.currency?.currency
            )} ${
              selectedInvoice?.currency?.currency !== CURRENCIES.GBP
                ? `(${moneyFormatter(
                    convertToGBP(
                      selectedInvoice["net_amount"],
                      getCurrenciesFromLS(),
                      selectedInvoice?.currency?.currency
                    )
                  )})`
                : ""
            }`}
            fontSize={16}
            fontStyle={"semibold"}
            variant={"secondary"}
            className="invoice-info-subtitle m-b-0px"
          />
          <When condition={lineItems?.length}>
            <ItemList
              data={lineItems}
              isEditView={true}
              viewLineItems={true}
              currencyValue={selectedInvoice.currency.symbol}
              itemTableContainerClass={"item-table-container"}
              tableClassName={"p-0px"}
              showForm={false}
              showHeader={false}
              columns={IN_LINE_ITEM_SPLIT_VIEW_COLUMN}
            />
          </When>
        </When>
        {INVOICE_INFO_VALUES.map((pair) =>
          ["amount", key_net_amount].includes(pair.key) && showLineItems ? (
            <></>
          ) : (
            <DisplayInfo
              label={pair.label}
              value={
                pair.key === key_net_amount
                  ? moneyFormatter(
                      selectedInvoice[pair.key],
                      2,
                      selectedInvoice?.currency?.currency ||
                        selectedInvoice?.currency
                    )
                  : selectedInvoice[pair.key]
              }
              goToPOManagement={goToPOManagementFromInfo}
              poExists={selectedInvoice?.purchase_order_exists}
            />
          )
        )}
        {!!customFormFieldDetails?.length &&
          customFormFieldDetails.map((field) => (
            <DisplayInfo label={field.custom_form_field} value={field.value} />
          ))}
      </Box>
    </>
  );
}

export default InvoiceInfo;
