import React from "react";
import { CustomButton, Modal, PreviewApprovalPath, Typography } from "..";
import { ASSIGN_WORKFLOW, constructClassName } from "../../../utils";
import "./assign-workflow-modal.scss";
import { ACCENTS, BUTTON_VARIANT } from "../../../constants";

const AssignWorkFlowModal = ({
  open,
  onCancel,
  confirm,
  recordsCount,
  workflowSteps,
  workflowName,
  module,
  customTitle,
  customModalDisclaimer,
}) => {
  return (
    <>
      <Modal
        isOpen={open}
        width={778}
        title={customTitle || ASSIGN_WORKFLOW.TITLE}
        hide={onCancel}
        maskClosable={false}
      >
        <div className="assign-workflow-modal">
          <Typography
            variant={"body"}
            fontSize={16}
            className={constructClassName(["px-3", "pt-3", "mb-0"])}
            text={
              customModalDisclaimer ||
              ASSIGN_WORKFLOW.MODAL_DISCLAIMER(
                recordsCount,
                workflowName,
                module
              )
            }
          />
          <div
            className={constructClassName([
              "mt-3",
              "mb-4",
              "approval-path-border",
            ])}
          >
            <Typography
              variant={"body"}
              fontSize={16}
              className="px-3 pt-3 mb-0"
              text={ASSIGN_WORKFLOW.MODAL_RESEARCH_WORKFLOW(workflowName)}
            />
            <PreviewApprovalPath
              className={"preview-workflow"}
              workflowData={workflowSteps}
              showName={false}
            />
          </div>
          <div className="assign-workflow-modal-btns">
            <CustomButton
              text={"Confirm"}
              loaderText="Confirming..."
              showLoader
              accent={ACCENTS.PRIMARY}
              variant={BUTTON_VARIANT.BUTTON_FILLED}
              onClick={confirm}
              className="confirm-button"
              customSize={"large"}
            />
            <CustomButton
              text={"Cancel"}
              accent={ACCENTS.SECONDARY}
              variant={BUTTON_VARIANT.BUTTON_HOLLOW}
              onClick={onCancel}
              className="cancel-button"
              customSize={"large"}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AssignWorkFlowModal;
