import React, { useEffect, useState } from "react";
import "./notificationsStyle.scss";
// import NotificationList from './notificationsList';
import { Alert } from "antd";
import { socketResponse } from "../socketIO";
import { CustomAlert } from "../common";

const Notifications = ({ windowSize, isLeftSidebar, bannerNotifications }) => {
  const [message, setMessage] = useState();
  const [bannerHeight, setBannerHeight] = useState(null);
  var bannerHVar = document.getElementById?.(
    "notification-alert-banner-section"
  )?.offsetHeight;
  useEffect(() => {
    if (bannerNotifications) {
      localStorage.setItem(
        "bannerNotification",
        JSON.stringify(bannerNotifications)
      );
      setMessage(bannerNotifications);
      setTimeout(() => {
        var bannerH = document.getElementById?.(
          "notification-alert-banner-section"
        )?.offsetHeight;
        // var siderbarWidth = document.getElementById("kloo-sidebar")?.offsetWidth;
        setStyles(bannerH, bannerNotifications);
      }, 2000);
    }
  }, [bannerNotifications]);
  const setStyles = (bannerH, bannerNotifications) => {
    if (
      document.getElementById("menu-wrapper") &&
      bannerH &&
      (message || bannerNotifications)
    ) {
      var siderbarWidth = document.getElementById("kloo-sidebar")?.offsetWidth;
      document.getElementById(
        "notification-alert-banner-section"
      ).style.marginLeft = -siderbarWidth + "px";
      const top = document.getElementById("menu-wrapper").style.marginTop;
      document.getElementById("menu-wrapper").style.marginTop = bannerH + "px";
      setBannerHeight(top);
      localStorage.setItem(
        "bannerNotification",
        JSON.stringify(message || bannerNotifications)
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      var bannerH = document.getElementById?.(
        "notification-alert-banner-section"
      )?.offsetHeight;
      setStyles(bannerH);
    }, 1000);
  }, [message, windowSize, isLeftSidebar]);
  useEffect(() => {
    const mySocketSub = socketResponse.subscribe((data) => {
      const notiicationMessage = { ...data, show: true };
      let perviousNotification = localStorage.getItem("bannerNotification");
      perviousNotification = perviousNotification
        ? JSON.parse(perviousNotification)
        : null;
      const updatedMessage = message || perviousNotification;
      setMessage(updatedMessage);
      if (data && !message) {
        setMessage(notiicationMessage);
      } else if (
        data &&
        message?.result?.message !== data?.result?.message &&
        message?.result?.id !== data?.result?.id
      ) {
        setMessage(notiicationMessage);
      }
    });
  });
  const onClose = () => {
    const notiicationMessage = { ...message, show: false };
    setMessage(notiicationMessage);
    localStorage.setItem(
      "bannerNotification",
      JSON.stringify(notiicationMessage)
    );
    document.getElementById("menu-wrapper").style.marginTop = 0 + "px";
  };
  return message?.show ? (
    <>
      <div
        className="notification-alert-banner-section"
        id="notification-alert-banner-section"
      >
        <CustomAlert
          message={
            <div
              dangerouslySetInnerHTML={{ __html: message?.result?.message }}
            ></div>
          }
          type="warning"
          closable
          onClose={onClose}
          className="banner-notification"
        />
      </div>
    </>
  ) : null;
};

export default Notifications;
