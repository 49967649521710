import { Form } from "antd";
import React, { useEffect } from "react";
import { Box, CustomButton, Divider, Drawer, When } from "../../../common";
import SuppliersAccountForm from "../SuppliersAccountForm";
import ViewSupplierDetails from "../ViewSupplierDetails";
import { addSupplierAccount, updateSupplierAccount } from "../../../../api";
import {
  getArrayLength,
  NOTIFICATION_TYPE,
  showNotification,
} from "../../../../utils";
import {
  ACCENTS,
  ADD_NEW_SUPPLIER_ACCOUNT_FORM,
  BUTTON_VARIANT,
  INVOICE_MANAGEMENT_TAB_KEYS,
  NAVIGATION_URL,
  SUPPLIER_ACCOUNT_SETS,
} from "../../../../constants";
import { useNavigate } from "react-router-dom";

const AddSupplierAccounts = ({
  open,
  onClose,
  reloadTable,
  selectedSupplier,
  prDetails,
  invoiceDetails,
  selectedAccount,
  setAccountsDetails,
  countries: countriesList,
  currencies,
  updateAccounts = false,
  addMoreAccount = false,
  scrollToTop,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  let defaultFormValue = {
    remember: true,
  };

  const {
    TITLE,
    saveButton,
    saveAndReturnToScheduleButton,
    saveAndReturnToPaymentRunsButtons,
    cancelButton,
    successMessage,
    updateMessage,
  } = ADD_NEW_SUPPLIER_ACCOUNT_FORM;

  useEffect(() => {
    if (selectedSupplier) {
      form.setFieldsValue({
        account_details: [{}],
      });
    }
    if (selectedAccount) {
      form.setFieldsValue({
        account_details: [selectedAccount],
      });
    }
  }, []);

  function addAccountHandler({ requestPayload, data }) {
    if (getArrayLength(data?.account_details) > 0) {
      return addSupplierAccount(requestPayload);
    } else {
      return true;
    }
  }

  function addressPayload(data) {
    return {
      addressline1: data?.addressline1 ?? "",
      addressline2: data?.addressline2 ?? "",
      city: data?.city ?? "",
      postcode: data?.postcode ?? "",
      country: data?.country ?? "",
    };
  }

  function goToPaymentRunsScheduleModal(res) {
    navigate(NAVIGATION_URL.PAYMENT_RUNS, {
      state: {
        prDetails: prDetails,
        selectedSupplier: res,
        schedule: true,
      },
    });
  }

  function goToInvoiceManagement(res) {
    navigate(NAVIGATION_URL.INVOICE_MANAGEMENT, {
      state: {
        persistFilters: true,
        invoiceDetails: { invoiceDetails, selectedSupplier: res },
        tab: INVOICE_MANAGEMENT_TAB_KEYS.TO_PAY,
      },
    });
  }

  function goToPaymentRunsDownloadModal(res) {
    navigate(NAVIGATION_URL.PAYMENT_RUNS, {
      state: {
        prDetails,
        selectedSupplier: res,
        download: true,
      },
    });
  }

  async function addAccountToExistingSupplier() {
    try {
      const data = await form.validateFields();
      const requestPayload = {
        supplier_id: selectedSupplier?.id,
        account_details: data?.account_details?.map((item) => {
          return {
            account_type: SUPPLIER_ACCOUNT_SETS.UKAccount,
            account_name: item?.account_name ?? "",
            account_alias: item?.account_alias ?? "",
            acc_number: item?.acc_number ?? "",
            sort_code: item?.sort_code ?? "",
            currency: item?.currency ?? "",
            bank_address: item?.addressline1
              ? {
                  bank_name: item?.bank_name,
                  ...addressPayload(item),
                }
              : [],
          };
        }),
      };
      await addAccountHandler({ requestPayload, data }).then((res) => {
        reloadTable();
        showNotification(NOTIFICATION_TYPE.success, successMessage);
        onClose();
        form.resetFields();
        if (prDetails?.schedule) {
          goToPaymentRunsScheduleModal(res);
        }
        if (prDetails?.download) {
          goToPaymentRunsDownloadModal(res);
        }
        if (invoiceDetails) {
          goToInvoiceManagement(res);
        }
        if (addMoreAccount) {
          setAccountsDetails(res?.supplier_accounts);
          scrollToTop();
        }
      });
    } catch (err) {
      if (err?.response?.data?.message) {
        showNotification(NOTIFICATION_TYPE.error, err?.response?.data?.message);
      }
    }
  }

  async function updateAccount() {
    try {
      const data = await form.validateFields();
      const requestPayload = {
        supplier_id: selectedSupplier?.id,
        account_details: data?.account_details?.map((item, i) => {
          return {
            id: selectedAccount?.id,
            account_type: SUPPLIER_ACCOUNT_SETS.UKAccount,
            account_name: item?.account_name ?? "",
            account_alias: item?.account_alias ?? "",
            acc_number: item?.acc_number ?? "",
            sort_code: item?.sort_code ?? "",
            currency: item?.currency ?? "",
            bank_address: item?.addressline1
              ? {
                  bank_name: item?.bank_name,
                  ...addressPayload(item),
                }
              : [],
          };
        }),
      };
      await updateSupplierAccount(requestPayload).then((res) => {
        setAccountsDetails(res?.supplier_accounts);
        reloadTable();
        showNotification(NOTIFICATION_TYPE.success, updateMessage);
        onClose();
        form.resetFields();
      });
    } catch (err) {
      if (err?.response?.data?.message) {
        showNotification(NOTIFICATION_TYPE.error, err?.response?.data?.message);
      }
    }
  }

  function addButtonLabelHandler() {
    if (prDetails?.download) {
      return saveAndReturnToPaymentRunsButtons.label;
    } else if (prDetails?.schedule || invoiceDetails) {
      return saveAndReturnToScheduleButton.label;
    } else {
      return saveButton.label;
    }
  }

  return (
    <Drawer
      className="supplier-management-drawer"
      open={open}
      onClose={onClose}
      title={selectedAccount ? TITLE.edit : TITLE.add}
    >
      <Box className="new-supplier-form-container">
        <Form
          form={form}
          name="create_supplier"
          initialValues={defaultFormValue}
          layout="vertical"
          autoComplete="off"
          className="w-100"
          scrollToFirstError
        >
          <When condition={selectedSupplier}>
            <ViewSupplierDetails supplierData={selectedSupplier} />
            <Divider className={"m-t-20px m-b-12px"} />
          </When>

          <SuppliersAccountForm
            countriesList={countriesList}
            currencies={currencies}
            clearIcon={false}
          />

          <Box direction={"row"} wrap="nowrap">
            <CustomButton
              text={addButtonLabelHandler()}
              accent={ACCENTS.PRIMARY}
              variant={BUTTON_VARIANT.BUTTON_FILLED}
              htmlType="submit"
              onClick={
                updateAccounts ? updateAccount : addAccountToExistingSupplier
              }
              showLoader={true}
              className={prDetails || invoiceDetails ? "w-60" : "w-50"}
              customSize={"large"}
            />
            <CustomButton
              text={cancelButton.label}
              accent={ACCENTS.SECONDARY}
              variant={BUTTON_VARIANT.BUTTON_HOLLOW}
              onClick={onClose}
              className={"w-50"}
              customSize={"large"}
            />
          </Box>
        </Form>
      </Box>
    </Drawer>
  );
};

export default AddSupplierAccounts;
