import { featureFlags } from "../../constants";
import {
  isDevelopmentEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
  moneyFormatter,
} from "./common";

const findOptionValue = (inputId, value, customFields) => {
  const field = customFields.find((field) => field.inputId === inputId);
  if (field && field?.options?.length > 0) {
    const option = field.options.find((option) => option.value === value);

    return option ? option.label : "";
  }
  return value;
};

export const transFormDataLineItemsPreview = (
  data,
  customFields,
  columnWidth,
  columns = []
) => {
  const result = {
    transformedItem: [],
    columnsArray: [],
  };

  result.columnsArray = columns;

  data.forEach((item) => {
    const transformedItem = {
      name: item.name || item.item,
      cost: item.cost || item.price,
      quantity: item.quantity,
      amount: item.amount,
      innerCustomFields: item.innerCustomFields,
    };

    item?.custom_expense_details?.forEach((field) => {
      const customField = customFields.find(
        (cf) => cf.inputId === field.custom_field_id
      );
      const label =
        customField?.inputLabel ??
        field.field_validation_configurations?.field_name;
      const key = label.replaceAll(" ", "_");

      transformedItem[key] = field?.field_values
        ? field?.field_values?.name
        : field?.value || "N/A";

      const existingColumn = result.columnsArray.find(
        (column) => column.key === key
      );
      if (!existingColumn) {
        result.columnsArray.push({ label, key, width: columnWidth || "" });
      }
    });

    result.transformedItem.push(transformedItem);
  });

  return result;
};

export const transFormDataLineItemsPreviewV2 = (
  data,
  customFields,
  columnWidth,
  columns = [],
  currencyData
) => {
  const result = {
    transformedItem: [],
    columnsArray: [],
  };

  result.columnsArray = columns;
  data.forEach((item) => {
    const transformedItem = {
      name: item.item,
      cost: moneyFormatter(item.unit_price, 2, currencyData?.currency),
      quantity: item.quantity,
      amount: item.amount,
      net_amount: moneyFormatter(
        Number(Number(item.unit_price) * Number(item.quantity)).toFixed(2),
        2,
        currencyData?.currency
      ),
      innerCustomFields: item?.innerCustomFields,
      item,
    };
    if (item?.custom_fields) {
      item?.custom_fields.forEach((field) => {
        const label = field?.label;
        const key = label.replaceAll(" ", "_");
        transformedItem[key] = field?.value || "N/A";

        const existingColumn = result.columnsArray.find(
          (column) => column.key === key
        );
        if (!existingColumn) {
          result.columnsArray.push({ label, key, width: columnWidth || "" });
        }
      });
    }
    result.transformedItem.push(transformedItem);
  });

  return result;
};

export const transFormDataLineItems = (
  data,
  customFields,
  columnWidth,
  columns = []
) => {
  const result = {
    transformedItem: [],
    columnsArray: [],
  };

  data.forEach((item) => {
    const transformedItem = {
      name: item.name || item.item,
      cost: item.cost || item.price,
      quantity: item.quantity,
      amount: item.amount,
      innerCustomFields: item.innerCustomFields,
    };

    const processFields = (fields) => {
      fields.forEach((field) => {
        const fieldId = field.custom_field_id;
        const customField = customFields.find((cf) => cf.inputId === fieldId);
        const label =
          customField?.inputLabel ??
          field.field_validation_configurations?.field_name;
        const key = label.replaceAll(" ", "_");
        transformedItem[key] = field?.field_values
          ? field.field_values?.name
          : findOptionValue(fieldId, field.value, customFields) || "N/A";
        result.columnsArray.push({ label, key, width: columnWidth || "" });
      });
    };

    processFields(item.innerCustomFields || item.custom_expense_details || []);

    const array = [...columns, ...result.columnsArray];
    const uniqueColumnArray = array.reduce((accumulator, current) => {
      if (!accumulator.some((item) => item.key === current.key)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    result.transformedItem.push(transformedItem);
    result.columnsArray = uniqueColumnArray;
  });

  return result;
};

export const isGRNISectionVisible = ({
  userTypeDetails,
  poDetails,
  userOrgId,
}) => {
  const { isPlatformUser } = userTypeDetails;
  return (
    !isPlatformUser || (isPlatformUser && poDetails?.owner_id === userOrgId)
  );
};

export const isPOEditButtonVisible = (poDetails) => {
  return poDetails.is_loggedin_user_allow_to_update || false;
};

export const isPOAuditLogVisible = (data) => {
  return isGRNISectionVisible(data);
};

export const isMatchedInvoiceSectionVisible = ({ userTypeDetails }) => {
  const { isPlatformUser } = userTypeDetails;
  return !isPlatformUser;
};
