import React, { memo } from "react";
import { Space as ASpace } from "antd";
import "./space.scss";
import { constructClassName } from "../../../utils";

function Space({ className, children, ...rest }) {
  return (
    <ASpace
      className={constructClassName(["custom-space", className])}
      {...rest}
    >
      {children}
    </ASpace>
  );
}

export default memo(Space);
