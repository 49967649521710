import { useCallback, useEffect, useState } from "react";
import { getAllWorkFlowList } from "../redux/action/accountPayable";
import { getWorkFlowV1 } from "../utils";
import useApproverDropdown from "./useApproverDropdown";

const useWorkflow = ({
  refreshWorkFlowData,
  roles,
  attributes,
  workflowType,
}) => {
  const [workFlowData, setWorkFlowData] = useState([]);
  const approverList = useApproverDropdown();
  const workFlowDataLength = workFlowData?.length;

  const getWorkflowList = useCallback(() => {
    getAllWorkFlowList(workflowType).then((res) => {
      setWorkFlowData(getWorkFlowV1(res, approverList[0], roles, attributes));
    });
  }, [approverList, roles, attributes, workflowType]);

  useEffect(() => {
    approverList &&
      approverList?.length &&
      approverList?.[0]?.length &&
      !workFlowDataLength &&
      getWorkflowList();
  }, [refreshWorkFlowData, approverList, workFlowDataLength, getWorkflowList]);

  return {
    workFlowData,
  };
};

export default useWorkflow;
