import { useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  When,
  CustomButton,
  Column,
  Row,
  WorkFlowStep,
  Image,
  Tooltip,
  Icon,
} from "../../common";
import {
  GLOBAL_DOUBLE_HYPHEN_SYMBOL,
  LOCAL_STORAGE_KEY,
  MODAL_TYPES,
  constructClassName,
  getItem,
  isPlatformEnvironment,
  transFormDataLineItemsPreviewV2,
} from "../../../utils";
import useCustomField from "../../../hooks/useCustomField";
import {
  PO_ITEM_DETAIL_TABLE,
  PO_ITEM_DETAIL_TABLE_ITEM_NAME,
  CATEGORISATION_CONSTANTS,
  CATEGORY_TYPES,
  SUPPLIER_PLACEHOLDER,
  PO_ACTIONS,
  PURCHASE_ORDER_CREATE_FROM,
  ACCENTS,
  BUTTON_VARIANT,
  PURCHASE_ORDER_VIEW_ITEMS,
  VISIBILITY_CONFIG,
  ERROR_MSG_CUSTOM_FIELD,
  WORK_FLOW_TYPES,
  PURCHASE_ORDER_TABS_KEYS,
  TOOL_TIP_TEXT_PO,
} from "../../../constants";
import "./purchase-order-pdf-view.scss";
import GRNIListContainer from "../GRNIListContainer";
import MatchedInvoicesListContainer from "../MatchedInvoicesListContainer";
import { useState } from "react";
import PuchaseOrderAttachments from "../PuchaseOrderAttachments";
import { isFeat11894Enabled, versionForNewCustomField } from "../../../helper";
import {
  getCustomFieldsByPoId,
  getTaxRateList,
  getTaxRatesByEntity,
} from "../../../api";
import { useToggle } from "../../../hooks";
import useERPFields from "../../../hooks/ERPFields";
import { useSelector } from "react-redux";
import {
  LINE_ITEM_TABLE_COLUMNS,
  LINE_ITEM_TABLE_COLUMNS_KEY,
} from "../../../constants/LineItems";
import {
  ERP_INTEGRATIONS_PAGE_CONFIG,
  ERP_LINE_ITEM_ORGS,
} from "../../../constants/Integration/OpenBanking";

export function PurchaseOrderPDFView(propsData) {
  const props = { ...propsData };
  const {
    reloadTable,
    enableGRNIFeature = false,
    enableMatchedInvoiceFeature = false,
    setOnDeleteReload,
    showModal,
    hideModal,
    version,
    defaultEntityList,
  } = props;
  const [transformedData, setTransformedData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [showLineItems, setShowLineItems] = useState(false);
  const { fields } = useCustomField({
    customFields: props?.custom_expense_details,
    categoryType: CATEGORY_TYPES.CUSTOM,
    pageName: CATEGORISATION_CONSTANTS.PURCHASE_ORDER,
    integrationType: "po",
  });
  const { FORM_LABELS } = PURCHASE_ORDER_CREATE_FROM;
  const [newDefaultFieldsLabel, setNewDefaultFieldslabel] = useState([]);
  const [showPoAvailableText, showPoAvailableTextToggle] = useState(false);
  const [taxRate, setTaxRate] = useState([]);
  const accountDetail =
    useSelector(
      (state) =>
        state &&
        state?.modular &&
        state?.modular?.accountDetail &&
        state?.modular?.accountDetail[0]
    ) || {};

  const isErpIntegrationEnabled =
    props.entityEnabled && (accountDetail?.is_erp_integration || 0);

  const {
    ChartOfAccounts,
    TaxRate,
    TrackingCategories,
    chartsOfAccountList,
    trackingCategoriesList,
    itemList,
    connectedErpPlatformName,
  } = useERPFields({
    entityId: props?.entity_id,
    isAllTaxRate: true,
  });

  useEffect(() => {
    setShowLineItems(false);
    getLineItemsCustomFileds();
    if (versionForNewCustomField(version) && props?.custom_fields?.length) {
      setNewDefaultFieldslabel(props?.custom_fields);
    } else {
      setNewDefaultFieldslabel([]);
    }
  }, [propsData]);

  const getLineItemsCustomFileds = async () => {
    let lineItemIds = [];
    if (props.bill_of_materials.length) {
      props.bill_of_materials.forEach((item) => {
        lineItemIds.push(item.id);
      });
      const lineItemIdsString = lineItemIds.join(",");
      try {
        const res = await getCustomFieldsByPoId(props.id, lineItemIdsString);
        props.custom_expense_details = res.default || [];
        props.line_items = res.line_items || [];
        props.line_item_data = res.line_item_data;
      } catch (e) {
        if (e?.response?.data?.message.includes(ERROR_MSG_CUSTOM_FIELD)) {
          props.custom_expense_details = [];
          props.line_items = [];
          props.line_item_data = [];
        }
      }
      addCustomFieldsToLineItemsPreview();
    }
  };

  const addCustomFieldsToLineItemsPreview = () => {
    props.bill_of_materials.forEach((item) => {
      const getItemData = props?.line_item_data?.find(
        (itemData) => itemData.id === item.id
      );
      const innerCustomFields = [];
      if (getItemData?.inputs) {
        getItemData.inputs.forEach((field) => {
          const getCustomField = props.line_items.find(
            (item) =>
              item.custom_fields_has_form_option_id ===
              field.custom_fields_has_form_option_id
          );
          getCustomField.value = field.user_input;
          if (getCustomField.attribute_type !== "textbox") {
            getCustomField.valueAsText = getCustomField.values.find(
              (option) => option.value === field.user_input
            )?.label;
          }
          innerCustomFields.push({
            custom_field_id: getCustomField.custom_field_id,
            value:
              getCustomField.attribute_type === "textbox"
                ? getCustomField.value
                : getCustomField.valueAsText,
            id: getCustomField.id,
            user_input: getCustomField.user_input,
            label: getCustomField.label,
          });
        });
      }
      item.innerCustomFields = innerCustomFields;
      item.custom_fields = innerCustomFields;
    });
    getTransFormDataLineItemsPreview();
  };

  const getTransFormDataLineItemsPreview = () => {
    const { transformedItem, columnsArray } = transFormDataLineItemsPreviewV2(
      props.bill_of_materials,
      fields,
      20,
      [],
      props?.currencyData
    );
    setTransformedData(transformedItem);
    setColumns(columnsArray);
    setShowLineItems(true);
  };

  const ShowDefaultFieldValueComponent = ({ fieldsData }) => {
    return (
      <>
        {fieldsData.map(({ label, value }) => (
          <Box
            direction="column"
            alignContent="flexStart"
            alignItem="flexStart"
          >
            <Typography
              fontStyle="semibold"
              className="m-b-0px"
              variant="secondary"
              text={label}
            />
            <Typography
              variant="secondary"
              className="m-b-15px text-break"
              text={value}
            />
          </Box>
        ))}
      </>
    );
  };

  useEffect(() => {
    // Check if chartsOfAccountList has items
    if (chartsOfAccountList.length > 0) {
      // If tax_solution_label is present in props
      if (props?.tax_solution_label) {
        // Fetch tax rate list based on tax_solution_label
        getTaxRateList(props?.entity_id, null, null, props?.tax_solution_label)
          .then(setTaxRate)
          .catch((error) => {
            console.error(
              "Error fetching tax rate list with tax_solution_label:",
              error
            );
          });
      } else {
        // Find all classifications from bill_of_materials where erp_account matches chartsOfAccountList erp_id
        const classifications = props?.bill_of_materials?.reduce(
          (acc, item) => {
            const account = chartsOfAccountList.find(
              (acc) => item.erp_account === acc?.erp_id
            );
            if (account) {
              acc.push(account.classification);
            }
            return acc;
          },
          []
        );

        // If there are classifications to fetch tax rates for
        if (classifications.length > 0) {
          // Fetch tax rates for each classification
          const taxRatePromises = classifications.map((classification) =>
            getTaxRateList(props?.entity_id, classification)
          );

          // Wait for all tax rate fetches to complete
          Promise.all(taxRatePromises)
            .then((taxRates) => {
              // Combine all tax rates into a single array
              const combinedTaxRates = taxRates.flat();
              setTaxRate(combinedTaxRates);
            })
            .catch((error) => {
              console.error("Error fetching tax rates:", error);
            });
        }
      }
    }
  }, [chartsOfAccountList, props?.erp_account]);

  const renderBody = ({ label, key, subKey, record, index, defaultRender }) => {
    switch (key) {
      case LINE_ITEM_TABLE_COLUMNS.account.key:
        return (
          chartsOfAccountList?.find(
            (data) => data?.erp_id === record?.item?.erp_account
          )?.label || null
        );
      case LINE_ITEM_TABLE_COLUMNS_KEY.TRACKING_CATEGORIES:
        const value = record?.item?.erp_tracking_categories?.find(
          (category) => category.type === subKey
        )?.value;
        return trackingCategoriesList?.[subKey]?.find(
          (category) => category.value === value
        )?.label;
      case LINE_ITEM_TABLE_COLUMNS.tax_rate.key:
        return taxRate?.find(
          (rate) => rate?.TaxType === record?.item?.erp_tax_rate
        )?.description;
      case LINE_ITEM_TABLE_COLUMNS.item_list.key:
        return itemList.find(
          (item) =>
            item.erp_id ===
            record?.item?.[LINE_ITEM_TABLE_COLUMNS_KEY.ITEM_LIST]
        )?.label;
      default:
        return defaultRender({ label, key, record, index, defaultRender });
    }
  };

  const getERPColumns = () => {
    let column = [];
    if (connectedErpPlatformName) {
      column = [
        {
          ...LINE_ITEM_TABLE_COLUMNS.account,
          widthInPX: 150,
          customClass: "dyanmic-erp-column",
        },
      ];
      for (const key in trackingCategoriesList) {
        if (Object.hasOwnProperty.call(trackingCategoriesList, key)) {
          column.push({
            label: key,
            key: LINE_ITEM_TABLE_COLUMNS_KEY.TRACKING_CATEGORIES,
            subKey: key,
            placeholder: key,
            widthInPX: 150,
            customClass: "dyanmic-erp-column",
          });
        }
      }
      column.push({
        ...LINE_ITEM_TABLE_COLUMNS.tax_rate,
        widthInPX: 150,
        customClass: "dyanmic-erp-column",
      });

      if (
        (connectedErpPlatformName !==
          ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.xero.key &&
          ERP_LINE_ITEM_ORGS.includes(getItem(LOCAL_STORAGE_KEY.ORD_ID))) ||
        !isPlatformEnvironment()
      ) {
        column.push({
          ...LINE_ITEM_TABLE_COLUMNS.item_list,
          widthInPX: 150,
          customClass: "dyanmic-erp-column",
        });
      }
    }
    return column;
  };

  const finalColumns = [
    ...PO_ITEM_DETAIL_TABLE_ITEM_NAME,
    ...getERPColumns(),
    ...columns,
    ...PO_ITEM_DETAIL_TABLE,
  ];

  const allowScroll = finalColumns.length > 4;

  const showItemListModal = () => {
    showModal(MODAL_TYPES.ITEM_LIST_MODAL, {
      title: PURCHASE_ORDER_CREATE_FROM.itemSwitch.label,
      transformedData: transformedData,
      billOfMaterials: props.bill_of_materials,
      renderBody: renderBody,
      finalColumns: finalColumns,
      onCancel: hideModal,
    });
  };

  const getFieldsData = () => {
    let data = newDefaultFieldsLabel.filter((data) => data?.value);
    let checkConditionalDataExist = data.filter(
      (item) =>
        item.custom_field_visibility === VISIBILITY_CONFIG.default &&
        item?.conditional_fields &&
        item?.conditional_fields?.length
    );

    if (checkConditionalDataExist?.length) {
      return data
        .filter(
          (item) => item.custom_field_visibility === VISIBILITY_CONFIG.default
        )
        .reduce((acc, parentItem) => {
          acc.push(parentItem);
          if (parentItem?.conditional_fields?.length) {
            parentItem?.conditional_fields.forEach((conditionItem) => {
              acc.push(conditionItem);
            });
          }
          return acc;
        }, []);
    } else {
      return data;
    }
  };

  const getERPData = () => {
    const accountData = ChartOfAccounts({
      viewOnlyData: true,
      selectedDataId: props?.erp_account,
    });
    const taxRateData = TaxRate({
      viewOnlyData: true,
      selectedDataId: props?.erp_tax_rate,
    });
    const trackingCategories = TrackingCategories({
      viewOnlyData: true,
      erp_tracking_categories: props?.erp_tracking_categories,
    });

    const trackingCategoriesData = isErpIntegrationEnabled
      ? trackingCategories
      : [];
    const data = [
      {
        hide: !isErpIntegrationEnabled || !accountData.value,
        label: accountData.label,
        value: accountData.value,
        src: accountData.src,
      },
      ...trackingCategoriesData,
      {
        hide: !isErpIntegrationEnabled || !taxRateData.value,
        label: taxRateData.label,
        value: taxRateData.value,
        src: taxRateData.src,
      },
    ];
    return data?.filter((el) => !el?.hide);
  };

  const checkIfDefaultEntity = useCallback(
    (id) => defaultEntityList?.some((item) => item?.id === id),
    [defaultEntityList]
  );

  const entityName = checkIfDefaultEntity(props?.entity_id)
    ? GLOBAL_DOUBLE_HYPHEN_SYMBOL
    : props?.entity_name;

  const POPDFItems = useMemo(() => {
    const data = [
      {
        hide: !props.entityEnabled,
        label: FORM_LABELS.entity,
        value: entityName || GLOBAL_DOUBLE_HYPHEN_SYMBOL,
      },
      {
        hide: false,
        label: FORM_LABELS.createdDate,
        value: props.created_at,
      },
      {
        hide: false,
        label: FORM_LABELS.startDate,
        value: props.start_date || "--",
      },
      {
        hide: false,
        label: FORM_LABELS.deliveryDate,
        value: props.delivery_date,
      },
      {
        hide: false,
        label: SUPPLIER_PLACEHOLDER,
        value: props.supplier_name,
      },
      {
        hide: false,
        label: FORM_LABELS.description,
        value: props.description,
      },
      {
        hide: false,
        label: FORM_LABELS.owner,
        value: `${props.owner}` || `-`,
      },
      {
        hide: !props.taxCodeEnabled,
        label: FORM_LABELS.netAmount,
        value: props.netAmount,
        suffixIconTooltip: TOOL_TIP_TEXT_PO.netAmount,
        suffixIconClassName: "",
        suffixIcon: "info",
      },
      {
        hide: !props.taxCodeEnabled,
        label: FORM_LABELS.taxRate,
        value: props?.tax_code_id
          ? `${props?.tax_rate}%`
          : GLOBAL_DOUBLE_HYPHEN_SYMBOL,
        suffixIconTooltip: TOOL_TIP_TEXT_PO.taxRate,
        suffixIconClassName: "",
        suffixIcon: "info",
      },
      {
        hide: !props.remainingAmount,
        label: FORM_LABELS.remainingAmount,
        value: props.remainingAmount,
        suffixIconTooltip: TOOL_TIP_TEXT_PO.remainingAmount,
        suffixIconClassName: "",
        suffixIcon: "info",
      },
      {
        hide: false,
        label: FORM_LABELS.grossAmount,
        value: props.amount,
        suffixIconTooltip: TOOL_TIP_TEXT_PO.totalAmount,
        suffixIconClassName: "",
        suffixIcon: "info",
      },
      {
        hide:
          props?.status !== PO_ACTIONS.REJECT || props?.reject_reason === null,
        label: FORM_LABELS.rejectionReason,
        value: props?.reject_reason?.reason,
      },
      {
        hide:
          props?.status !== PO_ACTIONS.REJECT || props?.reject_reason !== null,
        label: FORM_LABELS.rejectionReason,
        value: PURCHASE_ORDER_CREATE_FROM.rejectionReason.placeholder,
        placeholder: true,
      },
    ];
    return data?.filter((el) => !el?.hide);
  }, [FORM_LABELS, props]);

  function renderItems({ item, alignItem = "flexStart" }) {
    return (
      <Box
        direction="column"
        alignItem={alignItem}
        alignContent="flexStart"
        rowGap={0}
      >
        <Box columnGap={0}>
          <Typography {...PURCHASE_ORDER_VIEW_ITEMS.label} text={item?.label} />
          <When condition={item?.suffixIcon && isFeat11894Enabled()}>
            <Tooltip
              title={item?.suffixIconTooltip}
              overlayClassName={constructClassName(["tooltip-inside-label"])}
              placement="right"
            >
              <Icon
                showIconOutline={true}
                iconName={item?.suffixIcon}
                className={constructClassName([
                  "ms-2",
                  item?.suffixIconClassName,
                ])}
              />
            </Tooltip>
          </When>
        </Box>
        <When condition={!item?.placeholder}>
          <Box alignContent="center">
            <When condition={item?.src}>
              <Image src={item.src} width={30} className={"m-b-15px m-t-2px"} />
            </When>
            <Typography
              {...PURCHASE_ORDER_VIEW_ITEMS.value}
              text={item?.value}
            />
          </Box>
        </When>
        <When condition={item?.placeholder}>
          <Typography
            {...PURCHASE_ORDER_VIEW_ITEMS.placeholder}
            text={item?.value}
          />
        </When>
      </Box>
    );
  }

  const PDFViewItems =
      versionForNewCustomField(version) && newDefaultFieldsLabel?.length
        ? [...POPDFItems, ...getFieldsData()]
        : [...POPDFItems],
    firstColumn = [],
    secondColumn = [],
    temp = [firstColumn, secondColumn];
  PDFViewItems.forEach((v, i) => temp[i % 2].push(v));

  return (
    <>
      <Box
        className={props.className}
        direction="column"
        alignContent="flexStart"
        alignItem="flexStart"
      >
        <Row className="w-100 text-break-all">
          <When condition={props.status === PURCHASE_ORDER_TABS_KEYS.IN_REVIEW}>
            <WorkFlowStep type={WORK_FLOW_TYPES.PO} id={props.id} />
          </When>
          <Column
            {...{
              xs: 24,
              sm: 12,
              md: 12,
              lg: 12,
            }}
          >
            <Box
              direction="column"
              alignContent="flexStart"
              alignItem="flexStart"
            >
              {firstColumn?.map((item) => {
                return renderItems({ item });
              })}
            </Box>
          </Column>
          <Column
            {...{
              xs: 24,
              sm: 12,
              md: 12,
              lg: 12,
            }}
          >
            <Box
              direction="column"
              alignContent="flexStart"
              alignItem="flexStart"
            >
              {secondColumn?.map((item) => {
                return renderItems({ item });
              })}
            </Box>
          </Column>
        </Row>
        <When condition={props?.bill_of_materials?.length && showLineItems}>
          <CustomButton
            text={PURCHASE_ORDER_CREATE_FROM.viewItemList.label}
            accent={ACCENTS.LINK}
            variant={BUTTON_VARIANT.LINK}
            onClick={showItemListModal}
          />
        </When>
        <When condition={enableGRNIFeature}>
          <GRNIListContainer
            {...props}
            showPoAvailableText={showPoAvailableText}
            enableGRNIDownload
          />
        </When>
        <When condition={enableMatchedInvoiceFeature}>
          <MatchedInvoicesListContainer
            {...props}
            showPoAvailableTextToggle={showPoAvailableTextToggle}
          />
        </When>
        <When condition={props?.attachments?.length}>
          <PuchaseOrderAttachments
            showModal={showModal}
            attachments={props?.attachments}
          />
        </When>
      </Box>
    </>
  );
}
