import React, { useEffect, useState } from "react";
import { Box, CommonInputNumber } from "..";
import "./input-range.scss";

function InputRange({ label, selected, setSelected }) {
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);

  useEffect(() => {
    if ((min || max) && !selected?.find((item) => item.includes(label))) {
      setMax(null);
      setMin(null);
    }
  }, [selected]);

  const handleChange = (value, isMin) => {
    let selectedValue = [];
    if (isMin) {
      setMin(value);
      selectedValue = [
        ...selected.filter((item) => !item.includes(label)),
        `${label}-min_${value},max_${max}`,
      ];
    } else {
      setMax(value);
      selectedValue = [
        ...selected.filter((item) => !item.includes(label)),
        `${label}-min_${min},max_${value}`,
      ];
    }
    setSelected(selectedValue);
  };

  return (
    <Box
      justifyContent="spaceBetween"
      wrap="nowrap"
      className="input-range-container"
    >
      <div className="w-50">
        <CommonInputNumber
          name="min"
          className="range-input"
          controls={false}
          value={min}
          placeholder="minimum"
          type="number"
          onChange={(v) => handleChange(v, true)}
          precision={2}
          min={0}
          max={max}
          onlyInput
        />
      </div>
      <div className="divider" />
      <div className="w-50">
        <CommonInputNumber
          name="max"
          value={max}
          className="range-input"
          controls={false}
          placeholder="maximum"
          type="number"
          onChange={handleChange}
          precision={2}
          min={min}
          onlyInput
        />
      </div>
    </Box>
  );
}

export default InputRange;
