import React, { useState } from "react";
import { Box, CommonInput, CustomButton, Modal, Typography } from "..";
import { ACCENTS, BUTTON_VARIANT } from "../../../constants";

function TextBasedConfirmationModal(props) {
  const { onCancel, onConfirm, modalConstants, modalWidth = 550 } = props;

  const [disabledPrimaryBtn, setDisabledPrimaryBtn] = useState(true);

  const {
    title,
    subtitles,
    primaryBtnText,
    secondaryBtnText,
    inputPlaceholder,
    confirmationText,
  } = modalConstants;

  return (
    <Modal
      width={modalWidth}
      title={title}
      isOpen={true}
      showDivider={false}
      hide={onCancel}
    >
      <Box>
        <Box
          justifyContent="flexStart"
          direction="column"
          alignItem="flexStart"
        >
          {subtitles?.map((sub) => (
            <Typography
              className={"m-b-16px"}
              variant={"secondary"}
              text={sub}
            />
          ))}
          <CommonInput
            className={"m-t-16px"}
            wrapperClass={"w-100"}
            placeholder={inputPlaceholder}
            onChange={(e) => {
              if (e?.target?.value === confirmationText) {
                setDisabledPrimaryBtn(false);
              } else {
                setDisabledPrimaryBtn(true);
              }
            }}
          ></CommonInput>
        </Box>
        <Box className={"w-100"} justifyContent="spaceEvenly">
          <CustomButton
            text={primaryBtnText}
            accent={ACCENTS.PRIMARY}
            variant={BUTTON_VARIANT.BUTTON_FILLED}
            htmlType="submit"
            onClick={() => {
              onCancel();
              onConfirm();
            }}
            disabled={disabledPrimaryBtn}
            customSize={"large"}
          />
          <CustomButton
            text={secondaryBtnText}
            accent={ACCENTS.SECONDARY}
            variant={BUTTON_VARIANT.BUTTON_HOLLOW}
            onClick={onCancel}
            customSize={"large"}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default TextBasedConfirmationModal;
