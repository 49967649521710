import * as React from "react";
import Typography from "../../Typography";

export const EditableFormItem = (props) => {
  const { editing, children, displayValue, ...rest } = props;

  if (editing) {
    return <div {...rest}>{children}</div>;
  }

  return (
    <Typography
      variant={"body"}
      text={displayValue}
      ellipsis={{
        tooltip: displayValue,
      }}
      className={"table-cell-display-value"}
    />
  );
};
