import React, { useEffect, useState } from "react";
import { Form } from "antd";
import CommonSelect from "../../../common/Selector";
import {
  Box,
  CustomButton,
  Drawer,
  PreviewWorkflow,
  Typography,
  When,
  useGlobalModalContext,
} from "../../../common";
import { useDispatch } from "react-redux";
import {
  ASSIGN_WORKFLOW,
  NOTIFICATION_TYPE,
  getOnlyAmount,
  showNotification,
} from "../../../../utils";
import "react-multi-carousel/lib/styles.css";
import "./../../../InvoiceManagement/components/AssignWorkFlowDrawer/assignWorklowDrawerStyle.scss";
import { assignWorkFlowInvoicePO } from "../../../../redux/action/accountPayable";
import {
  ACCENTS,
  BUTTON_VARIANT,
  CURRENCIES,
  ERROR_MSG,
  NO_WORKFLOW_ID,
  WORK_FLOW_TYPES,
  WORK_FLOW_TYPES_TO_TEXT,
} from "../../../../constants";
import { loadingStart, loadingStop } from "../../../../redux/action/loader";
import AssignWorkFlowModalRightSection from "./AssignWorkFlowModalRightSection";
import useRolesAttributesUsers from "../../../../hooks/useRolesAttributesUsers";
import useWorkflow from "../../../../hooks/useWorkflow";

const WORKFLOW_ASSIGN_DRAWER = ({
  open,
  onClose,
  selectedData = [],
  closeDataForm = () => {},
  listUpdatedData,
  module,
  displayNumber,
}) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageIndex, setImageIndex] = useState(1);
  const [selectedWorkFlow, setSelectedWorkFlow] = useState(null);
  const [confirmDisable, setConfirmDisable] = useState(true);
  const { hideModal } = useGlobalModalContext();
  const detail = selectedData?.[0] || {};

  const ids = selectedData?.map(({ id }) => id);
  const { attributes, roles } = useRolesAttributesUsers({
    refreshData: open,
  });
  const { workFlowData } = useWorkflow({
    refreshWorkFlowData: open,
    roles,
    attributes,
    workflowType: WORK_FLOW_TYPES[module],
  });

  useEffect(() => {
    setImageIndex(null);
    const selectedItemDetails = selectedData.find(
      (data) => data.attachment || data[displayNumber]
    );
    if (selectedItemDetails) {
      setSelectedImage({
        attachment_url: selectedItemDetails.attachment,
        displayNumber: selectedItemDetails[displayNumber],
      });
    }
  }, [selectedData]);

  const selectWorkFLow = (data) => {
    setConfirmDisable(false);
    setSelectedWorkFlow(data);
  };
  const selectedWorkFlowData =
    selectedWorkFlow && workFlowData?.find(({ id }) => id === selectedWorkFlow);
  const onImageClick = (imageData, index) => {
    setSelectedImage(imageData);
    setImageIndex(index);
  };

  const handleClear = () => {
    setConfirmDisable(true);
    setSelectedWorkFlow(null);
  };

  const callAssignFunc = () => {
    return assignWorkFlowInvoicePO(
      WORK_FLOW_TYPES[module],
      selectedWorkFlow,
      ids
    );
  };
  const assignTheWorkflow = () => {
    dispatch(loadingStart());
    callAssignFunc()
      .then((res) => {
        showNotification(
          NOTIFICATION_TYPE.success,
          res?.autoapproved
            ? ASSIGN_WORKFLOW.AUTO_APPROVED_SUCCESS_MSG(
                ids?.length,
                WORK_FLOW_TYPES_TO_TEXT[module]
              )
            : ASSIGN_WORKFLOW.ASSIGN_WORKFLOW_SUCCESS_MSG(
                ids?.length,
                WORK_FLOW_TYPES_TO_TEXT[module]
              )
        );
        hideModal();
        onClose(true);
        listUpdatedData();
        closeDataForm();
      })
      .catch((error) => {
        showNotification(
          NOTIFICATION_TYPE.error,
          error?.response?.data?.message || ERROR_MSG.common
        );
      })
      .finally(() => dispatch(loadingStop()));
  };

  return (
    <When condition={open}>
      <div className="assign-work-flow-drawer">
        <Drawer
          open={open}
          width={1080}
          onClose={onClose}
          title={ASSIGN_WORKFLOW.TITLE}
          getContainer={false}
        >
          <div className="assign-workflow-inside-section">
            <div className="assign-workflow-left-section">
              <Form layout="vertical" className="h-100">
                <Typography
                  className="m-b-26px"
                  variant="title"
                  text={ASSIGN_WORKFLOW.SUB_TITLE(
                    WORK_FLOW_TYPES_TO_TEXT[module] +
                      (ids.length > 1 ? "s" : "")
                  )}
                  fontSize={18}
                  responsiveFontSize={false}
                />
                <CommonSelect
                  label={ASSIGN_WORKFLOW.SELECT_LABEL}
                  name="workFlowName"
                  placeholder={ASSIGN_WORKFLOW.SELECT_LABEL_PLACEHOLDER}
                  options={workFlowData?.map(({ workflow_name, id }) => ({
                    value: id,
                    label: workflow_name,
                  }))}
                  onSelect={selectWorkFLow}
                  onClear={handleClear}
                  rules={[
                    {
                      required: true,
                      message: ASSIGN_WORKFLOW.ASSIGN_WORKFLOW_ERROR_MSG,
                    },
                  ]}
                />
                <When
                  condition={
                    selectedWorkFlow && selectedWorkFlow !== NO_WORKFLOW_ID
                  }
                >
                  <PreviewWorkflow
                    workflowData={selectedWorkFlowData}
                    amount={getOnlyAmount(detail?.total_amount)}
                    currency={detail?.currency || CURRENCIES.GBP}
                    showName={false}
                    showNumberOfStep={true}
                    selectedData={detail}
                    heightPadding={300}
                  />
                </When>
                <Box className="mb-2 " justifyContent="spaceBetween">
                  <CustomButton
                    text={"Confirm"}
                    loaderText="Confirming..."
                    showLoader
                    disabled={!selectedWorkFlowData}
                    accent={ACCENTS.PRIMARY}
                    variant={BUTTON_VARIANT.BUTTON_FILLED}
                    onClick={assignTheWorkflow}
                    className="confirm-button w-45"
                    customSize={"large"}
                  />
                  <CustomButton
                    text={"Cancel"}
                    accent={ACCENTS.SECONDARY}
                    variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                    onClick={onClose}
                    className="cancel-button w-45"
                    customSize={"large"}
                  />
                </Box>
              </Form>
            </div>
            <AssignWorkFlowModalRightSection
              imageIndex={imageIndex}
              selectedData={selectedData}
              selectedImage={selectedImage}
              onImageClick={onImageClick}
              type={WORK_FLOW_TYPES_TO_TEXT[module]}
            />
          </div>
        </Drawer>
      </div>
    </When>
  );
};
export default WORKFLOW_ASSIGN_DRAWER;
