import React, { useEffect, useState } from "react";
import Box from "../Box";
import Typography from "../Typography";
import Switch from "../Switch";
import { Slider } from "antd";
import { moneyFormatter } from "../../../utils";
import CommonInputNumber from "../Input/InputNumber";
import When from "../When";
import InputRange from "../InputRange";

export default function FilterSliderRange({ label, selected, setSelected }) {
  const [isRangeMode, setIsRangeMode] = useState(false);
  const [rangeValue, setRangeValue] = useState([0, 0]);
  const [percentageValue, setPercentageValue] = useState(0);

  useEffect(() => {
    if (percentageValue && !selected?.find((item) => item.includes(label))) {
      setPercentageValue(0);
    }
  }, [selected, label]);

  const handlePercentageChange = (value) => {
    setPercentageValue(value);
    setSelected([
      ...selected.filter((item) => !item.includes(label)),
      `${label}-${value}`,
    ]);
  };

  const onSwitchChange = (checked) => {
    setIsRangeMode(checked);
  };

  const handleSliderChange = (v) => {
    if (isRangeMode) {
      setRangeValue(v);
    } else {
      setPercentageValue(v);
    }
  };

  return (
    <>
      <Box justifyContent="flexStart">
        <Typography
          className={isRangeMode ? "inactive-label" : ""}
          text={"Percentage"}
          variant={"body"}
        />
        <Switch
          className="filter-switch"
          isFormItem={false}
          onChange={onSwitchChange}
        />
        <Typography
          className={!isRangeMode ? "inactive-label" : ""}
          text={"Value"}
          variant={"body"}
        />
      </Box>
      <Typography
        text={
          isRangeMode
            ? "Remaining amount range as £ value"
            : "Remaining amount upper limit as % value"
        }
        variant={"body"}
        className="m-b-10px"
      />
      <When condition={isRangeMode}>
        <InputRange
          label={label}
          selected={selected}
          setSelected={setSelected}
        />
      </When>
      <When condition={!isRangeMode}>
        <Slider
          className="slider-range"
          min={0}
          max={100}
          value={percentageValue}
          draggableTrack={true}
          style={{
            track: {
              backgroundColor: "#66DDD6",
            },
          }}
          tooltip={{
            formatter: (v) => (isRangeMode ? moneyFormatter(v, 2) : `${v}%`),
          }}
          onAfterChange={handlePercentageChange}
          onChange={handleSliderChange}
        />
      </When>
    </>
  );
}
