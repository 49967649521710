import { memo } from "react";
import { MODAL_TYPES } from "../../../utils";
import { CustomButton, Icon, Table, Typography, When } from "../../common";
import {
  ACCENTS,
  BUTTON_VARIANT,
  PURCHASE_ORDER_CREATE_FROM,
} from "../../../constants";

function PuchaseOrderAttachments({ showModal, attachments }) {
  const showUploadedAttachments = (record) => {
    showModal(MODAL_TYPES.UPLOADED_ATTACHMENTS_MODAL, {
      title: record?.name,
      file: record,
      fileURL: record?.attachment_url,
    });
  };

  function renderActionColumn({ record }) {
    return (
      <>
        <CustomButton
          icon={<Icon iconName={"visibility"} />}
          accent={ACCENTS.BLACK}
          variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
          onClick={() => showUploadedAttachments(record)}
        />
      </>
    );
  }

  const renderBody = ({
    label,
    key,
    record,
    index,
    defaultRender,
    expandRow,
    isRowExpanded,
  }) => {
    return key === "actions"
      ? renderActionColumn({
          label,
          key,
          record,
          index,
          defaultRender,
          isRowExpanded,
          expandRow,
        })
      : defaultRender({ label, key, record, index, defaultRender });
  };

  const tableProps = {
    tableClassName: "m-b-0px",
    columns: PURCHASE_ORDER_CREATE_FROM.ATTACHMENT_TABLE_COLUMN,
    renderBody,
    data: attachments,
  };

  return (
    <>
      <When condition={attachments?.length}>
        <Typography
          fontStyle="semibold"
          className="m-b-0px m-t-16px"
          variant="secondary"
          text={PURCHASE_ORDER_CREATE_FROM.attachmentsSwitch.label}
        />
        <div className="attachments-list m-t-10px">
          <Table {...tableProps} />
        </div>
      </When>
    </>
  );
}

export default memo(PuchaseOrderAttachments);
