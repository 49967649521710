import { useEffect, useRef, useState } from "react";
import { deleteGRNI, downloadGRNIList, getGRNIList } from "../../../api";
import {
  ACCENTS,
  BUTTON_VARIANT,
  ERROR_MSG,
  GRNI_OPERATION_CONFIG,
  NOTIFICATION_TYPE,
  PO_GRNI_DETAIL_TABLE,
  PO_GRNI_MATCH_DATA,
  PURCHASE_ORDER_BUTTONS,
} from "../../../constants";
import {
  MODAL_TYPES,
  addMessageListener,
  constructClassName,
  downloadFileByJS,
  isStringOrInArray,
  removeMessageListener,
  showNotification,
  stringToArray,
} from "../../../utils";
import {
  Box,
  ComponentLoader,
  CustomButton,
  Icon,
  InfiniteScrollTable,
  Typography,
  When,
} from "../../common";
import "./GRNI-list-container.scss";
import CommonToolTip from "../../common/Tooltip";
import {
  grniAvailableMatchingValue,
  isFeat11423Enabled,
} from "../../../helper";

const { TITLE, DELETE, SUMMARY } = GRNI_OPERATION_CONFIG;
const { GRNI_TOOLTIP } = SUMMARY;

function GRNIListContainer(props) {
  const loadingRef = useRef(true);
  const [GRNIData, setGRNIData] = useState(null);
  const minimumGRNIRecord = 6;
  const grniRecordHeight = 48;

  const footerData = [
    {
      toolTipText: GRNI_TOOLTIP.grniTotalText,
      data: SUMMARY.generateValue(GRNIData?.totalAmount),
      isShow: true,
    },
    {
      toolTipText: GRNI_TOOLTIP.grniGoodsReceived,
      data: SUMMARY.generateGoodsReceived({ props }),
      isShow: props.enableGRNIDownload,
    },
    {
      toolTipText: GRNI_TOOLTIP.grniMatchingText,
      data: SUMMARY.displayGRNIAvailableMatchingValue(
        grniAvailableMatchingValue({ props })
      ),
      isShow: props.enableGRNIDownload && props?.showPoAvailableText,
    },
    {
      toolTipText: GRNI_TOOLTIP.poTotalText,
      data: SUMMARY.poAvailableValue({ props }),
      isShow: props.enableGRNIDownload && props?.showPoAvailableText,
    },
  ];

  const fetchGRNIList = () => {
    const currencyShortName = props?.currencyData?.currency;
    getGRNIList({ id: props.id, currencyShortName })
      .then((data) => {
        props.onGRNIDataLoaded && props.onGRNIDataLoaded(data);
        setGRNIData(data);
      })
      .catch((err) => {
        setGRNIData({});
        showNotification(
          NOTIFICATION_TYPE.error,
          err?.response?.data?.message || ERROR_MSG.common
        );
      })
      .finally(() => {
        loadingRef.current = false;
      });
  };

  useEffect(() => {
    if (isFeat11423Enabled()) {
      addMessageListener(PO_GRNI_MATCH_DATA.CHANNEL_NAME, (message) => {
        if (message) {
          if (isStringOrInArray(stringToArray(message), props.order_number)) {
            fetchGRNIList();
          }
        }
      });
    }
    return () => {
      removeMessageListener(PO_GRNI_MATCH_DATA.CHANNEL_NAME);
    };
  }, []);

  useEffect(() => {
    fetchGRNIList();
  }, [props.reloadGRNI]);

  const handleEditGRNIClick = (record) => {
    props.showModal(MODAL_TYPES.GRNI_JOURNEY_MODAL, {
      type: "edit",
      poData: props,
      GRNIData: record,
      onSuccessComplete: fetchGRNIList,
    });
  };

  const handleDeleteGRNIClick = (record) => {
    props.showModal(MODAL_TYPES.DELETE_CONFIRMATION_MODAL, {
      title: DELETE.title,
      subTitle: DELETE.subTitle,
      deleteOnConfirm: () => deleteGRNI({ id: record.id }),
      onConfirm: () => {
        fetchGRNIList();
        showNotification(NOTIFICATION_TYPE.success, DELETE.notification);
        props.hideModal();
      },
    });
  };

  const handleDownload = async () => {
    const excelLinkResponse = await downloadGRNIList({ id: props.id });
    let fileNameArr = excelLinkResponse?.data?.downloadLink?.split("/");
    let fileName =
      fileNameArr && fileNameArr.length
        ? fileNameArr[fileNameArr?.length - 1]
        : "";
    downloadFileByJS(excelLinkResponse.data.downloadLink, fileName);
  };

  const isGRNIDataAvailable = GRNIData !== null && GRNIData?.list?.length;
  const isScrollEnabled = props.isScrollEnabled;
  const grniTableMinimumHeight =
    isScrollEnabled && GRNIData?.list.length > minimumGRNIRecord
      ? `${minimumGRNIRecord * grniRecordHeight}px`
      : "100%";

  return (
    <>
      <When condition={props.showGRNISectionByDefault || isGRNIDataAvailable}>
        <Box
          justifyContent={"spaceBetween"}
          alignItem={"flexEnd"}
          className="w-100"
        >
          <Typography
            {...TITLE}
            className={constructClassName([
              TITLE.className,
              props.enableGRNIDownload ? "w-50" : "w-100",
            ])}
          />
          <When condition={props.enableGRNIDownload}>
            <CustomButton
              text={PURCHASE_ORDER_BUTTONS.COMPLETED_DOWNLOAD}
              icon={<Icon iconName={"save_alt"} />}
              accent={ACCENTS.SECONDARY}
              variant={BUTTON_VARIANT.LINK}
              onClick={handleDownload}
              showLoader={true}
            />
          </When>
        </Box>
        <When condition={loadingRef.current}>
          <ComponentLoader />
        </When>
        <When condition={!loadingRef.current && isGRNIDataAvailable}>
          <div>
            <InfiniteScrollTable
              className="p-0px h-100"
              tableClassName="GRNI-listing-table m-b-0px"
              columns={PO_GRNI_DETAIL_TABLE}
              data={GRNIData?.list}
              renderBody={({ label, key, record, index, defaultRender }) => {
                switch (key) {
                  case "action":
                    return (
                      <Box wrap={"nowrap"}>
                        <CustomButton
                          icon={<Icon iconName={"edit"} showIconOutline />}
                          accent={ACCENTS.BLACK}
                          variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
                          onClick={() => handleEditGRNIClick(record)}
                        />
                        <CustomButton
                          icon={<Icon iconName={"delete"} showIconOutline />}
                          accent={ACCENTS.DESTRUCTIVE}
                          variant={BUTTON_VARIANT.BUTTON_ONLY_ICON}
                          onClick={() => handleDeleteGRNIClick(record)}
                        />
                      </Box>
                    );
                  default:
                    return defaultRender({
                      label,
                      key,
                      record,
                      index,
                      defaultRender,
                    });
                }
              }}
              enableBorder={true}
              isInfiniteScroll={false}
              showScrollBar={isScrollEnabled}
              height={grniTableMinimumHeight}
            />
          </div>

          {footerData.map((item) => {
            return (
              <When condition={item.isShow}>
                <Box justifyContent="flexEnd" className={"mt-2 w-100"}>
                  <CommonToolTip title={item?.toolTipText} placement={"top"}>
                    <Icon
                      iconName={GRNI_TOOLTIP.iconName}
                      className="material-icons fs-20px cursor-pointer"
                      showIconOutline
                    />
                  </CommonToolTip>
                  <Typography {...SUMMARY.type} text={item.data} />
                </Box>
              </When>
            );
          })}
        </When>
      </When>
    </>
  );
}

export default GRNIListContainer;
