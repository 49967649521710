import { Tag as ATag } from "antd";
import { memo } from "react";

function Tag({ title, color, ...rest }) {
  return (
    <ATag color={color} {...rest}>
      {title}
    </ATag>
  );
}

export default memo(Tag);
