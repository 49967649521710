import Link from "antd/lib/typography/Link";
import { memo } from "react";
import { constructClassName } from "../../../utils/functions";
import "./Typography.scss";

function ActionLink({ text, onClick, className, ...rest }) {
  return (
    <Link
      className={constructClassName(["kloo-action-link", className])}
      onClick={onClick}
      {...rest}
    >
      {text}
    </Link>
  );
}

export default memo(ActionLink);
