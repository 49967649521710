import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Icon,
  ActionLink,
  Box,
  When,
  CommonInput,
  CustomButton,
} from "..";
import {
  ACCENTS,
  ACKNOWLEDGEMENT_CONSTANT,
  BUTTON_VARIANT,
  NAVIGATION_URL,
  WORK_FLOW_TYPES_TO_TEXT,
} from "../../../constants";
import "./acknowledgement-card.scss";
import { Form } from "antd";

function AcknowledgementCard({
  title,
  bodyText,
  icon,
  variant,
  buttonText,
  showClosePageText = false,
  showRejectionReasonField = false,
  rejectButtonDisabled = false,
  callSendRequest,
  maxLengthRejctReason,
  type,
}) {
  const navigate = useNavigate();
  const [reason, setReason] = useState();
  const [form] = Form.useForm();

  const handleClickHere = () => navigate(NAVIGATION_URL.LOGIN);
  const submitReason = () => {
    form
      .validateFields([
        ACKNOWLEDGEMENT_CONSTANT.REJECTION_REASON_INPUT.inputName,
      ])
      .then(() => {
        callSendRequest({ payload: { reason } });
      });
  };
  return (
    <div className="a-card-container">
      <Typography
        variant="title"
        text={title}
        fontSize={"24"}
        responsiveFontSize={false}
      />
      <Box>
        <div
          className={`${
            icon === ACKNOWLEDGEMENT_CONSTANT.SUBMITTED.icon ? "circle" : ""
          }`}
        >
          <Icon
            className={`a-icon ${variant} ${
              icon === "cancel" ? "material-icons-outlined" : ""
            }`}
            iconName={icon}
          />
        </div>
      </Box>
      <Typography
        variant="secondary"
        fontSize="16"
        text={bodyText && bodyText(WORK_FLOW_TYPES_TO_TEXT[type])}
      />
      <When condition={showClosePageText}>
        <Typography
          className="mt-4"
          variant="secondary"
          fontSize="16"
          text={ACKNOWLEDGEMENT_CONSTANT.PAGE_CLOSE_TEXT}
        />
      </When>
      <When condition={showRejectionReasonField}>
        <Form name="rejection_reason" form={form}>
          <CommonInput
            name={ACKNOWLEDGEMENT_CONSTANT.REJECTION_REASON_INPUT.inputName}
            placeholder={
              ACKNOWLEDGEMENT_CONSTANT.REJECTION_REASON_INPUT.placeholder
            }
            className="m-t-10px"
            size="large"
            maxLength={maxLengthRejctReason || 300}
            onChange={(e) => setReason(e.target.value)}
            disabled={rejectButtonDisabled}
          />
          <CustomButton
            text={
              buttonText ||
              ACKNOWLEDGEMENT_CONSTANT.REJECTION_REASON_INPUT.buttonText
            }
            accent={ACCENTS.PRIMARY}
            variant={BUTTON_VARIANT.BUTTON_FILLED}
            className="w-100 m-t-10px m-b-10px"
            onClick={submitReason}
            disabled={rejectButtonDisabled}
          />
        </Form>
      </When>
      <Box rowGap={0}>
        <Typography
          className="mt-4"
          variant="tertiary"
          fontSize="12"
          text={ACKNOWLEDGEMENT_CONSTANT.FOOTER_TEXT}
        />
        <ActionLink
          onClick={handleClickHere}
          text={
            <Typography
              className="mt-4"
              variant="tertiary"
              fontSize="12"
              text={ACKNOWLEDGEMENT_CONSTANT.FOOTER_LINK}
            />
          }
        />
      </Box>
    </div>
  );
}

export default AcknowledgementCard;
