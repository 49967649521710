import {
  CUSTOM_CONDITIONAL_EXPENSE_CONSTANTS,
  featureFlags,
} from "../../constants";
import {
  CATEGORISATION_CONSTANTS,
  VERSION_CUSTOM_FIELDS,
} from "../../constants/Categorisation";
import {
  isDevelopmentEnvironment,
  isPlatformEnvironment,
  isStageEnvironment,
} from "../../utils";

export function xeroCategoryStatus(currentStatus, xeroStatus) {
  return currentStatus === xeroStatus;
}
export function mapCategoryPageWise(resp, pageType) {
  const arr = (resp && resp?.data && resp?.data?.result) || [];
  const pageNameHandler = (page_name) => {
    switch (page_name) {
      case CATEGORISATION_CONSTANTS.CARD_EXPENSE:
        return "CE";
      case CATEGORISATION_CONSTANTS.ACCOUNT_PAYABLE:
        return "AP";

      default:
        break;
    }
  };
  const mappedresult = arr?.map((el) => {
    const req = el["required_field_data"]?.find(
      (item) => item["required_type"] === pageNameHandler(pageType)
    );
    return {
      ...el,
      required: Number((req && req?.required_value) || el?.required),
    };
  });
  resp.data["result"] = mappedresult;
  return resp;
}

export const versionForNewCustomField = (version = "1") => {
  return version === VERSION_CUSTOM_FIELDS;
};

export function isLineItemCategorisationEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.settings.categorisation.showLineItemSectionInInvoice_10948
        .enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.settings.categorisation.showLineItemSectionInInvoice_10948
        .enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.settings.categorisation.showLineItemSectionInInvoice_10948
        .enableForProd)
  );
}

export function isCheatSheetDownloadEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.settings.supplierCheatSheet.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.settings.supplierCheatSheet.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.settings.supplierCheatSheet.enableForProd)
  );
}
