import { memo } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { DEFAULT_THEME } from "../../../utils/constant";
import "./component-loader.scss";
import { constructClassName } from "../../../utils";

const ComponentLoader = ({ fontSize = 32, wrapperClassName, ...rest }) => (
  <div className={constructClassName(["kloo-card-loader", wrapperClassName])}>
    <Spin
      indicator={
        <LoadingOutlined
          style={{ fontSize: fontSize, color: DEFAULT_THEME.PRIMARY_COLOR }}
          spin
        />
      }
      {...rest}
    />
  </div>
);

export default memo(ComponentLoader);
