import { Divider as ADivider } from "antd";
import { memo } from "react";
import { constructClassName } from "../../../utils/functions";

function Divider({ className, ...rest }) {
  return (
    <ADivider
      className={constructClassName(["kloo-divider", className])}
      {...rest}
    />
  );
}

export default memo(Divider);
