import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";

export async function getPODashboardCount() {
  const tempResponse = await apiClient({
    url: PATH.dashboard.PODashboard,
    method: "GET",
  });
  return tempResponse?.data?.result[0];
}

export async function getIMDashboardCount() {
  const tempResponse = await apiClient({
    url: PATH.dashboard.IMDashboard,
    method: "GET",
  });
  return tempResponse?.data?.result[0];
}

export async function getCardDashboardCount(org_id, user_org_id) {
  const tempResponse = await apiClient({
    url: PATH.dashboard.cardAndCardRequestDashboard(org_id, user_org_id),
    method: "GET",
  });
  return tempResponse?.data?.result[0];
}

export async function getCardExpenseDashboardCount() {
  const tempResponse = await apiClient({
    url: PATH.dashboard.cardExpensesDashboard,
    method: "GET",
  });
  return tempResponse?.data?.result[0];
}
