import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import "../../assets/scss/Layout.scss";
import { PROMPTTIMEOUT, Z_INDEX_FOR_SCA_POPUP } from "../../utils/constant";
import { CustomButton, Typography } from "../common";
import { ACCENTS, BUTTON_VARIANT } from "../../constants";

export const UserInactiveModal = ({ onContinue, logout, promptTimeout }) => {
  const [counter, setCounter] = useState(PROMPTTIMEOUT);
  useEffect(() => {
    if (counter === 0) {
      return;
    }
    setTimeout(() => setCounter(counter - 1000), 1000);
  }, [counter]);

  return (
    <Modal
      centered
      className="idle-timer-modal"
      visible={true}
      width="520px"
      footer={[
        <CustomButton
          key="back"
          text={"Stay logged in"}
          accent={ACCENTS.PRIMARY}
          variant={BUTTON_VARIANT.BUTTON_FILLED}
          onClick={onContinue}
          customSize={"large"}
        />,
      ]}
      closable={false}
      zIndex={Z_INDEX_FOR_SCA_POPUP}
    >
      <div className="idle-timer-modal-container">
        <div className="confirm-close-button">
          <Typography
            variant={"title"}
            text={`Your session will expire in ${counter / 1000} seconds.`}
            fontSize={"24"}
            responsiveFontSize={false}
            className={"mb-3"}
          />
        </div>
        <p>Click below to continue.</p>
      </div>
    </Modal>
  );
};
