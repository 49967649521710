export const PERMISSIONS = {
  CARD_EXPENSES: {
    key: "Card Expenses",
    create: "card-expenses-create",
    read: "card-expenses-read",
    update: "card-expenses-update",
    delete: "card-expenses-delete",
    download: "card-expenses-download-read",
    markForReview: "card-expenses-mark-for-review-update",
    markForApproved: "card-expenses-mark-as-approved-update",
    saveAsDraft: "card-expenses-save-as-draft-update",
  },
  SUBSCRIPTION: {
    key: "Subscription",
    read: "subscription-read",
    create: "subscription-create",
    update: "subscription-update",
    delete: "subscription-delete",
  },
  SETTING: {
    key: "Setting",
    read: "setting-read",
    SMART_APPROVAL: {
      read: "setting-smart-approvals-read",
      INVOICE: {
        read: "setting-smart-approvals-invoices-read",
        schedulePayCancel: "setting-smart-approvals-invoices-schedule-read",
      },
      CARDS: {
        read: "setting-smart-approvals-cards-read",
      },
      PURCHASE_ORDER: {
        read: "setting-smart-approvals-purchase-orders-read",
      },
      SUPPLIER: {
        read: "setting-smart-approvals-supplier-read",
      },
      HISTORY: {
        read: "setting-smart-approvals-history-read",
      },
      PAYMENT_RUN: {
        read: "setting-smart-approvals-payment-runs-read",
      },
      CREDIT_NOTES: {
        read: "setting-smart-approvals-payment-runs-read",
      },
    },
    INTEGRATION: {
      read: "setting-integrations-read",
    },
    CATEGORISATION: {
      read: "setting-categorisation-read",
      CARD_EXPENSE_TAB: { read: "setting-categorisation-ce-read" },
      INVOICE_MANAGEMENT_TAB: { read: "setting-categorisation-ap-read" },
      PURCHASE_ORDER_TAB: { read: "setting-categorisation-po-read" },
      CATEGORIES: {
        read: "setting-categories-read",
      },
      INVOICE_LINE_TEM_CATEGORISATION: {
        read: "setting-categorisation-ap-li-read",
      },
      CUSTOM_EXPENSE_FIELD: {
        read: "setting-custom-expense-read",
        create: "setting-custom-expense-create",
        update: "setting-custom-expense-update",
        delete: "setting-custom-expense-delete",
      },
      XERO_TRACKING: {
        read: "setting-expense-field-read",
        create: "setting-expense-field-create",
        update: "setting-expense-field-update",
        delete: "setting-expense-field-delete",
      },
    },
    ADDRESS: {
      read: "setting-address-read",
    },
    THRESHOLD_MATCHING: {
      read: "threshold-matching-read",
    },
    CONFIGURATION: {
      read: "configurations-read",
      PO_MANAGEMENT_TAB: { read: "configurations-po-read" },
      ORGANISATION_TAB: { read: "configurations-organisation-read" },
      INOVICE_TAB: { read: "configurations-im-read" },
      INVOICE_OCR_LINE_ITEMS: { read: "configurations-automatic-li-im-read" },
      INVOICE_LINE_ITEMS: { read: "configurations-li-im-read" },
      INVOICE_DEFAULT_OWNER: { read: "configurations-default-owner-im-read" },
      TAX_CODES: {
        read: "configurations-tax-code-read",
      },
      AUTOMATIC_EMAIL_PO_SUPPLIER: {
        read: "configurations-automatic-email-po-read",
      },
      ENTITY: {
        read: "configurations-entity-read",
      },
      PREFIX: {
        read: "configurations-prefix-po-read",
      },
      THRESHOLD_MATCHING: {
        read: "threshold-matching-read",
      },
      DEFAULT_ORG_CURRENCY: {
        read: "configurations-default-currency-read",
      },
    },
  },
  PAYEE_MANAGEMENT: {
    key: "Payee Management",
    read: "payee-management-read",
    contact: "payee-contact-no-read",
    edit: "payee-edit-button-read",
  },
  SUPPLIER_MANAGEMENT: {
    key: "Payee Management",
    read: "payee-management-read",
    contact: "payee-contact-no-read",
    edit: "payee-management-read",
  },
  INVOICE_MANAGEMENT: {
    key: "Ap",
    read: "ap-invoice-read",
    create: "ap-invoice-create",
    update: "ap-invoice-update",
    SCHEDULE_TAB: {
      read: "schedule-tab-read",
    },
    payNowButton: "pay-now-button-read",
    schedulePaymentButton: "schedule-payment-button-read",
  },
  INVOICE_MATCHING: {
    key: "Invoice Matching",
    readPO: "invoice-matching-po-read",
    readIM: "invoice-matching-im-read",
  },
  APPROVALS: {
    key: "Approvals",
    read: "approvals-read",
  },
  DASHBOARD: {
    key: "Dashboard",
    read: "dashboard-read",
    IM: {
      read: "dashboard-im-read",
    },
    PO: {
      read: "dashboard-po-read",
    },
    CardAndCardExpenses: {
      read: "dashboard-card-and-card-expenses-read",
    },
    XERO_ANALYSIS_TAB: {
      read: "xero-analysis-read",
    },
    KLOO_SPEND_TAB: {
      read: "kloo-spend-read",
    },
    ANALYTICS_DASHBOARD: {
      read: "analytics-dashboard-read",
    },
  },
  FUNDS: {
    key: "Funds",
    DEBIT_ACCOUNT: {
      create: "debit-account-create",
      read: "debit-account-read",
      update: "debit-account-update",
      delete: "debit-account-delete",
    },
    STANDING_ORDER: {
      create: "standing-order-create",
      read: "standing-order-read",
    },
    IMMEDIATE_PAYMENT: {
      create: "immediate-payment-create",
      read: "immediate-payment-read",
    },
    BANK_TRANSFER: {
      create: "bank-transfer-create",
      read: "bank-transfer-read",
    },
    SCHEDULE: {
      read: "scheduled-read",
    },
    HISTORY: {
      read: "history-read",
    },
    ACCOUNT_DETAILS: {
      read: "account-details-read",
    },
  },
  CARDS: {
    key: "Cards",
    ALL_CARDS: {
      read: "all-cards-read",
    },
    MY_CARDS: {
      read: "my-cards-read",
    },
    VIRTUAL_CARDS: {
      create: "virtual-cards-create",
      read: "virtual-cards-read",
      update: "virtual-cards-update",
      delete: "virtual-cards-delete",
    },
    PHYSICAL_CARDS: {
      create: "physical-cards-create",
      read: "physical-cards-read",
      update: "physical-cards-update",
      delete: "physical-cards-delete",
      activate: "activate-physical-card-update",
    },
    limitUpdate: "card-limit-update",
    nicknameUpdate: "card-nickname-update",
    cancel: "cancel-card-update",
    freeze: "freeze-card-update",
    unFreeze: "unfreeze-card-update",
    statusUpdate: "card-status-update",
    download: "card-downloads-import",
    issueCard: "issue-card-create",
  },
  USERS: {
    key: "Users",
    create: "users-create",
    read: "users-read",
    update: "users-update",
    delete: "users-delete",
    invitationLinkSend: "invitation-link-send",
  },
  HEALTH_CHECK: { key: "Health Check", read: "health-check-read" },
  NOTIFICATIONS: { key: "Notifications", read: "notifications-read" },
  ORGANISATIONS: {
    key: "Organization",
    read: "organization-read",
    MODULR_ACCOUNT: {
      read: "organization-modulr-account-read",
    },
    create: "organization-create",
    update: "organization-update",
    delete: "organization-delete",
  },
  TRANSACTIONS: {
    key: "Transactions",
    read: "transactions-read",
    create: "transactions-create",
    update: "transactions-update",
    delete: "transactions-delete",
    USER: {
      read: "user-transactions-read",
    },
    ORGANISATION: {
      read: "organization-transactions-read",
    },
  },
  CUSTOMERS: { key: "Customer", create: "customer-create" },
  COMPANY: { key: "Company", read: "company-read" },
  ANALYTICS: {
    key: "Analytics",
    ORGANISATION: {
      read: "organization-analytics-read",
    },
    USER: {
      read: "user-analytics-read",
    },
  },
  CARD_REQUEST: {
    key: "Card Requests",
    read: "card-requests-read",
    create: "card-requests-create",
    update: "card-requests-update",
    delete: "card-requests-delete",
  },
  TEAMS: {
    key: "Teams",
    create: "teams-create",
    read: "teams-read",
    update: "teams-update",
    delete: "teams-delete",
  },
  CHARGEBEE: {
    key: "Chargebee",
    customerCreate: "chargebee-customer-create",
    subscriptionRead: "chargebee-subscription-read",
    customerBillRead: "chargebee-customer-bill-read",
    invoiceRead: "chargebee-invoice-read",
    organizationSubscriptionsRread: "chargebee-organization-subscriptions-read",
    organizationRead: "chargebee-organization-read",
  },
  ROLES: {
    key: "Roles",
    read: "roles-read",
    update: "roles-update",
    delete: "roles-delete",
  },
  PO: {
    key: "Po",
    create: "purchase-order-create",
    read: "purchase-order-read",
    update: "purchase-order-update",
  },
  REMOVE_DUPLICATE_CE: {
    key: "Remove Duplicate CE",
    "card-expenses-read": "card-expenses-read",
    "card-expenses-update": "card-expenses-update",
  },
  PROFILE: {
    key: "Profile",
    read: "profile-read",
    update: "profile-update",
  },
  PAYMENT_RUNS: {
    key: "Payment Runs",
    create: "payment-runs-create",
    read: "payment-runs-read",
    update: "payment-runs-update",
    delete: "payment-runs-delete",
  },
  CREDIT_NOTES: {
    key: "Credit Notes",
    create: "credit-notes-create",
    read: "credit-notes-read",
    update: "credit-notes-update",
    delete: "credit-notes-delete",
  },
};
