import { memo } from "react";
import useMobile from "../../../hooks/useMobile";
import { constructClassName } from "../../../utils/functions";
import "./Typography.scss";

function TertiaryText({
  text,
  fontStyle = "regular",
  fontSize = "22",
  mobileFontSize = "20",
  responsiveFontSize = false,
  className,
}) {
  const { isMobile } = useMobile();

  const renderedFontSize =
    responsiveFontSize && isMobile ? mobileFontSize : fontSize;

  const clubbedName = constructClassName([
    "kloo-tertiary-text",
    `kloo-font-weight-${fontStyle}`,
    `kloo-font-${renderedFontSize}`,
    className,
  ]);

  return <p className={clubbedName}>{text}</p>;
}

export default memo(TertiaryText);
